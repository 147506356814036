import { useQuery } from 'react-query';
import { queryConfig, queryClient } from 'src/_libs/react-query';

import { fetchBookingClinicProfile } from './_services';

export const QUERY_KEY_NAME = 'BookingClinicProfile';

const newQueryConfig = {
    ...queryConfig,
    queries: {
        ...queryConfig.queries,
        refetchOnMount: false
    }
}

export const queryRequest = async () => {
    // filter through the input params and remove any empty values
    return fetchBookingClinicProfile()
}

export const updateQuery = (data) => {
    return queryClient.setQueryData([QUERY_KEY_NAME, {}], data);
}

export const useBookingClinicProfile = () => {
    return useQuery({
        ...newQueryConfig,
        queryKey: [QUERY_KEY_NAME, {}],
        queryFn: () => queryRequest(),
      });
}