// 1. Import the extendTheme function
import { extendTheme } from '@chakra-ui/react';

// 2. Extend the theme to include custom colors, fonts, etc
// https://colors.eva.design/

export const theme = extendTheme({
  colors: {
    brand: {
      100: '#E6F3F5',
      200: '#CEE6EB',
      300: '#9FBBC3',
      400: '#687E87',
      500: '#273238',
      600: '#1C2730',
      700: '#131E28',
      800: '#0C1520',
      900: '#070F1A',
    },
    bdBlue: {
      100: '#D6EBFF',
      200: '#AED4FF',
      300: '#86BBFF',
      400: '#68A4FF',
      500: '#367FFF',
      600: '#2762DB',
      700: '#1B48B7',
      800: '#113293',
      900: '#0A227A',
    },
    error: {
      100: '#FEE0D8',
      200: '#FEBAB1',
      300: '#FD8D8A',
      400: '#FB6D77',
      500: '#F93D5C',
      600: '#D62C58',
      700: '#B31E53',
      800: '#90134B',
      900: '#770B45',
    },
    positive: {
      100: '#DCFADD',
      200: '#BAF6C3',
      300: '#91E6A6',
      400: '#6FCD8F',
      500: '#43AC72',
      600: '#309367',
      700: '#217B5C',
      800: '#15634F',
      900: '#0C5247',
    },
    neutral: {
      100: '#FDF7DB',
      200: '#FCEDB9',
      300: '#F8DD94',
      400: '#F1CD78',
      500: '#E9B54D',
      600: '#C89338',
      700: '#A77326',
      800: '#875618',
      900: '#6F420E',
    },
    negative: {
      100: '#FDEAE1',
      200: '#FCD1C4',
      300: '#F8B1A5',
      400: '#F1928D',
      500: '#E8686D',
      600: '#C74C5B',
      700: '#A7344D',
      800: '#862140',
      900: '#6F1337',
    },
    none: {
      100: '#EFF9F9',
      200: '#E0F2F4',
      300: '#C5DBE0',
      400: '#A4BAC1',
      500: '#7A8F99',
      600: '#597383',
      700: '#3D586E',
      800: '#263E58',
      900: '#172C49',
    },
    gums: {
      // oral habits
      100: '#FAFFCC',
      200: '#F4FF99',
      300: '#EDFF66',
      400: '#E6FF3F',
      500: '#DAFF00',
      600: '#B8DB00',
      700: '#97B700',
      800: '#779300',
      900: '#617A00',
    },
    calculus: {
      // plaque and gums
      100: '#FFF4CC',
      200: '#FFE699',
      300: '#FFD466',
      400: '#FFC23F',
      500: '#FFA500',
      600: '#DB8500',
      700: '#B76900',
      800: '#934F00',
      900: '#7A3D00',
    },
    caries: {
      // plaque and gums
      100: '#FDE8D9',
      200: '#FBCBB4',
      300: '#F4A68D',
      400: '#EA826E',
      500: '#DC4E41',
      600: '#BD2F2F',
      700: '#9E202A',
      800: '#7F1426',
      900: '#690C23',
    },
    color: {
      // whitening
      100: '#D6EBFF',
      200: '#AED4FF',
      300: '#86BBFF',
      400: '#68A4FF',
      500: '#367FFF',
      600: '#2762DB',
      700: '#1B48B7',
      800: '#113293',
      900: '#0A227A',
    },
    ortho: {
      // straightening
      100: '#C5F6E0',
      200: '#8EEECB',
      300: '#51CCAA',
      400: '#269A85',
      500: '#00574F',
      600: '#004A4A',
      700: '#00383E',
      800: '#002932',
      900: '#001E29',
    },
  },
  fonts: {
    heading: 'Proxima Nova, Open Sans, Arial, sans-serif',
    body: 'Proxima Nova, Open Sans, Arial, sans-serif',
  },
  components: {
    Text: {
      baseStyle: {
        fontFamily: 'Proxima Nova',
        fontWeight: 'normal',
        fontSize: ['15px'],
        textAlign: ['center', 'left'],
        color: '#405159',
      },
    },
    Popover: {
      baseStyle: {
        content: {
          _focus: {
            boxShadow: 'none',
          },
        },
      },
    },
    Switch: {
      baseStyle: {
        track: {
          _focus: {
            boxShadow: 'none',
          },
        },
      },
    },
    Modal: {
      baseStyle: {
        header: {
          bg: '#F7F7F7',
          filter: 'drop-shadow(0px -2px 15px rgba(0, 0, 0, 0.08))',
          py: '30px',
          textSize: '15px',
          textTransform: 'uppercase',
          letterSpacing: '1px',
          color: '#12171A',
          px: '40px',
        },
        body: {
          bg: '#F7F9FA',
          px: '40px',
          pt: '30px',
          pb: '50px'
        },
        footer: {
          bg: '#F7F9FA',
          pb: '20px'
        },
        closeButton: {
          m: '18px',
        }
      },
    },
    FormLabel: {
      baseStyle: {
        fontFamily: 'Proxima Nova',
        fontWeight: 'normal',
        fontSize: '16px',
        color: '#121719',
        textTransform: 'uppercase',
        letterSpacing: '1px'
      },
    },
    Textarea: {
      baseStyle: {
        paddingY: '15px',
      },
      field: {
        color: '#405159',
        _disabled: {
          bg: '#C7D3D933',
          color: '#C5C5C5',
        },
      },
      variants: {
        outline: {
          color: '#405159',
          border: '1px solid',
          borderColor: '#C7D3D9',
          borderRadius: '30px',
          _placeholder: {
            color: '#7A8F99'
          },
          _hover: {
            borderColor: '#405159',
            boxShadow: 'none',
          },
          _focus: {
            borderColor: '#405159',
            boxShadow: '0 0 0 1px #405159',
          },
          _invalid: {
            borderColor: '#E95252',
            boxShadow: 'none'
          }
        },
      },
      sizes: {
        md: {
          field: {
            py: '12px',
            px: '20px',
            borderRadius: '30px',
            h: '45px',
          }
        }
      }
    },
    Input: {
      field: {
        color: '#405159',
        borderRadius: '30px',
        _disabled: {
          bg: '#E1EBEC',
          color: '#C5C5C5',
        },
      },
      variants: {
        outline: {
          field: {
            color: '#405159',
            border: '1px solid',
            borderColor: '#C7D3D9',
            _placeholder: {
              color: '#7A8F99'
            },
            _hover: {
              borderColor: '#405159',
              boxShadow: 'none',
            },
            _focus: {
              borderColor: '#405159',
              boxShadow: '0 0 0 1px #405159',
            },
            _invalid: {
              borderColor: '#E95252',
              boxShadow: 'none'
            },
            _disabled: {
              opacity: 1,
              borderColor: '#C7D3D9',
              bg: '#E1EBEC',
              color: '#C5C5C5',
            },
          },
        },
      },
      sizes: {
        md: {
          field: {
            py: '12px',
            px: '20px',
            borderRadius: '30px',
            h: '45px',
          }
        }
      }
    },
    Tabs: {
      baseStyle: {
        tab: {
          _focus: {
            boxShadow: 'none',
          },
        },
      },
    },
    Button: {
      baseStyle: {
        fontFamily: 'Proxima Nova',
        _focus: {
          boxShadow: 'none',
        },
        _hover: {
          cursor: 'pointer'
        },
        borderRadius: '30px',
        textTransform: 'uppercase',
        py: '12px',
        px: '20px',
        fontSize: '15px',
        fontWeight: 'normal',
      },
      variants: {
        solid: {
          bg: '#44C5A6',
          color: '#405159',
          _hover: {
            bg: '#1EA584',
            cursor: 'pointer',
          },
          _disabled: {
            bg: '#C7D3D9',
            color: '#A0B1BA',
          },
        },
        outline: {
          bg: 'transparent',
          borderColor: '#405159',
          borderWidth: '1px',
          color: '#405159',
          _hover: {
            border: '1px solid',
            borderColor: '#405159',
            borderWidth: '1px',
            bg: 'transparent',
            cursor: 'pointer',
          },
          _disabled: {
            borderColor: '#C7D3D9',
            borderWidth: '1px',
            bg: 'transparent',
            color: '#A0B1BA',
          },
        },
      },
      sizes: {
        full: {
          w: 'full',
          py: '10px',
        },
      },
    },
    IconButton: {
      baseStyle: {
        borderRadius: '5px',
      }
    },
  },
});
