import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

import Popup from './Popup';
import './Popup.css';

import PopupDialogHeader, {
  getHeaderButtonContainer,
  getHeaderCloseButton,
} from './PopupDialogHeader';
import PopupDialogContent, {
  PopupDialogContentWithoutPadding,
  PopupDialogContentText,
} from './PopupDialogContent';
import PopupDialogFooter, { getFooterCloseButton } from './PopupDialogFooter';

import PopupDialogUnsaved from './PopupDialogUnsaved';

const baseCssClassName = 'popup-dialog';

/**
 * @typedef {Object} PopupDialogProps
 *
 * @property {ReactElement|function(PopupDialogInterface):ReactElement} content
 * @property {string} [cssClassName] Extra class names.
 * @property {PopupDialogHeaderProps} [headerProps]
 * @property {PopupDialogFooterProps} [footerProps]
 * @property {PopupProps} [popupProps]
 */

/**
 * @typedef {Object} PopupDialogInterface
 *
 * @property {PopupInterface} popup
 * @property {Element} element
 */

/**
 * Displays a customized popup dialog.
 */
class PopupDialog extends PureComponent {
  static propTypes = {
    content: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,

    // @todo rename to cssClassName
    cssClassNames: PropTypes.string,

    style: PropTypes.object,

    headerProps: PropTypes.object,
    footerProps: PropTypes.object,

    popupProps: PropTypes.object,

    onGetRef: PropTypes.func,
    onGetContentRef: PropTypes.func,
  };

  /**
   * @param {PopupDialogProps} props
   * @param {Object} context
   */
  constructor(props, context) {
    super(props, context);

    this._dialogEl = null;
    this._dialogContentEl = null;
    this._canClose = true;

    this.state = {
      isConfirmCloseDialogOpened: false,
    };
  }

  _handleDialogRef = (el) => {
    const { onGetRef } = this.props;

    if (!el) {
      return;
    }

    this._dialogEl = el;

    if (onGetRef) {
      onGetRef(this._dialogEl);
    }
  };

  _handleDialogContentRef = (el) => {
    const { onGetContentRef } = this.props;

    if (!el) {
      return;
    }

    this._dialogContentEl = el;

    if (onGetContentRef) {
      onGetContentRef(this._dialogContentEl);
    }
  };

  _handleBeforeClose = () => {
    if (!this._canClose) {
      this.setState({
        isConfirmCloseDialogOpened: true,
      });
      return false;
    }

    return true;
  };

  _handleCanClose = (value) => {
    this._canClose = value;
  };

  _handleUnsavedChangesCancel = () => {
    this.setState({
      isConfirmCloseDialogOpened: false,
    });
  };

  _getInterface(popupInterface) {
    return {
      ...popupInterface,
      dialog: {
        element: this._dialogEl,
        contentElement: this._dialogContentEl,
        onSetCanClose: this._handleCanClose,
      },
    };
  }

  render() {
    const { style = {}, popupProps = {} } = this.props;

    const { isConfirmCloseDialogOpened } = this.state;

    const { width, height, ...dialogStyle } = style;

    if (width) {
      dialogStyle.width = '100%';
    }

    if (height) {
      dialogStyle.height = '100%';
    }

    const extendedPopupProps = {
      ...popupProps,
      onBeforeClose: this._handleBeforeClose,
      alignmentProps: {
        ...(popupProps.alignmentProps || {}),
        width,
        height,
      },
    };

    return (
      <Popup {...extendedPopupProps}>
        {
          /**
           * @param {PopupInterface} popupInterface
           */
          (popupInterface) => {
            const { content } = this.props;
            // @todo cache interface after first render
            const dialogInterface = this._getInterface(popupInterface);
            return (
              <div
                className={classnames([baseCssClassName, this.props.cssClassNames])}
                style={dialogStyle}
                ref={this._handleDialogRef}
              >
                <PopupDialogHeader {...this.props.headerProps} dialogInterface={dialogInterface} />
                <PopupDialogContent onGetRef={this._handleDialogContentRef}>
                  {typeof content === 'function' ? content(dialogInterface) : content}
                </PopupDialogContent>
                <PopupDialogFooter {...this.props.footerProps} dialogInterface={dialogInterface} />
                {isConfirmCloseDialogOpened && (
                  <PopupDialogUnsaved
                    onConfirm={() => {
                      this._canClose = true;
                      this.setState({
                        isConfirmCloseDialogOpened: false,
                      });
                      popupInterface.popup.close();
                    }}
                    onCancel={this._handleUnsavedChangesCancel}
                  />
                )}
              </div>
            );
          }
        }
      </Popup>
    );
  }
}

export default PopupDialog;

export {
  PopupDialogHeader,
  PopupDialogFooter,
  PopupDialogContentWithoutPadding,
  PopupDialogContentText,
  getHeaderButtonContainer,
  getHeaderCloseButton,
  getFooterCloseButton,
};
