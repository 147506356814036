import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch} from 'react-redux';
import { Form } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import {
  upperFirst, toUpper, forIn, forEach
} from 'lodash';
import { REGEX_ONLY_TOOTH, REGEX_ONLY_REGION } from '../_config';

import { createChannelId } from '../_helpers';

// components
import { PartialScanChart } from '../RequestDetailPage/_components/PartialScanChart';
import { MediaSection } from '../MediaSection';
import { LoadingEllipsis } from '../_components/Loaders';

// redux state
import { actions } from './_redux/actions';
import {
  getPartialScanRegionsList,
  getPartialScanChart,
  getPartialScanRequested,
  getPartialScanStatus,
  getReportPartialScanStatus,
} from './_redux/selectors';

// actions
import { actions as chatActions } from '../ChatPage/_redux/actions';
import { actions as patientRecordActions } from '../PatientRecords/_redux/actions';
import { actions as patientRequestActions } from '../PatientRequests/_redux/actions';

// services
import { services as reportServices } from '../PatientReports/_redux/services';

// selectors
import { getIntlMessages, getUUID } from '../App/_redux/selectors';
import { getPatientDetails, getCurrentRequestId } from '../PatientDetailsPage/_redux/selectors';

// styles
import { StyledButton } from '../_css/styles';
import { StyledPartialScan } from './styles';
import { consoleLog } from '../_helpers/consoleLog';

const PartialScanRequired = () => {
  const intlMessages = useSelector(getIntlMessages);
  const dispatch = useDispatch();
  const currentRequestId = useSelector(getCurrentRequestId);
  const [ loading, setLoading ] = useState(null);
  const [ error, setError ] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [ done, setDone ] = useState(null)

  const clickNo = async () => {
    setLoading(true);
    setError(false);
    try{
      await reportServices.requirePartialScan(currentRequestId , {partialscan_requested: false})
      setLoading(false);
      setDone(true);
      dispatch(patientRequestActions.updateRequestExtraData(currentRequestId, {partialscan_requested: true}))
    } catch (e) {
      setLoading(false);
      setError(intlMessages['requestDetailsPage.timeline.partialscans.required.error'])
    }
  }

  const clickYes = async () => {
    setLoading(true);
    setError(false);
    try{
      await reportServices.requirePartialScan(currentRequestId, {partialscan_requested: true})
      setLoading(false);
      setDone(true);
      dispatch(patientRequestActions.updateRequestExtraData(currentRequestId, {partialscan_requested: true}))
    } catch (e){
      setLoading(false);
      setError(intlMessages['requestDetailsPage.timeline.partialscans.required.error'])
    }
  }

  return (
    <div className={'partialScanRequired'}>
      <div className={'partialScanRequiredDescription'}>{intlMessages['requestDetailsPage.timeline.partialscans.required.description']}</div>
      <StyledButton onClick={clickNo} size="small" marginTop="5px" disabled={loading}>
        {intlMessages['requestDetailsPage.timeline.partialscans.required.no']}
      </StyledButton>
      <StyledButton onClick={clickYes} size="small" marginTop="5px"  disabled={loading}>
        {intlMessages['requestDetailsPage.timeline.partialscans.required.yes']}
      </StyledButton>
      { error && <div className={'errorMessage'}>{error}</div>}
    </div>
  )
}

const FormSection = () => {
  const dispatch = useDispatch();
  const intlMessages = useSelector(getIntlMessages);
  const chartingState = useSelector(getPartialScanChart);
  const [ sent, setSent ] = useState(false);
  const [ sending, setSending ] = useState(false);
  const [ changed, setChanged]  = useState(false);
  const regions = useSelector(getPartialScanRegionsList);
  const chatUuid = useSelector(getUUID);
  const patientDetails = useSelector(getPatientDetails);
  const partialScanStatus = useSelector(getPartialScanStatus);
  const statusMessage = useSelector(getReportPartialScanStatus);
  const currentRequestId = useSelector(getCurrentRequestId);

  useEffect(()=>{
    setSent(false)
    if (regions.length == 0){
      setChanged(false);
    } else {
      setChanged(true);
    }
  }, [regions])

  const sendRequest = async () => {
    let data = {regions}
    try{
      setSending(true);
      let partialScan = await reportServices.createRequestPartialScan( currentRequestId , data);
      let chatData = {senderId: chatUuid, text: partialScan.id}
      dispatch(chatActions.sendPartialScan(chatData, createChannelId(chatUuid, patientDetails.unique_id)))
      consoleLog("no error")
      setSending(false);
      setSent(true);
      setChanged(false);
    } catch(err){
      setSending(false);
      consoleLog("error ", err)
      return{
        ...err,
        [FORM_ERROR]: upperFirst(intlMessages['form.error.general.error'])
      }
    }
  }
  const temp = {};
  forEach(chartingState, (val, key) => {
    if (val.length > 0){
      let tmpMatch = key.match(REGEX_ONLY_TOOTH);
      if (tmpMatch != null){
        if (temp[tmpMatch]) {
          temp[tmpMatch].push(key.match(REGEX_ONLY_REGION))
        } else
          temp[tmpMatch] = [key.match(REGEX_ONLY_REGION)]
      }
    }
  })
  //const selectedRegions = toPairs(temp);
  return (
    <div className={'formSection'}>
      <Form
        onSubmit={sendRequest}
        render = {
          ({
            handleSubmit,          
            submitting,
          }) => {
            return (
              <form
                className={'partialScanRequestForm'}
                onSubmit={handleSubmit}>
                <div className={'statusMessage'}>
                  <span>{intlMessages[`requestDetailsPage.timeline.partialscans.statusMessage.${sent ? 'WAITING' : statusMessage}`]}</span>
                </div>
                <StyledButton size="small" marginTop="5px" disabled={!changed || (partialScanStatus != null) || sent || submitting} type="submit">
                  {sending
                    ? <div className={'loading'}><LoadingEllipsis /></div>
                    : toUpper(intlMessages['requestDetailsPage.timeline.partialscans.form.button.label'])
                  }
                </StyledButton>
              </form>
            )
          }
      } />
    </div>
  )

}

const PartialScan = () => {
  const chartingState = useSelector(getPartialScanChart);
  const [toothChartingState, setToothChartingState] = useState({});
  const dispatch = useDispatch();
  const partialScanRequested = useSelector(getPartialScanRequested);
  const partialScanStatus = useSelector(getPartialScanStatus);
  const reportPartialScanStatus = useSelector(getReportPartialScanStatus);
  const currentRequestId = useSelector(getCurrentRequestId);

  const handleChange = (charting) => {
    //setChartingState(charting);
    dispatch(patientRecordActions.updatePartialScanChart(currentRequestId, charting));
    const newToothChart = {};
    forIn(charting, (val, key) => {
      if (val.length > 0){
        let tmpMatch = key.match(REGEX_ONLY_TOOTH);
        if (tmpMatch != null){
          newToothChart[tmpMatch] = val;
        }
      }
    })
    setToothChartingState(newToothChart);
  }

  consoleLog("Partial Scan summary: ", partialScanRequested, ", ", partialScanStatus)

  if (partialScanRequested == null){
    return (
      <PartialScanRequired />
    )
  } else {
    return (
      <div className={'partialScanChart'}>
        <PartialScanChart
          disabled = {reportPartialScanStatus != 'INIT'}
          charting = {chartingState}
          toothCharting = {toothChartingState}
          handleChange = {handleChange}
        />
      <FormSection />
      </div>
    )
  }

}

const ContentSection = () => {
  const currentRequestId = useSelector(getCurrentRequestId);

  return (
    <div className={'contentSection'}>
      <MediaSection key={`mediasection.${currentRequestId}`} />
      <div className={'partialScanSection'}>
        <PartialScan />
      </div>
    </div>
  )
}

const TitleSection = () => {
  const intlMessages = useSelector(getIntlMessages);
  return (
    <div className={'titleSection'}>
      <div><span>{intlMessages['requestDetailPage.timeline.partialscans.description']}</span></div>
    </div>
  )
}

const ActionSection = () => {
  const dispatch = useDispatch();
  return (
    <div className={'actionsSection'}>
      <StyledButton onClick={() => dispatch(actions.nextPage())}>
        {'Continue'}
      </StyledButton>
    </div>
  )
}

export const PartialScanSection = () => {

  return (
    <StyledPartialScan>
      <TitleSection />
      <ContentSection />
      <ActionSection />
    </StyledPartialScan>
  )
}
