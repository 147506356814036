import { constants } from './constants';

const initState = {
  loading: false,
  error: false,
  byId: {},
  loadMoreOffset: 0,
  requestCount: 0,
  sortBy: '-chat_date',
  statusQuery: 'PENDING',
  currentSessionId: '',
  currentSessionRequestId: null,
  currentSession: {},
};

export function vidChatAppointments(state = initState, action) {
  switch (action.type) {
    case constants.FETCH_APPOINTMENTS:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case constants.FETCH_APPOINTMENTS_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case constants.FETCH_APPOINTMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        byId: action.data.byId,
      };
    case constants.ADDAPPOINTMENT_STATE:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.data.unique_id]: action.data,
        },
      };
    case constants.UPDATEAPPOINTMENT_STATE:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.data.unique_id]: action.data,
        },
      };
    case constants.CREATE_APPOINTMENT_REQUEST:
      return state;
    case constants.CREATE_APPOINTMENT_SUCCESS:
      return state;
    case constants.UPDATE_APPOINTMENT_STATE:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.data.id]: action.data,
        },
      };
    case constants.UPDATE_CURRENT_SESSION_ID:
      return {
        ...state,
        currentSessionId: action.data,
      };
    case constants.UPDATE_CURRENT_SESSION_REQUEST_ID:
      return {
        ...state,
        currentSessionRequestId: action.data,
      };
    case constants.UPDATE_CURRENT_SESSION:
      return {
        ...state,
        currentSession: action.data,
      };
    default:
      return state;
  }
}
