import { createSelector } from 'reselect';
import moment from 'moment';

const getRequestListSlice = (state) => state.requestList;

export const getLoadMoreOffset = createSelector(getRequestListSlice, (requestList) => {
  return requestList.loadMoreOffset;
});

export const getById = createSelector(getRequestListSlice, (requestList) => {
  return requestList.byId || {};
});

export const getQueryParams = createSelector(getRequestListSlice, (requestList) => {
  return requestList.queryParams;
});

export const getQueryParamsOffset = createSelector(getQueryParams, (queryParams) => {
  return parseInt(queryParams?.offset || 0) || 0;
});

export const getFilterBy = createSelector(getRequestListSlice, (requestList) => {
  return requestList.filterBy;
});

export const getIsLoadingMore = createSelector(getRequestListSlice, (requestList) => {
  return requestList.loadingMore;
});

export const getisLoadMore = createSelector(getRequestListSlice, (requestList) => {
  return requestList.loadMore;
});

export const getRequestCount = createSelector([getById], (byId) => {
  return Object.keys(byId).length;
});

export const getRequestList = createSelector(getById, (byId) => {
  return byId
    ? Object.values(byId)
        //.filter((item) => item.type !== "VIDEOCHAT")
        .map((entry) => {
          return entry;
        })
        .sort((a, b) => {
          if (moment(a.created_at).isSame(moment(b.created_at))) {
            return 0;
          } else if (moment(a.created_at).isAfter(moment(b.created_at))) {
            return 1;
          } else {
            return -1;
          }
        })
    : [];
});

export const getPendingReportList = createSelector([getRequestList], (items) => {
  return items
    ? items.filter(
        (item) =>
          item.type.toUpperCase() == 'REPORT' &&
          !((item.report && item.report.sent_at != null) || item.completed_at)
      )
    : [];
});

export const getFilteredRequestList = createSelector(
  [getRequestList, getFilterBy],
  (items, filterBy) => {
    return items
      ? items.filter((item) => {
          if (filterBy.toUpperCase() == 'COMPLETE') {
            return (item.report && item.report.sent_at != null) || item.completed_at;
          } else if (filterBy.toUpperCase() == 'PENDING') {
            return !((item.report && item.report.sent_at != null) || item.completed_at);
          } else {
            return true;
          }
        })
      : [];
  }
);

export const getLoading = createSelector(getRequestListSlice, (requestList) => {
  return requestList.loading;
});

export const getError = createSelector(getRequestListSlice, (requestList) => {
  return requestList.error;
});
