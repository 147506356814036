import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import cn from 'classnames';
import { startCase } from 'lodash';
// components
import { RecordUploadMedia } from '../_components/RecordUploadMedia';
import { LoadingEllipsis } from '../_components/Loaders';

// compontnt redux
import { actions } from './_redux/actions';
import { getPatientUploadsById, getPatientRecordsById, getPatientRecordsLoading } from './_redux/selectors';

// actions
import { actions as requestPageActions } from '../PatientDetailsPage/_redux/actions';

// selectors
import { getDetailsRecordId } from '../PatientDetailsPage/_redux/selectors';
import { getIntlMessages } from '../App/_redux/selectors';

// styles
import { StyledPatientRecords } from './styles';

export const PatientRecords = () => {
  const dispatch = useDispatch();
  const intlMessages = useSelector(getIntlMessages)
  const currentRecordId = useSelector(getDetailsRecordId);

  const patientUploads = useSelector(getPatientUploadsById);
  const patientRecords = useSelector(getPatientRecordsById);
  const loading = useSelector(getPatientRecordsLoading);

  useEffect(()=>{
    if (!loading[currentRecordId] && !patientRecords[currentRecordId]){
      dispatch(actions.fetchRecord(currentRecordId));
    }
  }, [currentRecordId])

  const handleClick = (id) => {
    dispatch(requestPageActions.setCurrentMedia(id));
    dispatch(requestPageActions.openRecordDetailModal());
  }

  return(
    <StyledPatientRecords>
      <div className={cn('sectionTitle', 'recordTitle')}>
        {startCase(intlMessages['requestDetailPage.details.uploads.title'])}
      </div>
      {
        (loading[currentRecordId] == true)
        ? <div className={'loadingBoxes'}><LoadingEllipsis /></div>
        : <div className={'uploadBoxes'}>
          {(patientUploads[currentRecordId] && patientUploads[currentRecordId].length > 0) ?
            patientUploads[currentRecordId].map((elem, ind) =>
              <RecordUploadMedia
                zoom={false}
                key={elem.getId()}
                upload={elem}
                imgWidth={180}
                imgHeight={110}
                imgBorderRadius={0}
                onClick={() => handleClick(elem.getId())}
                alt={"recordupload"+ind}
              />
            )
            :
              <RecordUploadMedia
                imgWidth={180}
                imgHeight={110}
                imgBorderRadius={0}
                alt={"blank"}
              />
          }
        </div>
      }

    </StyledPatientRecords>
  )
}
