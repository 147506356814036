import Axios from 'axios';

import { BASE_API_URL } from 'src/_config';
import {storage} from 'src/_helpers/storage';

/*
const errorMessage = defineMessage({
  id: 'error.general.message',
  description: 'error message',
  defaultMessage: '에러가 발생했습니다.',
})

const errorTitle = defineMessage({
  id: 'error.general.title',
  description: 'error title',
  defaultMessage: '죄송합니다',
})*/


function authRequestInterceptor(config) {
  const token = storage.getToken();
  if (token) {
    config.headers = {
      ...config.headers,
      Authorization: `Token ${token}`,
    };
  }
  config.headers = {
    ...config.headers,
    Accept: 'application/json',
  };
  return config;
}

export const axios = Axios.create({
  baseURL: BASE_API_URL,
});

axios.interceptors.request.use(authRequestInterceptor);
axios.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    //const message = error.response?.data?.message || error?.message;
    if (error.response?.status == 401) {
      alert('you do not have permission to view this');
      storage.clearToken();
    } else {
      // TODO: this throws errors to much
      //useNotificationStore.getState().addNotification({
      //  type: 'error',
      //  title: '죄송합니다',
      //  message: '에러가 발생했습니다.',
      //});
    }
    if (error.response?.config?.method == 'get') {
      return null;
    }
    if (error.response?.status == 400) {
      return Promise.reject(error.response);
    } else {
      return Promise.reject(error);
    }
  }
);
