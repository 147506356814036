import { authHeader, handleResponse } from '../../_helpers';
import { API_URLS } from '../../_config';

export const services = {
  fetchAppointments,
  fetchVideoChatToken,
  createAppointment,
  updateAppointment,
};

function fetchAppointments(query = {}) {
  const requestUrl = API_URLS.getDentistAppointments;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader() },
  };
  let querystring = '';
  if (query) {
    querystring = new URLSearchParams(query).toString();
  }
  let url = requestUrl.url + '?' + querystring; //+'&status='+statusQuery;
  return fetch(url, requestOptions).then(handleResponse);
}

function createAppointment(data) {
  const requestUrl = API_URLS.createDentistAppointments;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  };
  let url = requestUrl.url;
  return fetch(url, requestOptions).then(handleResponse);
}

function updateAppointment(uid, data) {
  const requestUrl = API_URLS.updateDentistAppointment;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  };
  let url = requestUrl.url.replace('{appointmentUid}', uid);
  return fetch(url, requestOptions).then(handleResponse);
}

function fetchVideoChatToken(requestId) {
  const requestUrl = API_URLS.getDentstRequestVideoChatTOken;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader() },
  };
  let url = requestUrl.url.replace('{requestId}', requestId);
  return fetch(url, requestOptions).then(handleResponse);
}
