import { createSelector } from 'reselect';

const getIntlSlice = (state) => state.intl;
const getUserInfoStateSlice = (state) => state.userInfo;

export const getIntlMessages = createSelector([getIntlSlice], (intl) => {
  return intl.messages;
});

export const getIntlLocale = createSelector([getIntlSlice], (intl) => {
  return intl.locale;
});

export const getToothNumberingSystem = createSelector([getUserInfoStateSlice], () => {
  // TODO add this to a settings reducer on sign in
  return 'palmer';
});

export const isChatLoaded = createSelector(getUserInfoStateSlice, (state) => {
  return Object.keys(state.chat).length > 0;
});

export const isProfileLoaded = createSelector(getUserInfoStateSlice, (state) => {
  return Object.keys(state.profile).length > 0;
});

export const isChatLoading = createSelector(getUserInfoStateSlice, (state) => {
  return state.chatLoading;
});

export const isProfileLoading = createSelector(getUserInfoStateSlice, (state) => {
  return state.profileLoading;
});

export const getProfile = createSelector(getUserInfoStateSlice, (state) => {
  return !state.profileLoading && state.profile;
});

export const getProfileIsStaff = createSelector(getProfile, (profile) => {
  return profile?.is_staff;
});

export const getProfileAvatar = createSelector(getProfile, (profile) => {
  return profile && profile.avatar;
});

export const getProfileEmail = createSelector(getProfile, (profile) => {
  return profile && profile.email;
});

export const getProfileCountry = createSelector(getProfile, (profile) => {
  return profile && profile.country;
});

export const getProfileFirstName = createSelector(getProfile, (profile) => {
  return profile && profile.first_name;
});

export const getProfileLastName = createSelector(getProfile, (profile) => {
  return profile && profile.last_name;
});

export const getProfileUserType = createSelector(getProfile, (profile) => {
  return profile && profile.user_type;
});

export const getProfileID = createSelector(getProfile, (profile) => {
  return profile?.id;
});

export const getChatState = createSelector(getUserInfoStateSlice, (state) => {
  return state.chat;
});

export const getAuthKey = createSelector(getUserInfoStateSlice, (state) => {
  return state.chat && state.chat.auth_key;
});

export const getUUID = createSelector(getUserInfoStateSlice, (state) => {
  return state.chat && state.chat.uuid;
});

export const getChannelGroup = createSelector(getUserInfoStateSlice, (state) => {
  return state.chat && state.chat.channel_group;
});
