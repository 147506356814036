import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Modal from 'react-modal';
import { useSelector, useDispatch } from 'react-redux';

// components
import { CloseIcon as CloseSvg } from '../_images/icons/CloseIcon';
import { ReportProgressTimeline } from './ReportProgressTimeline';
import { PartialScanSection } from './PartialScanSection';
import { ReviewReportSection } from './ReviewReportSection'
import { ReportChartForm } from './ReportChartForm';
import { ReportSentSection } from './ReportSentSection';
import { LoadingEllipsis } from '../_components/Loaders';

//actions
import { actions } from './_redux/actions';

// selectors
import { getIntlMessages } from '../App/_redux/selectors';
import { getCurrentPage, getReportModalState } from './_redux/selectors';

// styles
import {
  StyledLoading,
  StyledReportWrapper
} from './styles';
import { ModalCloseIcon } from '../_css/styles';

const generalStyles = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
      zIndex: 10,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    content: {
      overflow: 'auto',
      WebkitOverflowScrolling: 'touch',
      width: 'fit-content',
      height:  '890px',
      maxHeight: '90%',
      outline: 'none',
      padding: '0',
      position: 'initial',
      zIndex: 10
    }
}
/*
const ReportActions = () => {
  const dispatch = useDispatch()
  const reportState = useSelector(getReportModalState);
  const currentPage = useSelector(getCurrentPage)

  const clickBack = () => {
    currentPage > 0 && dispatch(actions.setPage(currentPage - 1))
  }

  const clickNext = () => {
    currentPage < 4 && dispatch(actions.setPage(currentPage + 1))
  }

  return(
    <StyledReportActions>
      <StyledButton
        disabled={currentPage < 1}
        onClick={clickBack}>
        {'Back'}
      </StyledButton>
      <StyledButton
        disabled={currentPage > 3}
        onClick={clickNext}>
        {'Next'}
      </StyledButton>
    </StyledReportActions>
  )
}
*/
const ReportState = () => {
  const intlMessages = useSelector(getIntlMessages);
  const currentPage = useSelector(getCurrentPage);
  if (currentPage == 0){
    return <PartialScanSection />
  } else if (currentPage == 1){
    return <ReportChartForm />
  } else if (currentPage == 2) {
    return <ReviewReportSection />
  } else if (currentPage == 3){
    return <ReportSentSection />
  } else{
    return (
      <StyledLoading>
        <LoadingEllipsis  />
        <div className={'message'}>{intlMessages['requestDetailPage.timeline.loading.title']}</div>
      </StyledLoading>
    )
  }
}

export const ReportWrapper = () => {
  return(
    <StyledReportWrapper>
      <ReportProgressTimeline />
      <ReportState />
    </StyledReportWrapper>
  )
}

export const CreateReportModal = ({isOpen, onClose, isLoading, isFetching}) => {
  const dispatch = useDispatch();
  const params = useParams();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [anythingLoading, ] = useState(isLoading);
  // eslint-disable-next-line no-unused-vars
  const [fetching, ] = useState(isFetching);
  const currentPage = useSelector(getCurrentPage)
  const reportState = useSelector(getReportModalState);
  const currentRequestId = params.request_id;


  useEffect(() => {
    setModalIsOpen(isOpen)
    return () => {};
  }, [])

  useEffect(()=>{
    // fetch request when id changes in url
    dispatch(actions.setPage(null));
  }, [currentRequestId])


  //useEffect(()=>{
    // if request is loaded and reportid is found, load report
  //  if (currentReportId && currentReportDetails == null){
  //    dispatch(reportActions.fetchReport(currentReportId, currentRequestId))
  //  }
  //}, [currentReportId])

  // initial page 
  useEffect(()=>{
    //console.log("reportsstate", currentPage, " ", reportState, " ", fetching, " ", anythingLoading)
    if (((currentPage == null && reportState != null) || (reportState == 3))){
      dispatch(actions.setPage(reportState));
    }
  }, [currentPage, reportState])

  //const clickBack = () => {
  //  currentPage > 0 && dispatch(actions.setPage(currentPage - 1));
  //}

  //const clickNext = () => {
  //  currentPage < 3 && dispatch(actions.setPage(currentPage + 1));
  //}

  const afterOpenModal = () => {
    /* all refs are set*/
  }

  const closeModal = () => {
    onClose();
  }

  return(
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={generalStyles}
      shouldCloseOnOverlayClick={false}
      contentLabel="Create Report"
    >
      <div style={{position: 'relative', height:'100%'}}>
        <ModalCloseIcon onClick={closeModal} ><CloseSvg/ ></ModalCloseIcon>
        {/*<ModalPrevIcon className={cn({'disabled': currentPage < 1})} disabled={currentPage < 1} onClick={clickBack}>
          <BsChevronCompactLeft />
        </ModalPrevIcon>
        <ModalNextIcon className={cn({'disabled': currentPage > 3})} disabled={currentPage > 3} onClick={clickNext}>
          <BsChevronCompactRight />
        </ModalNextIcon>*/}

        <ReportWrapper />
      </div>
    </Modal>
  )
}