import React, { Component } from 'react';

import { connect } from 'react-redux';
import cn from 'classnames';
import { actions } from './_redux/actions';
import { size, upperFirst, toUpper, startCase } from 'lodash';

import ProgressBar from '../_components/ProgressBar/ProgressBar';
import { getFooterCloseButton, PopupDialogContentText } from '../_components/Popup/PopupDialog';


import { StyledPopupDialog } from './styles.js';
import { consoleLog } from '../_helpers/consoleLog';


const baseCssClassName = 'AlertDialog';
const generalCssClassName = `${baseCssClassName}-general`;
const loadingCssClassName = `${baseCssClassName}-loading`;
const progressCssClassName = `${baseCssClassName}__progress`;
const dialogContentClassName = `${baseCssClassName}__content`;

class AlertDialog extends Component{
  constructor(props){
    super(props);
  }

  componentDidMount(){

  }

  _handleReloadPage = () => {
    window.location.reload();
  }


  _handleDialogClose = () => {
    this.props.dispatch(actions.clear());
  }


  render(){
    const { intl, alert} = this.props;

    if ( size(alert) === 0 ) {
      return (<div></div>)
    } else {
      let footerProps = {};
      if (alert.type.startsWith('general')){
        footerProps = {
          buttons: (dialogInterface) => {
            if (alert.type.startsWith('loading')){
              return getFooterCloseButton(
                      dialogInterface,
                      {text: toUpper(intl.messages['popUp.button.error'])}
              )
            } else {
              return getFooterCloseButton(
                      dialogInterface,
                      {text: toUpper(intl.messages['popUp.button.error'])}
              )
            }
          },
        }
      }
      const alertTitle = startCase(intl.messages[`popUp.title.${alert.title_type || alert.type}`])
      consoleLog("alert title", alertTitle)
      const alertMessage = alert.message || upperFirst(intl.messages[`popUp.message.${alert.type}`])
      return (
        <StyledPopupDialog
          cssClassNames={cn(baseCssClassName, {[generalCssClassName]: alert.type.startsWith('general'),
                            [loadingCssClassName]: alert.type.startsWith('loading')})}
          headerProps={{
            title: alertTitle,
          }}
          content={() => (
            <PopupDialogContentText>
              { alert.type.startsWith('loading') && <ProgressBar />}
              <div className={dialogContentClassName} >
                <div className={progressCssClassName}
                  dangerouslySetInnerHTML={{
                    __html: upperFirst(alertMessage),
                  }}
                />
              </div>
            </PopupDialogContentText>
          )}
          footerProps={footerProps}
          popupProps={{
            onClose: (alert.type === "general.error500") && this._handleReloadPage || this._handleDialogClose
          }}
        />
      )
    }
  }
}

function mapStateToProps(state) {
    const { alert, intl } = state;
    return {
        alert,
        intl
    };
}

const connectedAlertDialog = connect(mapStateToProps)(AlertDialog);
export { connectedAlertDialog as AlertDialog };
