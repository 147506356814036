// src/SearchBar.js
import React, { useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { BiCurrentLocation } from "react-icons/bi";
import AsyncSelect from "react-select/async";
import { Box } from "@chakra-ui/react";
import { getIntlLocale, getIntlMessages } from "src/App/_redux/selectors";
import { useSelector } from "react-redux";
import { LocationPlacesSearch } from "./LocationPlacesSearch";


const selectStyles = {
    singleValue: (provided, state) => {
      const color = state.hasValue ? '#000000' : '#C7D3D9';
      const display = state.selectProps.menuIsOpen ? 'none' : 'block';
      return { ...provided, color, display };
    },
    control: (provided) => {
      const border = 'none';
      const borderRadius = 50;
      const backgroundColor = '#EDEDED';
      const paddingLeft = 10;
      return { ...provided, border, borderRadius, backgroundColor, paddingLeft };
    },
    dropdownIndicator: (provided) => {
      const color = '#000000';
      return { ...provided, color };
    },
    indicatorSeparator:  (provided, state) => {
      const backgroundColor = state.hasValue ? '#36454D' : '#C7D3D9';
      return { ...provided, backgroundColor };
    },
    /*input: (provided) => {
      const height = 36;
      return {...provided, height}
    }*/
  
  }
export const LocationSearchBar = ({
  onLocationSearch,
  handleGetCurrentLocation,
  setCurrentAddress,
}) => {
  const [ setQuery] = useState("");
  const intlLocale = useSelector(getIntlLocale);
  const [defaultInput, setDefaultInput] = useState({});
  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API;
  const searchService = new window.google.maps.places.AutocompleteService();
  const geocoder = new window.google.maps.Geocoder();
  const placesService = new window.google.maps.places.PlacesService(
    document.createElement("div")
  );
  const handleInputChange = async (query) => {
    const request = {
      input: query,
      key: apiKey,
      types: ["geocode"],
      language: intlLocale,
    };
    let response = await searchService.getPlacePredictions(request);

    const { predictions } = response;
    const newArray = predictions.map((item) => {
      return {
        label: item.description,
        value: item.place_id,
        place_id: item.place_id,
      };
    });
    return newArray;
  };

  const handleSelectSuggestion = (suggestion) => {
    setDefaultInput(undefined);
    if (suggestion) {
      fetchLatLng(suggestion.place_id);
    }
  };
  const fetchLatLng = async (placeId) => {
    const request = {
      placeId: placeId,
      // fields: ["address_components"],
    };

    // Make the request

    placesService.getDetails(request, (result, status) => {
      if (status === "OK") {
        setCurrentAddress(result.formatted_address);
        const lat = result.geometry.location.lat();
        const lng = result.geometry.location.lng();
        onLocationSearch({ lat, lng });
      } else {
        console.error("Error fetching place details:", status);
      }
    });
  };

  const handleFindAddress = async (lat, lng) => {
    const latLng = new window.google.maps.LatLng(lat, lng);
    let response = await geocoder.geocode({ location: latLng });

    const { results } = response;
    if (results) {
      const formattedAddress = results[0].formatted_address;
      setCurrentAddress(results[0].formatted_address);
      setDefaultInput({
        label: formattedAddress,
        value: results[0].place_id,
        place_id: results[0].place_id,
      });
    } else {
      console.error("Address not found.");
    }
  };

  const onClcikMyLocation = () => {
    handleGetCurrentLocation();
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          handleFindAddress(latitude, longitude);
        },
        (error) => {
          console.error("Error getting current location:", error);
        }
      );
    }
  };

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      setQuery(inputValue);
      resolve(handleInputChange(inputValue));
    });

  return (
    <div>
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        className="loaction_select"
      >
        <AiOutlineSearch
          color="#7B8887"
          fontSize={"20"}
          style={{ marginRight: "-45px", zIndex: "1000" }}
        />
        <AsyncSelect
          classNamePrefix="location_search"
          placeholder={"Type your Clinic Address"}
          value={defaultInput ? defaultInput : undefined}
          styles={{
            singleValue: (provided) => {
              return {
                ...provided,
                color: "#7B8887",
                fontFamily: "Poppins",
                paddingLeft: "10px",
              };
            },
            indicatorSeparator: (provided) => {
              return { ...provided, backgroundColor: "#E2E8F0" };
            },
            placeholder: (provided) => {
              return {
                ...provided,
                color: "#7B8887",
                fontFamily: "Poppins",
                paddingLeft: "10px",
              };
            },
            valueContainer: (provided) => {
              return {
                ...provided,
                color: "#7B8887",
                fontFamily: "Poppins",
                paddingLeft: "25px",
              };
            },
            input: (provided) => {
              return {
                ...provided,
                fontFamily: "Poppins",
                paddingLeft: "10px",
              };
            },
            container: (provided, state) => {
              return {
                ...provided,
                borderColor: state.isFocused ? "#179B8B" : "#7B8887",
                boxShadow: state.isFocused ? "#179B8B" : "#7B8887",
                color: "#7B8887",
                width: "100%",
                marginLeft: "20px",
                // height: "10px",
                background: "white",
                fontSize: "11px",
              };
            },

            option: (provided, state) => {
              return {
                ...provided,
                backgroundColor: state.isFocused ? "#DEF6F3" : "",
                color: state.isFocused ? "#1B2B29" : "#7B8887",
              };
            },
            control: (provided, state) => {
              return {
                ...provided,
                boxShadow: state.isFocused ? "0 0 0 0.1px #3182ce" : "none",
                borderColor: state.isFocused ? "#179B8B" : "#7B8887",
                color: "#7B8887",
                height: 15,
                borderRadius: 10,
                "&:hover": {
                  boxShadow: "none",
                  borderColor: "none",
                },
              };
            },
          }}
          autoFocus={true}
          isClearable
          loadOptions={promiseOptions}
          onChange={(e) => handleSelectSuggestion(e)}
        />
        <BiCurrentLocation
          color="#7B8887"
          fontSize={"20px"}
          style={{ marginLeft: "-25px", zIndex: "1000" }}
          cursor={"pointer"}
          onClick={() => onClcikMyLocation()}
        />
      </Box>
    </div>
  );
};



export const SearchBar = ({
    init,
    onLocationSearch,
    // eslint-disable-next-line no-unused-vars
    handleGetCurrentLocation,
    setCurrentAddress,
}) => {
    const intlMessages = useSelector(getIntlMessages);
    // eslint-disable-next-line no-unused-vars
    const [ value, setValue ] = useState("");
  
    const onChange = (e) =>{
      setValue(e);
      var request = {
        placeId: e.value.placeId,
        fields: ['name', 'rating', 'formatted_phone_number', 'geometry']
      };
  
      var service = new window.google.maps.places.PlacesService();
      service.getDetails(request, (place, status) => {
        if (status == window.google.maps.places.PlacesServiceStatus.OK) {
            setCurrentAddress(place.formatted_address);
            const lat = place.geometry.location.lat();
            const lng = place.geometry.location.lng();
            onLocationSearch({ lat, lng });

        }
      });
  
    }
  
      return (
        <div className={'clinicSearchBar'}>
            <LocationPlacesSearch
                init={init}
                onChange={onChange}
                setCurrentAddress={setCurrentAddress}
                styles={selectStyles}
                placeholder={intlMessages['clinicSearchPage.search.searchBar.places.placeholder']}
            />
        </div>
      )
  /*
    return(
      <div className={'clinicSearchBar'}>
        <StyledInput
          value={value}
          onChange={onChange}
          onKeyPress={onKeyPress}
          placeholder={'Enter Address'}
          />
      </div>
    )*/
  }