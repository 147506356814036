import { useQuery } from 'react-query';
import { queryConfig } from 'src/_libs/react-query';

import { fetchRecordUploadDetections } from './services';

export const QUERY_KEY_NAME = 'UploadDetections';

const newQueryConfig = {
    ...queryConfig,
    queries: {
        ...queryConfig.queries,
        refetchOnMount: false
    }
}

export const queryUploadDetections = async ({ recordUploadUid }) => {
    return fetchRecordUploadDetections(recordUploadUid);
}

export const useUploadDetections = ({ recordUploadUid, config={} }) => {
    return useQuery({
        ...newQueryConfig,
        ...config,
        refetchOnMount: false,
        enabled: !!recordUploadUid,
        queryKey: [QUERY_KEY_NAME, {recordUploadUid}],
        queryFn: () => queryUploadDetections({recordUploadUid}),
      });
}