import { authHeader, handleResponse } from '../../_helpers';
import { API_URLS } from '../../_config';

export const services = {
  fetchAppointment,
  fetchAppointmentVideoChatToken,
};

function fetchAppointment(apptUid) {
  const requestUrl = API_URLS.getDentistAppointmentRoom;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };
  let url = requestUrl.url.replace('{apptUid}', apptUid);

  return fetch(url, requestOptions).then(handleResponse);
}

/**
 * fetch appointment token returns all information required for video chat room
 * video chat opens in a separate window so no data is read from the current redux state
 */
function fetchAppointmentVideoChatToken(apptUid) {
  const requestUrl = API_URLS.getDentistAppointmentToken;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };
  let url = requestUrl.url.replace('{apptUid}', apptUid);

  return fetch(url, requestOptions).then(handleResponse);
}
