import React, { useState } from 'react';
import { useSelector} from 'react-redux';

// components
import { ChartSection, ChartCategories, ReportComments } from '../RequestDetailPage/PatientReportPreview';
import { SendReportForm } from './SendReportSection';

// redux state

// selectors
import { getIntlMessages } from '../App/_redux/selectors';

// styles
import {
  StyledReviewReportSection,
  StyledSendReportComponent
} from './styles';
import { StyledButton } from '../_css/styles';

const sendReportVariant = {
  open: { opacity: 1, left: 'calc(100% - 450px)' },
  closed: { opacity: 1, left: '100%' },
}

const SendReportComponent = ({isOpen, onCancel}) => {
  return (
    <StyledSendReportComponent
        animate={isOpen ? "open" : "closed"}
        transition={{ type: 'tween', ease: "linear"}}
        variants={sendReportVariant}
      >
      <SendReportForm
        onCancel={onCancel}
        />
    </StyledSendReportComponent>
  )
}

const ContentSection = () => {
  const intlMessages = useSelector(getIntlMessages);
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(old => !old);
  }

  const closeForm = () => {
    setIsOpen(false);
  }

  return (
    <div className={'contentSection'}>
      <div className={'reviewReportCategorySection'}>
        <ChartCategories />
      </div>
      <div className={'reviewReportDetailSection'}>
        <div className={'chartSection'}>
          <ChartSection />
        </div>
        <div className={'commentsSection'}>
          <ReportComments />
        </div>
      </div>
      <div className={'reviewReportActions'}>
        <StyledButton onClick={toggleOpen}>
          {intlMessages['requestDetailPage.report.form.finalizereport']}
        </StyledButton>
      </div>
      <SendReportComponent
        isOpen={isOpen}
        onCancel={closeForm}
        />
    </div>
  )
}

const TitleSection = () => {
  const intlMessages = useSelector(getIntlMessages);
  return (
    <div className={'titleSection'}>
      <div><span>{intlMessages['requestDetailPage.timeline.review.description']}</span></div>
      {/*<div className={'statusMessage'}><span>{intlMessages[`requestDetailsPage.timeline.review.statusMessage.${status}`]}</span></div>*/}
    </div>
  )
}
export const ReviewReportSection = () => {

  return (
    <StyledReviewReportSection>
      <TitleSection />
      <ContentSection />

    </StyledReviewReportSection>
  )
}
