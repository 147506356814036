import { constants } from './constants';
import { services } from './services';
import { RecordUpload } from '../../_helpers/models';

export const actions = {
  fetchRecord,
  fetchRecordbyUid,
  fetchRecordUpload,
  fetchRecordUploadDetections,
  updatePartialScanChart,
};

function fetchRecordUploadDetections(id) {
  return (dispatch) => {
    dispatch(request(id));
    services.fetchRecordUploadDetections(id).then(
      (data) => {
        let detections = [];
        if (typeof data.detections === 'string') {
          detections = JSON.parse(data.detections);
        } else {
          detections = data.detections;
        }
        let dataByFrame = (detections || []).reduce((acc, curr) => {
          acc[curr[1]] = curr;
          return acc;
        }, {});
        let newData = { ...data, detections: dataByFrame };
        dispatch(success(id, newData));
      },
      (error) => dispatch(failure(id, error))
    );
  };
  function request(id) {
    return { type: constants.FETCHDETECTIONS_REQUEST, data: { id } };
  }
  function success(id, data) {
    return { type: constants.FETCHDETECTIONS_SUCCESS, data: { id, detection: data } };
  }
  function failure(id, error) {
    return { type: constants.FETCHDETECTIONS_FAILURE, data: { id, error } };
  }
}

function fetchRecordbyUid(uid) {
  return (dispatch) => {
    dispatch(request(uid));
    services.fetchRecordByUid(uid).then(
      (data) => dispatch(success(data)),
      (error) => dispatch(failure(uid, error))
    );
  };
  function request(id) {
    return { type: constants.FETCHRECORD_REQUEST, data: { id } };
  }
  function success(data) {
    // breakdown reports...
    const { record } = data;
    var partialScans = [];
    let partialScanChart = {};
    // TODO mkae this a model? move to utils to normalize json data
    if (Array.isArray(record.child_user_records) && record.child_user_records.length > 0) {
      // filter for child records of type partial scan
      // filter the uploads that have status true (patient responded)
      record.child_user_records
        .filter((item) => item.type == 'PARTIALSC')
        .map((elem) => {
          for (var i = 0; i < elem.record_uploads.length; i++) {
            // if status is true, add the upload to media list to be shown
            if (elem.record_uploads[i].extra_data) {
              if (elem.record_uploads[i].extra_data.status == true) {
                partialScans.push(new RecordUpload(elem.record_uploads[i]));
              }
              // initialize partial scan chart with already requested regions
              partialScanChart[elem.record_uploads[i].extra_data.region] = 'selected';
            }
          }
        });
      //.reduce((acc,curr) => {return [...acc, ...curr.record_uploads.filter(item=>item.extra_data.status == true)]}, [])
    }
    data = {
      id: record.unique_id,
      uploads: [...record.patientrecord_uploads, ...partialScans].reduce((acc, curr) => {
        acc[curr.unique_id] = new RecordUpload(curr);
        return acc;
      }, {}),
      record,
      partialScanChart,
    };
    return { type: constants.FETCHRECORD_SUCCESS, data };
  }
  function failure(id, error) {
    return { type: constants.FETCHRECORD_FAILURE, data: { id, error } };
  }
}

function fetchRecordUpload(id) {
  return (dispatch) => {
    dispatch(request(id));
    services.fetchRecordUpload(id).then(
      (data) => {
        dispatch(success(id, new RecordUpload(data)));
      },
      (error) => dispatch(failure(id, error))
    );
  };
  function request(id) {
    return { type: constants.FETCHRECORDUPLOAD_REQUEST, data: { id } };
  }
  function success(id, data) {
    return { type: constants.FETCHRECORDUPLOAD_SUCCESS, data: { id, upload: data } };
  }
  function failure(id, error) {
    return { type: constants.FETCHRECORDUPLOAD_FAILURE, data: { id, error } };
  }
}

function fetchRecord(id, requestId = null) {
  return (dispatch) => {
    dispatch(request(id));
    services.fetchRecord(id, requestId).then(
      (data) => dispatch(success(data)),
      (error) => dispatch(failure(id, error))
    );
  };
  function request(id) {
    return { type: constants.FETCHRECORD_REQUEST, data: { id } };
  }
  function success(data) {
    // breakdown reports...
    const { record } = data;
    var partialScans = [];
    let partialScanChart = {};
    // TODO mkae this a model? move to utils to normalize json data
    if (Array.isArray(record.child_user_records) && record.child_user_records.length > 0) {
      // filter for child records of type partial scan
      // filter the uploads that have status true (patient responded)
      record.child_user_records
        .filter((item) => item.type == 'PARTIALSC')
        .map((elem) => {
          for (var i = 0; i < elem.record_uploads.length; i++) {
            // if status is true, add the upload to media list to be shown
            if (elem.record_uploads[i].extra_data) {
              if (elem.record_uploads[i].extra_data.status == true) {
                partialScans.push(new RecordUpload(elem.record_uploads[i]));
              }
              // initialize partial scan chart with already requested regions
              partialScanChart[elem.record_uploads[i].extra_data.region] = 'selected';
            }
          }
        });
      //.reduce((acc,curr) => {return [...acc, ...curr.record_uploads.filter(item=>item.extra_data.status == true)]}, [])
    }
    data = {
      id: record.unique_id,
      uploads: [...record.patientrecord_uploads, ...partialScans].map(
        (upl) => new RecordUpload(upl)
      ),
      record,
      partialScanChart,
    };
    return { type: constants.FETCHRECORD_SUCCESS, data };
  }
  function failure(id, error) {
    return { type: constants.FETCHRECORD_FAILURE, data: { id, error } };
  }
}

function updatePartialScanChart(id, data) {
  return (dispatch) => {
    dispatch(update(id, data));
  };
  function update(id, data) {
    return { type: constants.UPDATE_PARTIALSCANCHART, data: { id, value: data } };
  }
}
