import React, {useEffect, useState, useRef} from 'react';
import { useSelector, useDispatch  } from 'react-redux';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import cn from 'classnames';

import { RiSettings3Fill } from 'react-icons/ri';

import Select from 'react-select';

import moment from 'moment';

import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from 'react-day-picker/moment';

import { localeFormatDate, formatName, intervalRangeToTime, numberTo12Hour } from '../_helpers';
import { createPatientNotificationChannelId } from '../_helpers/pubnubUtils';

// components
import { PatientsSearch } from '../Patients/PatientsSearch';

// state redux
import { services } from './_redux/services';

// actions
import { actions as patientRequestActions }  from '../PatientRequests/_redux/actions';
import { actions as appointmentActions } from '../Appointments/_redux/actions';
import { actions as chatActions } from '../ChatPage/_redux/actions';

// services
//
// selectors
import { getIntlLocale, getIntlMessages} from '../App/_redux/selectors';

// styles
import { StyledButton } from '../_css/styles';
import { consoleLog } from '../_helpers/consoleLog';

const { Handle } = Slider;

const Styles = styled.div`
  width: 500px;
  .title{
    font-size: 22px;
    font-family: Proxima Nova Semibold;
    text-align: center;
  }
  .appointmentComplete{
    margin-top: 20px;

    &_label{
      padding: 10px 0;
      color: #7A8F99;
    }

    &_patientName{
      display:flex;
      align-items:center;
      font-size: 16px;
      .label{
        margin:0 8px;
        color: #7A8F99;
        font-size: 14px;
      }
      .birthDate, .gender, .splitter{
        font-family: 'Proxima Nova';
      }
    }

    &_value{
      font-size: 16px;
    }

    ${StyledButton}{
      display:flex;
      margin-top: 0;
      justify-content: center;
      min-width:100px;
    }
  }
  .originalAvailability{
    display:flex;
    justify-content: flex-start;
    padding: 10px 0px;
      color: #7A8F99;
    &_label{
      margin-right: 15px;
    }

    &_date{
      color: #7A8F99;
      letter-spacing: .5px;
    }
  }
  .label{
    font-family: Proxima Nova Semibold;
  }
  .content{
    input{
      border: 1px solid #C7D3D9;
      padding: 10px 15px;
      font-size: 16px;
      border-radius: 3px;
    }

    .patient{

      &_label{

      }

      &_input{
        display:flex;
        align-items:center;
        padding: 10px 0;

        &.Error{
          input{
            border-color:red;
          }
        }

        &:hover{
          cursor:text;
        }

        input{
          height: 32px;
        }
      }
    }

    .requestTime{
      display:flex;
      margin: 10px 0;
      justify-content: flex-start;
      .requestTimeLabel{
        margin-right: 10px;
      }
      .requestTimeValue{

      }

    }

    .timePickers{

      &_date{

        .DayPickerInput{
          line-height: 1.2rem;

          &.Error{
            input{
              border-color: red;
            }
          }
          .DayPicker-Day{
            line-height: 1.2rem;
          }
        }

      }
    }

    .appointmentConfirmation{
      margin-top: 20px;

      &_label{
        display:flex;
        justify-content: center;
        padding: 10px 0;

        > div:first-child{
          margin: 0 5px;
        }
      }

      ${StyledButton}{
        display:flex;
        margin-top: 0;
        justify-content: center;
        min-width:150px;
      }

      &_content{
        padding-bottom: 10px;
      }
    }



    .appointments{
      margin-top: 20px;
      display:flex;
      flex-direction: column;

      .appointments_label{
        display:flex;
        justify-content: space-between;

        .appointments_settings_icon{
          color: #7A8F99;
          font-size: 12px;

          &:hover{
            cursor:pointer;
          }

          > span {
            margin-left: 5px;
          }
        }
      }

      .appointments_settings{
        display:flex;
        flex-direction: column;
        padding: 10px 0 0;
        opacity: 0;
        height: 0;
        overflow:hidden;

        &_container{

        }

        &_labels, &_inputs{
          display:flex;
          justify-content: space-between;
        }

        &_labels{
          font-size: 14px;
        }

        &_inputs{
          margin: 5px 0 20px;
          align-items: center;
        }

        .appointments_settings_timeInterval{
          width: 25%;
          display: flex;
          justify-content: flex-start;

          &_input{
            width: 80px;
          }
        }

        .appointments_settings_timeRange{
          width: 70%;

          &_input{
            padding-right:20px;
            padding-left: 10px;

            .rc-slider-handle{
              &:before{
                content: attr(aria-label);
                position: absolute;
                font-family: Proxima Nova Semibold;
                font-size: 12px;
                white-space:nowrap;
                letter-spacing: 0px;
                bottom: 12px;
                right: -8.5px;
              }
            }

            .rc-slider-mark-text{
              letter-spacing: 0px;
              white-space:nowrap;
            }
          }

        }

      }

      .appointments_content{
        display:flex;
        overflow-x: scroll;
        padding: 20px;
        border-radius:11px;
        background-color: rgb(247, 249, 250);

        &::-webkit-scrollbar {
          width: 10x;
          height: 10px;
        }

        > div{
          display: grid;
          grid-auto-flow: column;
          grid-auto-columns: auto;
        }


        .appointment{
          display:flex;
          flex-direction: column;
          padding: 10px 20px;
          background-color: #273238;
          border-radius: 6px;
          color: #fff;
          margin-right: 8px;

          &:not(.before):not(.after):not(.busy):hover{
            cursor:pointer;
            transform: translateY(-1px);
          }

          &.before{
            transform: scaleY(0.95);
            &:hover{
              cursor: default;
            }
          }

          &.after{
            transform: scaleY(0.95);
            &:hover{
              cursor: default;
            }
          }

          &.busy{
            opacity: 0.2;
            &:hover{
              cursor: default;
            }

            .appointmentPatient{
              white-space: nowrap;
              font-size: 14px;
              text-align: center;

            }
          }

          &.requested{
            background-color: #007bff;
            box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.05);
          }


          .appointmentPatient{
            white-space: nowrap;
            font-size: 12px;
            text-align: center;

          }

          .appointmentTime{
            white-space: nowrap;
            font-family: Proxima Nova Semibold;
            font-size: 16px;
            letter-spacing: 1.8px;

          }
        }
      }
    }
  }
`;

let timeIntervalList = [
  {value: 10, label: 10},
  {value: 15, label: 15},
  {value: 30, label: 30}
]
let appointmentData = [
  {start_date: 1635818400*1000, patient: {first_name: 'Jackson', last_name: 'Wilson'}},
  {start_date: 1635820200*1000, patient: {first_name: 'Tom', last_name: 'Johnson'}},
  {start_date: 1635827400*1000, patient: {first_name: 'Aaron', last_name: 'Yohansen'}},
  {start_date: 1635831000*1000, patient: {first_name: 'Michael', last_name: 'Larsen'}},
  {start_date: 1635840900*1000, patient: {first_name: 'Bret', last_name: 'Chan'}}
]
let defaultMarks = {
  0: intervalRangeToTime(0),
  6: intervalRangeToTime(6),
  15: intervalRangeToTime(15),
  24: intervalRangeToTime(24)
}

const sliderHandle = props => {
  const { value, ...restProps } = props;
  restProps['ariaLabel'] = intervalRangeToTime(value)
  return (
      <Handle value={value} {...restProps} />
  );
};

export const AppointmentTime = ({time, range, timeInterval, busyTime, requestedMinutes, onClick}) => {
  const requestedRef = useRef(null);
  let isSelected = (requestedMinutes == time) || ( (requestedMinutes < time + timeInterval) && requestedMinutes >= time )
  useEffect(()=>{
    if (requestedRef.current && isSelected){
      onClick(time)
      requestedRef.current.scrollIntoView({behavior: 'smooth', inline: "center"});
    }
  }, [requestedRef.current, requestedMinutes])

  var hh = Math.floor(time/60); // getting hours of day in 0-24 format
  var mm = (time%60); // getting minutes of the hour in 0-55 format
  var ap = ['AM', 'PM']
  if (busyTime){
    return (
      <div
        className={cn('appointment', 'busy', {'before': time < range[0]*60}, {'after': time > range[1]*60}, {'requested': isSelected})}
        ref={requestedRef}
      >
        <div className={'appointmentTime'}>
          {("0" + numberTo12Hour(hh)).slice(-2) + ':' + ("0" + mm).slice(-2) + ap[Math.floor(hh/12)]}
        </div>
        <div className={'appointmentPatient'}>
          {`${busyTime.patient.first_name} ${busyTime.patient.last_name}`}
        </div>
      </div>
    )
  } else {
    return (
      <div
        className={cn('appointment', {'requested': isSelected})}
        ref={requestedRef}
        onClick={() => onClick(time)}
      >
        <div className={'appointmentTime'}>
          {("0" + numberTo12Hour(hh)).slice(-2) + ':' + ("0" + mm).slice(-2) + ap[Math.floor(hh/12)]}
        </div>
        <div className={'appointmentPatient'}>
          {`Available`}
        </div>
      </div>
    )
  }
}

/**
 * [AppointmentTimes description]
 * @param {array}   appointmentTimes  array time slots generated by range and interval
 * @param {int}     timeInterval      time interval for comparing appointment times
 * @param {object}  appointments      appointments indexed by seconds from start of day
 */
const AppointmentTimes = ({appointmentTimes, range, timeInterval, busyTimes, requestedMinutes, appointments, onTimeClick}) => {
  return (
    <div>
      {
        appointmentTimes.map((tm) => (
          <AppointmentTime
            key={`apptTime_${tm}`}
            time={tm}
            range={range}
            appointments={appointments}
            timeInterval={timeInterval}
            busyTime={busyTimes[tm]}
            requestedMinutes={requestedMinutes}
            onClick={onTimeClick}
          />
        ))
      }
    </div>
  )
}


const settingsMotionVariants = {
  open: { height: 'fit-content', opacity: 1},
  closed: { height: 0, opacity: 0 },
}
const AppointmentTimesSettings = ({showSettings, timeInterval, minTime, maxTime, sliderHandle, marks, handleRangeChange, handleIntervalChange}) => {
  const intlMessages = useSelector(getIntlMessages);
  //RiSettings3Fill
  //R
  return (
    <motion.div
      className={'appointments_settings'}
      animate = {showSettings ? "open" : "closed"}
      transition={{duration: 0.2}}
      variants = {settingsMotionVariants}
      >
        <div className={'appointments_settings_container'}>
          <div className={'appointments_settings_labels'}>
              <div className={'appointments_settings_timeInterval'}>
                  <div className={'appointments_settings_timeInterval_label'}>
                    {intlMessages['confirmAppointmentRequestForm.appointments.timeInterval.label']}
                  </div>
              </div>
              <div className={'appointments_settings_timeRange'}>
                  <div className={'appointments_settings_timeRange_label'}>
                    {intlMessages['confirmAppointmentRequestForm.appointments.timeRange.label']}
                  </div>
              </div>
          </div>
          <div className={'appointments_settings_inputs'}>
              <div className={'appointments_settings_timeInterval'}>
                  <Select
                    styles={{valueContainer: (provided) => {return {...provided, height: 34}}}}
                    className={'appointments_settings_timeInterval_input'}
                    onChange={inputValue => {handleIntervalChange(inputValue.value)}}
                    options={timeIntervalList}
                    isClearable={false}
                    isSearchable={false}
                    value={{'value': timeInterval, 'label': timeInterval}}
                    onBlurResetsInput={false}
                    onSelectResetsInput={false}
                  />
              </div>
              <div className={'appointments_settings_timeRange'}>
                  <div className={'appointments_settings_timeRange_input'}>
                      <Slider.Range
                        min={0} max={24}
                        marks={marks}
                        className={'appointments_settings_timeRange_input'}
                        defaultValue={[minTime, maxTime]}
                        handle={sliderHandle}
                        onAfterChange={handleRangeChange}
                        trackStyle={{ backgroundColor: '#273238' }}
                      />
                  </div>
              </div>
          </div>
        </div>
    </motion.div>
  )
}

export const AppointmentForm = ({request, onCreated}) => {
  const dispatch = useDispatch();
  const intlLocale = useSelector(getIntlLocale);
  const intlMessages = useSelector(getIntlMessages);
  const [ showSettings, setShowSettings ] = useState(false);
  const [ timeInterval, setTimeInterval ] = useState(30);
  const [ [minTime, maxTime], setTimeRange ] = useState([9,18]);
  const [ marks ,  ] = useState(defaultMarks);
  const [ appointmentTimes , setAppointmentTimes ] = useState([]);
  const [ busyTimes, setBusyTimes ] = useState({});
  // currentDay is a moment object, so value changes wont be detected by state changes
  // currentTime is time in total seconds
  const [ [currentDay, currentDateString, currentDateTimeString, currentTime], setCurrentDate] = useState([null, null, null, null])
  const [ todayAppointmentsByMins, setTodayAppointmentsByMins ] = useState(null)

  const [ defaultPatient, setDefaultPatient ] = useState(null)

  const [ appointments, setAppointments ] = useState(appointmentData);
  const [ loading, setLoading] = useState(false);
  const [ errors, setError ] = useState({});
  const [ saving, setSaving] = useState(false);
  const [ dirty, setDirty] = useState({});

  const datePlaceholder =  moment().locale(intlLocale).localeData().longDateFormat('L').replace(/\W$/, "").replace(/\W/g, "/");
  const timePlaceholder = moment().locale(intlLocale).localeData().longDateFormat('LT');

  useEffect(()=>{
    // initialize the requested date time from request
    // for new form,
    if (request){
      if (request.availability && request.availability.value){
        // convert from unix timestamp to milliseconds
        let formattedDateTime = localeFormatDate(request.availability.value*1000, intlLocale,`${datePlaceholder},H:mm`);
        let datetimeString = localeFormatDate(request.availability.value*1000, intlLocale,`${datePlaceholder} ${timePlaceholder}`);
        let [requestedDate, requestedTime] = formattedDateTime.split(",");
        let [hr, min] = requestedTime.split(":");
        let requestedMinutes = parseInt(hr)*60+parseInt(min);

        setCurrentDate(() =>
          [moment(request.availability.value*1000), requestedDate, datetimeString, requestedMinutes]
        )
      } else {
        let today = new Date();
        // default date?
        today.setMinutes(today.getMinutes() - (today.getMinutes() % timeInterval));
        let formattedDateTime = localeFormatDate(today, intlLocale,`${datePlaceholder},H:mm`);
        let datetimeString = localeFormatDate(today, intlLocale,`${datePlaceholder} ${timePlaceholder}`);
        let [requestedDate, requestedTime] = formattedDateTime.split(",");
        let [hr, min] = requestedTime.split(":");
        let requestedMinutes = parseInt(hr)*60+parseInt(min);
        setCurrentDate(() =>
          [moment(today), requestedDate, datetimeString, requestedMinutes]
        )
      }
      if (request.patient){
        setDefaultPatient({
          value: `${request.patient.unique_id}`,
          label: `${formatName(intlMessages['format.fullName'], request.patient.first_name, request.patient.last_name)}, ${localeFormatDate(request.patient.date_of_birth, intlLocale, "l")}, ${request.patient.gender}`
        })
      }
    } else {
      let today = new Date();
      // default date?
      today.setMinutes(today.getMinutes() - (today.getMinutes() % timeInterval));
      let formattedDateTime = localeFormatDate(today, intlLocale,`${datePlaceholder},H:mm`);
      let datetimeString = localeFormatDate(today, intlLocale,`${datePlaceholder} ${timePlaceholder}`);
      let [requestedDate, requestedTime] = formattedDateTime.split(",");
      let [hr, min] = requestedTime.split(":");
      let requestedMinutes = parseInt(hr)*60+parseInt(min);
      setCurrentDate(() =>
        [moment(today), requestedDate, datetimeString, requestedMinutes]
      )
    }

  }, [])

  useEffect(()=>{
    // initalize the appointments needed
    if (appointments){
      setTodayAppointmentsByMins(
        appointments.reduce((acc, curr) => {
          let formattedDate = localeFormatDate(curr.start_date, intlLocale, `${datePlaceholder},H:mm`);
          let [startDate, t] = formattedDate.split(",");
          let [hr, min] = t.split(":");
          let totalMins = parseInt(hr)*60+parseInt(min);
          acc[`${totalMins}`] = {...curr, startDate}
          return acc;
        }, {})
      )
    } else {
      setTodayAppointmentsByMins({});
    }
  }, [appointments])

  const onPatientChange = (uid) => {
    setDirty(dirty=>{
      dirty.patient = true;
      return dirty;
    })
    setDefaultPatient(uid);
  }

  const handleDayChange = (day) => {
    setDirty(dirty => {
      dirty.start_date = true;
      return dirty
    })
    if (day){

      setCurrentDate(([dt, time]) => {
        if (dt){
          dt.set('year', day.getFullYear());
          dt.set('month', day.getMonth());
          dt.set('date', day.getDate());
        } else {
          dt = moment(day);
        }
        return [dt, localeFormatDate(dt, intlLocale, datePlaceholder), localeFormatDate(dt, intlLocale, `${datePlaceholder} ${timePlaceholder}`) ,time]
      })
    }
  }

  const handleIntervalChange = (value) => {
    // TODO: update settings for user. working hours for this day
    setTimeInterval(value);
  }

  const handleRangeChange = (val) => {
    setTimeRange(val);
  }

  const handleTimeChange = (val) => {
    // time given in minutes in day

    // create moment timestamp here to send to appointmentcreate api
    // also change dt.
    consoleLog("handeTimeChange, ", val)
    setCurrentDate(([dt]) => {
        dt.set('hour', val / 60);
        dt.set('minute', val % 60);
        return [dt, localeFormatDate(dt, intlLocale, datePlaceholder), localeFormatDate(dt, intlLocale, `${datePlaceholder} ${timePlaceholder}`) , val];
    })
  }

  const handleSettingsIconClick = () => {
    consoleLog("handleSettingsIconClick ", showSettings)
    setShowSettings(old => !old);
  }

  const handleSubmit = async () => {
    // defaultPatient, currentDay, request.unique_id
    setSaving(true);
    /*const fakeSubmit = () => {
      let fakeResp = {
          "id": 2,
          "unique_id": "8d6f0ef3-1c6d-4f8b-a577-700c9faf31c7",
          "start_date": currentDay.unix(),
          "start_date_ts": currentDay.unix(),
          "remark": "",
          "request": null,
          "is_complete": false,
          "patient": {
              "id": 4,
              "name": "",
              "unique_id": `${defaultPatient && defaultPatient.value}`,
              "first_name": `${defaultPatient && defaultPatient.label.split(",")[0].split(" ")[0]}`,
              "last_name": `${defaultPatient && defaultPatient.label.split(",")[0].split(" ")[1]}`,
              "date_of_birth": "1990-03-01",
              "gender": "MALE"
          },
          "room_name": null,
          "created_at": "2021-10-12T01:27:29.446140Z"
      }
      setSaving(false);
      setDirty({});
      if (request && request.unique_id){
        dispatch(patientRequestActions.updateRequestStatus(request.unique_id, 'COMPLETE'));
      }
      dispatch(appointmentActions.addAppointmentState(fakeResp))
      onCreated && onCreated(fakeResp)
      // TODO: update the patientRequest reducer, and not just current patients request reducer
      //dispatch(patientRequestActions.updateRequestStatus(request.unique_id, 'COMPLETE'))
    }*/

    // check values
    let values = {};
    let newErrors = {};;
    if (defaultPatient && defaultPatient.value){
      values['patient'] = defaultPatient.value;
    } else {
      newErrors['patient'] = "missing"
    }
    if (currentDay){
      values['start_date'] = currentDay.unix();
    } else {
      newErrors['start_date'] = "missing"
    }
    if (request && request.unique_id){
      values['request'] = request.unique_id
    }
    if (Object.keys(newErrors).length > 0){
      consoleLog("settings new errors, ", newErrors);
      setError(newErrors);
      setDirty({});
      setSaving(false);
      return;
    }

    //setTimeout(()=>{
    //  fakeSubmit();
    //}, 1000)
    //return;

    try {
      let resp = await services.createAppointment(values);
      setSaving(false);
      setDirty({});
      if (request && request.unique_id){
        dispatch(patientRequestActions.updateRequestStatus(request.unique_id, 'COMPLETE'));
      }
      dispatch(appointmentActions.addAppointmentState(resp));
      dispatch(chatActions.sendAppointmentConfirmation(
        currentDateString,
        createPatientNotificationChannelId(resp.patient.unique_id) ,
        resp.unique_id
      )
      );
      onCreated && onCreated(resp);
      return;

    } catch(err) {
      setSaving(false);
      setDirty({});
      setError(err)
    }
    // 1. create appointment
    // 2. change request status to confirmed
    //    update request in database
    // 3. close appointment form modal
    /*
    let data = {"patient": defaultPatient.value, "start_date": currentDay.unix(), "request": request.unique_id}
    try{
      let resp = await appointmentServices.createAppointment(data);
      // update appointments
      setSaving(false);
      setDirty(false);
    } catch (err){
      setSaving(false);
      setDirty(false);
    }*/
  }

  useEffect(()=>{
    // api services to get appointemtns for current day
    if (currentDay){
      // clone so we dont change the state without setState (moment is an object so state can't detect chagnes)
      let tempDay = currentDay.clone();
      tempDay.set('hour', 0);
      tempDay.set('minute', 0);
      //setLoading(true);
      services.fetchAppointments({'query_type': 'DAY', 'q': tempDay.unix()})
        .then(
          data=> {
            setAppointments(data.appointment_list.map(appt=>{appt['start_date']=appt['start_date']*1000;return appt;}));
            setLoading(false);
          },
          error=> {
            setError(error);
            setLoading(false);
          }
        )
    }
  }, [currentDateString])

  useEffect(()=>{
    // based on timeInterval, return array of appointment times to display
    //loop to increment the time and push results in array
    if (todayAppointmentsByMins){
      var times = []; // time array
      var busyTimes = {}; // busyt times object

      var tt = minTime*60 || 0; // start time
      //var ap = ['AM', 'PM']; // AM-PM

      var addBefore = [];
      var addAfter = [];
      var appointmentTimes = [];
      Object.keys(todayAppointmentsByMins).map((time) => {
        if (parseInt(time) < minTime*60){
          // add to before object for before timeinterval
          addBefore.push(time)
          busyTimes[time] = todayAppointmentsByMins[time]
        } else if (parseInt(time) >= maxTime*60){
          // add to after object for after timeinterval
          addAfter.push(time)
          busyTimes[time] = todayAppointmentsByMins[time]
        } else {
          appointmentTimes.push(time)
        }
      })

      for (var i=0;tt<maxTime*60; i++) {
        //var hh = Math.floor(tt/60); // getting hours of day in 0-24 format
        //var mm = (tt%60); // getting minutes of the hour in 0-55 format
        //times[i] = ("0" + numberTo12Hour(hh)).slice(-2) + ':' + ("0" + mm).slice(-2) + ap[Math.floor(hh/12)]; // pushing data in array in [00:00 - 12:00 AM/PM format]
        times[i] = tt
        appointmentTimes.map((elem)=>{
          // check if appointment has same start time or start time is in between intervals
          if ( parseInt(elem) == tt
              || (parseInt(elem) >= tt && parseInt(elem) < (tt+timeInterval))){
                busyTimes[tt] = todayAppointmentsByMins[elem]
              }
        })
        tt = tt + timeInterval;
      }
      addBefore.sort();
      addAfter.sort();

      setAppointmentTimes([...addBefore,...times, ...addAfter]);
      setBusyTimes(busyTimes);
    }

  }, [todayAppointmentsByMins, timeInterval, minTime, maxTime])

  return (
    <Styles>
      <div className={'title'}>{intlMessages['confirmAppointmentRequestForm.title']}</div>
      { request && request.availability && request.availability.value &&
        <div className={'originalAvailability'}>
          <div className={'label originalAvailability_label'}>{intlMessages['confirmAppointmentRequestForm.originalAvailability.label']}{":"}</div>
          <div className={'originalAvailability_date'}>{localeFormatDate(request.availability.value*1000, intlLocale, "l LT")}</div>
        </div>
      }
      <div className={'content'}>
          <div className={'patient'}>
              <div className={'patient_label'}>
                {intlMessages['confirmAppointmentRequestForm.patient.label']}
              </div>
              <div className={cn('patient_input', {'Error': errors.patient && !dirty.patient} )}>
                <PatientsSearch error={errors.patient && !dirty.patient} defaultValue={defaultPatient} onPatientChange={onPatientChange}/>
              </div>
          </div>

          <div className={'requestTime'}>
              <div className={'requestTimeLabel'}>
                {intlMessages['confirmAppointmentRequestForm.requestTime.label']}
              </div>
              <div className={'requestTimeValue'}></div>
          </div>
          <div className={'timePickers'}>
              <div className={cn('timePickers_date', {'Error': errors.start_date && !dirty.start_date})}>
                  <DayPickerInput
                    format={datePlaceholder}
                    value={currentDateString}
                    placeholder={datePlaceholder}
                    formatDate={formatDate}
                    parseDate={parseDate}
                    keepFocus={false}
                    locale= {intlLocale}
                    localeUtils= {MomentLocaleUtils}
                    onDayChange={handleDayChange}
                  />
              </div>
          </div>
          <div className={'appointments'}>
              <div className={'label appointments_label'}>
                <div>{intlMessages['confirmAppointmentRequestForm.appointments.label']}</div>
                <div className={'appointments_settings_icon'} onClick={handleSettingsIconClick}>
                  <RiSettings3Fill />
                  <span>{intlMessages['confirmAppointmentRequestForm.appointments.label.settings']}</span>
                </div>
              </div>
              <AppointmentTimesSettings
                showSettings={showSettings}
                timeInterval={timeInterval}
                minTime={minTime}
                maxTime={maxTime}
                sliderHandle={sliderHandle}
                handleIntervalChange={handleIntervalChange}
                handleRangeChange={handleRangeChange}
                marks={marks}
                />
              <div className={'appointments_content'}>
                <AppointmentTimes
                  appointmentTimes={appointmentTimes}
                  busyTimes={busyTimes}
                  range={[minTime, maxTime]}
                  appointments={todayAppointmentsByMins}
                  requestedMinutes={currentTime}
                  timeInterval={timeInterval}
                  onTimeClick={handleTimeChange}
                />
            </div>
          </div>
          <div className={'appointmentConfirmation'}>
              <div className={'appointmentConfirmation_label'}>
                <div>{intlMessages['confirmAppointmentRequestForm.appointmentConfirmation.label']}</div>
                <div>{currentDateTimeString}</div>
              </div>
              <div className={'appointmentConfirmation_content'}>
                <div>
                  <StyledButton disabled={loading || saving} onClick={handleSubmit}>
                    <span>{intlMessages['confirmAppointmentRequestForm.appointmentConfirmation.button.label']}</span>
                  </StyledButton>
                </div>
              </div>
          </div>
      </div>
    </Styles>
  )
}
