import { create } from 'zustand';

export const useMediaStore = create((set) => ({
    videoFrames: {},
    imageIndex: 0,
    videoPlayer: {},
    isDetectionShowing: true,
    isDetectionLabelShowing: false,
    loadingVideoFrames: false,
    setVideoFrames: (videoFramesVal) => 
        set((state) => ({
            ...state,
            videoFrames: videoFramesVal
        })),
    setImageIndex: (imageIndexVal) => 
        set((state) => ({
            ...state,
            imageIndex: imageIndexVal
        })),
    setVideoPlayer: (videoPlayer) => 
        set((state) => ({
            ...state,
            videoPlayer: Object.assign({}, state.videoPlayer, videoPlayer)
        })),
    setLoadingVideoFrames: (loadingVideoFramesVal) => 
        set((state) => ({
            ...state,
            loadingVideoFrames: loadingVideoFramesVal
        })),
    setIsDetectionShowing: (isDetectionShowingVal) => 
        set((state) => ({
            ...state,
            isDetectionShowing: isDetectionShowingVal
        })),
    setIsDetectionLabelShowing: (isDetectionLabelShowingVal) => 
        set((state) => ({
            ...state,
            isDetectionLabelShowing: isDetectionLabelShowingVal
        })),
}));