import { createSelector } from 'reselect';

import { getCurrentRequestId } from '../../PatientDetailsPage/_redux/selectors';

import { find } from 'lodash';

import moment from 'moment';

const getAppointmentsSlice = (state) => state.vidChatAppointments;
const getOpenRequestsSlice = (state) => state.openRequests;

export const getAppointmentsById = createSelector([getAppointmentsSlice], (appointments) => {
  return appointments.byId;
});

export const getLoadingState = createSelector([getAppointmentsSlice], (appointments) => {
  return appointments.loading;
});

export const getErrorState = createSelector([getAppointmentsSlice], (appointments) => {
  return appointments.error;
});

export const getCurrentVideoChatSession = createSelector([getAppointmentsSlice], (state) => {
  return state.currentSession;
});

export const getCurrentVideoChatSessionRequestId = createSelector(
  [getCurrentVideoChatSession],
  (session) => {
    return session.requestId;
  }
);

export const getCurrentVideoChatSessionId = createSelector(
  [getCurrentVideoChatSession],
  (session) => {
    return session.sessionId;
  }
);

const checkUpcomingAppointment = (entry) => {
  if (entry.extra_data && entry.extra_data.video_chat && entry.extra_data.video_chat.chat_date) {
    return moment(entry.extra_data.video_chat.chat_date).isValid() && entry.type === 'VIDEOCHAT';
  }
  return false;
};

// get appointmetn by patient

export const getCurrentAppointmentRequest = createSelector(
  [getCurrentRequestId, getAppointmentsById],
  (requestId, appointments) => {
    return requestId
      ? find(appointments, { request_unique_id: requestId })
        ? convertToAppointmentModel(find(appointments, { request_unique_id: requestId }))
        : null
      : null;
  }
);

const convertToAppointmentModel = (entry) => {
  const addKeys = {};
  addKeys['title'] = `${entry.patient.first_name} ${entry.patient.last_name}`;
  addKeys['start_date'] = entry.start_date * 1000; // unix to milliseconds
  return Object.assign({}, entry, addKeys);
};

export const getUpcomingAppointments = createSelector([getAppointmentsById], (appointments) => {
  return appointments
    ? Object.values(appointments)
        .map((entry) => {
          return convertToAppointmentModel(entry);
        })
        .sort((a, b) => {
          if (a.start_date == b.start_date) {
            return 0;
          } else if (a.start_date > b.start_date) {
            return 1;
          } else {
            return -1;
          }
        })
    : [];
});

export const getUpcomingAppointmentsOld = createSelector([getAppointmentsById], (appointments) => {
  return appointments
    ? Object.values(appointments)
        .filter((data) => checkUpcomingAppointment(data))
        .map((entry) => {
          return convertToAppointmentModel(entry);
        })
        .sort((a, b) => {
          if (a.video_chat_date.isSame(b.video_chat_date)) {
            return 0;
          } else if (a.video_chat_date.isAfter(b.video_chat_date)) {
            return 1;
          } else {
            return -1;
          }
        })
    : [];
});

export const getOpenRequestById = createSelector([getOpenRequestsSlice], (requests) => {
  return requests.byId;
});

export const getOpenRequests = createSelector([getOpenRequestById], (requests) => {
  return requests
    ? Object.values(requests)
        .filter((data) => data.type === 'VIDEOCHAT')
        .map((entry) => {
          // dependent on filter....
          return entry;
        }) //.sort( (a,b) => {
    : //if (a)
      //}
      [];
});
