import { createSelector } from 'reselect';

const getPatientReportsSlice = (state) => state.patientReports;

export const getPatientReportsLoading = createSelector([getPatientReportsSlice], (state) => {
  return state.loading;
});

export const getPatientReportsError = createSelector([getPatientReportsSlice], (state) => {
  return state.error;
});

export const getPatientCreateReportsLoading = createSelector([getPatientReportsSlice], (state) => {
  return state.createLoading;
});

export const getPatientCreateReportsError = createSelector([getPatientReportsSlice], (state) => {
  return state.crateError;
});

export const getPatientReportById = createSelector([getPatientReportsSlice], (state) => {
  return state.reports;
});

export const getPatientReportChartsById = createSelector([getPatientReportsSlice], (state) => {
  return state.reportCharts;
});
