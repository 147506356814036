import React, { useEffect, useState } from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { useSelector, useDispatch} from 'react-redux';
import cn from 'classnames';
import moment from 'moment';
import { upperFirst, range, toUpper, toLower, startCase  } from 'lodash';

import BeatLoader from 'react-spinners/BeatLoader';

import { useQuerySearchParams } from '../_hooks/useSearchParams';
import { history, localeFormatDate, formatName } from '../_helpers';

// redux state
import { actions } from './_redux/actions';
import { services } from './_redux/services';
import {
  getLoadingState,
  getOpenRequestReports,
  getOpenRequestAppointments,
  getIsLoadingMore,
  getQueryParamsOffset,
  getisLoadMore
} from './_redux/selectors';

import { MdPlaylistAddCheck, MdOutlineExpandMore, MdOutlineAdd } from 'react-icons/md';

// components
import { LoadingEllipsis } from '../_components/Loaders';
import { DentistAdapterInline } from '../_components/FormElements';
import { Box, Tabs, TabList, Grid, 
    Text, Button, Flex,
    Tab, Tooltip, Skeleton, useToast } from '@chakra-ui/react'

// actions
import { actions as dentistActions } from '../Dentists/_redux/actions';

// selectors
import { getIntlMessages, getIntlLocale, getProfileIsStaff } from '../App/_redux/selectors';
import { getSearchDentistsLoading, getDentistsByCountry } from '../Dentists/_redux/selectors';

// styles

import {
  WrappedPage,
  StyledOpenRequestAppointmentListColumn,
  StyledOpenRequestReportListColumn,
  StyledButton,
  StyledPageTitle,
  StyledRequestListContainer,
  StyledRequestItem,
  StyledNoRequests,
  StyledRequestAppointmentListContainer,
  StyledRequestAppointmentItem,
  StyledContainer
} from './styles';


/**
 * If Admin, accept button will be Assign Button to dentist with a select field for country's dentists
 * 
 */
const AcceptButton = ({ 
  handleClick, status, country, disabled=null, requestId=null, loading=false 
}) => {
  const dispatch = useDispatch();
  const intlMessages = useSelector(getIntlMessages);
  const isStaff = useSelector(getProfileIsStaff);
  const dentistsByCountry = useSelector(getDentistsByCountry)
  const dentistsByCountryIsLoading = useSelector(getSearchDentistsLoading);
  const [currentCountry, ] = useState(country);

  const [currentDentist, setCurrentDentist] = useState(null);

  useEffect(() => {
    if (isStaff) {
      if (dentistsByCountryIsLoading[currentCountry] !== true) {
        if (dentistsByCountry[currentCountry] === undefined) {
          dispatch(dentistActions.searchDentists('', currentCountry));
        }
      }
    }
  }, [
    currentCountry,
    dentistsByCountryIsLoading[currentCountry],
    dentistsByCountry[currentCountry],
    isStaff,
    dispatch,
  ]);

  if (requestId){
    return (
      <StyledButton>
        <Link to={`/request/${requestId}`}>
          {toUpper(intlMessages['requestListPage.openRequestList.header.action.accepted'])}
        </Link>
      </StyledButton>
    )
  } else {
    if (isStaff){
      // adjust dentist list label to add email, so that its searchable in case name is weird
      return (
        <>
          <div style={{alignSelf: 'center'}}>{country}</div>
          <Box minWidth={'220px'} pr={'20px'}>
            { (!dentistsByCountryIsLoading[currentCountry]) 
              ? <BeatLoader color={'#d8dddf'} loading={true} size={8} />
              : <DentistAdapterInline 
                items={(dentistsByCountry[toLower(country)] || []).map(elem => {
                  elem.label = `${formatName(intlMessages['format.fullName'], 
                                  elem.first_name, elem.last_name)}, ${elem.email}`;
                  return elem;
                })}
                onChange = {(val) => setCurrentDentist(val)}
                placeholder={intlMessages['requestListPage.openRequestList.dentist.select.placeholder']}
                isDisabled={status !== 1 || loading}
              />
            }
          </Box>
          <Box display={'flex'}>
            <StyledButton 
              onClick={() => handleClick(currentDentist)} 
              disabled={status !== 1 || !currentDentist || disabled}>
              {
                (loading || !status)
                  ?  <BeatLoader color={'#d8dddf'} loading={loading} size={8} />
                  : (status == 3)
                      ? toUpper(intlMessages['requestListPage.openRequestList.header.action.accepted'])
                      : (isStaff) 
                        ? toUpper(intlMessages['requestListPage.openRequestList.header.action.assign'])
                        : toUpper(intlMessages['requestListPage.openRequestList.header.action.accept'])
              }
            </StyledButton>
          </Box>
        </>
      )
    }
    return (
        <StyledButton onClick={() => handleClick(null)} disabled={status != 1 || disabled}>
          {
            (!status)
              ? <BeatLoader color={'#d8dddf'} loading={loading} size={8} />
              : (status == 3)
                  ? toUpper(intlMessages['requestListPage.openRequestList.header.action.accepted'])
                  : toUpper(intlMessages['requestListPage.openRequestList.header.action.accept'])
          }
        </StyledButton>
    )
  }
}


const OpenRequestAppointmentListItem = ({prevappointment, appointment}) => {
  const dispatch = useDispatch();
  const intlLocale = useSelector(getIntlLocale);
  const intlMessages = useSelector(getIntlMessages);
  const toast = useToast();
  const [ loading, setLoading ] = useState(false);
  let apptDate = appointment.availability && appointment.availability.value
  let prevApptDate = prevappointment && prevappointment.availability && prevappointment.availability.value;
  let label = null;

  if (prevappointment ==  null){
    // first item
    if (apptDate == null){
      label = "When Available";
    } else {
      label = localeFormatDate(moment(apptDate*1000), intlLocale, 'ddd MMM Do YYYY');
    }
  } else {
    if (!moment(apptDate*1000).isSame(moment(prevApptDate*1000), 'date')){
      // set label for date
      label = localeFormatDate(moment(apptDate*1000), intlLocale, 'ddd MMM Do YYYY')
    } else {
      label = null;
    }
  }

  // if currentDate is != apptDate, new date so add a label
  // and set next day
  //
  //
  const handleAcceptClick = (chatDate, dentist) => {
    setLoading(true);

    services.acceptOpenRequestVideoChat(appointment.id, {"chat_date": moment(chatDate*1000).format(), dentist })
      .then(
        () => {
          // either get the request id and create a Link or
          // remove from list
          dispatch(actions.updateOpenRequest({
            id:appointment.id, status: 3, requestId: appointment.requestId
          }));
          setLoading(false);
        },
        () => {
          setLoading(false);
          toast({
            title: 'Error',
            description: 'Error Assigning Dentist',
            status: 'error',
            duration: 10000,
            isClosable: true
          })
        }
      )
  }

  return(
    <React.Fragment>
      {label && <div className={'dateLabel'}>{label}</div>}
      <StyledRequestAppointmentItem>
        <div className={'information'}>
          <div className={'appointmentDate'}>
            {(apptDate)
                ? localeFormatDate(apptDate * 1000, intlLocale, 'h:mm a')
                : "When Avaialble"
            }
          </div>
          <div className={'appointmentRemark'}>
            <span className={'label'}>
              {startCase(intlMessages['requestListPage.requestList.header.remark'])}
            </span>
            <span className={'description'}>{appointment.remark}</span>
          </div>
        </div>
        <div className={'action'}>
          <AcceptButton
            handleClick={(dentist) => handleAcceptClick(apptDate, dentist)}
            status={appointment.status}
            disabled = {apptDate == null}
            country={appointment.country}
            loading={loading}
            requestId={appointment.requestId}
          />
        </div>
      </StyledRequestAppointmentItem>
    </React.Fragment>
  )
}

const OpenRequestAppointmentList = ({loading}) => {
  const intlMessages = useSelector(getIntlMessages);
  const appointments = useSelector(getOpenRequestAppointments);
  if (loading){
    return (
      <StyledOpenRequestAppointmentListColumn>
        <StyledRequestAppointmentListContainer>
          {range(3).map(ind => (
            <Skeleton key={ind} height={68} w="100%" />
          ))}
        </StyledRequestAppointmentListContainer>
      </StyledOpenRequestAppointmentListColumn>
    )
  }

  return (
    <StyledOpenRequestAppointmentListColumn>
      <StyledRequestAppointmentListContainer>
        <div className={'openRequestAppointments'}>
          { (appointments.length > 0)
            ? appointments.map((elem, index, arr) => (
              <OpenRequestAppointmentListItem
                key={elem.id}
                  prevappointment={index > 0 ? arr[index - 1] : null} appointment={elem}
              />
            ))
            : <StyledNoRequests style={{paddingTop:20}}>
                  {startCase(intlMessages['requestListPage.openRequestList.norequests.message'])}
              </StyledNoRequests>
          }
        </div>
      </StyledRequestAppointmentListContainer>
    </StyledOpenRequestAppointmentListColumn>
  )
}

const OpenRequestItem = ({ requestFragment }) => {
  const intlMessages = useSelector(getIntlMessages);
  const dispatch = useDispatch();
  const toast = useToast();
  const [loading, setLoading] = useState(false);

  const handleAcceptClick = async (dentist) => {
    setLoading(true);
    services.acceptOpenRequest(requestFragment.id, dentist ? {dentist} : {})
    .then(
      () => {
        // either get the request id and create a Link or
        // remove from list
        dispatch(actions.updateOpenRequest({
            id:requestFragment.id, status: 3, requestId: requestFragment.requestId
        }))
        setLoading(false);
      },
      () => {
        setLoading(false);
        toast({
          title: 'Error',
          description: 'Error Assigning Dentist',
          status: 'error',
          position: 'top',
          duration: 1200,
          isClosable: true
        });
        // TODO: alert error.
      }
    )
  }

  return (
    <StyledRequestItem>
      <div>{intlMessages[`openRequest.item.record_type.${requestFragment.record_type}`]}</div>
      <div>{requestFragment.estimated_age || '-'}</div>
      <div>{localeFormatDate(requestFragment.created_at, 'en', 'lll')}</div>
      <Tooltip label={requestFragment.remark}>
        <div className={cn('ellipsisText')}>{requestFragment.remark}</div>
      </Tooltip>
      <div>
        { loading
          ? <div className={'loading'}><LoadingEllipsis /></div>
          : intlMessages[`openRequest.status.text.${requestFragment.status}`]
        }
      </div>
      <div className={cn('actions')}>
        <AcceptButton
          handleClick={handleAcceptClick}
          status={requestFragment.status}
          country={requestFragment.country}
          disabled={loading}
          loading={loading}
          requestId={requestFragment.requestId}
        />
      </div>
    </StyledRequestItem>
  )
}

const OpenRequestReportHeader = () => {
  const intlMessages = useSelector(getIntlMessages);
  return (
    <Grid 
      templateColumns={'1fr 1fr repeat(3, 2fr) 4fr'}
      gap={'10px'}
      padding={'0px 30px'}
    > 
      <Text>{toUpper(intlMessages['requestListPage.openRequestList.header.record_type'])}</Text>
      <Text>{toUpper(intlMessages['requestListPage.openRequestList.header.estimated_age'])}</Text>
      <Text>{toUpper(intlMessages['requestListPage.openRequestList.header.requested_on'])}</Text>
      <Text>{toUpper(intlMessages['requestListPage.openRequestList.header.remark'])}</Text>
      <Text>{toUpper(intlMessages['requestListPage.openRequestList.header.status'])}</Text>
      <Text>{''}</Text>
    </Grid>
  )
}

const OpenRequestReportList = ({loading}) => {
  const intlMessages = useSelector(getIntlMessages);
  const requests = useSelector(getOpenRequestReports);

  const onClick = () => {
    history.push("/");
  }

  if (loading){
    return (
      <StyledOpenRequestReportListColumn>
        <StyledRequestListContainer>
          <OpenRequestReportHeader />
          {range(3).map(ind => (
            <Skeleton key={ind} height={68} w="100%" />
          ))}
        </StyledRequestListContainer>
      </StyledOpenRequestReportListColumn>
    )
  }
  return(
    <StyledOpenRequestReportListColumn>
      <StyledRequestListContainer>
        <OpenRequestReportHeader />
        {(requests.length > 0)
          ? requests.map((request) => (
              <OpenRequestItem
                key={request.id}
                requestFragment={request}
              />
            ))
          : 
              <Flex direction={'column'} align={'center'} mt={10}>
                <MdPlaylistAddCheck size={42} fill={'#7A8F99'} />
                <Text mt={3}>{upperFirst(intlMessages['requestListPage.openRequestList.norequests.title'])}</Text>
                <Text color={'none.500'}>{intlMessages['requestListPage.openRequestList.norequests.description']}</Text>
                <Button 
                  leftIcon={<MdOutlineAdd size={18} />} 
                  mt={6} 
                  fontSize={14} 
                  variant="solid" 
                  colorScheme={'bdBlue'}
                  onClick={() => onClick()}
                >
                  {'Open Patient Requests'}
                </Button>
              </Flex>

        }
        <LoadMore />
      </StyledRequestListContainer>
    </StyledOpenRequestReportListColumn>
  )
}

export const PageTabs = ({tab, handleTabChange}) => {
  const intlMessages = useSelector(getIntlMessages);
  const tabList = ['reports', 'appointments']

  const handleTabsChange = (index) => {
    handleTabChange(tabList[index])
  }

  const selectedCss = {
    color: 'white', 
    bg: 'brand.500',
    cursor: 'default'
  }

  return (
    <Tabs 
      index={((tabList.indexOf(tab) != -1) ? tabList.indexOf(tab) : 0)} 
      size={"lg"} 
      variant='unstyled' 
      onChange={handleTabsChange}
    >
      <TabList >
        {
          ['reports', 'appointments'].map(tabLabel => (
            <Tab 
              key={tabLabel}
              _selected={selectedCss} 
              minWidth={'120px'} 
              boxShadow={'0px 5px 15px 5px rgb(0 0 0 / 5%)'}
              sx={{
                ':not([aria-selected="true"]):hover': {
                  transform: 'translateY(2px)',
                  cursor: 'pointer',
                }
              }}
              borderRadius={'10px'} 
              fontSize={'24px'} 
              mx={4}
              minW={'120px'}
              py={4} 
              px={8}
            >
              {upperFirst(intlMessages[`requestListPage.openRequestList.pagetab.${tabLabel}`])}
            </Tab>
          ))
        }
      </TabList>
    </Tabs>
  )
}

export const PageTabContent = ({loading, tab}) => {
  if (tab == 'appointments'){
    return (
      <OpenRequestAppointmentList loading={loading}/>
    ) 
  } else {
    return (
      <OpenRequestReportList loading={loading} />
    )
  }
}


export const LoadMore = () => {
  const dispatch = useDispatch();
  const intlMessages = useSelector(getIntlMessages);
  const isLoadingMore = useSelector(getIsLoadingMore);
  const queryParamsOffset = useSelector(getQueryParamsOffset);
  const isLoadMore = useSelector(getisLoadMore);
  const searchParams = useQuerySearchParams();

  const fetchMore = () => {
    let typ = toLower(searchParams.get('typ') || 'REPORTS');
    dispatch(actions.fetchRequestsMore(typ, queryParamsOffset+1))
  }

  if (isLoadingMore){
    return (
      <Skeleton height={83} w="100%" />
    )
  } else if (isLoadMore){
    return (
        <Flex 
        justify={'center'} 
        align={'center'} 
        width={'100%'}
        >
        <Button 
          leftIcon={<MdOutlineExpandMore size={24} />} 
          mt={4} 
          fontSize={14} 
          variant="solid" 
          colorScheme={'bdBlue'}
          onClick={() => fetchMore()}
        >
          {toUpper(intlMessages['requestListPage.requestList.loadmore'])}
        </Button>
        </Flex>
    )
  } else {
    return (
      <></>
    )
  }
}

export const OpenRequestsPage = () => {
  const dispatch = useDispatch();
  const intlMessages = useSelector(getIntlMessages);
  const history = useHistory();
  const loading = useSelector(getLoadingState);
  const isStaff = useSelector(getProfileIsStaff);
  const searchParams = useQuerySearchParams();

  let typ = toLower(searchParams.get('typ') || 'REPORTS');

  useEffect(() => {
    dispatch(actions.fetchRequests(typ));
  }, [typ])

  /*
  useEffect(() => {
    // country being empty vs not loaded yet.
    // TODO: check if country is empty vs profile not loaded yet
    if (!!country && isStaff){
      dispatch(dentistActions.searchDentists('', country));
    }
  }, [country, isStaff])*/

  const updateTab = (tab) => {
    let newParams = new URLSearchParams();
    newParams.set('typ', toUpper(tab))
    history.push({
      pathname: history.pathname,
      search: "?" + newParams.toString()
    })
  }

  if (!isStaff){
    return <Redirect to={'/'} />
  }

  return (
    <WrappedPage>
      <StyledPageTitle>
        {startCase(intlMessages['requestListPage.openRequests.title'])}
      </StyledPageTitle>
      <StyledContainer>
      <PageTabs tab={toLower(typ)} handleTabChange={updateTab} />
        <PageTabContent loading={loading} tab={toLower(typ)} />
      </StyledContainer>
    </WrappedPage>
  )
}
