import React, {  useState, useRef, useEffect }  from 'react';
import styled from 'styled-components';
import DailyIframe from '@daily-co/daily-js';

import EndCallIcon from '../../_images/icons/EndCallIcon.svg'

const VideoChatStyled = styled.div`
background-color:black;
height: 100%;
`;

const StyledVideoChatButtons = styled.div`
  width: 100%;
  display:flex;
  justify-content: center;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  position:absolute;
  bottom: 1px;
  margin: auto;


  .button{
    background: #FFFFFF;
    margin: 20px;
    width: 40px;
    height: 40px;
    border-radius: 50px;
    display:${props => props.isHidden ? 'none' : 'flex'};
    align-items:center;
    justify-content:center;
    border: 1px solid #273238;
    box-sizing: border-box;

    &:hover{
      cursor:pointer;
      opacity: 0.8;
    }
  }
`;

const EndToggleIcon = ({onClick}) => {
  return (
    <div className="button" onClick={onClick}><img src={EndCallIcon} /></div>
  )
}

const VIDEOCALL_STATUS = {
  JOINED: 'JOINED',
  LEFT: 'LEFT'
}

export const VideoChat = ({room, token, description, onJoined, onLeave}) => {
  const [ error, setError ] = useState(null);
  const [ status, setStatus ] = useState(null)
  const daily = useRef(null); // daily callObject
  const el = useRef(null);

  useEffect(()=>{
    // set up daily iframe
    daily.current = DailyIframe.createFrame( {
      showParticipantsBar: false,
      showLeaveButton: true,
      iframeStyle: {
        position: 'fixed',
        width: '100%',
        height: '100%',
        top: 0
      }
    });
    // eslint-disable-next-line no-unused-vars
    daily.current.on('joined-meeting', ( _ ) => {
      setStatus(VIDEOCALL_STATUS.JOINED)
      onJoined()
    })
    // eslint-disable-next-line no-unused-vars
    daily.current.on('left-meeting', ( _ ) => {
      setStatus(VIDEOCALL_STATUS.LEFT)
      onLeave()
    })
    daily.current.join({
      url: `https://beforedent.daily.co/${room}`,
      token: token
    })
    return () => {};
  }, [])

  // eslint-disable-next-line no-unused-vars
  const onError = (err) => {
    setError(`Failed to connect: ${err.message}`);
  }
  if (error ) {
    return (
      <div>{error }</div>
    )
  } else {
    return (
      <VideoChatStyled ref={el}>
        <div style={{'color': 'white', 'padding': 15}}>{description}</div>
        <StyledVideoChatButtons isHidden={status != VIDEOCALL_STATUS.JOINED}>
          <EndToggleIcon onClick={onLeave} />
        </StyledVideoChatButtons>
      </VideoChatStyled>
    )
  }
}
