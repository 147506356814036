import React, {useEffect, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Modal from 'react-modal';
import styled from 'styled-components';
import { toUpper, lowerCase, startCase, upperFirst  } from 'lodash';
import cn from 'classnames';
import moment from 'moment';

import { FormattedMessage } from 'react-intl';

import {formatName, formatString, localeFormatDate } from '../_helpers';

// components
import { LoadingEllipsis } from '../_components/Loaders';
import { PatientNotes as PatientNotesinModal } from '../PatientNotes';
import { CloseIcon as CloseSvg } from '../_images/icons/CloseIcon';

import { MdOutlineStickyNote2 } from 'react-icons/md';
import { Flex, Text, Skeleton } from '@chakra-ui/react';

//redux state
import { actions } from './_redux/actions';
import {
  getCurrentPatientNotes,
  getCurrentPatientNotesLoading,
  //getCurrentPatientNotesIsMore,
  getPatientNotesModalOpen
} from './_redux/selectors';


// actions
//import { actions as patientActions } from '../Patients/_redux/actions';
import { actions as noteActions } from '../PatientNotes/_redux/actions';
import { actions as dentistActions } from '../Dentists/_redux/actions';

// selectors
import { getCurrentPatient, getPatientInformation  } from '../Patients/_redux/selectors';
import { getIntlMessages, getIntlLocale, getProfileIsStaff } from '../App/_redux/selectors';

  // styles
import { StyledHeader } from './_common/styles';

//styles
import {
  generalModalStyles,
  ModalCloseIcon,
  ModalStyles
} from '../_css/styles';
import { consoleLog } from '../_helpers/consoleLog';
import { getDentistsById, getDentistsLoading } from '../Dentists/_redux/selectors';
import { Link } from 'react-router-dom';
import { GenderText } from 'src/_components/LabelFormats/Gender';

const StyledAddNewButton = styled.div`
  font-family: 'Proxima Nova Bold';
  font-size: 14px;
  color: #7A8F99;
  padding: 0 10px;

  &:hover{
    cursor:pointer;
  }
`;

const AddNewButton = ({handleClick}) => {
  return (
    <StyledAddNewButton onClick={handleClick}>
      <FormattedMessage 
        id={'patientSection.notes.add.button.text'}
        defaultMessage={'+ Add'}
      />
    </StyledAddNewButton>
  )
}


const PatientNoteItem = ({note, setIsAddNote}) => {
  const dispatch = useDispatch();
  const locale = useSelector(getIntlLocale);

  const handleClick = () => {
    setIsAddNote();
    dispatch(actions.openPatientNotesModal());
  }

  return(
    <div className={'patientNoteItem'} onClick={handleClick}>
      <div className={'patientNoteContent'}><span>{note.text}</span></div>
      <div className={'patientNoteItemDate'}><span>{localeFormatDate(note.created_at, locale, "MMM DD" )}</span></div>
    </div>
  )
}

const PatientNotes = ({setIsAddNote}) => {
  const dispatch = useDispatch();
  const intlMessages = useSelector(getIntlMessages);
  const currentPatient = useSelector(getCurrentPatient);
  const currentPatientNotes = useSelector(getCurrentPatientNotes);
  //const currentPatientNotesIsMore = useSelector(getCurrentPatientNotesIsMore);
  const loading = useSelector(getCurrentPatientNotesLoading);
  //const requests = useSelector(getPatientRequests)

  useEffect(() => {
    consoleLog("currentpatient " + currentPatient)
    if (currentPatient){
      dispatch(noteActions.fetchPatientNotes(currentPatient));
    }
  }, [currentPatient])

  const addNewClick = () => {
    setIsAddNote(true)
    dispatch(actions.openPatientNotesModal());
  }

  if (loading){
    return (
      <div className={'patientNote'}>
        <div className={'columnTitle'}>
          <span>{startCase(intlMessages['requestDetailPage.header.patient.notes.title'])}</span>
          <AddNewButton handleClick={addNewClick}/>
        </div>
        <LoadingEllipsis />
      </div>
    )
  } else {
    return(
      <div className={'patientNote'}>
        <div className={'columnTitle'}>
          <span>{startCase(intlMessages['requestDetailPage.header.patient.notes.title'])}</span>
          <AddNewButton handleClick={addNewClick}/>
        </div>
        <div className={'columnContent'}>
          {currentPatientNotes.map((note) => (
            <PatientNoteItem
              key={note.id}
              setIsAddNote={setIsAddNote}
              note={note}
              />
          ))}
          { currentPatientNotes.length < 1 &&
              <Flex direction={'column'} align={'center'} justify={'center'}>
                <MdOutlineStickyNote2 size={26} fill={'#7A8F99'} style={{margin: 0}} />
                <Text fontSize={16} mt={3}>{upperFirst(intlMessages['requestDetailPage.header.patient.notes.blank'])}</Text>
              </Flex>
          }
        </div>
      </div>
    )
  }

}

const modalStyles = {...generalModalStyles};
modalStyles.content.maxHeight = '60%';

const PatientNotesModal = ({focused=false}) => {
  const dispatch = useDispatch();
  const modalIsOpen = useSelector(getPatientNotesModalOpen)
  const currentPatient = useSelector(getCurrentPatient);
  const currentPatientNotes = useSelector(getCurrentPatientNotes);
  const loading = useSelector(getCurrentPatientNotesLoading);

  const afterOpenModal = () => {
    /* all refs are set*/
  }

  const closeModal = () => {
    dispatch(actions.closePatientNotesModal())
  }

  return (
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={modalStyles}
      shouldCloseOnOverlayClick={false}
      contentLabel="Patient Notes"
    >
      <ModalStyles>
        <ModalCloseIcon onClick={closeModal} ><CloseSvg/ ></ModalCloseIcon>
        <PatientNotesinModal 
            patientUid={currentPatient}
            notes={currentPatientNotes} 
            focused={focused}  
            loading={loading} 
        />
      </ModalStyles>
    </Modal>
  )
}

const ProfileAvatar = ({src, initials, isLoaded}) => {
  if (!isLoaded){
    return (
      <Skeleton>
        <div className='patientAvatar'>
          <img  />
        </div>
      </Skeleton>
    )
  }
  if (src && src.length > 0){
    return (
      <div className='patientAvatar'>
        <img src={src} />
      </div>
    )
  } else {
    return (
      <div className='patientAvatar'>
        <div className='initialsAvatar'>
          {initials}
        </div>
      </div>
    )
  }
}

const ListItemParent = ({relationship,  parent}) => {
  const intlMessages = useSelector(getIntlMessages);

  const related_to = parent
    ? ` ${formatName(intlMessages['format.fullName'], parent.first_name, parent.last_name) }`
    : ''

  if (parent == null){
    return <div></div>
  }

  return (
    <Flex>
      {
        related_to 
        ? <Link to={`/patients/${parent.unique_id}`}>{formatString(intlMessages['format.relationshipTo'], upperFirst(lowerCase(relationship)), related_to)}</Link> 
        : '' 
      }
    </Flex>
  )
}

export const PatientSection = () => {
  const dispatch = useDispatch();
  const intlMessages = useSelector(getIntlMessages);
  const intlLocale = useSelector(getIntlLocale)
  const patientDetails = useSelector(getPatientInformation);
  const isStaff = useSelector(getProfileIsStaff);
  const dentistsById = useSelector(getDentistsById);
  const dentistsLoading = useSelector(getDentistsLoading);

  const [ isAddNote, setIsAddNote ] = useState(false);

  useEffect(() => {
    // fetch dentist if not found in byId
    if (isStaff && patientDetails?.dentist !== undefined){
      if (dentistsLoading[patientDetails?.dentist] !== true && dentistsById[patientDetails?.dentist] === undefined){
        dispatch(dentistActions.fetchDentistDetails(patientDetails?.dentist));
      }
    }
  }, [patientDetails, dentistsById, dentistsLoading, isStaff])

  const dentist = dentistsById[patientDetails?.dentist];
  

  return (
    <StyledHeader>
      <div className={'patientAvatarWrapper'}>
        <div className={'columnTitle'} style={{visibility:'hidden'}}>
          {'Avatar'}
        </div>
        <ProfileAvatar
          isLoaded={patientDetails?.first_name}
          src={patientDetails.avatar}
          initials={(!patientDetails.first_name && "B") || patientDetails.first_name[0]}
          />
      </div>
      <div className={'patientSection'}>
        <div className={'columnTitle'}>
          <div>{startCase(intlMessages['requestDetailPage.header.patient.information.title'])}</div>
        </div>
        <div className={'patientSummaryWrapper'}>
          <div className={cn("patientColumn", "patientInformation")}>
            <div className={'informationRow'}>
                <div>{toUpper(intlMessages['requestDetailPage.header.patient.name'])}</div>
                <Skeleton minWidth='250px' w='fit-content' isLoaded={patientDetails?.first_name !== undefined}>
                    <div>{patientDetails.first_name}{' '}{patientDetails.last_name}</div>
                </Skeleton>
              </div>
              <div className={'informationRow'}>
                <div>{toUpper(intlMessages['requestDetailPage.header.patient.birthdate'])}</div>
                <Skeleton minWidth='250px' w='fit-content' isLoaded={patientDetails?.first_name !== undefined}>
                  <div>{localeFormatDate(patientDetails.date_of_birth, intlLocale) + ` (${moment().diff(patientDetails.date_of_birth, 'years')})`}</div>
                </Skeleton>
              </div>
              <div className={'informationRow'}>
                <div>{toUpper(intlMessages['requestDetailPage.header.patient.gender'])}</div>
                <Skeleton minWidth='250px' w='fit-content' isLoaded={patientDetails?.first_name}>
                  <div><GenderText gender={patientDetails?.gender} /></div>
                </Skeleton>
              </div>
              <div className={'informationRow'}>
                <div>{toUpper(intlMessages['requestDetailPage.header.patient.parent'])}</div>
                <Skeleton minWidth='250px' w='fit-content' isLoaded={patientDetails?.parent !== undefined}>
                  <ListItemParent 
                    relationship={patientDetails.relationship}
                    parent={patientDetails.parent} 
                  />
                </Skeleton>
              </div>
              {
                isStaff &&
                (
                  <div className={'informationRow'}>
                    <div>{toUpper(intlMessages['requestDetailPage.header.patient.dentist'])}</div>
                    <Skeleton minWidth='250px' w='fit-content' isLoaded={dentist !== undefined}>
                      <div>{`Dr. ${formatName(intlMessages['format.fullName'], dentist?.first_name, dentist?.last_name)}`} {isStaff && ` (${dentist?.country})`}</div>
                    </Skeleton>
                  </div>
                )
              }
          </div>
        </div>
      </div>
      <PatientNotes setIsAddNote={setIsAddNote}/>
      <PatientNotesModal focused={isAddNote} />
    </StyledHeader>
  )
}
