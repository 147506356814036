import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { WrappedPage as BaseWrappedPage } from '../PageComponents/WrappedPage';

export const StyledButton = styled.button`
  background: #273238;
  box-shadow: 0px 2px 14px rgba(39, 50, 56, 0.3);
  border-radius: 28px;
  color: white;
  padding: 5px 20px;
  font-weight: 700;
  width: fit-content;
  margin: 0 5px;

  border-top: unset;
  border-left: unset;

  &:not(:disabled) {
    &:hover {
      transform: translate(0px, 1px);
      box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.05);
    }
  }
  &:disabled {
    opacity: 0.5;
  }
`;

export const StyledPageTitle = styled.div`
  font-family: Proxima Nova;
  font-weight: 600;
  font-size: 28px;
  line-height: 40px;
  /* identical to box height, or 143% */
  color: #12171a;
  text-align: left;
`;

export const StyledLoadingDiv = styled.div`
  font-size: 48px;
  padding: 40px;
`;

export const StyledErrorDiv = styled.div`
  font-size: 48px;
  padding: 40px;
`;

export const StyledRequestListContainer = styled.div`
  padding: 20px 0px;
  display: -ms-grid;
  display: grid;
  row-gap: 10px;
  grid-auto-rows: max-content;
`;

export const StyledRequestHeader = styled.div`
  font-size: 14px;
  padding: 10px 30px;
  background: transparent;
  box-sizing: border-box;
  border-radius: 3px;
  display: -ms-grid;
  display: grid;
  max-height: 70px;

  -ms-grid-columns: 1fr 1fr 2fr 2fr 2fr 3fr;
  grid-template-columns: 1fr 1fr repeat(3, 2fr) 3fr;

  -ms-grid-gap: 40px;
  grid-gap: 40px;
`;

export const StyledRequestItem = styled.div`
  font-size: 14px;
  padding: 15px 30px;
  background: #f7f9fa;
  box-sizing: border-box;
  border-radius: 3px;
  display: grid;
  align-items: center;
  max-height: 70px;
  position: relative;

  .loading {
    display: flex;
    width: 100%;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.2);
  }
  > div {
    position: relative;
  }

  grid-template-columns: 1fr 1fr repeat(3, 2fr) 4fr;

  grid-gap: 10px;
  &:hover {
  }

  > div.ellipsisText {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  > div.actions {
    display: flex;
    justify-content: space-between;

    ${StyledButton} {
      .loadingButton {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;

        svg {
          height: 20px;
          width: 80px;
        }
      }
    }
  }
`;

export const StyledRequestItemLink = styled(Link)`
  font-size: 14px;
  padding: 10px 30px;
  background: #f7f9fa;
  box-sizing: border-box;
  border-radius: 3px;
  display: -ms-grid;
  display: grid;
  & {
    text-decoration: none;
    color: inherit;
  }

  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: repeat(5, 1fr);

  -ms-grid-gap: 40px;
  grid-gap: 40px;
  &:hover {
    cursor: pointer;
    transform: translate(0px, -2px);
    box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.05);
  }

  &:hover,
  &:visited,
  &:active,
  &:link {
    text-decoration: none;
    color: inherit;
  }

  > div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const StyledRequestItemRow = styled.a`
  font-size: 14px;
  padding: 10px 30px;
  background: #f7f9fa;
  box-sizing: border-box;
  border-radius: 3px;
  display: -ms-grid;
  display: grid;
  & {
    text-decoration: none;
    color: inherit;
  }

  grid-template-columns: repeat(5, 1fr);

  grid-gap: 40px;
  &:hover {
    cursor: pointer;
    transform: translate(0px, -2px);
    box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.05);
  }

  &:hover,
  &:visited,
  &:active,
  &:link {
    text-decoration: none;
    color: inherit;
  }
`;

export const StyledNoRequests = styled.div`
  text-align: left;
  color: #7a8f99;
`;

export const StyledRequestAppointmentItem = styled.div`
  display: grid;
  padding: 15px;
  padding-top: 10px;
  background: #f7f9fa;
  border-radius: 3px;
  margin-bottom: 8px;
  grid-template-columns: 3fr 1fr;

  .information {
    display: flex;
    flex-direction: column;
    text-align: left;

    .appointmentDate {
      font-family: 'Proxima Nova Semibold';
      font-size: 20px;
      color: #212529;
    }

    .appointmentRemark {
      display: flex;
      flex-direction: column;
      .label {
        color: #7a8f99;
        font-size: 14px;
      }
      .description {
        color: #405159;
      }
    }
  }

  .action {
    display: flex;
    justify-content: center;
    align-items: center;

    ${StyledButton} {
      .loadingButton {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;

        svg {
          height: 20px;
          width: 80px;
        }
      }
    }
  }
`;

export const StyledRequestAppointmentListContainer = styled.div`
  .openRequestAppointments {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .dateLabel {
      background: #efefef;
      font-size: 14px;
      font-family: 'Proxima Nova Semibold';
      border-radius: 25px;
      padding: 15px 25px;
      text-align: left;
      width: 200px;
      margin-bottom: 18px;
      margin-top: 10px;
    }
  }
`;

export const StyledOpenRequestAppointmentListColumn = styled.div`
  .title {
    margin: 10px 0;
    font-family: 'Proxima Nova Semibold';
    font-size: 20px;
    text-align: left;
  }
  padding-right: 30px;
`;

export const StyledOpenRequestReportListColumn = styled.div`
  .title {
    margin: 10px 0;
    font-family: 'Proxima Nova Semibold';
    font-size: 20px;
    text-align: left;
  }
`;

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const WrappedPage = styled(BaseWrappedPage)`
  grid-auto-rows: max-content;
`;
