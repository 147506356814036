/* eslint-disable no-unused-vars */
import React, { useState, useRef } from 'react';
import { useSelector, useDispatch} from 'react-redux';
import AsyncSelect from 'react-select/async';
import { components } from 'react-select';
import usePlacesAutocomplete, { getDetails } from "use-places-autocomplete";

import { toLower } from 'lodash';

// components
import { IoSearch } from 'react-icons/io5';


// selkectors
import { getProfileCountry } from 'src/App/_redux/selectors';
import { useMapStore } from '../state/map';
import { useIntl } from 'react-intl';

function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

// eslint-disable-next-line no-unused-vars
const testCall = async (val) => {
  try{
    await timeout(300);
    return [{"label": "Test 1", "value": 1}, {"label": "Test 2", "value": 2}]
  }catch (err){
    console.log("error")
    return []
  }
}

const selectStyles = {
  singleValue: (provided, state) => {
    const color = state.hasValue ? '#000000' : '#C7D3D9';
    const display = state.selectProps.menuIsOpen ? 'none' : 'block';
    return { ...provided, color, display };
  },
  control: (provided) => {
    const border = 'none';
    const borderRadius = 50;
    const backgroundColor = '#EDEDED';
    const paddingLeft = 10;
    return { ...provided, border, borderRadius, backgroundColor, paddingLeft };
  },
  dropdownIndicator: (provided) => {
    const color = '#000000';
    return { ...provided, color };
  },
  indicatorSeparator:  (provided, state) => {
    const backgroundColor = state.hasValue ? '#36454D' : '#C7D3D9';
    return { ...provided, backgroundColor };
  },
  /*input: (provided) => {
    const height = 36;
    return {...provided, height}
  }*/

}


const DropdownIndicator = props => (
  <components.DropdownIndicator { ...props}>
    <IoSearch />
  </components.DropdownIndicator>
);


/**
 * check if given google place type is an area/district requiring bounding box query
 * @param  {string} val type from google places api
 * @return {boolean}    returns true if place is an area/region/city/etc.
 */
const checkIsRegion = (val) => {
  if (val.startsWith('administrative_area_')
      || val.startsWith('sublocality')
      || val.startsWith('locality')
      || val.startsWith('political')
      || val.startsWith('neighborhood')
      || val.startsWith('postal_')
      || val.startsWith('colloquial_area')){
        return true
      }
  return false;
}

export const LocationPlacesSearch = ({init, map, setCurrentAddress}) => {
  //const dispatch = useDispatch();
  const { dragStarted, updateMapCenter } = useMapStore();
  const { formatMessage } = useIntl();
  const mapRegion = useSelector(getProfileCountry)
  const placesService = useRef(new window.google.maps.places.AutocompleteService());
  const placesInfoService = useRef(new window.google.maps.places.PlacesService(map));
  const [sessionToken, setSessionToken] = useState(new window.google.maps.places.AutocompleteSessionToken());

  const {
    suggestions: { status, data },
    setValue,
  } = usePlacesAutocomplete({
    cache: false,
    requestOptions: {
      /* Define search scope here */
      sessionToken: sessionToken,
      componentRestrictions: {
        country: toLower(mapRegion)
      },
    }
  });

  const onTyping = async (current) => {
    setValue(current);
    placesService.current.getPlacePredictions({
      input: current,
      sessionToken: sessionToken
    }, (predictions, status)=> {
      if (status != window.google.maps.places.PlacesServiceStatus.OK || !predictions) {
        return;
      }
      return new Promise(() => {return predictions.map((prediction) =>
        ({label: `${prediction.main_text} ${prediction.secondary_text}` , value: prediction.place_id})
      )}
      )
    })
    if (status != window.google.maps.places.PlacesServiceStatus.OK || !data){
      return [];
    }
    return data.map(suggestion => ({value: suggestion.place_id, label: suggestion.description}))
  }

  const searchClinics = async (placeResult) => {
    dragStarted(false);
    if (placeResult.types.filter(item => checkIsRegion(item)).length > 0){
      // bbnox query
      // lc lats, g: west, i: east
      // Eb lngs, g: south, i: north
      const { lc: { g: south, i: north}, Eb: {g: west, i: east} } = placeResult.geometry.viewport
      init.current = false;
      //dispatch(actions.searchClinics('bbox', `${north},${west},${south},${east}` ))
    } else if (placeResult.geometry && placeResult.geometry.location.lat()){
      // lat lng found
      init.current = false;
      setCurrentAddress(placeResult.formatted_address);
      //dispatch(actions.searchClinics('geopoint', `${placeResult.geometry.location.lat()},${placeResult.geometry.location.lng()}`))
      updateMapCenter({lat: placeResult.geometry.location.lat(), lng:  placeResult.geometry.location.lng()});

    }
  }

  const onChange = (item) => {
    getDetails({
      placeId: item.value,
      fields: ['address_component', 'formatted_address', 'geometry', 'vicinity', 'name', 'types']
    }).then((details) => {
        searchClinics(details);
        setSessionToken(new window.google.maps.places.AutocompleteSessionToken());
      })
      .catch((error) => {
        console.log("Error: ", error);
      });

    placesInfoService.current.getDetails({
      placeId: item.value.placeId,
      fields: ['address_component', 'geometry', 'vicinity', 'name']
    }, (place, status) => {
      if (status == window.google.maps.places.PlacesServiceStatus.OK) {
        // get geolocation lat, lng from place
        //service.searchClinics();
      }
    })
  }

  return(
    <div>
      <AsyncSelect
        loadOptions={onTyping}
        disabled={!map}
        components={{DropdownIndicator}}
        onChange={onChange}
        styles={selectStyles}
        placeholder={formatMessage({id: 'settingsPage.booking.locationSearch.placeholder', defaultMessage: 'Search for an address'})}
        />
    </div>
  )
}
