import { constants } from './constants';

const initState = {
  loading: {},
  error: {},
  appointments: {},
};

export function patientAppointments(state = initState, action) {
  switch (action.type) {
    /*case constants.GETAPPOINTMENT_REQUEST:
      return {
        ...state,
        loading: Object.assign({}, state.loading, {[action.data.id]: true}),
        error: Object.assign({}, state.error, {[action.data.id]: null})
      }
    case constants.GETAPPOINTMENT_SUCCESS:
      return {
        ...state,
        loading: Object.assign({}, state.loading, {[action.data.id]: false}),
        error: Object.assign({}, state.error, {[action.data.id]: null}),
        appointments: Object.assign({}, state.appointments, {[action.data.id]: action.data.appointment}),

      }
    case constants.GETAPPOINTMENT_FAILURE:
      return {
        ...state,
        loading: Object.assign({}, state.loading, {[action.data.id]: false}),
        error: Object.assign({}, state.error, {[action.data.id]: action.data.error})
      }*/
    case constants.UPDATE_APPOINTMENTS_MANY:
      return {
        ...state,
        appointments: Object.assign({}, state.appointments, action.data.appointments),
      };
    case constants.UPDATE_APPOINTMENT:
      return {
        ...state,
        appointments: {
          ...state.appointments,
          [action.data.id]: Object.assign({}, state.appointments[action.data.id], action.data.data),
        },
      };
    case constants.UPDATE_APPOINTMENT_EXTRADATA:
      return {
        ...state,
        appointments: {
          ...state.appointments,
          [action.data.id]: {
            ...state.appointments[action.data.id],
            extra_data: Object.assign(
              {},
              state.appointments[action.data.id].extra_data,
              action.data.extraData
            ),
          },
        },
      };
    default:
      return state;
  }
}
