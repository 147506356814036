import React from 'react';
import {
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Box, Flex, Text, SimpleGrid, Button, Center, Heading
} from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

export const AdminSelectClinicModal = ({
    isOpen,
    onClose
  }) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} isCentered>
          <ModalOverlay bg="blackAlpha.300" />
          <ModalContent bg="#f7f9fa">
            <ModalHeader>
              <Center>
                    <FormattedMessage 
                        id={'patientRequest.modal.noclinicselected.title'}
                        defaultMessage={'No Clinic Selected'}
                    />
              </Center>
            </ModalHeader>
            <ModalCloseButton/>
            <ModalBody>
                <Center>
                    <Heading as={'h2'}>
                        <FormattedMessage 
                            id={'patientRequest.form.noclinicselected.title'}
                            defaultMessage={'Clinic Required'}
                        />
                    </Heading>
                </Center>
              <Box>
              <Box>
                  <Flex mt={10} w={'full'}>
                      <Text>
                          <FormattedMessage 
                              id={'patientRequest.form.noclinicselected.description'}
                              defaultMessage={`Please select a clinic to manage patient requests. You can select a clinic from the navigation bar.`}
                          />
                      </Text>
                  </Flex>
                  <SimpleGrid columns={[1]}  mt={10}>
                      <div />
                      <div />
                      <Button onClick={onClose}>
                          <FormattedMessage 
                              id="adminPage.form.close.button.label"
                              defaultMessage="Close"
                          />
                      </Button>
                  </SimpleGrid>
              </Box>
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>
    )
  }
  