import { constants } from './constants';

const initState = {
  loading: false,
  error: false,
  byId: {},
  loadMoreOffset: 0,
  loadMore: false,
  loadingMore: false,
  queryParams: {},
  requestCount: 0,
  sortBy: '-created_by',
  typeBy: null,
};

export function openRequests(state = initState, action) {
  switch (action.type) {
    case constants.FETCH_REQUESTS:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case constants.FETCH_REQUESTS_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case constants.FETCH_REQUESTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        byId: action.data.byId,
        loadMore: action.data?.load_more,
        queryParams: action.data?.query_params,
      };
    case constants.FETCH_REQUESTSMORE:
      return {
        ...state,
        loadingMore: true,
        errorMore: false,
      };
    case constants.FETCH_REQUESTSMORE_ERROR:
      return {
        ...state,
        loadingMore: false,
        errorMore: true,
      };
    case constants.FETCH_REQUESTSMORE_SUCCESS:
      return {
        ...state,
        loadingMore: false,
        errorMore: false,
        byId: Object.assign({}, state.byId, action.data.byId),
        loadMore: action.data?.load_more,
        queryParams: action.data?.query_params,
      };
    case constants.UPDATE_REQUEST:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.data.id]: Object.assign({}, state.byId[action.data.id], action.data),
        },
      };
    case constants.UPDATE_REQUESTSTATUS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.data.id]: Object.assign({}, state.byId[action.data.id], {
            status: action.data.status,
          }),
        },
      };
    case constants.UPDATE:
      return {
        ...state,
        ...action.data,
      };
    default:
      return state;
  }
}
