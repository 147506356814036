import React from 'react';
import styled from 'styled-components';


import { ToothSetOutsideNum } from '../../../_components/ToothSet/ToothSetOutsideNum';

const StyledToothChartOutsideNum = styled.div`
.region{
  &:hover{
    opacity: .3;
    fill:blue;
  }
  &.selected{
    opacity:1;
    fill:blue;
  }
}

.number{
  &.selected{
    opacity:1;
  }
}
`;

export const ToothChartOutsideNum = ({charting, toothCharting, handleChange}) => {

  const handleClick = (toothId) => {
    let newCharting = {...charting, [toothId]: (charting[toothId] === 'selected') ? '' : 'selected'}
    handleChange(newCharting);
  }

  return (
    <StyledToothChartOutsideNum>
      <ToothSetOutsideNum
        charting = {charting}
        toothCharting = {toothCharting}
        onClick = {handleClick}
        width = {"378"}
        height = {"550"}
        />
    </StyledToothChartOutsideNum>
  )
}
