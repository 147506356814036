import { createSelector } from 'reselect';

import { getPatientReportChartsById } from '../../PatientReports/_redux/selectors';
import { getDetailsReportId } from '../../PatientDetailsPage/_redux/selectors';

import { omitBy, intersection } from 'lodash';

const getReportChartFormSlice = (state) => state.reportChartForm;

export const getCurrentCategory = createSelector([getReportChartFormSlice], (state) => {
  return state.currentCategory;
});

export const getCurrentChartId = createSelector([getReportChartFormSlice], (state) => {
  return state.currentChartId;
});

export const getCommentFormIsOpen = createSelector([getReportChartFormSlice], (state) => {
  return state.commentFormIsOpen;
});

export const getCurrentRegions = createSelector([getReportChartFormSlice], (state) => {
  return Object.keys(omitBy(state.currentRegions, (val) => val.length == 0));
});

export const getReportCharts = createSelector(
  [getDetailsReportId, getPatientReportChartsById],
  (currentReport, reportCharts) => {
    return reportCharts[currentReport];
  }
);

export const getCategoryCharts = createSelector(
  [getCurrentCategory, getReportCharts],
  (category, charts) => {
    return charts ? charts : [];
  }
);

export const getCurrentReportChart = createSelector(
  [getCurrentChartId, getReportCharts],
  (chartId, reportCharts) => {
    let result = null;
    if (chartId && reportCharts) {
      for (let i = 0; i < reportCharts.length; i++) {
        if (reportCharts[i].getId() == chartId) {
          result = reportCharts[i];
          break;
        }
      }
    }
    return result;
  }
);

export const getCurrentReportChartRegions = createSelector(
  [getCurrentReportChart],
  (currentChart) => {
    if (currentChart) {
      return currentChart.getAllRegions();
    } else {
      return {};
    }
  }
);

export const getCurrentRegionCharts = createSelector(
  [getCurrentRegions, getReportCharts],
  (currentRegions, reportCharts) => {
    return reportCharts.filter(
      (chart) => intersection(currentRegions, chart.getAllRegions()).length > 0
    );
  }
);
