import React from 'react';
import { 
    Box, Flex, Heading, Button, Center,
    // eslint-disable-next-line no-unused-vars
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, useDisclosure,
    Table, Thead, Tbody, Tr, Th, Td  } from '@chakra-ui/react';
import { useReactTable, getCoreRowModel, flexRender } from '@tanstack/react-table'
import { FormattedMessage, useIntl } from 'react-intl';
import { usePatientUserDetailsFavoriteDentists } from '../api/getPatientUserFavoriteDentists';
import { FavoriteDentistForm } from './FavoriteDentistForm';

const FavoriteDentistFormModal = ({
    userId,
    isOpen,
    onClose,
    onSuccess
    }) => {
        return (
            <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} isCentered >
            <ModalOverlay bg="blackAlpha.300" />
            <ModalContent minW="500px" bg="#f7f9fa">
                <ModalHeader>
                <Center>
                        <FormattedMessage 
                            id={'adminPage.clinicRoleForm.title'}
                            defaultMessage={'Add Role'}
                        />
                </Center>
                </ModalHeader>
                <ModalCloseButton/>
                <ModalBody>
                <Box>
                    <FavoriteDentistForm
                        userId={userId}
                        onClose={onClose}
                        onSuccess={onSuccess}
                    />
                </Box>
                </ModalBody>
            </ModalContent>
            </Modal>
        )
}

const PageHeader = ({userId, onAdded}) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    
    return (
        <>
            <FavoriteDentistFormModal onSuccess={onAdded} userId={userId} isOpen={isOpen} onClose={onClose} />
            <Flex justify={'space-between'} align={'end'}>
                <Heading as={'h2'}>
                    <FormattedMessage
                        id="admin.patientuserfavoritedentist.title"
                        defaultMessage="Favorite Dentists"
                    />    
                </Heading>
                <Flex >
                    <Button variant="outline" onClick={onOpen}>
                        <FormattedMessage
                            id="admin.patientuserfavoritedentist.button.newDentist"
                            defaultMessage="+ Add New Dentist"
                        />
                    </Button>
                </Flex>
            </Flex>
        </>
    )
}

// eslint-disable-next-line no-unused-vars
const PageTable = ({data, isLoading, pagination, setPagination}) => {
    const { formatMessage } = useIntl();
    const columns = React.useMemo(
        () => [
            {
                header: 'Dentist Email',
                id: 'email',
                accessorFn: row => row.dentist.email,
            },
            {
                header: 'Dentist Name',
                id: 'dentist_name',
                accessorFn: row => formatMessage(
                    {id: 'format.fullName', defaultMessage: '{givenName} {familyName}'}, 
                    {givenName: row.dentist.first_name, familyName: row.dentist.last_name}
                ),
            },
            {
                header: 'Is Active',
                id: 'active',
                accessorFn: row => (row.active ? 'Y' : 'N'),
            }
        ],
        []
    )

    const defaultData = React.useMemo(() => [], [])

    const table = useReactTable({
        data: data || defaultData,
        columns,
        state: {
            pagination
        },
        onPaginationChange: setPagination,
        rowCount: data?.total,
        getCoreRowModel: getCoreRowModel(),
        manualPagination: true,
        debugTable: true
    })

    return (
        <>
            <Table mt={'20px'}>
                <Thead>
                    {(table.getHeaderGroups() || []).map(headerGroup => (
                        <Tr key={headerGroup.id}>
                            {headerGroup.headers.map(header => (
                                <Th key={header.id} colSpan={header.colSpan}>
                                    {flexRender(
                                        header.column.columnDef.header,
                                        header.getContext()
                                    )}
                                </Th>
                            ))}
                        </Tr>
                    ))}
                </Thead>
                <Tbody>
                    {table.getRowModel().rows.map(row => {
                        return (
                        <Tr key={row.id} >
                            {row.getVisibleCells().map(cell => {
                            return (
                                <Td key={cell.id}>
                                    {flexRender(
                                        cell.column.columnDef.cell,
                                        cell.getContext()
                                    )}
                                </Td>
                            )
                            })}
                        </Tr>
                        )
                    })}
                </Tbody>
            </Table>
        </>
    )
}

export const PatientUserFavoriteDentistsPage = ({userId}) => {
    const { data, isLoading, isFetching, refetch } = usePatientUserDetailsFavoriteDentists({userId}); 

    return (
        <Box>
            <PageHeader userId={userId} onAdded={refetch}/>
            <PageTable data={data} isLoading={isLoading || isFetching} />
        </Box>
    )
}