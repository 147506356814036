import { authHeader, handleResponse } from "src/_helpers";
import { API_URLS } from "src/_config";


export function fetchSelectOptions(select_option) {
  const requestUrl = API_URLS.getDentistSelectOptions;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };
  let url = requestUrl.url.replace('{select_option}', select_option)
  return fetch(url, requestOptions).then(handleResponse);
}

export function updateGeopoint(data = {}, dpid = null) {
    const requestUrl = API_URLS.updateBookingClinicProfile;
    const requestOptions = {
      method: requestUrl.method,
      headers: { ...authHeader(), "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };
    
    let url = requestUrl.url;
  
    if (dpid) {
      url = url + "?dpid=" + dpid;
    }
    return fetch(url, requestOptions).then(handleResponse);
  }

export function updateBookingDentistProfile(data = {}, dpid = null) {
  const requestUrl = API_URLS.updateBookingDentistProfile;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  
  let url = requestUrl.url;

  if (dpid) {
    url = url + "?dpid=" + dpid;
  }
  return fetch(url, requestOptions).then(handleResponse);
}

export function fetchBookingClinicProfile() {
    const requestUrl = API_URLS.getBookingClinicProfile;
    const requestOptions = {
      method: requestUrl.method,
      headers: { ...authHeader(), "Content-Type": "application/json" },
    };
    return fetch(requestUrl.url, requestOptions).then(handleResponse);
  }

export function fetchBookingDentistProfile() {
  const requestUrl = API_URLS.getBookingDentistProfile;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  return fetch(requestUrl.url, requestOptions).then(handleResponse);
}

export function fetchClinicBilling() {
    const requestUrl = API_URLS.getClinicBilling;
    const requestOptions = {
      method: requestUrl.method,
      headers: { ...authHeader(), "Content-Type": "application/json" },
    };
    return fetch(requestUrl.url, requestOptions).then(handleResponse);
  }

export function fetchDentistReferralCode() {
  const requestUrl = API_URLS.getDentistReferralCode;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  return fetch(requestUrl.url, requestOptions).then(handleResponse);
}
