import React from 'react';

export const TimelineEventIcon = () => {
  return (
    <svg width="225" height="32" viewBox="0 31.4 59.531 8.4">
      <g transform="matrix(.99959 0 0 .99838 -100.96 -38.57)">
        <path
          d="M101.002 69.656h55.492l4.064 4.158-4.064 4.205h-55.492l3.85-4.205z"
          fill="#D8D8D8"
          strokeWidth="0.24"
        ></path>
        <circle cx="130.726" cy="73.838" r="1.522" fill="#fff" strokeWidth="0.15"></circle>
      </g>
    </svg>
  );
};
