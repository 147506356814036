import React from 'react';
import {
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Center, Button
} from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';


export const ConfirmationDialog = ({ isOpen, onClose, handleClose }) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    <Center>
                        <FormattedMessage 
                            id={'confirmationDialog.title'}
                            defaultMessage='Close Modal?'
                        />
                    </Center>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody pt={'30px'} pb={'20px'}>
                    <FormattedMessage 
                        id={'confirmationDialog.description'}
                        defaultMessage='Are you sure you want to close the modal? You will lose any unsaved progress.'
                    />
                </ModalBody>
                <ModalFooter pb={'20px'}>
                    <Button colorScheme="red" mr={3} onClick={handleClose}>
                        <FormattedMessage 
                            id={'confirmationDialog.yesClose'}
                            defaultMessage={'Yes, Close'}
                        />
                    </Button>
                    <Button variant="outlined" onClick={onClose}>
                        <FormattedMessage 
                            id={'confirmationDialog.cancel'}
                            defaultMessage={'No, Cancel'} 
                        />
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
