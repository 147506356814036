import { constants } from './constants';
import { services } from './services';

export const actions = {
  fetchDentistDetails,
  searchDentists,
};

function fetchDentistDetails(id) {
  return (dispatch) => {
    dispatch(request(id));
    services.fetchDentistDetails(id).then(
      (data) => dispatch(success(data)),
      (error) => {
        dispatch(failure(id, error));
      }
    );
  };
  function request(id) {
    return { type: constants.FETCH_DENTISTDETAILS_REQUEST, data: { id } };
  }
  function success(data) {
    const dentist = data;
    data = { id: dentist.unique_id, dentist };
    return { type: constants.FETCH_DENTISTDETAILS_SUCCESS, data };
  }
  function failure(id, error) {
    return { type: constants.FETCH_DENTISTDETAILS_FAILURE, data: { id, error } };
  }
}

function searchDentists(q = '', country = null) {
  return (dispatch) => {
    dispatch(request({ country }));
    services.searchDentists(q, country).then(
      (data) => {
        let byCountry = {};
        if (country == null || country == 'ALL') {
          // group by country
          byCountry = data.search_list.reduce((acc, curr) => {
            // if key is present, push, else create initial list
            if (acc[curr.country.toLowerCase()]) {
              acc[curr.country.toLowerCase()].push(curr);
            } else {
              acc[curr.country.toLowerCase()] = [curr];
            }
            return acc;
          }, {});
        } else {
          byCountry[country.toLowerCase()] = data.search_list;
        }
        dispatch(success({ byCountry }));
      },
      () => dispatch(failure({ country }))
    );
  };
  function request(data) {
    return { type: constants.SEARCHDENTISTS_REQUEST, data };
  }
  function success(data) {
    return { type: constants.SEARCHDENTISTS_SUCCESS, data };
  }
  function failure(data) {
    return { type: constants.SEARCHDENTISTS_FAILURE, data };
  }
}
