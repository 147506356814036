import React from 'react';
import styled from 'styled-components';


import { PartialScanTeeth } from '../../../_components/PartialScanTeeth/PartialScanTeeth';

const StyledPartialScanChart = styled.div`
.partialRegion{
  pointer-events: ${({disabled}) => (disabled) ? 'none' : '' };

  &:hover{
    opacity: .6;
  }

  &.selected{
    opacity:1;
    &:hover{
      opacity: 1;
    }
  }
}

.number{
  &.selected{
    opacity:1;
  }
}
`;

export const PartialScanChart = ({charting, toothCharting, handleChange, disabled}) => {

  const handleClick = (toothId) => {
    if (!disabled){
      let newCharting = {...charting, [toothId]: (charting[toothId] === 'selected') ? '' : 'selected'}
      handleChange(newCharting);
    }
  }

  return (
    <StyledPartialScanChart disabled={disabled}>
      <PartialScanTeeth
        charting = {charting}
        toothCharting = {toothCharting}
        onClick = {handleClick}
        width = {"300"}
        height = {"440"}
        />
    </StyledPartialScanChart>
  )
}
