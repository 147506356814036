import { authHeader, handleResponse } from 'src/_helpers';
import { API_URLS } from 'src/_config';
//import { axios } from 'src/_libs/axios';

export function fetchSelectOptions(select_option) {
    const requestUrl = API_URLS.getDentistSelectOptions;
    const requestOptions = {
      method: requestUrl.method,
      headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };
    let url = requestUrl.url.replace('{select_option}', select_option)
    return fetch(url, requestOptions).then(handleResponse);
  }

export function fetchClinicRoles() {
  const requestUrl = API_URLS.getDentistClinicRoles;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };
  let url = requestUrl.url
  return fetch(url, requestOptions).then(handleResponse);
}
