const PREFIX = 'PATIENTS_';
export const constants = {
  FETCH_PATIENTLIST_REQUEST: `${PREFIX}FETCH_PATIENTLIST_REQUEST`,
  FETCH_PATIENTLIST_SUCCESS: `${PREFIX}FETCH_PATIENTLIST_SUCCESS`,
  FETCH_PATIENTLIST_FAILURE: `${PREFIX}FETCH_PATIENTLIST_FAILURE`,
  FETCH_PATIENTDETAILS: `${PREFIX}FETCH_PATIENTDETAILS`,
  FETCH_PATIENTDETAILS_SUCCESS: `${PREFIX}FETCH_PATIENTDETAILS_SUCCESS`,
  FETCH_PATIENTDETAILS_FAILURE: `${PREFIX}FETCH_PATIENTDETAILS_FAILURE`,
  UPDATE_CURRENTPATIENT: `${PREFIX}UPDATE_CURRENTPATIENT`,
  FETCH_PATIENTAPPOINTMENTS: `${PREFIX}FETCH_PATIENTAPPOINTMENTS`,
  FETCH_PATIENTAPPOINTMENTS_SUCCESS: `${PREFIX}FETCH_PATIENTAPPOINTMENTS_SUCCESS`,
  FETCH_PATIENTAPPOINTMENTS_FAILURE: `${PREFIX}FETCH_PATIENTAPPOINTMENTS_FAILURE`,
  FETCH_PATIENTREQUESTS: `${PREFIX}FETCH_PATIENTREQUESTS`,
  FETCH_PATIENTREQUESTS_SUCCESS: `${PREFIX}FETCH_PATIENTREQUESTS_SUCCESS`,
  FETCH_PATIENTREQUESTS_FAILURE: `${PREFIX}FETCH_PATIENTREQUESTS_FAILURE`,
  FETCH_PATIENTRECORDS: `${PREFIX}FETCH_PATIENTRECORDS`,
  FETCH_PATIENTRECORDS_SUCCESS: `${PREFIX}FETCH_PATIENTRECORDS_SUCCESS`,
  FETCH_PATIENTRECORDS_FAILURE: `${PREFIX}FETCH_PATIENTRECORDS_FAILURE`,
  ADD_PATIENT: `${PREFIX}ADD_PATIENT`,
  UPDATE_REQUEST: `${PREFIX}UPDATE_REQUEST`,
  UPDATE_STATE: `${PREFIX}UPDATE_STATE`,
};
