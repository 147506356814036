import { API_URLS } from '../../_config';
import { authHeader, handleResponse } from '../../_helpers';

export const services = {
  fetchChatRecord,
};

function fetchChatRecord(id, patient) {
  const requestUrl = API_URLS.fetchChatRecord;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };
  let url = requestUrl.url.replace('{patient}', patient).replace('{recordId}', id);
  return fetch(url, requestOptions).then(handleResponse);
}
