import React from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Flex, Text } from '@chakra-ui/react';

import styled from 'styled-components';
import cn from 'classnames';
import { Group } from '@visx/group';
import { HeatmapRect } from '@visx/heatmap';
import { scaleLinear } from '@visx/scale';
import { range } from 'lodash';
import { number } from 'prop-types';

const neutral1 = '#7A8F99';
const neutral2 = '#6a767e';
const playBar = '#367FFFE0';
// accessors
const bins = (d) => d.bins;
const count = (d) => d.count;

function max(data, value) {
  return Math.max(...data.map(value));
}

//function min(data, value) {
//  return Math.min(...data.map(value));
//}

const StyledDetections = styled.div`
background-color: inherit;

.detectionSeekBar{

  &.notSeekable{
    visibility: hidden;
  }
}

.visx-heatmap-rect{
  &.detected{
    &:hover{
      cursor:crosshair;
    }
  }
}
`;


export const Detections = ({data, loaded, currFrame, onMouseEnter, containerWidth=560}) => {

  if (data?.frames < 1){
    return (
      <Flex align={['center']} justify={['center']} py={['10px']}>
        <Text>
        <FormattedMessage
            id={'detections.notloaded'}
            defaultMessage={'Video not fully loaded...'}
        />
        </Text>
      </Flex>
    )
  }

  /*if (['COMPLETED', 'COMPLETE' ].indexOf(data?.status) == -1){
    return (
        <Flex align={['center']} justify={['center']} py={['10px']}>
          <Text>
            <FormattedMessage
                id={'detections.processing'}
                defaultMessage={'Detection is still processing...'}
            />
          </Text>
        </Flex>
    )
  }*/

  let detections = [];
  // if dtections are stringified list, then parse it.
  //if (typeof dataDetections === "string"){
  //  dataDetections = JSON.parse(dataDetections);
  //}

  if (data?.detections){
    if (typeof data?.detections === 'string') {
      detections = JSON.parse(data.detections);
    } else  {
      detections = data.detections;
    }
  }
  let dataDetections = (detections || []).reduce((acc, curr) => {
    acc[curr[1]] = curr;
    return acc;
  }, {});


  let binData = range(1, data?.frames +1).reduce((acc, curr) => {
    // [timestamp in ms, frame number, array of detections [score, left, top, right, bottom]]
    // uusally for detections, but when its not, alternate colors
    acc.push({
      bin: curr,
      bins: [
        {
          bin: 1,
          count: (dataDetections[curr] && dataDetections[curr][2])
          ? dataDetections[curr][2].reduce((a, c) => Math.max(a, Number(c[0])), 0)
          : 0
        }
      ]
      })
    return acc;
  }, []) 


  //accessors
  let colorMax = max(binData, d => max(bins(d), count));
  const bucketSizeMax = max(binData, d => bins(d).length);

  if (colorMax == 0){
    colorMax = 1;
  }

  const margin = { top: 10, left: 0, right: 0, bottom: 10 };
  const xMax = containerWidth - margin.left - margin.right;
  const yMax = 50 - margin.bottom - margin.top;
  // DEBUG console.log("total frames, xmax, ymax ", size, xMax, yMax)

  const binWidth = xMax / binData.length;
  const binHeight = yMax / 1;

  // scales
  const xScale = scaleLinear({
    domain: [0, binData.length],
    range: [0, xMax]
  });
  const yScale = scaleLinear({
    domain: [0, bucketSizeMax],
    range: [10, yMax]
  });

  // DEBUG console.log("y scale", yMax, bucketSizeMax)

  const circleColorScale = scaleLinear({
    domain: [0, colorMax],
    range: [neutral1, neutral2],
    //range: [hot1, hot2],
  });
  const opacityScale = scaleLinear({
    domain: [0, colorMax],
    range: [0.2, 1],
  });

  //console.log("binData ", (binData || []).length)


  return (
    <StyledDetections>
      <Flex justify={'center'} mt={'5px'}>
        <Text>
          <FormattedMessage 
            id={'detections.detectionbar.description'}
            defaultMessage={'Hover over the bar below to seek through the video'}
          />
        </Text>
      </Flex>
      <svg width={containerWidth} height={60}>
        <Group left={margin.left} top={margin.top}>
          <HeatmapRect
            data={binData}
            xScale={xScale}
            yScale={yScale}
            colorScale={circleColorScale}
            opacityScale={opacityScale}
            binWidth={binWidth}
            binHeight={binHeight}
            gap={0}
          >
          {heatmap =>
            heatmap.map(heatmapBins =>
              heatmapBins.map(bin => (
                <rect
                  key={`heatmap-rect-${bin.row}-${bin.column}`}
                  className={cn("visx-heatmap-rect", {"detected": bin.opacity > 0})}
                  width={bin.width}
                  height={bin.height}
                  x={bin.x}
                  y={bin.y}
                  fill={bin.color}
                  fillOpacity={bin.opacity ? bin.opacity : 0}
                  onMouseEnter={() => {
                    bin.opacity > 0 && onMouseEnter(bin.column)
                  }}
                />
              )),
            )
          }
          </HeatmapRect>
        </Group>
        <rect 
          className={cn('detectionSeekBar', {'notSeekable': !loaded})} 
          x={xScale(currFrame) || 0} 
          y={14} 
          width={binWidth} 
          height={42} 
          rx={2} 
          fill={playBar} 
        />
      </svg>
    </StyledDetections>
  )
}

const DetectionDataPropType = PropTypes.shape({
    unique_id: PropTypes.string,
    detection_id: PropTypes.string,
    status: PropTypes.string,
    is_completed: PropTypes.bool,
    frames: PropTypes.number,
    run_time: PropTypes.number,
    fps: PropTypes.number,
    created_at: PropTypes.string,
    detections: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)))
    })

Detections.propTypes = {
    data: PropTypes.instanceOf(DetectionDataPropType),
    isShowing: PropTypes.bool,
    loaded: PropTypes.bool,
    currFrame: number,
    onMouseEnter: PropTypes.func,
    containerWidth: PropTypes.number
    
}