import React from 'react';
import { Icon } from '@chakra-ui/react';

const ClearAllIcon = (props) => {
  const iconWidth = props?.width || 20;
  const iconHeight = props?.height || 20;
  return (
    <Icon 
      viewBox="0 0 20 20" 
      color={'#979797'} 
      fill={'none'}
      {...props}
      width={`${iconWidth}px`}
      height={`${iconHeight}px`}
    >
        <path d="M4.87588 7.24129C5.66621 5.87727 6.92532 4.84696 8.41877 4.3422C9.91222 3.83744 11.5382 3.89263 12.994 4.4975C14.4498 5.10237 15.6361 6.21568 16.3321 7.63015C17.0282 9.04462 17.1864 10.6638 16.7775 12.1863C16.3685 13.7088 15.4201 15.0307 14.109 15.906C12.7979 16.7813 11.2134 17.1503 9.65046 16.9442C8.08754 16.7382 6.65276 15.9712 5.61328 14.786C4.57379 13.6008 4.00045 12.0783 4 10.5019" 
            stroke="currentColor" 
            strokeLinecap="round" 
            strokeLinejoin="round"
        />
        <path d="M8.0625 7.25195H4.8125V4.00195" 
            stroke="currentColor" 
            strokeLinecap="round" 
            strokeLinejoin="round"
        />
    </Icon>
  );
};

export default ClearAllIcon;