import { useMutation } from 'react-query';

import { sendReport } from './services';
import { QUERY_KEY_NAMES } from './constants';
import { queryClient } from 'src/_libs/react-query';

const sendReportApi = async ({ reportUid, data }) => {
    return sendReport(reportUid, data);
}

const handleError =  (reportUid, _error, context) => {
    queryClient.setQueryData([QUERY_KEY_NAMES.GET_REPORT, {reportId: reportUid}], () => {
        return {report: context.previousData.report};
    });
}

const onSuccess = async(reportUid, data) => {
    queryClient.setQueryData([QUERY_KEY_NAMES.GET_REPORT, {reportId: reportUid}], (oldData) => {
        return {
            report: {
                ...oldData.report,
                ...data
            }
        }
    });
}

export const useSendReport = ({ reportUid }) => {
    return useMutation({
        mutationKey: QUERY_KEY_NAMES.SEND_REPORT,
        onSuccess: (data) => onSuccess(reportUid, data),
        onError: (error, context) => handleError(reportUid, error, context),
        mutationFn: (data) => {        
            return sendReportApi({ reportUid, data });
        },
    });
}