import { constants } from './constants';

const initState = {
  loading: {},
  error: {},
  requests: {},
};

export function patientRequests(state = initState, action) {
  switch (action.type) {
    case constants.GETREQUEST_REQUEST:
      return {
        ...state,
        loading: Object.assign({}, state.loading, { [action.data.id]: true }),
        error: Object.assign({}, state.error, { [action.data.id]: null }),
      };
    case constants.GETREQUEST_SUCCESS:
      return {
        ...state,
        loading: Object.assign({}, state.loading, { [action.data.id]: false }),
        error: Object.assign({}, state.error, { [action.data.id]: null }),
        requests: Object.assign({}, state.requests, { [action.data.id]: action.data.request }),
      };
    case constants.GETREQUEST_FAILURE:
      return {
        ...state,
        loading: Object.assign({}, state.loading, { [action.data.id]: false }),
        error: Object.assign({}, state.error, { [action.data.id]: action.data.error }),
      };
    case constants.UPDATE_REQUESTS_MANY:
      return {
        ...state,
        requests: Object.assign({}, state.requests, action.data.requests),
      };
    case constants.UPDATE_REQUEST:
      return {
        ...state,
        requests: {
          ...state.requests,
          [action.data.id]: Object.assign({}, state.requests[action.data.id], action.data),
        },
      };
    case constants.UPDATE_REQUESTSTATUS:
      return {
        ...state,
        requests: {
          ...state.requests,
          [action.data.id]: Object.assign({}, state.requests[action.data.id], {
            status: action.data.status,
          }),
        },
      };
    case constants.UPDATE_REQUEST_EXTRADATA:
      return {
        ...state,
        requests: {
          ...state.requests,
          [action.data.id]: {
            ...state.requests[action.data.id],
            extra_data: Object.assign(
              {},
              state.requests[action.data.id].extra_data,
              action.data.extraData
            ),
          },
        },
      };
    default:
      return state;
  }
}
