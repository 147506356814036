import { create } from 'zustand';

export const useReportStore = create((set) => ({
    charting: {},
    isSubmitDisabled: true,
    setCharting: (chartingVal) => 
        set((state) => ({
            ...state,
            charting: chartingVal
        })),
    setIsSubmitDisabled: (boolVal) => 
        set((state) => ({
            ...state,
            isSubmitDisabled: boolVal
        }))
}));