import styled from 'styled-components';
import { WrappedPage as BaseWrappedPage } from '../PageComponents/WrappedPage';

export const WrappedPage = styled(BaseWrappedPage)`
  /*grid-auto-rows: max-content;*/
  min-height: unset;

  display: grid;
  grid-gap: 0px;
  grid-template-rows: 190px 650px;
`;

export const StyledCalendarHeader = styled.section`
  padding: 42px 33px 0;
  background-color: #fcfeff;
  box-shadow: 0 4px 15px -2px whitesmoke;
  display: flex;
  justify-content: space-between;

  .calendar-state {
    display: flex;
    flex-direction: column;
    text-align: left;
    font-family: Proxima Nova;
    font-size: 19px;
    color: #12171a;

    .welcome-msg {
      float: left;
      font-size: 19px;
    }

    .today-label {
      font-size: 15px;
      letter-spacing: 1px;
      margin-bottom: 8px;
      font-family: Proxima Nova Semibold;
    }

    .today-date {
      font-family: Proxima Nova Semibold;
      font-size: 28px;
      margin-bottom: 40px;

      .fa-chevron-down {
        font-size: 13px;
        line-height: 32px;
        margin-left: 50px;
      }
    }
  }

  .calendar-view-options {
    float: right;
    width: 279px;
    height: 48px;
    border-radius: 24px;
    background-color: #f0f3f5;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 3px 5px;
  }

  .calendar-view-option {
    display: inline-block;
    border: none;
    padding: 0 20px;
    font-size: 15px;
    font-weight: bolder;
    letter-spacing: 1px;
    outline: none;
    background: transparent;

    &--active {
      height: 38px;
      background: white;
      border-radius: 20px;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
    }
  }
`;

export const StyledCalendar = styled.div`

height:900px;

.rbc-btn {
  color: inherit;
  font: inherit;
  margin: 0; }

button.rbc-btn {
  overflow: visible;
  text-transform: none;
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled].rbc-btn {
  cursor: not-allowed; }

button.rbc-input::-moz-focus-inner {
  border: 0;
  padding: 0; }

.rbc-calendar {
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch; }

.rbc-calendar *,
.rbc-calendar *:before,
.rbc-calendar *:after {
  box-sizing: inherit; }

.rbc-abs-full, .rbc-row-bg {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }

.rbc-ellipsis, .rbc-event-label, .rbc-row-segment .rbc-event-content, .rbc-show-more {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.rbc-rtl {
  direction: rtl; }

.rbc-off-range {
  color: #999999; }

.rbc-off-range-bg {
  background: #e6e6e6; }
.rbc-allday-cell{
  display:none;
}

.rbc-header {
  overflow: hidden;
  flex: 1 0 0%;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 3px;
  text-align: center;
  vertical-align: middle;
  font-weight: bold;
  font-size: 90%;
  min-height: 0;
  border-bottom: 1px solid #DDD;
  }
  .rbc-header + .rbc-header {
    /*border-left: 1px solid #DDD; */
    border-left: none;
  }
  .rbc-rtl .rbc-header + .rbc-header {
    border-left-width: 0;
    border-right: 1px solid #DDD; }
  .rbc-header > a, .rbc-header > a:active, .rbc-header > a:visited {
    color: inherit;
    text-decoration: none; }

.rbc-row-content {
  position: relative;
  user-select: none;
  -webkit-user-select: none;
  z-index: 4; }

.rbc-row-content-scrollable {
  display: flex;
  flex-direction: column;
  height: 100%; }
  .rbc-row-content-scrollable .rbc-row-content-scroll-container {
    height: 100%;
    overflow-y: scroll;
    /* Hide scrollbar for Chrome, Safari and Opera */
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */ }
    .rbc-row-content-scrollable .rbc-row-content-scroll-container::-webkit-scrollbar {
      display: none; }

.rbc-today {
  background-color: #eaf6ff; }

.rbc-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  font-size: 16px; }
  .rbc-toolbar .rbc-toolbar-label {
    flex-grow: 1;
    padding: 0 10px;
    text-align: center; }
  .rbc-toolbar button {
    color: #373a3c;
    display: inline-block;
    margin: 0;
    text-align: center;
    vertical-align: middle;
    background: none;
    background-image: none;
    border: 1px solid #ccc;
    padding: .375rem 1rem;
    border-radius: 4px;
    line-height: normal;
    white-space: nowrap; }
    .rbc-toolbar button:active, .rbc-toolbar button.rbc-active {
      background-image: none;
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
      background-color: #e6e6e6;
      border-color: #adadad; }
      .rbc-toolbar button:active:hover, .rbc-toolbar button:active:focus, .rbc-toolbar button.rbc-active:hover, .rbc-toolbar button.rbc-active:focus {
        color: #373a3c;
        background-color: #d4d4d4;
        border-color: #8c8c8c; }
    .rbc-toolbar button:focus {
      color: #373a3c;
      background-color: #e6e6e6;
      border-color: #adadad; }
    .rbc-toolbar button:hover {
      color: #373a3c;
      background-color: #e6e6e6;
      border-color: #adadad; }

.rbc-btn-group {
  display: inline-block;
  white-space: nowrap; }
  .rbc-btn-group > button:first-child:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .rbc-btn-group > button:last-child:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .rbc-rtl .rbc-btn-group > button:first-child:not(:last-child) {
    border-radius: 4px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .rbc-rtl .rbc-btn-group > button:last-child:not(:first-child) {
    border-radius: 4px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .rbc-btn-group > button:not(:first-child):not(:last-child) {
    border-radius: 0; }
  .rbc-btn-group button + button {
    margin-left: -1px; }
  .rbc-rtl .rbc-btn-group button + button {
    margin-left: 0;
    margin-right: -1px; }
  .rbc-btn-group + .rbc-btn-group,
  .rbc-btn-group + button {
    margin-left: 10px; }

.rbc-event, .rbc-day-slot .rbc-background-event {
  border: none;
  box-sizing: border-box;
  box-shadow: none;
  margin: 0;
  padding: 2px 5px;
  background-color: #3174ad;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  width: 100%;
  text-align: left; }
  .rbc-slot-selecting .rbc-event, .rbc-slot-selecting .rbc-day-slot .rbc-background-event, .rbc-day-slot .rbc-slot-selecting .rbc-background-event {
    cursor: inherit;
    pointer-events: none; }
  .rbc-event.rbc-selected, .rbc-day-slot .rbc-selected.rbc-background-event {
    background-color: #265985; }
  .rbc-event:focus, .rbc-day-slot .rbc-background-event:focus {
    outline: 5px auto #3b99fc; }

.rbc-event-label {
  font-size: 80%; }

.rbc-event-overlaps {
  box-shadow: -1px 1px 5px 0px rgba(51, 51, 51, 0.5); }

.rbc-event-continues-prior {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.rbc-event-continues-after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.rbc-event-continues-earlier {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.rbc-event-continues-later {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.rbc-row {
  display: flex;
  flex-direction: row; }

.rbc-row-segment {
  padding: 0 1px 1px 1px; }

.rbc-selected-cell {
  background-color: rgba(0, 0, 0, 0.1); }

.rbc-show-more {
  background-color: rgba(255, 255, 255, 0.3);
  z-index: 4;
  font-weight: bold;
  font-size: 85%;
  height: auto;
  line-height: normal; }

.rbc-month-view {
  position: relative;
  border: 1px solid #DDD;
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  width: 100%;
  user-select: none;
  -webkit-user-select: none;
  height: 100%; }

.rbc-month-header {
  display: flex;
  flex-direction: row; }

.rbc-month-row {
  display: flex;
  position: relative;
  flex-direction: column;
  flex: 1 0 0;
  flex-basis: 0px;
  overflow: hidden;
  height: 100%; }
  .rbc-month-row + .rbc-month-row {
    border-top: 1px solid #DDD; }

.rbc-date-cell {
  flex: 1 1 0;
  min-width: 0;
  padding-right: 5px;
  text-align: right; }
  .rbc-date-cell.rbc-now {
    font-weight: bold; }
  .rbc-date-cell > a, .rbc-date-cell > a:active, .rbc-date-cell > a:visited {
    color: inherit;
    text-decoration: none; }

.rbc-row-bg {
  display: flex;
  flex-direction: row;
  flex: 1 0 0;
  overflow: hidden; }

.rbc-day-bg {
  flex: 1 0 0%; }
  .rbc-day-bg + .rbc-day-bg {
    /*border-left: 1px solid #DDD; */
    border-left: none;
  }
  .rbc-rtl .rbc-day-bg + .rbc-day-bg {
    border-left-width: 0;
    border-right: 1px solid #DDD; }

.rbc-overlay {
  position: absolute;
  z-index: 5;
  border: 1px solid #e5e5e5;
  background-color: #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
  padding: 10px; }
  .rbc-overlay > * + * {
    margin-top: 1px; }

.rbc-overlay-header {
  border-bottom: 1px solid #e5e5e5;
  margin: -10px -10px 5px -10px;
  padding: 2px 10px; }

.rbc-agenda-view {
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  overflow: auto; }
  .rbc-agenda-view table.rbc-agenda-table {
    width: 100%;
    border: 1px solid #DDD;
    border-spacing: 0;
    border-collapse: collapse; }
    .rbc-agenda-view table.rbc-agenda-table tbody > tr > td {
      padding: 5px 10px;
      vertical-align: top; }
    .rbc-agenda-view table.rbc-agenda-table .rbc-agenda-time-cell {
      padding-left: 15px;
      padding-right: 15px;
      text-transform: lowercase; }
    .rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
      border-left: 1px solid #DDD; }
    .rbc-rtl .rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
      border-left-width: 0;
      border-right: 1px solid #DDD; }
    .rbc-agenda-view table.rbc-agenda-table tbody > tr + tr {
      border-top: 1px solid #DDD; }
    .rbc-agenda-view table.rbc-agenda-table thead > tr > th {
      padding: 3px 5px;
      text-align: left;
      border-bottom: 1px solid #DDD; }
      .rbc-rtl .rbc-agenda-view table.rbc-agenda-table thead > tr > th {
        text-align: right; }

.rbc-agenda-time-cell {
  text-transform: lowercase; }
  .rbc-agenda-time-cell .rbc-continues-after:after {
    content: ' »'; }
  .rbc-agenda-time-cell .rbc-continues-prior:before {
    content: '« '; }

.rbc-agenda-date-cell,
.rbc-agenda-time-cell {
  white-space: nowrap; }

.rbc-agenda-event-cell {
  width: 100%; }

.rbc-time-column {
  display: flex;
  flex-direction: column;
  min-height: 100%; }
  .rbc-time-column .rbc-timeslot-group {
    flex: 1; }

.rbc-timeslot-group {
  border-bottom: 1px solid #DDD;
  min-height: ${(props) => (props.slotHeight ? props.slotHeight : '188')}px;
  display: flex;
  flex-flow: column nowrap; }

.rbc-time-gutter,
.rbc-header-gutter {
  flex: none; }

.rbc-label {
  padding: 0 5px;
  margin-right: 15px;
}

.rbc-day-slot {
  position: relative; }
  .rbc-day-slot .rbc-events-container {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    margin-right: 10px;
    top: 0; }
    .rbc-day-slot .rbc-events-container.rbc-rtl {
      left: 10px;
      right: 0; }
  .rbc-day-slot .rbc-event, .rbc-day-slot .rbc-background-event {
    border: 1px solid #265985;
    display: flex;
    max-height: 100%;
    min-height: 20px;
    flex-flow: column wrap;
    align-items: flex-start;
    overflow: hidden;
    position: absolute; }
  .rbc-day-slot .rbc-background-event {
    opacity: 0.75; }
  .rbc-day-slot .rbc-event-label {
    flex: none;
    padding-right: 5px;
    width: auto; }
  .rbc-day-slot .rbc-event-content {
    width: 100%;
    flex: 1 1 0;
    word-wrap: break-word;
    line-height: 1;
    height: 100%;
    min-height: 1em; }
  .rbc-day-slot .rbc-time-slot {
    border-top: 1px solid #f7f7f7; }

.rbc-time-view-resources .rbc-time-gutter,
.rbc-time-view-resources .rbc-time-header-gutter {
  position: sticky;
  left: 0;
  background-color: white;
  border-right: 1px solid #DDD;
  z-index: 10;
  margin-right: -1px; }

.rbc-time-view-resources .rbc-time-header {
  overflow: hidden; }

.rbc-time-view-resources .rbc-time-header-content {
  min-width: auto;
  flex: 1 0 0;
  flex-basis: 0px; }

.rbc-time-view-resources .rbc-time-header-cell-single-day {
  display: none; }

.rbc-time-view-resources .rbc-day-slot {
  min-width: 140px; }

.rbc-time-view-resources .rbc-header,
.rbc-time-view-resources .rbc-day-bg {
  width: 140px;
  flex: 1 1 0;
  flex-basis: 0 px; }

.rbc-time-header-content + .rbc-time-header-content {
  margin-left: -1px; }

.rbc-time-slot {
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #7A8F99;
  flex: 1 0 0; }
  .rbc-time-slot.rbc-now {
    font-weight: bold; }

.rbc-day-header {
  text-align: center; }

.rbc-slot-selection {
  z-index: 10;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 75%;
  width: 100%;
  padding: 3px; }

.rbc-slot-selecting {
  cursor: move; }

.rbc-time-view {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  border: 1px solid #DDD;
  min-height: 0; }
  .rbc-time-view .rbc-time-gutter {
    white-space: nowrap; }
  .rbc-time-view .rbc-allday-cell {
    box-sizing: content-box;
    width: 100%;
    height: 100%;
    position: relative; }
  .rbc-time-view .rbc-allday-cell + .rbc-allday-cell {
    border-left: 1px solid #DDD; }
  .rbc-time-view .rbc-allday-events {
    position: relative;
    z-index: 4; }
  .rbc-time-view .rbc-row {
    box-sizing: border-box;
    min-height: 20px; }

.rbc-time-header {
  display: flex;
  flex: 0 0 auto;
  flex-direction: row; }
  .rbc-time-header.rbc-overflowing {
    border-right: 1px solid #DDD; }
  .rbc-rtl .rbc-time-header.rbc-overflowing {
    border-right-width: 0;
    border-left: 1px solid #DDD; }
  .rbc-time-header > .rbc-row:first-child {
    border-bottom: 1px solid #DDD; }
  .rbc-time-header > .rbc-row.rbc-row-resource {
    border-bottom: 1px solid #DDD; }

.rbc-time-header-cell-single-day {
  display: none; }

.rbc-time-header-content {
  flex: 1;
  display: flex;
  min-width: 0;
  flex-direction: column;
  /*border-left: 1px solid #DDD; */
  border-left:none;
}
  .rbc-rtl .rbc-time-header-content {
    border-left-width: 0;
    border-right: 1px solid #DDD; }
  .rbc-time-header-content > .rbc-row.rbc-row-resource {
    border-bottom: 1px solid #DDD;
    flex-shrink: 0; }

.rbc-time-content {
  display: flex;
  flex: 1 0 0%;
  align-items: flex-start;
  width: 100%;
  border-top: 2px solid #DDD;
  overflow-y: auto;
  position: relative; }
  .rbc-time-content > .rbc-time-gutter {
    flex: none; }
  .rbc-time-content > * + * > * {
    border-left: 1px solid #DDD;
      border-left-width: 0;
  }
  .rbc-rtl .rbc-time-content > * + * > * {
    border-left-width: 0;
    border-right: 1px solid #DDD;
  }
  .rbc-time-content > .rbc-day-slot {
    width: 100%;
    user-select: none;
    -webkit-user-select: none; }

.rbc-current-time-indicator {
  position: absolute;
  z-index: 3;
  left: 0;
  right: 0;
  height: 1px;
  background-color: #74ad31;
  pointer-events: none; }



  .rbc-today {
    background-color: #F5F9FA;
  }

.BigCalendar.rbc-calendar {
  background-color: #F6F9FA;
  padding: 28px 0 0 24px;

  .rbc-time-view {
    border: 0;
  }

  .rbc-time-header {
    border: 0;

    .rbc-header-gutter {
      margin-right: 15px;
    }

    .rbc-row {
      border: 0;
      padding: 25px 0;
    }

    .rbc-row:nth-child(2) {
      display: none;
    }

    .rbc-header {
      border: 0;
      padding-left:40px;
      padding-right:40px;
      display:flex;
      align-items:flex-end;
      justify-content:flex-end;
      font-family: Proxima Nova;
      font-style: normal;
      font-weight: 600;
      font-size: 17px;
      color: #12171A;
      &.rbc-today{
        /*font-weight:bolder;*/
      }

      .rbc-header-today-view{
        text-align:center;
      }

      .rbc-header-date{
        float:right;
        margin-left:10px;
      }
    }
  }

  .rbc-time-content {
    border: 0;

    .rbc-current-time-indicator{
      background-color:rgb(54, 127, 255);
      z-index:3;
      height: 5px;
      background-color: #367FFF;
      pointer-events: none;
      border: 0.6px solid #367FFF;
      box-shadow: 0px 0px 4px #367FFF;
      border-radius:8px;

      display: flex;
      justify-content: space-between;
      align-items:center;
      left: 10px !important;
      right 10px !important;

      &:before{
        content: "";
        border-radius:12px;
        border:1px solid #367FFF;
        width:12px;

        background-color: #367FFF;
        height:12px;
        color:#367FFF;
        box-shadow: 0px 0px 4px #367FFF;
        margin-left:-5px;
      }
      &:after{
        content: "";
        border-radius:12px;
        border:1px solid #367FFF;
        width:12px;
        height:12px;
        background-color: #367FFF;
        color:#367FFF;
        box-shadow: 0px 0px 4px #367FFF;
        margin-right:-5px;
      }
    }

    .rbc-time-column{
      .rbc-time-slot{
        &::after {
          content: "";
          display: flex;
          padding: 5px 0;
          font-size: 12px;
          position: relative;
          align-items: center;
          justify-content: center;
          bottom: unset;
        }
      }
    }

    .rbc-time-gutter {
      margin-right: 15px;

      .rbc-time-slot {
        &::after {
          content: "\\2022";
          display: flex;
          padding: 5px 0;
          font-size: 12px;
          position: relative;
          align-items: center;
          justify-content: center;
          bottom: unset;
          line-height: 1px;
        }
      }
      .rbc-time-slot:first-child {
        display:flex;
        justify-content:center;
        align-items:flex-start;
        &::after {
          content: "";
          display: flex;
          padding: 5px 0;
          font-size: 12px;
          position: relative;
          align-items: center;
          justify-content: center;
          bottom: unset;
          display:none;
        }
      }

      .rbc-timeslot-group {
        border: 0;
      }
    }

    .rbc-timeslot-group {
      height: ${(props) => (props.slotHeight ? props.slotHeight : '188')}px;
      border: 0;
      border-top: 2px solid #EEF1F4;
    }

    .rbc-time-slot.rbc-label {
      font-size: 10px;
      font-weight:bolder;
    }
    /*.rbc-event:hover{
      z-index:10000;
      border-color:transparent;
    }*/
    .rbc-event {
      border: 0;
      color: black;
      /*padding: 12px 14px;
      border-top: 1px solid #F5F9FA;
      border-bottom: 2px solid #F5F9FA;*/

      margin-top: 5px;
      display:flex;
      align-items:center;
      justify-content:center;

      border-left: 30px solid #F5F9FA;
      border-right: 30px solid #F5F9FA;
      border-radius:4px;
      padding-right: 0px;

        .rbc-event-label {
          font-size: 12px;
          order: 2;
          flex-grow: 0;
        }

        .rbc-event-content {
          font-size: 14px;
          flex-grow: 0;
          overflow: hidden;
          font-weight:600;
          width: fit-content;
        }

    }

    .blue {
      background-color: #D4DFFD;
    }
    .orange {
      background-color: #FDEACF;
    }
    .gray{
      background-color: #F5F5F5;
    }

    .rbc-slot-selection {
      background-color: #3C70FF;
      box-shadow: 0 0 10px #3C70FF;
      color: white;
      font-size: 12px;
      width: 100%;
      padding-right: -15px;
      border:none;
    }
  }
}
`;
