import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import lodashValues from 'lodash/values';
import classnames from 'classnames';

import './Button.css';

const AVAILABLE_SIZES = {
  LARGE: 'large',
  SMALL: 'small',
};

const AVAILABLE_THEMES = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
};

const AVAILABLE_TYPES = {
  SUBMIT: 'submit',
  BUTTON: 'button',
  RESET: 'reset',
};

const baseCssClassName = 'Button';

const propTypes = {
  block: PropTypes.bool,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(lodashValues(AVAILABLE_SIZES)),
  theme: PropTypes.oneOf(lodashValues(AVAILABLE_THEMES)),
  type: PropTypes.oneOf(lodashValues(AVAILABLE_TYPES)),
  href: PropTypes.string,
  download: PropTypes.bool,
  title: PropTypes.string,
  uppercase: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
};

const defaultProps = {
  size: AVAILABLE_SIZES.SMALL,
  theme: AVAILABLE_THEMES.PRIMARY,
  type: AVAILABLE_TYPES.BUTTON,
};

class Button extends PureComponent {
  _handleLinkClick = (event) => {
    const { disabled, onClick } = this.props;

    if (disabled) {
      event.preventDefault();
      return;
    }

    if (onClick) {
      onClick(event);
    }
  };

  _renderLink() {
    const { block, size, theme, uppercase, disabled, children, ...rest } = this.props;

    if (disabled) {
      delete rest.onClick;
    }

    return (
      <a
        {...rest}
        onClick={this._handleLinkClick}
        className={classnames([
          baseCssClassName,
          size && `${baseCssClassName}__Size--${size}`,
          theme && `${baseCssClassName}__Theme--${theme}`,
          block && `${baseCssClassName}__Block`,
          uppercase && `${baseCssClassName}__Uppercase`,
          disabled && `${baseCssClassName}__Disabled`,
        ])}
        target={'_blank'}
      >
        {children}
      </a>
    );
  }

  _renderButton() {
    const { block, size, theme, uppercase, disabled, children, ...rest } = this.props;

    return (
      <button
        {...rest}
        disabled={disabled}
        className={classnames([
          baseCssClassName,
          size && `${baseCssClassName}__Size--${size}`,
          theme && `${baseCssClassName}__Theme--${theme}`,
          block && `${baseCssClassName}__Block`,
          uppercase && `${baseCssClassName}__Uppercase`,
          disabled && `${baseCssClassName}__Disabled`,
        ])}
      >
        {children}
      </button>
    );
  }

  render() {
    if (typeof this.props.href !== 'string') {
      return this._renderButton();
    }

    return this._renderLink();
  }
}

Button.AVAILABLE_SIZES = AVAILABLE_SIZES;
Button.AVAILABLE_THEMES = AVAILABLE_THEMES;
Button.AVAILABLE_TYPES = AVAILABLE_TYPES;

Button.propTypes = propTypes;
Button.defaultProps = defaultProps;

export default Button;
