import { constants } from './constants';

export const actions = {
  show,
  success,
  error,
  clear,
};

function show(data) {
  return { type: constants.SHOW_GENERAL, data };
}

function success(message, type) {
  return { type: constants.SUCCESS, message: message, title_type: type };
}

function error(err) {
  return { type: constants.ERROR, message: err.error };
}

function clear() {
  return { type: constants.CLEAR };
}
