import React from 'react';
import { useSelector} from 'react-redux';

import { Box, Flex, Button, Text, useDisclosure } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

import { upperFirst, startCase} from 'lodash';

import { Form, Field } from 'react-final-form';
import { getIntlMessages } from '../App/_redux/selectors';

import { MapModal } from './components/MapModal';

import { BiChevronRight } from 'react-icons/bi';

import { LocationMarkerIcon } from '../_images/icons/LocationMarkerIcon';

import {
    StyledForm,
    StyledField,
    StyledTextArea,
    StyledInputLayout,
    StyledLabel,
    StyledInput,
    ErrorLabel,
    errorCss
  } from '../_css/styles';

import { updateBookingDentistProfile } from './api/_services';
import { useBookingDentistProfile } from './api/getBookingDentistProfile';
import { useBookingClinicProfile, updateQuery as updateClinicQuery } from './api/getBookingClinicProfile';
import { FORM_ERROR } from 'final-form';
//import { useMapStore } from './state/map';


export const Booking = () => {
    const profileQuery = useBookingDentistProfile();
    const clinicQuery = useBookingClinicProfile();
    //const { clear } = useMapStore();
    const intlMessages = useSelector(getIntlMessages);
    const { isOpen, onOpen, onClose } = useDisclosure();

    let initValues = {
        categories: profileQuery.data?.categories || '',
        description: profileQuery.data?.description || '',
    }

    const updateProfile = async (values) => {
        try{
            await updateBookingDentistProfile(values);
        } catch (err) {
            console.log(err);
            return {[FORM_ERROR]: err}
        }
    }

    const handleMapClose = (data) => {
        //clear();
        if (data?.resultData) {
            updateClinicQuery(data?.resultData)
        }
        onClose();
    }

    return (
        <Box>
            <Text as={'h2'} textTransform={'capitalize'}>
                <FormattedMessage
                    id={'settingsPage.menulist.menuitem.booking.label'}
                    defaultMessage={'Booking'}
                />
            </Text>
            <Box py={'10px'} />
            <StyledLabel>
                <FormattedMessage
                    id={"settingsPage.booking.referral.label"}
                    defaultMessage='Referral Code'
                />
            </StyledLabel>
            <Text px={'14px'} pb={'8px'} py={'4px'}>
                { profileQuery.data?.code}
            </Text>
            <Form
            onSubmit={updateProfile}
            initialValues = {initValues}
            render = {({
                submitError,
                handleSubmit,
                dirty,
                submitSucceeded,
                submitting,
            }) => {
                return (
                    <StyledForm onSubmit={handleSubmit}>
                        {submitError && <div className="error">{submitError}</div>}
                        <StyledField name="categories">
                            {({input, meta}) => (
                                <StyledInputLayout>
                                    <StyledLabel>{startCase(intlMessages['settingsPage.booking.categories.label'])}</StyledLabel>
                                    <StyledInput
                                        {...input}
                                        type="text"
                                        addCSS={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)) && errorCss}
                                        placeholder={upperFirst(intlMessages['settingsPage.booking.categories.label'])} />
                                    <ErrorLabel
                                        addCSS={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)) && errorCss}
                                    >
                                        {((meta.error && meta.touched)  || (meta.submitError && !meta.dirtySinceLastSubmit)) && meta.error || meta.submitError}
                                    </ErrorLabel>
                                </StyledInputLayout>
                            )}
                        </StyledField>
                        <Field name="description" parse={v => v} >
                            {({ input, meta}) => (
                            <StyledInputLayout>
                                <StyledLabel>{startCase(intlMessages['settingsPage.booking.description.label'])}</StyledLabel>
                                <StyledTextArea
                                    {...input}
                                    columns={20}
                                    disabled={submitting}
                                    rows={10}
                                    type="text"
                                    addCSS={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)) && errorCss}
                                    placeholder={intlMessages['settingsPage.booking.description.label']}
                                />
                                <ErrorLabel
                                isError={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit))}
                                >
                                    {((meta.error && meta.touched)  || (meta.submitError && !meta.dirtySinceLastSubmit)) && 'error' || meta.submitError}
                                </ErrorLabel>
                            </StyledInputLayout>
                            )}
                        </Field>
                        <StyledLabel>
                                
                            <FormattedMessage 
                                id={'settingsPage.booking.address.label'}
                                defaultMessage="Address"
                            />
                        </StyledLabel>     
                        <Button 
                            w={['full']}
                            variant={'outline'}
                            borderColor={'#c7d3d9'}
                            borderRadius={'3px'}
                            h={['43px']}
                            maxW={['400px']}
                            leftIcon={<LocationMarkerIcon />}
                            rightIcon={<BiChevronRight />}
                            onClick={onOpen}
                        >{
                            !(clinicQuery?.isLoading || clinicQuery?.isFetching) && (
                                <>
                                    {
                                        isOpen && (
                                            <MapModal 
                                                isOpen={isOpen}
                                                onClose={handleMapClose}
                                                data = {clinicQuery.data}
                                            />)
                                    }
                                    <Text overflow={'hidden'} ml={'6px'} pr={'10px'}>
                                        {
                                            clinicQuery?.data?.address ?
                                            clinicQuery?.data?.address :
                                                <FormattedMessage 
                                                    id="settingsPage.booking.location.notset"
                                                    defaultMessage="Location not set"
                                                />
                                        }
                                    </Text>
                                </>
                            )
                        }
                        </Button>

                        <Flex pt={['20px']} w={['full']} justify={['flex-end']}>
                            <Button
                                variant={"solid"}
                                minW={['unset']}
                                isLoading={submitting}
                                isDisabled={!dirty || submitSucceeded}
                                type="submit"
                            >
                                <Text textTransform={'uppercase'} >
                                    <FormattedMessage
                                        id={'reportDetailsPage.report.button.save'}
                                        defaultMessage={'Save'}
                                    />
                                </Text>
                            </Button>
                        </Flex>
                    </StyledForm>
            )}} 
            />
        </Box>
    )
}