import { constants } from './constants';

const initState = {
  videoFrames: {},
  imageIndex: 0,
  videoPlayer: {},
  loadingVideoFrames: false,
};

export function mediaSection(state = initState, action) {
  switch (action.type) {
    case constants.SELECT_INDEX:
      return {
        ...state,
        imageIndex: action.data,
      };
    case constants.UPDATE:
      return {
        ...state,
        ...action.data,
      };
    case constants.UPDATE_STATE:
      return {
        ...state,
        [action.data.key]: action.data.value,
      };
    case constants.UPDATE_STATE_OBJECT:
      return {
        ...state,
        [action.data.key]: Object.assign({}, state[action.data.key], action.data.value),
      };
    default:
      return state;
  }
}
