const PREFIX = 'REQUESTLIST_';
export const constants = {
  GETREQUESTS_REQUEST: `${PREFIX}GETREQUESTS_REQUEST`,
  GETREQUESTS_SUCCESS: `${PREFIX}GETREQUESTS_SUCCESS`,
  GETREQUESTS_FAILURE: `${PREFIX}GETREQUESTS_FAILURE`,
  GETREQUESTSMORE_REQUEST: `${PREFIX}GETREQUESTSMORE_REQUEST`,
  GETREQUESTSMORE_SUCCESS: `${PREFIX}GETREQUESTSMORE_SUCCESS`,
  GETREQUESTSMORE_FAILURE: `${PREFIX}GETREQUESTSMORE_FAILURE`,
  UPDATE: `${PREFIX}UPDATE`,
};
