import React from 'react';
import { Icon } from '@chakra-ui/react';

const AddCircleIcon = (props) => {
  const iconWidth = props?.width || 33;
  const iconHeight = props?.height || 30;
  return (
    <Icon 
      viewBox="0 0 33 30" 
      color={'#405159'} 
      fill={'none'}
      {...props}
      width={`${iconWidth}px`}
      height={`${iconHeight}px`}
    >
        <path d="M31.8919 15C31.8919 22.9784 24.9861 29.5 16.399 29.5C7.81185 29.5 0.906128 22.9784 0.906128 15C0.906128 7.02158 7.81185 0.5 16.399 0.5C24.9861 0.5 31.8919 7.02158 31.8919 15Z" 
            stroke="currentColor"
        />
        <path d="M21.3781 15.225H16.5781V20.55H15.1531V15.225H10.3531V13.925H15.1531V8.75H16.5781V13.925H21.3781V15.225Z" 
            fill="currentColor"
        />

    </Icon>
  );
};

export default AddCircleIcon;
