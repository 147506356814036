import React from 'react';

import { LoadingEllipsisRotated } from 'src/_components/Loaders';

//import { consultRequestsService } from '../_services';

import { ToothChartStyles } from './styles.js';
import { ToothSetSelect } from 'src/_components/ToothSet/ToothSetSelect';

const baseCssClassName = 'ToothMovementChart';
const chartChartingCssClassName = `${baseCssClassName}__charting`;

const loadingOverlayCssClassName = `${baseCssClassName}__loadingOverlay`

//const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

const LoadingOverlay = () => (
  <div className={loadingOverlayCssClassName}>
    <LoadingEllipsisRotated viewBox={'5 20 50 100'}  />
  </div>
)


class ToothChartSelect extends React.Component{
  constructor(props){
    super(props)
    this.toothMenu = React.createRef();
    this.toothType = React.createRef();
    this.state = {
      charting: {},
      initializing: true,
      isSaving: false,
      isDirty: false
    }
  }

  async componentDidMount(){
    // get initial tooth state from db
    // probably just json
    //const response = await consultRequestsService.getToothChart(this.props.requestId, this.props.chartType);

    //await sleep(1000);
    const response = {chart: {charting:{}}};
    this.setState({initializing:false, ...response.chart });
  }

  handleSave = async () => {
    this.setState({isSaving:true});
    //const toothChart = await sleep(400);
    //const data = {charting: this.props.charting, id:this.state.id}
    //const toothChart = await consultRequestsService.updateToothChart(this.props.requestId, data, 'movement');
    this.setState({isSaving:false, isDirty:false});
  }


  handleClick = (toothId) => {
    let newCharting = {...this.props.charting, [toothId]: (this.props.charting[toothId] === 'move') ? '' : 'move'}
    this.setState(() => ({
      isDirty: true ,
      charting: newCharting
    }));
    this.props.handleChange(newCharting)

    // mark tooth to state
    //this.setState((state) => ({ isDirty: true , charting: {...state.charting, [toothId]: toothState} }));
    //this.toothMenu.current.tooltipRef = null;
    //ReactTooltip.hide();
  }

  render(){
    //const chartTitle =  (this.props.chartType === 'movement') ? 'Movement Chart' : 'Patient Condition' ;
    //const chartSubTitle = (this.state.jaw !== 'BOTH') ? intlData[`toothSet.movement.title.jaw.${this.state.jaw}`] : '';
    return (
      <ToothChartStyles className={baseCssClassName}>
        <div className={chartChartingCssClassName}>
          { (this.state.initializing || this.state.isSaving) &&
            <LoadingOverlay />
          }
          <ToothSetSelect
            charting = {this.props.charting}
            chartType = {this.props.chartType}
            onClick= {this.handleClick}
            className= {`ToothSet__${this.state.jaw}`}
          />
        </div>
      </ToothChartStyles>
    )
  }
}


export {ToothChartSelect}
