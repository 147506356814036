import React from "react";
import { Box } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { CreateReport } from "../components/CreateReport";

export const ReportForRequestPage = () => {
    const { requestId } = useParams();
    return (
        <Box h={'100%'} >
            <CreateReport requestUid={requestId} />
        </Box>
    )
}