import React from 'react';
import { Icon } from '@chakra-ui/react';

export const LocationMarkerIcon = (props) => {
  const iconWidth = props?.width || 11;
  const iconHeight = props?.height || 14;
  return (
    <Icon 
      viewBox="0 0 11 14"
      color={'#405159'}
      {...props}
      width={`${iconWidth}px`}
      height={`${iconHeight}px`}
    >
        <path 
            d="M5.5 3.05421C5.01935 3.05421 4.54948 3.19602 4.14984 3.4617C3.75019 3.72739 3.4387 4.10502 3.25476 4.54683C3.07082 4.98865 3.02269 5.47481 3.11646 5.94384C3.21023 6.41287 3.44169 6.8437 3.78157 7.18185C4.12144 7.52001 4.55447 7.75029 5.02589 7.84359C5.4973 7.93688 5.98594 7.889 6.43001 7.70599C6.87408 7.52299 7.25363 7.21308 7.52066 6.81545C7.7877 6.41783 7.93023 5.95035 7.93023 5.47213C7.93023 4.83086 7.67419 4.21585 7.21843 3.7624C6.76268 3.30896 6.14454 3.05421 5.5 3.05421ZM5.5 7.12649C5.17113 7.12649 4.84965 7.02947 4.5762 6.84768C4.30276 6.6659 4.08963 6.40752 3.96378 6.10523C3.83793 5.80293 3.805 5.47029 3.86916 5.14938C3.93332 4.82846 4.09168 4.53368 4.32423 4.30232C4.55678 4.07095 4.85306 3.91339 5.17561 3.84955C5.49816 3.78572 5.83249 3.81848 6.13632 3.9437C6.44016 4.06891 6.69985 4.28095 6.88256 4.55301C7.06527 4.82507 7.16279 5.14493 7.16279 5.47213C7.16279 5.91089 6.9876 6.33169 6.67577 6.64194C6.36394 6.95219 5.941 7.12649 5.5 7.12649ZM5.5 0C4.04183 0.00168402 2.64387 0.578751 1.61278 1.60461C0.581699 2.63047 0.0016926 4.02135 0 5.47213C0 7.43891 0.917093 9.52787 2.65215 11.5131C3.43527 12.4135 4.31664 13.2245 5.28 13.931C5.34449 13.9759 5.42129 14 5.5 14C5.57871 14 5.65551 13.9759 5.72 13.931C6.68336 13.2245 7.56473 12.4135 8.34785 11.5131C10.0829 9.52787 11 7.44082 11 5.47213C10.9983 4.02135 10.4183 2.63047 9.38722 1.60461C8.35613 0.578751 6.95817 0.00168402 5.5 0ZM5.5 13.1401C4.5407 12.4033 0.767442 9.26571 0.767442 5.47213C0.767442 4.22334 1.26605 3.02569 2.15358 2.14266C3.0411 1.25963 4.24485 0.763553 5.5 0.763553C6.75515 0.763553 7.9589 1.25963 8.84642 2.14266C9.73395 3.02569 10.2326 4.22334 10.2326 5.47213C10.2326 9.26571 6.4593 12.4033 5.5 13.1401Z" 
            fill="currentColor"
        />

    </Icon>
  )
}