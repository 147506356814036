import React from 'react';
import { Skeleton } from '@chakra-ui/react';
import { 
  FormControl, 
  FormLabel, 
  FormErrorMessage 
} from '@chakra-ui/react';
import { SelectFieldFromDefault } from './SelectComponents';

export const SelectOptionField = ({input, placeholder, isInvalid, selectOptions, isMultiple=false, label="", error="", isLoading=false, isDisabled=false}) => {

    if (isLoading){
      return <Skeleton height={'45px'} w={'full'} />
    }
  
    return (
      <FormControl id={input.name} isInvalid={isInvalid} >
        <FormLabel align="center">
          {label}
        </FormLabel>
        <SelectFieldFromDefault 
          input={input}
          name={input.name}
          placeholder={placeholder}
          isDisabled={isDisabled}
          multi={isMultiple}
          isError={isInvalid}
          options={selectOptions}
        />
        <FormErrorMessage>{error ? error : ""}</FormErrorMessage>
      </FormControl>
    )
  }
  