import React from 'react';
import moment from 'moment';

export const AgendaDateHeader = ({ label, onDrillDown }) => {
  return (
    <a href="#" onClick={onDrillDown}>
      {label}
    </a>
  );
};

export const DayDateHeader = ({ label, drilldownView, onDrillDown, date }) => {
  const moment_date = moment(date);
  if (!drilldownView) {
    return <div className="rbc-header-today-view">{moment_date.format('dddd, MMMM D')}</div>;
  }

  return (
    <a href="#" onClick={onDrillDown}>
      {label}
    </a>
  );
};

export const ThreeDayDateHeader = ({ label, drilldownView, onDrillDown, date }) => {
  const moment_date = moment(date);
  if (!drilldownView) {
    return (
      <div className="rbc-header-title">
        <span className="rbc-header-dayofweek">{moment_date.format('dddd')}</span>
        <span className="rbc-header-date">{moment_date.format('D')}</span>
      </div>
    );
  }

  return (
    <a href="#" onClick={onDrillDown}>
      {label}
    </a>
  );
};
