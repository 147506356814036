import { createSelector } from 'reselect';

const getPatientsSlice = (state) => state.patients;

export const getPatientsList = createSelector([getPatientsSlice], (patients) => {
  return patients.patients;
});

export const getPatientsListLoading = createSelector([getPatientsSlice], (patients) => {
  return patients.loading;
});

export const getPatientsById = createSelector([getPatientsSlice], (patients) => {
  return patients.byId;
});

export const getNewPatientFormOpen = createSelector([getPatientsSlice], (patients) => {
  return patients.newPatientFormModal;
});

export const getPatientRecords = createSelector([getPatientsSlice], (patients) => {
  return patients.currentPatientRecords;
});

export const getPatientRecordsError = createSelector([getPatientsSlice], (patients) => {
  return patients.errorRecords;
});

export const getPatientRecordsLoading = createSelector([getPatientsSlice], (patients) => {
  return patients.loadingRecords;
});

export const getPatientRequestsError = createSelector([getPatientsSlice], (patients) => {
  return patients.errorRequests;
});

export const getPatientRequestsLoading = createSelector([getPatientsSlice], (patients) => {
  return patients.loadingRequests;
});

export const getPatientRequests = createSelector([getPatientsSlice], (patients) => {
  return patients.currentPatientRequests || [];
});

export const getPatientAppointmentsError = createSelector([getPatientsSlice], (patients) => {
  return patients.errorAppointments;
});

export const getPatientAppointmentsLoading = createSelector([getPatientsSlice], (patients) => {
  return patients.loadingAppointments;
});

export const getPatientAppointments = createSelector([getPatientsSlice], (patients) => {
  return patients.currentPatientAppointments || [];
});

export const getCurrentPatient = createSelector([getPatientsSlice], (patients) => {
  return patients.currentPatientId;
});

export const getPatientInformation = createSelector(
  [getPatientsById, getCurrentPatient],
  (patientsById, currentPatientId) => {
    return patientsById[currentPatientId] || {};
  }
);

export const getCurrentPatientUid = createSelector([getPatientInformation], (info) => {
  return info ? info.currentPatientId : null;
});
