import { combineReducers } from 'redux';

import { intlReducer } from 'react-intl-redux';
import { connectRouter } from 'connected-react-router';
import { userInfo } from '../../App/_redux/reducer';
import { alertDialog } from '../../AlertDialog/_redux/reducer';
import { chatsList } from '../../ChatPage/_redux/reducer';
import { dashboardPage } from '../../Dashboard/_redux/reducer';
import { requestList } from '../../RequestListPage/_redux/reducer';
import { patientDetailPage } from '../../PatientDetailsPage/_redux/reducer';
import { requestDetail } from '../../RequestDetailPage/_redux/reducer';
import { openRequests } from '../../OpenRequestsPage/_redux/reducer';
import { vidChatAppointments } from '../../Appointments/_redux/reducer';
import { patients } from '../../Patients/_redux/reducer';
import { reportToothChart } from '../../ReportToothChart/_reducer';
import { createReport } from '../../CreateReport/_redux/reducer';
import { reportChartForm } from '../../CreateReport/ReportChartForm/_reducer';
import { patientRecords } from '../../PatientRecords/_redux/reducer';
import { patientReports } from '../../PatientReports/_redux/reducer';
import { patientNotes } from '../../PatientNotes/_redux/reducer';
import { patientRequests } from '../../PatientRequests/_redux/reducer';
import { patientAppointments } from '../../PatientAppointments/_redux/reducer';
import { mediaSection } from '../../MediaSection/_redux/reducer';
import { dentists } from '../../Dentists/_redux/reducer';

import {
  createNetworkStatusReducer,
  createMessageReducer,
  createPresenceReducer,
  createUserDataReducer,
  createUsersListReducer,
  createChannelDataReducer,
  createChannelsListReducer,
  createMembershipReducer,
  createChannelMembersReducer,
} from 'pubnub-redux';

const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    intl: intlReducer,
    alert: alertDialog,
    dashboardPage,
    userInfo,
    requestList,
    requestDetail,
    patientDetailPage,
    chatsList,
    openRequests,
    vidChatAppointments,
    patients,
    reportToothChart,
    reportChartForm,
    createReport,
    patientRecords,
    patientReports,
    patientRequests,
    patientAppointments,
    patientNotes,
    mediaSection,
    dentists,
    // pubnub reducers
    pnNetworkStatus: createNetworkStatusReducer(false),
    pnMessages: createMessageReducer(),
    pnPresence: createPresenceReducer(),
    pnUserData: createUserDataReducer(),
    pnUsersList: createUsersListReducer(),
    pnChannelData: createChannelDataReducer(),
    pnChannelList: createChannelsListReducer(),
    pnMembership: createMembershipReducer(),
    pnChannelMembers: createChannelMembersReducer(),
  });

export default rootReducer;
