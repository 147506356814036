import { useQuery } from 'react-query';
import { queryConfig } from 'src/_libs/react-query';

import { fetchRecord } from './services';

export const QUERY_KEY_NAME = 'RecordDetails';

const newQueryConfig = {
    ...queryConfig,
    queries: {
        ...queryConfig.queries,
        refetchOnMount: false
    }
}

export const queryRecord = async ({ recordId }) => {
    return fetchRecord(recordId);
}

export const useRecordDetails = ({ recordId, config={} }) => {
    return useQuery({
        ...newQueryConfig,
        ...config,
        enabled: !!recordId,
        queryKey: [QUERY_KEY_NAME, {recordId}],
        queryFn: () => queryRecord({recordId}),
      });
}