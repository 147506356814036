import { getScrollSize } from './general';

/**
 * Allows to control the app container layout to prevent scrolling when a modal window is opened.
 */
class GlobalPopupController {
  _lastId = 0;

  _stack = [];

  _scrollX = 0;
  _scrollY = 0;

  add() {
    const nextId = ++this._lastId;
    this._stack.push(nextId);

    if (this._stack.length === 1) {
      this._overlaySetup();
    }

    return nextId;
  }

  remove(id) {
    this._stack = this._stack.filter((_id) => _id !== id);

    if (this._stack.length === 0) {
      this._overlayTeardown();
    }
  }

  /**
   * The overlay implementation is inspired by the Facebook's one.
   * The page content gets the fixed position and offset equal to the current scroll position to avoid the jump.
   * @see http://www.facebook.com/
   *
   * For the scrolling to work on mobile devices, it is crucial to constrain the body size to the device (window) size.
   * @see http://stackoverflow.com/a/18827652
   */
  _overlaySetup() {
    const page = document.getElementById('app');
    const body = window.document.body;

    // Save the body scrolling position:
    this._scrollX = window.scrollX;
    this._scrollY = window.scrollY;

    body.style.overflowY = 'hidden';

    page.style.position = 'fixed';
    page.style.left = `${-this._scrollX}px`;
    page.style.top = `${-this._scrollY}px`;
    page.style.right = getScrollSize() + 'px';

    // Reset the scroll position:
    window.scrollTo(0, 0);
  }

  _overlayTeardown() {
    const page = document.getElementById('app');
    const body = window.document.body;

    page.style.position = '';
    page.style.width = '';
    page.style.left = '';
    page.style.top = '';
    page.style.right = '';

    body.style.overflowY = '';

    // Revert the scroll position:
    window.scrollTo(this._scrollX, this._scrollY);

    // Forget the stored values:
    this._scrollX = 0;
    this._scrollY = 0;
  }
}

export default GlobalPopupController;
