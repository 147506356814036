import { create } from 'zustand';

export const useAppConfig = create((set) => ({
    clinic: null,
    setConfig: (config, value) => 
        set((state) => ({
            ...state,
            [config]: value
        })),

}));