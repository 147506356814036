import React from 'react';

import styled from 'styled-components';
import cn from 'classnames';
import { Group } from '@visx/group';
import { HeatmapRect } from '@visx/heatmap';
import { scaleLinear } from '@visx/scale';
import { range } from 'lodash';

const neutral1 = '#7A8F99';
const neutral2 = '#6a767e';
const playBar = '#367FFFE0';
// accessors
const bins = (d) => d.bins;
const count = (d) => d.count;

function max(data, value) {
  return Math.max(...data.map(value));
}

//function min(data, value) {
//  return Math.min(...data.map(value));
//}

const StyledDetections = styled.div`
  background-color: white;

  .detectionSeekBar {
    &.notSeekable {
      visibility: hidden;
    }
  }

  .visx-heatmap-rect {
    &.detected {
      &:hover {
        cursor: crosshair;
      }
    }
  }
`;

export const Detections = ({ currentDetections, totalFrames, loaded, currFrame, onMouseEnter }) => {
  if (loaded === false && totalFrames < 1) {
    return <StyledDetections></StyledDetections>;
  }

  let binData = range(1, totalFrames + 1).reduce((acc, curr) => {
    // [timestamp in ms, frame number, array of detections [score, left, top, right, bottom]]
    // uusally for detections, but when its not, alternate colors
    acc.push({
      bin: curr,
      bins: [
        {
          bin: 1,
          count:
            currentDetections[curr] && currentDetections[curr][2]
              ? currentDetections[curr][2].reduce((a, c) => Math.max(a, Number(c[0])), 0)
              : 0,
        },
      ],
    });
    return acc;
  }, []);

  //accessors
  let colorMax = max(binData, (d) => max(bins(d), count));
  const bucketSizeMax = max(binData, (d) => bins(d).length);

  if (colorMax == 0) {
    colorMax = 1;
  }

  const margin = { top: 10, left: 0, right: 0, bottom: 10 };
  const xMax = 640 - margin.left - margin.right;
  const yMax = 50 - margin.bottom - margin.top;
  // DEBUG console.log("total frames, xmax, ymax ", size, xMax, yMax)

  const binWidth = xMax / binData.length;
  const binHeight = yMax / 1;

  // scales
  const xScale = scaleLinear({
    domain: [0, binData.length],
    range: [0, xMax],
  });
  const yScale = scaleLinear({
    domain: [0, bucketSizeMax],
    range: [10, yMax],
  });

  // DEBUG console.log("y scale", yMax, bucketSizeMax)

  const circleColorScale = scaleLinear({
    domain: [0, colorMax],
    range: [neutral1, neutral2],
    //range: [hot1, hot2],
  });
  const opacityScale = scaleLinear({
    domain: [0, colorMax],
    range: [0.2, 1],
  });

  return (
    <StyledDetections>
      <svg width={640} height={60}>
        <Group left={margin.left} top={margin.top}>
          <HeatmapRect
            data={binData}
            xScale={xScale}
            yScale={yScale}
            colorScale={circleColorScale}
            opacityScale={opacityScale}
            binWidth={binWidth}
            binHeight={binHeight}
            gap={0}
          >
            {(heatmap) =>
              heatmap.map((heatmapBins) =>
                heatmapBins.map((bin) => (
                  <rect
                    key={`heatmap-rect-${bin.row}-${bin.column}`}
                    className={cn('visx-heatmap-rect', { detected: bin.opacity > 0 })}
                    width={bin.width}
                    height={bin.height}
                    x={bin.x}
                    y={bin.y}
                    fill={bin.color}
                    fillOpacity={bin.opacity ? bin.opacity : 0}
                    onMouseEnter={() => {
                      bin.opacity > 0 && onMouseEnter(bin.column);
                    }}
                  />
                ))
              )
            }
          </HeatmapRect>
        </Group>
        <rect
          className={cn('detectionSeekBar', { notSeekable: !loaded })}
          x={xScale(currFrame) || 0}
          y={14}
          width={binWidth}
          height={42}
          rx={2}
          fill={playBar}
        />
      </svg>
    </StyledDetections>
  );
};
