import React from 'react';
import { Form, Field } from 'react-final-form';

import { cloneDeep } from "lodash";
import {
    Box, Flex, SimpleGrid, Button, Center, Heading,
    Input, FormControl, FormLabel, FormErrorMessage, VStack, InputGroup, InputRightElement,
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, useDisclosure,
} from '@chakra-ui/react';
import { InputComponent } from 'src/_components/Forms/InputComponent';
import { SelectOptionField } from 'src/_components/Forms/SelectOptionField';
import { FormattedMessage, useIntl } from 'react-intl';
import { ClinicSearch } from './ClinicSearch';
import { required } from 'src/_components/FormElements';
import { useSelectOptions } from '../api/getSelectOptions';
import { ClinicForm } from './ClinicForm';
import { convertToAPIValues, generatePassword } from 'src/_libs/forms';
import { createAccount } from '../api/services';

const ClinicFormModal = ({
    isOpen,
    onClose,
    onSuccess
}) => {
    // email, password, first_name, last_name, date_of_birth, gender, user_type, country, categories, description, 
    // clini_id, account_role... 
    // clinic_name, branch, address, timezone, geopoint

    const handleOnSuccess = (values) => {
        // sending to a selec tocmponent, so need value and label

        const selectValue = {
            value: values.id,
            label: values.name,
            data: values
        }
        onSuccess(selectValue);
        onClose();
    }

    // testing
    const handleClose = () => {
        onClose();
    }


    return (
        <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} isCentered>
          <ModalOverlay bg="blackAlpha.300" />
          <ModalContent minW="700px" bg="#f7f9fa">
            <ModalHeader>
              <Center>
                    <FormattedMessage 
                        id={'adminPage.clinicFormModal.title'}
                        defaultMessage={'Add New Clinic'}
                    />
              </Center>
            </ModalHeader>
            <ModalCloseButton/>
            <ModalBody>
                <Heading as={'h2'}>
                    <FormattedMessage 
                        id={'adminPage.clinicForm.title'}
                        defaultMessage={'Clinic Details - Public'}
                    />
                </Heading>
              <Box>
                <ClinicForm
                  onSuccess={handleOnSuccess}
                  onClose={handleClose}
                />
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>
    )
}

const AddNewClinicButton = ({onSuccess}) => {
    const { isOpen, onClose, onOpen } = useDisclosure();

    return (
        <>
            <ClinicFormModal isOpen={isOpen} onClose={onClose} onSuccess={onSuccess} />
            <Flex direction={"column"} textAlign={"right"} w={["full"]}>
                <FormLabel visibility={'hidden'} pb={"3.5px"}>{"-"}</FormLabel>
                <Button 
                    variant={'outline'}
                    onClick={onOpen}
                    alignSelf={'flex-end'}
                    h={'45px'}
                >
                    <FormattedMessage 
                        id={'adminPage.form.addNewClinic.button.label'}
                        defaultMessage='+ Add New'
                    />
                </Button>
            </Flex>
        </>
    )
}

const PasswordComponent = ({ label, placeholder, name, type="text", value, isInvalid, error, onChange, onGenerate }) => {
    const handleClick = () => onGenerate(generatePassword());
  
    return (
        <VStack>
            <FormControl id={name} isInvalid={isInvalid}>
                <FormLabel>{label}</FormLabel>
                <InputGroup>
                    <Input
                        placeholder={placeholder} type={type} value={value} onChange={onChange} isInvalid={isInvalid}
                    />
                    <InputRightElement width='80px' mr={'10px'}>
                        <Button h='35px' variant={'ghost'} onClick={handleClick}>
                            <FormattedMessage
                                id={'adminPage.form.field.password.generate'}
                                defaultMessage={'Generate'}
                            />
                        </Button>
                    </InputRightElement>
                </InputGroup>
                <FormErrorMessage>{error ? error  : ""}</FormErrorMessage>
            </FormControl>
        </VStack>
     
    )
  }

/**
 * Creates a new user, dentist, and assigns them to a clinic
 * @param {*} param0 
 * @returns 
 */
export const UserForm = ({
    onSuccess,
    onClose,
    initValues = {},
  }) => {
    const { data, isLoading, isFetching } = useSelectOptions({'option': 'countries'});
    const { data : gendersData, isLoading : gendersIsLoading, isFetching : gendersIsFetching } = useSelectOptions({'option': 'genders'});
    const { data : rolesData, isLoading: rolesIsLoading , isFetching: rolesIsFetching } = useSelectOptions({'option': 'account_roles'});
    const { formatMessage } = useIntl();
    
    const colSpacing = 4;
    const rowSpacing = 4;

    const handleSubmit = async (values) => {
        const valuesCopy = cloneDeep(values);
        const convertedValues = convertToAPIValues(valuesCopy);
        try{
            const result = await createAccount(convertedValues);
            onSuccess && onSuccess(result);
            onClose();
            return null;
        } catch (err) {
            return {
              ...err,
            };
        }
    }

    return (
        <Form
            onSubmit={handleSubmit}
            initialValues={initValues}
            mutators={{ 
              setValue: ([field, value], state, { changeValue }) => {
                changeValue(state, field, () => value)
              }
            }}
            render = {
                ({
                    handleSubmit,
                    submitting,
                    form: { mutators: { setValue } } // pass custom mutator here
                }) => {
                    return (
                        <Box as={'form'} onSubmit={handleSubmit}>
                            <SimpleGrid columns={[1]} spacing={colSpacing} mt={rowSpacing}>
                                <Field name="country" validate={required}>
                                    {({input, meta}) => (
                                        <SelectOptionField 
                                            isLoading={isFetching || isLoading}
                                            label={formatMessage({id: 'adminPage.form.field.country.label', defaultMessage: 'Country'})}
                                            placeholder={formatMessage({id: 'adminPage.form.field.country.label', defaultMessage: 'Country'})}
                                            input={input}
                                            selectOptions={data?.options}
                                            isInvalid={meta.touched && meta.error ? true : false}
                                            error={meta?.error}
                                        />
                                    )}
                                </Field>
                            </SimpleGrid>
                            <SimpleGrid columns={[2]} spacing={colSpacing}  mt={rowSpacing}>
                                <Field name="email" validate={required}>
                                    {({input, meta}) => (
                                        <InputComponent 
                                            label={formatMessage({id: 'adminPage.form.field.login_email.label', defaultMessage: 'Login Email'})}
                                            placeholder={formatMessage({id: 'adminPage.form.field.login_email.label', defaultMessage: 'Login Email'})}
                                            {...input}
                                            isInvalid={meta.touched && meta.error ? true : false}
                                            error={meta?.error}
                                        />
                                    )}
                                </Field>
                                <Field name="password" validate={required}>
                                    {({input, meta}) => (
                                        <PasswordComponent 
                                            label={formatMessage({id: 'adminPage.form.field.password.label', defaultMessage: 'Password'})}
                                            placeholder={formatMessage({id: 'adminPage.form.field.password.label', defaultMessage: 'Password'})}
                                            {...input}
                                            onGenerate={(value) => setValue('password', value)}
                                            isInvalid={meta.touched && meta.error ? true : false}
                                            error={meta?.error}
                                        />
                                    )}
                                </Field>
                            </SimpleGrid>
                            <SimpleGrid columns={[2]} spacing={colSpacing} mt={rowSpacing}>
                                <Field name="first_name" validate={required}>
                                    {({input, meta}) => (
                                        <InputComponent 
                                            label={formatMessage({id: 'adminPage.form.field.first_name.label', defaultMessage: 'First Name'})}
                                            placeholder={formatMessage({id: 'adminPage.form.field.first_name.label', defaultMessage: 'First Name'})}
                                            {...input}
                                            isInvalid={meta.touched && meta.error ? true : false}
                                            error={meta?.error}
                                        />
                                    )}
                                </Field>
                                <Field name="last_name" validate={required}>
                                    {({input, meta}) => (
                                        <InputComponent 
                                            label={formatMessage({id: 'adminPage.form.field.last_name.label', defaultMessage: 'Last Name'})}
                                            placeholder={formatMessage({id: 'adminPage.form.field.last_name.label', defaultMessage: 'Last Name'})}
                                            {...input}
                                            isInvalid={meta.touched && meta.error ? true : false}
                                            error={meta?.error}
                                        />
                                    )}
                                </Field>
                            </SimpleGrid>
                            <SimpleGrid columns={[2]} spacing={colSpacing} mt={rowSpacing}>
                                <Field name="date_of_birth">
                                    {({input, meta}) => (
                                        <InputComponent 
                                            label={formatMessage({id: 'adminPage.form.field.date_of_birth.label', defaultMessage: 'Date of Birth'})}
                                            placeholder={formatMessage({id: 'adminPage.form.field.date_of_birth.label', defaultMessage: 'Date of Birth'})}
                                            {...input}
                                            type="date"
                                            isInvalid={meta.touched && meta.error ? true : false}
                                            error={meta?.error}
                                        />
                                    )}
                                </Field>
                                <Field name="gender">
                                    {({input, meta}) => (
                                        <SelectOptionField 
                                            isLoading={gendersIsFetching || gendersIsLoading}
                                            isMultiple={false}
                                            label={formatMessage({id: 'adminPage.form.field.gender.label', defaultMessage: 'Gender'})}
                                            placeholder={formatMessage({id: 'adminPage.form.field.gender.label', defaultMessage: 'Gender'})}
                                            input={input}
                                            selectOptions={gendersData?.options}
                                            isInvalid={meta.touched && meta.error ? true : false}
                                            error={meta?.error}
                                        />
                                    )}
                                </Field>
                            </SimpleGrid>
                            <SimpleGrid columns={[1]} spacing={colSpacing} mt={rowSpacing}>
                                <Field name="categories">
                                    {({input, meta}) => (
                                        <InputComponent 
                                            label={formatMessage({id: 'adminPage.form.field.dentist.categories.label', defaultMessage: 'Categories'})}
                                            placeholder={formatMessage({id: 'adminPage.form.field.categories.label', defaultMessage: 'Categories'})}
                                            {...input}
                                            isInvalid={meta.touched && meta.error ? true : false}
                                            error={meta?.error}
                                        />
                                    )}
                                </Field>
                            </SimpleGrid>
                            <SimpleGrid columns={[1]} spacing={colSpacing} mt={rowSpacing}>
                                <Field name="description" >
                                    {({input, meta}) => (
                                        <InputComponent 
                                            type='textarea'
                                            label={formatMessage({id: 'adminPage.form.field.dentist.description.label', defaultMessage: 'Description'})}
                                            placeholder={formatMessage({id: 'adminPage.form.field.dentist.description.label', defaultMessage: 'Description'})}
                                            {...input}
                                            isInvalid={meta.touched && meta.error ? true : false}
                                            error={meta?.error}
                                        />
                                    )}
                                </Field>
                            </SimpleGrid>
                            <SimpleGrid templateColumns={['3fr 1fr']} spacing={colSpacing}  mt={rowSpacing}>
                                <Field name="clinic_id" validate={required}>
                                    {({ input, meta }) => (
                                        <ClinicSearch 
                                            input={input}
                                            meta={meta}
                                            isInvalid={meta.touched && meta.error ? true : false}
                                            error={meta?.error}
                                            label={formatMessage({id: 'adminPage.form.field.clinic.label', defaultMessage: 'Clinic'})}
                                            placeholder={formatMessage({id: 'adminPage.form.field.clinic.placeholder', defaultMessage: 'Search for a clinic'})}
                                            onSelect={(val) => {
                                                input.onChange(val);
                                            }}
                                        />
                                    )}
                                </Field>
                                <AddNewClinicButton onSuccess={(value) => setValue('clinic_id', value)} />
                            </SimpleGrid>
                            <SimpleGrid columns={[1]} spacing={colSpacing} mt={rowSpacing}>
                                <Field name="account_role" validate={required}>
                                    {({input, meta}) => (
                                        <SelectOptionField 
                                            isLoading={rolesIsFetching || rolesIsLoading}
                                            isMultiple={true}
                                            label={formatMessage({id: 'adminPage.form.field.account_roles.label', defaultMessage: 'Roles'})}
                                            placeholder={formatMessage({id: 'adminPage.form.field.account_roles.label', defaultMessage: 'Roles'})}
                                            input={input}
                                            selectOptions={rolesData?.options}
                                            isInvalid={meta.touched && meta.error ? true : false}
                                            error={meta?.error}
                                        />
                                    )}
                                </Field>
                            </SimpleGrid>
                            <SimpleGrid columns={[2]} spacing={colSpacing}  mt={10}>
                                <Button variant={'outline'} onClick={onClose} isDisabled={submitting}>
                                    <FormattedMessage 
                                        id="adminPage.form.cancel.button.label"
                                        defaultMessage="Cancel"
                                    />
                                </Button>
                                <Button type="submit" isLoading={submitting} isDisabled={submitting}>
                                    <FormattedMessage 
                                        id="adminPage.form.submit.button.label"
                                        defaultMessage="Submit"
                                    />
                                </Button>
                            </SimpleGrid>
                        </Box>
                    )
                }
            }
        />
    )
}