import React from 'react';
import { Box, Flex, Text, Circle, Progress, CloseButton } from '@chakra-ui/react';
import { UploadIcon } from 'src/_images/icons/UploadIcon';
import { FormattedMessage } from 'react-intl';

export const ScanRegionTitle = ({region}) => {
    if (region == 0){
        return (
            <FormattedMessage 
                id={'checkup.upload.region.label.0'}
                defaultMessage={'lower outer region'}
            />
        )
    } else if (region == 1){
        
        return (
            <FormattedMessage 
                id={'checkup.upload.region.label.1'}
                defaultMessage={'lower inner region'}
            />
        )
    } else if (region == 2){
        
        return (
            <FormattedMessage 
                id={'checkup.upload.region.label.2'}
                defaultMessage={'upper outer region'}
            />
        )
    } else if (region == 3){
        
        return (
            <FormattedMessage 
                id={'checkup.upload.region.label.3'}
                defaultMessage={'upper inner region'}
            />
        )
    }
    return (<></>)
}

const formatFileSize = (bytes, unit = 'MB') => {
    if (!bytes) { return 0}
    if (unit.toUpperCase() === 'KB') {
      return (bytes / 1024);
    } else { // Default to MB if not specified or recognized
      return (bytes / (1024 * 1024));
    }
  };

const formatNumber = (num) => {
    // returns decimal to tenths place if number is less than 10
    // otherwise, returns the number with no decimal places
    if (!num) {
        return "0.0";
    }
    if (num < 10) {
        return num.toFixed(1);
    } else {
        return num.toFixed(0);
    }
}

export const UploadPreview = ({selectedFile, innerBoxSize, handleRemove}) => {
    return (
        <Box borderRadius={'10px'} w={innerBoxSize} height={innerBoxSize} pos={'relative'}>
            <video
                style={{
                    objectFit: 'cover',
                    borderRadius: '10px',
                    width: innerBoxSize,
                    height: innerBoxSize,
                    position: 'absolute'
                }}
                src={selectedFile?.previewUrl}
            />   
            { (!selectedFile?.uploadCompleted || selectedFile?.progress > 0) &&
                <Circle 
                    bg={'#273238'} 
                    align={'center '}
                    justify={'center'}
                    size={'26px'} pos={'absolute'} 
                    right={'5px'} top={'5px'}
                    
                    _hover={{
                        cursor: 'pointer',
                    }}
                >
                <CloseButton isDisabled={selectedFile?.isUploading || selectedFile?.uploadCompleted} onClick={handleRemove} color={'#FFF'} size={'sm'} />
            </Circle>
            }
            {(selectedFile?.isUploading || selectedFile?.uploadCompleted) ? (
                <Flex 
                    direction={'column'}
                    px={'10px'}
                    pos={'absolute'} 
                    bottom={'10px'} 
                    w={innerBoxSize}
                    justify={'flex-start'}
                    align={'flex-start'}
                    textAlign={'left'}
                >
                    <Flex
                        position={'relative'}
                        w={['full']}
                        justify={'center'}
                        pb={'10px'}
                    >
                        <Text
                            color={'#FFF'}
                            fontSize={'18px'}
                            textAlign={'center'}
                            mixBlendMode={'screen'}
                        >
                            {`${formatNumber((selectedFile?.progress) * formatFileSize(selectedFile?.data?.size))} / ${formatNumber(formatFileSize(selectedFile?.data?.size))} MB`}
                        </Text>

                    </Flex>
                    <Progress 
                        position={'relative'}
                        w={'full'}
                        value={selectedFile?.progress ? selectedFile?.progress * 100 : 0} 
                        size={'xs'}
                        borderRadius={'5px'}
                        colorScheme={'teal'}
                    />
                </Flex>
            ) : (
                <Flex 
                    direction={'column'}
                    px={'10px'}
                    pos={'absolute'} 
                    bottom={'10px'} 
                    w={innerBoxSize}
                    justify={'flex-start'}
                    align={'flex-start'}
                    textAlign={'left'}
                >
                    <Flex
                        position={'relative'}
                        w={['full']}
                        overflow={'hidden'}
                        justify={'center'}
                        pb={'10px'}
                    >
                        <Text
                            w={['full']}
                            color={'#FFF'}
                            fontSize={'14px'}
                            textAlign={'left'}
                            noOfLines={2}
                            mixBlendMode={'screen'}
                        >
                            {selectedFile?.data?.name}
                        </Text>

                    </Flex>
                </Flex>
            ) }
        </Box>
    )
}

export const UploadPlaceholder = ({innerBoxSize, dragOver, handleDragOver, handleDragEnter, handleDragLeave, handleDrop, handleFileInputClick}) => {
    return (
        <Flex
            direction={'column'}
            w={'full'}
            align={'center'}
            justify={'center'}
            height={innerBoxSize}
            width={innerBoxSize}
            onDragOver={handleDragOver}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            onClick={handleFileInputClick}
            _hover={{
                cursor: 'pointer',
            }}
        >
            <UploadIcon color={dragOver ? '#FFF' : '#D3DCE0'} />
            <Text
                mt={'5px'}
                color={dragOver ? '#FFF' : '#009482'}
            >
                <FormattedMessage 
                    id={'checkup.upload.field.text'}
                    defaultMessage={'Upload file'}
                />
            </Text>
        </Flex>
    )
}

export const UploadThumbnail = ({selectedFile, innerBoxSize}) => {
    if (selectedFile?.uploadCompleted){
        return (
            <Box borderRadius={'10px'} w={innerBoxSize} height={innerBoxSize} pos={'relative'}>
                <video
                    style={{
                        objectFit: 'cover',
                        borderRadius: '10px',
                        width: innerBoxSize,
                        height: innerBoxSize,
                        position: 'absolute'
                    }}
                    src={selectedFile?.previewUrl}
                />   
            </Box>
        )
    } else {
        return (
            <Flex
                direction={'column'}
                w={'full'}
                align={'center'}
                justify={'center'}
                height={innerBoxSize}
                width={innerBoxSize}
                bg={'#efefef'}
                borderRadius={'10px'}   
            >
            </Flex>
        )
    }
}