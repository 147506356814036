import { API_URLS, PAGE_SIZE } from '../../_config';

import { authHeader, handleResponse } from '../../_helpers';

export const services = {
  fetchRequests,
  acceptOpenRequest,
  acceptOpenRequestVideoChat,
};

function fetchRequests(query, type, offset = 0, pageSize = PAGE_SIZE, dpid) {
  const requestUrl = API_URLS.getOpenRequestList;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader() },
  };
  let url = requestUrl.url + `?type=${type}&pg_sz=${pageSize}&offset=${offset}`;
  if (dpid) url += `&dpid=${dpid}`;
  return fetch(url, requestOptions).then(handleResponse);
}

function acceptOpenRequest(requestId, data) {
  const requestUrl = API_URLS.acceptOpenRequest;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  };
  let url = requestUrl.url.replace('{requestId}', requestId);
  return fetch(url, requestOptions).then(handleResponse);
}

function acceptOpenRequestVideoChat(requestId, data) {
  const requestUrl = API_URLS.acceptOpenRequestVideoChat;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  };
  let url = requestUrl.url.replace('{requestId}', requestId);
  return fetch(url, requestOptions).then(handleResponse);
}
