import React from 'react';
import { 
  Box, 
  Text,
  Flex,
  HStack,
  VStack,
  Skeleton,
  Circle,
  Divider,
} from '@chakra-ui/react';
import { Scrollbars } from 'react-custom-scrollbars';

import { formatDate } from 'src/_helpers';

import { FormattedMessage } from 'react-intl';
import { ToothSetModern } from 'src/_components/ToothSet/ToothSetModern';

import { CategorytDot } from './CategoryDot';
import { CategoryTitle } from './CategoryTitle';

import { useReportCategories } from 'src/Reports/api/getReportCategories';
import { useSelector } from 'react-redux';
import { getIntlMessages } from 'src/App/_redux/selectors';


const CategoryItem = ({category}) => {
    return (
      <Flex
        border={'0.5px solid #405159'}
        borderRadius={['30px']}
        align={'center'}
        bg={'#fff'}
        px={['10px']}
        py={['4px']}
        mb={['10px']}
        mr={['10px']}
      >
        <CategorytDot category={category} width={['10px']} height={['10px']} marginRight={['8px']} />
        <Text color={'#405159'} fontSize={['12px']} textTransform={'upperCase'}>
          <CategoryTitle  category={category} />
        </Text>
      </Flex>
    )
  }
  
  const ChartCategoriesLoader = () => {
    return (
        <HStack spacing={['10px']}>
            <Skeleton height={["30px"]} borderRadius={['30px']} w={['70px']} h={['28px']} />
            <Skeleton height={["60px"]} borderRadius={['30px']} w={['70px']} h={['28px']} />
            <Skeleton height={["60px"]} borderRadius={['30px']} w={['70px']} h={['28px']} />
            <Skeleton height={["60px"]} borderRadius={['30px']} w={['70px']} h={['28px']} />
        </HStack>
    )
  
  }
  
  const Categories = ({reportType=null}) => {
    const { data, isLoading, isFetching } = useReportCategories({reportType})
  
    return (
      <Flex justify={['center']} w={['full']} px={['40px']} py={['20px']} flexWrap={['wrap']}>
        {
          (isLoading || isFetching )? (
            <ChartCategoriesLoader />
          ) : (
            (data?.categories || []).map((category) => <CategoryItem key={category.value} category={category.value} />)
          )
        }
      </Flex>
    )
  }
  
  export const ChartSection = ({data, requestType}) => {
  
      const toothClasses = {};
  
      // go through the block data and convert it to tooth charting object
      // object with key of tooth number "txx" and value of category in all caps for class
      (data?.blocks || []).map((block) => {
          const data = block?.data;
          data.regions.map((region) => {
            if (region.length > 0){
              // will overwrite any previous block styles
              if (toothClasses[`t${region}`]){
                  toothClasses[`t${region}`].push(block.data.category.toUpperCase())
              } else {
                  toothClasses[`t${region}`] = [block.data.category.toUpperCase()]
              }
            }
          })
      })

      const isDMFT = (requestType || "").indexOf("DMFT") !== -1;

      if (isDMFT){
        return( 
          <Flex  
            mr={['20px']} 
            pr={['20px']}
            bg={'#fff'}
            boxShadow={['0px 0px 2px 0px rgba(0, 0, 0, 0.25)']}
            m={['5px']}
            borderRadius={['15px']}
          >
            <Flex 
              w={['full']}
              justify={'center'} 
              background={'#fff'}
              alignSelf={'center'}
              padding={['20px']}
              borderRadius={['25px']}
              px={['90px']}
            >
              <Flex align={['center']}>
                <Text mr={['5px']}>
                  <FormattedMessage
                    id={'reportDetailsPage.reviewReportModal.toothChart.right'}
                    defaultMessage={'R'}
                    />
                </Text>
              </Flex>
              <ToothSetModern
                toothClasses={toothClasses}
                width={190}
                height={360}
              />
              <Flex align={['center']}>
                <Text ml={['5px']}>
                  <FormattedMessage
                    id={'reportDetailsPage.reviewReportModal.toothChart.left'}
                    defaultMessage={'L'}
                    />
                </Text>
              </Flex>
            </Flex>
          </Flex>
        )
      } else {
        return( 
          <Box  
            mr={['20px']} 
            pr={['20px']}
            bg={'#fff'}
            boxShadow={['0px 0px 2px 0px rgba(0, 0, 0, 0.25)']}
            m={['5px']}
            borderRadius={['15px']}
          >
            <Flex 
              w={['full']}
              justify={'center'} 
              background={'#fff'}
              padding={['20px']}
              borderRadius={['25px']}
            >
              <Flex align={['center']}>
                <Text mr={['5px']}>
                  <FormattedMessage
                    id={'reportDetailsPage.reviewReportModal.toothChart.right'}
                    defaultMessage={'R'}
                    />
                </Text>
              </Flex>
              <ToothSetModern
                toothClasses={toothClasses}
                width={190}
                height={360}
              />
              <Flex align={['center']}>
                <Text ml={['5px']}>
                  <FormattedMessage
                    id={'reportDetailsPage.reviewReportModal.toothChart.left'}
                    defaultMessage={'L'}
                    />
                </Text>
              </Flex>
            </Flex>
            <Categories />
          </Box>
        )
      }
  
}
  
const ToothLabelItem = ({toothNum, category=""}) => {
    let color = "#273238"
    let fontColor = "#FFF"
    if (category.toLowerCase() == "caries"){
      color="#DC4E41"
      fontColor="#fff"
    } else if(category.toLowerCase() == "color"){
      color="#367FFF"
      fontColor="#fff"
    } else if(category.toLowerCase() == "gums"){
      color="#FFA500"
      fontColor="#273238"
    } else if(category.toLowerCase() == "habits"){
      color="#DAFF00"
      fontColor="#273238"
    } else if(category.toLowerCase() == "ortho"){
      color="#405159"
      fontColor="#fff"
    } else if(category.toLowerCase() == "general" || category.toLowerCase() == "others"){
      color="#A9A8A8"
      fontColor="#273238"
    }
    return (
      <Circle size={'30px'} bg={color}>
        <Text
          color={fontColor}
          fontSize={'15px'}
        >
          {toothNum}
        </Text>
      </Circle>
    )
  }
  
  
//import html2canvas from 'html2canvas';
//import { jsPDF } from "jspdf";
const CategoryLabelItem = ({block}) => {
    // data?.
    // block?.data?.category <- for color
    // block?.data?.subCategory
    // block?.data?.regions
    if (!block?.data?.regions || block?.data?.regions.length == 0){
      return <></>
    }
    return (
      <Flex direction={'column'}  align={'flex-start'} justify={'flex-start'}>
        <Text color="#394B55" fontSize={'18px'} textTransform={'capitalize'}>
            {block?.data?.title || block?.data?.subCategory}
        </Text>
        <HStack spacing={['8px']} mt={['5px']}>
          {
            block?.data?.regions.map((toothNum) => (
              <ToothLabelItem key={`${block?.id}_${toothNum}`} toothNum={toothNum} category={block?.data?.category || ""} />
            ))
          }
        </HStack>
      </Flex>
    )

}
const CategoryLabelSection = ({data}) => {

  return (
    <VStack align={'flex-start'} justify={'flex-start'} spacing={['15px']}>
      {
        (data?.blocks || []).map((block) => (<CategoryLabelItem key={block?.id} block={block} />))
      }

    </VStack>
  )
}

function removeXmlAndEntities(str) {
  // Remove XML tags
  return str.replace(/<toothnumbers[^>]*>(.*?)<\/toothnumbers>/gis, function(match, p1) {
    return p1.replace(/<tooth[^>]*>[\s\S]*?<\/tooth>/gi, '') // Remove all <tooth> tags and their contents
             .replace(/&nbsp;|,/g, ''); // Remove all &nbsp; entities and commas
  }).replace(/^[\s.]+/, '');
}

function removeXmlAndKeepTeeth(str) {
  // Removing HTML tags
  let output = str.replace(/<\/?[^>]+(>|$)/g, "");
  // Replacing HTML entities
  output = output.replace(/&nbsp;/gi, " "); // Replace all occurrences of &nbsp; with a space

  return output;
}

const CategoryCommentItem = ({block, type}) => {

    // data?.
    // block?.data?.category <- for color
    // block?.data?.subCategory
    // block?.data?.regions
    // block?.data?.text
   const comment = (type == "DMFT") ? removeXmlAndEntities(block?.data?.text) : removeXmlAndKeepTeeth(block?.data?.text)
    return (
      <Flex direction={'column'}  align={'flex-start'} justify={'flex-start'}>
        <Text color="#394B55" fontSize={'18px'} textTransform={'capitalize'}
          textAlign={'left'}
        >
            {block?.data?.title || block?.data?.subCategory}
        </Text>
        <Text
          color={'#405159'}
          fontSize={'15px'}
          lineHeight={'18px'}
          textAlign={'left'}
        >
          {comment}
        </Text>
      </Flex>
    )
}

const CategoryCommentsSection = ({data, type}) => {
  const intlMessages = useSelector(getIntlMessages);
  if (data?.blocks.length == 0){
    return (
      <VStack align={'flex-start'} justify={'flex-start'} spacing={['15px']} mt={'20px'}>
        <Flex direction={'column'}  align={'flex-start'} justify={'flex-start'}>

          <Text
            color={'#405159'}
            fontSize={'15px'}
            lineHeight={'18px'}
            textAlign={'left'}
          >
            {intlMessages["reportDetailsPage.reviewReportModal.reportSection.empty"]}
          </Text>
        </Flex>
      </VStack>
    )
  } else {
    return (
      <VStack align={'flex-start'} justify={'flex-start'} spacing={['15px']} mt={'20px'}>
        {
          (data?.blocks || []).map((block) => (<CategoryCommentItem key={block?.id} block={block} type={type} />))
        }
      </VStack>
    )
  }
}

export const PrintPreviewReportSection = ({data, type="DMFT", isPrint=true}) => {
  const intlMessages = useSelector(getIntlMessages);
  if (!data?.blocks || data?.blocks?.length == 0){
    return (
      <Box pl={['20px']} w={'580px'}>

          <Box
            p={['20px']} 
            boxShadow = {'0px 0px 2px 0px rgba(0, 0, 0, 0.25)'} 
            m={['5px']}
            bg={['#fff']}
            borderRadius={['15px']}
            height={'calc(100% - 10px)'}
          >   
          <VStack align={'flex-start'} justify={'flex-start'} spacing={['15px']} mt={'20px'}>
            <Flex direction={'column'}  align={'flex-start'} justify={'flex-start'}>
    
              <Text
                color={'#405159'}
                fontSize={'15px'}
                lineHeight={'18px'}
                textAlign={'left'}
              >
                {intlMessages["reportDetailsPage.reviewReportModal.reportSection.empty"]}
              </Text>
            </Flex>
          </VStack>
          </Box>
      </Box>
    )
  } 
  if (isPrint){
    return (
        <Box pl={['20px']} w={'580px'}>

            <Box
              p={['20px']} 
              boxShadow = {'0px 0px 2px 0px rgba(0, 0, 0, 0.25)'} 
              m={['5px']}
              bg={['#fff']}
              borderRadius={['15px']}
            >   
                <CategoryLabelSection data={data} />
                <Divider orientation='horizontal' borderColor={'#C5C5C5'} py={'10px'} />
                <CategoryCommentsSection data={data} type={type}/>
            </Box>
        </Box>
    )
  } else {
    return (
        <Box pl={['20px']} w={'520px'} height={'580px'}>

          <Scrollbars autoHide style={{height: '100%'}} >
            <Box
              p={['20px']} 
              boxShadow = {'0px 0px 2px 0px rgba(0, 0, 0, 0.25)'} 
              m={['5px']}
              bg={['#fff']}
              height={'100%'}
              borderRadius={['15px']}
            >   
                <CategoryLabelSection data={data} />
                <Divider orientation='horizontal' borderColor={'#C5C5C5'} py={'10px'} />
                <CategoryCommentsSection data={data} type={type} />
            </Box>
          </Scrollbars>
        </Box>
    )
  }
}

const PrintPreview = React.forwardRef(({ data, isLoading, isFetching, requestType }, ref) => {
    if (isLoading || isFetching || data?.report?.report_data) {
        return (
          <Box ref={ref} >
            <Flex overflow={'auto'} maxH={['70vh']} justify={['center']}>
                <ChartSection type={data?.type} data={data?.report?.report_data} requestType={requestType}/>
                <PrintPreviewReportSection data={data?.report?.report_data} type={requestType ? requestType : data?.report?.report_data} remark={data?.report?.remark} />
            </Flex>
          </Box>
        );
    } else {
        return <></>;  // Return null instead of empty fragment for potentially cleaner output
    }
});

PrintPreview.displayName = 'PrintPreview';
  

export const ReportPrintPreview = React.forwardRef(({data, isLoading, isFetching, requestType}, ref) => {
    return (
        <div style={{ display: "none"}}>
        <Box ref={ref}  pt={'20px'}>
            <Flex overflow={'auto'} maxH={['70vh']} justify={['center']} pb={'30px'}>
            <Text fontSize={['24px']} color={'#273238'}>
                { `${formatDate(data?.report?.sent_at)}:` }
            </Text>
            <Text fontSize={['24px']} color={'#273238'} ml={'5px'}>
                {data?.report?.patient_name}
            </Text>
            </Flex>
            <PrintPreview data={data} isLoading={isLoading} isFetching={isFetching} requestType={requestType} />
        </Box>
        </div>
    )
})

ReportPrintPreview.displayName = 'ReportPrintPreview';
