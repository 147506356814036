import { constants } from './constants';
//import { intersection, omitBy } from 'lodash';
//import { RecordUpload, ReportChart } from '../../_helpers/models';

const initState = {
  currentRequestId: null,
  currentPatientUuid: null,
  fetching: false,
  loading: false,
  error: '',
  reportModal: false,
  recordDetailModal: false,
  currentSelectedMedia: null,
  appointmentCompleteModal: true,
  patientNotesModal: false,
};

export function requestDetail(state = initState, action) {
  switch (action.type) {
    case constants.CLEAR_CURRENTREQUEST:
      return Object.assign({}, {}, initState);
    case constants.GETREQUEST_REQUEST:
      return {
        ...state,
        loading: true,
        fetching: true,
        error: '',
      };
    case constants.GETREQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        ...action.data,
      };
    case constants.GETREQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        fetching: false,
        error: '',
      };
    case constants.UPDATE_SELECTED_MEDIA:
      return {
        ...state,
        currentSelectedMedia: action.data.id,
      };
    case constants.UPDATE:
      return {
        ...state,
        ...action.data,
      };
    case constants.UPDATE_STATE:
      return {
        ...state,
        [action.data.key]: action.data.value,
      };
    case constants.UPDATE_STATE_OBJECT:
      return {
        ...state,
        [action.data.key]: Object.assign({}, state[action.data.key], action.data.value),
      };
    default:
      return state;
  }
}
