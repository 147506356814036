function formatStringNamed(formatted, ...args) {
  return args.reduce((p, c) => p.replace(/%s/, c), formatted);
}

function formatString(str, ...inputs) {
  var i = 0;
  return str.replace(/{[^{}]*}/g, function () {
    return inputs[i++];
  });
}

function formatName(formatString, givenName, familyName) {
  if (givenName && familyName) {
    return formatString.replace('{givenName}', givenName).replace('{familyName}', familyName);
  } else if (givenName) {
    return givenName;
  } else if (familyName) {
    return familyName;
  } else {
    return '';
  }
}

function toFixed(value, precision) {
  var power = Math.pow(10, precision || 0);
  return String(Math.round(value * power) / power);
}

const bytesToString = (bytes) => {
  if (!bytes) {
    return '0';
  }
  let size = parseFloat(bytes);
  let convAmount = 1024.0;
  if (size / convAmount / convAmount / convAmount > 1.0) {
    return toFixed(size / convAmount / convAmount / convAmount, 1) + ' GB';
  } else if (size / convAmount / convAmount > 1.0) {
    return toFixed(size / convAmount / convAmount, 1) + ' MB';
  } else if (size / convAmount > 1.0) {
    return toFixed(size / convAmount, 1) + ' kb';
  } else {
    return toFixed(size, 1) + ' bytes';
  }
};

function copyStyles(sourceDoc, targetDoc) {
  Array.from(sourceDoc.styleSheets).forEach((styleSheet) => {
    if (styleSheet.cssRules) {
      // true for inline styles
      const newStyleEl = targetDoc.createElement('style');

      Array.from(styleSheet.cssRules).forEach((cssRule) => {
        newStyleEl.appendChild(targetDoc.createTextNode(cssRule.cssText));
      });

      targetDoc.head.appendChild(newStyleEl);
    } else if (styleSheet.href) {
      // true for stylesheets loaded from a URL
      const newLinkEl = targetDoc.createElement('link');

      newLinkEl.rel = 'stylesheet';
      newLinkEl.href = styleSheet.href;
      targetDoc.head.appendChild(newLinkEl);
    }
  });
}

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export { formatString, formatStringNamed, formatName, bytesToString, copyStyles, sleep };
