import React from 'react';
import { Input, Textarea, FormControl, FormLabel, FormErrorMessage, VStack } from '@chakra-ui/react';

export const InputComponent = ({ label, placeholder, name, type="text", value, isInvalid, error, onChange }) => {
    if (type == "textarea"){
        return (
            <VStack>
                <FormControl id={name} isInvalid={isInvalid}>
                    <FormLabel>{label}</FormLabel>
                    <Textarea placeholder={placeholder} type={type} value={value} onChange={onChange} isInvalid={isInvalid} />
                    <FormErrorMessage>{error ? error  : ""}</FormErrorMessage>
                </FormControl>
            </VStack>
        )
    } else {
        return (
            <VStack>
                <FormControl id={name} isInvalid={isInvalid}>
                    <FormLabel>{label}</FormLabel>
                    <Input placeholder={placeholder} type={type} value={value} onChange={onChange} isInvalid={isInvalid} />
                    <FormErrorMessage>{error ? error  : ""}</FormErrorMessage>
                </FormControl>
            </VStack>
        )
    }

}