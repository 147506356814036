import React, {useState, useRef, useEffect} from 'react';
import ReactPlayer from 'react-player';

import InnerImageZoom from 'react-inner-image-zoom'

import { bytesToString } from '../../_helpers/utils'
// components
import { PaperclipIcon } from '../../_images/icons/PaperclipIcon';
import { LoadingCircle } from '../Loaders';

// styles
import {
  StyledRecordUploadIcon,
  StyledLoadingMedia,
  StyledRecordUploadMedia,
  StyledErrorDiv,
  BlankMediaItem
}
from './styles';
import { consoleLog } from '../../_helpers/consoleLog';

export const RecordMessageVideo = ({url, play, onError}) => {
  const playerRef = useRef();
  return(
    <ReactPlayer
        ref={playerRef}
        onError={onError}
        url={url}
        muted={true}
        controls={play}
        width={'100%'}
        height={'100%'}
      />
  )
}

export const RecordMessageImage = ({zoom, url, onError}) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  console.log(imageLoaded)
  if (zoom){
    return(
      <InnerImageZoom
        zoomScale={1.5}
        zoomSrc={url}
        src={url}
        onError={onError}
        onLoad={()=>setImageLoaded(true)}
        />
    )
  } else {
  return(
    <img
      src={url}
      onError={onError}
      onLoad={()=>setImageLoaded(true)}
      />
  )
  }
}

export const RecordUploadMedia = ({className, upload, selected, onClick, imgHeight=100, imgWidth=100, imgBorderRadius=30, play=false, marginTop=0, marginLeft=0, zoom=true}) => {
  const [ error, setError] = useState(null);
  const [ mimeType, setMimeType ] = useState(null);
  const playerRef = useRef();
  const contentSize = useRef(0);

  useEffect(() => {
    if (upload) {
      if (upload.getUploadHead()){
        fetch(upload.getUploadHead(), {method: 'HEAD'})
          .then( response => {
            let contenttype = response.headers.get("content-type");
            contentSize.current = response.headers.get("content-length");
            setMimeType(contenttype)
          }).catch(() => {
            setError(true);
          })
      } else if (upload.getUpload()) {
        setMimeType("image/*");
      } else {
        setError(true);
      }
    }
  }, [upload])

  const onImageError = () => {
    consoleLog("error image");
    setMimeType("video/*");
  }

  const onVideoError = () => {
    setError(true);
  }

  const onProgress = (prog) => {
    //let {playedSeconds, loaded, loadedSeconds, played } = prog;
    console.log(prog);
    
    // currDetection: timestamp, frame num, detecitons array
  }

  const onBuffer = (a=null) => {
    console.log("buffer started: " + a)
  }

  const onBufferEnd = (a=null) => {
    console.log("buffer ended: " + a);
  }

  if (!upload){
    return (
      <StyledRecordUploadMedia
        className={className}
        imgHeight={imgHeight}
        imgWidth={imgWidth}
        imgBorderRadius={imgBorderRadius}
        marginTop={marginLeft}
        marginLeft={marginTop}
        >
        <BlankMediaItem></BlankMediaItem>
      </StyledRecordUploadMedia>)
  }

  if (error){
    return (
      <StyledRecordUploadMedia
        className={className}
        imgHeight={imgHeight}
        imgWidth={imgWidth}
        imgBorderRadius={imgBorderRadius}
        marginTop={marginLeft}
        marginLeft={marginTop}
        >
        <StyledErrorDiv>
          {'Error Fetching'}
        </StyledErrorDiv>
      </StyledRecordUploadMedia>
    )
  }
  if (mimeType && mimeType.includes("image")){
    return(
      <StyledRecordUploadMedia
        className={className}
        onClick={onClick}
        selected={selected}
        imgHeight={imgHeight}
        imgWidth={imgWidth}
        imgBorderRadius={imgBorderRadius}
        marginTop={marginLeft}
        marginLeft={marginTop}
        >
        <RecordMessageImage
          zoom={zoom}
          onError={onImageError}
          url={upload.getUpload()} />
      </StyledRecordUploadMedia>
    )
  } else if (mimeType && mimeType.includes("video")) {
    return(
      <StyledRecordUploadMedia
        className={className}
        onClick={onClick}
        selected={selected}
        imgHeight={imgHeight}
        imgWidth={imgWidth}
        imgBorderRadius={imgBorderRadius}
        marginTop={marginLeft}
        marginLeft={marginTop}
        >
        <ReactPlayer
            ref={playerRef}
            onProgress={onProgress}
            onBuffer={onBuffer}
            onBufferEnd={onBufferEnd}
            onError={onVideoError}
            url={upload.getUpload()}
            muted={true}
            controls={play}
            width={'100%'}
            height={'100%'}
          />
      </StyledRecordUploadMedia>
    )
  } else if (mimeType){
    return(
      <StyledRecordUploadIcon
        className={className}
        onClick={onClick}
        selected={selected}
        imgHeight={100}
        imgWidth={250}
        imgBorderRadius={24}
        marginTop={marginLeft}
        marginLeft={marginTop}
        >
          <div className={'attachmentIcon'}>
            <PaperclipIcon />
          </div>
          <div className={'mediaDetails'}>
            <div className={'mediaName'}>
              <span>{upload.getUploadFileName()}</span>
            </div>
            <div className={'mediaSize'}>
              <span>{bytesToString(contentSize.current)}</span>
            </div>
          </div>
      </StyledRecordUploadIcon>
    )
  } else {
    return (
      <StyledLoadingMedia
        className={className}
        imgHeight={imgHeight}
        imgWidth={imgWidth}
        imgBorderRadius={imgBorderRadius}
        marginTop={marginLeft}
        marginLeft={marginTop}
        >
        <LoadingCircle />
      </StyledLoadingMedia>
    )
  }
}
