import React from 'react';
import { Icon } from '@chakra-ui/react';

const PaginationPrevious = (props) => {
  const iconWidth = props?.width || 14;
  const iconHeight = props?.height || 14;
  return (
    <Icon 
      viewBox="0 0 14 14" 
      color={'#273238'}  // #C7D3D9 when disabled
      fill={'none'}
      {...props}
      width={`${iconWidth}px`}
      height={`${iconHeight}px`}
    >
        <path 
            fillRule="evenodd" 
            clipRule="evenodd" 
            d="M9.09416 1.74965L3.73333 7.11048L9.17816 12.5553L9.92075 11.8121L5.2185 7.11048L9.83675 2.49223L9.09416 1.74965Z" 
            fill="currentColor"
        />
    </Icon>
  );
};
export default PaginationPrevious;