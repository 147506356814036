import React from 'react';
import styled from 'styled-components';


// styles
//
import { WrappedPage } from '../PageComponents/WrappedPage';

import {
  generalModalStyles,
  StyledButton
} from '../_css/styles';

export const ModalStyles = styled.div`
  position: relative;
  height: 100%;
  padding: 30px 50px;


  .title{
    font-size: 22px;
    font-family: Proxima Nova Semibold;
    text-align: center;
  }

  .appointmentComplete{
    margin-top: 20px;

    &_label{
      padding: 10px 0;
      color: #7A8F99;
    }

    &_patientName{
      display:flex;
      align-items:center;
      font-size: 16px;
      .label{
        margin:0 8px;
        color: #7A8F99;
        font-size: 14px;
      }
      .birthDate, .gender, .splitter{
        font-family: 'Proxima Nova';
      }
    }

    &_value{
      font-size: 16px;
    }

    &_buttons{
      display:flex;
      justify-content:center;
    }

    ${StyledButton}{
      display:flex;
      margin-top: 20px;
      justify-content: center;
      min-width:150px;
    }

  }
`;

export const Styles = styled(WrappedPage)`
  grid-auto-rows: max-content;

`;

const modalStyles = {...generalModalStyles};
modalStyles.content.maxHeight = '80%';

export const Dashboard = () => {
  return <div></div>
}

