import React, {useEffect} from 'react';

//import { useSelector, useDispatch} from 'react-redux';
//import { getFilteredReportRegions } from './_selectors';

import styled from 'styled-components';

import { ToothSetOutsideNum } from '../_components/ToothSet';

const ChartSectionStyles = styled.div`
  width: fit-content;
  padding: 50px;
  background-color: #F7F9FA;
  border-radius: 25px;
`;


export const ReportToothChart = ({charting, width="378", height="550"}) => {

  useEffect(()=>{
    return () => {};
  }, [])

  return(
    <ChartSectionStyles>
      <ToothSetOutsideNum
        charting = {charting}
        width = {width}
        height = {height}
        />
    </ChartSectionStyles>
  )
}
