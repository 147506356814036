import { constants } from './constants';
const initState = {
  searchLoading: {},
  searchError: {},
  byCountry: {}, // for search
  loading: {},
  error: {},
  dentists: {},
};

export function dentists(state = initState, action) {
  switch (action.type) {
    case constants.SEARCHDENTISTS_REQUEST:
      return {
        ...state,
        searchLoading: {
          ...state.searchLoading,
          [action.data.country]: true,
        },
        searchError: {
          ...state.searchError,
          [action.data.country]: false,
        },
      };
    case constants.SEARCHDENTISTS_SUCCESS:
      return {
        ...state,
        byCountry: Object.assign({}, state.byCountry, action.data.byCountry),
        searchLoading: {
          ...state.searchLoading,
          [action.data.country]: false,
        },
        searchError: {
          ...state.searchError,
          [action.data.country]: false,
        },
      };
    case constants.SEARCHDENTISTS_FAILURE:
      return {
        ...state,
        searchLoading: {
          ...state.searchLoading,
          [action.data.country]: false,
        },
        searchError: {
          ...state.searchError,
          [action.data.country]: true,
        },
      };
    case constants.FETCH_DENTISTDETAILS_REQUEST:
      return {
        ...state,
        loading: Object.assign({}, state.loading, { [action.data.id]: true }),
        error: Object.assign({}, state.error, { [action.data.id]: null }),
      };
    case constants.FETCH_DENTISTDETAILS_SUCCESS:
      return {
        ...state,
        loading: Object.assign({}, state.loading, { [action.data.id]: false }),
        error: Object.assign({}, state.error, { [action.data.id]: null }),
        dentists: Object.assign({}, state.dentists, { [action.data.id]: action.data.dentist }),
      };
    case constants.FETCH_DENTISTDETAILS_FAILURE:
      return {
        ...state,
        loading: Object.assign({}, state.loading, { [action.data.id]: false }),
        error: Object.assign({}, state.error, { [action.data.id]: action.data.error }),
      };
    default:
      return state;
  }
}
