import { createSelector } from 'reselect';
import { uniqBy } from 'lodash';

import { usePubNub } from 'pubnub-react';

const getMessagesSlice = (state) => state.pnMessages;
const getPatientsSlice = (state) => state.patients;
const getChannelData = (state) => state.pnChannelData; // TODO repeated
const getChatListStateSlice = (state) => state.chatsList;
const getUsersSlice = (state) => state.pnUserData;

export const getMessageDrafts = createSelector([getChatListStateSlice], (chatsList) => {
  return chatsList.messageDraftById;
});

export const getCurrentConversationId = createSelector([getChatListStateSlice], (chatLists) => {
  return chatLists.currentConversationId;
});

export const getChannelDataById = createSelector([getChannelData], (channels) => {
  return channels.byId;
});

export const getMessagesById = createSelector([getMessagesSlice], (messages) => {
  return messages.byId;
});

export const getUsersById = createSelector([getUsersSlice], (users) => {
  return users.byId;
});

export const getPatientsById = createSelector([getPatientsSlice], (patients) => {
  return patients.byId;
});
export const getMessageActions = createSelector([getChatListStateSlice], (chatList) => {
  return chatList.messageActionsById;
});

export const getMessageActionsByChannel = createSelector(
  [getMessageActions, getCurrentConversationId],
  (messageActions, conversationId) => {
    return messageActions[conversationId];
  }
);

export const getConversationMessageDraft = createSelector(
  [getMessageDrafts, getCurrentConversationId],
  (drafts, conversationId) => {
    return drafts[conversationId];
  }
);

export const getCurrentConversationMessages = createSelector(
  [getMessagesById, getMessageActions, getCurrentConversationId, getUsersById],
  (messages, messageActions, conversationId, users) => {
    const pn = usePubNub();
    return messages[conversationId]
      ? Object.values(messages[conversationId])
          .filter((data) => data.channel === conversationId)
          .map((entry) => {
            var decryptedMessage;
            if (typeof entry.message == 'string') {
              decryptedMessage = pn.decrypt(entry.message, process.env.REACT_APP_PUBNUB_CIPHER_KEY);
            } else if (entry.message.data) {
              decryptedMessage = pn.decrypt(
                entry.message.data,
                process.env.REACT_APP_PUBNUB_CIPHER_KEY
              );
            } else {
              decryptedMessage = entry.message;
            }
            if (entry.message.data && decryptedMessage != undefined) {
              let actions = uniqBy(
                (messageActions[conversationId] || []).filter(
                  (x) => x.messageTimetoken == entry.timetoken.toString()
                ),
                'actionTimetoken'
              );

              return {
                ...decryptedMessage,
                timetoken: entry.timetoken.toString(),
                actions,
                sender: users[decryptedMessage.senderId]
                  ? {
                      id: decryptedMessage.senderId,
                      name: decryptedMessage.senderId,
                    }
                  : {
                      id: 'unknown',
                      name: 'unknown',
                    },
              };
            } else {
              return {};
            }
          })
          .sort((msgA, msgB) => {
            if (msgA.timetoken === msgB.timetoken) {
              return 0;
            } else if (msgA.timetoken > msgB.timetoken) {
              return 1;
            } else {
              return -1;
            }
          })
      : [];
  }
);

export const getUnknownUsers = createSelector(
  [getMessagesById, getCurrentConversationId, getUsersById],
  (messages, conversationId, users) => {
    return messages[conversationId]
      ? Object.values(messages[conversationId])
          .filter((message) => message.channel === conversationId)
          .filter(
            (message) =>
              // if the user is unknown queue up a request for the missing data
              !users[message.message.senderId]
          )
          .map((message) => message.message.senderId)
      : [];
  }
);

export const getCurrentConversationData = createSelector(
  [getChannelDataById, getCurrentConversationId],
  (conversations, currentConversationId) => {
    return conversations[currentConversationId] && conversations[currentConversationId].custom
      ? { ...conversations[currentConversationId].custom }
      : { patient_name: 'Anonymous', request_created_at: '-' };
  }
);
