const PREFIX = 'APPOINTMENTS_';
export const constants = {
  FETCH_APPOINTMENTS: `${PREFIX}FETCH_APPOINTMENTS`,
  FETCH_APPOINTMENTS_ERROR: `${PREFIX}FETCH_APPOINTMENTS_ERROR`,
  FETCH_APPOINTMENTS_SUCCESS: `${PREFIX}FETCH_APPOINTMENTS_SUCCESS`,
  CREATE_APPOINTMENT_REQUEST: `${PREFIX}CREATE_APPOINTMENT_REQUEST`,
  CREATE_APPOINTMENT_SUCCESS: `${PREFIX}CREATE_APPOINTMENT_SUCCESS`,
  CREATE_APPOINTMENT_ERROR: `${PREFIX}CREATE_APPOINTMENT_ERROR`,
  UPDATE_APPOINTMENT_REQUEST: `${PREFIX}UPDATE_APPOINTMENT_REQUEST`,
  UPDATE_APPOINTMENT_SUCCESS: `${PREFIX}UPDATE_APPOINTMENT_SUCCESS`,
  UPDATE_APPOINTMENT_ERROR: `${PREFIX}UPDATE_APPOINTMENT_ERROR`,
  ADDAPPOINTMENT_STATE: `${PREFIX}ADDAPPOINTMENT_STATE`,
  UPDATEAPPOINTMENT_STATE: `${PREFIX}UPDATEAPPOINTMENT_STATE`,
  UPDATE_CURRENT_SESSION_ID: `${PREFIX}UPDATE_CURRENT_SESSION_ID`,
  UPDATE_CURRENT_SESSION_REQUEST_ID: `${PREFIX}UPDATE_CURRENT_SESSION_REQUEST_ID`,
  UPDATE_CURRENT_SESSION: `${PREFIX}UPDATE_CURRENT_SESSION`,
};
