import React from 'react';
import { FormattedMessage } from 'react-intl';

export const GenderText = ({gender=''}) => {
    const genderUpper = (gender || "").toUpperCase();
    if (genderUpper == 'MALE'){
        return <FormattedMessage id="settings.gender.MALE" defaultMessage='male' />
    } else if (genderUpper == 'FEMALE'){

        return <FormattedMessage id="settings.gender.FEMALE" defaultMessage='female' />
    } else {
        return <FormattedMessage id="settings.gender.OTHER" defaultMessage='other' />
    }
}



