import styled from 'styled-components';

export const ScrollView = styled.div`
  height: 100%;
  width: 100%;
  ${(props) => props.addCss}
`;
export const StyledSingleStep = styled.div`
  width: 13px;
  height: 12px;
  background: #d8d8d8;
  margin: 5px;
  border-radius: 20px;
  cursor: pointer;
  ${(props) => props.addCSS}
`;

export const StyledPrevStep = styled.div`
  cursor: pointer;
  margin: 5px;
  ${(props) => props.addCSS}
`;

export const StyledNextStep = styled.div`
  cursor: pointer;
  margin: 5px;
  ${(props) => props.addCSS}
`;

export const StyledStepsSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0 20px;
`;

export const StyledVideoFrame = styled.div`
  margin-left: 5px;
  img {
    width: 80px;
    &:hover {
      cursor: pointer;
    }
  }
`;
export const StyledVideoFrameList = styled.div`
  display: flex;
  margin: 15px 0;
  div:first-child {
    margin-left: 0;
  }
`;

export const StyledRegionTopOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  margin: 8px;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 40px;
  padding: 5px 10px;
  z-index: 3;
`;

export const StyledVideoView = styled.div`
  .videoPlayerContainer {
    position: relative;
    background-color: #f7f9fa;

    canvas {
      top: 0;
      left: 0;
      position: absolute;
      pointer-events: none;
      width: ${({ canvasWidth }) => (canvasWidth ? canvasWidth : 640)}px;
      height: ${({ canvasHeight }) => (canvasHeight ? canvasHeight : 480)}px;
      visibility: hidden;

      &.DETECTIONON {
        visibility: visible;
      }
    }
  }
  .react-player__preview {
    min-height: 420px;
  }
`;

export const StyledPhotoView = styled.div`
  background-color: #f7f9fa;
`;

export const StyledMediaView = styled.div``;

export const StyledMediaSectionTitle = styled.div`
  margin-bottom: 20px;
  margin-top: 20px;
  text-align: center;
`;
export const StyledMediaSection = styled.div`
  overflow-x: hidden;
  width: 640px;
  min-height: 650px;
  .carousel-status {
    font-size: 12px;
  }
`;
