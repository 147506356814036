import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import { localeFormatMessageTime, extractPatientUuidFromChannelId } from '../../_helpers';
import { UserRecord } from '../../_helpers/models';

// components
import { LoadingCircle } from '../../_images/icons/LoadingCircle';
import { RecordUploadMedia } from '../../_components/RecordUploadMedia';

// services
import { services as chatServices } from '../_redux/services';

// selectors
import { getIntlMessages, getUUID, getIntlLocale } from '../../App/_redux/selectors';

// styles
import {
  StyledRecordMessage,
  //StyledPartialScanMessage,
  StyledLoadingMessage,
  StyledTextMessage,
  StyledMessageContent,
  StyledMessageContentRecord,
  StyledMessage,
  StyledErrorDiv
}
from './styles';
import { consoleLog } from '../../_helpers/consoleLog';

/*
content_type: "image/png"
created_at: "2021-06-01T07:47:25.175073Z"
file: "https://bd-plover-local.s3.amazonaws.com/upl/An-1622533645.png?AWSAccessKeyId=AKIA3UBDXNBOGMTRVKTL&Signature=IMsNYeQRwMMFyhgejDWcg7x3rQg%3D&Expires=1622544053"
file_head: "https://bd-plover-local.s3.amazonaws.com/upl/An-1622533645.png?AWSAccessKeyId=AKIA3UBDXNBOGMTRVKTL&Signature=ZeO4EqUL21B%2FOTGc8O%2BIiy12vFQ%3D&Expires=1622544053"
file_name: "An-1622533645.png"
id: 38
parent: null
type: "ATTACHMENT"
upload_count: 1
user: 2
 */

export const RecordMessage = ({msg, conversationId}) => {
  const [ recordUpload, setRecordUpload] = useState(null);
  const [ error, setError] = useState(null);
  const intlMessages = useSelector(getIntlMessages);

  useEffect(()=>{
    async function f() {
      try{
        const record = await chatServices.fetchChatRecord(msg.text, extractPatientUuidFromChannelId(conversationId))
        //if (record && record.record_uploads.length > 0){
        //  setRecordUpload(record.record_uploads[0])
        //}
        if (record){
          setRecordUpload(new UserRecord(record));
        }
      } catch (err) {
        setError(true);
        consoleLog("ERROR: recordmessage");
      }
    }
    f();
  }, []);

  if (error) {
    return (
      <StyledRecordMessage>
        <StyledErrorDiv>
          {intlMessages['chatPage.messagetemplate.recordmessage.errorFetching']}
        </StyledErrorDiv>
      </StyledRecordMessage>
    )
  }
  if (recordUpload){
    return(
      <RecordUploadMedia
        upload={recordUpload}
        imgWidth={225}
        imgHeight={225}
        zoom={false}
        marginLeft={0}
        />
    )
  } else {
    return (
      <StyledLoadingMessage>
        <LoadingCircle />
      </StyledLoadingMessage>
    )
  }
}

export const TextMessage = ({msg}) => {
  return (
    <StyledTextMessage>
      {msg.text || msg}
    </StyledTextMessage>
  )
}

export const LoadingMessage = () => {
  return (
    <StyledLoadingMessage>
      <LoadingCircle />
    </StyledLoadingMessage>
  )
}

const MessageItemContent = ({messageFragment, conversationId}) => {
  //const dispatch = useDispatch();
  const intlMessages = useSelector(getIntlMessages);

  //const [isHovering, hoverProps] = useHover({ mouseEnterDelayMS: 0 });
  if (messageFragment.type && messageFragment.type.toUpperCase() == "TEXT"){
    return (
      <StyledMessageContent>
        <TextMessage msg={messageFragment} />
      </StyledMessageContent>
    )
  } else if (messageFragment.type && messageFragment.type.toUpperCase() == "RECORD_PARTIALSCAN") {
    return (
      <StyledMessageContent>
        <TextMessage msg={intlMessages['chatPage.messagetemplate.partialscan.uploaded.message']} />
      </StyledMessageContent>
    )
  } else if (messageFragment.type && messageFragment.type.toUpperCase().startsWith("RECORD")) {
    // TODO report
    return (
      <StyledMessageContentRecord>
        <RecordMessage msg={messageFragment} conversationId={conversationId} />
      </StyledMessageContentRecord>
    )
  } else if (messageFragment.type && messageFragment.type.toUpperCase() == "REPORT") {
    // TODO report
    return (
      <StyledMessageContent>
        {intlMessages['openRequest.item.type.REPORT']}
      </StyledMessageContent>
    )
  } else if (messageFragment.type && messageFragment.type.toUpperCase() == "PARTIALSCAN") {
    return (
      <StyledMessageContent>
        <TextMessage msg={intlMessages['chatPage.messagetemplate.partialscan.message']} />
      </StyledMessageContent>
    )
  }  else{
    return (<span></span>)
  }
}

const MessageItem = ({msg, conversationId, senderMe}) => {
  const myUuid = useSelector(getUUID);
  const intlLocale = useSelector(getIntlLocale);

  if (!msg.text){
    return ( <span></span>)
  }
  return (
    <StyledMessage
      senderMe={senderMe || myUuid == msg.senderId }
      >
        <div className={cn('messageContent')}>
          <div className={cn('messageTime')}>
            {msg.timetoken && localeFormatMessageTime(msg.timetoken, intlLocale)}
          </div>
          <MessageItemContent messageFragment={msg} conversationId={conversationId} />
        </div>
    </StyledMessage>
  )
}

export const MessageListItem = ({ messageFragment, conversationId, senderMe }) => {
  //const [isHovering, hoverProps] = useHover({ mouseEnterDelayMS: 0 });
  return (
      <MessageItem msg={messageFragment} conversationId={conversationId} senderMe={senderMe} />
  );
};
