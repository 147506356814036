import React from 'react';
import { useLocation } from 'react-router-dom';

export function useQuerySearchParams() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function useSearchParamsObject() {
  const searchParams = useQuerySearchParams();
  return React.useMemo(() => {
    const obj = {};
    for (const [key, value] of searchParams) {
      obj[key] = value;
    }
    return obj;
  }, [searchParams]);
}