import { constants } from './constants';
import { services } from './services';

export const actions = {
  fetchPatientNotes,
  createPatientNote,
  addPatientNote,
  updateNote,
};

function fetchPatientNotes(uid, query = {}) {
  return (dispatch) => {
    dispatch(request(uid));
    services.fetchPatientNotes(uid, query).then(
      (data) => dispatch(success(uid, data)),
      (error) => {
        dispatch(failure(uid, error));
      }
    );
  };
  function request() {
    return { type: constants.FETCH_PATIENTNOTES_REQUEST };
  }
  function success(uid, data) {
    let patient_notes = (data.patient_notes || []).reduce((a, b) => {
      a[b.unique_id] = b;
      return a;
    }, {});
    return {
      type: constants.FETCH_PATIENTNOTES_SUCCESS,
      data: { isMore: data.is_more, patient_notes, uid },
    };
  }
  function failure(uid, error) {
    return { type: constants.FETCH_PATIENTNOTES_FAILURE, error };
  }
}

function createPatientNote(uid) {
  return (dispatch) => {
    dispatch(request(uid));
    services.createPatientNote(uid, {}).then(
      (data) => dispatch(success(data)),
      (error) => dispatch(failure(error))
    );
  };
  function request(id) {
    return { type: constants.CREATEPATIENTNOTE_REQUEST, data: { id } };
  }
  function success(data) {
    // breakdown reports...
    // TODO mkae this a model? move to utils to normalize json data
    return { type: constants.CREATEPATIENTNOTE_SUCCESS, data: { id: data.id, data } };
  }
  function failure(id, error) {
    return { type: constants.CREATEPATIENTNOTE_FAILURE, data: { id, error } };
  }
}

function addPatientNote(uid, note) {
  return (dispatch) => {
    dispatch(add(uid, note));
  };
  function add(uid, note) {
    return { type: constants.ADD_PATIENTNOTE, data: { uid, note } };
  }
}

function updateNote(patientUuid) {
  return (dispatch) => {
    dispatch(update(patientUuid));
  };
  function update(data) {
    return { type: constants.UPDATE_PATIENTNOTE, data };
  }
}
