const PREFIX = 'OPENREQUESTS_';
export const constants = {
  FETCH_REQUESTS: `${PREFIX}FETCH_REQUESTS`,
  FETCH_REQUESTS_ERROR: `${PREFIX}FETCH_REQUESTS_ERROR`,
  FETCH_REQUESTS_SUCCESS: `${PREFIX}FETCH_REQUESTS_SUCCESS`,
  FETCH_REQUESTSMORE: `${PREFIX}FETCH_REQUESTSMORE`,
  FETCH_REQUESTSMORE_ERROR: `${PREFIX}FETCH_REQUESTSMORE_ERROR`,
  FETCH_REQUESTSMORE_SUCCESS: `${PREFIX}FETCH_REQUESTSMORE_SUCCESS`,
  UPDATE_REQUEST: `${PREFIX}UPDATE_REQUEST`,
  UPDATE_REQUESTSTATUS: `${PREFIX}UPDATE_REQUESTSTATUS`,
};
