import React from 'react';

//import { consultRequestsService } from '../_services';
import { Flex } from '@chakra-ui/react';
import { ToothSetSelect } from 'src/_components/ToothSet/ToothSetSelect';
import { useSelectChartingStore } from '../state/toothChart.js';

//const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))


const ToothChartSelect = ({charting, chartType, handleChange}) => {
    const { updateState } = useSelectChartingStore();

    const handleClick = (e) => {
        const toothId = e.target.getAttribute('data-tip')
        let newCharting = {...charting, [toothId]: (charting[toothId] === 'move') ? '' : 'move'}
        updateState({
            isDirty: true ,
            charting: newCharting
        })
        handleChange(newCharting)

        // mark tooth to state
        //this.setState((state) => ({ isDirty: true , charting: {...state.charting, [toothId]: toothState} }));
        //this.toothMenu.current.tooltipRef = null;
        //ReactTooltip.hide();
    }

    return (
        <Flex direction={['column']}>
            <ToothSetSelect
                charting = {charting}
                chartType = {chartType}
                onClick= {handleClick}
                className= {`ToothSet__${chartType}`}
            />
        </Flex>
    )
}

export {ToothChartSelect}
