import React from 'react';
import { PrivateRouteWithChildren } from 'src/_components/PrivateRoute';
import { UserCollectionPage } from './UserCollectionPage';
import { ClinicCollectionPage } from './ClinicCollectionPage';
import { Redirect } from 'react-router-dom';
import { useAuth } from 'src/_libs/auth';
import { UserRequestCollectionPage } from './UserRequestCollectionPage';
import { PatientUserCollectionPage } from './PatientUserCollectionPage';


export const Routes = () => {
    const {user} = useAuth();

    if (user?.is_staff != true) {
        return (
            <>
                <Redirect to={"/"} />
            </>
        );
    }
    return (
        <>
            <PrivateRouteWithChildren path="/adm/collections/dentists">
                <UserCollectionPage />
            </PrivateRouteWithChildren>
            <PrivateRouteWithChildren path="/adm/collections/userRequests">
                <UserRequestCollectionPage />
            </PrivateRouteWithChildren> 
            <PrivateRouteWithChildren path="/adm/collections/patientUsers">
                <PatientUserCollectionPage />
            </PrivateRouteWithChildren>
            <PrivateRouteWithChildren path="/adm/collections/">
                <ClinicCollectionPage />
            </PrivateRouteWithChildren>
        </>
    );
};
