import { toothNumbersClass, toothNumbersTag, toothTag, toothClass } from './constants';

export function isToothNumber(value) {
  return value >= 11 && value <= 48;
}

function separateTeeth(text) {
  // includes comma and space
  //const splitText = text.split(/(, )| /);
  // removes comma and space
  const splitText = text.split(/[\s,]+/);
  return splitText;
}

function wrapTeeth(text, tag, classes) {
  const toothnumbers = document.createElement(tag);
  toothnumbers.classList.add(classes);
  separateTeeth(text).map((word) => {
    if (/^, *$|^[ ,]+$/.test(word)) {
      // if word is blank or comma, just add it as a regular text
      return;
    } else if (isToothNumber(word)) {
      // otherwise, surround it with the tooth tag
      const tooth = document.createElement(toothTag);
      tooth.classList.add(toothClass);
      tooth.appendChild(document.createTextNode(word));
      toothnumbers.appendChild(tooth);
      toothnumbers.appendChild(document.createTextNode('\u00A0'));
      return;
    } else {
      // not tooth or space
      return;
    }
  });
  return toothnumbers;
}

export function replaceValuesInTemplate(messageTemplate, parameters) {
  // Replace placeholders in the template
  var outputMessage = messageTemplate.replace(/\{\{(\w+)\}\}/g, function (_, key) {
    // Return the matched key's value from the parameters object or keep the original placeholder if not found
    return parameters[key] || _;
  });
  return outputMessage;
}

export function createCategoryText(teeth, template = null) {
  // get toothnumbers > tooth from category
  // output: Teeth {} {} {}
  var serializer = new XMLSerializer();
  var teethDom = wrapTeeth(teeth, toothNumbersTag, toothNumbersClass);
  var teethString = serializer.serializeToString(teethDom);
  if (template === null) {
    return teethString;
  } else {
    return replaceValuesInTemplate(template, { teeth: teethString });
  }
}

export function createCategoryBlockData(category, teeth, template = null, subCategory=null, title=null) {
  return {
    category: category,
    subCategory: subCategory,
    title: title,
    text: createCategoryText(teeth, template),
    regions: separateTeeth(teeth),
    grade: null,
  };
}
