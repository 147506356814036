import { constants } from './constants';

const initState = {
  patientFormModal: false,
  appointmentFormModal: false,
};

export function dashboardPage(state = initState, action) {
  switch (action.type) {
    case constants.UPDATE:
      return {
        ...state,
        ...action.data,
      };
    default:
      return state;
  }
}
