import { authHeader, handleResponse } from '../../_helpers';
import { API_URLS } from '../../_config';

export const services = {
  fetchRequest,
};

function fetchRequest(requestId, idType = 'uid') {
  const requestUrl = API_URLS.getDentistRequestDetail;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };
  let url = requestUrl.url.replace('{idType}', idType).replace('{requestId}', requestId);

  return fetch(url, requestOptions).then(handleResponse);
}
