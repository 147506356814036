import { constants } from './constants';

export const actions = {
  nextPage,
  previousPage,
  setPage,
};

function nextPage() {
  return (dispatch, getState) => {
    let page = getState().createReport.currentPage;
    dispatch(update(page + 1));
  };
  function update(newPage) {
    return { type: constants.UPDATE, data: { currentPage: newPage } };
  }
}

function previousPage() {
  return (dispatch, getState) => {
    let page = getState().createReport.currentPage;
    dispatch(update(page - 1));
  };
  function update(newPage) {
    return { type: constants.UPDATE, data: { currentPage: newPage } };
  }
}

function setPage(newPage) {
  return (dispatch) => {
    dispatch(update(newPage));
  };
  function update(newPage) {
    return { type: constants.UPDATE, data: { currentPage: newPage } };
  }
}
