import React, { useEffect, useRef } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Box,
  Grid,
  Text,
  Button
} from "@chakra-ui/react";

import { useJsApiLoader } from '@react-google-maps/api';
import {
    updateGeopoint,
} from "../api/_services";
import { useSelector } from "react-redux";
import { getIntlLocale, getProfileCountry } from "src/App/_redux/selectors";
import {SearchBar} from "./LocationSearchBar";
import {LocationMap} from "./LocationMap";
import { FormattedMessage, useIntl } from "react-intl";
import { useMapStore } from "../state/map";

import {
    StyledInputLayout,
    StyledLabel,
    StyledTextArea
  } from 'src/_css/styles';



export const MapModal = ({
  isOpen,
  data,
  onClose, 
}) => {

  const init = useRef(false);
  const [ isLoading, setIsLoading ] = React.useState(false);
  const { currentMapCenter, updateMapCenter } = useMapStore();
  const [currentAddress, setCurrentAddress] = React.useState(data?.address || "");
  const { formatMessage } = useIntl();
  const country = useSelector(getProfileCountry)
  const intlLocale = useSelector(getIntlLocale);
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`,
    language: `${intlLocale}`,
    libraries: ['places']
  })

  useEffect(() => {
    if (data?.coordinates?.lat) {
        updateMapCenter({lat: data?.coordinates?.lat, lng: data?.coordinates?.lon});
    } 
  }, [data?.coordinates]);

  const handleLocationSearch = ({ lat, lng }) => {
    updateMapCenter({ lat, lng });
  };

  const handleMapClick = (newLocation) => {
    updateMapCenter(newLocation);
  };
  const handleSubmit = async () => {
    setIsLoading(true);
    const data = {
        coordinates: `${currentMapCenter.lat},${currentMapCenter.lng}`,
        address: currentAddress,
    };
    try {
      const resp = await updateGeopoint(data);
      setIsLoading(false);
      onClose({resultData: resp})
    } catch (err) {
        setIsLoading(false);
        console.log(err)
    }
  };

  return (
    <Modal
      size={"3xl"}
      isCentered
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay bg="blackAlpha.300" />
      <ModalContent p={"10px 30px 10px 30px"}>
        <ModalHeader display={"flex"} alignItems={"center"}>
          <Text width={"100%"} textAlign={'center'} fontSize={['20px']}>

            <FormattedMessage
                id={"settingsPage.booking.address.location.title"}
                defaultMessage={"Set Clinic Location"}
                description={"Set Clinic Location"}
            />

          </Text>
        </ModalHeader>
        <ModalBody>
            {
                isLoaded && (
                    <>
                    <Box w="full">
                      <SearchBar
                        init={init}
                        onLocationSearch={handleLocationSearch}
                        center={currentMapCenter}
                        setCurrentAddress={setCurrentAddress}
                      />
                    </Box>
                    <Box w="full" mt={"10px"}>
                        <LocationMap center={currentMapCenter} country={country} onMapClick={handleMapClick} />
                    </Box>
                    <Box py={['10px']}> 
                        <StyledInputLayout>
                            <StyledLabel>
                                <FormattedMessage 
                                    id={'settingsPage.booking.address.label'}
                                    defaultMessage="Address"
                                />
                                </StyledLabel>
                            <StyledTextArea
                                value={currentAddress}
                                placeholder={formatMessage({id: 'settingsPage.booking.address.label'})}
                                onChange={(e) => setCurrentAddress(e.target.value)}
                               />
                        </StyledInputLayout>
                    </Box>
                    <Grid
                        w={"full"}
                        templateColumns={["1fr 1fr", " 1fr 1fr"]}
                        gap={"10px"}
                        mt={"20px"}
                    >
                        <Button
                            h={41}
                            variant={"outline"}
                            onClick={onClose}
                            // leftIcon={<CloseIcon mr={2} />}
                        >
                        <FormattedMessage
                            id={
                            "popUp.title.cancel"
                            }
                            defaultMessage={"Cancel"}
                        />
                        </Button>

                        <Button
                            h={41}
                            // leftIcon={<CheckIcon width={12} height={10} mr={2} />}
                            variant={"solid"}
                            color={"white"}
                            type="submit"
                            isLoading={isLoading}
                            onClick={() => {
                                handleSubmit();
                            }}
                            >
                            <FormattedMessage
                                id={
                                "reportDetailsPage.report.button.save"
                                }
                                defaultMessage={"Save"}
                            />
                        </Button>
                    </Grid>
                </>
            )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

