import { useCallback } from 'react';
import { debounce } from 'lodash';

export const debounceClick = (func) =>
  useCallback(
    debounce(func, 300, {
      leading: true,
      trailing: false,
    }),
    []
  );
