import React from 'react';
import { Icon } from '@chakra-ui/react';

const PaginationNext = (props) => {
  const iconWidth = props?.width || 14;
  const iconHeight = props?.height || 14;
  return (
    <Icon 
      viewBox="0 0 14 14" 
      color={'#273238'} 
      fill={'none'}
      {...props}
      width={`${iconWidth}px`}
      height={`${iconHeight}px`}
    >
        <path 
            fillRule="evenodd" 
            clipRule="evenodd" 
            d="M4.90583 1.74965L10.2667 7.11048L4.82183 12.5553L4.07925 11.8121L8.7815 7.11048L4.16325 2.49223L4.90583 1.74965Z" 
            fill="currentColor"
        />

    </Icon>
  );
};
export default PaginationNext;