import React, { useState } from 'react';
import { useSelector} from 'react-redux';

import { Form } from 'react-final-form';
import { FORM_ERROR } from "final-form";

import { upperFirst, toUpper, startCase, map} from 'lodash';

// services
import { services as appServices } from '../App/_redux/services';

// selectors
import { getIntlMessages } from '../App/_redux/selectors';

// styles
import {
  StyledForm,
  StyledField,
  StyledInputLayout,
  StyledLabel,
  StyledButton,
  StyledInput,
  ErrorLabel,
  errorCss
} from '../_css/styles';
import { StyledChangePassword } from './styles';

export const Authentication = () => {
  const intlMessages = useSelector(getIntlMessages);
  let [ succeeded, setSucceeded ] = useState(null);

  const changePassword = async (values) => {
    try{
      await appServices.changePassword(values);
      setSucceeded('changed password');
      return null;
    } catch (error) {
      let newErrors = {};
      map(error, (value, key) => {
        newErrors[key] = value[0]
      })
      return {
        ...newErrors,
        [FORM_ERROR]: 'general_error'
      }
    }
  }

  return (
    <StyledChangePassword>
      <h2>{startCase(intlMessages['settingsPage.authentication.title'])}</h2>
      <div className={'successMessage'}>{intlMessages['settingsPage.authentication.description']}</div>
      <Form
        onSubmit={changePassword}
        render = {({
             submitError,
             handleSubmit,
             submitting,
             pristine,
        }) => (
          <StyledForm
            onSubmit={handleSubmit}
          >
            {submitError && <div className="error">{submitError}</div>}
            {succeeded && <div className={'successMessage'}><span>{succeeded}</span></div>}
            <StyledField name="old_password">
              {({input, meta}) => (
                <StyledInputLayout>
                  <StyledLabel>{upperFirst(intlMessages['settingsPage.authentication.old_password.label'])}</StyledLabel>
                  <StyledInput
                    {...input}
                    type="password"
                    addCSS={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)) && errorCss}
                    placeholder={upperFirst(intlMessages['settingsPage.authentication.old_password.placeholder'])} />
                  <ErrorLabel
                    addCSS={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)) && errorCss}
                    >
                      {((meta.error && meta.touched)  || (meta.submitError && !meta.dirtySinceLastSubmit)) && meta.error || meta.submitError}
                  </ErrorLabel>
                </StyledInputLayout>
              )}
            </StyledField>
            <StyledField name="new_password1">
              {({input, meta}) => (
                <StyledInputLayout>
                  <StyledLabel>{upperFirst(intlMessages['settingsPage.authentication.new_password1.label'])}</StyledLabel>
                  <StyledInput
                    {...input}
                    type="password"
                    addCSS={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)) && errorCss}
                    placeholder={upperFirst(intlMessages['settingsPage.authentication.new_password1.placeholder'])} />
                  <ErrorLabel
                    addCSS={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)) && errorCss}
                    >
                      {((meta.error && meta.touched)  || (meta.submitError && !meta.dirtySinceLastSubmit)) && meta.error || meta.submitError}
                  </ErrorLabel>
                </StyledInputLayout>
              )}
            </StyledField>
            <StyledField name="new_password2">
              {({input, meta}) => (
                <StyledInputLayout>
                  <StyledLabel>{upperFirst(intlMessages['settingsPage.authentication.new_password2.label'])}</StyledLabel>
                  <StyledInput
                    {...input}
                    type="password"
                    addCSS={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)) && errorCss}
                    placeholder={upperFirst(intlMessages['settingsPage.authentication.new_password2.placeholder'])} />
                  <ErrorLabel
                    addCSS={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)) && errorCss}
                    >
                      {((meta.error && meta.touched)  || (meta.submitError && !meta.dirtySinceLastSubmit)) && meta.error || meta.submitError}
                  </ErrorLabel>
                </StyledInputLayout>
              )}
            </StyledField>
            <StyledButton disabled={submitting || pristine} type="submit">{toUpper(intlMessages['settingsPage.authentication.button.label'])}</StyledButton>

        </StyledForm>
      )}
      />
    </StyledChangePassword>
  )
}
