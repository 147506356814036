import { MAIN_SCROLL_CSS_CLASS_NAME } from '../_config';

const SCROLL_SIZE_CACHE = {};

function getScrollSize(scrollCssClassName = MAIN_SCROLL_CSS_CLASS_NAME) {
  if (SCROLL_SIZE_CACHE[scrollCssClassName]) {
    return SCROLL_SIZE_CACHE[scrollCssClassName];
  }

  const container = document.createElement('DIV');
  const content = document.createElement('DIV');

  container.style.height = '100px';
  container.style.overflowY = 'scroll';
  container.style.position = 'absolute';
  container.style.left = '-9999px';
  content.style.height = '200px';
  container.className = scrollCssClassName;
  content.className = scrollCssClassName;

  container.appendChild(content);
  document.body.appendChild(container);

  SCROLL_SIZE_CACHE[scrollCssClassName] = container.offsetWidth - container.clientWidth;
  return SCROLL_SIZE_CACHE[scrollCssClassName];
}

export { getScrollSize };
