/* eslint-disable no-unused-vars */
import React, {useEffect, useRef, useState} from 'react';
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";
import { ToothSetModern } from 'src/_components/ToothSet/ToothSetModern';

import { Flex, Text, Button } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

const testdata =  {
    "dentist": "8ea8c240-8058-4766-9b4e-8291c7a4f1d3",
    "patient": {
        "id": 35,
        "ploveruser": 14,
        "ploveruser_unique_id": "a27f84cb-17bd-4c43-8d08-f18a23ebe328",
        "dentist": "8ea8c240-8058-4766-9b4e-8291c7a4f1d3",
        "external_id": "",
        "location": null,
        "location_data": {
            "country": "",
            "postal_code": "",
            "administrative_area_level_1": ""
        },
        "name": "",
        "relationship": "SELF",
        "parent": null,
        "unique_id": "7da252e9-7269-4176-8081-6d4bcd16cece",
        "first_name": "สัน",
        "last_name": "giii",
        "email": "jason.chung88@gmail.com",
        "phonenumber": "",
        "display_name": "",
        "nickname": "",
        "date_of_birth": "1994-12-06",
        "gender": "MALE"
    },
    "unique_id": "90c8d072-5698-4fc5-8091-9a720090f4f8",
    "availability": {},
    "type": "REPORT",
    "status": "PENDING",
    "record": 89,
    "report": {
        "summary": "",
        "sent_at": null,
        "grade": null,
        "report_data": {
            "time": 1713253241953,
            "blocks": [
                {
                    "id": "l0CEjGBnWb",
                    "data": {
                        "text": "<toothnumbers xmlns=\"http://www.w3.org/1999/xhtml\" class=\"cdx-toothnumbers\"><tooth class=\"cdx-tooth\">15</tooth>&nbsp;<tooth class=\"cdx-tooth\">16</tooth>&nbsp;<tooth class=\"cdx-tooth\">25</tooth>&nbsp;<tooth class=\"cdx-tooth\">26</tooth>&nbsp;Missing Teeth</toothnumbers>",
                        "grade": null,
                        "regions": [
                            "15",
                            "16",
                            "25",
                            "26"
                        ],
                        "category": "caries"
                    },
                    "type": "cariesCategory"
                },
                {
                    "id": "5Johlm5mt0",
                    "data": {
                        "text": "There are cavities on <toothnumbers xmlns=\"http://www.w3.org/1999/xhtml\" class=\"cdx-toothnumbers\"><tooth class=\"cdx-tooth\">47</tooth>&nbsp;<tooth class=\"cdx-tooth\">37</tooth>&nbsp;<tooth class=\"cdx-tooth\">36</tooth>&nbsp;<tooth class=\"cdx-tooth\">46</tooth>&nbsp;</toothnumbers>. Given the extent, a crown might be necessary. I recommend a detailed evaluation.",
                        "grade": null,
                        "regions": [
                            "47",
                            "37",
                            "36",
                            "46"
                        ],
                        "category": "caries"
                    },
                    "type": "cariesCategory"
                },
                {
                    "id": "i6gUDmzmES",
                    "data": {
                        "text": "There are cavities in <toothnumbers xmlns=\"http://www.w3.org/1999/xhtml\" class=\"cdx-toothnumbers\"><tooth class=\"cdx-tooth\">26</tooth>&nbsp;<tooth class=\"cdx-tooth\">25</tooth>&nbsp;<tooth class=\"cdx-tooth\">24</tooth>&nbsp;</toothnumbers>. A filling procedure might be necessary to restore these teeth. I recommend prompt attention.",
                        "grade": null,
                        "regions": [
                            "26",
                            "25",
                            "24"
                        ],
                        "category": "caries"
                    },
                    "type": "cariesCategory"
                },
                {
                    "id": "IQmV9yegWG",
                    "data": {
                        "text": "There are signs of teeth clenching and possible tongue thrusting. I recommend an evaluation for a dental guard and possible myofunctional therapy.",
                        "grade": null,
                        "regions": [
                            ""
                        ],
                        "category": "habits"
                    },
                    "type": "habitsCategory"
                }
            ],
            "version": "2.28.2"
        },
        "id": 76,
        "unique_id": "218ee9ce-b2c8-4e94-8984-efe8ac0a8630",
        "status": 2,
        "created_at": "2023-12-14T09:44:02.100683Z"
    },
    "user_request": 239,
    "extra_data": {
        "record_type": "SELF",
        "estimated_age": null,
        "clinic_dentist": 8
    },
    "request_reports": [
        {
            "summary": "",
            "sent_at": null,
            "grade": null,
            "report_data": {
                "time": 1713253241953,
                "blocks": [
                    {
                        "id": "l0CEjGBnWb",
                        "data": {
                            "text": "<toothnumbers xmlns=\"http://www.w3.org/1999/xhtml\" class=\"cdx-toothnumbers\"><tooth class=\"cdx-tooth\">15</tooth>&nbsp;<tooth class=\"cdx-tooth\">16</tooth>&nbsp;<tooth class=\"cdx-tooth\">25</tooth>&nbsp;<tooth class=\"cdx-tooth\">26</tooth>&nbsp;Missing Teeth</toothnumbers>",
                            "grade": null,
                            "regions": [
                                "15",
                                "16",
                                "25",
                                "26"
                            ],
                            "category": "caries"
                        },
                        "type": "cariesCategory"
                    },
                    {
                        "id": "5Johlm5mt0",
                        "data": {
                            "text": "There are cavities on <toothnumbers xmlns=\"http://www.w3.org/1999/xhtml\" class=\"cdx-toothnumbers\"><tooth class=\"cdx-tooth\">47</tooth>&nbsp;<tooth class=\"cdx-tooth\">37</tooth>&nbsp;<tooth class=\"cdx-tooth\">36</tooth>&nbsp;<tooth class=\"cdx-tooth\">46</tooth>&nbsp;</toothnumbers>. Given the extent, a crown might be necessary. I recommend a detailed evaluation.",
                            "grade": null,
                            "regions": [
                                "47",
                                "37",
                                "36",
                                "46"
                            ],
                            "category": "caries"
                        },
                        "type": "cariesCategory"
                    },
                    {
                        "id": "i6gUDmzmES",
                        "data": {
                            "text": "There are cavities in <toothnumbers xmlns=\"http://www.w3.org/1999/xhtml\" class=\"cdx-toothnumbers\"><tooth class=\"cdx-tooth\">26</tooth>&nbsp;<tooth class=\"cdx-tooth\">25</tooth>&nbsp;<tooth class=\"cdx-tooth\">24</tooth>&nbsp;</toothnumbers>. A filling procedure might be necessary to restore these teeth. I recommend prompt attention.",
                            "grade": null,
                            "regions": [
                                "26",
                                "25",
                                "24"
                            ],
                            "category": "caries"
                        },
                        "type": "cariesCategory"
                    },
                    {
                        "id": "IQmV9yegWG",
                        "data": {
                            "text": "There are signs of teeth clenching and possible tongue thrusting. I recommend an evaluation for a dental guard and possible myofunctional therapy.",
                            "grade": null,
                            "regions": [
                                ""
                            ],
                            "category": "habits"
                        },
                        "type": "habitsCategory"
                    }
                ],
                "version": "2.28.2"
            },
            "id": 76,
            "unique_id": "218ee9ce-b2c8-4e94-8984-efe8ac0a8630",
            "status": 2,
            "created_at": "2023-12-14T09:44:02.100683Z"
        }
    ],
    "completed_at": null,
    "remark": "",
    "id": 89,
    "created_at": "2023-12-14T09:18:20.725369Z"
}

async function exportAsImage(el){
    try{
        const canvas = await html2canvas(el);
        const image = canvas.toDataURL("image/png", 1.0);
        return image;
    } catch(err){
        console.log(err)
        return null;
    }
}


export const ExportComponent = ({toothClasses}) => {
    const exportRef = useRef();
    const [ imageSrc, setImageSrc ] = useState(null);
    
    useEffect(() => {
        if (exportRef.current != null){
            exportAsImage(exportRef.current).then((image) => {
                setImageSrc(image);
            })
        }
    }, [exportRef])

    const generatePdf = () => {
        const doc = new jsPDF({
            unit: 'px',
            format: 'a4',
            hotfixes: ["px_scaling"]
        });
        doc.text('Hello world!', 10, 20);

        doc.addImage(imageSrc, 'PNG', 0, 40, 220, 360);

        doc.save('a4.pdf');
    }

    return (
        <Flex direction={'column'} p={'200px'}>
            <div ref={exportRef}>
                <Flex 
                w={['full']}
                justify={'center'} 
                background={'#fff'}
                padding={['20px']}
                borderRadius={['25px']}
                >
                <Flex align={['center']}>
                    <Text mr={['5px']}>
                    <FormattedMessage
                        id={'reportDetailsPage.reviewReportModal.toothChart.right'}
                        defaultMessage={'R'}
                        />
                    </Text>
                </Flex>
                <ToothSetModern
                    toothClasses={toothClasses}
                    width={190}
                    height={360}
                />
                <Flex align={['center']}>
                    <Text ml={['5px']}>
                    <FormattedMessage
                        id={'reportDetailsPage.reviewReportModal.toothChart.left'}
                        defaultMessage={'L'}
                        />
                    </Text>
                </Flex>
                </Flex>
            </div>
            <Button isDisabled={!imageSrc} isLoading={!imageSrc} onClick={generatePdf}>{'Download Image'}</Button>
        </Flex>
    )
}

export const ReportPDFPage = () => {
    const toothClasses = {};

    // go through the block data and convert it to tooth charting object
    // object with key of tooth number "txx" and value of category in all caps for class
    testdata?.report?.report_data?.blocks.map((block) => {
        const data = block?.data;
        data.regions.map((region) => {
          if (region.length > 0){
            // will overwrite any previous block styles
            if (toothClasses[`t${region}`]){
                toothClasses[`t${region}`].push(block.data.category.toUpperCase())
            } else {
                toothClasses[`t${region}`] = [block.data.category.toUpperCase()]
            }
          }
        })
    })

    return (
        <Flex w={'full'} py={'100px'}>
            <ExportComponent 
                toothClasses={toothClasses}
            />

        </Flex>
    )
}