import React from 'react';
import { FormattedMessage } from 'react-intl';

export const CategoryTitle = ({category}) => {
    if (category === 'CARIES') {
        return (
            <FormattedMessage 
                id={`requestDetailPage.reportChart.category.CARIES.label`}
                defaultMessage='Caries'
            />
        )
    } else if (category === 'COLOR') {
        return (
            <FormattedMessage 
                id={`requestDetailPage.reportChart.category.COLOR.label`}
                defaultMessage='Color'
            />
        )
    } else if (category === 'GUMS') {
        return (
            <FormattedMessage 
                id={`requestDetailPage.reportChart.category.GUMS.label`}
                defaultMessage='Gums'
            />
        )
    } else if (category === 'HABITS') {
        return (
            <FormattedMessage 
                id={`requestDetailPage.reportChart.category.HABITS.label`}
                defaultMessage='Habits'
            />
        )
    } else if (category === 'ORTHO') {
        return (
            <FormattedMessage 
                id={`requestDetailPage.reportChart.category.ORTHO.label`}
                defaultMessage='Ortho'
            />
        )
    } else if (category === 'MULTIPLE') {
        return (
            <FormattedMessage 
                id={`requestDetailPage.reportChart.category.MULTIPLE.label`}
                defaultMessage='Multiple'
            />
        )
    } else {
        return (
            <FormattedMessage 
                id={`requestDetailPage.reportChart.category.OTHERS.label`}
                defaultMessage='General'
            />
        )
    }
}
