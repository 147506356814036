import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch} from 'react-redux';
import Modal from 'react-modal';
import styled from 'styled-components';
import cn from 'classnames';
import { upperFirst, toUpper } from 'lodash';
import { GRADES, REQUEST_TYPES } from '../_config';
import { Form, Field } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
// redux state
import { actions } from './_redux/actions';

import { getAppointmentCompleteModalOpen } from './_redux/selectors';

// components
import { CloseIcon as CloseSvg } from '../_images/icons/CloseIcon';
import { SelectAdapterNonClearable } from '../_components/FormElements';

//actions
import { actions as reportActions } from '../PatientReports/_redux/actions';
import { actions as patientNoteActions } from '../PatientNotes/_redux/actions';
import { actions as patientAppointmentActions } from '../PatientAppointments/_redux/actions';

// services
import { services as appointmentServices } from '../Appointments/_redux/services';
import { services as patientNoteServices } from '../PatientNotes/_redux/services';

// styles
import {
  StyledInputLayout,
  StyledTextArea,
  StyledButton,
  ErrorLabel,
  FlexColumn,
  FlexRow,
  errorCss,
  generalModalStyles,
  ModalCloseIcon,
  StyledLabel
} from '../_css/styles'
import { consoleLog } from '../_helpers/consoleLog';
import { getIntlLocale, getIntlMessages } from '../App/_redux/selectors';
import { getCurrentAppointmentRequest } from '../Appointments/_redux/selectors';
import { AppointmentForm } from '../Appointments/AppointmentForm';
import { getPatientDetails } from '../CreateReport/_redux/selectors';
import { formatName, localeFormatDate, localeFormatFromNow } from '../_helpers';
import { ReportVideoChat } from '../RequestDetailPage/ReportVideoChat';


const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const StyledHeader = styled.div`
  padding: 20px 0;
  font-size: 1.2rem;
  text-align:center;
`;

const StyledAppointmentCompleteWrapper = styled.div`
  padding: 30px 50px;
  width: 500px;

  .FormFinished{
    text-align: left;

    p{
      white-space: pre;

    }

    .divider{
      width: 100%;
      border-bottom: 1px solid #EDEDED;
      margin: 15px 0;
    }

    ${FlexColumn}{
      padding-bottom:0;
    }
  }

  .StartSection{
    .subTitle{
      display:flex;
      justify-content:flex-start;
      padding: 10px 0;
      > span {
        margin-right: 5px;
        &.fromNow{
          font-style: italic;
          color: gray;
          &.PastDue{
            color:red;
          }
        }
      }
    }

    p{
      font-style: italic;
      color: gray;
      &.PastDue{
        color:red;
      }
    }
  }

`;

export const AppointmentStartSection = ({currentRequestType, onClose, onSkip}) => {
  const intlMessages = useSelector(getIntlMessages);
  const intlLocale = useSelector(getIntlLocale);
  const currentAppointmentRequest = useSelector(getCurrentAppointmentRequest);

  if (currentRequestType == REQUEST_TYPES.REPORT){
    return (
        <>
        </>
    )
  } else if (currentRequestType == REQUEST_TYPES.VIDEOCHAT){
    return (
      <StyledAppointmentCompleteWrapper>
        <FlexColumn className={'StartSection'}>
          <h3>{intlMessages['videoChatPage.appointment.header']}</h3>
          <div className={'subTitle'}>
            <span>{intlMessages['videoChatPage.appointment.appointment.scheduledFor']}</span>
            <span style={{color: 'dimgray'}}>{localeFormatDate(currentAppointmentRequest?.start_date, intlLocale, "MMM DD h:mm A")}</span>
            <span className={cn('fromNow', {'PastDue': currentAppointmentRequest?.start_date_ts*1000 < Date.now()})}>{localeFormatFromNow(currentAppointmentRequest?.start_date, intlLocale)}</span>
          </div>
          
          <FlexRow style={{'justifyContent': 'center'}}>
            <ReportVideoChat onClose={onClose} />
          </FlexRow>
          <FlexRow>
            <StyledButton style={{'marginTop':10}} variant={'outlined'} onClick={() => onSkip()} type="button">
              {intlMessages['videoChatPage.appointment.appointment.skip.label']}
            </StyledButton>
          </FlexRow>
        </FlexColumn>
      </StyledAppointmentCompleteWrapper>
    )
  } else {
    return (
      <>
      </>
    )
  }
}

const AppointmentCompleteForm_Finished = ({next, summary}) => {
  const [ loading, setLoading ] = useState(false);
  const intlMessages = useSelector(getIntlMessages);
  const intlLocale = useSelector(getIntlLocale);
  const currentPatient = useSelector(getPatientDetails);
  let now = localeFormatDate(new Date, intlLocale, "l")
  let patientName = formatName(intlMessages['format.fullName'], currentPatient.first_name, currentPatient.last_name)

  const handleFinish = async () => {
    setLoading(true);
    // update appointment status as complete.
    await sleep(10);
    next();
    setLoading(false);
  }

  return (
    <StyledAppointmentCompleteWrapper>
      <FlexColumn className="FormFinished">
          <h3>{(intlMessages['videoChatPage.completed.sent.header'] || '').replace("{date}", now)}</h3>
          <div className={'divider'}></div>
          <p>{(intlMessages['videoChatPage.completed.sent.hasReceived'] || '').replace("{patientName}", patientName)}</p>
          <label>{intlMessages['videoChatPage.completed.sent.summary.label']}</label>
          <p style={{'fontStyle': 'italic', 'color': 'dimgray'}}>
            {summary}
          </p>
          <FlexColumn>
            <StyledButton 
              disabled={loading} 
              onClick={handleFinish}
            >
                {intlMessages['videoChatPage.completed.summary.submit.button.label']}
            </StyledButton>
          </FlexColumn>
      </FlexColumn>
    </StyledAppointmentCompleteWrapper>
  )
}

const AppointmentCompleteForm_SendSummary = ({next, updateSummary}) => {
  const intlMessages = useSelector(getIntlMessages);
  const currentAppointmentRequest = useSelector(getCurrentAppointmentRequest);
  const dispatch = useDispatch();

  const handleSubmit = async (values) => {
    // send message to patient and comment on appointment?
    updateSummary(values.remark)
    
    try{
      const resp = await appointmentServices.updateAppointment(currentAppointmentRequest?.unique_id, {is_complete: true, ...values});
      dispatch(patientAppointmentActions.updateAppointment(currentAppointmentRequest?.unique_id, resp))
      dispatch(reportActions.updateSendReport(resp.report))
      next()
    } catch (err){
      consoleLog("ERROR completing appointment ")
      return {
        ...err,
        [FORM_ERROR] : upperFirst(intlMessages['form.error.general.error'])
      }
    }
    return null;
  }

  const initValues = {
    grade: currentAppointmentRequest?.report?.grade,
    remark: currentAppointmentRequest?.report?.summary,
  }

  return (
    <StyledAppointmentCompleteWrapper>
      <StyledHeader>{intlMessages['videoChatPage.completed.summary.header']}</StyledHeader>
      <div>
        <Form
          initialValues={initValues}
          onSubmit={handleSubmit}
          render = {({
            handleSubmit,
            submitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <Field
                name="grade"
                items={GRADES}
                label={toUpper(intlMessages['requestDetailPage.report.form.grade'])}
                component={SelectAdapterNonClearable}
                placeholder={upperFirst(intlMessages['requestDetailPage.report.form.grade'])}
              />
              <StyledLabel>{intlMessages['videoChatPage.completed.summary.remark.label']}</StyledLabel>
              <Field name="remark" parse={v => v} >
                {({ input, meta}) => (
                  <StyledInputLayout>
                      <StyledTextArea
                        {...input}
                        columns={20}
                        disabled={submitting}
                        rows={5}
                        type="text"
                        addCSS={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)) && errorCss}
                        placeholder={intlMessages['videoChatPage.completed.summary.remark.placeholder']}
                      />
                    <ErrorLabel
                      isError={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit))}
                      >
                        {((meta.error && meta.touched)  || (meta.submitError && !meta.dirtySinceLastSubmit)) && 'error' || meta.submitError}
                    </ErrorLabel>
                  </StyledInputLayout>
                )}
              </Field>
              <FlexRow style={{'justifyContent': 'flex-end'}}>
                <StyledButton type="submit">
                  {intlMessages['videoChatPage.completed.summary.submit.button.label']}
                </StyledButton>
              </FlexRow>
            </form>
          )}
        />
      </div>
    </StyledAppointmentCompleteWrapper>
  )
}

const AppointmentCompleteForm_PatientNote = ({next, patientUid}) => {
  const dispatch = useDispatch();
  const intlMessages = useSelector(getIntlMessages);

  const handleSkip = () => {
    next();
  }

  const handleSubmit = async (values) => {
    // send message to patient and comment on appointment?
    try{
      const res = await patientNoteServices.createPatientNote(patientUid, values.text)
      dispatch(patientNoteActions.addPatientNote(patientUid, res))
      next();
    } catch (err){
      return {
        ...err,
        [FORM_ERROR] : upperFirst(intlMessages['form.error.general.error']) 
      }
    }
    
    return null;
  }

  return (
    <StyledAppointmentCompleteWrapper>
      <StyledHeader>{intlMessages['videoChatPage.completed.patientnote.header']}</StyledHeader>
      <div>
        <Form
          onSubmit={handleSubmit}
          render = {({
            handleSubmit,
            submitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <Field name="text" parse={v => v}>
                {({ input, meta}) => (
                  <StyledInputLayout>
                      <StyledTextArea
                        {...input}
                        columns={20}
                        disabled={submitting}
                        rows={10}
                        type="text"
                        addCSS={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)) && errorCss}
                        placeholder={intlMessages['videoChatPage.completed.patientnote.note.placeholder']}
                      />
                    <ErrorLabel
                      isError={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit))}
                      >
                        {((meta.error && meta.touched)  || (meta.submitError && !meta.dirtySinceLastSubmit)) && 'error' || meta.submitError}
                    </ErrorLabel>
                  </StyledInputLayout>
                )}
              </Field>
              <FlexRow>
                <StyledButton variant={'outlined'} onClick={handleSkip} disabled={submitting} type="button">
                  {intlMessages['videoChatPage.completed.patientnote.skip.button.label']}
                </StyledButton>
                <StyledButton disabled={submitting} type="submit">
                  {intlMessages['videoChatPage.completed.patientnote.submit.button.label']}
                </StyledButton>
              </FlexRow>
            </form>
          )}
        />
      </div>
    </StyledAppointmentCompleteWrapper>
  )
}

const AppointmentCompleteForm_Init = ({next, onNotCompleted}) => {
  const intlMessages = useSelector(getIntlMessages);

  return (
    <StyledAppointmentCompleteWrapper>
      <StyledHeader>{intlMessages['videoChatPage.completed.intial.header']}</StyledHeader>
      <FlexColumn addCss={'padding-bottom:0px;'}>
        <StyledButton onClick={next}>{intlMessages['videoChatPage.completed.intial.yes.button.label']}</StyledButton>
        <StyledButton onClick={onNotCompleted}>{intlMessages['videoChatPage.completed.intial.no.button.label']}</StyledButton>
      </FlexColumn>
    </StyledAppointmentCompleteWrapper>
  )
}

export const AppointmentCompleteForm = ({patientUid, onNotCompleted, onFinish, initialPage=0}) => {
  const summary = useRef("");
  const [ page, setPage ] = useState(initialPage);

  useEffect(() => {
    return () => {};
  }, [])

  const setSummary = (val) => {
    summary.current = val;
  }

  const _showPage = (pg) => {
    if (pg == 0){
      return (
        <AppointmentCompleteForm_Init
          next={() => setPage(1)}
          onNotCompleted={onNotCompleted}
          />
      )
    } else if (pg == 1){
      return (
        <AppointmentCompleteForm_PatientNote
          next={() => setPage(2)}
          patientUid={patientUid}
          />
      )
    } else if (pg == 2){
      return (
        <AppointmentCompleteForm_SendSummary
          next={() => setPage(3)}
          updateSummary={setSummary}
          />
      )
    } else {
      return (
        <AppointmentCompleteForm_Finished
          next={onFinish}
          summary={summary.current}
          />
      )
    }
  }

  return (
    <>
      {_showPage(page)}
    </>
  )
}

export const AppointmentCompleteModal = ({patientUid}) => {
  const modalIsOpen = useSelector(getAppointmentCompleteModalOpen);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {};
  }, [])

  const afterOpenModal = () => {
    /* all refs are set*/
  }

  const closeModal = () => {
    dispatch(actions.closeAppointmentCompleteModal())
  }

  return (
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={generalModalStyles}
      shouldCloseOnOverlayClick={false}
      contentLabel="Appointment Complete Form"
    >
      <div style={{ position: 'relative', height: '100%'}}>
        <ModalCloseIcon onClick={closeModal} ><CloseSvg/ ></ModalCloseIcon>
        <AppointmentForm patientUid={patientUid} onNotCompleted={closeModal} onFinish={closeModal} />
      </div>
    </Modal>
  )
}
