import React from 'react';
import { useSelector} from 'react-redux';

import { startCase } from 'lodash';

// selectors

import { getIntlMessages } from '../App/_redux/selectors';

// styles
import { StyledNotifications } from './styles';

export const Notifications = () => {
  const intlMessages = useSelector(getIntlMessages)

  return (
    <StyledNotifications>
      <h2>{startCase(intlMessages['settingsPage.notifications.title'])}</h2>
      <div className={'successMessage'}>{intlMessages['settingsPage.notifications.description']}</div>

    </StyledNotifications>
  )

}
