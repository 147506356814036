import React, { useEffect } from 'react';
import { createSelector } from 'reselect'
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { startCase  } from 'lodash';

import { extractPatientUuidFromChannelId, formatName  } from '../_helpers';

import { Flex, Text } from '@chakra-ui/react';

import { actions } from './_redux/actions';
import { actions as patientActions } from '../Patients/_redux/actions';

import { WrappedPage as BaseWrappedPage } from '../PageComponents/WrappedPage';

import { ChatConversation } from './ChatConversation';
import { ChatDetails } from './ChatDetails';
//import { WrappedPage } from '../PageComponents/WrappedPage';

// redux state 
import {
  getCurrentConversationData,
  getCurrentConversationId,
} from './_redux/selectors';

const WrappedPage = styled(BaseWrappedPage)`
  grid-template-rows: 40px 1fr;
  height:100vh;
  max-height:100vh;
`;

const StyledPageTitle = styled.div`
  font-family: Proxima Nova;
  font-weight: 600;
  font-size: 28px;
  line-height: 40px;
  /* identical to box height, or 143% */
  color: #12171A;
  text-align: left;
  margin-bottom: 40px;
`;

const StyledChatUI = styled.div`
  display:flex;
  flex-direction: row;
  overflow-y:hidden;
`;

const StyledChatListItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px;
  border-bottom: solid 1px #efefef;
  ${props => props.addCss}

  &:hover{
    cursor:pointer;
  }
`;

const selectedCss = {
  'transform': 'translateX(5px)',
  'backgroundColor':'#F8F8FF'
}

const StyledChatList = styled.section`
  flex: 0 0 auto;
  flex-direction: column;
  height: calc(100%-72px-50px-40px);
  width:15%;
`;

/*
ChatItem Model Stuff
 */



/*
ChatList Model Stuff
 */
const getChatListStateSlice = (state) => state.chatsList;
const getPatientsSlice = (state) => state.patients;
const getIntlSlice = (state) => state.intl;

const getIntlMessages = createSelector(
  [getIntlSlice],
  (intl) => {
    return intl.messages;
  }
)

const getChatsList = createSelector(
  getChatListStateSlice,
  (chatsList) => {
    return (chatsList.chatsList || []).filter(x => !x.startsWith('clinicalsupport.'))
  }
)

const getPatientsById = createSelector(
  [getPatientsSlice],
  (patients) => {
    return patients.byId;
  }
)

const isChatsListLoading = createSelector(
  getChatListStateSlice,
  (chatsList) => {
    return chatsList.chatsListLoading
  }
)

const ChatListItem = ({id, item, selected}) => {
  const dispatch = useDispatch();
  const intlMessages = useSelector(getIntlMessages);
  const currentConversation = useSelector(getCurrentConversationId);

  const onClick = () => {
    if (currentConversation != id){
      dispatch(actions.selectConversation(id));
      dispatch(patientActions.updateCurrentPatient(extractPatientUuidFromChannelId(id)));
    }
  }

  const name = formatName(intlMessages['format.fullName'], item.first_name, item.last_name)
  //TODO select latest message sent

  return(
    <StyledChatListItem addCss={ selected && selectedCss} onClick={onClick}>
      <div>{name}</div>
    </StyledChatListItem>
  )
}

const ChatList = ({currentConversion}) => {
  const intlMessages = useSelector(getIntlMessages);
  // TODO change chatsList to setChannelData and then only need to get data from channelsDataById
  const chatsList = useSelector(getChatsList);
  const chatsListLoading = useSelector(isChatsListLoading);
  const patientsById = useSelector(getPatientsById);

  if (chatsListLoading || chatsList == null){
    return (<div>Loading...</div>)
  }
  if ((chatsList || []).length < 1){
    return (
      <StyledChatList>
        <Flex>
          <Text>{intlMessages['requestDetailPage.details.chats.noChats.description']}</Text>
        </Flex>
      </StyledChatList>
    )
  }

  return (
    <StyledChatList>
      {chatsList.map( (id) => (
        (patientsById[extractPatientUuidFromChannelId(id)])
          ? <ChatListItem key={id} id={id} item={patientsById[extractPatientUuidFromChannelId(id)]} selected={currentConversion == id}/>
          : <StyledChatListItem key={id} addCss={(currentConversion == id) ? selectedCss : {'display': 'none'}}>{intlMessages['popUp.message.loading']}</StyledChatListItem>
      ))}
    </StyledChatList>
  )

}

const Chat = () => {
  const conversationId = useSelector(getCurrentConversationId);
  const conversationData = useSelector(getCurrentConversationData);

  return (
    <React.Fragment>
      <StyledChatUI>
          <ChatList conversationId={conversationId}/>
          <ChatConversation conversationData={conversationData} conversationId={conversationId}/>
          <ChatDetails />
      </StyledChatUI>
    </React.Fragment>
  )
}

export const ChatPage = () => {
  const dispatch = useDispatch();
  const intlMessages = useSelector(getIntlMessages);

  useEffect(()=>{
    dispatch(actions.selectConversation(null));
    return () => {};
  }, [])

  return (
    <WrappedPage>
      <StyledPageTitle>{startCase(intlMessages['chatPage.title'])}</StyledPageTitle>
       <Chat />
    </WrappedPage>
  )
}