import React, { useEffect, useState, useRef } from 'react';
import { createSelector } from 'reselect'
import { useSelector, useDispatch } from 'react-redux';
import cn from 'classnames';
import { localeFormatDate, formatName } from '../_helpers';
import { Scrollbars } from 'react-custom-scrollbars';

import { actions as patientActions } from '../Patients/_redux/actions';
import styled from 'styled-components';

const StyledChatDetails = styled.div`
  display:flex;
  flex-direction:column;
  width:30%;
  text-align:left;
  padding-left: 15px;
  height: calc(100% - 30px);
`;


const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 1 auto;
  padding-bottom: 50px;
  ${props => props.addCss}
`;


const ScrollView = styled.div`
  height: 100%;
  width: 100%;
  ${props => props.addCss}
`;

const StyledPatientInformation = styled.div`
  diplay:flex;
  flex-direction: column;
  font-size:14px;
  text-align:left;

  .patientName{
    font-size:16px;
    font-weight:700;
  }

  .patientBirthdate{

  }

  .patientGender{

  }
`;

const getPatientsSlice = (state) => state.patients;
const getIntlSlice = (state) => state.intl;

const getIntlMessages = createSelector(
  [getIntlSlice],
  (intl) => {
    return intl.messages;
  }
)

const getIntlLocale = createSelector(
  [getIntlSlice],
  (intl) => {
    return intl.locale;
  }
)

const getPatientsById = createSelector(
  [getPatientsSlice],
  (patients) => {
    return patients.byId;
  }
)

const getPatientRecords = createSelector(
  [getPatientsSlice],
  (patients) => {
    return patients.currentPatientRecords;
  }
)

const getPatientRequests = createSelector(
  [getPatientsSlice],
  (patients) => {
    return patients.currentPatientRequests;
  }
)

const getCurrentPatient = createSelector(
  [getPatientsSlice],
  (patients) => {
    return patients.currentPatientId;
  }
);

const getPatientInformation = createSelector(
  [getPatientsById, getCurrentPatient],
  (patientsById, currentPatientId ) => {
    return patientsById[currentPatientId]
  }
)

const StyledPatientRecordItem = styled.div`
  display:grid;
  display: -ms-grid;
  -ms-grid-gap: 4px;
  grid-gap: 4px;
  -ms-grid-columns: 32% 32% 32%;
  grid-template-columns: repeat(3, 32%);
  text-align:left;
`;

const PatientRecordItem = ({record}) => {
  const locale = useSelector(getIntlLocale);
  return (
    <StyledPatientRecordItem>
      <div><span>{localeFormatDate(record.created_at, locale, "MMM DD" )}</span></div>
      <div><span>{record.type}</span></div>
      <div><span>{record.patientrecord_uploads.length}</span></div>
    </StyledPatientRecordItem>
  )
}

const StyledPatientRecords = styled.div`
  display:flex;
  flex-direction:column;
  font-size: 12px;
  margin-top: 8px;
  margin-bottom: 8px;
`;

const PatientRecords = () => {
  const dispatch = useDispatch();
  const currentPatient = useSelector(getCurrentPatient);
  const records = useSelector(getPatientRecords)
  const [loadMore, toggleLoadMore] = useState(false);
  useEffect(() => {
    dispatch(patientActions.getCurrentPatientRecordsByUUid(currentPatient))
  }, [currentPatient])

    if (records && records.length > 3){
      if (loadMore){
        return (
          <StyledPatientRecords>
            {records.map((rec) => (
                  <PatientRecordItem
                      key={rec.id}
                      record={rec}
                  />
              ))}
          </StyledPatientRecords>
        )
      } else {
        return (
          <StyledPatientRecords>
            {records.slice(0,3).map((rec) => (
                  <PatientRecordItem
                      key={rec.id}
                      record={rec}
                  />
              ))}
              <button onClick={() => toggleLoadMore(true)}>Load More...</button>
          </StyledPatientRecords>
        )
      }
    } else if (records && records.length > 0){
      return (
        <StyledPatientRecords>
          {records.map((rec) => (
                <PatientRecordItem
                    key={rec.id}
                    record={rec}
                />
            ))}
        </StyledPatientRecords>
      )
    } else {
      return (
        <StyledPatientRecords>
          No Records
        </StyledPatientRecords>
      )
    }
}


const StyledPatientRecordsTitle = styled.div`
  font-size:20px;
  font-weight: 700;
  margin-top: 8px;
  margin-bottom: 8px;
  text-align:left;
`;

const PatientRecordsTitle = () => {
  return (
    <StyledPatientRecordsTitle>
      <span>Patient Records</span>
    </StyledPatientRecordsTitle>
  )
}


const StyledPatientRequestItem = styled.div`
  display:grid;
  display: -ms-grid;
  -ms-grid-gap: 4px;
  grid-gap: 4px;
  -ms-grid-columns: 32% 32% 32%;
  grid-template-columns: repeat(3, 32%);
  text-align:left;

  > div{
    white-space: nowrap;
    overflow: hidden;
  }
`;

const PatientRequestItem = ({request}) => {
  const locale = useSelector(getIntlLocale);
  let reqDate = "-";
  if (request.status == "COMPLETE"){
    reqDate = localeFormatDate(request.completed_at, locale, "MMM DD" )
  }
  return (
    <StyledPatientRequestItem>
      <div><span>{reqDate}</span></div>
      <div><span>{request.type}</span></div>
      <div><span>{request.status}</span></div>
    </StyledPatientRequestItem>
  )
}

const StyledPatientRequestsTitle = styled.div`
  font-size:20px;
  font-weight: 700;
  margin-top: 8px;
  margin-bottom: 8px;
  text-align:left;
`;

const PatientRequestsTitle = () => {
  return (
    <StyledPatientRequestsTitle>
      <span>Previous Requests</span>
    </StyledPatientRequestsTitle>
  )
}

const StyledPatientRequests = styled.div`
  display:flex;
  flex-direction:column;
  font-size: 12px;
  margin-top: 8px;
  margin-bottom: 8px;
`;

const PatientRequests = () => {
  const dispatch = useDispatch();
  const currentPatient = useSelector(getCurrentPatient);
  const requests = useSelector(getPatientRequests)
  const [loadMore, toggleLoadMore] = useState(false);
  useEffect(() => {
    dispatch(patientActions.getCurrentPatientRequestsByUUid(currentPatient))
  }, [currentPatient])

  // status == "COMPLETE"
  //   type == "REPORT" && report
  //     report.completed_at
  //    type == "VIDEOCHAT" && extra_data && extra_data.video_chat
  //      extra_data.video_chat.chat_date
  // else...
  //
  //
  if (requests && requests.length > 3){
    if (loadMore){
      return (
        <StyledPatientRequests>
            {requests.map((req) => (
                  <PatientRequestItem
                      key={req.id}
                      request={req}
                  />
              ))
            }
        </StyledPatientRequests>
      )
    } else {
      return (
        <StyledPatientRequests>
            {requests.slice(0,3).map((req) => (
                  <PatientRequestItem
                      key={req.id}
                      request={req}
                  />
              ))
            }
            <button onClick={() => toggleLoadMore(true)}>Load More...</button>
        </StyledPatientRequests>
      )
    }
  } else if (requests && requests.length > 0){
    return (
      <StyledPatientRequests>
        {requests.map((req) => (
              <PatientRequestItem
                  key={req.id}
                  request={req}
              />
          ))
        }
      </StyledPatientRequests>
    )
  } else {
    return (
      <StyledPatientRequests>
        No Requests
      </StyledPatientRequests>
    )
  }
}

const StyledPatientInformationTitle = styled.div`
  font-size:20px;
  font-weight: 700;
  margin-top: 8px;
  margin-bottom: 8px;
  text-align:left;
`;

const PatientInformationTitle = () => {
  return (
    <StyledPatientInformationTitle>
      <span>Patient Information</span>
    </StyledPatientInformationTitle>
  )
}

const PatientInformation = () => {
  const patientInfo = useSelector(getPatientInformation);
  const locale = useSelector(getIntlLocale);
  const intlMessages = useSelector(getIntlMessages);
  if (patientInfo){
    return (
      <StyledPatientInformation>
        <div className={cn('patientName')}><span>{formatName(intlMessages['format.fullName'], patientInfo.first_name, patientInfo.last_name)}</span></div>
        <div className={cn('patientBirthdate')}><span>{localeFormatDate(patientInfo.date_of_birth, locale, 'll')}</span></div>
        <div className={cn('patientGender')}><span>{patientInfo.gender}</span></div>
      </StyledPatientInformation>
    )
  } else {
    return(
    <StyledPatientInformation>
      <div>No Patient Details Loaded</div>
    </StyledPatientInformation>
    )
  }
}

const ChatDetails = () => {
  const currentPatient = useSelector(getCurrentPatient);
  const [height, setHeight] = useState(0)
  // eslint-disable-next-line no-unused-vars
  const [width, setWidth] = useState(0)
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current){
      setHeight(ref.current.clientHeight)
      setWidth(ref.current.clientWidth)
    }
    function handleResize() {
      if (ref.current){
        setHeight(ref.current.clientHeight)
        setWidth(ref.current.clientWidth)
      }
    }
    window.addEventListener('resize', handleResize)
  })
  if (currentPatient != null){
    return(
        <StyledChatDetails
           ref={ref}
          >
          <Scrollbars
              style={{height: height}}
              autoHide
            >
            <ScrollView>
              <FlexColumn>
                <PatientInformationTitle />
                <PatientInformation />
                <PatientRequestsTitle />
                <PatientRequests />
                <PatientRecordsTitle />
                <PatientRecords />
              </FlexColumn>
            </ScrollView>
          </Scrollbars>
        </StyledChatDetails>
    )
  } else {
    return(
      <StyledChatDetails>
      </StyledChatDetails>
    )
  }
}

export { ChatDetails }
