import { authHeader, handleResponse } from '../../_helpers';
import { API_URLS } from '../../_config';

export const services = {
  getRequest,
};

function getRequest(requestId) {
  const requestUrl = API_URLS.getDentistRequestDetailId;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };
  let url = requestUrl.url.replace('{requestId}', requestId);

  return fetch(url, requestOptions).then(handleResponse);
}
