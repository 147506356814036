import React from 'react';
import { useSelector } from 'react-redux';

import { localeFormatDate, formatName } from '../_helpers';

// redux state
import { getPatientDetails} from './_redux/selectors';

// selectors
import { getIntlLocale, getIntlMessages } from '../App/_redux/selectors';

// styles
import { StyledReportSentSection } from './styles';

const ContentSection = () => {
  const intlMessages = useSelector(getIntlMessages);
  const intlLocale = useSelector(getIntlLocale);
  const currentPatient = useSelector(getPatientDetails);
  let now = localeFormatDate(new Date, intlLocale, "l")
  let patientName = formatName(intlMessages['format.fullName'], currentPatient.first_name, currentPatient.last_name)

  return(
    <div className={'contentSection'}>
      <div>
        <h3>{intlMessages['requestDetailPage.timeline.sent.reportSentOn'].replace("{date}", now)}</h3>
        <div className={'divider'}></div>
        <p>{intlMessages['requestDetailPage.timeline.sent.hasReceived'].replace("{patientName}", patientName)}</p>
        <p>{`The patient might have follow-up questions (up to 24 hours from now). We will notify you if the patient contacts you with any questions.`}
        </p>
      </div>
    </div>
  )
}


export const ReportSentSection = () => {
  return(
    <StyledReportSentSection>
      <ContentSection />
    </StyledReportSentSection>
  )
}
