import styled from 'styled-components';
import { motion } from 'framer-motion';

import { StyledButton } from '../_css/styles';

export const StyledReportSentSection = styled.div`
  .contentSection {
    padding: 50px;
    text-align: left;
    width: 800px;
    p {
      white-space: break-spaces;
    }

    .divider {
      width: 100%;
      border-bottom: 1px solid #ededed;
      margin: 15px 0;
    }
  }
`;

export const StyledFormHeader = styled.div`
  font-size: 18px;
  text-align: left;
  font-family: Proxima Nova Semibold;
  margin: 20px 0 20px 0;
`;

export const StyledLoading = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 700px;
  width: 900px;

  .message {
    margin-top: 20px;
  }
`;

export const StyledPartialScan = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  flex-grow: 1;
  height: 100%;
  .titleSection {
    color: #12171a;
    margin-top: 20px;
    text-align: center;

    .statusMessage {
      font-style: italic;
      color: gray;
    }
  }

  .actionsSection {
    position: absolute;
    right: 0;
    bottom: 0;
  }

  .contentSection {
    padding-top: 30px;
    display: flex;

    .partialScanSection {
      display: flex;
      flex-direction: column;
      margin-left: 15px;
      align-items: flex-end;

      .partialScanChart {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: fit-content;
        padding-top: 30px;
        background-color: #273238;
        border-radius: 25px;
        text-align: center;
      }

      svg {
        height: 400px;
      }

      .formSection {
        display: flex;
        flex-direction: column;
        width: 300px;
        margin-top: 15px;
        margin-left: 50px;
        margin-right: 50px;
        margin-bottom: 15px;

        .partialScanRequestForm {
          margin-top: 0px;

          .statusMessage {
            color: white;
            font-size: 12px;
            margin: 10px;
          }

          ${StyledButton} {
            background: #367fff;
            border-color: #367fff;

            .loading {
              svg {
                height: 16px;
              }
            }

            &:disabled {
              opacity: 0.7;
            }
          }
        }
      }
      .partialScanActions {
      }
      .partialScanRequired {
        padding-top: 50px;
        display: flex;
        flex-direction: column;

        .partialScanRequiredDescription {
          padding-bottom: 20px;
          font-size: 18px;
        }

        button {
          &:last-child {
            margin-top: 15px;
          }
        }

        .errorMessage {
          margin-top: 20px;
          color: red;
          font-size: 14px;
          text-align: center;
        }
      }
    }
  }
`;

export const StyledCategoryItem = styled.li`
  position:relative;
  border-radius:80px;
  font-size:15px;
  line-height:24px;
  display: flex;
  align-items: center;
  background-color: white;
  padding: 8px 15px 8px 0;
  &:marker{
    none;
  }
  &:hover{
    cursor:pointer;
    opacity: 0.8;
  }

  &.selected{
      background-color: #405159;
      color:white;

      &:hover{
        cursor: default;
        opacity: 1;
      }
  }

  &:before {content:'';display:inline-block;width:10px;height:10px;border-radius:10px;vertical-align:middle;margin-left:7px;margin-right:12px;}
  &.COLOR::before {background-color:#367FFF}
  &.ORTHO::before {background-color:#00574F}
  &.GUMS::before {background-color:#daff00}
  &.HABITS::before {background-color:#daff00}
  &.CARIES::before {background-color:#DC4E41}
  &.CALCULUS::before {background-color:#ffa500}
  &.ALL::before {background-color:#000000}
  &:not(:last-child) {margin-bottom:8px;}
`;

export const StyledChartCategories = styled.ul`
  margin-top: 42px;
  list-style-type: none;
`;

export const StyledReviewReportSection = styled.div`
  display: flex;
  flex-direction: column;

  .titleSection {
    color: #12171a;
    margin-bottom: 20px;
    margin-top: 20px;
    text-align: center;

    .statusMessage {
      font-style: italic;
      color: gray;
    }
  }

  .contentSection {
    padding-top: 10px;
    display: flex;
    width: 1000px;
    overflow: hidden;
    position: relative;
    flex-direction: column;

    .reviewReportCategorySection {
      ${StyledChartCategories} {
        margin-top: 0px;
        width: fit-content;
        display: flex;
        flex-wrap: wrap;
        ${StyledCategoryItem} {
          font-size: 16px;
          background-color: #efefef;
          margin-right: 12px;
          margin-top: 0px;
          &.selected {
            background-color: #405159;
            color: white;

            &:hover {
              cursor: default;
              opacity: 1;
            }
          }
        }
      }
    }

    .reviewReportDetailSection {
      display: flex;
      width: 100%;
    }

    .chartSection {
      width: fit-content;
    }

    .commentsSection {
      height: 500px;
      overflow: auto;
      flex-grow: 1;
      .reportComments {
      }
    }

    .reviewReportActions {
      display: flex;
      justify-content: flex-end;

      button {
        margin: 10px;
        margin-top: 0;
      }
    }
  }
`;

export const StyledFormActions = styled.div`
  display: flex;
  flex-direction: row-reverse;

  ${StyledButton} {
    margin: 0 auto;
    margin-top: 10px;
    font-weight: 700;
  }

  .clear {
    background-color: lightgray;
  }
`;

export const StyledSendReportComponent = styled(motion.div)`
  position: absolute;
  top: 15px;
  left: calc(100% - 10px);
  height: calc(100% - 15px);
  form {
    width: 350px;
    height: 100%;
  }

  ${StyledFormHeader} {
    font-size: 22px;
    text-align: left;
  }

  ${StyledFormActions} {
    width: 350px;
    position: absolute;
    bottom: 0;
    padding-bottom: 15px;
    justify-content: space-between;
    flex-direction: row;
    display: flex;
    > button {
      margin: 0;
    }
  }
`;

export const StyledSendReportSection = styled.div`
  display: flex;
  flex-direction: column;

  .titleSection {
    color: #12171a;
    margin-bottom: 20px;
    margin-top: 20px;
    text-align: center;

    .statusMessage {
      font-style: italic;
      color: gray;
    }
  }

  .contentSection {
    padding-top: 30px;
    display: flex;
    width: 1000px;
    overflow: auto;
    justify-content: center;
  }
`;

export const StyledTextDescription = styled.div`
  font-style: italic;
`;

export const StyledSendReportFormContainer = styled.div`
  height: 100%;
  background: #f7f9fa;
  border: 1px solid #ededed;
  box-shadow: 1px 3px 20px rgba(239, 239, 239, 0.5);
  border-radius: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 20px 50px 0px;
  font-size: 14px;

  > form {
    position: relative;
  }
`;

export const StyledReportProgress = styled.div`
  text-align: center;
  .progressTitle {
    margin-top: 10px;
    font-family: 'Proxima Nova';
    font-size: 18px;
    color: lightgray;
    &--finished {
      color: black;
    }
    &--active {
      color: #367fff;
    }
  }
`;

export const StyledReportWrapper = styled.div`
  padding: 40px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
`;

export const StyledReportActions = styled.div`
  padding: 40px;
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
`;
