import { constants } from './constants';

const initState = {
  loading: false,
  error: false,
  loadingList: false,
  errorList: false,
  noteCount: 0,
  byId: {},
  isMorebyId: {},
  currentNoteId: null,
  loadMoreOffset: 0,
  patientNoteCount: 0,
  currentPatientNotes: [],
};

export function patientNotes(state = initState, action) {
  switch (action.type) {
    case constants.FETCH_PATIENTNOTES_REQUEST:
      return {
        ...state,
        loadingList: true,
        errorList: false,
      };
    case constants.FETCH_PATIENTNOTES_SUCCESS:
      return {
        ...state,
        loadingList: false,
        errorList: false,
        byId: {
          ...state.byId,
          [action.data.uid]: Object.assign(
            {},
            state.byId[action.data.uid],
            action.data.patient_notes
          ),
        },
        isMorebyId: {
          ...state.isMorebyId,
          [action.data.uid]: action.data.isMore,
        },
      };
    case constants.FETCH_PATIENTNOTES_FAILURE:
      return {
        ...state,
        loadingList: false,
        errorList: action.error,
      };
    case constants.ADD_PATIENTNOTE:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.data.uid]: {
            ...state.byId[action.data.uid],
            [action.data.note.unique_id]: Object.assign(
              {},
              state.byId[action.data.uid][action.data.note.unique_id],
              action.data.note
            ),
          },
        },
      };
    case constants.CREATEPATIENTNOTE_REQUEST:
      return state;
    case constants.CREATEPATIENTNOTE_SUCCESS:
      return state;
    case constants.CREATEPATIENTNOTE_FAILURE:
      return state;
    case constants.UPDATE_PATIENTNOTE:
      return state;
    default:
      return state;
  }
}
