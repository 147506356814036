import { useQuery } from 'react-query';
import { queryConfig } from 'src/_libs/react-query';

import { fetchClinicRoles } from './services';

export const QUERY_KEY_NAME = 'Dentist_ClinicRoles';

const newQueryConfig = {
    ...queryConfig,
    refetchOnMount: false,
    queries: {
        ...queryConfig.queries,
    }
}

export const queryRequest = async () => {
    return fetchClinicRoles()
}

export const useClinicRoles = ({config={}}) => {       

    return useQuery({
        ...newQueryConfig,
        ...config,
        queryKey: [QUERY_KEY_NAME, {}],
        queryFn: () => queryRequest(), // in brackets because im parsing the object in queryRequest
      });
}