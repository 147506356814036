import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch} from 'react-redux';
import { motion, AnimatePresence } from 'framer-motion';
import cn from 'classnames';
import { Flex, Box } from '@chakra-ui/react';

import { RiDeleteBinLine } from 'react-icons/ri'

import {
  upperFirst, toUpper, startCase,
  findIndex,
} from 'lodash';
import { Form, Field } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import { FaPlus , FaChevronDown, FaChevronLeft, FaChevronRight } from 'react-icons/fa'

import { REPORT_TYPES, GRADES } from '../../_config';
import { required} from '../../_components/FormElements';

// components
import { MediaSection } from '../../MediaSection';
import { SelectAdapterGrade } from '../../_components/FormElements';

// redux state
import { constants } from './_constants';
import { actions } from './_actions';
import {
  getCurrentChartId,
  getCurrentRegions,
  getReportCharts,
  getCurrentReportChart,
  getCurrentCategory,
  getCommentFormIsOpen
} from './_selectors';

// actions
import { actions as createReportActions } from '../_redux/actions';
import { actions as reportActions } from '../../PatientReports/_redux/actions';

// services
import { services as reportServices } from '../../PatientReports/_redux/services';

// selectors
import { getReportPartialScanStatus } from '../_redux/selectors';
import {
  getDetailsReportId,
  getRequestDone
} from '../../PatientDetailsPage/_redux/selectors';
import {
  getIntlMessages,
} from '../../App/_redux/selectors';


// styles
import {
  StyledSelectedFields,
  StyledInputLayout,
  StyledTextArea,
  StyledLabel,
  StyledButton,
  ErrorLabel,
  errorCss
} from '../../_css/styles';
import {
  StyledFormActions
} from '../styles';
import {
  FlexColumn,
  StyledChartSection,
  StyledReportChart,
  StyledReportChartHeader,
  StyledNoteSection,
  StyledReportForm,
  StyledReportCategory,
  StyledReportCategoryRegions,
  StyledReportCategoryRegion,
  StyledBlankReportCategoryRegion,
  StyledReportCategories,
  StyledCreateReportSection,
  StyledToothMovementChart,
  StyledPatientRecordReportChartForm,
  StyledSelectTeethLabel
} from './styles';
import { consoleLog } from '../../_helpers/consoleLog';
import { useReportCategories } from 'src/Reports/api/getReportCategories';

function checkChartId(uid){
  if (typeof uid == "string" && uid.length > 0){
    return true;
  } else if (typeof uid == "number" && uid > 0){
    return true;
  } return false;
}

const ChartSection = () => {
  const dispatch = useDispatch();
  const [charting, setCharting] = useState({});
  const currentChartId = useSelector(getCurrentChartId);
  const currentReportChart = useSelector(getCurrentReportChart);

  consoleLog("currentreportchart ", currentChartId, " currentReportChart ", currentReportChart)
  useEffect(()=> {

    if (checkChartId(currentChartId)){
      consoleLog("currentreportchart ", currentReportChart, currentReportChart.getCharting())
      setCharting(currentReportChart.getCharting());
      dispatch(actions.updateCurrentRegions(currentReportChart.getCharting()));
    } else {
      setCharting([])
    }
  }, [currentChartId, currentReportChart])

  const handleRegionChange = (charting) => {
    setCharting(charting);
    dispatch(actions.updateCurrentRegions(charting));
  }

  return (
    <StyledChartSection>
      <StyledToothMovementChart
        charting={charting}
        handleChange={handleRegionChange}
      />
    </StyledChartSection>
  )
}

const EditNoteForm = ({reportChart}) => {
  const dispatch = useDispatch();
  const intlMessages = useSelector(getIntlMessages);
  // eslint-disable-next-line no-unused-vars
  const [isSaving, setSaving] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isDirty, setDirty ] = useState(false);
  const [ isDeleteReady, setIsDeleteReady ] = useState(false);
  const currentRegions = useSelector(getCurrentRegions);
  const currentReportChart = useSelector(getCurrentReportChart);
  const currentReportId = useSelector(getDetailsReportId);
  const isRequestDone = useSelector(getRequestDone);
  const formRef = useRef();
  //const resetButton = useRef();
  consoleLog("currentReportChart", currentRegions, currentReportChart, reportChart.getId(), currentReportChart==reportChart.getId());

  const viewChartClick = () => {
    dispatch({type: constants.UPDATE, data:{commentFormIsOpen: false}})
  }

  const deleteChart = async () => {
    if (currentReportChart && currentReportChart.getId()){
      await reportServices.deleteReportChart(currentReportId, currentReportChart.getId());
      dispatch(actions.selectReportChart(-1));
      dispatch(reportActions.removeReportChart(currentReportId, currentReportChart.getId()))
    }
  }

  const closeChart = () => {
    dispatch(actions.selectReportChart(-1));
    dispatch({type: constants.UPDATE, data:{commentFormIsOpen: false}})
  }

  const updateReportChart = async (values) => {
    const { report_type, ...newValues } = values;
    newValues['extra_data'] = Object.assign({}, currentReportChart.getExtraData(), {'all_regions': currentRegions, type: report_type})
    //newValues['grade'] = 1; // temporary grade of 1 NEUTRAL TODO maybe get rid of this field in backend
    setSaving(true);
    try{
      await reportServices.updateReportChart(currentReportId, currentReportChart.getId(), newValues);
      dispatch(reportActions.updateReportChart(currentReportId, newValues))
      //dispatch(reportActions.fetchReport(currentReportId));
      setSaving(false);
      setDirty(false);
      formRef.current.restart(values);
      return null;
    } catch(err){
      setSaving(false);
      setDirty(false);
      return {
        ...err,
        [FORM_ERROR]: upperFirst(intlMessages['form.error.general.error'])
      }
    }
  }

  let initValues = {
    comment: "",
    report_type: "",
    grade: ""

  }
  if (currentReportChart != null){
    initValues = {
      comment: currentReportChart.getComment(),
      report_type: currentReportChart.getReportType(),
      grade: currentReportChart.getGrade(),
    }
  }

  return (
      <Form
        onSubmit={updateReportChart}
        initialValues={initValues}
        render = {({
          handleSubmit,
          form,
          submitting,
          pristine,
        }) => {
          formRef.current = form;
          return (
            <StyledPatientRecordReportChartForm
              isSelected={(currentReportChart && currentReportChart.getId()) ==reportChart.getId()}
              id={'reportChart'+reportChart.getId()}
              onSubmit={handleSubmit}
              >
                <StyledSelectTeethLabel>
                  <div>{startCase(intlMessages['requestDetailPage.reportChart.form.regions.title'])}</div>
                  <div className={'selectMoreButton'} onClick={viewChartClick}>
                    <span>{startCase(intlMessages['requestDetailPage.reportChart.form.regions.title.selectMore'])}</span>
                    <FaChevronDown />
                  </div>
                </StyledSelectTeethLabel>
                <StyledSelectedFields>
                  {currentRegions.length > 0
                    ? currentRegions.map((tooth, index) => <span key={index}>{tooth.replace("t", "")}</span>)
                    : upperFirst(intlMessages['requestDetailPage.reportChart.editForm.regions.noRegionSelected'])
                  }
                </StyledSelectedFields>

                <Field
                  name="grade"
                  items={GRADES}
                  validate={required}
                  label={upperFirst(intlMessages['requestDetailPage.report.form.grade'])}
                  component={SelectAdapterGrade}
                  placeholder={upperFirst(intlMessages['requestDetailPage.report.form.grade'])}
                />

                <Field name="comment" parse={v => v} validate={required}>
                  {({ input, meta}) => (
                    <StyledInputLayout>
                      <StyledLabel className="Input__Label">{startCase(intlMessages['requestDetailPage.reportChart.form.comment'])}</StyledLabel>
                      <StyledTextArea
                        {...input}
                        columns={10}
                        disabled={isRequestDone || submitting}
                        rows={5}
                        type="text"
                        addCSS={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)) && errorCss}
                        placeholder={upperFirst(intlMessages['requestDetailPage.reportChart.form.comment'])} />
                      <ErrorLabel
                        addCSS={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)) && errorCss}
                        >
                          {((meta.error && meta.touched)  || (meta.submitError && !meta.dirtySinceLastSubmit)) && intlMessages[`form.error.${(meta.error)}`] || meta.submitError}
                      </ErrorLabel>
                    </StyledInputLayout>
                  )}
                </Field>
                {/*<StyledButton disabled={sent_at || submitting} type="submit">{toUpper(intl.messages['requestDetailPage.report.form.button.submit'])}</StyledButton>*/}
                <StyledFormActions style={{'flexDirection': 'column', alignItems: 'space-around', padding: '0 15px'}}>
                  <Flex justify={'space-between'}>
                    <StyledButton 
                      className={'cancel outlined'} 
                      size={'small'}
                      style={{margin:0}}
                      type="button" 
                      onClick={closeChart}
                    >
                      {toUpper(intlMessages['requestDetailPage.timeline.editreport.cancel.label'])}
                    </StyledButton>
                    <StyledButton 
                      size={'small'}
                      style={{margin:0}} 
                      disabled={isRequestDone || submitting || pristine} 
                      type="submit"
                    >
                      {toUpper(intlMessages['requestDetailPage.reportChart.form.button.submit'])}
                    </StyledButton>
                  </Flex>
                  <Flex style={{paddingTop: 10}} justify={'flex-end'}>
                    <StyledButton 
                      className={'delete'} 
                      type="button"   
                      size={'small'}
                      style={{margin:0, minWidth: isDeleteReady ? '150px' : 'unset', padding: '10px 15px'}}
                      initial={false}
                      animate={{width: isDeleteReady ? 'fit-content' : '48px'}}
                      transition={{duration: 0.3}}
                      disabled={submitting} 
                      onClick={isDeleteReady ? deleteChart : () => setIsDeleteReady(true)}
                    >
                      <Flex overflow={'clip'} position={'relative'} minH={'18px'} >
                        <Box position={'absolute'} left={'0px'} top={'0px'}>
                          <RiDeleteBinLine size={18} fill="#FFFFFF" />
                        </Box>
                        <Box style={{
                          paddingLeft: 10, 
                          paddingRight: 10, 
                          marginLeft: '20px', 
                          width: isDeleteReady ? '100%' : 'fit-content',
                          whiteSpace: 'nowrap'
                        }}>
                          {toUpper(intlMessages['requestDetailPage.timeline.editreport.delete.confirm.label'])}
                        </Box>
                      </Flex>
                    </StyledButton>
                  </Flex>
                </StyledFormActions>
            </StyledPatientRecordReportChartForm>
          )
        }}
        />
  )
}

const NewNoteForm = () => {
  const dispatch = useDispatch();
  // eslint-disable-next-line no-unused-vars
  const [isSaving, setSaving] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isDirty, setDirty ] = useState(false);
  const intlMessages = useSelector(getIntlMessages);
  const currentRegions = useSelector(getCurrentRegions);
  const currentCategory = useSelector(getCurrentCategory);
  const currentReportChart = useSelector(getCurrentReportChart);
  const currentReportId = useSelector(getDetailsReportId);
  const isRequestDone = useSelector(getRequestDone);
  const formRef = useRef();

  const viewChartClick = () => {
    dispatch({type: constants.UPDATE, data:{commentFormIsOpen: false}})
  }

  const clearChart = (form) => {
    form.restart();
    dispatch({type: constants.UPDATE, data:{commentFormIsOpen: false}})
  }

  const createReportChart = async(values) => {
    // add values extradata
    //values['extra_data'] = currentReportChart.getExtraData();
    const newValues = values;

    newValues['extra_data'] = Object.assign({}, values['extra_data'], {'all_regions': currentRegions, type: currentCategory})
    setSaving(true);
    try {
      let reportChart = await reportServices.createReportChart(currentReportId, newValues);

      dispatch(actions.selectReportChart(-1));
      dispatch(reportActions.addReportChart(currentReportId, reportChart));
      setSaving(false);
      setDirty(false);
      setTimeout(()=>{
        formRef.current.restart();
      }, 300)
      return null;
    } catch(err){
      consoleLog("reportchartform catching some error, ")
      setSaving(false);
      setDirty(false);
      return {
        ...err,
        [FORM_ERROR]: upperFirst(intlMessages['form.error.general.error'])
      }
    }
  }

  return (
      <Form
        onSubmit={createReportChart}
        initialValues={{grade: ''}}
        render = {({
          handleSubmit,
          form,
          submitting,
        }) => {
        formRef.current = form;
        return(
          <StyledPatientRecordReportChartForm
            isSelected={currentReportChart==null}
            id={'newReportChart'}
            onSubmit={handleSubmit}
            >
              <StyledSelectTeethLabel>
                <div>{startCase(intlMessages['requestDetailPage.reportChart.form.regions.title'])}</div>
                <div className={'selectMoreButton'} onClick={viewChartClick}>
                  <span>{startCase(intlMessages['requestDetailPage.reportChart.form.regions.title.selectMore'])}</span>
                  <FaChevronDown />
                </div>
              </StyledSelectTeethLabel>
              <StyledSelectedFields>
                {currentRegions.length > 0
                  ? currentRegions.map((tooth, index) => <span key={index}>{tooth.replace("t", "")}</span>)
                  : upperFirst(intlMessages['requestDetailPage.reportChart.createForm.regions.noRegionSelected'])
                }
              </StyledSelectedFields>

              <Field
                name="grade"
                items={GRADES}
                validate={required}
                label={upperFirst(intlMessages['requestDetailPage.report.form.grade'])}
                component={SelectAdapterGrade}
                placeholder={upperFirst(intlMessages['requestDetailPage.report.form.grade'])}
              />

              <Field name="comment" parse={v => v} validate={required}>
                {({ input, meta}) => (
                  <StyledInputLayout>
                    <StyledLabel className="Input__Label">{upperFirst(intlMessages['requestDetailPage.reportChart.form.comment'])}</StyledLabel>
                    <StyledTextArea
                      {...input}
                      columns={10}
                      disabled={isRequestDone || submitting}
                      rows={5}
                      type="text"
                      addCSS={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)) && errorCss}
                      placeholder={upperFirst(intlMessages['requestDetailPage.reportChart.form.comment'])} />
                    <ErrorLabel
                      addCSS={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)) && errorCss}
                      >
                        {((meta.error && meta.touched)  || (meta.submitError && !meta.dirtySinceLastSubmit)) && intlMessages[`form.error.${(meta.error)}`] || meta.submitError}
                    </ErrorLabel>
                  </StyledInputLayout>
                )}
              </Field>
              {/*<StyledButton disabled={sent_at || submitting} type="submit">{toUpper(intl.messages['requestDetailPage.report.form.button.submit'])}</StyledButton>*/}
              <StyledFormActions>
                <StyledButton 
                  marginTop={"5px"} disabled={isRequestDone || submitting} 
                  size={'small'}
                  type="submit">
                  {toUpper(intlMessages['requestDetailPage.timeline.createreport.addNew.label'])}
                </StyledButton>
                <StyledButton 
                  className={'cancel outlined'} 
                  size={'small'}
                  type="button" 
                  onClick={() => clearChart(form)}
                >
                  {toUpper(intlMessages['requestDetailPage.timeline.editreport.cancel.label'])}
                </StyledButton>
              </StyledFormActions>
          </StyledPatientRecordReportChartForm>
          )}}
        />
  )
}

const NewReportChartItem = () => {
  //const handleClick = () => {
  //  dispatch(actions.selectReportChart(null))
  //}
  return (
    <FlexColumn>
      <NewNoteForm />
    </FlexColumn>
  )
}

const ReportChartItem = ({ chart, handleClick}) => {
  consoleLog("REPORTCHARTITEM: ", chart)

  const onClick = () => {
    if (handleClick && chart) {
      handleClick(chart.getId())
    }
  }

  return (
    <AnimatePresence>
      <FlexColumn
        initial={{ opacity: 0}}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        onClick={onClick}>
        <EditNoteForm reportChart={chart} />
      </FlexColumn>
    </AnimatePresence>
  )
}

const ReportCharts = () => {
  const dispatch = useDispatch();
  const regionCharts = useSelector(getReportCharts);
  const currentChartId = useSelector(getCurrentChartId);

  const handleClick = (chart) => {
    // TODO: update currentReportChart to edit?
    if (currentChartId == chart.getId()){
      dispatch(actions.selectReportChart(null));
    } else {
      dispatch(actions.selectReportChart(chart.getId()));
    }
  }

  return (
    <StyledReportChart>
        {regionCharts &&
          regionCharts.filter(item => item.getId() == currentChartId).map((chart) => (
              <ReportChartItem
                chart={chart}
                key={chart.getId()}
                onClick={handleClick}
              />
          )
        )}
      <NewReportChartItem
        selected={currentChartId == null}
        />

    </StyledReportChart>
  )
}

const ReportChartHeader = ({onClick}) => {
  return (
    <StyledReportChartHeader onClick={onClick}>
      <ReportCategoryRegions />
      <BlankReportCategoryRegion />
    </StyledReportChartHeader>
  )
}

const noteSectionVariants = {
  open: { opacity: 1, top: 0 },
  closed: { opacity: 1, top: 494 },
}

const ReportChartsSection = () => {
  const dispatch = useDispatch();
  const intlMessages = useSelector(getIntlMessages);
  const isOpen = useSelector(getCommentFormIsOpen);

  const handleClick = (e) => {
    if (!isOpen || !e.target.getAttribute('class') == "reportCategoryRegion"){
      dispatch({type: constants.UPDATE, data:{commentFormIsOpen: !isOpen}})
    }
  }

  return (
      <StyledNoteSection
        animate={isOpen ? "open" : "closed"}
        transition={{ type: 'tween', ease: "linear"}}
        variants={noteSectionVariants}
      >
        <motion.div
          className={'openFormButton'}
          animate={isOpen ? {opacity: 0, height: 10} :  {opacity: 1, height: 16} }
          >
            <div onClick={handleClick}>
              <span>{upperFirst(intlMessages['requestDetailPage.reportChart.form.regions.title.openForm'])}</span>
              {/*<FaChevronUp />*/}
            </div>
        </motion.div>
        <ReportChartHeader onClick={handleClick} />
        <ReportCharts />
      </StyledNoteSection>
  )
}

const ReportForm = () => {
  return (
    <StyledReportForm >
      <ChartSection />
      <ReportChartsSection />
    </StyledReportForm>
  )
}

const ReportCategory = ({currentCat, category, onClick}) => {
  const intlMessages = useSelector(getIntlMessages)

  return(
    <StyledReportCategory selected={currentCat == category.value} onClick={() => onClick(category.value)}>
      <motion.div
          className={cn('reportLabel', category.value)}
          animate={(currentCat == category.value) ? {width: "fit-content"} : {width: 20}}
          transition={{duration: .2}}
        ><span>{intlMessages[`requestDetailPage.reportChart.category.${category.value}.label`]}</span></motion.div>
    </StyledReportCategory>
  )
}

/**
 * Categories shown (Color, gums, etc.)
 */
const ReportCategories = ({report_type}) => {
  const { data } = useReportCategories({report_type});
  const dispatch = useDispatch();
  const currentCategory = useSelector(getCurrentCategory);
  const currentChartId = useSelector(getCurrentChartId);

  const reportCategories = data?.categories || [];

  const handleClick = (cat) => {
    currentChartId && dispatch(actions.selectReportChart(null));
    dispatch({type: constants.UPDATE, data: {currentCategory: cat}})
  }

  const gotoPreviousCategory = () => {
    let currIndex = findIndex(reportCategories, {value: currentCategory});
    currentChartId && dispatch(actions.selectReportChart(null));
    (currIndex > 1) && dispatch({type: constants.UPDATE, data: {currentCategory: REPORT_TYPES[currIndex - 1].value}})
  }

  const gotoNextCategory = () => {
    let currIndex = findIndex(reportCategories, {value: currentCategory});
    consoleLog("gotonext", currIndex, reportCategories.length);
    currentChartId && dispatch(actions.selectReportChart(null));
    (reportCategories.length - 1 > currIndex) && dispatch({type: constants.UPDATE, data: {currentCategory: REPORT_TYPES[currIndex + 1].value}});
  }


  return(
    <StyledReportCategories>
      <div className={'reportCategoriesContainer'}>
        <div className={cn('navArrow', {'disabled': findIndex(REPORT_TYPES, {value: currentCategory}) == 0})}
            onClick={gotoPreviousCategory}
          >
          <FaChevronLeft />
        </div>
        {reportCategories.map((elem) =>
          <ReportCategory
            key={elem.value}
            currentCat={currentCategory}
            category={elem}
            onClick={handleClick}
            />
        )}
        <div className={cn('navArrow', {'disabled': findIndex(REPORT_TYPES, {value: currentCategory}) >= reportCategories.length - 1})}
          onClick={gotoNextCategory}
          >
          <FaChevronRight />
        </div>
      </div>
    </StyledReportCategories>
  )
}

const ReportCategoryRegion = ({chart}) => {
  const dispatch = useDispatch();
  const isCommentOpen = useSelector(getCommentFormIsOpen);
  const currentChartId = useSelector(getCurrentChartId);
  const intlMessages = useSelector(getIntlMessages);

  useEffect(()=>{
    if (!isCommentOpen && checkChartId(currentChartId)){
      dispatch({type:constants.UPDATE, data: {commentFormIsOpen: true}})
    }
  }, [currentChartId])

  const handleClick = () => {
    // TODO: update currentReportChart to edit?
    if (currentChartId == chart.getId()){
      dispatch(actions.selectReportChart(null));
    } else {
      dispatch(actions.selectReportChart(chart.getId()));
    }
  }

  return(
    <StyledReportCategoryRegion
      animate={(currentChartId == chart.getId()) ? {opacity: 1} : {opacity: 0.7}}
      transition={{duration: .3}}
      className={cn(
        {'selected': currentChartId == chart.getId()},
        {'negative': chart && chart.getGrade() === 1},
        {'neutral': chart && chart.getGrade() === 2},
        {'positive': chart && chart.getGrade() === 3}
      )}
      onClick={handleClick}>
      { chart.getAllRegions().length > 0
        ? <span className={'reportCategoryRegion'}>{chart.getAllRegions()[0].replace("t", "")}</span>
        : <span>{toUpper(intlMessages['requestDetailPage.reportChart.categoryRegion.noTeethSelected'])}</span>
      }
    </StyledReportCategoryRegion>
  )
}

const BlankReportCategoryRegion = () => {
  const dispatch = useDispatch();
  const currentRegions = useSelector(getCurrentRegions)
  const currentChartId = useSelector(getCurrentChartId)
  const currentReportChart = useSelector(getCurrentReportChart);
  const isOpen = useSelector(getCommentFormIsOpen);
  const handleClick = () => {
    (currentRegions.length == 0 || checkChartId(currentChartId)) && dispatch(actions.selectReportChart(null));
  }

  let isActive = currentRegions.length > 0 && (currentChartId < 1) || currentChartId == null;
  let isAnimating = currentRegions.length > 0 && !isOpen && isActive

  return (
    <StyledBlankReportCategoryRegion onClick={handleClick}>
      <motion.div
        className={cn('icon', 
            {'noRegions': checkChartId(currentChartId) || currentRegions.length == 0}, 
            {'selected': isActive},
            {'negative': currentReportChart && currentReportChart.getGrade() === 1},
            {'neutral': currentReportChart && currentReportChart.getGrade() === 2},
            {'positive': currentReportChart && currentReportChart.getGrade() === 3}
        )}
      >
        <FaPlus />
      </motion.div>

      { isActive && currentRegions.length > 0 &&
        <motion.div className={'label'}
          animate={!isAnimating ? {opacity: 1} : {opacity: .4}}
          transition={!isAnimating ? {} : {repeatType: 'reverse', duration: 0.9, repeat: Infinity}}
          >
          <div>
            {currentRegions.map((elem) => elem.replace("t", "")).join(", ")}
          </div>
        </motion.div >
      }
    </StyledBlankReportCategoryRegion>
  )
}

/**
 * Regions shown after a Category (Color, Gums, etc) is Selected
 */
const ReportCategoryRegions = () => {
  const currentCategory = useSelector(getCurrentCategory);
  const regionCharts = useSelector(getReportCharts);
  const intlMessages = useSelector(getIntlMessages);
  const currentRequestReportId = useSelector(getDetailsReportId);
  consoleLog("qwer report id", currentRequestReportId)
  consoleLog("qwer regionCharts", regionCharts)
  return(
    <StyledReportCategoryRegions>
      {
        regionCharts
          ? regionCharts.filter(item => item.getReportType() == currentCategory)
              .map((item) => <ReportCategoryRegion key={item.getId()} chart={item} />)
          : intlMessages['requestDetailPage.report.form.category.nocomment']
      }
    </StyledReportCategoryRegions>
  )
}

const ContentSection = () => {
  const params = useParams();
  const intlMessages = useSelector(getIntlMessages);
  const statusMessage = useSelector(getReportPartialScanStatus);

  return (
    <div className={'contentSection'}>
      <div className={'createReportSection'}>
        <ReportCategories />
        <ReportForm />
        <div className={'createReportStatusMessage'}>
          {statusMessage == 'WAITING' && <span>{intlMessages[`requestDetailsPage.timeline.partialscans.statusMessage.${statusMessage}`]}</span>}
        </div>
      </div>
      <MediaSection key={`mediasection.${params.request_id}`} />
    </div>
  )
}

const TitleSection = () => {
  const intlMessages = useSelector(getIntlMessages);
  return (
    <div className={'titleSection'}>
      <div><span>{intlMessages['requestDetailPage.timeline.createreport.description']}</span></div>
      <div className={'statusMessage'}><span>{}</span></div>
    </div>
  )
}

const ActionsSection = () => {
  const intlMessages = useSelector(getIntlMessages);
  const dispatch = useDispatch();
  return(
    <div className={'actionsSection'}>
      <StyledButton onClick={()=>dispatch(createReportActions.nextPage())}>
        {intlMessages['requestDetailPage.report.form.status.review']}
      </StyledButton>
    </div>
  )
}

export const ReportChartForm = () => {
  const dispatch = useDispatch();
  const currentCategory = useSelector(getCurrentCategory);
  useEffect(()=>{
    return () => {};
  }, [])

  useEffect(()=>{
    if (!currentCategory){
      dispatch({type:constants.UPDATE, data: {currentCategory: REPORT_TYPES[0].value}})
    }
    return () => {};
  }, [currentCategory])

  return (
    <StyledCreateReportSection>
      <TitleSection />
      <ContentSection />
      <ActionsSection />
    </StyledCreateReportSection>
  )
}
