import { constants } from './constants';
import { services } from './services';

export const actions = {
  fetchRequest,
  updateRequestsMany,
  updateRequestStatus,
  updateRequest,
  updateRequestExtraData,
};

function fetchRequest(id) {
  return (dispatch) => {
    dispatch(request(id));
    services.fetchRequest(id).then(
      (data) => dispatch(success(data)),
      (error) => dispatch(failure(id, error))
    );
  };
  function request(id) {
    return { type: constants.GETREQUEST_REQUEST, data: { id } };
  }
  function success(data) {
    // breakdown reports...
    const { request } = data;
    // TODO mkae this a model? move to utils to normalize json data
    data = { id: request.unique_id, request };
    return { type: constants.GETREQUEST_SUCCESS, data };
  }
  function failure(id, error) {
    return { type: constants.GETREQUEST_FAILURE, data: { id, error } };
  }
}

function updateRequestsMany(data) {
  return (dispatch) => {
    dispatch(update(data));
  };
  function update(data) {
    return { type: constants.UPDATE_REQUESTS_MANY, data };
  }
}

function updateRequest(id, data) {
  return (dispatch) => {
    dispatch(update({ id, ...data }));
  };
  function update(data) {
    return { type: constants.UPDATE_REQUEST, data };
  }
}

function updateRequestStatus(id, status) {
  return (dispatch) => {
    dispatch(update(id, status));
  };
  function update(id, status) {
    return { type: constants.UPDATE_REQUESTSTATUS, data: { id, status } };
  }
}

function updateRequestExtraData(id, data) {
  return (dispatch) => {
    dispatch(update(id, data));
  };
  function update(id, data) {
    return { type: constants.UPDATE_REQUEST_EXTRADATA, data: { id, extraData: data } };
  }
}
