import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import cn from 'classnames';
import { startCase } from 'lodash';
// components
import { RecordUploadMedia } from '../_components/RecordUploadMedia';
import { LoadingEllipsis } from '../_components/Loaders';

// compontnt redux
import { actions } from './_redux/actions';

// actions
import { actions as patientRecordActions } from '../PatientRecords/_redux/actions';

// selectors
import { getIntlMessages } from '../App/_redux/selectors';
import {
  getPatientRecordsById,
  getPatientUploadsById,
  getPatientRecordsError,
  getPatientRecordsLoading
} from '../PatientRecords/_redux/selectors';

// styles
import { StyledPatientRecords } from '../RequestDetailPage/_common/styles';


const Styles = styled.div`
  display:flex;
  margin-right: 10px;
  align-items:center;
`;

const PatientUploadRecord = ({recordId, record, loading, expanded=false}) => {
  const dispatch = useDispatch();
  const [ init, setInit ] = useState(null);

  const patientUploadsById = useSelector(getPatientUploadsById);

  useEffect(()=>{
    if ((init == null) && loading){
      // first load
      setInit(true)
    } else if (init && !loading){
      // first loading finished
      setInit(false)
    }
  }, [loading, init])

  useEffect(()=>{
    if (recordId && !record) {
      dispatch(patientRecordActions.fetchRecordbyUid(recordId))
    }
  }, [recordId])


  const handleClick = () => {
    dispatch(actions.openRecordViewerModal());
  }

  if (!init && loading ){
    return(
      <Styles>
        <LoadingEllipsis />
      </Styles>
    )
  } else if (init){
    return (
      <Styles>
        <LoadingEllipsis />
      </Styles>
    )
  } else if (record){
    return (
      <Styles expanded={expanded}>
        {record.patientrecord_uploads.map((elem) => (
          <RecordUploadMedia
            zoom={false}
            key={elem.unique_id}
            upload={patientUploadsById[elem.unique_id]}
            imgWidth={180}
            imgHeight={110}
            imgBorderRadius={0}
            onClick={() => handleClick()}
            alt={"recordupload"+elem.id}
          />
        ))}
      </Styles>
    )
  } else {
    return(
      <Styles>
        <RecordUploadMedia
          imgWidth={180}
          imgHeight={110}
          imgBorderRadius={0}
          alt={"blank"}
        />
      </Styles>
    )
  }
}

export const PatientRecordsList = ({records, loading}) => {
  const intlMessages = useSelector(getIntlMessages)
  const [ init, setInit ] = useState(null);

  const patientRecordsById = useSelector(getPatientRecordsById);
  const patientRecordsLoading = useSelector(getPatientRecordsLoading);
  const patientRecordsError = useSelector(getPatientRecordsError);

  useEffect(()=>{
    if ((init == null) && loading){
      // first load
      setInit(true)
    } else if (init && !loading){
      // first loading finished
      setInit(false)
    }
  }, [loading, init])

  useEffect(()=>{
    return () => {}
  }, [])

  return(
    <StyledPatientRecords style={{display: 'none'}}>
      <div className={cn('sectionTitle', 'recordTitle')}>
        {startCase(intlMessages['requestDetailPage.details.uploads.title'])}
      </div>
      {
        init
          ? <div className={'loadingBoxes'}><LoadingEllipsis /></div>
          : <div className={'uploadBoxes'}>
            {(!init && loading) &&
              <div className={'loadingBoxes'}><LoadingEllipsis /></div>
            }
            {(records?.length > 0) ?
              records.map((elem) =>
                <PatientUploadRecord
                  key={elem.unique_id}
                  recordId={elem.unique_id}
                  record={patientRecordsById[elem.unique_id]}
                  error={patientRecordsError[elem.unique_id]}
                  loading={patientRecordsLoading[elem.unique_id]}
                />
              )
              :
                <RecordUploadMedia
                  imgWidth={180}
                  imgHeight={110}
                  imgBorderRadius={0}
                  alt={"blank"}
                />
            }
        </div>
      }

    </StyledPatientRecords>
  )
}
