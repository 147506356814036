import React from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';
import lodashValues from 'lodash/values';

import './Alignment.css';

const baseCssClassName = 'alignment';
const contentCssClassName = `${baseCssClassName}__content`;

const HORIZONTAL_ALIGNMENT = {
  LEFT: 'left',
  CENTER: 'center',
  RIGHT: 'right',
};

const VERTICAL_ALIGNMENT = {
  TOP: 'top',
  CENTER: 'center',
  BOTTOM: 'bottom',
};

/**
 * Creates base css class name.
 *
 * @param {Object} [props]
 * @param {string} [props.horizontal="LEFT"]
 *
 * @return {string}
 */
function buildBaseClassName(props = {}) {
  const horizontal = props.horizontal || HORIZONTAL_ALIGNMENT.LEFT;

  return classnames([
    baseCssClassName,
    horizontal && `${baseCssClassName}__m-horizontal-${horizontal}`,
  ]);
}

/**
 * Creates base css class name.
 *
 * @param {Object} [props]
 * @param {string} [props.vertical="TOP"]
 *
 * @return {string}
 */
function buildContentClassName(props = {}) {
  const vertical = props.vertical || VERTICAL_ALIGNMENT.TOP;

  return classnames([
    contentCssClassName,
    vertical && `${contentCssClassName}__m-vertical-${vertical}`,
  ]);
}

/**
 * @typedef {Object} AlignmentProps
 *
 * @property {ReactElement} children
 * @property {string} [horizontal=left]
 * @property {string} [vertical=top]
 * @property {string|number} [width]
 * @property {string|number} [height]
 */

const propTypes = {
  children: PropTypes.node.isRequired,

  horizontal: PropTypes.oneOf(lodashValues(HORIZONTAL_ALIGNMENT)),
  vertical: PropTypes.oneOf(lodashValues(VERTICAL_ALIGNMENT)),

  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const defaultProps = {
  horizontal: HORIZONTAL_ALIGNMENT.LEFT,
  vertical: VERTICAL_ALIGNMENT.TOP,
};

/**
 * Component aligns the children elements in the horizontal and vertical plane.
 *
 * @param {AlignmentProps} props
 * @returns {ReactElement}
 */
function Alignment(props) {
  const { children, width, height } = props;

  return (
    <div className={buildBaseClassName(props)}>
      <div
        className={buildContentClassName(props)}
        style={{
          width,
          height,
        }}
      >
        {children}
      </div>
    </div>
  );
}

Alignment.horizontal = HORIZONTAL_ALIGNMENT;
Alignment.vertical = VERTICAL_ALIGNMENT;

Alignment.propTypes = propTypes;
Alignment.defaultProps = defaultProps;

export default Alignment;
