import { authHeader, handleResponse } from 'src/_helpers';
import { API_URLS } from 'src/_config';
import { axios } from 'src/_libs/axios';
/**
 * Fetch report details
 * @param {string} reportId: uid of report
 * @returns
 */
export function fetchReport(reportId) {
  const requestUrl = API_URLS.getDentistRequestReportDetail;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };
  let url = requestUrl.url.replace('{reportId}', reportId);
  return fetch(url, requestOptions).then(handleResponse);
}

/**
 * Fetch request detail
 * @param {string} requestId: uid of request
 */
export function fetchRequest(requestId) {
  const requestUrl = API_URLS.getDentistRequestDetail;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };
  let url = requestUrl.url.replace('{idType}', 'uid').replace('{requestId}', requestId);

  return fetch(url, requestOptions).then(handleResponse);
}

/**
 * Create report
 * @param {string} requestId: uid of request
 */
export function createReportForRequest(requestUid, data) {
  const requestUrl = API_URLS.createRequestReport;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  };
  let url = requestUrl.url.replace('{requestId}', requestUid);
  return fetch(url, requestOptions).then(handleResponse);
}

/**
 * Update report
 * @param {string} report: uid of request
 */
export function updateReport(reportUid, data) {
  const requestUrl = API_URLS.updateRequestReport;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  };
  let url = requestUrl.url.replace('{reportId}', reportUid);
  return fetch(url, requestOptions).then(handleResponse);
}

/**
 * Send report
 * @param {string} report: uid of request
 */
export function sendReport(reportUid, data) {
  //const requestUrl = API_URLS.sendReport;
  //const requestOptions = {
  //  method: requestUrl.method,
  //  headers: { ...authHeader(), 'Content-Type': 'application/json' },
  //  body: JSON.stringify(data),
  //};
  let url = '/d/dentist/report/{reportId}/send'.replace('{reportId}', reportUid);
  return axios.post(url, data)
  //return fetch(url, requestOptions).then(handleResponse);
}

/**
 * Report Recommendations
 * @param {string} report_type (optional) - type of report DMFT or DEFAULT
 */
export function fetchReportRecommendations(reportUid, locale=null) {
  const requestUrl = API_URLS.getReportRecommendations;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };
  let url = requestUrl.url.replace('{reportId}', reportUid);
  if (locale){
    url = url + `&locale=${locale}`;
  }
  return fetch(url, requestOptions).then(handleResponse);
}

/**
 * Report Categories
 * @param {string} report_type (optional) - type of report DMFT or DEFAULT
 */
export function fetchReportCategories(reportType=null, locale=null) {
  const requestUrl = API_URLS.getDentistReportCategories;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };
  let url = requestUrl.url + `?report_type=${reportType}`;
  if (locale){
    url = url + `&locale=${locale}`;
  }
  return fetch(url, requestOptions).then(handleResponse);
}

/**
 * Category templates
 * @param {string} category
 */
export function fetchCategoryTemplates(category, reportType, locale=null) {
  const requestUrl = API_URLS.getDentistReportTemplates;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };
  let url = requestUrl.url + `?category=${category}`;
  if (reportType){
    url = url + `&report_type=${reportType}`;
  }
  if (locale){
    url = url + `&locale=${locale}`;
  }
  return fetch(url, requestOptions).then(handleResponse);
}

/**
 * Get record upload detections
 * @param {string} recordUploadUid
 * @returns
 */
export function fetchRecordUploadDetections(recordUploadUid) {
  var url, requestUrl;
  requestUrl = API_URLS.getDentistRequestRecordUploadDetections;
  url = requestUrl.url.replace('{recordUploadUid}', recordUploadUid);
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };
  return fetch(url, requestOptions).then(handleResponse);
}

/**
 * Get record detail
 * @param {string} recordId
 * @param {string} requestId
 * @returns
 */
export function fetchRecord(recordId, requestId) {
  var url, requestUrl;
  if (requestId) {
    requestUrl = API_URLS.getDentistRequestRecordDetailWithRequest;
    url = requestUrl.url.replace('{requestId}', requestId).replace('{recordId}', recordId);
  } else {
    requestUrl = API_URLS.getDentistRequestRecordDetail;
    url = requestUrl.url.replace('{recordId}', recordId);
  }
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };
  return fetch(url, requestOptions).then(handleResponse);
}
