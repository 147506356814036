import React from 'react';
import cn from 'classnames';

const ToothSet = ({className, width="240", height="210", charting, chartType, onClick}) => (
  <div className={cn('ToothSet',className)}>
    <svg xmlns="http://www.w3.org/2000/svg" id="toothUp" viewBox="0 0 240 210" width={width} height={height}>
      <defs>
        <style>
        </style>
      </defs>
      <line className="cls-1" x1="119.75" x2="119.75" y2="1"></line>
      <line className="cls-2" x1="119.75" y1="3" x2="119.75" y2="422.12"></line>
      <line className="cls-1" x1="119.75" y1="423.12" x2="119.75" y2="424.12"></line>
      <line className="cls-1" x1="239.49" y1="206.35" x2="238.49" y2="206.35"></line>
      <line className="cls-3" x1="236.5" y1="206.35" x2="2" y2="206.35"></line>
      <line className="cls-1" x1="1" y1="206.35" y2="206.35"></line>
      <g className={cn("fullTooth", "g18", charting["t18"])} data-for={`indTooth_${chartType}`} data-tip='t18' data-event='click' onClick={onClick && (() => onClick('t18'))}>
        <path className="ipr18 tooth ipr_t non_click" id="t18" transform="translate(-291.84 -167)" d="M 313 338.41 s -9.92 2.49 -8.24 8.35 s 1.89 5.66 2.11 8.47 s 0.31 10.07 8 10.83 s 19.46 -1.88 20.4 -12.12 S 329.28 338.3 313 338.41 Z"></path>
        <path className="tnum" transform="translate(-291.84 -167)" d="M 315.79 355.93 c -0.09 0 -0.14 0 -0.14 -0.13 v -5.9 l -0.74 0.64 a 0.25 0.25 0 0 1 -0.11 0 a 0.11 0.11 0 0 1 -0.1 0 l -0.45 -0.54 a 0.13 0.13 0 0 1 0 -0.09 a 0.17 0.17 0 0 1 0.05 -0.1 l 1.52 -1.4 l 0.07 0 l 0.08 0 h 0.53 a 0.35 0.35 0 0 1 0.2 0 a 0.43 0.43 0 0 1 0 0.23 v 7.17 a 0.11 0.11 0 0 1 -0.13 0.13 Z"></path>
        <path className="tnum" transform="translate(-291.84 -167)" d="M 321.28 356 a 3.14 3.14 0 0 1 -0.9 -0.13 a 2.59 2.59 0 0 1 -0.81 -0.4 a 2.12 2.12 0 0 1 -0.59 -0.67 a 1.88 1.88 0 0 1 -0.23 -0.94 a 1.6 1.6 0 0 1 0.12 -0.65 a 1.85 1.85 0 0 1 0.33 -0.51 a 2.47 2.47 0 0 1 0.48 -0.4 a 5.71 5.71 0 0 1 0.57 -0.33 a 3.09 3.09 0 0 1 -0.95 -0.79 a 1.63 1.63 0 0 1 -0.35 -1 a 1.61 1.61 0 0 1 0.18 -0.77 a 1.87 1.87 0 0 1 0.5 -0.6 a 2.37 2.37 0 0 1 0.74 -0.39 a 2.86 2.86 0 0 1 0.9 -0.14 a 3.15 3.15 0 0 1 0.92 0.13 a 2.22 2.22 0 0 1 0.73 0.37 a 1.72 1.72 0 0 1 0.48 0.58 a 1.7 1.7 0 0 1 0.17 0.78 a 1.59 1.59 0 0 1 -0.31 0.95 a 3.88 3.88 0 0 1 -1 0.89 l 0.63 0.4 a 2.73 2.73 0 0 1 0.48 0.42 a 1.66 1.66 0 0 1 0.3 0.48 a 1.58 1.58 0 0 1 0.1 0.6 a 1.93 1.93 0 0 1 -0.78 1.61 a 2.52 2.52 0 0 1 -0.81 0.4 A 3.33 3.33 0 0 1 321.28 356 Z m 0 -3.48 l -0.45 0.16 a 2.21 2.21 0 0 0 -0.46 0.26 a 1.51 1.51 0 0 0 -0.36 0.38 a 0.94 0.94 0 0 0 -0.15 0.53 a 0.87 0.87 0 0 0 0.13 0.47 a 1.25 1.25 0 0 0 0.33 0.36 a 1.55 1.55 0 0 0 0.46 0.23 a 1.65 1.65 0 0 0 0.49 0.08 a 1.81 1.81 0 0 0 0.51 -0.07 a 1.41 1.41 0 0 0 0.45 -0.22 a 1.24 1.24 0 0 0 0.32 -0.36 a 0.92 0.92 0 0 0 0.12 -0.48 a 0.75 0.75 0 0 0 -0.12 -0.39 a 1.69 1.69 0 0 0 -0.33 -0.38 a 3.16 3.16 0 0 0 -0.45 -0.33 A 2.82 2.82 0 0 0 321.29 352.52 Z m 0 -3.27 a 1.3 1.3 0 0 0 -0.42 0.07 a 1.37 1.37 0 0 0 -0.38 0.19 a 1 1 0 0 0 -0.28 0.31 a 0.81 0.81 0 0 0 -0.11 0.42 a 0.7 0.7 0 0 0 0.14 0.41 a 2 2 0 0 0 0.33 0.37 a 2.76 2.76 0 0 0 0.39 0.29 a 2.29 2.29 0 0 0 0.33 0.17 a 1.93 1.93 0 0 0 0.35 -0.19 A 2.87 2.87 0 0 0 322 351 a 2 2 0 0 0 0.31 -0.38 a 0.72 0.72 0 0 0 0.12 -0.38 a 0.77 0.77 0 0 0 -0.09 -0.37 a 1.08 1.08 0 0 0 -0.25 -0.31 a 1.17 1.17 0 0 0 -0.37 -0.21 A 1.36 1.36 0 0 0 321.28 349.25 Z"></path>
      </g>
      <g className={cn("fullTooth", "g17", charting["t17"])} data-for={`indTooth_${chartType}`} data-tip='t17' data-event='click' onClick={onClick && (() => onClick('t17'))}>
        <path className="ipr17 tooth ipr_t non_click" id="t17" transform="translate(-291.84 -167)" d="M 334.85 314.14 s -13.42 -8.64 -20.72 -7.68 s -9 5.77 -8.34 11.2 s 0.69 11.06 1.88 12.72 s 6 9.81 22.27 7.84 S 338.43 315.66 334.85 314.14 Z"></path>
        <path className="tnum" transform="translate(-291.84 -167)" d="M 318.92 326.3 c -0.09 0 -0.14 0 -0.14 -0.13 v -5.9 l -0.74 0.64 a 0.25 0.25 0 0 1 -0.11 0 a 0.11 0.11 0 0 1 -0.1 0 l -0.45 -0.54 a 0.13 0.13 0 0 1 0 -0.09 a 0.17 0.17 0 0 1 0.05 -0.1 l 1.52 -1.4 l 0.07 0 l 0.08 0 h 0.53 a 0.35 0.35 0 0 1 0.2 0 a 0.43 0.43 0 0 1 0 0.23 v 7.17 a 0.11 0.11 0 0 1 -0.13 0.13 Z"></path>
        <path className="tnum" transform="translate(-291.84 -167)" d="M 324 326.16 a 0.32 0.32 0 0 1 -0.08 0.11 a 0.24 0.24 0 0 1 -0.14 0 h -0.81 a 0.33 0.33 0 0 1 -0.19 0 s 0 -0.07 0 -0.14 l 2.6 -6.36 h -3.18 a 0.17 0.17 0 0 1 -0.12 0 a 0.16 0.16 0 0 1 0 -0.12 v -0.7 c 0 -0.11 0.06 -0.16 0.17 -0.16 h 4.24 a 0.35 0.35 0 0 1 0.2 0 a 0.21 0.21 0 0 1 0 0.14 v 0.61 a 0.29 0.29 0 0 1 0 0.16 Z"></path>
      </g>
      <g className={cn("fullTooth", "g16", charting["t16"])} data-for={`indTooth_${chartType}`} data-tip='t16' data-event='click' onClick={onClick && (() => onClick('t16'))}>
        <path className="ipr16 tooth ipr_t non_click" id="t16" transform="translate(-291.84 -167)" d="M 336.31 276.86 s -7 -3.33 -11.93 -3.38 s -10.91 4 -10.49 10.36 s -0.36 8.39 -0.38 10.57 s -0.34 10.78 10.73 12.37 s 15.19 2.66 17.47 -0.49 a 14.82 14.82 0 0 0 2.64 -8.22 C 344.53 296 351.84 284.4 336.31 276.86 Z"></path>
        <path className="tnum" transform="translate(-291.84 -167)" d="M 325.16 294.83 c -0.09 0 -0.14 0 -0.14 -0.13 v -5.9 l -0.74 0.64 a 0.25 0.25 0 0 1 -0.11 0 a 0.11 0.11 0 0 1 -0.1 0 l -0.45 -0.54 a 0.13 0.13 0 0 1 0 -0.09 a 0.17 0.17 0 0 1 0.05 -0.1 l 1.52 -1.4 l 0.07 0 l 0.08 0 h 0.53 a 0.35 0.35 0 0 1 0.2 0 a 0.43 0.43 0 0 1 0 0.23 v 7.17 a 0.11 0.11 0 0 1 -0.13 0.13 Z"></path>
        <path className="tnum" transform="translate(-291.84 -167)" d="M 330.66 294.91 a 2.09 2.09 0 0 1 -1.81 -0.88 a 4.75 4.75 0 0 1 -0.63 -2.74 a 7.21 7.21 0 0 1 0.17 -1.59 a 3.63 3.63 0 0 1 0.56 -1.31 a 2.82 2.82 0 0 1 1 -0.89 a 3.39 3.39 0 0 1 1.56 -0.33 a 4.64 4.64 0 0 1 0.64 0 a 3.19 3.19 0 0 1 0.58 0.13 a 0.11 0.11 0 0 1 0.08 0.13 l 0 0.8 c 0 0.09 -0.07 0.12 -0.16 0.08 a 2.16 2.16 0 0 0 -0.55 -0.13 q -0.29 0 -0.64 0 a 2 2 0 0 0 -0.88 0.17 a 1.74 1.74 0 0 0 -0.61 0.48 a 2.39 2.39 0 0 0 -0.39 0.73 a 4.93 4.93 0 0 0 -0.21 0.9 a 1.18 1.18 0 0 1 0.24 -0.23 a 1.92 1.92 0 0 1 0.34 -0.2 a 2.54 2.54 0 0 1 0.38 -0.14 a 1.44 1.44 0 0 1 0.39 -0.05 a 2.66 2.66 0 0 1 1 0.17 a 2.17 2.17 0 0 1 0.76 0.5 a 2.24 2.24 0 0 1 0.49 0.78 a 2.82 2.82 0 0 1 0.17 1 a 3 3 0 0 1 -0.17 1 a 2.36 2.36 0 0 1 -0.49 0.82 a 2.3 2.3 0 0 1 -0.78 0.55 A 2.54 2.54 0 0 1 330.66 294.91 Z m 0.1 -4 a 1.32 1.32 0 0 0 -1 0.39 a 1.41 1.41 0 0 0 -0.37 1 a 2 2 0 0 0 0.1 0.64 a 1.73 1.73 0 0 0 0.27 0.52 a 1.3 1.3 0 0 0 0.41 0.35 a 1.06 1.06 0 0 0 0.52 0.13 a 1.26 1.26 0 0 0 1 -0.42 a 1.91 1.91 0 0 0 0 -2.22 A 1.16 1.16 0 0 0 330.76 290.89 Z"></path>
      </g>
      <g className={cn("fullTooth", "g15", charting["t15"])} data-for={`indTooth_${chartType}`} data-tip='t15' data-event='click' onClick={onClick && (() => onClick('t15'))}>
        <path className="ipr15 tooth ipr_t" id="t15" transform="translate(-291.84 -167)" d="M 344.11 257.09 s -9.73 -6.11 -13.66 -5.72 s -9.11 3.45 -8.53 8.24 s 0.46 11.84 10.05 13.59 s 14.22 2.94 16.6 -3.32 S 348.92 259.82 344.11 257.09 Z"></path>
        <path className="tnum" transform="translate(-291.84 -167)" d="M 331.78 267 c -0.09 0 -0.14 0 -0.14 -0.13 V 261 l -0.74 0.64 a 0.25 0.25 0 0 1 -0.11 0 a 0.11 0.11 0 0 1 -0.1 0 l -0.45 -0.54 a 0.13 0.13 0 0 1 0 -0.09 a 0.17 0.17 0 0 1 0.05 -0.1 l 1.52 -1.4 l 0.07 0 l 0.08 0 h 0.53 a 0.35 0.35 0 0 1 0.2 0 a 0.43 0.43 0 0 1 0 0.23 v 7.17 a 0.11 0.11 0 0 1 -0.13 0.13 Z"></path>
        <path className="tnum" transform="translate(-291.84 -167)" d="M 338.82 266.43 a 2.26 2.26 0 0 1 -0.88 0.49 a 3.69 3.69 0 0 1 -1.08 0.16 h -0.3 l -0.44 0 l -0.47 -0.09 a 1.79 1.79 0 0 1 -0.39 -0.13 s -0.07 -0.05 -0.07 -0.07 a 0.8 0.8 0 0 1 0 -0.1 v -0.81 c 0 -0.12 0.07 -0.15 0.21 -0.08 a 1.64 1.64 0 0 0 0.35 0.13 l 0.41 0.09 l 0.41 0.06 l 0.38 0 a 1.61 1.61 0 0 0 1.1 -0.38 a 1.31 1.31 0 0 0 0.43 -1 a 1.46 1.46 0 0 0 -0.35 -1 a 1.53 1.53 0 0 0 -1.17 -0.38 a 3.35 3.35 0 0 0 -0.67 0.07 a 4.32 4.32 0 0 0 -0.66 0.19 q -0.39 0.16 -0.39 -0.18 l 0 -3.67 a 0.24 0.24 0 0 1 0 -0.14 s 0.08 0 0.18 0 h 3.64 a 0.12 0.12 0 0 1 0.09 0 a 0.13 0.13 0 0 1 0 0.09 v 0.77 a 0.15 0.15 0 0 1 0 0.09 a 0.11 0.11 0 0 1 -0.09 0 h -2.77 l 0 1.85 a 3.92 3.92 0 0 1 0.88 -0.09 a 2.78 2.78 0 0 1 0.94 0.15 a 2.21 2.21 0 0 1 0.76 0.45 a 2.09 2.09 0 0 1 0.51 0.71 a 2.3 2.3 0 0 1 0.18 0.94 A 2.48 2.48 0 0 1 338.82 266.43 Z"></path>
      </g>
      <g className={cn("fullTooth", "g14", charting["t14"])} data-for={`indTooth_${chartType}`} data-tip='t14' data-event='click' onClick={onClick && (() => onClick('t14'))}>
        <path className="ipr14 tooth ipr_t base" id="t14" transform="translate(-291.84 -167)" d="M 352.75 234.29 s -8.11 -6.17 -11.33 -5.85 s -8.37 2.33 -9.68 7.69 s -1.43 9.08 -0.36 11.4 s 3.3 4 8.88 4.63 s 10.76 1.06 12.75 0.4 a 8.11 8.11 0 0 0 5 -6 C 358.58 243.5 359.12 239.57 352.75 234.29 Z"></path>
        <path className="tnum" transform="translate(-291.84 -167)" d="M 339.37 244.52 c -0.09 0 -0.14 0 -0.14 -0.13 v -5.9 l -0.74 0.64 a 0.25 0.25 0 0 1 -0.11 0 a 0.11 0.11 0 0 1 -0.1 0 l -0.45 -0.54 a 0.13 0.13 0 0 1 0 -0.09 a 0.17 0.17 0 0 1 0.05 -0.1 l 1.52 -1.4 l 0.07 0 l 0.08 0 h 0.53 a 0.35 0.35 0 0 1 0.2 0 a 0.43 0.43 0 0 1 0 0.23 v 7.17 a 0.11 0.11 0 0 1 -0.13 0.13 Z"></path>
        <path className="tnum" transform="translate(-291.84 -167)" d="M 346.49 242.83 v 1.51 a 0.24 0.24 0 0 1 0 0.14 s -0.07 0 -0.14 0 h -0.72 a 0.19 0.19 0 0 1 -0.14 0 a 0.21 0.21 0 0 1 0 -0.14 v -1.51 h -3.19 a 0.26 0.26 0 0 1 -0.13 0 s 0 0 0 -0.1 V 242 a 0.28 0.28 0 0 1 0 -0.14 l 0.08 -0.12 l 3.08 -4.63 l 0.08 -0.1 a 0.2 0.2 0 0 1 0.12 0 h 0.91 c 0.05 0 0.08 0 0.09 0 a 0.47 0.47 0 0 1 0 0.12 v 4.7 h 0.93 a 0.2 0.2 0 0 1 0.12 0 s 0 0.06 0 0.13 v 0.73 c 0 0.07 0 0.11 0 0.12 a 0.2 0.2 0 0 1 -0.12 0 Z m -3.21 -1 h 2.14 v -3.25 Z"></path>
      </g>
      <g className={cn("fullTooth", "g13", charting["t13"])} data-for={`indTooth_${chartType}`} data-tip='t13' data-event='click' onClick={onClick && (() => onClick('t13'))}>
        <path className="ipr13 tooth ipr_t base on" id="t13" transform="translate(-291.84 -167)" d="M 364.76 215.06 s 0.68 -8.22 -5.8 -8.94 s -16.34 0.77 -17.73 11 s 4.77 11.29 6.89 11.46 s 10.1 0.94 12.13 1 s 4.81 -0.37 5 -4.85 A 80.79 80.79 0 0 0 364.76 215.06 Z"></path>
        <path className="tnum on" transform="translate(-291.84 -167)" d="M 351.22 221.69 c -0.09 0 -0.14 0 -0.14 -0.13 v -5.9 l -0.74 0.64 a 0.25 0.25 0 0 1 -0.11 0 a 0.11 0.11 0 0 1 -0.1 0 l -0.45 -0.54 a 0.13 0.13 0 0 1 0 -0.09 a 0.17 0.17 0 0 1 0.05 -0.1 l 1.52 -1.4 l 0.07 0 l 0.08 0 h 0.53 a 0.35 0.35 0 0 1 0.2 0 a 0.43 0.43 0 0 1 0 0.23 v 7.17 a 0.11 0.11 0 0 1 -0.13 0.13 Z"></path>
        <path className="tnum on" transform="translate(-291.84 -167)" d="M 358.1 221.17 a 2.41 2.41 0 0 1 -0.91 0.45 a 4.21 4.21 0 0 1 -1.11 0.14 a 5.05 5.05 0 0 1 -0.86 -0.07 a 5 5 0 0 1 -0.8 -0.2 a 0.3 0.3 0 0 1 -0.14 -0.08 a 0.17 0.17 0 0 1 0 -0.13 l 0.09 -0.75 a 0.15 0.15 0 0 1 0.06 -0.12 s 0.1 0 0.19 0 l 0.52 0.15 a 2.61 2.61 0 0 0 0.53 0.09 l 0.43 0 a 2.66 2.66 0 0 0 0.43 0 a 2 2 0 0 0 0.41 -0.09 a 1.4 1.4 0 0 0 0.36 -0.18 a 1.13 1.13 0 0 0 0.48 -1 a 1 1 0 0 0 -0.1 -0.44 a 1.05 1.05 0 0 0 -0.32 -0.38 a 1.64 1.64 0 0 0 -0.56 -0.26 a 3.1 3.1 0 0 0 -0.82 -0.09 h -0.5 a 0.1 0.1 0 0 1 -0.13 -0.13 v -0.74 c 0 -0.06 0 -0.1 0 -0.11 a 0.18 0.18 0 0 1 0.1 0 h 0.48 a 2.65 2.65 0 0 0 0.53 -0.06 a 2.33 2.33 0 0 0 0.6 -0.21 a 1.7 1.7 0 0 0 0.49 -0.38 a 0.81 0.81 0 0 0 0.2 -0.56 a 0.77 0.77 0 0 0 -0.13 -0.45 a 1 1 0 0 0 -0.33 -0.31 a 1.53 1.53 0 0 0 -0.44 -0.17 a 2 2 0 0 0 -0.45 -0.05 l -0.32 0 l -0.38 0.08 l -0.39 0.1 l -0.36 0.12 a 0.22 0.22 0 0 1 -0.17 0 a 0.15 0.15 0 0 1 -0.07 -0.11 l -0.09 -0.73 a 0.13 0.13 0 0 1 0 -0.11 a 0.26 0.26 0 0 1 0.14 -0.09 a 6.74 6.74 0 0 1 0.86 -0.25 a 4 4 0 0 1 0.84 -0.09 a 3.57 3.57 0 0 1 0.88 0.11 a 2.38 2.38 0 0 1 0.77 0.34 a 1.79 1.79 0 0 1 0.54 0.6 a 1.74 1.74 0 0 1 0.21 0.88 a 1.61 1.61 0 0 1 -0.09 0.54 a 2.08 2.08 0 0 1 -0.67 0.94 a 2.29 2.29 0 0 1 -0.52 0.31 a 2.47 2.47 0 0 1 1 0.73 a 1.59 1.59 0 0 1 0.34 1 a 2.26 2.26 0 0 1 -0.2 1 A 2.08 2.08 0 0 1 358.1 221.17 Z"></path>
      </g>
      <g className={cn("fullTooth", "g12", charting["t12"])} data-for={`indTooth_${chartType}`} data-tip='t12' data-event='click' onClick={onClick && (() => onClick('t12'))}>
        <path className="ipr12 tooth ipr_t base on" id="t12" transform="translate(-291.84 -167)" d="M 379.25 190.85 a 6.59 6.59 0 0 0 -6.76 -0.21 c -3.58 2 -12.6 7.76 -12.55 10.52 s -0.21 2.63 4 6.15 s 10.94 11.89 15.5 7.39 c 1.89 -2.2 3 -13.29 2.38 -17.82 S 380 191.24 379.25 190.85 Z"></path>
        <path className="tnum on" transform="translate(-291.84 -167)" d="M 368.85 206 c -0.09 0 -0.14 0 -0.14 -0.13 V 200 l -0.74 0.64 a 0.25 0.25 0 0 1 -0.11 0 a 0.11 0.11 0 0 1 -0.1 0 l -0.45 -0.54 a 0.13 0.13 0 0 1 0 -0.09 a 0.17 0.17 0 0 1 0.05 -0.1 l 1.52 -1.4 l 0.07 0 l 0.08 0 h 0.53 a 0.35 0.35 0 0 1 0.2 0 a 0.43 0.43 0 0 1 0 0.23 v 7.17 a 0.11 0.11 0 0 1 -0.13 0.13 Z"></path>
        <path className="tnum on" transform="translate(-291.84 -167)" d="M 372 206 q -0.19 0 -0.19 -0.13 v -0.56 a 0.32 0.32 0 0 1 0 -0.16 l 0.1 -0.16 l 1.51 -1.79 a 14.09 14.09 0 0 0 1.22 -1.64 a 2.24 2.24 0 0 0 0.4 -1.08 a 1.1 1.1 0 0 0 -0.29 -0.79 a 1 1 0 0 0 -0.72 -0.31 a 2.75 2.75 0 0 0 -0.79 0.11 a 4 4 0 0 0 -0.83 0.37 a 0.26 0.26 0 0 1 -0.16 0 a 0.1 0.1 0 0 1 -0.1 -0.09 l -0.14 -0.76 a 0.11 0.11 0 0 1 0 -0.1 a 0.37 0.37 0 0 1 0.13 -0.11 a 5.61 5.61 0 0 1 0.87 -0.36 a 3.06 3.06 0 0 1 1 -0.16 a 2.79 2.79 0 0 1 0.88 0.13 a 2 2 0 0 1 0.7 0.4 a 1.85 1.85 0 0 1 0.46 0.66 a 2.32 2.32 0 0 1 0.17 0.92 a 2.29 2.29 0 0 1 -0.2 0.91 a 5.52 5.52 0 0 1 -0.5 0.91 a 10.13 10.13 0 0 1 -0.64 0.85 l -0.62 0.73 l -0.46 0.56 l -0.49 0.57 h 2.92 c 0.09 0 0.14 0.05 0.14 0.16 v 0.7 c 0 0.11 0 0.17 -0.14 0.17 Z"></path>
      </g>
      <g className={cn("fullTooth", "g11", charting["t11"])} data-for={`indTooth_${chartType}`} data-tip='t11' data-event='click' onClick={onClick && (() => onClick('t11'))}>
        <path className="ipr11 tooth ipr_t base on" id="t11" transform="translate(-291.84 -167)" d="M 395.58 182.46 s -16.58 1.32 -14.12 8.75 s 6.69 21 13.19 21 s 11.41 -8.34 13.29 -13.43 s 7.57 -15 -4.64 -16.07 A 49 49 0 0 0 395.58 182.46 Z"></path>
        <path className="tnum on" transform="translate(-291.84 -167)" d="M 393 198.72 c -0.09 0 -0.14 0 -0.14 -0.13 v -5.9 l -0.74 0.64 a 0.25 0.25 0 0 1 -0.11 0 a 0.11 0.11 0 0 1 -0.1 0 l -0.45 -0.54 a 0.13 0.13 0 0 1 0 -0.09 a 0.17 0.17 0 0 1 0.05 -0.1 l 1.52 -1.4 l 0.07 0 l 0.08 0 h 0.53 a 0.35 0.35 0 0 1 0.2 0 a 0.43 0.43 0 0 1 0 0.23 v 7.17 a 0.11 0.11 0 0 1 -0.13 0.13 Z"></path>
        <path className="tnum on" transform="translate(-291.84 -167)" d="M 398.26 198.72 c -0.09 0 -0.14 0 -0.14 -0.13 v -5.9 l -0.74 0.64 a 0.25 0.25 0 0 1 -0.11 0 a 0.11 0.11 0 0 1 -0.1 0 l -0.45 -0.54 a 0.13 0.13 0 0 1 0 -0.09 a 0.17 0.17 0 0 1 0.05 -0.1 l 1.52 -1.4 l 0.07 0 l 0.08 0 h 0.53 a 0.35 0.35 0 0 1 0.2 0 a 0.43 0.43 0 0 1 0 0.23 v 7.17 a 0.11 0.11 0 0 1 -0.13 0.13 Z"></path>
      </g>
      <g className={cn("fullTooth", "g21", charting["t21"])} data-for={`indTooth_${chartType}`} data-tip='t21' data-event='click' onClick={onClick && (() => onClick('t21'))}>
        <path className="ipr21 tooth ipr_t base on" id="t21" transform="translate(-291.84 -167)" d="M 427.13 182.5 s 16.58 1.32 14.12 8.75 s -6.69 21 -13.19 21 s -11.41 -8.34 -13.29 -13.43 s -7.57 -15 4.64 -16.07 A 49 49 0 0 1 427.13 182.5 Z"></path>
        <path className="tnum on" transform="translate(-291.84 -167)" d="M 421.21 198.73 c -0.13 0 -0.19 0 -0.19 -0.13 V 198 a 0.32 0.32 0 0 1 0 -0.16 l 0.1 -0.16 l 1.51 -1.79 a 14.16 14.16 0 0 0 1.22 -1.64 a 2.24 2.24 0 0 0 0.4 -1.08 a 1.1 1.1 0 0 0 -0.29 -0.79 a 1 1 0 0 0 -0.72 -0.31 a 2.75 2.75 0 0 0 -0.79 0.11 a 4 4 0 0 0 -0.83 0.37 a 0.25 0.25 0 0 1 -0.16 0 a 0.1 0.1 0 0 1 -0.1 -0.09 l -0.14 -0.76 a 0.11 0.11 0 0 1 0 -0.1 a 0.38 0.38 0 0 1 0.13 -0.11 a 5.63 5.63 0 0 1 0.87 -0.36 a 3.06 3.06 0 0 1 1 -0.16 a 2.79 2.79 0 0 1 0.88 0.13 a 2 2 0 0 1 0.7 0.4 a 1.86 1.86 0 0 1 0.46 0.66 a 2.35 2.35 0 0 1 0 1.83 a 5.52 5.52 0 0 1 -0.5 0.91 a 10.34 10.34 0 0 1 -0.64 0.85 l -0.62 0.73 l -0.46 0.56 l -0.49 0.57 h 2.92 c 0.09 0 0.14 0.05 0.14 0.16 v 0.7 c 0 0.11 0 0.17 -0.14 0.17 Z"></path>
        <path className="tnum on" transform="translate(-291.84 -167)" d="M 428.58 198.72 c -0.09 0 -0.14 0 -0.14 -0.13 v -5.9 l -0.74 0.64 a 0.25 0.25 0 0 1 -0.12 0 a 0.11 0.11 0 0 1 -0.1 0 l -0.45 -0.54 a 0.14 0.14 0 0 1 0 -0.09 a 0.17 0.17 0 0 1 0.05 -0.1 l 1.52 -1.4 l 0.07 0 l 0.08 0 h 0.53 c 0.11 0 0.18 0 0.2 0 a 0.43 0.43 0 0 1 0 0.23 v 7.17 a 0.11 0.11 0 0 1 -0.13 0.13 Z"></path>
      </g>
      <g className={cn("fullTooth", "g22", charting["t22"])} data-for={`indTooth_${chartType}`} data-tip='t22' data-event='click' onClick={onClick && (() => onClick('t22'))}>
        <path className="ipr22 tooth ipr_t base on" id="t22" transform="translate(-291.84 -167)" d="M 443.45 190.89 a 6.59 6.59 0 0 1 6.76 -0.21 c 3.58 2 12.6 7.76 12.55 10.52 s 0.21 2.63 -4 6.15 s -10.94 11.89 -15.5 7.39 c -1.89 -2.2 -3 -13.29 -2.38 -17.82 S 442.75 191.28 443.45 190.89 Z"></path>
        <path className="tnum on" transform="translate(-291.84 -167)" d="M 445.11 206 c -0.13 0 -0.19 0 -0.19 -0.13 v -0.56 a 0.33 0.33 0 0 1 0 -0.16 l 0.11 -0.16 l 1.51 -1.79 a 14 14 0 0 0 1.22 -1.64 a 2.24 2.24 0 0 0 0.4 -1.08 a 1.1 1.1 0 0 0 -0.29 -0.79 a 1 1 0 0 0 -0.72 -0.31 a 2.76 2.76 0 0 0 -0.79 0.11 a 4 4 0 0 0 -0.83 0.37 a 0.26 0.26 0 0 1 -0.16 0 a 0.1 0.1 0 0 1 -0.1 -0.09 l -0.14 -0.76 a 0.1 0.1 0 0 1 0 -0.1 a 0.36 0.36 0 0 1 0.13 -0.11 a 5.59 5.59 0 0 1 0.87 -0.36 a 3.06 3.06 0 0 1 1 -0.16 a 2.8 2.8 0 0 1 0.88 0.13 a 2 2 0 0 1 0.7 0.4 a 1.83 1.83 0 0 1 0.46 0.66 a 2.33 2.33 0 0 1 0.17 0.92 a 2.29 2.29 0 0 1 -0.2 0.91 a 5.52 5.52 0 0 1 -0.5 0.91 a 9.92 9.92 0 0 1 -0.64 0.85 l -0.62 0.73 l -0.46 0.56 l -0.49 0.57 h 2.92 c 0.09 0 0.14 0.05 0.14 0.16 v 0.7 q 0 0.17 -0.14 0.17 Z"></path>
        <path className="tnum on" transform="translate(-291.84 -167)" d="M 450.39 206 c -0.12 0 -0.19 0 -0.19 -0.13 v -0.56 a 0.32 0.32 0 0 1 0 -0.16 l 0.1 -0.16 l 1.51 -1.79 a 14 14 0 0 0 1.22 -1.64 a 2.24 2.24 0 0 0 0.4 -1.08 a 1.1 1.1 0 0 0 -0.29 -0.79 a 1 1 0 0 0 -0.72 -0.31 a 2.75 2.75 0 0 0 -0.79 0.11 a 4 4 0 0 0 -0.83 0.37 a 0.26 0.26 0 0 1 -0.16 0 a 0.1 0.1 0 0 1 -0.1 -0.09 l -0.14 -0.76 a 0.11 0.11 0 0 1 0 -0.1 a 0.36 0.36 0 0 1 0.13 -0.11 a 5.6 5.6 0 0 1 0.87 -0.36 a 3 3 0 0 1 1 -0.16 a 2.79 2.79 0 0 1 0.88 0.13 a 2 2 0 0 1 0.7 0.4 a 1.86 1.86 0 0 1 0.46 0.66 a 2.33 2.33 0 0 1 0.17 0.92 a 2.29 2.29 0 0 1 -0.2 0.91 a 5.52 5.52 0 0 1 -0.5 0.91 a 10.13 10.13 0 0 1 -0.64 0.85 l -0.62 0.73 l -0.46 0.56 l -0.49 0.57 h 2.92 c 0.09 0 0.14 0.05 0.14 0.16 v 0.7 c 0 0.11 0 0.17 -0.14 0.17 Z"></path>
      </g>
      <g className={cn("fullTooth", "g23", charting["t23"])} data-for={`indTooth_${chartType}`} data-tip='t23' data-event='click' onClick={onClick && (() => onClick('t23'))}>
        <path className="ipr23 tooth ipr_t base on" id="t23" transform="translate(-291.84 -167)" d="M 457.94 215.1 s -0.68 -8.22 5.8 -8.94 s 16.34 0.77 17.73 11 s -4.77 11.29 -6.89 11.46 s -10.1 0.94 -12.13 1 s -4.81 -0.37 -5 -4.85 A 80.77 80.77 0 0 1 457.94 215.1 Z"></path>
        <path className="tnum on" transform="translate(-291.84 -167)" d="M 462.82 221.7 c -0.13 0 -0.19 0 -0.19 -0.13 V 221 a 0.33 0.33 0 0 1 0 -0.16 l 0.1 -0.16 l 1.51 -1.79 a 14.16 14.16 0 0 0 1.22 -1.64 a 2.24 2.24 0 0 0 0.4 -1.08 a 1.1 1.1 0 0 0 -0.29 -0.79 a 1 1 0 0 0 -0.72 -0.31 a 2.76 2.76 0 0 0 -0.79 0.11 a 4 4 0 0 0 -0.83 0.37 a 0.25 0.25 0 0 1 -0.16 0 a 0.1 0.1 0 0 1 -0.1 -0.09 l -0.14 -0.76 a 0.1 0.1 0 0 1 0 -0.1 a 0.38 0.38 0 0 1 0.13 -0.11 a 5.62 5.62 0 0 1 0.87 -0.36 a 3.07 3.07 0 0 1 1 -0.16 a 2.79 2.79 0 0 1 0.88 0.13 a 2 2 0 0 1 0.7 0.4 a 1.83 1.83 0 0 1 0.46 0.66 a 2.35 2.35 0 0 1 0 1.83 a 5.52 5.52 0 0 1 -0.5 0.91 a 10.12 10.12 0 0 1 -0.64 0.85 l -0.62 0.73 l -0.46 0.56 l -0.49 0.57 h 2.92 c 0.09 0 0.14 0.05 0.14 0.16 v 0.7 c 0 0.11 0 0.17 -0.14 0.17 Z"></path>
        <path className="tnum on" transform="translate(-291.84 -167)" d="M 471.79 221.17 a 2.41 2.41 0 0 1 -0.91 0.45 a 4.21 4.21 0 0 1 -1.11 0.14 a 5.19 5.19 0 0 1 -1.66 -0.28 a 0.29 0.29 0 0 1 -0.14 -0.08 a 0.16 0.16 0 0 1 0 -0.13 l 0.09 -0.75 a 0.15 0.15 0 0 1 0.06 -0.12 s 0.1 0 0.19 0 l 0.52 0.15 a 2.6 2.6 0 0 0 0.53 0.09 l 0.43 0 a 2.67 2.67 0 0 0 0.43 0 a 2 2 0 0 0 0.41 -0.09 a 1.41 1.41 0 0 0 0.36 -0.18 a 1.13 1.13 0 0 0 0.48 -1 a 1 1 0 0 0 -0.1 -0.44 a 1.05 1.05 0 0 0 -0.32 -0.38 a 1.63 1.63 0 0 0 -0.56 -0.26 a 3.09 3.09 0 0 0 -0.82 -0.09 h -0.5 a 0.1 0.1 0 0 1 -0.13 -0.13 v -0.74 c 0 -0.06 0 -0.1 0 -0.11 a 0.18 0.18 0 0 1 0.1 0 h 0.48 a 2.66 2.66 0 0 0 0.53 -0.06 a 2.34 2.34 0 0 0 0.6 -0.21 a 1.71 1.71 0 0 0 0.49 -0.38 a 0.81 0.81 0 0 0 0.2 -0.56 a 0.78 0.78 0 0 0 -0.13 -0.45 a 1 1 0 0 0 -0.33 -0.31 a 1.52 1.52 0 0 0 -0.44 -0.17 a 2 2 0 0 0 -0.45 -0.05 l -0.32 0 l -0.38 0.08 l -0.39 0.1 l -0.36 0.12 a 0.22 0.22 0 0 1 -0.17 0 a 0.15 0.15 0 0 1 -0.07 -0.11 l -0.09 -0.73 a 0.13 0.13 0 0 1 0 -0.11 a 0.26 0.26 0 0 1 0.14 -0.09 a 6.8 6.8 0 0 1 0.86 -0.25 a 4 4 0 0 1 0.84 -0.09 a 3.57 3.57 0 0 1 0.88 0.11 a 2.37 2.37 0 0 1 0.77 0.34 a 1.78 1.78 0 0 1 0.54 0.6 a 1.74 1.74 0 0 1 0.21 0.88 a 1.62 1.62 0 0 1 -0.09 0.54 a 2.12 2.12 0 0 1 -0.26 0.51 a 2.07 2.07 0 0 1 -0.41 0.43 a 2.28 2.28 0 0 1 -0.52 0.31 a 2.46 2.46 0 0 1 1 0.73 a 1.58 1.58 0 0 1 0.34 1 a 2.25 2.25 0 0 1 -0.2 1 A 2.08 2.08 0 0 1 471.79 221.17 Z"></path>
      </g>
      <g className={cn("fullTooth", "g24", charting["t24"])} data-for={`indTooth_${chartType}`} data-tip='t24' data-event='click' onClick={onClick && (() => onClick('t24'))}>
        <path className="ipr24 tooth ipr_t base" id="t24" transform="translate(-291.84 -167)" d="M 470 234.33 s 8.11 -6.17 11.33 -5.85 s 8.37 2.33 9.68 7.69 s 1.43 9.08 0.36 11.4 s -3.3 4 -8.88 4.63 s -10.76 1.06 -12.75 0.4 a 8.11 8.11 0 0 1 -5 -6 C 464.13 243.53 463.59 239.61 470 234.33 Z"></path>
        <path className="tnum" transform="translate(-291.84 -167)" d="M 473.57 244.53 c -0.12 0 -0.19 0 -0.19 -0.13 v -0.56 a 0.32 0.32 0 0 1 0 -0.16 l 0.1 -0.16 l 1.51 -1.79 a 14 14 0 0 0 1.22 -1.64 a 2.24 2.24 0 0 0 0.4 -1.08 a 1.1 1.1 0 0 0 -0.29 -0.79 a 1 1 0 0 0 -0.72 -0.31 a 2.75 2.75 0 0 0 -0.79 0.11 a 4 4 0 0 0 -0.83 0.37 a 0.26 0.26 0 0 1 -0.16 0 a 0.1 0.1 0 0 1 -0.1 -0.09 l -0.14 -0.76 a 0.11 0.11 0 0 1 0 -0.1 a 0.36 0.36 0 0 1 0.13 -0.11 a 5.6 5.6 0 0 1 0.87 -0.36 a 3 3 0 0 1 1 -0.16 a 2.79 2.79 0 0 1 0.88 0.13 a 2 2 0 0 1 0.7 0.4 a 1.86 1.86 0 0 1 0.46 0.66 a 2.33 2.33 0 0 1 0.17 0.92 a 2.29 2.29 0 0 1 -0.2 0.91 a 5.52 5.52 0 0 1 -0.5 0.91 a 10.13 10.13 0 0 1 -0.64 0.85 l -0.62 0.73 l -0.46 0.56 l -0.49 0.57 h 2.92 c 0.09 0 0.14 0.05 0.14 0.16 v 0.7 c 0 0.11 0 0.17 -0.14 0.17 Z"></path>
        <path className="tnum" transform="translate(-291.84 -167)" d="M 482.78 242.83 v 1.51 a 0.23 0.23 0 0 1 0 0.14 s -0.07 0 -0.14 0 h -0.72 a 0.2 0.2 0 0 1 -0.14 0 a 0.21 0.21 0 0 1 0 -0.14 v -1.51 h -3.18 a 0.26 0.26 0 0 1 -0.13 0 s 0 0 0 -0.1 V 242 a 0.29 0.29 0 0 1 0 -0.14 l 0.08 -0.12 l 3.08 -4.63 l 0.08 -0.1 a 0.2 0.2 0 0 1 0.13 0 h 0.91 c 0.05 0 0.08 0 0.09 0 a 0.49 0.49 0 0 1 0 0.12 v 4.7 h 0.93 a 0.2 0.2 0 0 1 0.12 0 s 0 0.06 0 0.13 v 0.73 c 0 0.07 0 0.11 0 0.12 a 0.2 0.2 0 0 1 -0.12 0 Z m -3.21 -1 h 2.14 v -3.25 Z"></path>
      </g>
      <g className={cn("fullTooth", "g25", charting["t25"])} data-for={`indTooth_${chartType}`} data-tip='t25' data-event='click' onClick={onClick && (() => onClick('t25'))}>
        <path className="ipr25 tooth ipr_t" id="t25" transform="translate(-291.84 -167)" d="M 478.6 257.13 s 9.73 -6.11 13.66 -5.72 s 9.11 3.45 8.53 8.24 s -0.46 11.84 -10.05 13.59 s -14.22 2.94 -16.6 -3.32 S 473.78 259.85 478.6 257.13 Z"></path>
        <path className="tnum" transform="translate(-291.84 -167)" d="M 482.47 267 c -0.13 0 -0.19 0 -0.19 -0.13 v -0.56 a 0.33 0.33 0 0 1 0 -0.16 l 0.11 -0.16 l 1.51 -1.79 a 14 14 0 0 0 1.22 -1.64 a 2.24 2.24 0 0 0 0.4 -1.08 a 1.1 1.1 0 0 0 -0.29 -0.79 a 1 1 0 0 0 -0.72 -0.31 a 2.76 2.76 0 0 0 -0.79 0.11 a 4 4 0 0 0 -0.83 0.37 a 0.26 0.26 0 0 1 -0.16 0 a 0.1 0.1 0 0 1 -0.1 -0.09 l -0.14 -0.76 a 0.1 0.1 0 0 1 0 -0.1 a 0.36 0.36 0 0 1 0.13 -0.11 a 5.59 5.59 0 0 1 0.87 -0.36 a 3.06 3.06 0 0 1 1 -0.16 a 2.8 2.8 0 0 1 0.88 0.13 a 2 2 0 0 1 0.7 0.4 a 1.83 1.83 0 0 1 0.46 0.66 a 2.33 2.33 0 0 1 0.17 0.92 a 2.29 2.29 0 0 1 -0.2 0.91 a 5.52 5.52 0 0 1 -0.5 0.91 a 9.92 9.92 0 0 1 -0.64 0.85 l -0.62 0.73 l -0.46 0.56 l -0.49 0.57 h 2.92 c 0.09 0 0.14 0.05 0.14 0.16 v 0.7 q 0 0.17 -0.14 0.17 Z"></path>
        <path className="tnum" transform="translate(-291.84 -167)" d="M 491.61 266.43 a 2.27 2.27 0 0 1 -0.88 0.49 a 3.68 3.68 0 0 1 -1.08 0.16 h -0.3 l -0.44 0 l -0.47 -0.09 a 1.81 1.81 0 0 1 -0.39 -0.13 s -0.07 -0.05 -0.07 -0.07 a 0.72 0.72 0 0 1 0 -0.1 v -0.81 c 0 -0.12 0.07 -0.15 0.21 -0.08 a 1.64 1.64 0 0 0 0.35 0.13 l 0.41 0.09 l 0.41 0.06 l 0.38 0 a 1.61 1.61 0 0 0 1.1 -0.38 a 1.32 1.32 0 0 0 0.43 -1 a 1.46 1.46 0 0 0 -0.35 -1 a 1.53 1.53 0 0 0 -1.17 -0.38 a 3.35 3.35 0 0 0 -0.67 0.07 a 4.26 4.26 0 0 0 -0.66 0.19 c -0.26 0.11 -0.39 0 -0.39 -0.18 l 0 -3.67 a 0.25 0.25 0 0 1 0 -0.14 s 0.08 0 0.18 0 h 3.64 a 0.12 0.12 0 0 1 0.09 0 a 0.13 0.13 0 0 1 0 0.09 v 0.77 a 0.15 0.15 0 0 1 0 0.09 a 0.11 0.11 0 0 1 -0.09 0 h -2.77 l 0 1.85 a 3.92 3.92 0 0 1 0.88 -0.09 a 2.77 2.77 0 0 1 0.94 0.15 a 2.2 2.2 0 0 1 0.76 0.45 a 2.09 2.09 0 0 1 0.51 0.71 a 2.29 2.29 0 0 1 0.18 0.94 A 2.48 2.48 0 0 1 491.61 266.43 Z"></path>
      </g>
      <g className={cn("fullTooth", "g26", charting["t26"])} data-for={`indTooth_${chartType}`} data-tip='t26' data-event='click' onClick={onClick && (() => onClick('t26'))}>
        <path className="ipr26 tooth ipr_t non_click" id="t26" transform="translate(-291.84 -167)" d="M 486.4 276.9 s 7 -3.33 11.93 -3.38 s 10.92 4 10.49 10.36 s 0.36 8.39 0.38 10.57 s 0.34 10.78 -10.73 12.37 s -15.19 2.66 -17.47 -0.49 a 14.82 14.82 0 0 1 -2.64 -8.22 C 478.18 296 470.87 284.44 486.4 276.9 Z"></path>
        <path className="tnum" transform="translate(-291.84 -167)" d="M 487.83 294.84 c -0.13 0 -0.19 0 -0.19 -0.13 v -0.56 a 0.33 0.33 0 0 1 0 -0.16 l 0.1 -0.16 l 1.51 -1.79 a 14.16 14.16 0 0 0 1.22 -1.64 a 2.24 2.24 0 0 0 0.4 -1.08 a 1.1 1.1 0 0 0 -0.29 -0.79 a 1 1 0 0 0 -0.72 -0.31 a 2.76 2.76 0 0 0 -0.79 0.11 a 4 4 0 0 0 -0.83 0.37 a 0.25 0.25 0 0 1 -0.16 0 a 0.1 0.1 0 0 1 -0.1 -0.09 l -0.14 -0.76 a 0.1 0.1 0 0 1 0 -0.1 a 0.38 0.38 0 0 1 0.13 -0.11 a 5.62 5.62 0 0 1 0.87 -0.36 a 3.07 3.07 0 0 1 1 -0.16 a 2.79 2.79 0 0 1 0.88 0.13 a 2 2 0 0 1 0.7 0.4 a 1.83 1.83 0 0 1 0.46 0.66 a 2.35 2.35 0 0 1 0 1.83 a 5.52 5.52 0 0 1 -0.5 0.91 a 10.12 10.12 0 0 1 -0.64 0.85 l -0.62 0.73 l -0.46 0.56 l -0.49 0.57 h 2.92 c 0.09 0 0.14 0.05 0.14 0.16 v 0.7 c 0 0.11 0 0.17 -0.14 0.17 Z"></path>
        <path className="tnum" transform="translate(-291.84 -167)" d="M 495.43 294.91 a 2.09 2.09 0 0 1 -1.81 -0.88 a 4.75 4.75 0 0 1 -0.63 -2.74 a 7.24 7.24 0 0 1 0.17 -1.59 a 3.61 3.61 0 0 1 0.56 -1.31 a 2.82 2.82 0 0 1 1 -0.89 a 3.38 3.38 0 0 1 1.56 -0.33 a 4.64 4.64 0 0 1 0.64 0 a 3.18 3.18 0 0 1 0.58 0.13 a 0.11 0.11 0 0 1 0.08 0.13 l 0 0.8 c 0 0.09 -0.07 0.12 -0.16 0.08 a 2.17 2.17 0 0 0 -0.55 -0.13 c -0.2 0 -0.41 0 -0.64 0 a 2 2 0 0 0 -0.88 0.17 a 1.74 1.74 0 0 0 -0.61 0.48 a 2.4 2.4 0 0 0 -0.39 0.73 a 4.93 4.93 0 0 0 -0.21 0.9 a 1.17 1.17 0 0 1 0.24 -0.23 a 1.89 1.89 0 0 1 0.34 -0.2 a 2.56 2.56 0 0 1 0.38 -0.14 a 1.44 1.44 0 0 1 0.39 -0.05 a 2.65 2.65 0 0 1 1 0.17 a 2.18 2.18 0 0 1 0.76 0.5 a 2.22 2.22 0 0 1 0.49 0.78 a 2.8 2.8 0 0 1 0.17 1 a 3 3 0 0 1 -0.17 1 a 2.36 2.36 0 0 1 -0.49 0.82 a 2.3 2.3 0 0 1 -0.78 0.55 A 2.55 2.55 0 0 1 495.43 294.91 Z m 0.1 -4 a 1.32 1.32 0 0 0 -1 0.39 a 1.41 1.41 0 0 0 -0.37 1 a 2 2 0 0 0 0.1 0.64 a 1.75 1.75 0 0 0 0.27 0.52 a 1.3 1.3 0 0 0 0.41 0.35 a 1.06 1.06 0 0 0 0.52 0.13 a 1.26 1.26 0 0 0 1 -0.42 a 1.91 1.91 0 0 0 0 -2.22 A 1.16 1.16 0 0 0 495.53 290.89 Z"></path>
      </g>
      <g className={cn("fullTooth", "g27", charting["t27"])} data-for={`indTooth_${chartType}`} data-tip='t27' data-event='click' onClick={onClick && (() => onClick('t27'))}>
        <path className="ipr27 tooth ipr_t non_click" id="t27" transform="translate(-291.84 -167)" d="M 487.86 314.18 s 13.42 -8.64 20.72 -7.68 s 9 5.77 8.34 11.2 s -0.69 11.06 -1.88 12.72 s -6 9.81 -22.27 7.84 S 484.27 315.7 487.86 314.18 Z"></path>
        <path className="tnum" transform="translate(-291.84 -167)" d="M 495.81 326.31 c -0.12 0 -0.19 0 -0.19 -0.13 v -0.56 a 0.32 0.32 0 0 1 0 -0.16 l 0.1 -0.16 l 1.51 -1.79 a 14 14 0 0 0 1.22 -1.64 a 2.24 2.24 0 0 0 0.4 -1.08 a 1.1 1.1 0 0 0 -0.29 -0.79 a 1 1 0 0 0 -0.72 -0.31 a 2.75 2.75 0 0 0 -0.79 0.11 a 4 4 0 0 0 -0.83 0.37 a 0.26 0.26 0 0 1 -0.16 0 a 0.1 0.1 0 0 1 -0.1 -0.09 l -0.14 -0.76 a 0.11 0.11 0 0 1 0 -0.1 a 0.36 0.36 0 0 1 0.13 -0.11 a 5.6 5.6 0 0 1 0.87 -0.36 a 3 3 0 0 1 1 -0.16 a 2.79 2.79 0 0 1 0.88 0.13 a 2 2 0 0 1 0.7 0.4 a 1.86 1.86 0 0 1 0.46 0.66 a 2.33 2.33 0 0 1 0.17 0.92 a 2.29 2.29 0 0 1 -0.2 0.91 a 5.52 5.52 0 0 1 -0.5 0.91 a 10.13 10.13 0 0 1 -0.64 0.85 l -0.62 0.73 l -0.46 0.56 l -0.49 0.57 H 500 c 0.09 0 0.14 0.05 0.14 0.16 v 0.7 c 0 0.11 0 0.17 -0.14 0.17 Z"></path>
        <path className="tnum" transform="translate(-291.84 -167)" d="M 502.94 326.16 a 0.33 0.33 0 0 1 -0.08 0.11 a 0.25 0.25 0 0 1 -0.14 0 h -0.81 a 0.32 0.32 0 0 1 -0.19 0 s 0 -0.07 0 -0.14 l 2.6 -6.36 h -3.18 a 0.17 0.17 0 0 1 -0.12 0 a 0.16 0.16 0 0 1 0 -0.12 v -0.7 c 0 -0.11 0.06 -0.16 0.17 -0.16 h 4.24 a 0.35 0.35 0 0 1 0.2 0 a 0.21 0.21 0 0 1 0 0.14 v 0.61 a 0.3 0.3 0 0 1 -0.05 0.16 Z"></path>
      </g>
      <g className={cn("fullTooth", "g28", charting["t28"])} data-for={`indTooth_${chartType}`} data-tip='t28' data-event='click' onClick={onClick && (() => onClick('t28'))}>
        <path className="ipr28 tooth ipr_t non_click" id="t28" transform="translate(-291.84 -167)" d="M 509.72 338.45 s 9.92 2.49 8.24 8.35 s -1.89 5.66 -2.11 8.47 s -0.31 10.07 -8 10.83 s -19.46 -1.88 -20.4 -12.12 S 493.43 338.34 509.72 338.45 Z"></path>
        <path className="tnum" transform="translate(-291.84 -167)" d="M 498.72 355.94 c -0.13 0 -0.19 0 -0.19 -0.13 v -0.56 a 0.32 0.32 0 0 1 0 -0.16 l 0.1 -0.16 l 1.51 -1.79 a 14.16 14.16 0 0 0 1.22 -1.64 a 2.24 2.24 0 0 0 0.4 -1.08 a 1.1 1.1 0 0 0 -0.29 -0.79 a 1 1 0 0 0 -0.72 -0.31 a 2.75 2.75 0 0 0 -0.79 0.11 a 4 4 0 0 0 -0.83 0.37 a 0.25 0.25 0 0 1 -0.16 0 a 0.1 0.1 0 0 1 -0.1 -0.09 l -0.14 -0.76 a 0.11 0.11 0 0 1 0 -0.1 a 0.38 0.38 0 0 1 0.13 -0.11 a 5.63 5.63 0 0 1 0.87 -0.36 a 3.06 3.06 0 0 1 1 -0.16 a 2.79 2.79 0 0 1 0.88 0.13 a 2 2 0 0 1 0.7 0.4 a 1.86 1.86 0 0 1 0.46 0.66 a 2.35 2.35 0 0 1 0 1.83 a 5.52 5.52 0 0 1 -0.5 0.91 a 10.34 10.34 0 0 1 -0.64 0.85 l -0.62 0.73 l -0.46 0.56 l -0.49 0.57 H 503 c 0.09 0 0.14 0.05 0.14 0.16 v 0.7 c 0 0.11 0 0.17 -0.14 0.17 Z"></path>
        <path className="tnum" transform="translate(-291.84 -167)" d="M 506.3 356 a 3.14 3.14 0 0 1 -0.9 -0.13 a 2.59 2.59 0 0 1 -0.81 -0.4 a 2.1 2.1 0 0 1 -0.59 -0.67 a 1.88 1.88 0 0 1 -0.23 -0.94 a 1.62 1.62 0 0 1 0.12 -0.65 a 1.88 1.88 0 0 1 0.33 -0.51 a 2.48 2.48 0 0 1 0.48 -0.4 a 5.76 5.76 0 0 1 0.57 -0.33 a 3.08 3.08 0 0 1 -0.95 -0.79 a 1.63 1.63 0 0 1 -0.35 -1 a 1.61 1.61 0 0 1 0.18 -0.77 a 1.88 1.88 0 0 1 0.5 -0.6 a 2.37 2.37 0 0 1 0.74 -0.39 a 2.86 2.86 0 0 1 0.9 -0.14 a 3.16 3.16 0 0 1 0.92 0.13 a 2.23 2.23 0 0 1 0.73 0.37 a 1.72 1.72 0 0 1 0.48 0.58 a 1.69 1.69 0 0 1 0.17 0.78 a 1.59 1.59 0 0 1 -0.31 0.95 a 3.88 3.88 0 0 1 -1 0.89 l 0.63 0.4 a 2.73 2.73 0 0 1 0.48 0.42 a 1.63 1.63 0 0 1 0.3 0.48 a 1.57 1.57 0 0 1 0.11 0.6 a 1.94 1.94 0 0 1 -0.78 1.61 a 2.53 2.53 0 0 1 -0.81 0.4 A 3.33 3.33 0 0 1 506.3 356 Z m 0 -3.48 l -0.45 0.16 a 2.21 2.21 0 0 0 -0.46 0.26 a 1.52 1.52 0 0 0 -0.36 0.38 a 0.94 0.94 0 0 0 -0.15 0.53 a 0.86 0.86 0 0 0 0.13 0.47 a 1.25 1.25 0 0 0 0.33 0.36 a 1.54 1.54 0 0 0 0.46 0.23 a 1.65 1.65 0 0 0 0.49 0.08 a 1.82 1.82 0 0 0 0.51 -0.07 a 1.42 1.42 0 0 0 0.45 -0.22 a 1.23 1.23 0 0 0 0.32 -0.36 a 0.91 0.91 0 0 0 0.13 -0.48 a 0.75 0.75 0 0 0 -0.12 -0.39 a 1.65 1.65 0 0 0 -0.33 -0.38 a 3.17 3.17 0 0 0 -0.45 -0.33 A 2.82 2.82 0 0 0 506.31 352.52 Z m 0 -3.27 a 1.31 1.31 0 0 0 -0.42 0.07 a 1.37 1.37 0 0 0 -0.38 0.19 a 1 1 0 0 0 -0.28 0.31 a 0.8 0.8 0 0 0 -0.11 0.42 a 0.7 0.7 0 0 0 0.14 0.41 a 2 2 0 0 0 0.33 0.37 a 2.73 2.73 0 0 0 0.39 0.29 a 2.31 2.31 0 0 0 0.33 0.17 a 1.93 1.93 0 0 0 0.35 -0.19 A 2.89 2.89 0 0 0 507 351 a 2 2 0 0 0 0.31 -0.38 a 0.73 0.73 0 0 0 0.12 -0.38 a 0.77 0.77 0 0 0 -0.09 -0.37 a 1.06 1.06 0 0 0 -0.25 -0.31 a 1.16 1.16 0 0 0 -0.37 -0.21 A 1.36 1.36 0 0 0 506.3 349.25 Z"></path>
      </g>
    </svg>
    <svg xmlns="http://www.w3.org/2000/svg" id="toothLow" viewBox="0 210 240 210" width={width} height={height}>
      <defs>
        <style>
        </style>
      </defs>
      <line className="cls-1" x1="119.75" x2="119.75" y2="1"></line>
      <line className="cls-2" x1="119.75" y1="3" x2="119.75" y2="422.12"></line>
      <line className="cls-1" x1="119.75" y1="423.12" x2="119.75" y2="424.12"></line>
      <line className="cls-1" x1="239.49" y1="206.35" x2="238.49" y2="206.35"></line>
      <line className="cls-3" x1="236.5" y1="206.35" x2="2" y2="206.35"></line>
      <line className="cls-1" x1="1" y1="206.35" y2="206.35"></line>
      <g className={cn("fullTooth", "g48", charting["t48"])} data-for={`indTooth_${chartType}`} data-tip='t48' data-event='click' onClick={onClick && (() => onClick('t48'))}>
        <path className="ipr48 tooth ipr_t non_click" id="t48" transform="translate(-291.84 -167)" d="M 310.49 379.53 s -9.88 3.57 -9.28 8.21 s 1 5.63 0.76 10.1 s 1.27 12.36 4.76 13 s 16.93 1.06 21 -4.73 S 329.81 373.92 310.49 379.53 Z"></path>
        <path className="tnum" transform="translate(-291.84 -167)" d="M 313.77 397.75 v 1.51 a 0.24 0.24 0 0 1 0 0.14 s -0.07 0 -0.14 0 h -0.72 a 0.19 0.19 0 0 1 -0.14 0 a 0.21 0.21 0 0 1 0 -0.14 v -1.51 h -3.19 a 0.26 0.26 0 0 1 -0.13 0 s 0 0 0 -0.1 v -0.73 a 0.28 0.28 0 0 1 0 -0.14 l 0.08 -0.12 l 3.08 -4.63 l 0.08 -0.1 a 0.2 0.2 0 0 1 0.12 0 h 0.91 c 0.05 0 0.08 0 0.09 0 a 0.47 0.47 0 0 1 0 0.12 v 4.7 h 0.93 a 0.2 0.2 0 0 1 0.12 0 s 0 0.06 0 0.13 v 0.73 c 0 0.07 0 0.11 0 0.12 a 0.2 0.2 0 0 1 -0.12 0 Z m -3.21 -1 h 2.14 v -3.25 Z"></path>
        <path className="tnum" transform="translate(-291.84 -167)" d="M 317.43 399.52 a 3.14 3.14 0 0 1 -0.9 -0.13 a 2.59 2.59 0 0 1 -0.81 -0.4 a 2.12 2.12 0 0 1 -0.59 -0.67 a 1.88 1.88 0 0 1 -0.23 -0.94 a 1.6 1.6 0 0 1 0.12 -0.65 a 1.85 1.85 0 0 1 0.33 -0.51 a 2.47 2.47 0 0 1 0.48 -0.4 a 5.71 5.71 0 0 1 0.57 -0.33 a 3.09 3.09 0 0 1 -0.95 -0.79 a 1.63 1.63 0 0 1 -0.35 -1 a 1.61 1.61 0 0 1 0.18 -0.77 a 1.87 1.87 0 0 1 0.5 -0.6 a 2.37 2.37 0 0 1 0.74 -0.39 a 2.86 2.86 0 0 1 0.9 -0.14 a 3.15 3.15 0 0 1 0.92 0.13 a 2.22 2.22 0 0 1 0.73 0.37 a 1.72 1.72 0 0 1 0.48 0.58 a 1.7 1.7 0 0 1 0.17 0.78 a 1.59 1.59 0 0 1 -0.31 0.95 a 3.88 3.88 0 0 1 -1 0.89 l 0.63 0.4 a 2.73 2.73 0 0 1 0.48 0.42 a 1.66 1.66 0 0 1 0.3 0.48 a 1.58 1.58 0 0 1 0.1 0.6 a 1.93 1.93 0 0 1 -0.78 1.61 a 2.52 2.52 0 0 1 -0.81 0.4 A 3.33 3.33 0 0 1 317.43 399.52 Z m 0 -3.48 l -0.45 0.16 a 2.21 2.21 0 0 0 -0.46 0.26 a 1.51 1.51 0 0 0 -0.36 0.38 a 0.94 0.94 0 0 0 -0.15 0.53 a 0.87 0.87 0 0 0 0.13 0.47 a 1.25 1.25 0 0 0 0.33 0.36 a 1.55 1.55 0 0 0 0.46 0.23 a 1.65 1.65 0 0 0 0.49 0.08 a 1.81 1.81 0 0 0 0.51 -0.07 a 1.41 1.41 0 0 0 0.45 -0.22 a 1.24 1.24 0 0 0 0.32 -0.36 a 0.92 0.92 0 0 0 0.12 -0.48 a 0.75 0.75 0 0 0 -0.12 -0.39 a 1.69 1.69 0 0 0 -0.33 -0.38 a 3.16 3.16 0 0 0 -0.45 -0.33 A 2.82 2.82 0 0 0 317.44 396 Z m 0 -3.27 a 1.3 1.3 0 0 0 -0.42 0.07 a 1.37 1.37 0 0 0 -0.38 0.19 a 1 1 0 0 0 -0.28 0.31 a 0.81 0.81 0 0 0 -0.11 0.42 a 0.7 0.7 0 0 0 0.14 0.41 a 2 2 0 0 0 0.33 0.37 a 2.76 2.76 0 0 0 0.39 0.29 a 2.29 2.29 0 0 0 0.33 0.17 a 1.93 1.93 0 0 0 0.35 -0.19 a 2.87 2.87 0 0 0 0.38 -0.31 a 2 2 0 0 0 0.31 -0.38 a 0.72 0.72 0 0 0 0.12 -0.38 a 0.77 0.77 0 0 0 -0.09 -0.37 a 1.08 1.08 0 0 0 -0.25 -0.31 a 1.17 1.17 0 0 0 -0.37 -0.21 A 1.36 1.36 0 0 0 317.43 392.76 Z"></path>
      </g>
      <g className={cn("fullTooth", "g47", charting["t47"])} data-for={`indTooth_${chartType}`} data-tip='t47' data-event='click' onClick={onClick && (() => onClick('t47'))}>
        <path className="ipr47 tooth ipr_t non_click" id="t47" transform="translate(-291.84 -167)" d="M 316.12 412.17 s -8.59 1.92 -9.24 6 s -0.4 5.74 -2 9.62 s -0.69 12.74 2 15.6 s 6 3.88 9.86 4 s 13.19 -0.7 17.57 -2.7 s 6.83 -4.62 6 -10 s -3.45 -9 -3.94 -12.27 s -6.47 -12 -10.69 -11.16 S 319.16 411.25 316.12 412.17 Z"></path>
        <path className="tnum" transform="translate(-291.84 -167)" d="M 318.75 432.67 v 1.51 a 0.24 0.24 0 0 1 0 0.14 s -0.07 0 -0.14 0 h -0.72 a 0.19 0.19 0 0 1 -0.14 0 a 0.21 0.21 0 0 1 0 -0.14 v -1.51 H 314.5 a 0.26 0.26 0 0 1 -0.13 0 s 0 0 0 -0.1 v -0.73 a 0.28 0.28 0 0 1 0 -0.14 l 0.08 -0.12 l 3.08 -4.63 l 0.08 -0.1 a 0.2 0.2 0 0 1 0.12 0 h 0.91 c 0.05 0 0.08 0 0.09 0 a 0.47 0.47 0 0 1 0 0.12 v 4.7 h 0.93 a 0.2 0.2 0 0 1 0.12 0 s 0 0.06 0 0.13 v 0.73 c 0 0.07 0 0.11 0 0.12 a 0.2 0.2 0 0 1 -0.12 0 Z m -3.21 -1 h 2.14 V 428.4 Z"></path>
        <path className="tnum" transform="translate(-291.84 -167)" d="M 322 434.23 a 0.32 0.32 0 0 1 -0.08 0.11 a 0.24 0.24 0 0 1 -0.14 0 h -0.81 a 0.33 0.33 0 0 1 -0.19 0 s 0 -0.07 0 -0.14 l 2.6 -6.36 h -3.18 a 0.17 0.17 0 0 1 -0.12 0 a 0.16 0.16 0 0 1 0 -0.12 V 427 c 0 -0.11 0.06 -0.16 0.17 -0.16 h 4.24 a 0.35 0.35 0 0 1 0.2 0 a 0.21 0.21 0 0 1 0 0.14 v 0.61 a 0.29 0.29 0 0 1 0 0.16 Z"></path>
      </g>
      <g className={cn("fullTooth", "g46", charting["t46"])} data-for={`indTooth_${chartType}`} data-tip='t46' data-event='click' onClick={onClick && (() => onClick('t46'))}>
        <path className="ipr46 tooth ipr_t non_click" id="t46" transform="translate(-291.84 -167)" d="M 320.55 448.67 s -11.19 4.53 -10.89 9.51 s 1.48 8.33 1.51 11.75 s 0.86 13 9.15 13.38 s 16 1.47 20.46 -1.13 s 6.55 -5.36 5.11 -10.78 s -3.36 -7.75 -3.3 -10.75 s -1.19 -12.26 -9.48 -12.54 S 325.76 447.33 320.55 448.67 Z"></path>
        <path className="tnum" transform="translate(-291.84 -167)" d="M 326.29 467.8 v 1.51 a 0.24 0.24 0 0 1 0 0.14 s -0.07 0 -0.14 0 h -0.72 a 0.19 0.19 0 0 1 -0.14 0 a 0.21 0.21 0 0 1 0 -0.14 V 467.8 H 322 a 0.26 0.26 0 0 1 -0.13 0 s 0 0 0 -0.1 v -0.73 a 0.28 0.28 0 0 1 0 -0.14 l 0.08 -0.12 l 3.08 -4.63 l 0.08 -0.1 a 0.2 0.2 0 0 1 0.12 0 h 0.91 c 0.05 0 0.08 0 0.09 0 a 0.47 0.47 0 0 1 0 0.12 v 4.7 h 0.93 a 0.2 0.2 0 0 1 0.12 0 s 0 0.06 0 0.13 v 0.73 c 0 0.07 0 0.11 0 0.12 a 0.2 0.2 0 0 1 -0.12 0 Z m -3.21 -1 h 2.14 v -3.25 Z"></path>
        <path className="tnum" transform="translate(-291.84 -167)" d="M 330 469.57 a 2.09 2.09 0 0 1 -1.81 -0.88 a 4.75 4.75 0 0 1 -0.63 -2.74 a 7.21 7.21 0 0 1 0.17 -1.59 a 3.63 3.63 0 0 1 0.56 -1.31 a 2.82 2.82 0 0 1 1 -0.89 a 3.39 3.39 0 0 1 1.56 -0.33 a 4.64 4.64 0 0 1 0.64 0 a 3.19 3.19 0 0 1 0.58 0.13 a 0.11 0.11 0 0 1 0.08 0.13 l 0 0.8 c 0 0.09 -0.07 0.12 -0.16 0.08 a 2.16 2.16 0 0 0 -0.55 -0.13 q -0.29 0 -0.64 0 a 2 2 0 0 0 -0.88 0.17 a 1.74 1.74 0 0 0 -0.61 0.48 a 2.39 2.39 0 0 0 -0.39 0.73 a 4.93 4.93 0 0 0 -0.21 0.9 a 1.18 1.18 0 0 1 0.24 -0.23 a 1.92 1.92 0 0 1 0.34 -0.2 a 2.54 2.54 0 0 1 0.38 -0.14 a 1.44 1.44 0 0 1 0.39 -0.05 a 2.66 2.66 0 0 1 1 0.17 a 2.17 2.17 0 0 1 0.76 0.5 a 2.24 2.24 0 0 1 0.49 0.78 a 2.82 2.82 0 0 1 0.17 1 a 3 3 0 0 1 -0.17 1 a 2.36 2.36 0 0 1 -0.49 0.82 a 2.3 2.3 0 0 1 -0.78 0.55 A 2.54 2.54 0 0 1 330 469.57 Z m 0.1 -4 a 1.32 1.32 0 0 0 -1 0.39 a 1.41 1.41 0 0 0 -0.37 1 a 2 2 0 0 0 0.1 0.64 a 1.73 1.73 0 0 0 0.27 0.52 a 1.3 1.3 0 0 0 0.41 0.35 a 1.06 1.06 0 0 0 0.52 0.13 a 1.26 1.26 0 0 0 1 -0.42 a 1.91 1.91 0 0 0 0 -2.22 A 1.16 1.16 0 0 0 330.06 465.55 Z"></path>
      </g>
      <g className={cn("fullTooth", "g45", charting["t45"])} data-for={`indTooth_${chartType}`} data-tip='t45' data-event='click' onClick={onClick && (() => onClick('t45'))}>
        <path className="ipr45 tooth ipr_t" id="t45" transform="translate(-291.84 -167)" d="M 350.81 487.91 s -1.1 -2.1 -5 -2.39 s -9.21 -0.92 -12.45 0.15 s -7 1.2 -8.72 5.41 s -3.19 8.54 -1.69 11.06 s 3.25 3.08 6.11 5.32 s 3 2.24 7.17 2.64 s 10.44 -1.85 13.25 -6.77 S 353.51 492.32 350.81 487.91 Z"></path>
        <path className="tnum" transform="translate(-291.84 -167)" d="M 336.17 498.81 v 1.51 a 0.24 0.24 0 0 1 0 0.14 s -0.07 0 -0.14 0 h -0.72 a 0.19 0.19 0 0 1 -0.14 0 a 0.21 0.21 0 0 1 0 -0.14 v -1.51 h -3.19 a 0.26 0.26 0 0 1 -0.13 0 s 0 0 0 -0.1 V 498 a 0.28 0.28 0 0 1 0 -0.14 l 0.08 -0.12 l 3.08 -4.63 l 0.08 -0.1 a 0.2 0.2 0 0 1 0.12 0 h 0.91 c 0.05 0 0.08 0 0.09 0 a 0.47 0.47 0 0 1 0 0.12 v 4.7 h 0.93 a 0.2 0.2 0 0 1 0.12 0 s 0 0.06 0 0.13 v 0.73 c 0 0.07 0 0.11 0 0.12 a 0.2 0.2 0 0 1 -0.12 0 Z m -3.21 -1 h 2.14 v -3.25 Z"></path>
        <path className="tnum" transform="translate(-291.84 -167)" d="M 341.38 499.92 a 2.26 2.26 0 0 1 -0.88 0.49 a 3.69 3.69 0 0 1 -1.08 0.16 h -0.3 l -0.44 0 l -0.47 -0.09 a 1.79 1.79 0 0 1 -0.39 -0.13 s -0.07 -0.05 -0.07 -0.07 a 0.8 0.8 0 0 1 0 -0.1 v -0.81 c 0 -0.12 0.07 -0.15 0.21 -0.08 a 1.64 1.64 0 0 0 0.35 0.13 l 0.41 0.09 l 0.41 0.06 l 0.38 0 a 1.61 1.61 0 0 0 1.1 -0.38 a 1.31 1.31 0 0 0 0.43 -1 a 1.46 1.46 0 0 0 -0.35 -1 a 1.53 1.53 0 0 0 -1.17 -0.38 a 3.35 3.35 0 0 0 -0.67 0.07 a 4.32 4.32 0 0 0 -0.66 0.19 q -0.39 0.16 -0.39 -0.18 l 0 -3.67 a 0.24 0.24 0 0 1 0 -0.14 s 0.08 0 0.18 0 h 3.64 a 0.12 0.12 0 0 1 0.09 0 a 0.13 0.13 0 0 1 0 0.09 v 0.77 a 0.15 0.15 0 0 1 0 0.09 a 0.11 0.11 0 0 1 -0.09 0 h -2.77 l 0 1.85 a 3.92 3.92 0 0 1 0.88 -0.09 a 2.78 2.78 0 0 1 0.94 0.15 a 2.21 2.21 0 0 1 0.76 0.45 a 2.09 2.09 0 0 1 0.51 0.71 a 2.3 2.3 0 0 1 0.18 0.94 A 2.48 2.48 0 0 1 341.38 499.92 Z"></path>
      </g>
      <g className={cn("fullTooth", "g44", charting["t44"])} data-for={`indTooth_${chartType}`} data-tip='t44' data-event='click' onClick={onClick && (() => onClick('t44'))}>
        <path className="ipr44 tooth ipr_t" id="t44" transform="translate(-291.84 -167)" d="M 360.19 519.24 s 0.45 -6.32 -3.36 -7.58 s -11.47 -2 -15.55 -0.34 s -6 3.41 -7.66 9.05 s -0.44 10.71 4.71 12.43 s 12.55 4.88 16.53 -1.89 S 360.28 521.55 360.19 519.24 Z"></path>
        <path className="tnum" transform="translate(-291.84 -167)" d="M 345.58 524.3 v 1.51 a 0.24 0.24 0 0 1 0 0.14 s -0.07 0 -0.14 0 h -0.72 a 0.19 0.19 0 0 1 -0.14 0 a 0.21 0.21 0 0 1 0 -0.14 V 524.3 h -3.19 a 0.26 0.26 0 0 1 -0.13 0 s 0 0 0 -0.1 v -0.73 a 0.28 0.28 0 0 1 0 -0.14 l 0.08 -0.12 l 3.08 -4.63 l 0.08 -0.1 a 0.2 0.2 0 0 1 0.12 0 h 0.91 c 0.05 0 0.08 0 0.09 0 a 0.47 0.47 0 0 1 0 0.12 v 4.7 h 0.93 a 0.2 0.2 0 0 1 0.12 0 s 0 0.06 0 0.13 v 0.73 c 0 0.07 0 0.11 0 0.12 a 0.2 0.2 0 0 1 -0.12 0 Z m -3.21 -1 h 2.14 V 520 Z"></path>
        <path className="tnum" transform="translate(-291.84 -167)" d="M 350.87 524.3 v 1.51 a 0.24 0.24 0 0 1 0 0.14 s -0.07 0 -0.14 0 H 350 a 0.19 0.19 0 0 1 -0.14 0 a 0.21 0.21 0 0 1 0 -0.14 V 524.3 h -3.19 a 0.26 0.26 0 0 1 -0.13 0 s 0 0 0 -0.1 v -0.73 a 0.28 0.28 0 0 1 0 -0.14 l 0.08 -0.12 l 3.08 -4.63 l 0.08 -0.1 a 0.2 0.2 0 0 1 0.12 0 h 0.91 c 0.05 0 0.08 0 0.09 0 a 0.47 0.47 0 0 1 0 0.12 v 4.7 h 0.93 a 0.2 0.2 0 0 1 0.12 0 s 0 0.06 0 0.13 v 0.73 c 0 0.07 0 0.11 0 0.12 a 0.2 0.2 0 0 1 -0.12 0 Z m -3.21 -1 h 2.14 V 520 Z"></path>
      </g>
      <g className={cn("fullTooth", "g43", charting["t43"])} data-for={`indTooth_${chartType}`} data-tip='t43' data-event='click' onClick={onClick && (() => onClick('t43'))}>
        <path className="ipr43 tooth ipr_t on" id="t43" transform="translate(-291.84 -167)" d="M 370.11 532.52 a 5.27 5.27 0 0 0 -5.94 -0.52 c -3.47 1.77 -4.37 1.46 -8.63 2.93 s -6.15 0.06 -7 5.88 s -1.58 7.75 -0.22 10.41 s 4.1 5.73 10.15 6.15 s 12 -0.81 13.54 -10.32 S 371.58 534.4 370.11 532.52 Z"></path>
        <path className="tnum on" transform="translate(-291.84 -167)" d="M 358.67 547.45 V 549 a 0.24 0.24 0 0 1 0 0.14 s -0.07 0 -0.14 0 h -0.72 a 0.19 0.19 0 0 1 -0.14 0 a 0.21 0.21 0 0 1 0 -0.14 v -1.51 h -3.19 a 0.26 0.26 0 0 1 -0.13 0 s 0 0 0 -0.1 v -0.73 a 0.28 0.28 0 0 1 0 -0.14 l 0.08 -0.12 l 3.08 -4.63 l 0.08 -0.1 a 0.2 0.2 0 0 1 0.12 0 h 0.91 c 0.05 0 0.08 0 0.09 0 a 0.47 0.47 0 0 1 0 0.12 v 4.7 h 0.93 a 0.2 0.2 0 0 1 0.12 0 s 0 0.06 0 0.13 v 0.73 c 0 0.07 0 0.11 0 0.12 a 0.2 0.2 0 0 1 -0.12 0 Z m -3.21 -1 h 2.14 v -3.25 Z"></path>
        <path className="tnum on" transform="translate(-291.84 -167)" d="M 363.72 548.63 a 2.41 2.41 0 0 1 -0.91 0.45 a 4.21 4.21 0 0 1 -1.11 0.14 a 5.05 5.05 0 0 1 -0.86 -0.07 a 5 5 0 0 1 -0.8 -0.2 a 0.3 0.3 0 0 1 -0.14 -0.08 a 0.17 0.17 0 0 1 0 -0.13 L 360 548 a 0.15 0.15 0 0 1 0.06 -0.12 s 0.1 0 0.19 0 l 0.52 0.15 a 2.61 2.61 0 0 0 0.53 0.09 l 0.43 0 a 2.66 2.66 0 0 0 0.43 0 a 2 2 0 0 0 0.41 -0.09 a 1.4 1.4 0 0 0 0.36 -0.18 a 1.13 1.13 0 0 0 0.48 -1 a 1 1 0 0 0 -0.1 -0.44 a 1.05 1.05 0 0 0 -0.32 -0.38 a 1.64 1.64 0 0 0 -0.56 -0.26 a 3.1 3.1 0 0 0 -0.82 -0.09 H 361 a 0.1 0.1 0 0 1 -0.13 -0.13 v -0.74 c 0 -0.06 0 -0.1 0 -0.11 a 0.18 0.18 0 0 1 0.1 0 h 0.48 a 2.65 2.65 0 0 0 0.53 -0.06 a 2.33 2.33 0 0 0 0.6 -0.21 a 1.7 1.7 0 0 0 0.49 -0.38 a 0.81 0.81 0 0 0 0.2 -0.56 a 0.77 0.77 0 0 0 -0.13 -0.45 a 1 1 0 0 0 -0.33 -0.31 a 1.53 1.53 0 0 0 -0.44 -0.17 a 2 2 0 0 0 -0.45 -0.05 l -0.32 0 l -0.38 0.08 l -0.39 0.1 l -0.36 0.12 a 0.22 0.22 0 0 1 -0.17 0 a 0.15 0.15 0 0 1 -0.07 -0.11 l -0.09 -0.73 a 0.13 0.13 0 0 1 0 -0.11 a 0.26 0.26 0 0 1 0.14 -0.09 a 6.74 6.74 0 0 1 0.86 -0.25 a 4 4 0 0 1 0.84 -0.09 a 3.57 3.57 0 0 1 0.88 0.11 a 2.38 2.38 0 0 1 0.77 0.34 a 1.79 1.79 0 0 1 0.54 0.6 a 1.74 1.74 0 0 1 0.21 0.88 a 1.61 1.61 0 0 1 -0.09 0.54 a 2.08 2.08 0 0 1 -0.67 0.94 a 2.29 2.29 0 0 1 -0.52 0.31 a 2.47 2.47 0 0 1 1 0.73 a 1.59 1.59 0 0 1 0.34 1 a 2.26 2.26 0 0 1 -0.2 1 A 2.08 2.08 0 0 1 363.72 548.63 Z"></path>
      </g>
      <g className={cn("fullTooth", "g42", charting["t42"])} data-for={`indTooth_${chartType}`} data-tip='t42' data-event='click' onClick={onClick && (() => onClick('t42'))}>
        <path className="ipr42 tooth ipr_t on" id="t42" transform="translate(-291.84 -167)" d="M 389.58 556.16 a 23.93 23.93 0 0 1 -1.18 -6.26 c 0 -2.24 -2.5 -6.1 -6.37 -4.82 s -6.09 5.42 -8.36 7.52 s -7.72 6 -4 10.44 s 9.67 6.64 13.63 5.85 s 8.65 -2.16 7.6 -9.59 C 390.55 557.93 390 557.5 389.58 556.16 Z"></path>
        <path className="tnum on" transform="translate(-291.84 -167)" d="M 379.42 560.54 v 1.51 a 0.24 0.24 0 0 1 0 0.14 s -0.07 0 -0.14 0 h -0.72 a 0.19 0.19 0 0 1 -0.14 0 a 0.21 0.21 0 0 1 0 -0.14 v -1.51 h -3.19 a 0.26 0.26 0 0 1 -0.13 0 s 0 0 0 -0.1 v -0.73 a 0.28 0.28 0 0 1 0 -0.14 l 0.08 -0.12 l 3.08 -4.63 l 0.08 -0.1 a 0.2 0.2 0 0 1 0.12 0 h 0.91 c 0.05 0 0.08 0 0.09 0 a 0.47 0.47 0 0 1 0 0.12 v 4.7 h 0.93 a 0.2 0.2 0 0 1 0.12 0 s 0 0.06 0 0.13 v 0.73 c 0 0.07 0 0.11 0 0.12 a 0.2 0.2 0 0 1 -0.12 0 Z m -3.21 -1 h 2.14 v -3.25 Z"></path>
        <path className="tnum on" transform="translate(-291.84 -167)" d="M 380.79 562.25 q -0.19 0 -0.19 -0.13 v -0.56 a 0.32 0.32 0 0 1 0 -0.16 l 0.1 -0.16 l 1.51 -1.79 a 14.09 14.09 0 0 0 1.22 -1.64 a 2.24 2.24 0 0 0 0.4 -1.08 a 1.1 1.1 0 0 0 -0.29 -0.79 a 1 1 0 0 0 -0.72 -0.31 a 2.75 2.75 0 0 0 -0.79 0.11 a 4 4 0 0 0 -0.83 0.37 a 0.26 0.26 0 0 1 -0.16 0 a 0.1 0.1 0 0 1 -0.1 -0.09 l -0.14 -0.76 a 0.11 0.11 0 0 1 0 -0.1 a 0.37 0.37 0 0 1 0.13 -0.11 a 5.61 5.61 0 0 1 0.87 -0.36 a 3.06 3.06 0 0 1 1 -0.16 a 2.79 2.79 0 0 1 0.88 0.13 a 2 2 0 0 1 0.7 0.4 a 1.85 1.85 0 0 1 0.46 0.66 a 2.32 2.32 0 0 1 0.17 0.92 a 2.29 2.29 0 0 1 -0.2 0.91 a 5.52 5.52 0 0 1 -0.5 0.91 a 10.13 10.13 0 0 1 -0.64 0.85 l -0.62 0.73 l -0.46 0.56 l -0.49 0.57 H 385 c 0.09 0 0.14 0.05 0.14 0.16 v 0.7 c 0 0.11 0 0.17 -0.14 0.17 Z"></path>
      </g>
      <g className={cn("fullTooth", "g41", charting["t41"])} data-for={`indTooth_${chartType}`} data-tip='t41' data-event='click' onClick={onClick && (() => onClick('t41'))}>
        <path className="ipr41 tooth ipr_t on" id="t41" transform="translate(-291.84 -167)" d="M 410.63 563.64 s -2.4 -6.09 -3.5 -9.53 s -2.56 -7.66 -5.3 -7.32 s -3.57 3 -5.42 7.19 s -5.18 9.63 -4 12.19 s 3.11 5.13 8.17 5.34 S 411.72 571.56 410.63 563.64 Z"></path>
        <path className="tnum on" transform="translate(-291.84 -167)" d="M 400.24 564.22 v 1.51 a 0.24 0.24 0 0 1 0 0.14 s -0.07 0 -0.14 0 h -0.72 a 0.19 0.19 0 0 1 -0.14 0 a 0.21 0.21 0 0 1 0 -0.14 v -1.51 H 396 a 0.26 0.26 0 0 1 -0.13 0 s 0 0 0 -0.1 v -0.73 a 0.28 0.28 0 0 1 0 -0.14 l 0.08 -0.12 l 3.08 -4.63 l 0.08 -0.1 a 0.2 0.2 0 0 1 0.12 0 h 0.91 c 0.05 0 0.08 0 0.09 0 a 0.47 0.47 0 0 1 0 0.12 v 4.7 h 0.93 a 0.2 0.2 0 0 1 0.12 0 s 0 0.06 0 0.13 v 0.73 c 0 0.07 0 0.11 0 0.12 a 0.2 0.2 0 0 1 -0.12 0 Z m -3.21 -1 h 2.14 v -3.25 Z"></path>
        <path className="tnum on" transform="translate(-291.84 -167)" d="M 403.7 565.91 c -0.09 0 -0.14 0 -0.14 -0.13 v -5.9 l -0.74 0.64 a 0.25 0.25 0 0 1 -0.11 0 a 0.12 0.12 0 0 1 -0.11 0 l -0.45 -0.54 a 0.14 0.14 0 0 1 0 -0.09 a 0.17 0.17 0 0 1 0.05 -0.1 l 1.52 -1.4 l 0.07 0 l 0.08 0 h 0.53 a 0.35 0.35 0 0 1 0.2 0 a 0.43 0.43 0 0 1 0 0.23 v 7.17 a 0.11 0.11 0 0 1 -0.13 0.13 Z"></path>
      </g>
      <g className={cn("fullTooth", "g31", charting["t31"])} data-for={`indTooth_${chartType}`} data-tip='t31' data-event='click' onClick={onClick && (() => onClick('t31'))}>
        <path className="ipr31 tooth ipr_t on" id="t31" transform="translate(-291.84 -167)" d="M 412.08 563.68 s 2.4 -6.09 3.5 -9.53 s 2.56 -7.66 5.3 -7.32 s 3.57 3 5.42 7.19 s 5.18 9.63 4 12.19 s -3.11 5.13 -8.17 5.34 S 411 571.6 412.08 563.68 Z"></path>
        <path className="tnum on" transform="translate(-291.84 -167)" d="M 420.24 565.39 a 2.42 2.42 0 0 1 -0.91 0.45 a 4.2 4.2 0 0 1 -1.11 0.14 a 5.06 5.06 0 0 1 -0.86 -0.07 a 5 5 0 0 1 -0.8 -0.2 a 0.3 0.3 0 0 1 -0.14 -0.08 a 0.17 0.17 0 0 1 0 -0.13 l 0.09 -0.75 a 0.15 0.15 0 0 1 0.06 -0.12 s 0.1 0 0.19 0 l 0.52 0.15 a 2.62 2.62 0 0 0 0.53 0.09 l 0.43 0 a 2.66 2.66 0 0 0 0.43 0 a 2 2 0 0 0 0.41 -0.09 a 1.38 1.38 0 0 0 0.36 -0.18 a 1.13 1.13 0 0 0 0.48 -1 a 1 1 0 0 0 -0.1 -0.44 a 1.05 1.05 0 0 0 -0.32 -0.38 a 1.65 1.65 0 0 0 -0.56 -0.26 a 3.1 3.1 0 0 0 -0.82 -0.09 h -0.5 a 0.16 0.16 0 0 1 -0.11 0 a 0.16 0.16 0 0 1 0 -0.1 v -0.74 c 0 -0.06 0 -0.1 0 -0.11 a 0.18 0.18 0 0 1 0.11 0 h 0.48 a 2.65 2.65 0 0 0 0.53 -0.06 a 2.31 2.31 0 0 0 0.6 -0.21 a 1.69 1.69 0 0 0 0.49 -0.38 a 0.81 0.81 0 0 0 0.2 -0.56 a 0.78 0.78 0 0 0 -0.13 -0.45 a 1 1 0 0 0 -0.33 -0.31 a 1.53 1.53 0 0 0 -0.44 -0.17 a 2 2 0 0 0 -0.45 -0.05 l -0.32 0 l -0.38 0.08 l -0.39 0.1 l -0.36 0.12 a 0.23 0.23 0 0 1 -0.17 0 a 0.15 0.15 0 0 1 -0.07 -0.11 l -0.09 -0.73 a 0.13 0.13 0 0 1 0 -0.11 a 0.26 0.26 0 0 1 0.14 -0.09 a 6.78 6.78 0 0 1 0.86 -0.25 a 4 4 0 0 1 0.84 -0.09 a 3.56 3.56 0 0 1 0.88 0.11 a 2.39 2.39 0 0 1 0.77 0.34 a 1.79 1.79 0 0 1 0.54 0.6 a 1.75 1.75 0 0 1 0.21 0.88 a 1.6 1.6 0 0 1 -0.09 0.54 a 2.08 2.08 0 0 1 -0.67 0.94 a 2.25 2.25 0 0 1 -0.52 0.31 a 2.47 2.47 0 0 1 1 0.73 a 1.59 1.59 0 0 1 0.34 1 a 2.26 2.26 0 0 1 -0.2 1 A 2.1 2.1 0 0 1 420.24 565.39 Z"></path>
        <path className="tnum on" transform="translate(-291.84 -167)" d="M 423.93 565.91 c -0.09 0 -0.14 0 -0.14 -0.13 v -5.9 l -0.74 0.64 a 0.25 0.25 0 0 1 -0.12 0 a 0.11 0.11 0 0 1 -0.1 0 l -0.45 -0.54 a 0.13 0.13 0 0 1 0 -0.09 a 0.16 0.16 0 0 1 0.05 -0.1 l 1.52 -1.4 l 0.07 0 l 0.08 0 h 0.53 c 0.11 0 0.18 0 0.2 0 a 0.43 0.43 0 0 1 0 0.23 v 7.17 a 0.11 0.11 0 0 1 -0.13 0.13 Z"></path>
      </g>
      <g className={cn("fullTooth", "g32", charting["t32"])} data-for={`indTooth_${chartType}`} data-tip='t32' data-event='click' onClick={onClick && (() => onClick('t32'))}>
        <path className="ipr32 tooth ipr_t on" id="t32" transform="translate(-291.84 -167)" d="M 433.13 556.2 a 23.93 23.93 0 0 0 1.18 -6.26 c 0 -2.24 2.5 -6.1 6.37 -4.82 s 6.09 5.42 8.36 7.52 s 7.72 6 4 10.44 s -9.67 6.64 -13.63 5.85 s -8.65 -2.16 -7.6 -9.59 C 432.15 558 432.73 557.54 433.13 556.2 Z"></path>
        <path className="tnum on" transform="translate(-291.84 -167)" d="M 440.74 561.72 a 2.41 2.41 0 0 1 -0.91 0.45 a 4.2 4.2 0 0 1 -1.11 0.14 a 5.06 5.06 0 0 1 -0.86 -0.07 a 5 5 0 0 1 -0.8 -0.2 a 0.31 0.31 0 0 1 -0.14 -0.08 a 0.17 0.17 0 0 1 0 -0.13 l 0.09 -0.75 A 0.14 0.14 0 0 1 437 561 s 0.1 0 0.19 0 l 0.52 0.15 a 2.63 2.63 0 0 0 0.53 0.09 l 0.43 0 a 2.66 2.66 0 0 0 0.43 0 a 2 2 0 0 0 0.41 -0.09 a 1.38 1.38 0 0 0 0.36 -0.18 a 1.13 1.13 0 0 0 0.48 -1 a 1 1 0 0 0 -0.1 -0.44 a 1.05 1.05 0 0 0 -0.32 -0.38 a 1.65 1.65 0 0 0 -0.56 -0.26 a 3.11 3.11 0 0 0 -0.82 -0.09 h -0.5 a 0.1 0.1 0 0 1 -0.13 -0.13 v -0.74 c 0 -0.06 0 -0.1 0 -0.11 a 0.18 0.18 0 0 1 0.1 0 h 0.48 a 2.63 2.63 0 0 0 0.53 -0.06 a 2.33 2.33 0 0 0 0.6 -0.21 a 1.68 1.68 0 0 0 0.49 -0.38 a 0.81 0.81 0 0 0 0.21 -0.56 a 0.77 0.77 0 0 0 -0.13 -0.45 a 1 1 0 0 0 -0.33 -0.31 a 1.55 1.55 0 0 0 -0.44 -0.17 a 2 2 0 0 0 -0.45 -0.05 l -0.32 0 l -0.38 0.08 l -0.39 0.1 l -0.36 0.12 a 0.22 0.22 0 0 1 -0.17 0 a 0.16 0.16 0 0 1 -0.07 -0.11 l -0.09 -0.73 a 0.13 0.13 0 0 1 0 -0.11 a 0.26 0.26 0 0 1 0.14 -0.09 a 6.69 6.69 0 0 1 0.86 -0.25 a 4 4 0 0 1 0.84 -0.09 a 3.56 3.56 0 0 1 0.88 0.11 a 2.38 2.38 0 0 1 0.77 0.34 a 1.79 1.79 0 0 1 0.54 0.6 a 1.74 1.74 0 0 1 0.21 0.88 a 1.6 1.6 0 0 1 -0.09 0.54 a 2 2 0 0 1 -0.26 0.51 a 2.07 2.07 0 0 1 -0.41 0.43 a 2.3 2.3 0 0 1 -0.52 0.31 a 2.48 2.48 0 0 1 1 0.73 a 1.6 1.6 0 0 1 0.34 1 a 2.26 2.26 0 0 1 -0.2 1 A 2.08 2.08 0 0 1 440.74 561.72 Z"></path>
        <path className="tnum on" transform="translate(-291.84 -167)" d="M 442.34 562.25 c -0.13 0 -0.19 0 -0.19 -0.13 v -0.56 a 0.33 0.33 0 0 1 0 -0.16 l 0.1 -0.16 l 1.51 -1.79 A 14.16 14.16 0 0 0 445 557.8 a 2.24 2.24 0 0 0 0.4 -1.08 a 1.1 1.1 0 0 0 -0.29 -0.79 a 1 1 0 0 0 -0.72 -0.31 a 2.76 2.76 0 0 0 -0.79 0.11 a 4 4 0 0 0 -0.83 0.37 a 0.25 0.25 0 0 1 -0.16 0 a 0.1 0.1 0 0 1 -0.1 -0.09 l -0.14 -0.76 a 0.1 0.1 0 0 1 0 -0.1 a 0.38 0.38 0 0 1 0.13 -0.11 a 5.62 5.62 0 0 1 0.87 -0.36 a 3.07 3.07 0 0 1 1 -0.16 a 2.79 2.79 0 0 1 0.88 0.13 a 2 2 0 0 1 0.7 0.4 a 1.83 1.83 0 0 1 0.46 0.66 a 2.35 2.35 0 0 1 0 1.83 a 5.52 5.52 0 0 1 -0.5 0.91 a 10.12 10.12 0 0 1 -0.64 0.85 l -0.62 0.73 l -0.46 0.56 l -0.49 0.57 h 2.92 c 0.09 0 0.14 0.05 0.14 0.16 v 0.7 c 0 0.11 0 0.17 -0.14 0.17 Z"></path>
      </g>
      <g className={cn("fullTooth", "g33", charting["t33"])} data-for={`indTooth_${chartType}`} data-tip='t33' data-event='click' onClick={onClick && (() => onClick('t33'))}>
        <path className="ipr33 tooth ipr_t on" id="t33" transform="translate(-291.84 -167)" d="M 452.6 532.56 a 5.28 5.28 0 0 1 5.94 -0.52 c 3.47 1.77 4.37 1.46 8.63 2.93 s 6.15 0.06 7 5.88 s 1.58 7.75 0.22 10.41 s -4.1 5.73 -10.15 6.15 s -12 -0.81 -13.54 -10.32 S 451.13 534.44 452.6 532.56 Z"></path>
        <path className="tnum on" transform="translate(-291.84 -167)" d="M 461.56 548.63 a 2.42 2.42 0 0 1 -0.91 0.45 a 4.2 4.2 0 0 1 -1.11 0.14 a 5.06 5.06 0 0 1 -0.86 -0.07 a 5 5 0 0 1 -0.8 -0.2 a 0.3 0.3 0 0 1 -0.14 -0.08 a 0.17 0.17 0 0 1 0 -0.13 l 0.09 -0.75 a 0.15 0.15 0 0 1 0.06 -0.12 s 0.1 0 0.19 0 l 0.52 0.15 a 2.62 2.62 0 0 0 0.53 0.09 l 0.43 0 a 2.66 2.66 0 0 0 0.43 0 a 2 2 0 0 0 0.41 -0.09 a 1.38 1.38 0 0 0 0.36 -0.18 a 1.13 1.13 0 0 0 0.48 -1 a 1 1 0 0 0 -0.1 -0.44 a 1.05 1.05 0 0 0 -0.32 -0.38 a 1.65 1.65 0 0 0 -0.56 -0.26 a 3.1 3.1 0 0 0 -0.82 -0.09 h -0.5 a 0.16 0.16 0 0 1 -0.11 0 a 0.16 0.16 0 0 1 0 -0.1 v -0.74 c 0 -0.06 0 -0.1 0 -0.11 a 0.18 0.18 0 0 1 0.11 0 h 0.48 a 2.65 2.65 0 0 0 0.53 -0.06 a 2.31 2.31 0 0 0 0.6 -0.21 a 1.69 1.69 0 0 0 0.49 -0.38 a 0.81 0.81 0 0 0 0.2 -0.56 a 0.78 0.78 0 0 0 -0.13 -0.45 a 1 1 0 0 0 -0.33 -0.31 a 1.53 1.53 0 0 0 -0.44 -0.17 a 2 2 0 0 0 -0.45 -0.05 l -0.32 0 l -0.38 0.08 l -0.39 0.1 l -0.36 0.12 a 0.23 0.23 0 0 1 -0.17 0 a 0.15 0.15 0 0 1 -0.07 -0.11 l -0.09 -0.73 a 0.13 0.13 0 0 1 0 -0.11 a 0.26 0.26 0 0 1 0.14 -0.09 a 6.78 6.78 0 0 1 0.86 -0.25 a 4 4 0 0 1 0.84 -0.09 a 3.56 3.56 0 0 1 0.88 0.11 a 2.39 2.39 0 0 1 0.77 0.34 a 1.79 1.79 0 0 1 0.54 0.6 a 1.75 1.75 0 0 1 0.21 0.88 a 1.6 1.6 0 0 1 -0.09 0.54 a 2.08 2.08 0 0 1 -0.67 0.94 a 2.25 2.25 0 0 1 -0.52 0.31 a 2.47 2.47 0 0 1 1 0.73 a 1.59 1.59 0 0 1 0.34 1 a 2.26 2.26 0 0 1 -0.2 1 A 2.1 2.1 0 0 1 461.56 548.63 Z"></path>
        <path className="tnum on" transform="translate(-291.84 -167)" d="M 466.84 548.63 a 2.41 2.41 0 0 1 -0.91 0.45 a 4.21 4.21 0 0 1 -1.11 0.14 a 5.19 5.19 0 0 1 -1.66 -0.28 a 0.29 0.29 0 0 1 -0.14 -0.08 a 0.16 0.16 0 0 1 0 -0.13 l 0.09 -0.75 a 0.15 0.15 0 0 1 0.06 -0.12 s 0.1 0 0.19 0 l 0.52 0.15 a 2.6 2.6 0 0 0 0.53 0.09 l 0.43 0 a 2.67 2.67 0 0 0 0.43 0 a 2 2 0 0 0 0.41 -0.09 a 1.41 1.41 0 0 0 0.36 -0.18 a 1.13 1.13 0 0 0 0.48 -1 a 1 1 0 0 0 -0.1 -0.44 a 1.05 1.05 0 0 0 -0.32 -0.38 a 1.63 1.63 0 0 0 -0.56 -0.26 a 3.09 3.09 0 0 0 -0.82 -0.09 h -0.5 a 0.1 0.1 0 0 1 -0.13 -0.13 v -0.74 c 0 -0.06 0 -0.1 0 -0.11 a 0.18 0.18 0 0 1 0.1 0 h 0.48 a 2.66 2.66 0 0 0 0.53 -0.06 a 2.34 2.34 0 0 0 0.6 -0.21 a 1.71 1.71 0 0 0 0.49 -0.38 a 0.81 0.81 0 0 0 0.2 -0.56 a 0.78 0.78 0 0 0 -0.13 -0.45 a 1 1 0 0 0 -0.33 -0.31 a 1.52 1.52 0 0 0 -0.44 -0.17 a 2 2 0 0 0 -0.45 -0.05 l -0.32 0 l -0.38 0.08 l -0.39 0.1 l -0.36 0.12 a 0.22 0.22 0 0 1 -0.17 0 a 0.15 0.15 0 0 1 -0.07 -0.11 l -0.09 -0.73 a 0.13 0.13 0 0 1 0 -0.11 a 0.26 0.26 0 0 1 0.14 -0.09 a 6.8 6.8 0 0 1 0.86 -0.25 a 4 4 0 0 1 0.84 -0.09 a 3.57 3.57 0 0 1 0.88 0.11 a 2.37 2.37 0 0 1 0.77 0.34 a 1.78 1.78 0 0 1 0.54 0.6 a 1.74 1.74 0 0 1 0.21 0.88 a 1.62 1.62 0 0 1 -0.09 0.54 a 2.12 2.12 0 0 1 -0.26 0.51 a 2.07 2.07 0 0 1 -0.41 0.43 a 2.28 2.28 0 0 1 -0.52 0.31 a 2.46 2.46 0 0 1 1 0.73 a 1.58 1.58 0 0 1 0.34 1 a 2.25 2.25 0 0 1 -0.2 1 A 2.08 2.08 0 0 1 466.84 548.63 Z"></path>
      </g>
      <g className={cn("fullTooth", "g34", charting["t34"])} data-for={`indTooth_${chartType}`} data-tip='t34' data-event='click' onClick={onClick && (() => onClick('t34'))}>
        <path className="ipr34 tooth ipr_t" id="t34" transform="translate(-291.84 -167)" d="M 462.51 519.28 s -0.45 -6.32 3.36 -7.58 s 11.47 -2 15.55 -0.34 s 6 3.41 7.66 9.05 s 0.44 10.71 -4.71 12.43 s -12.55 4.88 -16.53 -1.89 S 462.43 521.59 462.51 519.28 Z"></path>
        <path className="tnum" transform="translate(-291.84 -167)" d="M 475.17 525.48 a 2.42 2.42 0 0 1 -0.91 0.45 a 4.2 4.2 0 0 1 -1.11 0.14 a 5.06 5.06 0 0 1 -0.86 -0.07 a 5 5 0 0 1 -0.8 -0.2 a 0.3 0.3 0 0 1 -0.14 -0.08 a 0.17 0.17 0 0 1 0 -0.13 l 0.09 -0.75 a 0.15 0.15 0 0 1 0.06 -0.12 s 0.1 0 0.19 0 l 0.52 0.15 a 2.62 2.62 0 0 0 0.53 0.09 l 0.43 0 a 2.66 2.66 0 0 0 0.43 0 a 2 2 0 0 0 0.41 -0.09 a 1.38 1.38 0 0 0 0.36 -0.18 a 1.13 1.13 0 0 0 0.48 -1 a 1 1 0 0 0 -0.1 -0.44 a 1.05 1.05 0 0 0 -0.32 -0.38 a 1.65 1.65 0 0 0 -0.56 -0.26 a 3.1 3.1 0 0 0 -0.82 -0.09 h -0.5 a 0.16 0.16 0 0 1 -0.11 0 a 0.16 0.16 0 0 1 0 -0.1 v -0.74 c 0 -0.06 0 -0.1 0 -0.11 a 0.18 0.18 0 0 1 0.11 0 H 473 a 2.65 2.65 0 0 0 0.53 -0.06 a 2.31 2.31 0 0 0 0.6 -0.21 a 1.69 1.69 0 0 0 0.49 -0.38 a 0.81 0.81 0 0 0 0.2 -0.56 a 0.78 0.78 0 0 0 -0.13 -0.45 a 1 1 0 0 0 -0.33 -0.31 a 1.53 1.53 0 0 0 -0.44 -0.17 a 2 2 0 0 0 -0.45 -0.05 l -0.32 0 l -0.38 0.08 l -0.39 0.1 l -0.36 0.12 a 0.23 0.23 0 0 1 -0.17 0 a 0.15 0.15 0 0 1 -0.07 -0.11 l -0.09 -0.73 a 0.13 0.13 0 0 1 0 -0.11 a 0.26 0.26 0 0 1 0.14 -0.09 a 6.78 6.78 0 0 1 0.86 -0.25 a 4 4 0 0 1 0.84 -0.09 a 3.56 3.56 0 0 1 0.88 0.11 a 2.39 2.39 0 0 1 0.77 0.34 a 1.79 1.79 0 0 1 0.54 0.6 a 1.75 1.75 0 0 1 0.21 0.88 a 1.6 1.6 0 0 1 -0.09 0.54 a 2.08 2.08 0 0 1 -0.67 0.94 a 2.25 2.25 0 0 1 -0.52 0.31 a 2.47 2.47 0 0 1 1 0.73 a 1.59 1.59 0 0 1 0.34 1 a 2.26 2.26 0 0 1 -0.2 1 A 2.1 2.1 0 0 1 475.17 525.48 Z"></path>
        <path className="tnum" transform="translate(-291.84 -167)" d="M 480.7 524.3 v 1.51 a 0.24 0.24 0 0 1 0 0.14 s -0.07 0 -0.14 0 h -0.72 a 0.19 0.19 0 0 1 -0.14 0 a 0.21 0.21 0 0 1 0 -0.14 V 524.3 h -3.19 a 0.26 0.26 0 0 1 -0.13 0 s 0 0 0 -0.1 v -0.73 a 0.27 0.27 0 0 1 0 -0.14 l 0.08 -0.12 l 3.08 -4.63 l 0.08 -0.1 a 0.19 0.19 0 0 1 0.12 0 h 0.91 c 0.05 0 0.08 0 0.09 0 a 0.45 0.45 0 0 1 0 0.12 v 4.7 h 0.93 a 0.2 0.2 0 0 1 0.12 0 s 0 0.06 0 0.13 v 0.73 c 0 0.07 0 0.11 0 0.12 a 0.2 0.2 0 0 1 -0.12 0 Z m -3.21 -1 h 2.14 V 520 Z"></path>
      </g>
      <g className={cn("fullTooth", "g35", charting["t35"])} data-for={`indTooth_${chartType}`} data-tip='t35' data-event='click' onClick={onClick && (() => onClick('t35'))}>
        <path className="ipr35 tooth ipr_t" id="t35" transform="translate(-291.84 -167)" d="M 471.9 487.95 s 1.1 -2.1 5 -2.39 s 9.21 -0.92 12.45 0.15 s 7 1.2 8.72 5.41 s 3.19 8.54 1.69 11.06 s -3.25 3.08 -6.11 5.32 s -3 2.24 -7.17 2.64 s -10.44 -1.85 -13.25 -6.77 S 469.19 492.36 471.9 487.95 Z"></path>
        <path className="tnum" transform="translate(-291.84 -167)" d="M 482.9 500 a 2.42 2.42 0 0 1 -0.91 0.45 a 4.2 4.2 0 0 1 -1.11 0.14 a 5.06 5.06 0 0 1 -0.86 -0.07 a 5 5 0 0 1 -0.8 -0.2 a 0.3 0.3 0 0 1 -0.14 -0.08 a 0.17 0.17 0 0 1 0 -0.13 l 0.09 -0.75 a 0.15 0.15 0 0 1 0.06 -0.12 s 0.1 0 0.19 0 l 0.52 0.15 a 2.62 2.62 0 0 0 0.53 0.09 l 0.43 0 a 2.66 2.66 0 0 0 0.43 0 a 2 2 0 0 0 0.41 -0.09 a 1.38 1.38 0 0 0 0.36 -0.18 a 1.13 1.13 0 0 0 0.48 -1 a 1 1 0 0 0 -0.1 -0.44 a 1.05 1.05 0 0 0 -0.32 -0.38 a 1.65 1.65 0 0 0 -0.56 -0.26 a 3.1 3.1 0 0 0 -0.82 -0.09 h -0.5 a 0.16 0.16 0 0 1 -0.11 0 a 0.16 0.16 0 0 1 0 -0.1 v -0.74 c 0 -0.06 0 -0.1 0 -0.11 a 0.18 0.18 0 0 1 0.11 0 h 0.48 a 2.65 2.65 0 0 0 0.53 -0.06 a 2.31 2.31 0 0 0 0.6 -0.21 a 1.69 1.69 0 0 0 0.49 -0.38 a 0.81 0.81 0 0 0 0.2 -0.56 a 0.78 0.78 0 0 0 -0.13 -0.45 a 1 1 0 0 0 -0.33 -0.31 a 1.53 1.53 0 0 0 -0.44 -0.17 a 2 2 0 0 0 -0.45 -0.05 l -0.32 0 l -0.38 0.08 l -0.39 0.1 l -0.36 0.12 a 0.23 0.23 0 0 1 -0.17 0 a 0.15 0.15 0 0 1 -0.07 -0.11 l -0.09 -0.73 a 0.13 0.13 0 0 1 0 -0.11 a 0.26 0.26 0 0 1 0.14 -0.09 a 6.78 6.78 0 0 1 0.86 -0.25 a 4 4 0 0 1 0.84 -0.09 a 3.56 3.56 0 0 1 0.88 0.11 a 2.39 2.39 0 0 1 0.77 0.34 a 1.79 1.79 0 0 1 0.54 0.6 a 1.75 1.75 0 0 1 0.21 0.88 a 1.6 1.6 0 0 1 -0.09 0.54 a 2.08 2.08 0 0 1 -0.67 0.94 a 2.25 2.25 0 0 1 -0.52 0.31 a 2.47 2.47 0 0 1 1 0.73 a 1.59 1.59 0 0 1 0.34 1 a 2.26 2.26 0 0 1 -0.2 1 A 2.1 2.1 0 0 1 482.9 500 Z"></path>
        <path className="tnum" transform="translate(-291.84 -167)" d="M 488.35 499.92 a 2.26 2.26 0 0 1 -0.88 0.49 a 3.7 3.7 0 0 1 -1.08 0.16 h -0.3 l -0.44 0 l -0.47 -0.09 a 1.78 1.78 0 0 1 -0.39 -0.13 s -0.07 -0.05 -0.07 -0.07 a 0.69 0.69 0 0 1 0 -0.1 v -0.81 c 0 -0.12 0.07 -0.15 0.21 -0.08 a 1.64 1.64 0 0 0 0.35 0.13 l 0.41 0.09 l 0.41 0.06 l 0.38 0 a 1.61 1.61 0 0 0 1.1 -0.38 a 1.32 1.32 0 0 0 0.43 -1 a 1.46 1.46 0 0 0 -0.35 -1 a 1.53 1.53 0 0 0 -1.17 -0.38 a 3.35 3.35 0 0 0 -0.67 0.07 a 4.32 4.32 0 0 0 -0.66 0.19 q -0.39 0.16 -0.39 -0.18 l 0 -3.67 a 0.24 0.24 0 0 1 0 -0.14 s 0.08 0 0.18 0 h 3.64 a 0.12 0.12 0 0 1 0.09 0 a 0.13 0.13 0 0 1 0 0.09 v 0.77 a 0.15 0.15 0 0 1 0 0.09 a 0.11 0.11 0 0 1 -0.09 0 H 485.9 l 0 1.85 a 3.93 3.93 0 0 1 0.88 -0.09 a 2.78 2.78 0 0 1 0.94 0.15 a 2.22 2.22 0 0 1 0.76 0.45 a 2.11 2.11 0 0 1 0.51 0.71 a 2.29 2.29 0 0 1 0.18 0.94 A 2.48 2.48 0 0 1 488.35 499.92 Z"></path>
      </g>
      <g className={cn("fullTooth", "g36", charting["t36"])} data-for={`indTooth_${chartType}`} data-tip='t36' data-event='click' onClick={onClick && (() => onClick('t36'))}>
        <path className="ipr36 tooth ipr_t non_click" id="t36" transform="translate(-291.84 -167)" d="M 502.16 448.71 s 11.19 4.53 10.89 9.51 s -1.48 8.33 -1.51 11.75 s -0.86 13 -9.15 13.38 s -16 1.47 -20.46 -1.13 s -6.55 -5.36 -5.11 -10.78 s 3.36 -7.75 3.3 -10.75 s 1.19 -12.26 9.48 -12.54 S 496.94 447.37 502.16 448.71 Z"></path>
        <path className="tnum" transform="translate(-291.84 -167)" d="M 493.55 469 a 2.42 2.42 0 0 1 -0.91 0.45 a 4.2 4.2 0 0 1 -1.11 0.14 a 5.06 5.06 0 0 1 -0.86 -0.07 a 5 5 0 0 1 -0.8 -0.2 a 0.3 0.3 0 0 1 -0.14 -0.08 a 0.17 0.17 0 0 1 0 -0.13 l 0.09 -0.75 a 0.15 0.15 0 0 1 0.06 -0.12 s 0.1 0 0.19 0 l 0.52 0.15 a 2.62 2.62 0 0 0 0.53 0.09 l 0.43 0 a 2.66 2.66 0 0 0 0.43 0 a 2 2 0 0 0 0.41 -0.09 a 1.38 1.38 0 0 0 0.36 -0.18 a 1.13 1.13 0 0 0 0.48 -1 a 1 1 0 0 0 -0.1 -0.44 a 1.05 1.05 0 0 0 -0.32 -0.38 a 1.65 1.65 0 0 0 -0.56 -0.26 a 3.1 3.1 0 0 0 -0.82 -0.09 h -0.5 a 0.16 0.16 0 0 1 -0.11 0 a 0.16 0.16 0 0 1 0 -0.1 v -0.74 c 0 -0.06 0 -0.1 0 -0.11 a 0.18 0.18 0 0 1 0.11 0 h 0.48 a 2.65 2.65 0 0 0 0.53 -0.06 a 2.31 2.31 0 0 0 0.6 -0.21 a 1.69 1.69 0 0 0 0.49 -0.38 a 0.81 0.81 0 0 0 0.2 -0.56 a 0.78 0.78 0 0 0 -0.13 -0.45 a 1 1 0 0 0 -0.33 -0.31 a 1.53 1.53 0 0 0 -0.44 -0.17 a 2 2 0 0 0 -0.45 -0.05 l -0.32 0 l -0.38 0.08 l -0.39 0.1 l -0.36 0.12 a 0.23 0.23 0 0 1 -0.17 0 a 0.15 0.15 0 0 1 -0.07 -0.11 l -0.09 -0.73 a 0.13 0.13 0 0 1 0 -0.11 a 0.26 0.26 0 0 1 0.14 -0.09 a 6.78 6.78 0 0 1 0.86 -0.25 a 4 4 0 0 1 0.84 -0.09 a 3.56 3.56 0 0 1 0.88 0.11 a 2.39 2.39 0 0 1 0.77 0.34 a 1.79 1.79 0 0 1 0.54 0.6 a 1.75 1.75 0 0 1 0.21 0.88 a 1.6 1.6 0 0 1 -0.09 0.54 a 2.08 2.08 0 0 1 -0.67 0.94 a 2.25 2.25 0 0 1 -0.52 0.31 a 2.47 2.47 0 0 1 1 0.73 a 1.59 1.59 0 0 1 0.34 1 a 2.26 2.26 0 0 1 -0.2 1 A 2.1 2.1 0 0 1 493.55 469 Z"></path>
        <path className="tnum" transform="translate(-291.84 -167)" d="M 497.46 469.57 a 2.09 2.09 0 0 1 -1.81 -0.88 a 4.75 4.75 0 0 1 -0.63 -2.74 a 7.24 7.24 0 0 1 0.17 -1.59 a 3.61 3.61 0 0 1 0.56 -1.31 a 2.82 2.82 0 0 1 1 -0.89 a 3.38 3.38 0 0 1 1.56 -0.33 a 4.64 4.64 0 0 1 0.64 0 a 3.18 3.18 0 0 1 0.58 0.13 a 0.11 0.11 0 0 1 0.08 0.13 l 0 0.8 c 0 0.09 -0.07 0.12 -0.16 0.08 a 2.17 2.17 0 0 0 -0.55 -0.13 c -0.2 0 -0.41 0 -0.64 0 a 2 2 0 0 0 -0.88 0.17 a 1.74 1.74 0 0 0 -0.61 0.48 a 2.4 2.4 0 0 0 -0.39 0.73 a 4.93 4.93 0 0 0 -0.21 0.9 a 1.17 1.17 0 0 1 0.24 -0.23 a 1.89 1.89 0 0 1 0.34 -0.2 a 2.56 2.56 0 0 1 0.38 -0.14 a 1.44 1.44 0 0 1 0.39 -0.05 a 2.65 2.65 0 0 1 1 0.17 a 2.18 2.18 0 0 1 0.76 0.5 a 2.22 2.22 0 0 1 0.49 0.78 a 2.8 2.8 0 0 1 0.17 1 a 3 3 0 0 1 -0.17 1 a 2.36 2.36 0 0 1 -0.49 0.82 a 2.3 2.3 0 0 1 -0.78 0.55 A 2.55 2.55 0 0 1 497.46 469.57 Z m 0.1 -4 a 1.32 1.32 0 0 0 -1 0.39 a 1.41 1.41 0 0 0 -0.37 1 a 2 2 0 0 0 0.1 0.64 a 1.75 1.75 0 0 0 0.27 0.52 a 1.3 1.3 0 0 0 0.41 0.35 a 1.06 1.06 0 0 0 0.52 0.13 a 1.26 1.26 0 0 0 1 -0.42 a 1.91 1.91 0 0 0 0 -2.22 A 1.16 1.16 0 0 0 497.56 465.55 Z"></path>
      </g>
      <g className={cn("fullTooth", "g37", charting["t37"])} data-for={`indTooth_${chartType}`} data-tip='t37' data-event='click' onClick={onClick && (() => onClick('t37'))}>
        <path className="ipr37 tooth ipr_t non_click" id="t37" transform="translate(-291.84 -167)" d="M 506.59 412.21 s 8.59 1.92 9.24 6 s 0.4 5.74 2 9.63 s 0.69 12.74 -2 15.6 s -6 3.88 -9.86 4 s -13.19 -0.7 -17.57 -2.7 s -6.83 -4.62 -6 -10 s 3.45 -9 3.94 -12.27 s 6.47 -12 10.69 -11.16 S 503.55 411.28 506.59 412.21 Z"></path>
        <path className="tnum" transform="translate(-291.84 -167)" d="M 500.81 433.85 a 2.41 2.41 0 0 1 -0.91 0.45 a 4.21 4.21 0 0 1 -1.11 0.14 a 5.2 5.2 0 0 1 -1.66 -0.28 a 0.3 0.3 0 0 1 -0.14 -0.08 a 0.16 0.16 0 0 1 0 -0.13 l 0.09 -0.75 a 0.15 0.15 0 0 1 0.06 -0.12 s 0.1 0 0.19 0 l 0.52 0.15 a 2.61 2.61 0 0 0 0.53 0.09 l 0.43 0 a 2.67 2.67 0 0 0 0.43 0 a 2 2 0 0 0 0.41 -0.09 a 1.41 1.41 0 0 0 0.36 -0.18 a 1.13 1.13 0 0 0 0.48 -1 a 1 1 0 0 0 -0.11 -0.44 a 1.05 1.05 0 0 0 -0.32 -0.38 a 1.63 1.63 0 0 0 -0.56 -0.26 a 3.11 3.11 0 0 0 -0.82 -0.09 h -0.5 a 0.15 0.15 0 0 1 -0.1 0 a 0.15 0.15 0 0 1 0 -0.1 v -0.74 c 0 -0.06 0 -0.1 0 -0.11 a 0.17 0.17 0 0 1 0.1 0 h 0.48 a 2.64 2.64 0 0 0 0.53 -0.06 a 2.36 2.36 0 0 0 0.6 -0.21 a 1.71 1.71 0 0 0 0.49 -0.38 a 0.81 0.81 0 0 0 0.2 -0.56 a 0.77 0.77 0 0 0 -0.13 -0.45 A 1 1 0 0 0 500 428 a 1.54 1.54 0 0 0 -0.44 -0.17 a 2 2 0 0 0 -0.45 -0.05 l -0.32 0 l -0.38 0.08 l -0.39 0.1 l -0.36 0.12 a 0.22 0.22 0 0 1 -0.17 0 a 0.15 0.15 0 0 1 -0.07 -0.11 l -0.09 -0.73 a 0.13 0.13 0 0 1 0 -0.11 a 0.27 0.27 0 0 1 0.14 -0.09 a 6.7 6.7 0 0 1 0.86 -0.25 a 4 4 0 0 1 0.84 -0.09 a 3.57 3.57 0 0 1 0.88 0.11 a 2.37 2.37 0 0 1 0.77 0.34 a 1.78 1.78 0 0 1 0.54 0.6 a 1.73 1.73 0 0 1 0.21 0.88 a 1.62 1.62 0 0 1 -0.09 0.54 a 2.07 2.07 0 0 1 -0.67 0.94 a 2.32 2.32 0 0 1 -0.52 0.31 a 2.47 2.47 0 0 1 1 0.73 a 1.59 1.59 0 0 1 0.34 1 a 2.25 2.25 0 0 1 -0.21 1 A 2.07 2.07 0 0 1 500.81 433.85 Z"></path>
        <path className="tnum" transform="translate(-291.84 -167)" d="M 504.26 434.23 a 0.33 0.33 0 0 1 -0.08 0.11 a 0.25 0.25 0 0 1 -0.14 0 h -0.81 a 0.32 0.32 0 0 1 -0.19 0 s 0 -0.07 0 -0.14 l 2.6 -6.36 h -3.18 a 0.17 0.17 0 0 1 -0.12 0 a 0.16 0.16 0 0 1 0 -0.12 V 427 c 0 -0.11 0.06 -0.16 0.17 -0.16 h 4.24 a 0.35 0.35 0 0 1 0.2 0 a 0.21 0.21 0 0 1 0 0.14 v 0.61 a 0.3 0.3 0 0 1 -0.05 0.16 Z"></path>
      </g>
      <g className={cn("fullTooth", "g38", charting["t38"])} data-for={`indTooth_${chartType}`} data-tip='t38' data-event='click' onClick={onClick && (() => onClick('t38'))}>
        <path className="ipr38 tooth ipr_t non_click" id="t38" transform="translate(-291.84 -167)" d="M 512.22 379.57 s 9.88 3.57 9.28 8.21 s -1 5.63 -0.76 10.1 s -1.27 12.36 -4.76 13 s -16.93 1.06 -21 -4.73 S 492.9 374 512.22 379.57 Z"></path>
        <path className="tnum" transform="translate(-291.84 -167)" d="M 505.22 398.92 a 2.41 2.41 0 0 1 -0.91 0.45 a 4.2 4.2 0 0 1 -1.11 0.14 a 5.06 5.06 0 0 1 -0.86 -0.07 a 5 5 0 0 1 -0.8 -0.2 a 0.31 0.31 0 0 1 -0.14 -0.08 a 0.17 0.17 0 0 1 0 -0.13 l 0.09 -0.75 a 0.14 0.14 0 0 1 0.06 -0.12 s 0.1 0 0.19 0 l 0.52 0.15 a 2.63 2.63 0 0 0 0.53 0.09 l 0.43 0 a 2.66 2.66 0 0 0 0.43 0 a 2 2 0 0 0 0.41 -0.09 a 1.38 1.38 0 0 0 0.36 -0.18 a 1.13 1.13 0 0 0 0.48 -1 a 1 1 0 0 0 -0.1 -0.44 a 1.05 1.05 0 0 0 -0.32 -0.38 a 1.65 1.65 0 0 0 -0.56 -0.26 A 3.11 3.11 0 0 0 503 396 h -0.5 a 0.1 0.1 0 0 1 -0.13 -0.13 v -0.74 c 0 -0.06 0 -0.1 0 -0.11 a 0.18 0.18 0 0 1 0.1 0 H 503 a 2.63 2.63 0 0 0 0.53 -0.06 a 2.33 2.33 0 0 0 0.6 -0.21 a 1.68 1.68 0 0 0 0.49 -0.38 a 0.81 0.81 0 0 0 0.21 -0.56 a 0.77 0.77 0 0 0 -0.13 -0.45 a 1 1 0 0 0 -0.33 -0.31 a 1.55 1.55 0 0 0 -0.44 -0.17 a 2 2 0 0 0 -0.45 -0.05 l -0.32 0 l -0.38 0.08 l -0.39 0.1 l -0.36 0.12 a 0.22 0.22 0 0 1 -0.17 0 a 0.16 0.16 0 0 1 -0.07 -0.11 l -0.09 -0.73 a 0.13 0.13 0 0 1 0 -0.11 a 0.26 0.26 0 0 1 0.14 -0.09 a 6.69 6.69 0 0 1 0.86 -0.25 a 4 4 0 0 1 0.84 -0.09 a 3.56 3.56 0 0 1 0.88 0.11 a 2.38 2.38 0 0 1 0.77 0.34 a 1.79 1.79 0 0 1 0.54 0.6 a 1.74 1.74 0 0 1 0.21 0.88 a 1.6 1.6 0 0 1 -0.09 0.54 a 2 2 0 0 1 -0.26 0.51 a 2.07 2.07 0 0 1 -0.41 0.43 a 2.3 2.3 0 0 1 -0.52 0.31 a 2.48 2.48 0 0 1 1 0.73 a 1.6 1.6 0 0 1 0.34 1 a 2.26 2.26 0 0 1 -0.2 1 A 2.08 2.08 0 0 1 505.22 398.92 Z"></path>
        <path className="tnum" transform="translate(-291.84 -167)" d="M 509.12 399.52 a 3.14 3.14 0 0 1 -0.9 -0.13 a 2.59 2.59 0 0 1 -0.81 -0.4 a 2.1 2.1 0 0 1 -0.59 -0.67 a 1.88 1.88 0 0 1 -0.23 -0.94 a 1.62 1.62 0 0 1 0.12 -0.65 a 1.88 1.88 0 0 1 0.33 -0.51 a 2.48 2.48 0 0 1 0.48 -0.4 a 5.76 5.76 0 0 1 0.57 -0.33 a 3.08 3.08 0 0 1 -0.95 -0.79 a 1.63 1.63 0 0 1 -0.35 -1 a 1.61 1.61 0 0 1 0.18 -0.77 a 1.88 1.88 0 0 1 0.5 -0.6 a 2.37 2.37 0 0 1 0.74 -0.39 a 2.86 2.86 0 0 1 0.9 -0.14 a 3.16 3.16 0 0 1 0.92 0.13 a 2.23 2.23 0 0 1 0.73 0.37 a 1.72 1.72 0 0 1 0.48 0.58 a 1.69 1.69 0 0 1 0.17 0.78 a 1.59 1.59 0 0 1 -0.31 0.95 a 3.88 3.88 0 0 1 -1 0.89 l 0.63 0.4 a 2.73 2.73 0 0 1 0.48 0.42 a 1.63 1.63 0 0 1 0.3 0.48 a 1.57 1.57 0 0 1 0.11 0.6 a 1.94 1.94 0 0 1 -0.78 1.61 a 2.53 2.53 0 0 1 -0.81 0.4 A 3.33 3.33 0 0 1 509.12 399.52 Z m 0 -3.48 l -0.45 0.16 a 2.21 2.21 0 0 0 -0.46 0.26 a 1.52 1.52 0 0 0 -0.36 0.38 a 0.94 0.94 0 0 0 -0.15 0.53 a 0.86 0.86 0 0 0 0.13 0.47 a 1.25 1.25 0 0 0 0.33 0.36 a 1.54 1.54 0 0 0 0.46 0.23 a 1.65 1.65 0 0 0 0.49 0.08 a 1.82 1.82 0 0 0 0.51 -0.07 a 1.42 1.42 0 0 0 0.45 -0.22 a 1.23 1.23 0 0 0 0.32 -0.36 a 0.91 0.91 0 0 0 0.13 -0.48 a 0.75 0.75 0 0 0 -0.12 -0.39 a 1.65 1.65 0 0 0 -0.33 -0.38 a 3.17 3.17 0 0 0 -0.45 -0.33 A 2.82 2.82 0 0 0 509.13 396 Z m 0 -3.27 a 1.31 1.31 0 0 0 -0.42 0.07 a 1.37 1.37 0 0 0 -0.38 0.19 a 1 1 0 0 0 -0.28 0.31 a 0.8 0.8 0 0 0 -0.11 0.42 a 0.7 0.7 0 0 0 0.14 0.41 a 2 2 0 0 0 0.33 0.37 a 2.73 2.73 0 0 0 0.39 0.29 a 2.31 2.31 0 0 0 0.33 0.17 a 1.93 1.93 0 0 0 0.35 -0.19 a 2.89 2.89 0 0 0 0.38 -0.31 a 2 2 0 0 0 0.31 -0.38 a 0.73 0.73 0 0 0 0.12 -0.38 a 0.77 0.77 0 0 0 -0.09 -0.37 a 1.06 1.06 0 0 0 -0.25 -0.31 a 1.16 1.16 0 0 0 -0.37 -0.21 A 1.36 1.36 0 0 0 509.12 392.76 Z"></path>
      </g>
    </svg>
  </div>
)

export { ToothSet };
