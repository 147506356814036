import { constants } from './constants';

const initState = {
  loading: false,
  error: false,
  loadingList: false,
  errorList: false,
  patientCount: 0,
  byId: {},
  currentPatientId: null,
  loadingAppointments: false,
  errorAppointments: false,
  loadingRequests: false,
  errorRequests: false,
  loadingRecords: false,
  errorRecords: false,
  loadMoreOffset: 0,
  requestCount: 0,
  currentPatientAppointments: [],
  currentPatientRequests: [],
  currentPatientRecords: [],
  currentPatientConversationId: null,
  newPatientFormModal: false,
};

export function patients(state = initState, action) {
  switch (action.type) {
    case constants.FETCH_PATIENTDETAILS:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case constants.FETCH_PATIENTDETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        byId: Object.assign({}, state.byId, { [action.data.id]: action.data.patient }),
      };

    case constants.FETCH_PATIENTDETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case constants.FETCH_PATIENTLIST_REQUEST:
      return {
        ...state,
        loadingList: true,
        errorList: false,
      };
    case constants.FETCH_PATIENTLIST_SUCCESS:
      return {
        ...state,
        patients: action.data.patientsList,
        loadingList: false,
        errorList: false,
        byId: Object.assign({}, state.byId, action.data.patients),
        patientCount: action.data.patientCount,
      };
    case constants.FETCH_PATIENTLIST_FAILURE:
      return {
        ...state,
        loadingList: false,
        error: action.error,
      };

    case constants.FETCH_PATIENTAPPOINTMENTS:
      return {
        ...state,
        loadingAppointments: true,
        errorAppointments: false,
      };
    case constants.FETCH_PATIENTAPPOINTMENTS_SUCCESS:
      return {
        ...state,
        loadingAppointments: false,
        errorAppointments: false,
        currentPatientAppointments: action.data.patient_appointments,
      };
    case constants.FETCH_PATIENTAPPOINTMENTS_FAILURE:
      return {
        ...state,
        loadingAppointments: false,
        errorAppointments: action.error,
      };
    case constants.FETCH_PATIENTREQUESTS:
      return {
        ...state,
        loadingRequests: true,
        errorRequests: false,
      };
    case constants.FETCH_PATIENTREQUESTS_SUCCESS:
      return {
        ...state,
        loadingRequests: false,
        errorRequests: false,
        currentPatientRequests: action.data.patient_requests,
      };
    case constants.FETCH_PATIENTREQUESTS_FAILURE:
      return {
        ...state,
        loadingRequests: false,
        errorRequests: action.error,
      };
    case constants.FETCH_PATIENTRECORDS:
      return {
        ...state,
        loadingRecords: true,
        errorRecords: false,
      };
    case constants.FETCH_PATIENTRECORDS_SUCCESS:
      return {
        ...state,
        loadingRecords: false,
        errorRecords: false,
        currentPatientRecords: action.data.patient_records,
      };
    case constants.FETCH_PATIENTRECORDS_FAILURE:
      return {
        ...state,
        loadingRecords: false,
        errorRecords: action.error,
      };
    case constants.UPDATE_CURRENTPATIENT:
      return {
        ...state,
        currentPatientId: action.data,
        currentPatientRequests: [],
        currentPatientRecords: [],
      };
    case constants.ADD_PATIENT:
      return {
        ...state,
        byId: Object.assign({}, state.byId, { [action.data.unique_id]: action.data }),
      };
    case constants.UPDATE_STATE:
      return {
        ...state,
        ...action.data,
      };
    default:
      return state;
  }
}
