import { createSelector } from 'reselect';
import {
  getCurrentRequestId,
  getCurrentPatientUid,
  getRequestDetails,
  getRecordDetails,
  getRequestCompletedAt,
  getReportSentAt,
  getRequestLoading,
  getReportLoading,
  getDetailsReportId,
} from '../../PatientDetailsPage/_redux/selectors';
//} from '../../RequestDetailPage/_redux/selectors';

import { getPatientReportById } from '../../PatientReports/_redux/selectors';
import { getPatientPartialScansById } from '../../PatientRecords/_redux/selectors';
import { getPatientsById } from '../../Patients/_redux/selectors';

const getCreateReportSlice = (state) => state.createReport;

export const getCurrentPage = createSelector([getCreateReportSlice], (state) => {
  return state.currentPage;
});

export const getReportDetails = createSelector(
  [getDetailsReportId, getPatientReportById],
  (reportId, reports) => {
    return reports[reportId];
  }
);

export const getPatientDetails = createSelector(
  [getCurrentPatientUid, getPatientsById],
  (uid, patients) => {
    return patients[uid] || {};
  }
);

export const getPartialScanChart = createSelector(
  [getCurrentRequestId, getPatientPartialScansById],
  (currentRequestId, partialScanCharts) => {
    return partialScanCharts[currentRequestId];
  }
);

export const getPartialScanRegionsList = createSelector([getPartialScanChart], (chart) => {
  return chart ? Object.keys(chart).filter((item) => chart[item]) : [];
});

export const getPartialScanRequested = createSelector([getRequestDetails], (request) => {
  return request?.extra_data?.partialscan_requested;
});

export const getPartialScanStatus = createSelector([getRecordDetails], (record) => {
  // go through each child record of type partial scan
  // then go though each child record's uploads to check if status is sent
  // if any are false, will return false, otherwise true and all partial scans received
  // reducer goes thorugh each partial scan and checks extra_data.status. true && true && etc...
  if (record === undefined) {
    return undefined;
  }
  return record?.child_user_records.length > 0
    ? record.child_user_records
        .filter((item) => item.type == 'PARTIALSC')
        .reduce((acc, curr) => {
          return (
            acc &&
            curr.record_uploads.reduce((c, d) => {
              return d.extra_data?.status && c;
            }, true)
          );
        }, true)
    : null;
});

export const getReportPartialScanStatus = createSelector(
  [getPartialScanStatus, getPartialScanRequested],
  (status, isRequested) => {
    if (status === undefined) {
      return undefined;
    }
    if (isRequested == null || (status === null && isRequested)) {
      return 'INIT'; // dentist needs to determine if partial scan is needed
    }
    if (isRequested == true) {
      return status ? 'COMPLETE' : 'WAITING'; // request sent, status dependent on patient
    } else {
      return 'NONE'; // no partial scan needed
    }
  }
);

/**
 * control modal state by checking status of requests and reports
 */
export const getReportModalState = createSelector(
  [getReportDetails, getReportPartialScanStatus],
  (report) => {
    if (report === undefined) {
      return undefined;
    } else if (report?.sent_at !== null || report?.status === 3) {
      return 3; // 3 is final page
    } else if (report?.status === 2) {
      // in local dentist review so return review page
      return 2;
    } else if (report?.status === 1) {
      // in internal review so return review page
      return 2;
    } else if (report?.status === 0) {
      return 1;
    } else {
      // prbably need to initialize a report first.
      return undefined;
    }
  }
);

export const getReportModalState2 = createSelector(
  [
    getPartialScanStatus,
    getPartialScanRequested,
    getRequestCompletedAt,
    getReportSentAt,
    getRequestLoading,
    getReportLoading,
  ],
  (partialScanStatus, partialScanRequested, compeletedAt, sentAt, reqLoading, reportLoading) => {
    if (reqLoading || reportLoading) {
      return null;
    } else if (compeletedAt != null || sentAt != null) {
      return 3;
    } else if (partialScanRequested == null) {
      // report not started
      return 0;
    } else if (partialScanRequested == false || partialScanStatus == true) {
      return 1;
    } else {
      // report not initiated
      return 0;
    }
  }
);
