
import React from 'react';
import { Icon } from '@chakra-ui/react';

const SearchIcon = (props) => {
  const iconWidth = props?.width || 15;
  const iconHeight = props?.height || 15;
  return (
    <Icon 
      viewBox="0 0 15 15" 
      color={'#405159'} 
      fill={'none'}
      {...props}
      width={`${iconWidth}px`}
      height={`${iconHeight}px`}
    >
        <path id="Stroke 1" fillRule="evenodd" clipRule="evenodd" d="M6.57031 10.5664C8.77968 10.5664 10.5703 8.77577 10.5703 6.56641C10.5703 4.35704 8.77968 2.56641 6.57031 2.56641C4.36129 2.56641 2.57031 4.3572 2.57031 6.56641C2.57031 8.77561 4.36129 10.5664 6.57031 10.5664ZM6.57031 3.69336C8.15836 3.69336 9.44531 4.98032 9.44531 6.56836C9.44531 8.1564 8.15836 9.44336 6.57031 9.44336C4.98259 9.44336 3.69531 8.15622 3.69531 6.56836C3.69531 4.9805 4.98259 3.69336 6.57031 3.69336Z" fill="currentColor"/>
        <path id="Stroke 3" d="M8.71875 9.49961L12.1562 12.3121L12.8686 11.4414L9.43114 8.62891L8.71875 9.49961Z" fill="currentColor"/>
        <rect id="Rectangle" opacity="0.01" width="15" height="15" fill="currentColor"/>
    </Icon>
  );
};
export default SearchIcon;
