import React, {useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { findIndex } from 'lodash';
import { localeFormatDate } from '../_helpers';
import { FaChevronDown, FaChevronLeft, FaChevronRight } from 'react-icons/fa';

// actions
import { actions as appointmentActions } from '../Appointments/_redux/actions';

// selectors
import { getUpcomingAppointments, getLoadingState, getErrorState, getCurrentAppointmentRequest } from '../Appointments/_redux/selectors';
import { getIntlMessages, getIntlLocale } from '../App/_redux/selectors';
import { getRequestDetails } from '../RequestDetailPage/_redux/selectors';

// styles
import { StyledUpcomingSection, StyledUpcomingItem } from './_common/styles';

const UpcomingItem = ({appointment}) => {
  const intlLocale = useSelector(getIntlLocale);
  const params = useParams();
  const currentRequestId = params.request_id;
  let selected = appointment.id == currentRequestId;
  if (appointment.id){
      return (
        <StyledUpcomingItem selected={selected} to={`/patients/${appointment.patient.unique_id}?mdl=appointment.VIDEOCHAT&rid=${appointment.request_unique_id}`}>
          <div className={'upcomingInfo'}>
            <div className={'upcomingTime'}>{localeFormatDate(appointment.start_date, intlLocale, "hh:mm A")}</div>
            <div className={'upcomingDate'}>{localeFormatDate(appointment.start_date, intlLocale, "MMM DD")}</div>
            <div className={'upcomingIcon'}><FaChevronDown /></div>
          </div>
          <div className={'upcomingInfoName'}><span>{appointment.title}</span></div>
        </StyledUpcomingItem>
      )
  } else {
    return (<span></span>)
  }
}

const PrevArrow = ({ onClick}) => {
  const handleClick = () => {
    onClick()
  }
  return (
    <div className='prevArrow' onClick={handleClick}><FaChevronLeft /></div>
  )
}

const NextArrow = ({onClick}) => {
  const handleClick = () => {
    onClick()
  }
  return (
    <div className='nextArrow' onClick={handleClick}><FaChevronRight /></div>
  )
}

export const UpcomingSection = () => {
  const dispatch = useDispatch();
  const intlMessages = useSelector(getIntlMessages);
  const currentLoading = useSelector(getLoadingState);
  const currentError = useSelector(getErrorState);
  const params = useParams();
  const [currentPageIndex, setCurrentPageIndex ] = useState(0);
  const currentRequestId = params.request_id;
  const appointments = useSelector(
    getUpcomingAppointments
  );
  const currentAppointment = useSelector(getCurrentAppointmentRequest);
  const requestDetails = useSelector(getRequestDetails);

  useEffect(() => {
    dispatch(appointmentActions.fetchAppointments());
  }, [])

  useEffect(()=>{
    if (appointments.length > 0){
      let appointmentIndex = findIndex(appointments, {id: Number(currentRequestId)});
      setCurrentPageIndex(Math.max(Math.floor(appointmentIndex/4), 0))
    }
  }, [currentRequestId])

  useEffect(()=> {

  }, [currentError])

  useEffect(()=> {

  }, [currentLoading])

  const prevClick = () => {
    currentPageIndex > 0 && setCurrentPageIndex(currentPageIndex-1)
  }

  const nextClick = () => {
    setCurrentPageIndex(currentPageIndex+1)
  }

  const renderView = () => {
    if ((currentRequestId != null) && currentAppointment){
      // request detail page, current request appointment
      return(
        <React.Fragment>
        {(appointments.length > 0)
          ? appointments.slice(currentPageIndex*4,(currentPageIndex+1)*4).map((appt) =>
              <UpcomingItem key={appt.id} appointment={appt} />)
          : <div className={'noAppointments'}>{intlMessages['appointmentList.norequests.message']}</div>
        }
        </React.Fragment>
      )
    } else if (currentRequestId != null && requestDetails){
      // request detail page, current request not appointment
      return(
        <React.Fragment>
          {/*<UpcomingItem appointment={requestDetails} />*/}
          { [requestDetails, ...appointments]
              .slice(currentPageIndex*4,(currentPageIndex+1)*4)
              .map((appt) => <UpcomingItem key={appt.id} appointment={appt} />)
          }
        </React.Fragment>
      )
    } else {
      // home page
      return(
        <React.Fragment>
        {(appointments.length > 0)
          ? appointments.slice(currentPageIndex*4,(currentPageIndex+1)*4).map((appt) =>
              <UpcomingItem key={appt.id}  appointment={appt} />)
          : <div className={'noAppointments'}>{intlMessages['appointmentList.norequests.message']}</div>
        }
        </React.Fragment>
      )
    }
  }

  return (
    <StyledUpcomingSection>
      {currentPageIndex > 0 && <PrevArrow onClick={prevClick} />}
      <div className={'upcomingWrapper'}>
        { renderView() }
      </div>
      { (currentPageIndex < (Math.ceil(appointments.length/4) -1)) && <NextArrow onClick={nextClick}/>}
    </StyledUpcomingSection>
  )
}
