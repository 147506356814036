import moment from 'moment';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { MOMENT_DATE_FORMAT } from '../_config';

dayjs.extend(localizedFormat);

export function formatDate(dateString, format = 'L', locale='en') {
  const date = dayjs(dateString);
  return date.locale(locale).format(format);
}

export function calculateAge(dateString) {
  const today = dayjs();
  const birthDate = dayjs(dateString);
  const age = today.diff(birthDate, 'year');

  return age;
}

/**
 * moment requires language with region or something did
 * what do i need to do for region
 */
export function localeFormatDate(time, locale, format) {
  var regionFormat = format || MOMENT_DATE_FORMAT;
  if (!time) {
    return '-';
  }
  //const actualTime = moment(time).locale(locale).format(regionFormat);
  return moment(time).locale(locale).format(regionFormat);
}

export function localeFormatDateAdd(time, days, locale, format) {
  var regionFormat = format || MOMENT_DATE_FORMAT;
  if (!time) {
    return '-';
  }
  //const actualTime = moment(time).locale(locale).format(regionFormat);
  return moment(time).add(days, 'days').locale(locale).format(regionFormat);
}

export function localeFormatFromNow(time, locale) {
  if (!time) {
    return '-';
  }
  //const actualTime = moment(time).locale(locale).fromNow();
  return moment(time).locale(locale).fromNow();
}

export function localeFormatChatListItem(time, locale) {
  var timeFormat = 'MMM D';
  if (!time) {
    return '-';
  }
  // TODO change this based on fromNow, same day, etc.
  return moment(parseInt(time) / 10000)
    .locale(locale)
    .format(timeFormat);
}

export function localeFormatMessageTime(time, locale) {
  var timeFormat = 'LT';
  if (!time) {
    return '';
  }
  return moment(parseInt(time) / 10000)
    .locale(locale)
    .format(timeFormat);
}

export function intervalRangeToTime(num) {
  if (num % 12 == 0) {
    return `12 ${num == 12 ? 'PM' : 'AM'}`;
  } else if (num > 12) {
    return `${num % 12} PM`;
  } else {
    return `${num} AM`;
  }
}

export function numberTo12Hour(num) {
  if (num % 12 == 0) {
    return `12`;
  } else if (num > 12) {
    return `${num % 12}`;
  } else {
    return `${num}`;
  }
}

export function getCurrentPubnubTimeToken() {
  return new Date().getTime() * 10000;
}
