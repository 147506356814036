import React, { useState, useEffect, useRef } from 'react';
import cn from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { startCase } from 'lodash'
import { BASE_URL } from '../_config';

// components
// actions
import { actions } from './_redux/actions';

import { usePubNub } from "pubnub-react";
// services
import { services as videoChatServices } from '../VideoChatPage/_redux/services';

// selectors

import { getIntlMessages, getProfileID, getUUID } from '../App/_redux/selectors';
import { getCurrentAppointmentRequest } from '../Appointments/_redux/selectors';

// styles
import { StyledCurrentReport } from './_common/styles';
import { consoleLog } from '../_helpers/consoleLog';
import { createChannelId, createPubnubMessageVideoChatPayload, endPubnubMessageVideoChatPayload } from '../_helpers';
import { getCurrentPatient } from '../Patients/_redux/selectors';


export const ReportVideoChat = ({onClose}) => {
  const intlMessages = useSelector(getIntlMessages);
  const dispatch = useDispatch();
  const location = useLocation();
  const pubnub = usePubNub();
  const userId = useSelector(getUUID);
  const patientId = useSelector(getCurrentPatient)
  const profileId = useSelector(getProfileID);
  const currentAppointmentRequest = useSelector(getCurrentAppointmentRequest);

  const [ loading, setLoading ] = useState(false);
  const [ showVideo, setShowVideo ] = useState(false);

  const [ room, setRoom ] = useState(null);
  const [ token, setToken ] = useState(null);

  const patientToken = useRef(null);
  const videoChatMessageId = useRef(null);
  let params = new URLSearchParams(location.search);

  let popupWindow = useRef(null)
  let currentRequestUid = currentAppointmentRequest?.unique_id

  // for a better way to open into new window...
  // https://stackoverflow.com/questions/47574490/open-a-component-in-new-window-on-a-click-in-react

  useEffect(()=>{
    // get the room name from api on load
    consoleLog("current parmas apid ", params.get("apid"))
    async function f(ruid){
      try{
        const resp = await videoChatServices.fetchAppointment(ruid);
        consoleLog("getting room: " + resp.room_name);
        if (resp.room_name != null){
          setRoom(resp.room_name);
        } else {
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
      } 
    }
    if (currentRequestUid && showVideo){
      setLoading(true);
      //f("8d6f0ef3-1c6d-4f8b-a577-700c9faf31c7");
      f(currentRequestUid);
    }
    return () => {};
  }, [currentRequestUid, showVideo])

  useEffect(()=>{
    // get the token after room information has been fetched
    if (room != null && showVideo){
      videoChatServices.fetchAppointmentVideoChatToken(currentRequestUid)
      //videoChatServices.fetchAppointmentVideoChatToken("8d6f0ef3-1c6d-4f8b-a577-700c9faf31c7") // testing
        .then(
          data => {
            consoleLog("getting token: " + data.token);
            if (data.token != null){
              setToken(data.token);
              patientToken.current = data.patient_token;
              setLoading(false);
              handleVideoOpen(data.token);
            } else {
              setLoading(false);
            }
          },
          () => {
            setLoading(false);
          }
        )
    }
  }, [room, showVideo])

  useEffect(()=> {
    if (popupWindow.current){
      popupWindow.current.addEventListener("beforeunload", handlePopupClose);
    }
  }, [popupWindow.current])

  const clickVideoOpen = (e) => {
    e.preventDefault();
    setShowVideo(true);
  }

  const sendVideoMessage = async () => {
    // HACK: maybe put this to actions, 
    const appMessage = {
      type: "videochat",
      senderId: userId,
      text: "DAILYv1", // add this because javascript frontend does not return empty string messages
      data: {
        vid: "DAILYv1",
        token: patientToken.current,
        room: room
      }
    }
    let currChannel = createChannelId(userId, patientId);
    let payload = createPubnubMessageVideoChatPayload(pubnub, appMessage, currChannel, profileId)
    try{
      const resp = await pubnub.publish({
        channel: currChannel,
        message: payload
      })
      // store timetoken/id to add action later
      videoChatMessageId.current = resp.timetoken 
    } catch (status){
      consoleLog(`ERROR sending message: ${status}`)
    }
  }

  const sendEndVideoMessage = async () => {
    consoleLog(`videochatEnded ${videoChatMessageId.current}`)
    let currChannel = createChannelId(userId, patientId);
    if (videoChatMessageId.current != null){
      //let data = {type: "status",value: "completed"}
      // cant manually encrypt message action
      //let encrypted = pubnub.encrypt(JSON.stringify({type: "status", value: "completed"}), process.env.REACT_APP_PUBNUB_CIPHER_KEY)
      // send message action to remove
      try {
        await pubnub.addMessageAction({
            channel: currChannel,
            messageTimetoken: videoChatMessageId.current,
            action: {type: "status", value: "completed"},
        });
      } catch (status) {
        consoleLog(`ERROR sending message action: ${status}`)
      }
      // send signal to update currently loaded messages because add message action
      // doesnt trigger the subscribe 
      let signalData = {t:"ma",mid:videoChatMessageId.current,d:{t:"s",v:"c"}}
      try {
         pubnub.signal({channel: currChannel,message: signalData});
      } catch (status) {
        consoleLog(`ERROR sending message action: ${status}`)
      }
    }
    const appMessage = {
      type: "videochat_end",
      senderId: userId,
      text: videoChatMessageId.current || ""
    }
    let payload = endPubnubMessageVideoChatPayload(pubnub, appMessage, currChannel, profileId)
    try{
      await pubnub.publish({
        channel: currChannel,
        message: payload
      })
    } catch (status){
      consoleLog(`ERROR sending message: ${status}`)
    }
  }

  const handleVideoOpen = (tok) => {
    //consoleLog("handlevideoopen", e, url)
    let popupParams = `status=no,location=no,toolbar=no,menubar=no,top=0,left=0,height=650,width=900`;

    let description = `Fayjay Boochu`//`${currentAppointmentRequest.first_name} ${currentAppointmentRequest.last_name}`;

    let tokUrl = BASE_URL + `/videochat?rm=${room}&t=${tok}&desc=${description}`;

    popupWindow.current = window.open(tokUrl, 'popup', popupParams);
    popupWindow.current.addEventListener("beforeunload", handlePopupClose);
    setShowVideo(true)
    // send message to patient in chat
    // url for token and room name?
    sendVideoMessage()
  }

  const handlePopupClose = () => {
    consoleLog("handlePopupClose")
    if (popupWindow.current == null){
      return;
    }
    if (popupWindow.current.closed){
      return;
    }
    sendEndVideoMessage();
    setShowVideo(false);
    dispatch(actions.openAppointmentCompleteModal());
    onClose();
    popupWindow.current.close();
  }

  const clickPopupClose = () => {
    consoleLog("clickPopupClose")
    if (popupWindow.current == null){
      return;
    }
    if (popupWindow.current.closed){
      return;
    }
    sendEndVideoMessage();
    setShowVideo(false);
    dispatch(actions.openAppointmentCompleteModal());
    onClose();
    popupWindow.current.close();
  }

  /*const handlePopupOpen = () => {
    // get room and token from video chat api
    var roomname = "uDVe82TZ9mS1a72ImleE"
    var token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoidURWZTgyVFo5bVMxYTcySW1sZUUiLCJ1IjoiRGVudGlzdCIsInVkIjoiMjNzQUExMiIsImQiOiIzNDgxZGYyMS00NDNlLTQ2M2YtOGUzNy1lZTI0ZGE4NmY1ZTEiLCJpYXQiOjE2MzI4MTM0NDd9.UENrYt3XBBPPpyVa0ir9nNS4qIVTkF-73wd_RaTK5SM";

    let newParams = new URLSearchParams();
    newParams.set("rm", roomname);
    newParams.set("t", token);
    history.push(`${BASE_URL}/videochat/${currentRequestUid}?${newParams.toString()}`)

  }*/

  var appointmentId = currentAppointmentRequest?.unique_id
  var roomname = currentAppointmentRequest?.room_name
  //var token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoidURWZTgyVFo5bVMxYTcySW1sZUUiLCJ1IjoiRGVudGlzdCIsInVkIjoiMjNzQUExMiIsImQiOiIzNDgxZGYyMS00NDNlLTQ2M2YtOGUzNy1lZTI0ZGE4NmY1ZTEiLCJpYXQiOjE2MzI4MTM0NDd9.UENrYt3XBBPPpyVa0ir9nNS4qIVTkF-73wd_RaTK5SM";

  let newParams = new URLSearchParams();
  newParams.set("rm", roomname);
  newParams.set("t", token);


  consoleLog("currentappointmentrequest ",  currentAppointmentRequest)
  consoleLog("currentRequestUid ", currentRequestUid)

  //let url = BASE_URL + `/videochat/${currentRequestUid}?${newParams.toString()}`;
  let url = BASE_URL + `/videochat?apid=${appointmentId}`


    return (
      <StyledCurrentReport>
        <div className={cn('videoChatSection', {'onlyText': !currentAppointmentRequest})}>
          { (loading)
              ? <span className={'aSpan'} >{'Loading...'}</span>
              : (showVideo)
                ? <>
                    <span className={'aSpan'} onClick={() => clickPopupClose()}>{'Close Video Popup'}</span>
                    <a style={{display:'none'}} href={url} target="popup" onClick={clickVideoOpen}>
                      <span>{intlMessages['requestDetailPage.details.videochat.button.label']}</span>
                    </a>
                  </>
                : (currentAppointmentRequest && currentRequestUid)
                    ? <a href={url} target="popup" onClick={clickVideoOpen}>
                        <span>{startCase(intlMessages['requestDetailPage.details.videochat.button.label'])}</span>
                      </a>
                    : <span className={'noChats'}>
                        {startCase(intlMessages['requestDetailPage.details.videochat.noChat.description'])}
                      </span>
          }
        </div>
      </StyledCurrentReport>
    )
}
