import React, { useState, useEffect} from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Modal from 'react-modal';
import moment from 'moment';

import { upperFirst } from 'lodash';
import {localeFormatDate, formatName } from '../_helpers';

// components
import { CloseIcon as CloseSvg } from '../_images/icons/CloseIcon';
import { LoadingEllipsis } from '../_components/Loaders';
import { AppointmentForm } from '../Appointments/AppointmentForm';

// redux state
//import { actions } from './_redux/actions';
import {
  getAppointmentConfirmationFormOpen,
  getCurrentRequestId
} from './_redux/selectors';

// selectors
import {
  getPatientRequestsById,
  getPatientRequestsLoading,
} from '../PatientRequests/_redux/selectors';
import { getIntlLocale, getIntlMessages} from '../App/_redux/selectors';

// styles
import {  ModalCloseIcon, StyledButton } from '../_css/styles';
import { consoleLog } from '../_helpers/consoleLog';

const generalStyles = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
      zIndex: 10,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    content: {
      overflow: 'auto',
      WebkitOverflowScrolling: 'touch',
      width: 'fit-content',
      height:  'fit-content',
      outline: 'none',
      padding: '0',
      position: 'initial',
      zIndex: 10
    }
}

const Styles = styled.div`
  position: relative;
  height: 100%;
  width: fit-content;
  padding: 30px 50px;
  .title{
    font-size: 22px;
    font-family: Proxima Nova Semibold;
    text-align: center;
  }

  .loading{
    display:flex;
    align-items: center;
    justify-content: center;
  }
  .appointmentComplete{
    margin-top: 20px;

    &_label{
      padding: 10px 0;
      color: #7A8F99;
    }

    &_patientName{
      display:flex;
      align-items:center;
      font-size: 16px;
      .label{
        margin:0 8px;
        color: #7A8F99;
        font-size: 14px;
      }
      .birthDate, .gender, .splitter{
        font-family: 'Proxima Nova';
      }
    }

    &_value{
      font-size: 16px;
    }

    ${StyledButton}{
      display:flex;
      margin-top: 20px;
      justify-content: center;
      min-width:100px;
    }

  }
`;

const CompletedForm = ({appointment, handleClose}) => {
  const intlMessages = useSelector(getIntlMessages);
  const intlLocale = useSelector(getIntlLocale);
  return (
    <div>
      <div className={'title'}>{intlMessages['confirmAppointmentRequestForm.appointmentConfirmation.completed.title']}</div>
      <div className={'appointmentComplete'}>
          <div className={'appointmentComplete_label'}>
            {intlMessages['confirmAppointmentRequestForm.patient.label']}
          </div>
          <div className={'appointmentComplete_patientName'}>
            <div>{formatName(intlMessages['format.fullName'], appointment.patient.first_name, appointment.patient.last_name)}</div>
            <div className={'label birthDate'}>{intlMessages['patientForm.date_of_birth.label']}</div>
            <div className={'value birthDate'}>{localeFormatDate(moment(appointment.patient.date_of_birth, "YYYY-MM-DD"), intlLocale, "l")}</div>
            <div className={'splitter'}>{""}</div>
            <div className={'label gender'}>{intlMessages['patientForm.gender.label']}</div>
            <div className={'value gender'}>{intlMessages[`settings.gender.${appointment.patient.gender}`]}</div>
          </div>

          <div className={'appointmentComplete_label'}>
            <div>{intlMessages['confirmAppointmentRequestForm.appointmentConfirmation.completed.appointmentTime.label']}</div>

          </div>
          <div className={'appointmentComplete_value'}>
            <div>{localeFormatDate(moment(appointment.start_date*1000), intlLocale, "l LT")}</div>
          </div>
          <div className={'appointmentComplete_content'}>
            <div>
              <StyledButton onClick={handleClose}>
                <span>{upperFirst(intlMessages['confirmAppointmentRequestForm.appointmentConfirmation.completed.button.label'])}</span>
              </StyledButton>
            </div>
          </div>
      </div>
    </div>  )
}

export const ConfirmAppointmentModal = ({onClose}) => {
  //const dispatch = useDispatch();
  const modalIsOpen = useSelector(getAppointmentConfirmationFormOpen)
  const currentRequestUid = useSelector(getCurrentRequestId);
  const patientRequests = useSelector(getPatientRequestsById);
  const patientRequestsLoading = useSelector(getPatientRequestsLoading);
  //const patientRequestsError = useSelector(getPatientRequestsError);

  const [completed, setCompleted ] = useState(false);
  const [appointment, setAppointment] = useState(null);

  useEffect(() => {
    return () => {};
  }, [])

  const afterOpenModal = () => {
    /* all refs are set*/
  }

  const closeModal = () => {
    onClose();
  }

  const onCreated = (appointment) => {
    consoleLog("confirmacppointmentmodal onCreated ", appointment)
    setAppointment(appointment);
    setCompleted(true);
  }

  return (
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={generalStyles}
      shouldCloseOnOverlayClick={false}
      contentLabel="Create Report"
    >
      <Styles>
        <ModalCloseIcon onClick={closeModal} ><CloseSvg/ ></ModalCloseIcon>
        {patientRequestsLoading[currentRequestUid] &&
          <div className={'loading'}><LoadingEllipsis /></div>
        }
        {
          completed
          ? <CompletedForm appointment={appointment} handleClose={closeModal}/>
          : <AppointmentForm
              request={patientRequests[currentRequestUid]}
              onCreated={onCreated}
            />
        }

      </Styles>
    </Modal>
  )
}
