import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector, useDispatch} from 'react-redux';
import { createSelector } from 'reselect'
import styled from 'styled-components';
import cn from 'classnames';
import moment from 'moment';
import NewWindow from 'react-new-window'
import { startCase  } from 'lodash';
import { localeFormatDate, formatName } from '../_helpers';
import { URLS } from '../_config';

import Timeline from 'react-calendar-timeline';
import 'react-calendar-timeline/lib/Timeline.css'

import { VideoChat, VideoChatComponent } from '../_components/VideoChat';

import { actions } from './_redux/actions';
import { services } from './_redux/services';

export const StyledButton = styled.button`
  box-shadow: 0px 2px 14px rgba(39, 50, 56, 0.3);
  border-radius: 28px;
  color:white;
  padding: 10px 15px;
  font-weight: 700;
  letter-spacing:
  width: fit-content;
  min-width: 200px;
  margin: 0 auto;
  margin-top: 30px;

  background: ${props => props.endCall ? '#F93D5C' : '#273238' };
  &:disabled{
    opacity: .5;
  }
`;

const StyledPageTitle = styled.div`
  font-family: Proxima Nova;
  font-weight: 600;
  font-size: 20px;
  line-height: 40px;
  /* identical to box height, or 143% */
  color: #12171A;
  text-align: left;
`;

const StyledLoadingDiv = styled.div`
  font-size:48px;
  padding: 40px;
`;

const StyledErrorDiv = styled.div`
  font-size:48px;
  padding: 40px;
`;

const StyledNoRequests = styled.div`
  font-size:36px;
  text-align:center;
  padding: 50px 0;
`;

const StyledAppointmentListContainer = styled.div`
  padding: 10px 10px;
  display:flex;
  justify-content: start;
`;

const StyledAppointmentItemLink = styled(Link)`
  padding: 20px;
  display: flex;
  flex-direction: column;
  height: fit-content;
  box-shadow: 0px 5px 15px 5px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  background: ${props => props.selected ? '#367FFF' : '#FFFFFF'};

  & {
    text-decoration: none;
    color: inherit;
  }


  &:hover{
    cursor:pointer;
    transform: translate(0px, -1px);
    box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.05);
  }

  &:hover, &:visited, &:active, &:link{
    text-decoration: none;
    color: inherit;
  }


  .header{
    display:flex;
    flex-direction:row;
    align-items:baseline;

    .time{
      font-size:18px;
      font-weight:700;
      margin-right:10px;
      color: #12171A;
    }
    .date{
      font-size:14px;
      color: #7A8F99;

    }
  }

  .patientName{
    text-align: left;
    font-size:14px;
  }
`;

const getOpenRequestsSlice = (state) => state.openRequests;
const getAppointmentsSlice = (state) => state.vidChatAppointments;
const getIntlSlice = (state) => state.intl;

const getAppointmentsById = createSelector(
  [getAppointmentsSlice],
  (appointments) => {
    return appointments.byId;
  }
)

const getLoadingState = createSelector(
  [getAppointmentsSlice],
  (appointments) => {
    return appointments.loading;
  }
)

const getErrorState = createSelector(
  [getAppointmentsSlice],
  (appointments) => {
    return appointments.error;
  }
)

const getIntlMessages = createSelector(
  [getIntlSlice],
  (intl) => {
    return intl.messages;
  }
)

const getIntlLocale = createSelector(
  [getIntlSlice],
  (intl) => {
    return intl.locale;
  }
)

const getCurrentVideoChatSession = createSelector(
  [getAppointmentsSlice],
  (state) => {
    return state.currentSession;
  }
)

const getCurrentVideoChatSessionRequestId = createSelector(
  [getCurrentVideoChatSession],
  (session) => {
    return session.requestId;
  }
)

const getCurrentVideoChatSessionId = createSelector(
  [getCurrentVideoChatSession],
  (session) => {
    return session.sessionId;
  }
)

const checkUpcomingAppointment = (entry) => {
  if (entry.extra_data && entry.extra_data.video_chat && entry.extra_data.video_chat.chat_date){
    return moment(entry.extra_data.video_chat.chat_date).isValid() && entry.type === "VIDEOCHAT";
  }
  return false
}

const convertToAppointmentModel = (entry) => {
  const addKeys = {}
  if (entry.extra_data && entry.extra_data.video_chat && entry.extra_data.video_chat.chat_date){
    addKeys['video_chat_date'] = moment(entry.extra_data.video_chat.chat_date)
    addKeys['video_chat_date_end'] = moment(entry.extra_data.video_chat.chat_date).add(15, 'minutes')
    addKeys['title'] = `${entry.patient.first_name} ${entry.patient.last_name}`
  } else {
    addKeys['video_chat_date'] = null
    addKeys['video_chat_end'] = null
  }
  if (entry.extra_data && entry.extra_data.video_chat && entry.extra_data.video_chat.session_id){
    addKeys['video_chat_session_id'] = entry.extra_data.video_chat.session_id
  } else {
    addKeys['video_chat_session_id'] = null
  }
  return Object.assign({}, entry, addKeys)
}

const getUpcomingAppointments = createSelector(
  [getAppointmentsById],
  (appointments) => {
    return appointments
      ? Object.values(appointments)
          .filter((data) => checkUpcomingAppointment(data) )
          .map( (entry) => {
            return convertToAppointmentModel(entry);
          }).sort( (a,b) => {
            if (a.video_chat_date.isSame(b.video_chat_date)){
              return 0;
            } else if (a.video_chat_date.isAfter(b.video_chat_date) ){
              return 1;
            } else {
              return -1;
            }
          })
      : [];
  }
)

const getOpenRequestById = createSelector(
  [getOpenRequestsSlice],
  (requests) => {
    return requests.byId;
  }
)


const getOpenRequests = createSelector(
  [getOpenRequestById],
  (requests) => {
    return requests
      ? Object.values(requests)
          .filter((data) => data.type ===  "VIDEOCHAT" )
        .map( (entry) => {
          // dependent on filter....
          return entry;
        })//.sort( (a,b) => {
          //if (a)
        //}
      : [];
  }
)


//const testTok = 'T1==cGFydG5lcl9pZD00Njc3NjA5NCZzaWc9MDExMGZhMTBkMGU2NTliNmQ5ZDhmZDA0MDNlOTA2MGEzMGQ0ZGZiOTpzZXNzaW9uX2lkPTJfTVg0ME5qYzNOakE1Tkg1LU1UWXhNREEzTVRJME1qRXdNbjU1U1dKblRHMXZZM0JUUTFGMlRsTlBMMnRDTlVoTGIwaC1RWDQmY3JlYXRlX3RpbWU9MTYxMDYxMzM4NSZub25jZT0wLjQwMTAzNjY2MjcwNDE4MjE1JnJvbGU9cHVibGlzaGVyJmV4cGlyZV90aW1lPTE2MTMyMDUzODUmaW5pdGlhbF9sYXlvdXRfY2xhc3NfbGlzdD0='
//const testSession = '2_MX40Njc3NjA5NH5-MTYxMDA3MTI0MjEwMn55SWJnTG1vY3BTQ1F2TlNPL2tCNUhLb0h-QX4'

const AppointmentItem = ({apptFragment}) => {
  const intlMessages = useSelector(getIntlMessages);
  const dispatch = useDispatch();
  const locale = useSelector(getIntlLocale);
  const location = useLocation();
  const currentSessionRequestId = useSelector(getCurrentVideoChatSessionRequestId);
  // time, date, patient name
  const { id, video_chat_date, video_chat_session_id} = apptFragment;
  const toUrl = URLS.requestPage.url.replace('{requestId}', apptFragment.id);


  const onClick = () => {
    dispatch(actions.updateCurrentSession(video_chat_session_id, id))
    //dispatch(actions.updateCurrentSessionRequest(id))
  }

  return (
    <StyledAppointmentItemLink selected={currentSessionRequestId == id} onClick={onClick} to={{pathname: toUrl, state: { from: location}}}>
      <div className={cn('header')}>
        <div className={cn('time')}>{localeFormatDate(video_chat_date, locale, 'LT')}</div>
        <div className={cn('date')}>{localeFormatDate(video_chat_date, locale, 'l')}</div>
      </div>
      <div className={cn('patientName')}>
        <span>{formatName(intlMessages['format.fullName'], apptFragment.patient.first_name, apptFragment.patient.last_name)}</span>
      </div>
    </StyledAppointmentItemLink>
  )
}

// eslint-disable-next-line no-unused-vars
const AppointmentsList = () => {
  const intlMessages = useSelector(getIntlMessages);
  const appointments = useSelector(
    getUpcomingAppointments
  );

  if (appointments.length < 1){
    return (
      <StyledAppointmentListContainer>
        <StyledNoRequests>{startCase(intlMessages['appointmentList.norequests.message'])}</StyledNoRequests>
      </StyledAppointmentListContainer>
    )
  } else {
      return (
        <StyledAppointmentListContainer>
          {appointments.map((appt, index) => (
            <AppointmentItem
              key={index}
              apptFragment={appt}
            />
          ))}
        </StyledAppointmentListContainer>
      )
  }
}


const StyledVideoChatContainer = styled.div``;
// eslint-disable-next-line no-unused-vars
const VideoChatContainer = () => {
  const dispatch = useDispatch();
  const currentSessionId = useSelector(getCurrentVideoChatSessionId);
  const currentSessionRequestId = useSelector(getCurrentVideoChatSessionRequestId);
  const [ loading, setLoading ] = useState(false);
  const [ err, setError ] = useState(false);
  const [ vidChatToken, setToken ] = useState("");
  const [ vidChatSession, setSessionId ] = useState("");
  const [ newWindow, setNewWindow ] = useState(false);

  useEffect(() =>{
    if (currentSessionRequestId != null){
      setLoading(true);
      const fetchVideoChatToken = async () => {
        try{
          let response = await services.fetchVideoChatToken(currentSessionRequestId);
          setNewWindow(false);
          const { session_id, token } = response;
          // TODO testing

          setToken(token);
          setSessionId(session_id);
        } catch (err){
          setToken("");
          setSessionId("");
          setError("error grabbing token")
        } finally {
          setLoading(false);
        }
      }
      fetchVideoChatToken();
    }
  }, [currentSessionRequestId])

  const endCall = () => {
    setToken("");
    setSessionId("");
    dispatch(actions.updateCurrentSession(null, null))
  }

  if (err){
    return (
      <StyledVideoChatContainer>
        { err }
      </StyledVideoChatContainer>
    )
  } else if (loading) {
    return(
      <StyledVideoChatContainer>
        { 'LOADING...' }
      </StyledVideoChatContainer>
    )
  } else if (currentSessionId && vidChatToken){
    if (newWindow){
      return (
        <NewWindow
            title={'Video Chat Appointment'}
            onUnload={()=>endCall()}
            features={{width: 700, height: 500}}>
          <StyledVideoChatContainer>
              <VideoChatComponent
                sessionId={vidChatSession}
                token={vidChatToken}
                handleClose={endCall}
                />
          </StyledVideoChatContainer>
        </NewWindow>
      )
    } else {
      return (
        <StyledVideoChatContainer>
          <VideoChat
            sessionId={vidChatSession}
            token={vidChatToken}
            />
          <StyledButton
            endCall
            onClick={() => setNewWindow(true)}
          >NEW WINDOW</StyledButton>
          <StyledButton
            endCall
            onClick={() => endCall()}
          >END</StyledButton>
        </StyledVideoChatContainer>
      )
    }
  } else {
    return(
      <span></span>
    )
  }
}

const StyledAppointmentsContainer = styled.div`
  width: 1250px;
  overflow: scroll;
`;
const AppointmentsContainer = () =>{
  const openRequests = useSelector(getOpenRequests);
  /*return (
    <StyledAppointmentsContainer>
      <VideoChatContainer />
      <AppointmentsList />
    </StyledAppointmentsContainer>
  )*/
  const groups = [{id: 1, title: 'My Upcoming'}]
  const filterData = (item) => {
    const { id, video_chat_date} = item;
    const newData = {
      id: id,
      group: 1,
      title: 'Open Request',
      start_time: moment(video_chat_date),
      end_time : moment(video_chat_date).add(30, 'm')
    }
    return newData;
  }

  const items = openRequests.map(item => {return filterData(item)});

  return(
    <StyledAppointmentsContainer>
      <Timeline
        groups={groups}
        items={items}
        defaultTimeStart={moment().add(-1, 'hour')}
        defaultTimeEnd={moment().add(1, 'd')}
        lineHeight = {60}
        timeSteps={{
          second: 1,
          minute: 15,
          hour: 1,
          day: 1,
          month: 1,
          year: 1
        }}
        />

    </StyledAppointmentsContainer>
  )
}



const StyledAppointments = styled.div`

`;

const Appointments = () => {
  const dispatch = useDispatch();
  const intlMessages = useSelector(getIntlMessages);
  const currentLoading = useSelector(getLoadingState);
  const currentError = useSelector(getErrorState);

  useEffect(() => {
    dispatch(actions.fetchAppointments());
  }, [])

  useEffect(()=> {

  }, [currentError])

  useEffect(()=> {

  }, [currentLoading])

  return (
    <StyledAppointments>
      <StyledPageTitle>{startCase(intlMessages['appointmentList.title'])}</StyledPageTitle>
          { currentError && <StyledErrorDiv> ERROR</StyledErrorDiv>}
          {
            currentLoading && <StyledLoadingDiv> LOADING </StyledLoadingDiv>
          }
          {
            !currentLoading && !currentError &&
            <AppointmentsContainer
            />
          }
    </StyledAppointments>
  )
}

export { Appointments };
