import React from 'react';
import Select, { components } from 'react-select';
import cn from 'classnames';


import { Flex, Box, Tooltip } from '@chakra-ui/react';

import { StyledLabel, ErrorLabel } from '../../_css/styles'

import { find } from 'lodash';

const SelectAdapter = ({input, meta, items, label, intlMessages, className, defaultValue, disabled, ...rest }) => (
  <div className={className}>
    <label className="Input__Label">{label}</label>
    <Select
      {...input}
      className={ cn({"Input_Error": (meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)})}
      onChange={inputValue => {input.onChange(inputValue.value)}}
      options={items}
      value={find(items, {'value': defaultValue || input.value})}
      defaultValue={defaultValue|| find(items, {'value': input.value})}
      disabled={disabled}
      {...rest}
    />
    <div className={cn("Error__Label", {"Error": (meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)} )} >
      {(meta.error && meta.touched  || meta.submitError && !meta.dirtySinceLastSubmit) && intlMessages[`form.error.${(meta.error || meta.submitError)}`]}
    </div>
  </div>
)

const SelectAdapterNonClearable = ({input, meta, items, label, intlMessages, className, defaultValue, disabled, ...rest }) => {
  return(
  <div className={className}>
    <label className="Input__Label">{label}</label>
    <Select
      {...input}
      className={ cn({"Input_Error": (meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)})}
      onChange={inputValue => {input.onChange(inputValue.value)}}
      options={items}
      isDisabled={disabled}
      clearable={false}
      searchable={false}
      value={find(items, {'value': defaultValue || input.value})}
      onBlurResetsInput={false}
      onSelectResetsInput={false}
      {...rest}
    />
    <div className={cn("Error__Label", {"Error": (meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)} )} >
      {(meta.error && meta.touched  || meta.submitError && !meta.dirtySinceLastSubmit) && intlMessages[`form.error.${(meta.error || meta.submitError)}`]}
    </div>
  </div>
  )
}

const DentistAdapterInlineOption = (props) => {
  return (
      <components.Option {...props} />
  );
};

const formatDentistAdapterOptionLabel = (props) => {
  return (
    <Tooltip label={props.label}>
      <Box
        overflow={'clip'}
        whiteSpace={'nowrap'}
        height="100%"
      >
        <Flex
          height="100%"
          whiteSpace={'nowrap'}
          bg='parent'
        >
          <Flex >
              {props.label.split(",")[0]}
          </Flex>
          <Flex
            ml={2}>
            {props.email}
          </Flex>
        </Flex>
      </Box>
    </Tooltip>
  )
}

const dentistInlineStyles = {
  control: (provided) => ({
    ...provided,
    borderColor: '#7A8F99'
  })
}

const DentistAdapterInline = ({value, items, defaultValue, isDisabled, onChange, ...rest }) => {

  return(
  <div>
    <Select
      onChange={inputValue => {onChange(inputValue?.value)}}
      options={items}
      styles={dentistInlineStyles}
      isDisabled={isDisabled}
      isClearable={true}
      isSearchable={true}
      value={find(items, {'value': defaultValue || value})}
      onBlurResetsInput={false}
      onSelectResetsInput={false}
      formatOptionLabel={formatDentistAdapterOptionLabel}
      components={{Option: DentistAdapterInlineOption}}
      {...rest}
    />
  </div>
  )
}

const dot = (color = 'transparent') => ({
  alignItems: 'center',
  display: 'flex',

  ':before': {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: 'block',
    marginRight: 8,
    height: 10,
    width: 10,
  },
});

const gradeSelectStyles = {
  option: (styles, {data}) => ({ ...styles, ...dot(data.color)}),
  singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color)})
}
const errorStyles = {
  option: (styles, {data}) => ({ ...styles, ...dot(data.color)}),
  singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color)}),
  placeholder: (provided) => {
    return { ...provided, color: '#A0AEC0' }
  },
  control: (provided) => {
    return { ...provided, 
      borderColor: 'red', 
    }
  }
}
const SelectAdapterGrade = ({input, meta, items, label, intlMessages, className, defaultValue, disabled, ...rest }) => {
  const isError = (meta.error && meta.touched  || meta.submitError && !meta.dirtySinceLastSubmit)
  return(
  <div className={className}>
    <StyledLabel className="Input__Label">{label}</StyledLabel>
    <Select
      className={ cn({"Input_Error": (meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)})}
      onChange={inputValue => {input.onChange(inputValue?.value)}}
      styles={isError ? errorStyles : gradeSelectStyles}
      options={items}
      isDisabled={disabled}
      clearable={true}
      isClearable={true}
      searchable={false}
      defaultValue={defaultValue}
      value={find(items, {'value': defaultValue || input?.value}) || null}
      onBlurResetsInput={false}
      onSelectResetsInput={false}
      {...rest}
    />
    <ErrorLabel className={cn("Error__Label", {"Error": (meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)} )} >
      {isError && intlMessages[`form.error.${(meta.error || meta.submitError)}`]}
    </ErrorLabel>
  </div>
  )
}



export {SelectAdapter, SelectAdapterNonClearable, DentistAdapterInline, SelectAdapterGrade};
