import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchMessageHistory, fetchUserData } from "pubnub-redux";
import {
  usePagination,
} from "../_helpers/usePagination";
import { actions } from './_redux/actions';
import { extractPatientUuidFromChannelId, formatName  } from '../_helpers';

// redux state
import {
  getPatientsById,
  getCurrentConversationMessages,
  getUnknownUsers,
  getConversationMessageDraft


} from './_redux/selectors';

import { Flex, Text } from '@chakra-ui/react'; 
import { BiMessageError } from 'react-icons/bi';

// components
import { MessageListItem } from './MessageTemplates/MessageTemplates';

// selectors
import { getIntlMessages, getUUID } from '../App/_redux/selectors';

// styles
import {
  StyedChatHeader,
  StyledBox,
  FlexRow,
  FlexColumn,
  ScrollView,
  StyledTextArea,
  Divider
} from './styles';
import { consoleLog } from '../_helpers/consoleLog';

const wasFetched = (() => {
  const ids = {};
  return (id) => {
    // eslint-disable-next-line no-prototype-builtins
    if (ids.hasOwnProperty(id)) {
      return true;
    } else {
      ids[id] = true;
      return false;
    }
  };
})();

const MessageList = ({conversationId, onError}) => {
  const dispatch = useDispatch();
  const [height, setHeight] = useState(0);


  const handleScroll = (e) => {
    const scrollPosition = e.target.scrollTop;
    if (scrollPosition !== 0) {
      consoleLog("scroll Position: " + scrollPosition);
    }
  };

  const getNextPage = useCallback(
    async (tt, total, channel) => {
      const pageSize = 100;
      const action = await dispatch(
        fetchMessageHistory({
          count: pageSize,
          channel,
          start: tt || undefined,
          stringifiedTimeToken: true
        })
      );

      dispatch(actions.fetchMessageActions({
        limit: pageSize,
        channel,
        start: tt || undefined
      }));
      const response = action.payload.response;
      return {
        results: response.messages,
        pagination: `${response.startTimeToken}`,
        pagesRemain: response.messages.length === pageSize
      };
      
    },
    [dispatch]
  );

  const { containerRef, endRef } = usePagination(
    getNextPage,
    conversationId,
    null,
    null,
    onError
  );


  const messages = useSelector(
    getCurrentConversationMessages
  );

  const unknownUsers = useSelector(getUnknownUsers);

  useEffect(() => {
    // send requests for missing data
    unknownUsers.forEach(uuid => {
      if (!wasFetched(uuid)) {
        dispatch(
          fetchUserData({
            uuid
          })
        );
      }
    });
  }, [unknownUsers, dispatch]);

  const el = containerRef.current;

  // when history is pulled, scroll down to compensate
  const newHeight = el && el.scrollHeight;
  useEffect(() => {
    if (height === 0 && newHeight) {
      setHeight(newHeight);
    } else if (newHeight && newHeight !== height) {
      if (el) {
        el.scrollTop += newHeight - height;
      }
      setHeight(newHeight);
    }
  }, [newHeight, height, el]);


  const scrollToBottom = useCallback(() => {
    return el && (el.scrollTop = el.scrollHeight - el.clientHeight);
  }, [el]);

  const hasReachedBottom = el
    ? el.scrollHeight - el.clientHeight === el.scrollTop
    : false;

  useEffect(() => {
    if (hasReachedBottom) {
      scrollToBottom();
    }
  }, [messages?.length, hasReachedBottom, scrollToBottom]);


  return (
    <ScrollView  ref={containerRef} onScroll={handleScroll}>
      <FlexColumn>
         {/* This moves the list of messages to the bottom, since there's a bug with flex-end scroll */}
         <FlexColumn></FlexColumn>
         <div ref={endRef} />
         { (messages || []).map( (message ) => (
           <MessageListItem
             messageFragment={message}
             key={message.timetoken}
             conversationId={conversationId}
           />
         ))}
       </FlexColumn>
    </ScrollView>
  )

}

const ChatHeader = ({item}) => {
  const intlMessages = useSelector(getIntlMessages);
  const name = formatName(intlMessages['format.fullName'], item.first_name, item.last_name)
  if (name){
    return (
      <StyedChatHeader>
        {'Conversation with ' + name}
      </StyedChatHeader>
    )
  } else {
    return (
      <StyedChatHeader>
      </StyedChatHeader>
    )
  }
}

const Header = ({conversationId, conversationData}) => {
  const patientsById = useSelector(getPatientsById);
  return (
    <StyledBox
      addCss={{padding:'7px 6px 0', backgroundColor: 'rgb(247, 249, 250)'}}>
      <FlexRow addCss={{justifyContent:'space-between'}} >
        <ChatHeader
          item={(conversationId && patientsById[extractPatientUuidFromChannelId(conversationId)]) ? patientsById[extractPatientUuidFromChannelId(conversationId)] : "Loading"}
          label={conversationData?.request_created_at}
          />

      </FlexRow>
      <StyledBox
        addCss={ {paddingTop:5, borderBottom:"lightgray"} }
        >
      </StyledBox>
    </StyledBox>
  )
}

const autoExpand = (el) => {
  setTimeout(function () {
    el.style.cssText = "height:auto; padding:0";
    el.style.cssText = "height:" + (el.scrollHeight + 20) + "px";
  }, 0);
}

const newTextDraft = (draft, newText) => {
  if (draft.text=== newText) {
    return draft;
  }
  return {
    type: "text",
    senderId: draft.senderId,
    text: newText
  }
}

const isDraftModified = (message) => {
  switch (message.type) {
    case "text":
      return message.text !== "";
    default:
      consoleLog(`C3annot determine if message of type "${message.type}" has been modified.`)
  }
};

const TextMessageEditor = ({message, sendDraft, updateDraft, isDisabled}) => {
  const text = message.text;
  const textareaRef = useRef(document.createElement("textarea"));

  const textChanged = (e) => {
    updateDraft(newTextDraft(message, e.target.value))
  }

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !(e.shiftKey)) {
      const draft = newTextDraft(message, text);
      if (isDraftModified(draft)) {
        sendDraft(draft);
      }
      e.preventDefault();
    }
    autoExpand(e.target);
  }

  //const emojiInserted = (messageWithEmoji) => {
  //  updateDraft(newTextDraft(message, messageWithEmoji));
  //  textareaRef.current.focus();
  //};

  useEffect(() => {
    autoExpand(textareaRef.current);
  }, [textareaRef]);

  return (
    <FlexRow addCss={{padding:"30"}}>
      <FlexRow addCss={{flexGrow:1}}>
        <StyledTextArea
          ref={textareaRef}
          disabled={isDisabled}
          rows={3}
          value={text}
          onChange={textChanged}
          onKeyPress={handleKeyPress}
          placeholder="Press Enter to send message..."
        ></StyledTextArea>
      </FlexRow>
    </FlexRow>
  )

}

const MessageEditor = ({message, sendDraft, updateDraft, isDisabled}) => {
  switch (message.type) {
    case "text":
      return (
        <StyledBox
          addCss={{
            position: 'relative',
            borderRadius: '20px'
          }}
          >
          <TextMessageEditor
            message={message}
            sendDraft={sendDraft}
            updateDraft={updateDraft}
            isDisabled={isDisabled}
            >
          </TextMessageEditor>
        </StyledBox>

      )
    default:
      consoleLog(`no editor available for draft message of type ${message.type}`);
  }
}

const MessageInput = ({conversationId, isDisabled}) => {
  const userId = useSelector(getUUID);
  const dispatch = useDispatch();
  const storedDraft = useSelector(getConversationMessageDraft);

  const defaultDraft = {
    type: "text",
    senderId: userId,
    text: ""
  }
  const message = storedDraft ? storedDraft : defaultDraft;
  const send = (appMessage) => {
    dispatch(actions.sendMessage(appMessage));
    dispatch(actions.discardMessageDraft(conversationId));
  }

  const update = (draft) => {
    dispatch(actions.updateMessageDraft(conversationId, draft));
  }

  return (
    <StyledBox
      addCss={{}}
      >
      <MessageEditor
        message={message}
        sendDraft={send}
        updateDraft={update}
        isDisabled={isDisabled}
        ></MessageEditor>
    </StyledBox>
  )

}

export const ChatConversation = ({conversationId, conversationData}) => {
  const intlMessages = useSelector(getIntlMessages);
  const [ pnError, setPnError ] = useState(false);
  return (
    <FlexColumn
      addCss={{
        height:'100%',
        width:"100%",
        position:["fixed", "static"],
        bg:"backgrounds.content",
        borderRight:"light"
      }}
      >
      <Header conversationId={conversationId} conversationData={conversationData} />
      {
        conversationId
        ? <MessageList conversationId={conversationId} onError={setPnError} />
        : <Flex direction={'column'} bg={'rgb(247, 249, 250)'} height={'100%'} px={'15px'} align={'center'} justify={'center'}>
            <BiMessageError size={26} />
            <Text fontSize={16} mt={3}>{intlMessages['chatPage.chatConversation.noChatSelected.title']}</Text>
            <Text fontSize={14} color={'none.500'}>{intlMessages['chatPage.chatConversation.noChatSelected.title']}</Text>
          </Flex>
      }
      <Divider />
      { conversationId && <MessageInput conversationId={conversationId} isDisabled={pnError === false} />}
    </FlexColumn>
  )
}
