import { createSelector } from 'reselect';
import moment from 'moment';

const getOpenRequestsSlice = (state) => state.openRequests;

export const getOpenRequestById = createSelector([getOpenRequestsSlice], (requests) => {
  return requests.byId;
});

export const getQueryParams = createSelector(getOpenRequestsSlice, (requests) => {
  return requests.queryParams;
});

export const getQueryParamsOffset = createSelector(getOpenRequestsSlice, (queryParams) => {
  return parseInt(queryParams?.offset || 0) || 0;
});

export const getCurrentSortBy = createSelector([getOpenRequestsSlice], (requests) => {
  return requests.sortBy;
});

export const getCurrentTypeBy = createSelector([getOpenRequestsSlice], (requests) => {
  return requests.typeBy;
});

export const getIsLoadingMore = createSelector(getOpenRequestsSlice, (requests) => {
  return requests.loadingMore;
});

export const getisLoadMore = createSelector(getOpenRequestsSlice, (requests) => {
  return requests.loadMore;
});

export const getLoadingState = createSelector([getOpenRequestsSlice], (requests) => {
  return requests.loading;
});

export const getErrorState = createSelector([getOpenRequestsSlice], (requests) => {
  return requests.error;
});

export const getOpenRequests = createSelector(
  [getOpenRequestById, getCurrentSortBy, getCurrentTypeBy],
  (requests, sortBy, typeBy) => {
    return requests
      ? Object.values(requests)
          .filter((data) => (typeBy ? data.type === typeBy : true))
          .map((entry) => {
            // dependent on filter....
            return entry;
          }) //.sort( (a,b) => {
      : //if (a)
        //}
        [];
  }
);

export const getOpenRequestReports = createSelector([getOpenRequestById], (requests) => {
  return requests
    ? Object.values(requests)
        .filter((data) => data.type == 'REPORT')
        .map((entry) => {
          // dependent on filter....
          return entry;
        })
        .sort((a, b) => {
          if (moment(a.created_at).isAfter(moment(b.created_at))) {
            return 1;
          } else if (moment(a.created_at).isSame(moment(b.created_at))) {
            return 0;
          } else {
            return -1;
          }
        })
    : [];
});

export const getOpenRequestAppointments = createSelector([getOpenRequestById], (requests) => {
  return requests
    ? Object.values(requests)
        .filter((data) => data.type == 'VIDEOCHAT')
        .map((entry) => {
          // dependent on filter....
          return entry;
        })
        .sort((a, b) => {
          if (a.availability) {
            if (parseInt(a.availability.value) > parseInt(b.availability.value)) {
              return 1;
            } else if (parseInt(a.availability.value) == parseInt(b.availability.value)) {
              return 0;
            } else {
              return -1;
            }
          } else {
            return 1;
          }
        })
    : [];
});
