import { constants } from './constants';
import {
  createPubnubMessageReportPayload,
  createPubnubMessageTextPayload,
  createPubnubMessageVideoChatPayload,
  createPubnubMessagePartialScanPayload,
  createPubnubMessageAppointmentConfirmPayload,
  extractPatientUuidFromChannelId,
} from '../../_helpers';

import { actions as patientActions } from '../../Patients/_redux/actions';

import { fetchChannelData, sendMessage as sendPnMessage } from 'pubnub-redux';
import { consoleLog } from '../../_helpers/consoleLog';

export const actions = {
  selectConversation,
  loadChatChannels,
  sendMessage,
  updateMessageDraft,
  discardMessageDraft,
  sendPartialScan,
  sendReport,
  sendVideoChat,
  fetchMessageActions,
  sendAppointmentConfirmation,
};

function selectConversation(chatId) {
  return (dispatch) => {
    dispatch(select(chatId));
  };
  function select(data) {
    return { type: constants.CHAT_LIST_SELECT_CONVERSATION, data };
  }
}

function fetchMessageActions(args) {
  // args : {channel, start, end, limit}
  return (dispatch, context) => {
    context.pubnub?.api.getMessageActions(args, (status, response) => {
      if (status.error) {
        console.log('operation failed w/ error:', status);
      } else {
        // TODO make this by message action id since a pure list will have duplicates potentially
        dispatch(success({ messageActions: response?.data || [], channel: args.channel }));
      }
    });
  };
  function success(data) {
    return { type: constants.UPDATE_CHANNEL_MESSAGE_ACTIONS, data };
  }
}

function loadChatChannels(chatUuid, chatAuthKey, chatChannelGroup) {
  return (dispatch, context) => {
    dispatch(request());
    context.pubnub?.api.setUUID(chatUuid).setAuthKey(chatAuthKey);
    //dispatch(setUserData({ uuid: chatUuid, data: {name:"Bill Park", externalId: "1", custom:{userType: "DENT"}} }))
    //  .then(() => {
    context.pubnub?.api.channelGroups.listChannels(
      { channelGroup: [chatChannelGroup] },
      (status, response) => {
        if (status.error) {
          consoleLog('operation failed w/ error:', status);
          dispatch(failure(status.error));
          return;
        } else {
          response.channels.forEach(function (channel) {
            try {
              dispatch(fetchChannelData({ channel: channel }));
              dispatch(
                patientActions.loadPatientDetailsByUUid(extractPatientUuidFromChannelId(channel))
              );
            } catch {
              consoleLog('dispatch error fetchChannelData');
            }
          });
          dispatch(success(response.channels));
          context.pubnub?.api.subscribe({
            channelGroups: [chatChannelGroup],
          });
        }
      }
    );
    //  })
  };
  function request() {
    return { type: constants.CHAT_LIST_LOAD_REQUEST };
  }
  function success(data) {
    return { type: constants.CHAT_LIST_LOAD_SUCCESS, data };
  }
  function failure(error) {
    return { type: constants.CHAT_LIST_LOAD_FAILURE, error };
  }
}

function sendReport(message, conversationId, reportId) {
  return (dispatch, context) => {
    return dispatch(
      sendPnMessage({
        channel: conversationId,
        message: createPubnubMessageReportPayload(context.pubnub.api, message, reportId),
      })
    );
  };
}

function sendAppointmentConfirmation(date, conversationId, appointmentId) {
  return (dispatch, context) => {
    return dispatch(
      sendPnMessage({
        channel: conversationId,
        message: createPubnubMessageAppointmentConfirmPayload(
          context.pubnub.api,
          date,
          appointmentId
        ),
      })
    );
  };
}

function sendVideoChat(message, channel) {
  return (dispatch, getState, context) => {
    const state = getState();
    let currChannel = channel || state.chatsList.currentConversationId;
    return dispatch(
      sendPnMessage({
        channel: currChannel,
        message: createPubnubMessageVideoChatPayload(
          context.pubnub.api,
          message,
          currChannel,
          state.userInfo.profile.id
        ),
      })
    );
  };
}

function sendMessage(message, channel) {
  return (dispatch, getState, context) => {
    const state = getState();
    let currChannel = channel || state.chatsList.currentConversationId;
    return dispatch(
      sendPnMessage({
        channel: currChannel,
        message: createPubnubMessageTextPayload(
          context.pubnub.api,
          message,
          currChannel,
          state.userInfo.profile.id,
          state.userInfo.chat.uuid
        ),
      })
    );
  };
}

function sendPartialScan(message, channel) {
  return (dispatch, getState, context) => {
    const state = getState();
    return dispatch(
      sendPnMessage({
        channel: channel,
        message: createPubnubMessagePartialScanPayload(
          context.pubnub.api,
          message,
          channel,
          state.userInfo.profile.id
        ),
      })
    );
  };
}

function updateMessageDraft(conversationId, draft) {
  return (dispatch) => {
    dispatch(update({ conversationId, draft }));
  };
  function update(data) {
    return { type: constants.MESSAGE_DRAFT_UPDATED, data };
  }
}

function discardMessageDraft(conversationId) {
  return (dispatch) => {
    dispatch(discard({ conversationId }));
  };
  function discard(data) {
    return { type: constants.MESSAGE_DRAFT_DISCARDED, data };
  }
}
