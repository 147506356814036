import React from 'react';
import { useSelector} from 'react-redux';

import { FormattedMessage, useIntl } from 'react-intl';

import { Form } from 'react-final-form';

import { upperFirst, startCase} from 'lodash';

import { localeFormatDate } from '../_helpers';

//components
import { LoadingEllipsis } from '../_components/Loaders';

// selector
import { getIntlMessages, getIntlLocale } from '../App/_redux/selectors';

// styles
import {
  StyledForm,
  StyledField,
  StyledInputLayout,
  StyledLabel,
  StyledInput,
  ErrorLabel,
  errorCss
} from '../_css/styles';
import { StyledProfile } from './styles';
import { useAuth } from 'src/_libs/auth';


export const Profile = () => {
  const { formatMessage } = useIntl();
  const intlLocale = useSelector(getIntlLocale);
  const intlMessages = useSelector(getIntlMessages);
  const { user: profile, isLoading : profileLoading } = useAuth();

  const updateProfile = async(values) => {
    //console.log(values)
    return values;
  }

  if (profileLoading){
    return (
      <StyledProfile>
        <h2>{startCase(intlMessages['settingsPage.profile.title'])}</h2>
        <div className={'loading'}>
          <LoadingEllipsis />
        </div>
      </StyledProfile>
    )
  } else {
    let initValues = {
      first_name: profile.first_name,
      last_name: profile.last_name,
      date_of_birth: profile.date_of_birth ? localeFormatDate(profile.date_of_birth, intlLocale, "l") : "",
      gender: formatMessage({id: `settings.gender.${profile.gender}`}),
      phonenumber: profile.phonenumber ? profile.phonenumber : ""
    }
    return (
      <StyledProfile>
        <h2>
          <FormattedMessage
            id={'settingsPage.profile.title'}
            defaultMessage={'Profile Information'}
          />

        </h2>
        <div className={'successMessage'}>{intlMessages['settingsPage.profile.description']}</div>
        <Form
           onSubmit={updateProfile}
           initialValues = {initValues}
           render = {({
             submitError,
             handleSubmit,
           }) => (
             <StyledForm
               onSubmit={handleSubmit}
               >
               {submitError && <div className="error">{submitError}</div>}
               <StyledField name="first_name">
                 {({input, meta}) => (
                   <StyledInputLayout>
                     <StyledLabel>{startCase(intlMessages['settingsPage.profile.first_name.label'])}</StyledLabel>
                     <StyledInput
                       {...input}
                       disabled={true}
                       type="text"
                       addCSS={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)) && errorCss}
                       placeholder={upperFirst(intlMessages['settingsPage.profile.first_name.placeholder'])} />
                     <ErrorLabel
                       addCSS={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)) && errorCss}
                       >
                         {((meta.error && meta.touched)  || (meta.submitError && !meta.dirtySinceLastSubmit)) && meta.error || meta.submitError}
                     </ErrorLabel>
                   </StyledInputLayout>
                 )}
               </StyledField>
               <StyledField name="last_name">
                 {({input, meta}) => (
                   <StyledInputLayout>
                     <StyledLabel>{startCase(intlMessages['settingsPage.profile.last_name.label'])}</StyledLabel>
                     <StyledInput
                       {...input}
                       disabled={true}
                       type="text"
                       addCSS={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)) && errorCss}
                       placeholder={upperFirst(intlMessages['settingsPage.profile.last_name.placeholder'])} />
                     <ErrorLabel
                       addCSS={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)) && errorCss}
                       >
                         {((meta.error && meta.touched)  || (meta.submitError && !meta.dirtySinceLastSubmit)) && meta.error || meta.submitError}
                     </ErrorLabel>
                   </StyledInputLayout>
                 )}
               </StyledField>
               <StyledField name="date_of_birth">
                 {({input, meta}) => (
                   <StyledInputLayout>
                     <StyledLabel>{startCase(intlMessages['settingsPage.profile.date_of_birth.label'])}</StyledLabel>
                     <StyledInput
                       {...input}
                       disabled={true}
                       type="text"
                       addCSS={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)) && errorCss}
                       placeholder={upperFirst(intlMessages['settingsPage.profile.date_of_birth.label'])} />
                     <ErrorLabel
                       addCSS={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)) && errorCss}
                       >
                         {((meta.error && meta.touched)  || (meta.submitError && !meta.dirtySinceLastSubmit)) && meta.error || meta.submitError}
                     </ErrorLabel>
                   </StyledInputLayout>
                 )}
               </StyledField>
               <StyledField name="gender">
                 {({input, meta}) => (
                   <StyledInputLayout>
                     <StyledLabel>{startCase(intlMessages['settingsPage.profile.gender.label'])}</StyledLabel>
                     <StyledInput
                       {...input}
                       disabled={true}
                       type="text"
                       addCSS={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)) && errorCss}
                       placeholder={upperFirst(intlMessages['settingsPage.profile.gender.label'])} />
                     <ErrorLabel
                       addCSS={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)) && errorCss}
                       >
                         {((meta.error && meta.touched)  || (meta.submitError && !meta.dirtySinceLastSubmit)) && meta.error || meta.submitError}
                     </ErrorLabel>
                   </StyledInputLayout>
                 )}
               </StyledField>
               <StyledField name="phonenumber">
                 {({input, meta}) => (
                   <StyledInputLayout>
                     <StyledLabel>{startCase(intlMessages['settingsPage.profile.phonenumber.label'])}</StyledLabel>
                     <StyledInput
                       {...input}
                       disabled={true}
                       type="text"
                       addCSS={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)) && errorCss}
                       placeholder={upperFirst(intlMessages['settingsPage.profile.phonenumber.label'])} />
                     <ErrorLabel
                       addCSS={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)) && errorCss}
                       >
                         {((meta.error && meta.touched)  || (meta.submitError && !meta.dirtySinceLastSubmit)) && meta.error || meta.submitError}
                     </ErrorLabel>
                   </StyledInputLayout>
                 )}
               </StyledField>
             </StyledForm>

           )}
        />
      </StyledProfile>
    )
  }
}
