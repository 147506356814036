import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { 
  Box, 
  HStack,
  Text,
  Flex,
  Skeleton,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton
} from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import {useReportDetails } from '../api/getReport';

import { ReadOnlyEditor } from '../../_components/Editor';
import { CategorytDot } from './CategoryDot';
import { CategoryTitle } from './CategoryTitle';
import { ToothSetModern } from 'src/_components/ToothSet/ToothSetModern';
import { useSendReport } from '../api/sendReport';

import { PrintPreviewReportSection } from 'src/Reports/components/ReportPrintPreview';

import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";
import { useReportCategories } from '../api/getReportCategories';
import { QUERY_KEY_NAME as REQUEST_QUERY_KEY_NAME } from 'src/RequestListPage/api/getRequests';
import { queryClient } from 'src/_libs/react-query';

const CategoryItem = ({category}) => {
  return (
    <Flex
      border={'0.5px solid #405159'}
      borderRadius={['30px']}
      align={'center'}
      bg={'#fff'}
      px={['10px']}
      py={['4px']}
      mb={['10px']}
      mr={['10px']}
    >
      <CategorytDot category={category} width={['10px']} height={['10px']} marginRight={['8px']} />
      <Text color={'#405159'} fontSize={['12px']} textTransform={'upperCase'}>
        <CategoryTitle  category={category} />
      </Text>
    </Flex>
  )
}
const ChartCategoriesLoader = () => {
  return (
      <HStack spacing={['10px']}>
          <Skeleton height={["30px"]} borderRadius={['30px']} w={['70px']} h={['28px']} />
          <Skeleton height={["60px"]} borderRadius={['30px']} w={['70px']} h={['28px']} />
          <Skeleton height={["60px"]} borderRadius={['30px']} w={['70px']} h={['28px']} />
          <Skeleton height={["60px"]} borderRadius={['30px']} w={['70px']} h={['28px']} />
      </HStack>
  )
}

const Categories = ({reportType=null}) => {
  const { data, isLoading, isFetching } = useReportCategories({reportType});
  return (
      <Flex justify={['center']} w={['full']} px={['40px']} py={['20px']} flexWrap={['wrap']}>
      {
        (isLoading || isFetching )? (
          <ChartCategoriesLoader />
        ) : (
          (data?.categories || []).map((category) => <CategoryItem key={category.value} category={category.value} />)
        )
      }
      </Flex>
  )
}

async function exportAsImage(el){
  try{
      const canvas = await html2canvas(el);
      const image = canvas.toDataURL("image/png", 1.0);
      return image;
  } catch(err){
      console.log(err)
      return null;
  }
}


export const ChartSection = ({data, requestType}) => {

    const chartRef = useRef(null);
    const [ imageSrc, setImageSrc ] = useState(null);
    
    useEffect(() => {
        if (chartRef.current != null){
            exportAsImage(chartRef.current).then((image) => {
                setImageSrc(image);
            })
        }
    }, [chartRef])

    const toothClasses = {};

    // go through the block data and convert it to tooth charting object
    // object with key of tooth number "txx" and value of category in all caps for class
    (data?.blocks || []).map((block) => {
        const data = block?.data;
        data.regions.map((region) => {
          if (region.length > 0){
            // will overwrite any previous block styles
            if (toothClasses[`t${region}`]){
                toothClasses[`t${region}`].push(block.data.category.toUpperCase())
            } else {
                toothClasses[`t${region}`] = [block.data.category.toUpperCase()]
            }
          }
        })
    })


    // eslint-disable-next-line no-unused-vars
    const generatePdf = () => {
      const doc = new jsPDF({
          unit: 'px',
          format: 'a4',
          hotfixes: ["px_scaling"]
      });
      doc.text('Hello world!', 10, 20);

      doc.addImage(imageSrc, 'PNG', 0, 40, 220, 360);

      doc.save('a4.pdf');
  }

  const isDMFT = (requestType || "").indexOf("DMFT") !== -1;

  if (isDMFT){

    return( 
      <Box  
        mr={['20px']} 
        bg={'#fff'}
        boxShadow={['0px 0px 2px 0px rgba(0, 0, 0, 0.25)']}
        m={['5px']}
        borderRadius={['15px']}
        ref={chartRef}
      >
        <Flex p={['30px']}>
          
        </Flex>
        <Flex 
          w={['full']}
          justify={'center'} 
          background={'#fff'}
          padding={['20px']}
          borderRadius={['25px']}
          px={['90px']}
        >
          <Flex align={['center']}>
            <Text mr={['5px']}>
              <FormattedMessage
                id={'reportDetailsPage.reviewReportModal.toothChart.right'}
                defaultMessage={'R'}
                />
            </Text>
          </Flex>
          <ToothSetModern
            toothClasses={toothClasses}
            width={190}
            height={360}
          />
          <Flex align={['center']}>
            <Text ml={['5px']}>
              <FormattedMessage
                id={'reportDetailsPage.reviewReportModal.toothChart.left'}
                defaultMessage={'L'}
                />
            </Text>
          </Flex>
        </Flex>
      </Box>
    )
  } else {

    return( 
      <Box  
        mr={['20px']} 
        bg={'#fff'}
        boxShadow={['0px 0px 2px 0px rgba(0, 0, 0, 0.25)']}
        m={['5px']}
        borderRadius={['15px']}
        ref={chartRef}
      >
        <Flex p={['30px']}>
          
        </Flex>
        <Flex 
          w={['full']}
          justify={'center'} 
          background={'#fff'}
          padding={['20px']}
          borderRadius={['25px']}
        >
          <Flex align={['center']}>
            <Text mr={['5px']}>
              <FormattedMessage
                id={'reportDetailsPage.reviewReportModal.toothChart.right'}
                defaultMessage={'R'}
                />
            </Text>
          </Flex>
          <ToothSetModern
            toothClasses={toothClasses}
            width={190}
            height={360}
          />
          <Flex align={['center']}>
            <Text ml={['5px']}>
              <FormattedMessage
                id={'reportDetailsPage.reviewReportModal.toothChart.left'}
                defaultMessage={'L'}
                />
            </Text>
          </Flex>
        </Flex>
        <Categories />
      </Box>
    )
  }

  }

export const ReportSection = ({data}) => {
  const editorInstance = useRef(null);

  const setEditorInstance = (instance) => {
    if (editorInstance?.current == null){
        editorInstance.current = instance;
    }
}


  if (!data?.blocks || data?.blocks?.length == 0){
    return (
      <Box 
        p={0} 
        boxShadow = {'0px 0px 2px 0px rgba(0, 0, 0, 0.25)'} 
        m={['5px']}
        bg={['#fff']}
        borderRadius={['15px']}
      >
        <Skeleton height={['400px']} w={['full']} />
      </Box>
    )
  } else {
    return (
      <Box
        p={0} 
        boxShadow = {'0px 0px 2px 0px rgba(0, 0, 0, 0.25)'} 
        m={['5px']}
        bg={['#fff']}
        borderRadius={['15px']}
      >   
          <ReadOnlyEditor 
            editorInstance={editorInstance.current} 
            setEditorInstance={setEditorInstance} 
            data={data}
            isTitleOn={true}
          />
      </Box>
    )
  }

}

export const ReportReview = ({data, isLoading, isFetching, requestType}) => {
  if (isLoading || isFetching || data?.report?.report_data){  
    return (
        <Flex overflow={'auto'} maxH={['70vh']} justify={['center']}>
            <ChartSection requestType={requestType} data={data?.report?.report_data} />
            <PrintPreviewReportSection type={data?.report?.request_type} data={data?.report?.report_data} isPrint={false} />   
        </Flex>
    )
  } else {
    return (<></>)
  }
}

const ReportReviewModal = ({isOpen, onClose, reportUid, requestType }) => {
  const sendReportMutation = useSendReport({ reportUid: reportUid });
  const { data, isLoading, isFetching } = useReportDetails({ reportId: reportUid });

  const sendReport = async () => {
    try {
      // eslint-disable-next-line no-unused-vars
      const report  = await sendReportMutation.mutateAsync(data)
      queryClient.refetchQueries([REQUEST_QUERY_KEY_NAME])

      //onSuccess(report);
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  return (
      <Modal 
        isOpen={isOpen} 
        onClose={onClose} 
        size={'xl'} 
        closeOnOverlayClick={false} 
        isCentered={true}
      >
      <ModalOverlay />
      <ModalContent minW={'1100px'} bg={'#F7F7F7'}>
          <ModalCloseButton mt={['15px']} onClick={onClose} />
          <ModalHeader bg={'#F7F7F7'} py={['30px']} filter={'drop-shadow(0px -2px 15px rgba(0, 0, 0, 0.08))'}>
              <Text 
                  textTransform={'uppercase'}
                  color={'#1217A'} 
                  fontSize={['15px']} 
                  letterSpacing={['1px']} 
                  textAlign={'center'}
                  fontWeight={[400]}
              >
                  <FormattedMessage
                      id={'reportDetailsPage.reviewReportModal.title'}
                      defaultMessage={'Submit Report'}
                  />
              </Text>
          </ModalHeader>
          <ModalBody pt={['24px']} px={['50px']} h={'650px'}>
              <ReportReview requestType={requestType} data={data} isLoading={isLoading} isFetching={isFetching} />
          </ModalBody>
          <ModalFooter px={[0]}>
              <Box w={'full'}>
                  <Flex justify={['center']} w={'full'}>
                      <Text 
                          color={'#979797'}
                          textAlign={'center'}
                          fontSize={['16px']}

                      >
                          <FormattedMessage 
                              id={'reportDetailsPage.reviewReportModal.footer.description'}
                              defaultMessage={'Please double check the report to avoid errors before submitting.'}
                          />
                      </Text>
                  </Flex>
                  <Flex justify={['center']} py={['15px']} w={'full'}>
                      <Button variant={'outline'} mr={['5px']} size={['lg']} onClick={onClose}>
                          <FormattedMessage
                              id={'reportDetailsPage.reviewReportModal.footer.button.cancel'}
                              defaultMessage={'Cancel'}
                          />
                      </Button>
                      <Button 
                        variant={'solid'} 
                        ml={['5px']} 
                        size={['lg']} 
                        isDisabled={isFetching}
                        onClick={sendReport}
                        isLoading={sendReportMutation.isFetching}
                      >
                          <FormattedMessage
                              id={'reportDetailsPage.reviewReportModal.footer.button.submit'}
                              defaultMessage={'Submit Report'}
                          />
                      </Button>
                  </Flex>
              </Box>
          </ModalFooter>
      </ModalContent>
    </Modal>
  )    
}

ReportReviewModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  reportUid: PropTypes.string.isRequired,
  requestType: PropTypes.string
}

export { ReportReviewModal };