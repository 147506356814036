import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch} from 'react-redux';
import { createSelector } from 'reselect'

import cn from 'classnames';
import moment from 'moment';

import { actions } from './_redux/actions';

import { ThreeDayComponent } from './_components/ThreeDayComponent';
import { DayDateHeader, ThreeDayDateHeader, AgendaDateHeader} from './_components/DateHeaders';

import { Calendar, Views, momentLocalizer } from 'react-big-calendar'
import overlap from 'react-big-calendar/lib/utils/layout-algorithms/overlap'
import { WrappedPage, StyledCalendar, StyledCalendarHeader } from './styles';
import { consoleLog } from '../_helpers/consoleLog';

const getAppointmentsSlice = (state) => state.vidChatAppointments;

const getAppointmentsById = createSelector(
  [getAppointmentsSlice],
  (appointments) => {
    return appointments.byId;
  }
)
const checkUpcomingAppointment = (entry) => {
  if (entry.extra_data && entry.extra_data.video_chat && entry.extra_data.video_chat.chat_date){
    return moment(entry.extra_data.video_chat.chat_date).isValid() && entry.type === "VIDEOCHAT";
  }
  return false
}

const convertToAppointmentModel = (entry) => {
  const addKeys = {}
  if (entry.extra_data && entry.extra_data.video_chat && entry.extra_data.video_chat.chat_date){
    addKeys['video_chat_date'] = moment(entry.extra_data.video_chat.chat_date)
    addKeys['video_chat_date_end'] = moment(entry.extra_data.video_chat.chat_date).add(10, 'minutes')
    addKeys['title'] = `${entry.patient.first_name} ${entry.patient.last_name}`
  } else {
    addKeys['video_chat_date'] = null
    addKeys['video_chat_end'] = null
  }
  if (entry.extra_data && entry.extra_data.video_chat && entry.extra_data.video_chat.session_id){
    addKeys['video_chat_session_id'] = entry.extra_data.video_chat.session_id
  } else {
    addKeys['video_chat_session_id'] = null
  }
  return Object.assign({}, entry, addKeys)
}


const getUpcomingAppointments = createSelector(
  [getAppointmentsById],
  (appointments) => {
    return appointments
      ? Object.values(appointments)
          .filter((data) => checkUpcomingAppointment(data) )
          .map( (entry) => {
            consoleLog(entry);
            return convertToAppointmentModel(entry);
          }).sort( (a,b) => {
            if (a.video_chat_date.isSame(b.video_chat_date)){
              return 0;
            } else if (a.video_chat_date.isAfter(b.video_chat_date) ){
              return 1;
            } else {
              return -1;
            }
          })
      : [];
  }
)



export const CalendarEvent = () => {
  return(
    <div>
      CUSTOM EVENT
    </div>
  )
}

/**
 * Calendar Header for selecting view and displaying header message
 * @param {str} currentView string of view (react-big-calendar.Views.DAY, WEEK, AGENDA, etc.)
 * @param {func} handleViewClick function to change view (setCurrentView)
 */

export const AppointmentCalendarHeader = ({currentView, handleViewClick}) => {
  return(
    <StyledCalendarHeader>
      <div className={'calendar-state'}>
        <p className={'welcome-msg'}>
          {'hello user'}
        </p>
        <p className={"today-label"}>{'TODAY'}</p>
        <p className={"today-date"}>{moment(new Date()).format('dddd, MMMM Do YYYY')}</p>
      </div>
      <div className={'calendar-view-options'}>
        <button className={cn('calendar-view-option', {'calendar-view-option--active': currentView == Views.DAY})}
          onClick={() => handleViewClick(Views.DAY)}>
          {'TODAY'}
        </button>
        <button className={cn('calendar-view-option', {'calendar-view-option--active': currentView == Views.WEEK})}
          onClick={() => handleViewClick(Views.WEEK)}>
          {'3 Day'}
        </button>
        <button className={cn('calendar-view-option', {'calendar-view-option--active': currentView == Views.AGENDA})}
          onClick={() => handleViewClick(Views.AGENDA)}>
          {'LIST'}
        </button>
      </div>
    </StyledCalendarHeader>
  )
}

/**
 * Calendar to return video chat appointments. react-big-calendar library
 * http://jquense.github.io/react-big-calendar/examples/index.html#api
 *
 */
export const AppointmentsCalendar = () => {
  const dispatch = useDispatch();
  const appointments = useSelector(
    getUpcomingAppointments
  );
  const [ currentView, setCurrentView ] = useState(Views.WEEK)



  useEffect(() => {
    dispatch(actions.fetchAppointments());
  }, [])

  // setting up Calendar Component
  const localizer = momentLocalizer(moment)
  let formats = {
    dayFormat: (date, culture, localizer) =>
      localizer.format(date, 'dddd D', culture),
  };
  let views = {
    week: ThreeDayComponent,
    day: true,
    agenda:true
  }
  let eventPropGetter = () => {
    return {style: {}, className: 'blue'}
  }
  // adjustment for 15 minute, overlap algorithm uses 30 for min (defaul cal val)
  // https://github.com/jquense/react-big-calendar/issues/1530#issuecomment-642491080
  let dayLayoutAlgorithm = (params) => {
    return overlap({ ...params, minimumStartDifference: 15 })
  }
  let startAccessor = (event) => {
    // convert from moment to Date for calendar
    return event.video_chat_date.toDate()
  }
  let endAccessor = (event) => {
    // convert from moment to Date for calendar
    return event.video_chat_date_end.toDate()
  }
  const components = {
    day: {
      header: DayDateHeader
    },
    week: {
      header: ThreeDayDateHeader
    },
    agenda: {
      header: AgendaDateHeader
    }
  }

  return (
    <WrappedPage>
      <AppointmentCalendarHeader
        currentView={currentView}
        handleViewClick={setCurrentView}
        />
      <StyledCalendar
        slotHeight={'150' /* min needed for times and event slots to match in calendar */}
        >
        <Calendar
          className={"BigCalendar"}
          dayLayoutAlgorithm={dayLayoutAlgorithm}
          eventPropGetter = {eventPropGetter}
          localizer={localizer}
          defaultView={Views.WEEK}
          view={currentView}
          events={appointments}
          step={15}
          timeslots={4}
          formats={formats}
          scrollToTime={new Date()}
          toolbar={false}
          startAccessor={startAccessor}
          endAccessor={endAccessor}
          views={views}
          components={components}
        />
      </StyledCalendar>
    </WrappedPage>
  )

  /*return (
    <WrappedPage>
      <Calendar
        events={appointments}
        localizer={localizer}
        defaultView={Views.WEEK}
        defaultDate={new Date()}
        startAccessor="video_chat_date"
        endAccessor="video_chat_date_end"
        views={{ week: ThreeDayComponent }}
        style={{height: 800}}
        />
    </WrappedPage>
  )*/
}
