import React, {  useState, useEffect, useRef }  from 'react';
import styled from 'styled-components';
//import { OTSession, OTPublisher, OTStreams, OTSubscriber, preloadScript, createSession } from 'opentok-react';
import { OTPublisher, OTSubscriber, preloadScript, createSession } from 'opentok-react';

import { OPENTOK_API_KEY } from '../../_config';
import { consoleLog } from '../../_helpers/consoleLog';
const VideoChatComponentStyled = styled.div`
  .videochat-subscriber{
    width:48%;
    float:left;
  }

  .videochat-full{
    height:700px;width:100%;
  }

  .videochat-small{
    right:0;bottom:0;
    margin-right:20px;margin-bottom:20px;
    z-index:2;
  }

  video.OT_video-element{
    object-fit:contain !important;
  }
`;
const VideoChatBoxesStyled = styled.div`
  > div:not(:first-child) > div > div{
    right:0;
    bottom:0;
    margin-right:20px;
    margin-bottom:20px;
    z-index:2;
    position: absolute;
    border-radius: 5px;
    border: 2px solid white;

  }
  > div:first-child > div > div{
    height:100% !important;
    width:100% !important;
  }
  > div:only-child > div > div{
    height:100% !important;
    width:100% !important;
  }
  > div:first-child  > div{
    height:100% !important;
    width:100% !important;
  }
  > div:only-child  > div{
    height:100% !important;
    width:100% !important;
  }

`;
const VideoChatBoxStyled = styled.div`
  ${props => props.addCss}
`;
const ButtonsOverlayStyled = styled.div`
  bottom: 0;
  height: auto;
  left: 0;
  position: absolute;
  text-align: center;
  right: 0;
`;
const ButtonsStyled = styled.div`
  margin-bottom: 0;
  text-align: center;
  white-space: nowrap;
  pointer-events: none;
  position: relative;
  -webkit-transition: transform 250ms ease-out;
  transition: transform 250ms ease-out;
`;
const EndButtonStyled = styled.button`

    pointer-events:all;
    margin-bottom:35px !important;
`;

const EndButton = ({handleClick}) => {
  return (
    <EndButtonStyled
      onClick={() => handleClick()}>
      {"End Session"}
    </EndButtonStyled>
  )
}

const VideoChatComponent = ({sessionId, token, handleClose}) => {
  const [ streams, setStreams ] = useState([]);
  const [height, setHeight] = useState(0)
  const [width, setWidth] = useState(0)
  const [ publisherStyles, setPublisherStyles]  = useState({})
  const [ subscriberStyles, setSubscribersStyles]  = useState({})
  const ref = useRef(null);

  const updateStreams = (streams) => {
    if (streams.length == 1){
      setPublisherStyles({})
      setSubscribersStyles({height})
      setStreams(streams)
    } else {
      setPublisherStyles({})
      setStreams(streams)
    }
  }
  useEffect( () => () => consoleLog("unmount", width), [] );
  useEffect(() => {
    if (ref.current){
      setHeight(ref.current.clientHeight)
      setWidth(ref.current.clientWidth)
    }
    function handleResize() {
      if (ref.current){
        setHeight(ref.current.clientHeight)
        setWidth(ref.current.clientWidth)
      }
    }
    window.addEventListener('resize', handleResize)
  })
  const sessionHelper = createSession({
    apiKey: OPENTOK_API_KEY,
    sessionId: sessionId,
    token: token,
    onStreamsUpdated: (streams) => {
      updateStreams(streams);
    }
  })

  const subscriberProps = {preferredFrameRate: 30, showControls: false};
  const publisherProps = {audioFallbackEnabled: false, showControls: false}
  const subscriberEvents = {
    videoDisabled: (event) => {
      consoleLog('Subscriber video disabled!', event);
    },
    videoEnabled: (event) => {
      consoleLog('Subscriber video enabled!', event);
    }
  }
  const publisherEvents = {
    streamCreated: (event) => {
      consoleLog('Publisher stream created!', event);
    },
    streamDestroyed: (event) => {
      consoleLog('Publisher stream destroyed!', event);
    }
  }

  return (
    <VideoChatComponentStyled ref={ref}>
      <VideoChatBoxesStyled>
        {streams.map((stream, index) => {
          return(
            <VideoChatBoxStyled key={index} addCss={subscriberStyles}>
              <OTSubscriber
                style={subscriberStyles}
                key={stream.id}
                session={sessionHelper.session}
                stream={stream}
                properties={subscriberProps}
                eventHandlers={subscriberEvents}
              />
            </VideoChatBoxStyled>
          )
        })}
        <VideoChatBoxStyled addCss={publisherStyles}>
          <OTPublisher
            properties={publisherProps}
            eventHandlers={publisherEvents}
            session={sessionHelper.session}
          />
        </VideoChatBoxStyled>
      </VideoChatBoxesStyled>
      <ButtonsOverlayStyled>
        <ButtonsStyled>
          <EndButton
            handleClick={handleClose}
          />
        </ButtonsStyled>
      </ButtonsOverlayStyled>
    </VideoChatComponentStyled>
  )
}


const loadedVideoChat = preloadScript(VideoChatComponent);
export { loadedVideoChat as VideoChatComponent};
