import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch} from 'react-redux';
import Modal from 'react-modal';
import styled from 'styled-components';
import { upperFirst, toUpper } from 'lodash';
import { GRADES } from '../_config';
import { Form, Field } from 'react-final-form';

// redux state
import { actions } from './_redux/actions';

import { getAppointmentCompleteModalOpen } from './_redux/selectors';

// components
import { CloseIcon as CloseSvg } from '../_images/icons/CloseIcon';
import { SelectAdapterNonClearable } from '../_components/FormElements';

//actions
import { actions as reportActions } from '../PatientReports/_redux/actions';
import { actions as patientNoteActions } from '../PatientNotes/_redux/actions';

// services
import { services as appointmentServices } from '../Appointments/_redux/services';
import { services as patientNoteServices } from '../PatientNotes/_redux/services';

// styles
import {
  StyledInputLayout,
  StyledTextArea,
  StyledButton,
  ErrorLabel,
  FlexColumn,
  FlexRow,
  errorCss,
  generalModalStyles,
  ModalCloseIcon
} from '../_css/styles'
import { consoleLog } from '../_helpers/consoleLog';
import { getCurrentAppointmentRequest } from '../Appointments/_redux/selectors';
import { getIntlMessages } from '../App/_redux/selectors';
import { FORM_ERROR } from 'final-form';


const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const StyledHeader = styled.div`
  padding: 20px 0;
  font-size: 1.2rem;
  text-align:center;
`;

const StyledAppointmentCompleteWrapper = styled.div`
  padding: 30px 50px;
  width: 500px;
`;

const AppointmentCompleteForm_Finished = ({next, summary}) => {
  const [ loading, setLoading ] = useState(false)

  const handleFinish = async () => {
    setLoading(true);
    // update appointment status as complete.
    await sleep(300);
    next();
    setLoading(false);

  }

  return (
    <StyledAppointmentCompleteWrapper>
      <StyledHeader>{'Appointment Summary'}</StyledHeader>
      <FlexColumn addCss={'padding-bottom:0px;'}>
        <FlexRow addCss={'justify-content:space-between'}>
          <span>{'PATIENT'}</span>
          <span>{'Fayja Coolas'}</span>
        </FlexRow>
        <FlexRow addCss={'justify-content:space-between'}>
          <span>{'ID'}</span>
          <span>{'1233231s23123'}</span>
        </FlexRow>
        <FlexRow addCss={'justify-content:space-between'}>
          <span>{'DOB'}</span>
          <span>{'01/01/2000'}</span>
        </FlexRow>
        <FlexRow addCss={'justify-content:space-between'}>
          <span>{'APPOINTMENT TIME'}</span>
          <span>{'10/12/2021 5:00pm'}</span>
        </FlexRow>
        <FlexColumn>
          <span>{'SUMMARY'}</span>
            {
              (summary?.length > 0)
                ? <div style={{whiteSpace: "pre"}}>{summary}</div>
                : <div>{"None"}</div>
            }
        </FlexColumn>
        <FlexColumn>
          {
            <StyledButton disabled={loading} onClick={handleFinish}>{'Complete Appointment'}</StyledButton>
          }
        </FlexColumn>
      </FlexColumn>
    </StyledAppointmentCompleteWrapper>
  )
}

const AppointmentCompleteForm_SendSummary = ({next, updateSummary}) => {
  const intlMessages = useSelector(getIntlMessages);
  const currentAppointmentRequest = useSelector(getCurrentAppointmentRequest);
  const dispatch = useDispatch();
 
  const handleSubmit = async (values) => {
    // send message to patient and comment on appointment?
    updateSummary(values.remark)
    try{
      const resp = await appointmentServices.updateAppointment(currentAppointmentRequest?.unique_id, values);
      dispatch(reportActions.updateSendReport(resp.report))
      next()
    } catch (err){
      consoleLog("ERROR completing appointment ")
      return {
        ...err,
        [FORM_ERROR] : upperFirst(intlMessages['form.error.general.error'])
      }
    }
    return null;
  }

  return (
    <StyledAppointmentCompleteWrapper>
      <StyledHeader>{'Leave a quick summary of the video chat for the patient'}</StyledHeader>
      <div>
        <Form
          onSubmit={handleSubmit}
          render = {({
            handleSubmit,
            submitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <Field name="remark" parse={v => v} >
                {({ input, meta}) => (
                  <StyledInputLayout>
                      <StyledTextArea
                        {...input}
                        columns={20}
                        disabled={submitting}
                        rows={10}
                        type="text"
                        addCSS={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)) && errorCss}
                        placeholder={'Leave a quick summary'}
                      />
                    <ErrorLabel
                      isError={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit))}
                      >
                        {((meta.error && meta.touched)  || (meta.submitError && !meta.dirtySinceLastSubmit)) && 'error' || meta.submitError}
                    </ErrorLabel>
                  </StyledInputLayout>
                )}
              </Field>

              <Field
                name="grade"
                items={GRADES}
                label={toUpper(intlMessages['requestDetailPage.report.form.grade'])}
                component={SelectAdapterNonClearable}
                placeholder={upperFirst(intlMessages['requestDetailPage.report.form.grade'])}
              />
              <FlexRow>
                <StyledButton type="submit" disabled={submitting}>
                  {'Save & Finish'}
                </StyledButton>
              </FlexRow>
            </form>
          )}
        />
      </div>
    </StyledAppointmentCompleteWrapper>
  )
}

const AppointmentCompleteForm_PatientNote = ({next, patientUid}) => {
  const dispatch = useDispatch();
  const intlMessages = useSelector(getIntlMessages);

  const handleSkip = () => {
    next();
  }

  const handleSubmit = async (values) => {
    // send message to patient and comment on appointment?
    try{
      const res = await patientNoteServices.createPatientNote(patientUid, values.text)
      dispatch(patientNoteActions.addPatientNote(patientUid, res))
      next();
    } catch (err){
      return {
        ...err,
        [FORM_ERROR] : upperFirst(intlMessages['form.error.general.error']) 
      }
    }
    
    return null;
  }

  return (
    <StyledAppointmentCompleteWrapper>
      <StyledHeader>{'Create an Internal Patient Note'}</StyledHeader>
      <div>
        <Form
          onSubmit={handleSubmit}
          render = {({
            handleSubmit,
            submitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <Field name="text" parse={v => v}>
                {({ input, meta}) => (
                  <StyledInputLayout>
                      <StyledTextArea
                        {...input}
                        columns={20}
                        disabled={submitting}
                        rows={10}
                        type="text"
                        addCSS={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)) && errorCss}
                        placeholder={'Create note'}
                      />
                    <ErrorLabel
                      isError={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit))}
                      >
                        {((meta.error && meta.touched)  || (meta.submitError && !meta.dirtySinceLastSubmit)) && 'error' || meta.submitError}
                    </ErrorLabel>
                  </StyledInputLayout>
                )}
              </Field>
              <FlexRow>
                <StyledButton className={'outlined'} onClick={handleSkip} disabled={submitting} type="button">
                  {'Skip'}
                </StyledButton>
                <StyledButton disabled={submitting} type="submit">
                  {'Save & Continue'}
                </StyledButton>
              </FlexRow>
            </form>
          )}
        />
      </div>
    </StyledAppointmentCompleteWrapper>
  )
}

const AppointmentCompleteForm_Init = ({next, closeModal}) => {


  return (
    <StyledAppointmentCompleteWrapper>
      <StyledHeader>{'Was the appointment completed?'}</StyledHeader>
      <FlexColumn addCss={'padding-bottom:0px;'}>
        <StyledButton onClick={next}>{'Yes'}</StyledButton>
        <StyledButton onClick={closeModal}>{'No'}</StyledButton>
      </FlexColumn>
    </StyledAppointmentCompleteWrapper>
  )
}

export const AppointmentCompleteModal = ({patientUid}) => {
  const modalIsOpen = useSelector(getAppointmentCompleteModalOpen);
  const currentAppointmentRequest = useSelector(getCurrentAppointmentRequest);
  const dispatch = useDispatch();
  const summary = useRef("");
  const [ page, setPage ] = useState(0);

  useEffect(() => {
    return () => {};
  }, [])

  const afterOpenModal = () => {
    /* all refs are set*/
  }

  const closeModal = () => {
    dispatch(actions.closeAppointmentCompleteModal())
  }

  const setSummary = (val) => {
    summary.current = val;
  }

  const _showPage = (pg) => {
    if (pg == 0){
      return (
        <AppointmentCompleteForm_Init
          next={() => setPage(1)}
          closeModal={closeModal}
          />
      )
    } else if (pg == 1){
      return (
        <AppointmentCompleteForm_PatientNote
          patientUid={patientUid}
          next={() => setPage(2)}
          />
      )
    } else if (pg == 2){
      return (
        <AppointmentCompleteForm_SendSummary
          next={() => setPage(3)}
          currentAppointmentRequest={currentAppointmentRequest}
          updateSummary={setSummary}
          />
      )
    } else {
      return (
        <AppointmentCompleteForm_Finished
          next={closeModal}
          summary={summary.current}
          />
      )
    }
  }

  return (
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={generalModalStyles}
      shouldCloseOnOverlayClick={false}
      contentLabel="Appointment Complete Form"
    >
      <div style={{ position: 'relative', height: '100%'}}>
        <ModalCloseIcon onClick={closeModal} ><CloseSvg/ ></ModalCloseIcon>
          {_showPage(page)}
      </div>
    </Modal>
  )
}
