import { constants } from './constants';

export const actions = {
  selectIndex,
  addVideoFrames,
  update,
  updateState,
  updateStateObject,
};

function selectIndex(index) {
  return (dispatch) => {
    dispatch({ type: constants.SELECT_INDEX, data: index });
  };
}

function addVideoFrames(uploadId, timestamp, frame) {
  return (dispatch, getState) => {
    // TODO getState, dispatch
    const state = getState();
    let videoFrames = state.mediaSection.videoFrames;
    // add to id and stuff
    videoFrames[uploadId] = videoFrames[uploadId]
      ? [...videoFrames[uploadId], { timestamp, frame }]
      : [{ timestamp, frame }];
    dispatch(update({ key: 'videoFrames', value: videoFrames }));
  };
  function update(data) {
    return { type: constants.UPDATE_STATE_OBJECT, data };
  }
}

function update(data) {
  return (dispatch) => {
    dispatch(update(data));
  };
  function update(data) {
    return { type: constants.UPDATE, data };
  }
}

function updateState(key, value) {
  return (dispatch) => {
    dispatch(update({ key, value }));
  };
  function update(data) {
    return { type: constants.UPDATE_STATE, data };
  }
}

function updateStateObject(key, value) {
  return (dispatch) => {
    dispatch(update({ key, value }));
  };
  function update(data) {
    return { type: constants.UPDATE_STATE_OBJECT, data };
  }
}
