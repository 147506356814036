import { constants } from './constants';

const initState = {
  profileLoading: false,
  chatLoading: false,
  profile: {},
  chat: {},
  profileError: false,
  chatError: false,
};

export function userInfo(state = initState, action) {
  switch (action.type) {
    case constants.USER_PROFILE_REQUEST:
      return {
        ...state,
        profileLoading: true,
      };
    case constants.USER_PROFILE_SUCCESS:
      return {
        ...state,
        profile: action.data,
        profileLoading: false,
        profileError: false,
      };
    case constants.USER_PROFILE_FAILURE:
      return {
        ...state,
        profile: {},
        profileLoading: false,
        profileError: true,
      };
    case constants.USER_CHAT_INITIALIZATION_REQUEST:
      return {
        ...state,
        chatLoading: true,
      };
    case constants.USER_CHAT_INITIALIZATION_SUCCESS:
      return {
        ...state,
        chat: action.data,
        chatLoading: false,
        chatError: false,
      };
    case constants.USER_CHAT_INITIALIZATION_FAILURE:
      return {
        ...state,
        chat: {},
        chatLoading: false,
        chatError: true,
      };
    case constants.UPDATE_OBJECT:
      return {
        ...state,
        [action.data.key]: Object.assign({}, state[action.data.key], action.data.value),
      };
    case constants.UPDATE:
      return {
        ...state,
        ...action.data,
      };
    default:
      return state;
  }
}
