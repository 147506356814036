import React, { useEffect } from 'react';
import { Form, Field } from 'react-final-form';

import axios from 'axios'

import { cloneDeep } from "lodash";
import { Square, Box, Flex, HStack, SimpleGrid, Text, Button, Spinner, FormLabel,
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody,
    useDisclosure } from '@chakra-ui/react';
import { SelectOptionField } from 'src/_components/Forms/SelectOptionField';
import { FormattedMessage, useIntl } from 'react-intl';
import { required } from 'src/_components/FormElements';
import { useSelectOptions } from '../api/getSelectOptions';
import { UserSearch } from './UserSearch'

import { createRecord, createUserRequest, upload } from '../api/services';
import { convertToAPIValues } from 'src/_libs/forms';
import { updateUserRequestDetails, useUserRequestDetails } from '../api/getUserRequestDetails';
import { UserProfileSearch } from './UserProfileSearch';
import { ConfirmationDialog } from './ConfirmationDialog';
import { useUploadScanStore } from '../stores/uploadScans';

import { ScanRegionTitle, UploadPreview, UploadPlaceholder, UploadThumbnail } from './UploadComponents';
import { RECORD_TYPES } from 'src/_config';
import { ClinicDentistSearch } from './DentistSearch';
import { RequestCreatedDialog } from './RequestCreatedDialog';

export const EditUserRequestForm = ({
    onSuccess,
    onClose,
    requestId
}) => {
    const { data, isLoading, isFetching } = useUserRequestDetails({requestId});

    const handleSuccess = (data) => {
        updateUserRequestDetails(data?.id, data)
        onSuccess && onSuccess();
    }

    if (isLoading || isFetching){
        return ( 
            <Flex w={['full']} align={'center'} justify={'center'} p={10}>
                <Spinner 
                    size='xl'
                    thickness='4px'
                    speed='0.65s' 
                    color='#44C5A6'
                />
            </Flex>
        )
    }else {
        return <UserRequestForm onSuccess={handleSuccess} onClose={onClose} initValues={data} />
    }
}

const formatInitValues = (values) => {
    // format select search fields
    if (!values?.user){
        return values;
    }
    return {
        ...values,
        user: {
            ...values?.user,
            data: values?.user
        }
    }
}


const ScanRegion = ({region, selectedFile}) => {
    const removeRegion = useUploadScanStore(state => state.removeRegion);
    const setRegion = useUploadScanStore(state => state.setRegion);

    const fileInputRef = React.useRef(null);
    const [dragOver, setDragOver] = React.useState(false);

    const handleFileInputClick = () => {
        // Trigger the file input click event
        fileInputRef.current.click();
    };

    const handleDragOver = (event) => {
        event.preventDefault();
        if (!dragOver) setDragOver(true);
    };

    const handleDragEnter = (event) => {
        event.preventDefault();
        setDragOver(true);
    };

    const handleDragLeave = (event) => {
        event.preventDefault();
        setDragOver(false);
    };

    const handleDrop = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setDragOver(false);
        const file = event.dataTransfer.files[0];
        processFile(file);
    };


    const handleRemove = () => {
      // Reset the file input
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
      //setPreviewUrl('');
      // Clear the selected file state
      //setSelectedFile(null);
      if (region != null){
          removeRegion(region)
      }
    };

    const handleFileChange = (event) => {
        processFile(event.target.files[0]);
    };

    const processFile = (file) => {
        if (!file) {
            return;
        }
        //if (selectedFile?.previewUrl) {
        //    URL.revokeObjectURL(selectedFile?.previewUrl);
        //}
        setRegion(region, file)
    };

    const boxSize = '110px';
    const innerBoxSize = '100px';

    return (
        <Flex  direction={'column'} align={'center'}>
            <Flex>
                <Text
                    fontSize={'16px'}
                    color={'#7B8887'}
                    textTransform={'capitalize'}
                >
                    <ScanRegionTitle region={region} /> 
                </Text>
            </Flex>
            <Square 
                mt={'15px'}
                borderRadius={'10px'}
                pos={'relative'}
                size={boxSize}
                align={'center'}
                bg={dragOver ? '#35BE9D' : 'transparent'}
                border={'2px dashed #6B8A99'}
            >
                <Box w={['full']} pos={'absolute'}>
                    <input 
                        type="file" 
                        ref={fileInputRef}
                        hidden 
                        onChange={handleFileChange} 
                        style={{display: 'none'}}
                        accept="video/*" 
                    />
                    { selectedFile ? (
                        <UploadPreview
                            selectedFile={selectedFile}
                            innerBoxSize={innerBoxSize}
                            handleRemove={handleRemove}
                        />
                    ) : (
                        <UploadPlaceholder 
                            innerBoxSize={innerBoxSize}
                            dragOver={dragOver}
                            handleDragOver={handleDragOver}
                            handleDragEnter={handleDragEnter}
                            handleDragLeave={handleDragLeave}
                            handleDrop={handleDrop}
                            handleFileInputClick={handleFileInputClick}
                        />
                    )}
                </Box>

            </Square>
        </Flex>
    )
}

const UploadSubmitButton = ({isLoading, handleUploadClick, onComplete, isDisabled, isCompleted}) => {
    useEffect(() => {
        if (isCompleted){
            onComplete();
        }
    }, [isCompleted])


    const handleClick = () => {
        if (isCompleted){
            onComplete()
        } else {
            if (!isLoading){
                handleUploadClick()
            }
        }
    }
    return (
        <Button
            isDisabled={isDisabled}
            isLoading={!isCompleted && isLoading} 
            onClick={handleClick}
        >
            { isCompleted ? (
                    <FormattedMessage 
                        id="adminPage.form.finish.button.label"
                        defaultMessage="Next"
                    />
                ) : (
                    <FormattedMessage 
                        id="adminPage.form.upload.button.label"
                        defaultMessage="Upload"
                    />
                )
            }
        </Button>
    )
}

const UploadsForm = ({userId, profileId, onError, onClose, onSuccess}) => {
    const currentRecordRef = React.useRef(null);

    const isUploading = useUploadScanStore(state => state.isUploading);
    const clearUploads = useUploadScanStore(state => state.clearUploads);
    const setIsUploading = useUploadScanStore(state => state.setIsUploading);  
    const updateRegion = useUploadScanStore(state => state.updateRegion);
    const region0 = useUploadScanStore(state => state.region0);
    const region1 = useUploadScanStore(state => state.region1);
    const region2 = useUploadScanStore(state => state.region2);
    const region3 = useUploadScanStore(state => state.region3);

    const colSpacing = 4;

    const handleRecordError = () => {  
        clearUploads();
        onError && onError();
        onClose();
    }

    const handleUpload =  async () => {
        
        if (region0 && region1 && region2 && region3) {
                try {
                    // create record here with API
                    const data = {
                        "user": userId,
                        "user_profile": profileId,
                        "type": RECORD_TYPES.FULLSCAN,
                        "extra_data": null
                    }
                    const recordResp = await createRecord(userId, data);
                    currentRecordRef.current = recordResp.id;
                } catch(err){
                    console.log("UPLOAD ERROR: ", err)
                    handleRecordError();
                    return;
                }
                let uploadList = [ 
                    region0, region1, region2, region3 
                ].map((selectedFile, index) => {
                    return  upload(
                                userId,
                                selectedFile?.data, 
                                currentRecordRef.current, 
                                (event) => {
                                    // HACK set final progress to 95 because there is a delay between progress and finished upload
                                    updateRegion(index, {progress: Math.round((event.loaded) / event.total), isUploading: true})
                                }, 
                                selectedFile.extraData
                    )
                })
                axios.all(uploadList)
                    .then( axios.spread((...responses) => {
                        responses.map((resp, index) => {
                            updateRegion(index, {uploadData: resp.data, isUploading: false, uploadCompleted: true})
                        })
                    })).catch(errs => {
                        console.log(errs);
                        // TODO errs lenght or check if object
                        if (errs.length > 0){
                            errs.map((err, index) => {
                                updateRegion(index, {uploadError: err})
                            })
                        } else if (typeof errs == typeof {}){
                            updateRegion(0, {uploadError: JSON.stringify(errs)})
                        } else {
                            updateRegion(0, {uploadError: errs})
                        }
                    })
        }
    }

    const handleUploadClick = () => {
        if (isUploading){
            return
        }
        setIsUploading(true)
        handleUpload()
    }

    const handleFinish = () => {
        // closes in parent component, so just send the record id
        onSuccess && onSuccess(currentRecordRef.current);
    }

    return (
        <Box position={'relative'} height={'300px'} overflow={'auto'}>
            <SimpleGrid>
                { /*<div className={'loading'}><LoadingEllipses /></div>*/}
                <HStack w={['full']} minW={`650px`} px={['40px']} py={['30px']} spacing={'25px'}>
                {
                    [region0, region1, region2, region3].map((selectedFile, region) => {
                        return (
                            <ScanRegion region={region} selectedFile={selectedFile} key={region} />
                        )
                    })
                }
                </HStack>
            </SimpleGrid>
            <SimpleGrid columns={[2]} spacing={colSpacing}  mt={10}>
                <Button 
                    variant={'outline'} 
                    onClick={onClose} 
                    isDisabled={isUploading || (region0?.uploadCompleted && region1?.uploadCompleted && region2?.uploadCompleted && region3?.uploadCompleted)}
                >
                    <FormattedMessage 
                        id="adminPage.form.cancel.button.label"
                        defaultMessage="Cancel"
                    />
                </Button>
                <UploadSubmitButton 
                    isCompleted={region0?.uploadCompleted && region1?.uploadCompleted && region2?.uploadCompleted && region3?.uploadCompleted}
                    isDisabled={
                        !region0?.data ||
                        !region1?.data ||
                        !region2?.data ||
                        !region3?.data
                    }
                    onComplete={handleFinish}
                    isLoading={isUploading} 
                    handleUploadClick={handleUploadClick}
                />
            </SimpleGrid>
        </Box>
    )
}

const UploadsFormModal = ({userId, profileId, onSuccess, onClose, isOpen}) => {
    return (
        <Modal isCentered isOpen={isOpen}>
            <ModalOverlay />
            <ModalContent minW={'800px'}>
                <ModalHeader>
                    <FormattedMessage 
                        id={'adminPage.form.uploadScans.title'}
                        defaultMessage='Upload Scans'
                    />
                </ModalHeader>
                <ModalBody>
                    <UploadsForm 
                        userId={userId}
                        profileId={profileId}
                        onClose={onClose}
                        onSuccess={onSuccess}
                    />
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

const UserSelectForm = ({
    isShowing,
    onSuccess,
    onClose,
}) => {
    const { formatMessage } = useIntl();
    const colSpacing = 4;
    const rowSpacing = 4;

    const handleSubmit = (values) => {
        // set the user for continuous request creation
        onSuccess(values);
        return null;
    }

    return (
        <Box display={isShowing ? 'block' : 'none'}>
            <Form
                onSubmit={handleSubmit}
                render = {
                    ({
                        handleSubmit,
                        values
                    }) => (
                        <Box as={'form'} id={'userSelectField'} onSubmit={handleSubmit}>
                            <SimpleGrid columns={[1]} spacing={colSpacing} mt={rowSpacing}>
                                <Text>
                                    <FormattedMessage
                                        id="adminPage.form.select_user.description"
                                        defaultMessage="Select a user to continue creating a request."
                                    />
                                </Text>
                            </SimpleGrid>
                            <SimpleGrid columns={[1]} spacing={colSpacing} mt={rowSpacing}>
                                <Field name="user">
                                    {({input, meta}) => (
                                        <UserSearch   
                                            input={input}
                                            meta={meta}
                                            isInvalid={meta.touched && meta.error ? true : false}
                                            error={meta?.error}
                                            label={formatMessage({id: 'adminPage.form.field.user.label', defaultMessage: 'User'})}
                                            placeholder={formatMessage({id: 'adminPage.form.field.user.placeholder', defaultMessage: 'Search for a user'})}
                                            onSelect={(val) => {
                                                input.onChange(val);
                                            }}
                                        />
                                    )}
                                </Field>
                            </SimpleGrid>
                            <SimpleGrid columns={[2]} spacing={colSpacing}  mt={'50px'}>
                                <Button variant={'outline'} onClick={onClose}>
                                    <FormattedMessage 
                                        id="adminPage.form.cancel.button.label"
                                        defaultMessage="Cancel"
                                    />
                                </Button>
                                <Button type="submit" isDisabled={!values?.user}>
                                    <FormattedMessage 
                                        id="adminPage.form.next.button.label"
                                        defaultMessage="Next"
                                    />
                                </Button>
                            </SimpleGrid>
                            <SimpleGrid columns={[1]} spacing={colSpacing} mt={rowSpacing}>
                            
                            </SimpleGrid>
                        </Box>
                )} 
            />
        </Box>
    )
}

const ScanThumbnails = ({region0, region1, region2, region3}) => {
    const innerBoxSize = '50px'
    return (
        <HStack spacing={'5px'} ml={'20px'}>
            <UploadThumbnail selectedFile={region0} innerBoxSize={innerBoxSize} />
            <UploadThumbnail selectedFile={region1} innerBoxSize={innerBoxSize} />
            <UploadThumbnail selectedFile={region2} innerBoxSize={innerBoxSize} />
            <UploadThumbnail selectedFile={region3} innerBoxSize={innerBoxSize} />
        </HStack>
    )
}

const CreateUserRequestForm = ({
    user, 
    onUnsetUser,
    onSuccess,
    onClose,
    initValues = {},
  }) => {
    const { data : requestTypesData, isLoading : requestTypesIsLoading, isFetching : requestTypesIsFetching } = useSelectOptions({'option': 'request_types'});
    const { isOpen: isUploadFormOpen, onClose: onUploadFormClose, onOpen: onUploadFormOpen } = useDisclosure();
    const { isOpen: isCreatedDialogOpen, onClose: onCreatedDialogClose, onOpen: onCreatedDialogOpen } = useDisclosure();
    const [recordId, setRecordId] = React.useState(null);
    const { formatMessage } = useIntl();
    const clearUploads = useUploadScanStore(state => state.clearUploads);
    const region0 = useUploadScanStore(state => state.region0);
    const region1 = useUploadScanStore(state => state.region1);
    const region2 = useUploadScanStore(state => state.region2);
    const region3 = useUploadScanStore(state => state.region3);
    
    const colSpacing = 4;
    const rowSpacing = 4;

    const handleSubmit = async (values) => {
        const valuesCopy = cloneDeep(values);
        const convertedValues = convertToAPIValues({...valuesCopy, user: user, record: recordId});
        try{
            await createUserRequest(convertedValues);
            onCreatedDialogOpen();
            clearUploads();
            return null;
        } catch (err) {
            return {
                ...err,
            };
        }
    }

    const isEditForm = initValues.id ? true : false;

    const onUploadSuccess = (values) => {
        setRecordId(values?.value ? values?.value : values)
        onUploadFormClose();
    }

    const handleCreatedDialogClose = () => {   
        // close this form
        onCreatedDialogClose();
        onSuccess && onSuccess();
    }

    return (
        <Form
            onSubmit={handleSubmit}
            initialValues={formatInitValues(initValues)} // format dentist value to add data field for select option componentf
            render = {
                ({
                    handleSubmit,
                    submitting,
                    values
                }) => {
                    return (
                            <Box as={'form'} onSubmit={handleSubmit}>
                                <RequestCreatedDialog 
                                    isOpen={isCreatedDialogOpen}
                                    onClose={handleCreatedDialogClose}
                                    name={values?.user_profile?.label}
                                    requestType={values?.type?.label}
                                />
                                <SimpleGrid columns={[1]} spacing={colSpacing} spacingY={'5px'} mt={rowSpacing} mb={'30px'}>
                                    <Text>
                                        <FormattedMessage
                                            id="adminPage.form.create_request.description"
                                            defaultMessage="Creating a request for the selected user."
                                        />
                                    </Text>
                                    <Flex>
                                        <Text>
                                            {user?.data?.email}
                                        </Text>
                                        <Button variant={'link'} ml={'8px'} onClick={onUnsetUser}>
                                            <FormattedMessage
                                                id="adminPage.form.unset_user.button.label"
                                                defaultMessage="Change User"
                                            />
                                        </Button>
                                    </Flex>
                                </SimpleGrid>
                                <SimpleGrid columns={[1]} spacing={colSpacing} mt={rowSpacing}>
                                    <Field name="type" validate={required} >
                                        {({input, meta}) => (
                                            <SelectOptionField 
                                                isDisabled={isEditForm}
                                                isLoading={requestTypesIsFetching || requestTypesIsLoading}
                                                label={formatMessage({id: 'adminPage.form.field.request_type.label', defaultMessage: 'Request Type'})}
                                                placeholder={formatMessage({id: 'adminPage.form.field.request_type.label', defaultMessage: 'Request Type'})}
                                                input={input}
                                                selectOptions={requestTypesData?.options}
                                                isInvalid={meta.touched && meta.error ? true : false}
                                                error={meta?.error}
                                            />
                                        )}
                                    </Field>
                                </SimpleGrid>
                                <SimpleGrid columns={[1]} spacing={colSpacing} mt={rowSpacing}>
                                    <Field name="user_profile">
                                        {({input, meta}) => (
                                            <UserProfileSearch   
                                                userId={user?.value}
                                                input={input}
                                                meta={meta}
                                                isInvalid={meta.touched && meta.error ? true : false}
                                                error={meta?.error}
                                                label={formatMessage({id: 'adminPage.form.field.user_profile.label', defaultMessage: 'User Profile'})}
                                                placeholder={formatMessage({id: 'adminPage.form.field.user_profile.placeholder', defaultMessage: 'Search for a user profile'})}
                                                onSelect={(val) => {
                                                    input.onChange(val);
                                                }}
                                            />
                                        )}
                                    </Field>
                                </SimpleGrid>
                                <SimpleGrid columns={[1]} colSpacing={colSpacing} rowSpacing={0} mt={rowSpacing}>
                                    <FormLabel>
                                        <FormattedMessage 
                                            id={'adminPage.form.uploads.label'}
                                            defaultMessage={'Scans'}
                                        />
                                    </FormLabel>
                                    <Flex align={'center'}>
                                        <Button 
                                            variant={'outline'} 
                                            onClick={onUploadFormOpen} 
                                            isDisabled={
                                                submitting || !values?.user_profile || (
                                                    region0?.uploadCompleted && region1?.uploadCompleted && region2?.uploadCompleted && region3?.uploadCompleted
                                                )}
                                        >
                                            <FormattedMessage
                                                id="adminPage.form.upload.button.label"
                                                defaultMessage="Start Uploads"
                                            />
                                        </Button>
                                        <ScanThumbnails
                                            region0={region0}
                                            region1={region1}
                                            region2={region2}
                                            region3={region3}
                                        />
                                    </Flex>
                                    <UploadsFormModal
                                        userId={user?.value}
                                        profileId={values?.user_profile?.value}
                                        onSuccess={onUploadSuccess}
                                        onClose={onUploadFormClose}
                                        isOpen={isUploadFormOpen}
                                    />
                                </SimpleGrid>
                                <SimpleGrid columns={[1]} spacing={colSpacing} mt={rowSpacing}>
                                    <Field name="clinic_dentist">
                                        {({input, meta}) => (
                                            <ClinicDentistSearch
                                                input={input}
                                                meta={meta}
                                                isInvalid={meta.touched && meta.error ? true : false}
                                                error={meta?.error}
                                                label={formatMessage({id: 'adminPage.form.field.request_dentist.label', defaultMessage: 'Dentist (Optional)'})}
                                                placeholder={formatMessage({id: 'adminPage.form.field.accountrole.request_dentist.placeholder', defaultMessage: 'Search for a dentist'})}
                                                onSelect={(val) => {
                                                    input.onChange(val);
                                                }}
                                            />
                                        )}
                                    </Field>
                                </SimpleGrid>
    
                                <SimpleGrid columns={[2]} spacing={colSpacing}  mt={10}>
                                    <Button variant={'outline'} onClick={onClose} isDisabled={submitting}>
                                        <FormattedMessage 
                                            id="adminPage.form.cancel.button.label"
                                            defaultMessage="Cancel"
                                        />
                                    </Button>
                                    <Button type="submit" 
                                        isLoading={submitting} 
                                        isDisabled={(recordId == null) || (values?.user_profile?.value == null) || (values?.type?.value == null) }
                                    >
                                        <FormattedMessage 
                                            id="adminPage.form.submit.button.label"
                                            defaultMessage="Submit"
                                        />
                                    </Button>
                                </SimpleGrid>
    
                            </Box>
                    )
                }
            }
        />
    )
}

export const UserRequestForm = ({
    onSuccess,
    onClose
}) => {
    // select user then create request
    const [user, setUser] = React.useState(null);
    const [isUserSet, setIsUserSet] = React.useState(false);
    const { isOpen, onOpen, onClose: onConfirmClose } = useDisclosure();

    const handleOnUserSelect = (values) => {
        if (values.user?.value != user?.value){
            setUser(values?.user);
        }
        setIsUserSet(true);
    }

    const handleRequestFormClose = () => {
        onOpen();
    }

    const handleUnsetUser = () => {
        setIsUserSet(false);
    }

    const handleOnCreateRequestSuccess = () => {
        setIsUserSet(false);
        onSuccess();
    }

    return (
        <>
            <UserSelectForm isShowing={isUserSet == false} onSuccess={handleOnUserSelect} onClose={onClose} />
            <ConfirmationDialog isOpen={isOpen} onClose={onConfirmClose} handleClose={onClose} />
            {
                (user?.value && (isUserSet == true)) && (
                    <CreateUserRequestForm  
                        user={user}
                        onSuccess={handleOnCreateRequestSuccess}
                        onUnsetUser={handleUnsetUser}
                        onClose={handleRequestFormClose}
                    />
                )
            }
        </>
    )

}