import styled from 'styled-components';
import { motion } from 'framer-motion';

export const StyledTimelineEvent = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  .timelineEventLabel {
    margin-top: 10px;
    font-family: 'Proxima Nova';
    font-size: 18px;
    color: #d8d8d8;
  }

  svg {
    width: 200px;
  }

  &.eventFinished {
    &:hover {
      cursor: pointer;
    }
    color: black;
    .timelineEventLabel {
      color: black;
    }
    path {
      fill: black;
    }
  }

  &.eventActive {
    color: #367fff;
    font-style: italic;
    .timelineEventLabel {
      color: #367fff;
    }

    svg:hover {
      cursor: default;
    }

    path {
      fill: #367fff;
    }
  }
`;

export const StyledTimeline = styled.div``;

export const StyledReportProgressTimeline = styled.div`
  display: flex;
  justify-content: center;
`;
