import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { RequireAuth } from 'src/_routes';

export const PrivateRoute = ({ component: Component, ...rest }) => {
    return(
    <Route {...rest} render={props => (
        localStorage.getItem('user')
            ? <Component {...props} />
            : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
    )} />)
};

export const PrivateRouteWithChildren = ({ children, ...rest }) => {
    return(
        <Route {...rest}>
            <RequireAuth>
                {children}
            </RequireAuth>
        </Route>
    )
};
