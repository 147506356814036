import { API_URLS } from '../../_config';

import { authHeader, handleResponse } from '../../_helpers';

export const services = {
  fetchPatients,
  createPatient,
  updatePatient,
  fetchPatientDetailsByUUid,
  fetchPatientAppointmentsByUuid,
  fetchPatientRequestsByUuid,
  fetchPatientRecordsByUuid,
  searchPatients,
};

function searchPatients(query) {
  const requestUrl = API_URLS.getDentistPatientSearch;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };
  let url = requestUrl.url + '?query=' + query;
  return fetch(url, requestOptions).then(handleResponse);
}

function fetchPatients(query) {
  const requestUrl = API_URLS.getDentistPatientList;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };
  let url = requestUrl.url + '?' + query;
  return fetch(url, requestOptions).then(handleResponse);
}

function createPatient(data) {
  const requestUrl = API_URLS.createDentistPatient;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  };
  let url = requestUrl.url;
  return fetch(url, requestOptions).then(handleResponse);
}

function updatePatient(uid, data) {
  const requestUrl = API_URLS.updateDentistPatient;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  };
  let url = requestUrl.url.replace('{patient_uid}', uid);
  return fetch(url, requestOptions).then(handleResponse);
}

function fetchPatientDetailsByUUid(patientUuid) {
  const requestUrl = API_URLS.getDentistPatientDetailUUid;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };
  let url = requestUrl.url.replace('{patient_uuid}', patientUuid);
  return fetch(url, requestOptions).then(handleResponse);
}

function fetchPatientAppointmentsByUuid(patientUuid) {
  const requestUrl = API_URLS.getDentistPatientAppointmentsUUid;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };
  let url = requestUrl.url.replace('{patient_uuid}', patientUuid);
  return fetch(url, requestOptions).then(handleResponse);
}

function fetchPatientRequestsByUuid(patientUuid) {
  const requestUrl = API_URLS.getDentistPatientRequestsUUid;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };
  let url = requestUrl.url.replace('{patient_uuid}', patientUuid);
  return fetch(url, requestOptions).then(handleResponse);
}

function fetchPatientRecordsByUuid(patientUuid) {
  const requestUrl = API_URLS.getDentistPatientRecordsUUid;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };
  let url = requestUrl.url.replace('{patient_uuid}', patientUuid);
  return fetch(url, requestOptions).then(handleResponse);
}
