import { createSelector } from 'reselect';

const getPatientNotesSlice = (state) => state.patientNotes;

export const getPatientNotesById = createSelector([getPatientNotesSlice], (notes) => {
  return notes.byId;
});

export const getPatientNotesIsMoreById = createSelector([getPatientNotesSlice], (notes) => {
  return notes.isMorebyId;
});
export const getPatientNotesLoading = createSelector([getPatientNotesSlice], (notes) => {
  return notes.loadingList;
});
export const getPatientNotesIsError = createSelector([getPatientNotesSlice], (notes) => {
  return notes.errorList;
});
