import { constants } from './_constants';

export const actions = {
  selectReportCategory,
};

function selectReportCategory(cat) {
  return (dispatch) => {
    dispatch(update(cat));
  };
  function update(cat) {
    return { type: constants.UPDATE, data: { currentChartCategory: cat } };
  }
}
