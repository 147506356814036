import styled from 'styled-components';

export const StyledPartialScanMessage = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 250px;

  .top {
    padding: 15px 30px;
    padding-bottom: 10px;
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 21px;
    color: #12171a;
    background-color: #f0f3f5;
  }
  .button {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 19px;
    letter-spacing: 0.9px;
    color: #12171a;
    height: 48px;
    text-transform: uppercase;
    background: #ffffff;
    border-bottom-right-radius: 30px;
    border-bottom-left-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      cursor: pointer;
    }
  }
`;
export const StyledRecordMessage = styled.div`
  padding: 0;
  ${(props) => (props.selected ? 'outline: 2px solid #367FFF; outline-offset: -2px;' : '')}

  img {
    width: 250px;
    height: 250px;
    object-fit: cover;
    vertical-align: middle;
    border-radius: 30px;
  }

  video {
    width: 250px;
    height: 250px;
    object-fit: cover;
    vertical-align: middle;
    border-radius: 30px;
  }
`;
export const StyledTextMessage = styled.div``;
export const StyledLoadingMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  vertical-align: middle;
  border-radius: 30px;
  color: #12171a;
  svg {
    height: 70px;
    width: 70px;
  }
`;

export const StyledErrorDiv = styled.div`
  padding: 10px 15px;
`;

export const StyledMessageContent = styled.div`
  max-width: 280px;
  align-items: right;
  border-radius: 30px;
  font-size: 15px;
  line-height: 21px;
  color: #12171a;
  padding: 15px 30px;
`;

export const StyledMessageContentRecord = styled(StyledMessageContent)`
  padding: 0;
  color: transparent;
`;

export const StyledMessage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 1 auto;
  padding-top: 30px;
  align-items: flex-end;
  text-align: left;
  margin-right: ${(props) => (props.senderMe ? '0px' : '0px')};

  > .messageContent {
    display: flex;
    justify-content: ${(props) => (props.senderMe ? 'flex-end' : 'flex-start')};
    align-items: flex-end;
    width: 100%;

    > .messageTime {
      order: ${(props) => (props.senderMe ? '1' : '2')};
      margin: 0px 10px;
      font-size: 12px;
      color: #3e5159;
    }

    > ${StyledMessageContent} {
      background-color: ${(props) => (props.senderMe ? '#273238' : '#F0F3F5')};
      color: ${(props) => (props.senderMe ? '#FFFFFF' : '#12171A')};
      order: ${(props) => (props.senderMe ? '2' : '1')};
    }
  }
`;

export const StyledMessageListItemText = styled.div`
  font-size: 15px;
  font-weight: 300;
`;
