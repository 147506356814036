import { API_DATE_FORMAT } from 'src/_config';
import { mapValues } from 'lodash';
import moment from 'moment';
import 'moment-timezone';

/*
 * Password Creation
 */
export function generatePassword() {
    const lowerCaseLetters = "abcdefghijklmnopqrstuvwxyz";
    const upperCaseLetters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const specialCharacters = "!@#$%^&|?";
    const minLength = 8;
    const maxLength = 12;
    const length = Math.floor(Math.random() * (maxLength - minLength + 1)) + minLength;

    const getRandomChar = (chars) => chars[Math.floor(Math.random() * chars.length)];

    let password = '';
    // Ensure one character from each required set
    password += getRandomChar(lowerCaseLetters);
    password += getRandomChar(upperCaseLetters);
    password += getRandomChar(specialCharacters);

    // Fill the remaining 5 characters randomly from all sets
    const allCharacters = lowerCaseLetters + upperCaseLetters + specialCharacters;
    for (let i = 3; i < length; i++) {
        password += getRandomChar(allCharacters);
    }

    // Shuffle the password to ensure randomness of character positions
    password = password.split('').sort(() => 0.5 - Math.random()).join('');
    return password;
}


/*
 * Single value conversion
 */
function convertSingleToAPIValue(value){
    if (Array.isArray(value)) {
        return value.map(item => {
            return convertSingleToAPIValue(item);
        }); // Recursive call for each item if obj is an array
    }
    if (moment.isMoment(value)){
        return value.format(API_DATE_FORMAT)
    }
    if (moment.isDate(value)){
        return moment(value).format(API_DATE_FORMAT)
    }
    if (value == null){
        return value
    }
    if (Object.prototype.hasOwnProperty.call(value, "value")){
        return value.value;
    } 
    if (Object.prototype.hasOwnProperty.call(value, "id")){
        return value.id
    }
    return value // return the object as is
}

/*
 * Convert values to API values
 */

export function convertToAPIValues(values){
    return mapValues(values, (obj) => {
        return convertSingleToAPIValue(obj)
    })
  }
  