import React from 'react';
import styled from 'styled-components';
import { Link, useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';
import { URLS } from '../../_config';
import { findKey } from 'lodash';
import navhomeicon from 'src/_images/NavHomeIcon.svg';
import navpatientsicon from 'src/_images/NavPatientsIcon.svg';
import navrequestsicon from 'src/_images/NavRequestsIcon.svg';
import navsettingsicon from 'src/_images/NavSettingsIcon.svg';
import navadminicon from 'src/_images/NavAdminIcon.svg';
import navhomeiconactive from 'src/_images/NavHomeIconActive.svg';
import navpatientsiconactive from 'src/_images/NavPatientsIconActive.svg';
import navrequestsiconactive from 'src/_images/NavRequestsIconActive.svg';
import navsettingsiconactive from 'src/_images/NavSettingsIconActive.svg';
import navadminiconactive from 'src/_images/NavAdminIconActive.svg';

import { getProfileIsStaff } from '../../App/_redux/selectors';
import { useAuth } from 'src/_libs/auth';


const StyledSideNav = styled.div`
    position: fixed;     /* Fixed Sidebar (stay in place on scroll and position relative to viewport) */
    height: calc( 100vh - 54px );
    width: 88px;     /* Set the width of the sidebar */
    z-index: 1;      /* Stay on top of everything */
    top: 72px;      /* Stay at the top */
    overflow-x: hidden;     /* Disable horizontal scroll */
    padding-top: 10px;
    background: #F5F5F5;
    box-shadow: 5px 0px 10px 0px rgba(0, 0, 0, 0.03);
`;
const StyledNavItem = styled.div`
    height: 70px;
    width: 75px; /* width must be same size as NavBar to center */
    margin: auto;
    text-align: center; /* Aligns <a> inside of NavIcon div */
    margin-bottom: 0;   /* Puts space between NavItems */
    a {
        font-size: 2.7em;
        color: ${(props) => props.active ? "lightgray" : "#273238"};
        ${(props) => (props.active ? 'pointer-events: none;' : '')};
        :hover {
            opacity: 0.7;
            text-decoration: none; /* Gets rid of underlining of icons */
        }
    }
    img {
      margin:auto;
      padding: 10px;
    }
`;

const ITEMS = [
  {
    path: URLS.home.url, /* path is used as id to check which NavItem is active basically */
    name: URLS.home.title,
    css: 'fa fa-fw fa-home',
    icon: navhomeicon,
    iconActive: navhomeiconactive,
    key: 1 /* Key is required, else console throws error. Does this please you Mr. Browser?! */
  },
  /*{
    path: URLS.chatList.url,
    name: URLS.chatList.title,
    css: 'fas fa-hashtag',
    icon: navchaticon,
    iconActive: navchaticonactive,
    key: 2
  },*/
  {
    path: URLS.patientList.url,
    name: URLS.patientList.title,
    css: 'fas fa-hashtag',
    icon: navpatientsicon,
    iconActive: navpatientsiconactive,
    key: 3,
  },
  {
    path: URLS.openRequestsPage.url,
    name: URLS.openRequestsPage.title,
    css: 'fas fa-hashtag',
    icon: navrequestsicon,
    iconActive: navrequestsiconactive,
    key: 4,
    isAdmin: true
  },
  {
    path: URLS.settings.url,
    name: URLS.settings.title,
    icon: navsettingsicon,
    iconActive: navsettingsiconactive,
    css: 'fas fa-hashtag',
    key: 5
  },
]



const STAFF_ITEMS = [
  {
    path: URLS.home.url, /* path is used as id to check which NavItem is active basically */
    name: URLS.home.title,
    css: 'fa fa-fw fa-home',
    icon: navhomeicon,
    iconActive: navhomeiconactive,
    key: 1 /* Key is required, else console throws error. Does this please you Mr. Browser?! */
  },
  /*{
    path: URLS.chatList.url,
    name: URLS.chatList.title,
    css: 'fas fa-hashtag',
    icon: navchaticon,
    iconActive: navchaticonactive,
    key: 2
  },*/
  {
    path: URLS.patientList.url,
    name: URLS.patientList.title,
    css: 'fas fa-hashtag',
    icon: navpatientsicon,
    iconActive: navpatientsiconactive,
    key: 3,
  },
  {
    path: URLS.openRequestsPage.url,
    name: URLS.openRequestsPage.title,
    css: 'fas fa-hashtag',
    icon: navrequestsicon,
    iconActive: navrequestsiconactive,
    key: 4,
    isAdmin: true
  },
  {
    path: URLS.settings.url,
    name: URLS.settings.title,
    icon: navsettingsicon,
    iconActive: navsettingsiconactive,
    css: 'fas fa-hashtag',
    key: 5
  },
  {
    path: URLS.adminPage.url,
    name: URLS.adminPage.title,
    icon: navadminicon,
    iconActive: navadminiconactive,
    css: 'fas fa-hashtag',
    key: 6
  },
]

class NavItem extends React.Component {

    render() {
        const { isActive, isOpen, icon, iconActive, name, path, css } = this.props;
        return(
            <StyledNavItem active={isActive}>
                <Link to={path} className={css}>
                    <NavIcon src={isActive ? iconActive : icon} alt={name}/>
                    {
                      isOpen && (<span>{name}</span>)
                    }
                </Link>
            </StyledNavItem>
        );
    }
}

const NavIcon = styled.img`

`;

const checkPathIsActive = (currentPath, path) => {
  if (!currentPath) {
      return false; // Handle null, undefined, or empty string
  }
  if (path == "/" || path == "") { // handle index page which has no path
      return currentPath == path;
  }
  return currentPath.startsWith(path);
}

export const Sidebar = () => {
  const {user} = useAuth();
  const location = useLocation();

  const isStaff = useSelector(getProfileIsStaff);

  const isOpen = false;


  var currentLocation = ((location.pathname) || (location.location && location.location.pathname));

  const menuItems = (user?.is_staff == true) ? STAFF_ITEMS : ITEMS;

  // Check which icon to highlight. No match results in Home icon being highlighted
  let activePath = '/'
  if ( ['', '/'].indexOf(currentLocation) == -1){
    for (let i = 0; i < menuItems.length; i++) {
      if (menuItems[i].path != '/'){    
        if (currentLocation.startsWith(menuItems[i].path)) {
          activePath = menuItems[i].path
          break
        }
      }
    }
  }
  
  if (findKey(URLS, {'url': currentLocation, 'sidebar': false}) || currentLocation.startsWith("/videochat/")) {
    return (<div></div>)
  } else {
    return(
        <StyledSideNav>
          {
            menuItems.map((item) => {
                // item.path.startsWith(currentLocation)
                if (item?.isAdmin){
                  if (isStaff){
                    return (
                        <NavItem
                            isOpen={isOpen}
                            path={item.path}
                            name={item.name}
                            css={item.css}
                            icon={item.icon}
                            iconActive={item.iconActive}
                            isActive={checkPathIsActive(activePath, item.path)}
                            key={item.key}
                        />
                    );
                  } else {
                    return (<div key={item.key} />)
                  }
                }
                return (
                    <NavItem
                        isOpen={isOpen}
                        path={item.path}
                        name={item.name}
                        css={item.css}
                        icon={item.icon}
                        iconActive={item.iconActive}
                        isActive={checkPathIsActive(activePath, item.path)}
                        key={item.key}
                    />
                );
            })
          }
        </StyledSideNav>
    );
  }
}