import React from 'react';
import { useSelector, useDispatch  } from 'react-redux';
import { upperFirst, toUpper, find  } from 'lodash';
import styled from 'styled-components';
import { GENDERS } from '../_config';
import cn from 'classnames';
import moment from 'moment';

import Select from 'react-select';
import { Form, Field } from 'react-final-form';
import { required} from '../_components/FormElements';
import { FORM_ERROR } from "final-form";

//import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl } from 'react-phone-number-input';
import {default as PhoneInput} from 'react-phone-number-input/input'


import { localeFormatDate } from '../_helpers';

// redux state
import { actions } from './_redux/actions';
import { services } from './_redux/services';

// selectors
import { getIntlLocale, getIntlMessages, getProfileCountry} from '../App/_redux/selectors';

// styles
import {
  StyledInputLayout,
  StyledButton,
  ErrorLabel,
  StyledLabel,
  StyledInput,
  errorCss,
} from '../_css/styles'
import { consoleLog } from '../_helpers/consoleLog';

const Styles = styled.form`
  width: 700px;

  .header{
    padding: 30px 0px 30px;
    font-family: Proxima Nova Semibold;
    font-size: 18px;
    text-align:center;
    box-shadow: 0px -2px 15px 0px rgba(0, 0, 0, 0.08);
    margin-bottom: 30px;
  }


  .formRow{
    display:flex;
    justify-content: space-between;
    width: 100%;
    padding: 0px 30px;

    > div{
      width: 50%;
    }

    > div:first-child{
      padding-right:10px;
    }
    > div:last-child{
      padding-left: 10px;
    }
  }

  .column{
    display:flex;
    flex-direction: column;
  }

  .SelectAdapter{
    width: 100%;
    min-height: 45px;
    
    > div {
      min-height: 45px;
    }
  }

  .PhoneInputLayout{

    .Input__Label{
      margin: 15px 0;
      font-size: 16px;
      width: 100%;
      text-align: left;
    }

    input{
    border: 1px solid #C7D3D9;
      box-sizing: border-box;
      border-radius: 3px;
      padding: 10px 15px;
      width: 100%;
    }
  }

  .PhoneInput{

  }


`;



class PhoneAdapter extends React.Component{
  render(){
    const { input, meta, className, label, placeholder, country, intlMessages } = this.props;
    return(
      <div className={className}>
        <label className="Input__Label" style={{margin: '5px 0'}}>{label}</label>
        <PhoneInput
          className={className}
          meta={meta}
          input={input}
          international
          value={input.value}
          country={country}
          onChange={value => {consoleLog(value);input.onChange(value);}}
          placeholder={placeholder}
          inputClassName={cn("form-control Input_Text", {"Input_Error": (meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)} )}
        />
        <div className={cn("Error__Label", {"Error": (meta.error || meta.submitError) && meta.touched} )} >
          {(meta.error || meta.submitError) && meta.touched && intlMessages[`form.error.${(meta.error || meta.submitError)}`]}
        </div>
        </div>
    )
  }
}

const selectStyles = {
    placeholder: (provided) => {
      const color =  "#7A8F99";  // Change to the color you want
      return {...provided, color}
    },
    valueContainer: (provided) => {
      const padding = '10px 15px';
      return { ...provided, padding }
    },
    singleValue: (provided, state) => {
          const color = state.hasValue ? '#000000' : '#C7D3D9';
          return { ...provided, color };
        },
    control: (provided) => {
          const minHeight = 45;
          const borderColor = '#C7D3D9';
          return { ...provided, minHeight, borderColor };
        },
    dropdownIndicator: (provided, state) => {
          const color = state.hasValue ? '#000000' : '#C7D3D9';
          return { ...provided, color };
        },
    indicatorSeparator:  (provided, state) => {
          const backgroundColor = state.hasValue ? '#36454D' : '#C7D3D9';
          return { ...provided, backgroundColor };
        },
    /*input: (provided) => {
      *     const height = 36;
      *         return {...provided, height}
      *           }*/

}

export const SelectAdapterNonClearable = ({input, meta, items, label,  intlMessages , className="SelectAdapter", defaultValue, disabled, ...rest }) => {
  let newSelectStyles = selectStyles;
  if ((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)){
    selectStyles.control['borderColor'] = 'red';
    newSelectStyles = {
      ...selectStyles,
      control: (provided) => {
         const height = '40px' // add for 1px border
         const borderColor = 'red';
         const borderWidth = '1px';
        return { ...provided, borderColor, height, borderWidth};
      }
    }
  }
  return(
    <StyledInputLayout>
      <StyledLabel>{label}</StyledLabel>
      <Select
        {...input}
        styles={newSelectStyles}
        className={ cn(className, {"Input_Error": (meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)})}
        onChange={inputValue => {input.onChange(inputValue.value)}}
        options={items}
        isDisabled={disabled}
        isClearable={false}
        isSearchable={false}
        value={find(items, {'value': defaultValue || input.value})}
        onBlurResetsInput={false}
        onSelectResetsInput={false}
        {...rest}
      />
      <ErrorLabel
        isError={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit))}
        className={cn("Error__Label", {"Error": (meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)} )} >
        {(meta.error && meta.touched  || meta.submitError && !meta.dirtySinceLastSubmit) ? intlMessages["registration.form.location.error"] : undefined}
      </ErrorLabel>
    </StyledInputLayout>
  )
}

export const PatientForm = ({initValues, onCreated}) => {
  const dispatch = useDispatch();
  const intlLocale = useSelector(getIntlLocale);
  const intlMessages = useSelector(getIntlMessages);
  const userCountry = useSelector(getProfileCountry);

  let user = JSON.parse(localStorage.getItem('user'));
  let country = (user && user.country) ?  user.country : userCountry;


  const datePlaceholder = moment().locale("ko").localeData().longDateFormat('L').replace(/\W$/, "").replace(/\W/g, "/");
  //const dateOnlyNumbs = datePlaceholder.replace(/\W/g, "");
  let pattern = /\W/g;
  var match;
  const dateIndexes = [];
  while ((match = pattern.exec(datePlaceholder)) != null) {
      dateIndexes.push(match.index);
  }

  const parseDate = (value) => {
    // parse to API format, which is YYYY-MM-DD
    let apiDate = localeFormatDate(value, intlLocale, "YYYY-MM-DD");
    consoleLog(apiDate);
    if (apiDate){
      return apiDate;
    } return value;
  }

  /*const formatDate = (value) => {
    if (!value) return value;
    const onlyNums = value.replace(/[^\d]/g, "");
    consoleLog("date12formating " ,value, ", ", onlyNums, ", ", datePlaceholder.length)

    if (value.length <= dateIndexes[0]){
      return `${onlyNums.slice(0, dateIndexes[0])}`

    } else if (value.length <= dateIndexes[1]){
      return `${onlyNums.slice(0, dateIndexes[0])}/${onlyNums.slice(dateIndexes[0], dateIndexes[1]-1)}`

    } else if (value.length <= datePlaceholder.length){
      return `${onlyNums.slice(0, dateIndexes[0])}/${onlyNums.slice(dateIndexes[0], dateIndexes[1]-1)}/${onlyNums.slice(dateIndexes[1]-1, datePlaceholder.length-1)}`
    } else {
      return value.slice(0,datePlaceholder.length);
    }
  }*/

  const handleSubmit = async (values) => {
    // create new patiet api
    //onCreated({...values, unique_id: 'skj20sd-sdfsd232-ssswer'});
    try{
      let resp = await services.createPatient(values);
      dispatch(actions.addPatient(resp))
      onCreated(resp);
      return null;
    } catch(err){
      return {
        ...err,
        [FORM_ERROR]: upperFirst(intlMessages['form.error.general.error'])
      }
    }
  }

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initValues}
      validate={
        (values)=>{
          if (values.date_of_birth){
            parseDate(values.date_of_birth);
          }
        }
      }
      render = {({
        handleSubmit,
        submitting,
      }) => (
        <Styles
          onSubmit={handleSubmit}
          >
            <div className={'header'}>
              {intlMessages['patientForm.title']}
            </div>
            {
              (intlMessages['format.fullName'].split(" ")[0] == "{familyName}")
              ?
                <div className={'formRow'}>
                  <Field name="last_name" parse={v => v} validate={required}>
                    {({ input, meta}) => (
                      <StyledInputLayout>
                        <StyledLabel className="Input__Label">{upperFirst(intlMessages['patientForm.last_name.label'])}</StyledLabel>
                        <StyledInput
                          {...input}
                          type="text"
                          addCSS={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)) && errorCss}
                          placeholder={upperFirst(intlMessages['patientForm.last_name.placeholder'])} />
                        <ErrorLabel
                          addCSS={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)) && errorCss}
                          >
                            {((meta.error && meta.touched)  || (meta.submitError && !meta.dirtySinceLastSubmit)) && intlMessages[`form.error.email.${(meta.error)}`] || meta.submitError}
                        </ErrorLabel>
                      </StyledInputLayout>
                    )}
                  </Field>
                  <Field name="first_name" parse={v => v} validate={required}>
                    {({ input, meta}) => (
                      <StyledInputLayout>
                        <StyledLabel className="Input__Label">{upperFirst(intlMessages['patientForm.first_name.label'])}</StyledLabel>
                        <StyledInput
                          {...input}
                          type="text"
                          addCSS={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)) && errorCss}
                          placeholder={upperFirst(intlMessages['patientForm.first_name.placeholder'])} />
                        <ErrorLabel
                          addCSS={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)) && errorCss}
                          >
                            {((meta.error && meta.touched)  || (meta.submitError && !meta.dirtySinceLastSubmit)) && intlMessages[`form.error.email.${(meta.error)}`] || meta.submitError}
                        </ErrorLabel>
                      </StyledInputLayout>
                    )}
                  </Field>
                </div>
              :
                <div className={'formRow'}>
                  <Field name="first_name" parse={v => v} validate={required}>
                    {({ input, meta}) => (
                      <StyledInputLayout>
                        <StyledLabel className="Input__Label">{upperFirst(intlMessages['patientForm.first_name.label'])}</StyledLabel>
                        <StyledInput
                          {...input}
                          type="text"
                          addCSS={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)) && errorCss}
                          placeholder={upperFirst(intlMessages['patientForm.first_name.placeholder'])} />
                        <ErrorLabel
                          addCSS={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)) && errorCss}
                          >
                            {((meta.error && meta.touched)  || (meta.submitError && !meta.dirtySinceLastSubmit)) && intlMessages[`form.error.email.${(meta.error)}`] || meta.submitError}
                        </ErrorLabel>
                      </StyledInputLayout>
                    )}
                  </Field>
                  <Field name="last_name" parse={v => v} validate={required}>
                    {({ input, meta}) => (
                      <StyledInputLayout>
                        <StyledLabel className="Input__Label">{upperFirst(intlMessages['patientForm.last_name.label'])}</StyledLabel>
                        <StyledInput
                          {...input}
                          type="text"
                          addCSS={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)) && errorCss}
                          placeholder={upperFirst(intlMessages['patientForm.last_name.placeholder'])} />
                        <ErrorLabel
                          addCSS={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)) && errorCss}
                          >
                            {((meta.error && meta.touched)  || (meta.submitError && !meta.dirtySinceLastSubmit)) && intlMessages[`form.error.email.${(meta.error)}`] || meta.submitError}
                        </ErrorLabel>
                      </StyledInputLayout>
                    )}
                  </Field>
                </div>

            }
            <div className={'formRow'}>
              <Field name="date_of_birth" validate={required}>
                {({ input, meta}) => (
                  <StyledInputLayout>
                    <StyledLabel className="Input__Label">{upperFirst(intlMessages['patientForm.date_of_birth.label'])}</StyledLabel>
                    <StyledInput
                      {...input}
                      type="date"
                      lang="th"
                      addCSS={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)) && errorCss}
                       />
                    <ErrorLabel
                      addCSS={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)) && errorCss}
                      >
                        {((meta.error && meta.touched)  || (meta.submitError && !meta.dirtySinceLastSubmit)) && intlMessages[`form.error.email.${(meta.error)}`] || meta.submitError}
                    </ErrorLabel>
                  </StyledInputLayout>
                )}
              </Field>
            <Field
              name="gender"
              className={'SelectAdapter'}
              type='settings'
              items={GENDERS.map(item => {
                return { label: upperFirst(intlMessages[`settings.gender.${item}`]), value: item}
              })}
              intlMessages={intlMessages}
              label={upperFirst(intlMessages['patientForm.gender.label'])}
              component={SelectAdapterNonClearable}
              placeholder={upperFirst(intlMessages['patientForm.gender.placeholder'])}
            />
            </div>
            <div className={'formRow'}>
              <Field name="email" parse={v => v} validate={required}>
                {({ input, meta}) => (
                  <StyledInputLayout>
                    <StyledLabel className="Input__Label">{upperFirst(intlMessages['patientForm.email.label'])}</StyledLabel>
                    <StyledInput
                      {...input}
                      type="text"
                      addCSS={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)) && errorCss}
                      placeholder={upperFirst(intlMessages['patientForm.email.placeholder'])} />
                    <ErrorLabel
                      addCSS={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)) && errorCss}
                      >
                        {((meta.error && meta.touched)  || (meta.submitError && !meta.dirtySinceLastSubmit)) && intlMessages[`form.error.email.${(meta.error)}`] || meta.submitError}
                    </ErrorLabel>
                  </StyledInputLayout>
                )}
              </Field>
              <Field
                className={'PhoneInputLayout'}
                name="phonenumber"
                country={country}
                label={upperFirst(intlMessages['patientForm.phonenumber.label'])}
                intlMessages={intlMessages}
                component={PhoneAdapter} placeholder={upperFirst(intlMessages['patientForm.phonenumber.placeholder'])}
              />
            </div>
            <div className={'formRow'}>
              <StyledButton disabled={submitting} type="submit">{toUpper(intlMessages['patientForm.submit.label'])}</StyledButton>
            </div>
        </Styles>
      )}
    />
  )
}
