import { createSelector } from 'reselect';

const getPatientRecordsSlice = (state) => state.patientRecords;

export const getPatientRecordsLoading = createSelector([getPatientRecordsSlice], (state) => {
  return state.loading;
});

export const getCurrentMediaId = createSelector([getPatientRecordsSlice], (state) => {
  return state.currentSelectedMedia;
});

export const getPatientRecordsError = createSelector([getPatientRecordsSlice], (state) => {
  return state.error;
});

export const getPatientRecordsById = createSelector([getPatientRecordsSlice], (state) => {
  return state.records || {};
});

export const getPatientRecordUploadDetectionsLoading = createSelector(
  [getPatientRecordsSlice],
  (state) => {
    return state.detectionsLoading || {};
  }
);
export const getPatientRecordUploadDetectionsError = createSelector(
  [getPatientRecordsSlice],
  (state) => {
    return state.detectionsError || {};
  }
);
export const getPatientRecordUploadDetectionsById = createSelector(
  [getPatientRecordsSlice],
  (state) => {
    return state.detections || {};
  }
);

export const getPatientUploadsLoading = createSelector([getPatientRecordsSlice], (state) => {
  return state.uploadsLoading;
});

export const getPatientUploadsById = createSelector([getPatientRecordsSlice], (state) => {
  return state.uploads;
});

export const getPatientPartialScansById = createSelector([getPatientRecordsSlice], (state) => {
  return state.partialScanCharts;
});
