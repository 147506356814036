import { constants } from './_constants';

export const actions = {
  selectReportChart,
  updateCurrentRegions,
};

function selectReportChart(id) {
  return (dispatch) => {
    dispatch(update(id));
  };
  function update(id) {
    if (id == null) {
      return { type: constants.UPDATE, data: { currentChartId: id, currentRegions: {} } };
    } else if (id > 0) {
      return { type: constants.UPDATE, data: { currentChartId: id } };
    } else {
      return { type: constants.UPDATE, data: { currentChartId: id, currentRegions: {} } };
    }
  }
}

function updateCurrentRegions(regions) {
  return (dispatch) => {
    dispatch(update(regions));
  };
  function update(data) {
    return { type: constants.UPDATECURRENTREGIONS, data };
  }
}
