const PREFIX = 'PATIENTRECORDS_';
export const constants = {
  FETCHRECORD_REQUEST: `${PREFIX}FETCHRECORD_REQUEST`,
  FETCHRECORD_SUCCESS: `${PREFIX}FETCHRECORD_SUCCESS`,
  FETCHRECORD_FAILURE: `${PREFIX}FETCHRECORD_FAILURE`,
  FETCHRECORDUPLOAD_REQUEST: `${PREFIX}FETCHRECORDUPLOAD_REQUEST`,
  FETCHRECORDUPLOAD_SUCCESS: `${PREFIX}FETCHRECORDUPLOAD_SUCCESS`,
  FETCHRECORDUPLOAD_FAILURE: `${PREFIX}FETCHRECORDUPLOAD_FAILURE`,
  FETCHDETECTIONS_REQUEST: `${PREFIX}FETCHDETECTIONS_REQUEST`,
  FETCHDETECTIONS_SUCCESS: `${PREFIX}FETCHDETECTIONS_SUCCESS`,
  FETCHDETECTIONS_FAILURE: `${PREFIX}FETCHDETECTIONS_FAILURE`,
  UPDATE: `${PREFIX}UPDATE`,
  UPDATE_RECORD: `${PREFIX}UPDATE_RECORD`,
  UPDATE_PARTIALSCANCHART: `${PREFIX}UPDATE_PARTIALSCANCHART`,
};
