import React, { useEffect, useState, useRef, useCallback } from 'react';
import ReactDOMServer from 'react-dom/server';
import { useSelector, useDispatch} from 'react-redux';
import { range } from 'lodash';
import { motion } from 'framer-motion';
import InnerImageZoom from 'react-inner-image-zoom'
import ReactPlayer from 'react-player/lazy'
import { Carousel } from 'react-responsive-carousel';
import ReactTooltip from 'react-tooltip';
import { Scrollbars } from 'react-custom-scrollbars';
import { TOOTH_NUMBERING_SYSTEM } from '../_config';

import { Flex, Text, Button, Switch, FormLabel } from '@chakra-ui/react';
// components
import { LoadingCircle, LoadingEllipsis } from '../_components/Loaders';
import { FaChevronRight, FaChevronLeft } from 'react-icons/fa';
import { MdFileDownload, MdRefresh, MdErrorOutline } from 'react-icons/md'
import { PaperclipIcon } from '../_images/icons/PaperclipIcon';
import { Detections } from './Detections';

// redux state
import { actions } from './_redux/actions';
import {
  getCurrentVideoFrames,
  getCurrentUploadUid,
  getCurrentImageIndex,
  getRequestUploads,
  getTotalUploads,
} from './_redux/selectors';

// actions
import { actions as recordActions } from '../PatientRecords/_redux/actions';

// selectors
import { getIntlMessages } from '../App/_redux/selectors';
import { 
  getPatientUploadsById, 
  getPatientUploadsLoading,
  getPatientRecordUploadDetectionsLoading,
  getPatientRecordUploadDetectionsById 
} from '../PatientRecords/_redux/selectors';

import {
  getCurrentRequestId,
  getDetailsRecordId,
  getRecordLoading
} from '../PatientDetailsPage/_redux/selectors';


// styles
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';
import {
  StyledSingleStep,
  StyledPrevStep,
  StyledNextStep,
  StyledStepsSection,
  StyledVideoFrame,
  StyledVideoFrameList,
  StyledVideoView,
  StyledPhotoView,
  StyledMediaSection
} from './styles';
import { consoleLog } from '../_helpers/consoleLog';

const CURRENT_TOOTH_NUMBERING_SYSTEM = 'palmer';

function useCurrentRef() {
  const [ image, setImage ] = useState(null);
  const ref = useCallback(node => {
    if (node !== null) {
      setImage(node);
    }
  }, [])
  return [image, ref];
}


const SingleStep = ({index}) => {
  const dispatch = useDispatch();
  const selectedIndex = useSelector(getCurrentImageIndex);
  const handleClick = () => {
    dispatch(actions.selectIndex(index))
  }
  if (selectedIndex == index){
    return (
      <StyledSingleStep addCSS={{'background': '#0661FF'}}>
      </StyledSingleStep>
    )
  } else {
    return (
      <StyledSingleStep onClick={handleClick}>
      </StyledSingleStep>
    )
  }
}

const PrevStep = () => {
  const dispatch = useDispatch();
  const imageIndex = useSelector(getCurrentImageIndex);
  const handleClick = () => {
    dispatch(actions.selectIndex(imageIndex - 1))
  }
  if (imageIndex > 0){
    return (
      <StyledPrevStep onClick={handleClick}><FaChevronLeft /></StyledPrevStep>
    )
  } else {
    return(
      <StyledPrevStep addCSS={{'cursor': 'unset', 'color': '#D8D8D8'}}><FaChevronLeft /></StyledPrevStep>
    )
  }
}

const NextStep = () => {
  const dispatch = useDispatch();
  const imageIndex = useSelector(getCurrentImageIndex);
  const totalUploads = useSelector(getTotalUploads);
  const handleClick = () => {
      dispatch(actions.selectIndex(imageIndex + 1))
  }
  if (imageIndex + 1 < totalUploads){
    return (
      <StyledNextStep onClick={handleClick}><FaChevronRight /></StyledNextStep>
    )
  } else {
    return (
      <StyledNextStep addCSS={{'cursor': 'unset', 'color': '#D8D8D8'}}><FaChevronRight /></StyledNextStep>
    )
  }
}

const StepsSection = () => {
  const totalUploads = useSelector(getTotalUploads);

  useEffect(()=>{
    return () => {};
  }, [])
  return (
    <StyledStepsSection>
      <PrevStep />
      {range(totalUploads).map( (num, index) => (
        <SingleStep index={index} key={index}/>
      )
      )}
      <NextStep />
    </StyledStepsSection>
  )
}


const VideoFrame = ({frame}) => {
  const dispatch = useDispatch();
  useEffect(()=>{
    ReactTooltip.rebuild();
    return () => {};
  }, [])
  const handleClick = () => {
    dispatch(actions.updateStateObject('videoPlayer', {seekTime: frame.timestamp}))
  }
  const toolTipHtml = `<img width="450" src='${frame.frame}' />`;
  return (
    <StyledVideoFrame data-for={"framePreview"} data-tip={toolTipHtml} data-html={true} onClick={handleClick}>
      <img src={frame.frame} />
    </StyledVideoFrame>
  )
}

const VideoFrameList = () => {
  const currentVideoFrameList = useSelector(getCurrentVideoFrames);
  const ref = useRef(null);

  if (currentVideoFrameList && currentVideoFrameList.length > 0){
    return (
      <div ref={ref}>
        <Scrollbars
            style={{width: '100%'}}
            autoHide
            autoHeight
          >
          <StyledVideoFrameList>
            {currentVideoFrameList.map((frame, index) => <VideoFrame key={index} index={index} frame={frame} />)}
          </StyledVideoFrameList>
        </Scrollbars>
      </div>
    )
  } else {
    return (
      <div></div>
    )
  }
}

const DetectionCanvas = ({frame, isDetectionOn}) => {
  const ref = useRef();
  const lastDrawFrame = useRef(0);
  const canvas = ref.current;
  const context = canvas && canvas.getContext('2d');


  const maxSameFrame = 2;

  let lineWidth = 3;
  useEffect(()=>{
    if (context && isDetectionOn && frame){
      // DEBUG console.log("should be drawing frame ", frame)
      // frame = [frameNum, detections array]
      // frame = [frameTs, frameNum, detectionsArray ]
      // if same frame for 4 frames and no current detections, remove
      if (Math.abs(frame[0] - (lastDrawFrame?.current || 0)) > maxSameFrame){
        context.clearRect(0, 0, canvas.width, canvas.height)
      }
      console.log("Detection frame ", frame)
      if (frame[1].length > 0){
        context.clearRect(0, 0, canvas.width, canvas.height)
        frame[1].map(elem => {
            console.log("Detection elem ", elem)
            let [ left, top, right, bottom] = elem;
            context.beginPath();
            context.lineWidth = `${lineWidth}`;
            context.strokeStyle = "green";
            context.rect(left, top, Math.min(right, 640-lineWidth/2)-left, Math.min(480-lineWidth/2, bottom)-top);
            context.stroke();

            // Calculate the position for the text
            //const textX = left + (Math.min(right, 640 - lineWidth / 2) - left) / 2;
            //const textY = top - 10; // Adjust this value to position the text appropriately above the box

            // Draw the text
            context.font = "16px Arial"; // Set the font size and family as needed
            context.textAlign = "center"; // Center the text horizontally
            context.fillStyle = "black"; // Set the text color
            //context.fillText(text, textX, textY);
        });
        lastDrawFrame.current = frame[0];
      }
    }
  }, [frame, isDetectionOn])

  return(
    <canvas className={isDetectionOn ? 'DETECTIONON' : 'DETECTIONOFF'} ref={ref} width={640} height={480} />
  )
}

// NOTE: to make better... https://github.com/transitive-bullshit/ffmpeg-extract-frames#readme
const VideoView = ({ srcUrl, hasDetections, description, onError}) => {
  const dispatch = useDispatch();
  const [ isDetectionOn, setIsDetectionOn ] = useState(true);

  const currentRecordUploadUid = useSelector(getCurrentUploadUid);

  const detectionsLoading = useSelector(getPatientRecordUploadDetectionsLoading)
  const detectionsById = useSelector(getPatientRecordUploadDetectionsById);
  const videoPlayerRef = useRef();
  //const currentVideoFrameList = useSelector(getCurrentVideoFrames);
  //const videoFrames = useSelector(getVideoFrames);

  const [ canvasFrame, setCanvasFrame] = useState();
  const [ isLoaded, setIsLoaded ] = useState(false);
  const [ videoPlaying, setVideoPlaying ] = useState(false);
  const [ videoInfo, setVideoInfo ] = useState([-1,-1]); // fps, frames
  const [ videoSeek, setVideoSeek ] = useState(0);

  const canUseDetections = true //isStaff

  const totalFrames = useRef(-1);
  const detectionFps = useRef(-1);

  useEffect(()=>{
    /*if (srcUrl && id && videoFrames[id] == null){
      const extractVideo = async () => {
        setExtracting(true);
        setExtractError(false);
        try{
          let result = await extractFramesFromVideo(srcUrl, id, extractCallback);
        } catch (error) {
          setExtractError(true);
        }
        ReactTooltip.rebuild();
        setExtracting(false);
      }
      extractVideo();
    }*/
    return () => {};
  }, [])

  useEffect(()=>{
    // fetch detections if user is staff
    // TODO: maybe make this unrestricted. change var canUseDetections for isStaff if restricted
    if (canUseDetections){
      if (currentRecordUploadUid != null && hasDetections){
        if (detectionsLoading[currentRecordUploadUid] !== true && detectionsById[currentRecordUploadUid] === undefined){
          // if upload has detections and its not loaded, fetch detections
          dispatch(recordActions.fetchRecordUploadDetections(currentRecordUploadUid))
        } else if (detectionsLoading[currentRecordUploadUid] === false && 
          detectionsById[currentRecordUploadUid] !== undefined){
            // once detections are loaded, set videoInfo([seconds, frames])
            totalFrames.current = detectionsById[currentRecordUploadUid].frames
            detectionFps.current = detectionsById[currentRecordUploadUid].fps
            setVideoInfo([detectionsById[currentRecordUploadUid].fps, detectionsById[currentRecordUploadUid].frames])
          }
      }
    }
  }, [currentRecordUploadUid, detectionsLoading[currentRecordUploadUid], detectionsById[currentRecordUploadUid] ])

  useEffect(()=>{
    if (videoInfo[1] > -1){
      setIsLoaded(true)
    }
  }, [videoInfo])

  const onProgress = (prog) => { 
    let {playedSeconds, loaded, loadedSeconds, played } = prog;
    setVideoSeek(playedSeconds*videoInfo[0])
    if (loaded >= 1){
      (!isLoaded) && setIsLoaded(true);
      (hasDetections === false) && setVideoInfo([30, loadedSeconds*30]);
      if (hasDetections && isDetectionOn && detectionsById[currentRecordUploadUid] !== undefined){
        let currFrame = Math.round(played*videoInfo[1]);
        let currDetection = detectionsById[currentRecordUploadUid]['detections'][currFrame] || detectionsById[currentRecordUploadUid]['detections'][currFrame+1];
        // currDetection: timestamp, frame num, detecitons array
        (currDetection && setCanvasFrame([currFrame, currDetection[2]])) || setCanvasFrame([currFrame,[]]);
      }
    }
    //console.log(videoPlayerRef.current)
    // currDetection: timestamp, frame num, detecitons array
  }

  const onBuffer = (a=null) => {
    consoleLog("buffer started: " , Object.keys(a))
    consoleLog(videoPlayerRef.current)
  }

  const onBufferEnd = (a=null) => {
    consoleLog("buffer ended: " + Object.keys(a))
    consoleLog(videoPlayerRef.current.player)
  }

  const handleMouseEnterDetection = (frameNum) => {
    if (videoInfo[1] > 0) {
      try{
        setVideoPlaying(false);
        setVideoSeek(frameNum);
        videoPlayerRef.current && videoPlayerRef.current.seekTo(frameNum/videoInfo[1], 'fraction');
      } catch (err) {
        consoleLog("error seeking to frame: ", err);
      }
    } 
  }

  return(
    <StyledVideoView>
      <div className={'videoPlayerContainer'}>
        { (description && description.length > 0) && 
          <Flex align={'center'}
            position={'absolute'} width={'100%'} top={0} left={0} right={0} 
            bg={'rgba(0,0,0,0.2)'} padding={'10px 15px'} 
          >
              <Text color={'white'}>{description}</Text>
          </Flex>
        }
        <Flex
          as={motion.div}
          position={'absolute'} width={'100%'} top={0} left={0} right={0} bg={'rgba(0,0,0,0.2)'} 
          justify={'space-between'} padding={'10px 15px'} zIndex={2}
          whileHover={{
            y: 0
          }}
        >
            <Flex w="full" align={'center'} justify={'end'}>
              {
                (hasDetections ) &&
                (<>
                  <FormLabel margin={0} mr={2} htmlFor='detectionOn' color={'white'}>{'Detections'}</FormLabel>
                  <Switch margin={0} size={'md'} id={'detectionOn'} colorScheme={'brand'} 
                    isChecked={isDetectionOn} onChange={() => setIsDetectionOn(old => !old)} />
                </>)
              }
            </Flex>
        </Flex>
        <ReactPlayer
          ref={videoPlayerRef}
          onProgress={onProgress}
          onBuffer={onBuffer}
          onBufferEnd={onBufferEnd}
          playing={videoPlaying}
          onPlay={() => setVideoPlaying(true)}
          onPause={() => setVideoPlaying(false)}
          url={srcUrl}
          muted={true}
          playbackRate={0.8}
          controls={false} 
          width={640}
          height={480}
          onError={onError}
        />
        <DetectionCanvas frame={canvasFrame} isDetectionOn={isDetectionOn} />
      </div>
      {
        ((hasDetections === false) || detectionsLoading[currentRecordUploadUid] === false) 
        ? <Detections
            currentDetections={(canUseDetections && isDetectionOn && detectionsById[currentRecordUploadUid])? detectionsById[currentRecordUploadUid].detections : {}}
            totalFrames={videoInfo[1]}
            currFrame={videoSeek}
            loaded={isLoaded}
            onMouseEnter={handleMouseEnterDetection}
          />
        : <Flex height={'60px'} align={'center'} justify={'center'}><LoadingEllipsis /></Flex>
      }
    </StyledVideoView>
  )
}

const PhotoView = ({srcUrl, description, onError}) => {
  const dispatch = useDispatch();
  const currentImageIndex = useSelector(getCurrentImageIndex);
  const [image, ref] = useCurrentRef();
  useEffect(()=>{
    if (image !== null){
      dispatch(actions.updateStateObject("currentImageComponent", {[currentImageIndex]:  ReactDOMServer.renderToString(image.current)}))
    }
  }, [image]);
  useEffect(()=>{
    return () => {};
  }, [])
  return(
    <StyledPhotoView ref={ref} imageLoaded>
      { description && 
        <Flex align={'center'}
          position={'absolute'} width={'100%'} top={0} left={0} right={0} 
          bg={'rgba(0,0,0,0.2)'} padding={'10px 15px'} 
        >
            <Text color={'white'}>{description}</Text>
        </Flex>
      }
      <img 
        src={srcUrl}
        style={{display:'none', width:640, height:0}}
        onError={onError}
        width={0}
      />
      <InnerImageZoom
        zoomScale={1.5}
        zoomSrc={srcUrl}
        src={srcUrl}
      />
    </StyledPhotoView>
  )
}

const MediaView = ({num}) => {
  const intlMessages = useSelector(getIntlMessages);
  const dispatch = useDispatch();

  const currentUploadList = useSelector(getRequestUploads);
  const patientUploads = useSelector(getPatientUploadsById);
  const patientUploadsLoading = useSelector(getPatientUploadsLoading);

  consoleLog("currentUploadList ", currentUploadList)

  const [ error, setError] = useState(null);
  const [ mimeType, setMimeType ] = useState(null);

  const currentRecordUpload = currentUploadList[num]
  const currentUpload = patientUploads[currentRecordUpload.unique_id]

  useEffect(() => {
    if (currentUpload) {
      if (currentUpload.getUploadHead()){
        fetch(currentUpload.getUploadHead(), {method: 'HEAD'})
          .then( response => {
            if (response.status === 403){
              setError(true);
              return null;
            }
            let contenttype = response.headers.get("content-type");
            setMimeType(contenttype)
          }).catch(() => {
            setError(true);
          })
      } else if (currentUpload.getUpload()) {
        setMimeType("image/*");
      } else {
        setError(true);
      }
    }
  }, [currentUpload])

  useEffect(()=>{
    return () => {};
  }, [])

  const refetchUpload = () => {
    if (patientUploadsLoading[currentRecordUpload.unique_id] !== true){
      dispatch(recordActions.fetchRecordUpload(currentRecordUpload.unique_id))
    }
  }

  const onImageError = () => {
    consoleLog("MediaSection image error")
    setMimeType("video/*");
  }

  const onVideoError = (...props) => {
    consoleLog("MediaSection video error: ", props)
    //setError(true);
  }

  if (patientUploadsLoading[currentRecordUpload.unique_id] === true){
    return(
      <Flex minHeight={'480px'} borderRadius={'2px'} bg={'#F7F9FA'}  minWidth={'640px'} align={'center'} justify={'center'}>
        <LoadingCircle />
      </Flex>
    )
  }

  let status = "";
  if (currentUpload && currentUpload.getRegion() > -1){
    status = intlMessages[`requestDetailPage.upload.region.${currentUpload.getRegion()}.label`]
    status += ": "
    status += TOOTH_NUMBERING_SYSTEM[CURRENT_TOOTH_NUMBERING_SYSTEM][intlMessages[`requestDetailPage.upload.region.${currentUpload.getRegion()}.teethrange.start`]]
    status += " -> "
    status += TOOTH_NUMBERING_SYSTEM[CURRENT_TOOTH_NUMBERING_SYSTEM][intlMessages[`requestDetailPage.upload.region.${currentUpload.getRegion()}.teethrange.end`]]
  }
  
  if (currentUpload == null){
    return (
      <Flex minHeight={'480px'} borderRadius={'2px'} bg={'#F7F9FA'}  minWidth={'640px'} align={'center'} justify={'center'}>
      </Flex>
    )
  }

  if (error){
    return(
      <Flex direction={'column'}  borderRadius={'2px'} bg={'#F7F9FA'}  minHeight={'480px'} minWidth={'640px'} align={'center'} justify={'center'}>
          <MdErrorOutline size={26} fill={'#F93D5C'} />
          <Text fontSize={14} mt={2}>{'Error fetching asset'}</Text>
          <Text fontSize={12} color={'none.500'}>{'Click the Refresh button to refetch the asset'}</Text>
          <Button 
            leftIcon={<MdRefresh size={16} />} 
            mt={4}
            size={'sm'}
            fontSize={14} 
            variant="solid" 
            colorScheme={'bdBlue'}
            onClick={refetchUpload}
          >
            {'Refresh'}
          </Button>
      </Flex>
    )
  }
  if (mimeType && mimeType.includes("video")){
    return (
        <VideoView 
          onError={onVideoError} 
          hasDetections={currentUpload !== undefined ? currentUpload?.upload?.has_detections : null} 
          description={status} 
          srcUrl={currentUpload.getUpload()} id={currentUpload.getId()} 
        />
    )
  } else if(mimeType && mimeType.includes("image")){
    return (
        <PhotoView 
          onError={onImageError} 
          description={status} 
          srcUrl={currentUpload.getUpload()} 
        />
    )
  } else if(mimeType){
    return (
      <Flex direction={'column'} minHeight={'480px'} borderRadius={'2px'} bg={'#F7F9FA'}  minWidth={'640px'} align={'center'} justify={'center'}>
        <PaperclipIcon width={22} />
        <Text fontSize={14} mt={2}>{'No Preview Available'}</Text>
        <Text fontSize={12} color={'none.500'}>{currentUpload?.upload?.filename}</Text>
        <a style={{'text-decoration': 'none'}} download={currentUpload?.upload?.filename} href={currentUpload.getUpload()} title="downloadFIle">
          <Button 
            leftIcon={<MdFileDownload size={16} />} 
            mt={4}
            size={'sm'}
            fontSize={14} 
            variant="solid" 
            colorScheme={'bdBlue'}
          >
            {'Download'}
          </Button>
        </a>
      </Flex>
    )
  } else {
    return(
      <Flex minHeight={'480px'} borderRadius={'2px'} bg={'#F7F9FA'}  minWidth={'640px'} align={'center'} justify={'center'}>
        <LoadingCircle />
      </Flex>
    )
  }
}


export const MediaSection = () => {
  const dispatch = useDispatch();
  const imageIndex = useSelector(getCurrentImageIndex);
  const totalUploads = useSelector(getTotalUploads);
  const uploads = useSelector(getRequestUploads);
  //const currentRequestId = params.request_id
  const currentRequestId = useSelector(getCurrentRequestId);


  const currentRecordId = useSelector(getDetailsRecordId);
  const recordLoading = useSelector(getRecordLoading);
  // check if image or video
  //
  const handleClick = (index) => {
    dispatch(actions.selectIndex(index))
  }


  useEffect(()=>{
    consoleLog("current record id, record loading, uploads", currentRecordId, " ", recordLoading, " ", uploads)
    if (!recordLoading && !isNaN(currentRecordId) && uploads.length == 0){
      dispatch(recordActions.fetchRecord(currentRecordId, currentRequestId));
    }
  }, [currentRecordId])

  useEffect(()=>{
    return () => {};
  }, [])

  return (
    <StyledMediaSection>
      <StepsSection />
      <ReactTooltip id="framePreview" className={'framePreviewTooltip'}/>
      <Carousel
        statusFormatter={() => status}
        selectedItem={imageIndex}
        showThumbs={true}
        showArrows={false}
        showIndicators={false}
        onChange={handleClick}
        >
          {range(0, totalUploads).map((num, index) =>
            <MediaView key={index} num={num}/>
          )}
      </Carousel>
      <VideoFrameList />
    </StyledMediaSection>
  )

}
