import { create } from 'zustand';
import { RECORD_TYPES } from 'src/_config';

export const usePatientUploadScanStore = create((set) => ({
    isUploading: false,
    region0: null,
    region1: null,
    region2: null,
    region3: null,
    clearUploads: () => {
        set(() => ({
            isUploading: false,
            region0: null,
            region1: null,
            region2: null,
            region3: null
        }))
    
    },
    setIsUploading: (val) => {
        set((state) => ({
            ...state,
            isUploading: val
        }))
    },
    setRegion: (region, val) => {
        if (region == 0){
            set((state) => ({
                ...state,
                region0: {
                    data: val,
                    name: "region0",
                    type: "video",
                    scanType: RECORD_TYPES.FULLSCAN,
                    extraData: {region: 0, led:-1},
                    previewUrl: URL.createObjectURL(val),
                    progress: 0,
                    uploadCompleted: false,
                    isUploading: false,
                    uploadError: null
                }
            }))
        } else if (region == 1){
            set((state) => ({
                ...state,
                region1: {
                    data: val,
                    name: "region1",
                    type: "video",
                    scanType: RECORD_TYPES.FULLSCAN,
                    extraData: {region: 1, led:-1},
                    previewUrl: URL.createObjectURL(val),
                    progress: 0,
                    uploadCompleted: false,
                    isUploading: false,
                    uploadError: null
                }
            }))
        } else if (region == 2){
            set((state) => ({
                ...state,
                region2: {
                    data: val,
                    name: "region2",
                    type: "video",
                    scanType: RECORD_TYPES.FULLSCAN,
                    extraData: {region: 2, led:-1},
                    previewUrl: URL.createObjectURL(val),
                    progress: 0,
                    uploadCompleted: false,
                    isUploading: false,
                    uploadError: null
                } 
            }))
        } else if (region == 3){
            set((state) => ({
                ...state,
                region3: {
                    data: val,
                    name: "region3",
                    type: "video",
                    scanType: RECORD_TYPES.FULLSCAN,
                    extraData: {region: 3, led:-1},
                    previewUrl: URL.createObjectURL(val),
                    progress: 0,
                    uploadCompleted: false,
                    isUploading: false,
                    uploadError: null
                } 
            }))
        }
    },
    updateRegion: (region, data) => {
        if (region == 0){
            set((state) => ({
                ...state,
                region0: Object.assign({}, state.region0, data)
            }))
        } else if (region == 1){
            set((state) => ({
                ...state,
                region1: Object.assign({}, state.region1, data)
            }))
        } else if (region == 2){
            set((state) => ({
                ...state,
                region2: Object.assign({}, state.region2, data)
            }))
        } else if (region == 3){
            set((state) => ({
                ...state,
                region3: Object.assign({}, state.region3, data)
            }))
        }
    },
    removeRegion: (region) => {
        if (region == 0){
            set((state) => {
                // have to release the blob url
                URL.revokeObjectURL(state.region0.previewUrl);
                return {
                    ...state,
                    region0: null
                }
            })
        } else if (region == 1){
            set((state) => {
                // have to release the blob url
                URL.revokeObjectURL(state.region1.previewUrl);
                return {
                    ...state,
                    region1: null
                }
            })
        } else if (region == 2){
            set((state) => {
                // have to release the blob url
                URL.revokeObjectURL(state.region2.previewUrl);
                return {
                    ...state,
                    region2: null
                }
            })
        } else if (region == 3){
            set((state) => {
                // have to release the blob url
                URL.revokeObjectURL(state.region3.previewUrl);
                return {
                    ...state,
                    region3: null
                }
            })
        }
    }


}));