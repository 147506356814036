import React from 'react';
import { Icon } from '@chakra-ui/react';

const PasswordShowIcon = (props) => {
  const iconWidth = props?.width || 16;
  const iconHeight = props?.height || 10;
  return (
    <Icon 
      viewBox="0 0 16 10" 
      {...props}
      width={`${iconWidth}px`}
      height={`${iconHeight}px`}
    >
        <path d="M15.9648 4.84423C15.9418 4.79423 15.3777 3.61154 14.118 2.42051C12.9492 1.31667 10.9411 0 8 0C5.05895 0 3.05079 1.31667 1.88196 2.42051C0.622281 3.61154 0.0582069 4.79231 0.0351557 4.84423C0.0119774 4.89346 0 4.94675 0 5.00064C0 5.05453 0.0119774 5.10782 0.0351557 5.15705C0.0582069 5.20641 0.622281 6.3891 1.88196 7.58013C3.05079 8.68398 5.05895 10 8 10C10.9411 10 12.9492 8.68398 14.118 7.58013C15.3777 6.3891 15.9418 5.20833 15.9648 5.15705C15.988 5.10782 16 5.05453 16 5.00064C16 4.94675 15.988 4.89346 15.9648 4.84423ZM8 9.23077C5.87252 9.23077 4.01487 8.49872 2.4779 7.05577C1.8336 6.45035 1.28839 5.75747 0.860251 5C1.28827 4.24264 1.8335 3.54996 2.4779 2.94487C4.01487 1.50128 5.87252 0.769231 8 0.769231C10.1275 0.769231 11.9851 1.50128 13.5221 2.94487C14.1665 3.54996 14.7117 4.24264 15.1397 5C14.7079 5.78269 12.5424 9.23077 8 9.23077ZM8 2.05128C7.38318 2.05128 6.78022 2.22422 6.26735 2.54823C5.75449 2.87224 5.35476 3.33277 5.11871 3.87157C4.88267 4.41038 4.82091 5.00327 4.94124 5.57527C5.06158 6.14726 5.3586 6.67267 5.79476 7.08506C6.23091 7.49744 6.78661 7.77828 7.39158 7.89206C7.99654 8.00584 8.6236 7.94744 9.19347 7.72426C9.76333 7.50108 10.2504 7.12313 10.5931 6.63822C10.9358 6.15331 11.1187 5.5832 11.1187 5C11.1176 4.21826 10.7887 3.46884 10.2041 2.91607C9.61942 2.36329 8.8268 2.0523 8 2.05128ZM8 7.17949C7.54409 7.17949 7.09842 7.05166 6.71935 6.81218C6.34027 6.57269 6.04482 6.2323 5.87035 5.83405C5.69589 5.4358 5.65024 4.99758 5.73918 4.5748C5.82812 4.15202 6.04766 3.76368 6.37004 3.45887C6.69241 3.15406 7.10315 2.94649 7.5503 2.86239C7.99744 2.7783 8.46092 2.82146 8.88213 2.98642C9.30333 3.15138 9.66334 3.43073 9.91663 3.78914C10.1699 4.14756 10.3051 4.56894 10.3051 5C10.3051 5.57804 10.0623 6.1324 9.62996 6.54113C9.19767 6.94986 8.61135 7.17949 8 7.17949Z" fill="#4C5666"/>
 </Icon>
  );
};

export default PasswordShowIcon;
