import { createSelector } from 'reselect';
import { usePubNub } from 'pubnub-react';
import { createChannelId } from '../../_helpers/pubnubUtils';

import {
  getPatientRequestsById,
  getPatientRequestsLoading,
} from '../../PatientRequests/_redux/selectors';
import {
  getPatientRecordsById,
  getPatientRecordsLoading,
} from '../../PatientRecords/_redux/selectors';
import {
  getPatientReportById,
  getPatientReportsLoading,
} from '../../PatientReports/_redux/selectors';
import {
  getPatientNotesById,
  getPatientNotesIsMoreById,
  getPatientNotesLoading,
} from '../../PatientNotes/_redux/selectors';
import { getCurrentPatient } from '../../Patients/_redux/selectors';
import { getMessagesById, getUsersById } from '../../ChatPage/_redux/selectors';
import { getUUID } from '../../App/_redux/selectors';

const getRequestDetailSlice = (state) => state.requestDetail;

export const getCurrentRequestId = createSelector([getRequestDetailSlice], (state) => {
  return state.currentRequestId;
});

export const getCurrentMediaId = createSelector([getRequestDetailSlice], (state) => {
  return state.currentSelectedMedia;
});

export const getRequestDetails = createSelector(
  [getPatientRequestsById, getCurrentRequestId],
  (requests, currentRequestId) => {
    return requests[currentRequestId] || {};
  }
);

// after load, this will be set, not based on url, based on fetched data
export const getLoadedRequestId = createSelector([getRequestDetails], (details) => {
  return details.id;
});

export const getCurrentPatientUid = createSelector([getRequestDetailSlice], (state) => {
  return state.currentPatientUuid;
});

export const getPatientDetails = createSelector([getRequestDetails], (details) => {
  return details.patient || {};
});

export const getDetailsUid = createSelector([getRequestDetails], (details) => {
  return details.unique_id;
});

export const getDetailsPatientId = createSelector([getRequestDetails], (details) => {
  return details.patient && details.patient.id;
});

export const getDetailsRequestType = createSelector([getRequestDetails], (details) => {
  return details.type;
});

export const getDetailsReportId = createSelector([getRequestDetails], (details) => {
  return details.report;
});

export const getDetailsRecordId = createSelector([getRequestDetails], (details) => {
  return details.record;
});

export const getRequestLoading = createSelector(
  [getPatientRequestsLoading, getCurrentRequestId],
  (loading, requestId) => {
    return loading[requestId] == true;
  }
);

export const getRecordLoading = createSelector(
  [getPatientRecordsLoading, getDetailsRecordId],
  (loading, currentRecord) => {
    return loading[currentRecord] == true;
  }
);

export const getReportLoading = createSelector(
  [getPatientReportsLoading, getDetailsReportId],
  (loading, currentReport) => {
    return loading[currentReport] == true;
  }
);

export const getIsLoading = createSelector(
  [getRequestLoading, getRecordLoading, getReportLoading],
  (requestLoading, recordLoading, reportLoading) => {
    return requestLoading || recordLoading || reportLoading;
  }
);

export const getRecordDetails = createSelector(
  [getPatientRecordsById, getDetailsRecordId],
  (records, recordId) => {
    return records[recordId] || {};
  }
);

export const getReportDetails = createSelector(
  [getPatientReportById, getDetailsReportId],
  (reports, reportId) => {
    return reports[reportId] || {};
  }
);

export const getFetching = createSelector([getReportLoading], (loading) => {
  return loading;
});

export const getPartialScanRequested = createSelector([getRequestDetails], (details) => {
  return details.extra_data && details.extra_data.partialscan_requested;
});

export const getRequestCompletedAt = createSelector([getRequestDetails], (request) => {
  return request.completed_at;
});

export const getReportSentAt = createSelector([getReportDetails], (report) => {
  return report.sent_at;
});

export const getReportStatus = createSelector([getReportDetails], (report) => {
  return report.status;
});

export const getRequestDone = createSelector([getRequestDetailSlice], (details) => {
  return details.status == 'COMPLETE' || details.status == 'CANCELED';
});

export const getAppointmentCompleteModalOpen = createSelector([getRequestDetailSlice], (state) => {
  return state.appointmentCompleteModal;
});

export const getPatientNotesModalOpen = createSelector([getRequestDetailSlice], (state) => {
  return state.patientNotesModal;
});

export const getReportModalOpen = createSelector([getRequestDetailSlice], (state) => {
  return state.reportModal;
});

export const getRecordDetailModalOpen = createSelector([getRequestDetailSlice], (state) => {
  return state.recordDetailModal;
});

export const getCurrentRecordLoading = createSelector(
  [getDetailsRecordId, getPatientRecordsLoading],
  (recordId, loadingById) => {
    return loadingById[recordId];
  }
);

export const getCurrentRecordDetails = createSelector(
  [getDetailsRecordId, getPatientRecordsById],
  (recordId, byId) => {
    return byId[recordId] || {};
  }
);

export const getCurrentRecordCreatedDate = createSelector([getCurrentRecordDetails], (record) => {
  return record.created_at;
});

export const getCurrentRecordUploads = createSelector(
  [getDetailsRecordId, getPatientRecordsById],
  (recordId, byId) => {
    return byId[recordId].patientrecord_uploads || [];
  }
);

export const getCurrentSelectedMediaItem = createSelector(
  [getCurrentMediaId, getDetailsRecordId, getPatientRecordsById],
  (mediaId, recordId, byId) => {
    return byId[recordId]
      ? byId[recordId].patientrecord_uploads.find((elem) => elem.id == mediaId)
      : null;
  }
);

export const getCurrentPatientConversationId = createSelector(
  [getUUID, getCurrentPatient],
  (dentistUid, patientUid) => {
    return dentistUid && patientUid ? createChannelId(dentistUid, patientUid) : '';
  }
);

export const getCurrentPatientNotesIsMore = createSelector(
  [getCurrentPatient, getPatientNotesIsMoreById],
  (patientUid, isMore) => {
    return isMore[patientUid] ? isMore[patientUid] : false;
  }
);
export const getCurrentPatientNotesLoading = createSelector([getPatientNotesLoading], (loading) => {
  return loading;
});

export const getCurrentPatientNotes = createSelector(
  [getCurrentPatient, getPatientNotesById],
  (patientUid, notes) => {
    return notes[patientUid]
      ? Object.values(notes[patientUid]).sort(function (a, b) {
          return b.created_at_ts - a.created_at_ts;
        })
      : [];
  }
);

export const getCurrentPatientMessages = createSelector(
  [getMessagesById, getCurrentPatientConversationId, getUsersById],
  (messages, chId, users) => {
    const pn = usePubNub();
    return messages[chId]
      ? Object.values(messages[chId])
          .filter((data) => data.channel === chId)
          .map((entry) => {
            var decryptedMessage;
            if (typeof entry.message == 'string') {
              decryptedMessage = pn.decrypt(entry.message, process.env.REACT_APP_PUBNUB_CIPHER_KEY);
            } else if (entry.message.data) {
              decryptedMessage = pn.decrypt(
                entry.message.data,
                process.env.REACT_APP_PUBNUB_CIPHER_KEY
              );
            } else {
              decryptedMessage = entry.message;
            }
            if (entry.message.data && decryptedMessage != undefined) {
              return {
                ...decryptedMessage,
                timetoken: entry.timetoken.toString(),
                sender: users[decryptedMessage.senderId]
                  ? {
                      id: decryptedMessage.senderId,
                      name: decryptedMessage.senderId,
                    }
                  : {
                      id: 'unknown',
                      name: 'unknown',
                    },
              };
            } else {
              return {};
            }
          })
          .sort((msgA, msgB) => {
            if (msgA.timetoken === msgB.timetoken) {
              return 0;
            } else if (msgA.timetoken > msgB.timetoken) {
              return 1;
            } else {
              return -1;
            }
          })
      : [];
  }
);
