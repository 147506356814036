import { useState, useRef, useEffect } from "react";
import { useVisibility } from "./useVisibility";

const usePagination = (
  getNextPage,
  extraArgs,
  savePaginationState,
  restorePaginationState,
  onError = null,
  isEnabled = true,
  debounce = 500
) => {
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState(null);
  const [count, setCount] = useState(null);
  const [prev, setPagination] = useState(null);
  const [pagesRemain, setPagesRemain] = useState(true);
  const [responseId, setResponseId] = useState(extraArgs);
  // refs and intersection observers for infinite scroll
  const containerRef = useRef(null);
  const endRef = useRef(null);
  // TODO: this needs to be a parameter
  const atEnd = useVisibility(endRef, containerRef, 0, "0px 0px 500px 0px");

  // if the extra args change, reset
  useEffect(() => {
    const { count, pagination, pagesRemain } =
      (restorePaginationState && restorePaginationState()) || {};
    setPagination(pagination || null);
    setPagesRemain(pagesRemain === undefined ? true : pagesRemain);
    setCount(count || null);
  }, [extraArgs, restorePaginationState]);

  useEffect(() => {
    setLoading(false);
  }, [extraArgs]);

  const shouldUpdate =
    !loading && (atEnd || prev === null) && pagesRemain && isEnabled;

  // infinite scroll
  useEffect(() => {
    let scheduled = null;
    let results, pagination, pagesRemain;

    if (shouldUpdate) {
      // fetch next page
      (async () => {
        if (scheduled !== null) {
          clearTimeout(scheduled);
        }
        setLoading(true);
        try{
          await getNextPage(
            prev,
            count,
            extraArgs
          );
          
        } catch(err){
          console.log("err", err)
          onError && onError(err);
          return [];
        }
        const current = results?.current
        let newCount = 0
        if (results != null && current != null){
          // update state
          newCount =
            current.length > 0
              ? prev && count
                ? count + current.length
                : current.length
              : count;
          if (current.length > 0) {
            setResults(old => {
              if (prev && old) {
                return [...old, current];
              } else {
                return [current];
              }
            });
            setResponseId(extraArgs);
          }
        }
        if (!savePaginationState) {
          setCount(newCount);
          setPagination(pagination);
          setPagesRemain(pagesRemain);
        } else {
          savePaginationState(extraArgs, pagination, newCount, pagesRemain);
        }

        // enforce a delay between fetching pages
        scheduled = setTimeout(() => {
          setLoading(false);
        }, debounce);
      })();
    }

    return () => {
      if (scheduled !== null) {
        clearTimeout(scheduled);
      }
    };
  }, [
    getNextPage,
    pagesRemain,
    prev,
    count,
    debounce,
    shouldUpdate,
    extraArgs,
    savePaginationState
  ]);

  return { loading, results, containerRef, endRef, responseId };
};

export { usePagination };
