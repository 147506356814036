function createPubnubMessageTextPayload(pn, message, conversationId, dentistProfileId, senderId) {
  const secureData = pn.encrypt(JSON.stringify(message), process.env.REACT_APP_PUBNUB_CIPHER_KEY);

  const fcm_notif = {
    title: 'Message from Dentist',
    body: 'Open to check your messages',
    click_action: 'OPEN_CHAT_NOTIF',
  };
  const fcm_data = {
    pn_channel: 'CHAT',
    pn_type: 'text',
    pn_channelId: conversationId,
    pn_channelDentistId: dentistProfileId,
    pn_senderId: senderId,
  };
  return {
    data: secureData,
    pn_gcm: {
      notification: fcm_notif,
      data: fcm_data,
    },
    pn_apns: {
      aps: {
        alert: {
          title: 'Message from Dentist',
          body: 'Open to check your messages',
        },
        sound: 'default',
      },
      pn_push: [
        {
          targets: [
            {
              environment: 'development',
              topic: 'web.beforedent.plover.dev',
            },
          ],
        },
      ],
    },
  };
}

function createPubnubMessageReportPayload(pn, message, reportId) {
  const secureData = pn.encrypt(JSON.stringify(message), process.env.REACT_APP_PUBNUB_CIPHER_KEY);

  const fcm_notif = {
    title: 'Report has been Sent',
    body: 'Open to check your report',
    click_action: 'OPEN_REPORT_NOTIF',
  };
  const fcm_data = {
    pn_channel: 'REPORT',
    pn_type: 'report',
    pn_reportId: reportId,
  };
  return {
    data: secureData,
    pn_gcm: {
      notification: fcm_notif,
      data: fcm_data,
    },
    pn_apns: {
      aps: {
        alert: {
          title: 'Report has been Sent',
          body: 'Open to check your report',
        },
        sound: 'default',
      },
      pn_push: [
        {
          targets: [
            {
              environment: 'development',
              topic: 'web.beforedent.plover.dev',
            },
          ],
        },
      ],
    },
  };
}

function createPubnubMessageAppointmentConfirmPayload(pn, date, appointmentId) {
  const secureData = pn.encrypt(JSON.stringify(date), process.env.REACT_APP_PUBNUB_CIPHER_KEY);

  const fcm_notif = {
    title: 'Appointment Confirmation',
    body: `Appointment has been confirmed for ${date}`,
    click_action: 'OPEN_APPOINTMENT_NOTIF',
  };
  const fcm_data = {
    pn_channel: 'APPOINTMENT',
    pn_type: 'appointment',
    pn_appointmentId: appointmentId,
  };
  return {
    data: secureData,
    pn_gcm: {
      notification: fcm_notif,
      data: fcm_data,
    },
    pn_apns: {
      aps: {
        alert: {
          title: 'Appointment Confirmation',
          body: 'Open to check your report',
        },
        sound: 'default',
      },
      pn_push: [
        {
          targets: [
            {
              environment: 'development',
              topic: 'web.beforedent.plover.dev',
            },
          ],
        },
      ],
    },
  };
}

function createPubnubMessageVideoChatPayload(pn, message, conversationId, dentistProfileId) {
  message.type = 'videochat';
  const secureData = pn.encrypt(JSON.stringify(message), process.env.REACT_APP_PUBNUB_CIPHER_KEY);

  const fcm_notif = {
    title: 'Video Chat has started',
    body: 'Go to video chat',
    click_action: 'OPEN_VIDEOCHAT_NOTIF',
  };
  const fcm_data = {
    pn_channel: 'VIDEOCHAT',
    pn_type: 'videochat',
    pn_channelId: conversationId,
    pn_channelDentistId: dentistProfileId,
  };
  return {
    data: secureData,
    pn_gcm: {
      notification: fcm_notif,
      data: fcm_data,
    },
  };
}

function createPubnubMessagePartialScanPayload(pn, message, conversationId, dentistProfileId) {
  message.type = 'partialscan';
  const secureData = pn.encrypt(JSON.stringify(message), process.env.REACT_APP_PUBNUB_CIPHER_KEY);

  const fcm_notif = {
    title: 'Message from Dentist',
    body: 'Open to check your messages',
    click_action: 'OPEN_CHAT_NOTIF',
  };
  const fcm_data = {
    pn_channel: 'CHAT',
    pn_type: 'text',
    pn_channelId: conversationId,
    pn_channelDentistId: dentistProfileId,
  };
  return {
    data: secureData,
    pn_gcm: {
      notification: fcm_notif,
      data: fcm_data,
    },
  };
}

function endPubnubMessageVideoChatPayload(pn, message, conversationId, dentistProfileId) {
  message.type = 'videochat_end';
  const secureData = pn.encrypt(JSON.stringify(message), process.env.REACT_APP_PUBNUB_CIPHER_KEY);

  const fcm_notif = {
    title: 'Video Chat has ended',
    body: 'The dentist has ended the video chat',
    click_action: 'OPEN_VIDEOCHAT_NOTIF',
  };
  const fcm_data = {
    pn_channel: 'VIDEOCHAT',
    pn_type: 'videochat_end',
    pn_channelId: conversationId,
    pn_channelDentistId: dentistProfileId,
  };
  return {
    data: secureData,
    pn_gcm: {
      notification: fcm_notif,
      data: fcm_data,
    },
  };
}
function endPubnubMessageActionVideoChatPayload(pn, message, conversationId, dentistProfileId) {
  message.type = 'videochat_end';
  const secureData = pn.encrypt(JSON.stringify(message), process.env.REACT_APP_PUBNUB_CIPHER_KEY);

  const fcm_notif = {
    title: 'Video Chat has ended',
    body: 'The dentist has ended the video chat',
    click_action: 'OPEN_VIDEOCHAT_NOTIF',
  };
  const fcm_data = {
    pn_channel: 'VIDEOCHAT',
    pn_type: 'videochat_end',
    pn_channelId: conversationId,
    pn_channelDentistId: dentistProfileId,
  };
  return {
    data: secureData,
    pn_gcm: {
      notification: fcm_notif,
      data: fcm_data,
    },
  };
}

function createChannelId(dentist, patient) {
  if (patient && dentist) {
    return `PD1t1_${dentist}.${patient}`;
  } else {
    return null;
  }
}

function createPatientNotificationChannelId(patient) {
  if (patient) {
    return `notifications.${patient}`;
  }
  return null;
}

function extractPatientUuidFromChannelId(channelId) {
  if (channelId.startsWith('PD1t1_')) {
    var [patient] = channelId.split('.');
    return patient;
    //} else if (channelId.startsWith("clinicalsupport.")){
    //  var [support, patient] = channelId.split(".");
    //  return patient;
  } else {
    return null;
  }
}

function extractDentistUuidFromChannelId(channelId) {
  if (channelId.startsWith('PD1t1_')) {
    var [dentist] = channelId.replace('PD1t1_', '').split('.');
    return dentist;
  }
  return null;
}

export {
  createPubnubMessageTextPayload,
  createPubnubMessageReportPayload,
  endPubnubMessageVideoChatPayload,
  endPubnubMessageActionVideoChatPayload,
  createPubnubMessagePartialScanPayload,
  createPubnubMessageAppointmentConfirmPayload,
  createPubnubMessageVideoChatPayload,
  extractDentistUuidFromChannelId,
  extractPatientUuidFromChannelId,
  createChannelId,
  createPatientNotificationChannelId,
};
