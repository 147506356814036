import React, { useEffect } from 'react';
import Modal from 'react-modal';
import { useSelector, useDispatch } from 'react-redux';
import { ReportWrapper } from '../CreateReport/CreateReportModal'


import {
  getReportModalOpen,
  getCurrentRequestId,
  getDetailsReportId,
  getReportDetails
} from './_redux/selectors';

//actions
import { actions as createReportActions} from '../CreateReport/_redux/actions';
import { actions as reportActions } from '../PatientReports/_redux/actions';

import { CloseIcon as CloseSvg } from '../_images/icons/CloseIcon';
import { getPatientReportsLoading, getPatientCreateReportsLoading } from '../PatientReports/_redux/selectors';
import { getPatientRequestsById } from '../PatientRequests/_redux/selectors';
import { getCurrentPage, getReportModalState } from '../CreateReport/_redux/selectors';

import {  ModalCloseIcon } from '../_css/styles';
import { isEmpty } from 'lodash';

const generalStyles = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
      zIndex: 10,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    content: {
      overflow: 'auto',
      WebkitOverflowScrolling: 'touch',
      width: 'fit-content',
      height:  '890px',
      outline: 'none',
      padding: '0',
      position: 'initial',
      zIndex: 10
    }
}

export const CreateReportModal = ({onClose}) => {
  const dispatch = useDispatch();
  const currentPage = useSelector(getCurrentPage)
  const reportState = useSelector(getReportModalState);
  const currentRequestUid = useSelector(getCurrentRequestId);
  const currentReportId = useSelector(getDetailsReportId);
  const currentReportDetails = useSelector(getReportDetails);
  const patientReportsLoading = useSelector(getPatientReportsLoading);
  const patientCreateReportsLoading = useSelector(getPatientCreateReportsLoading);

  const patientRequests = useSelector(getPatientRequestsById);


  const modalIsOpen = useSelector(getReportModalOpen);


  useEffect(() => {    
    dispatch(createReportActions.setPage(null));
    return () => {};
  }, [])

  useEffect(()=>{
    // TODO: request detaisl initialized on RequestsTasksSection. if not on page, what happens.
    // if request is loaded and reportid is found, load report

    if (currentRequestUid != null && patientRequests[currentRequestUid] != null){
      if (currentReportId == null && patientCreateReportsLoading[currentRequestUid] !== true){
        dispatch(reportActions.createReport(currentRequestUid))
      } else if (currentReportId && isEmpty(currentReportDetails) && patientReportsLoading[currentReportId] !== true){ 
        dispatch(reportActions.fetchReport(currentReportId, currentRequestUid))
      }
    }
  }, [currentReportId, 
      currentRequestUid, 
      patientRequests[currentRequestUid], 
      patientReportsLoading[currentReportId],
      patientCreateReportsLoading[currentRequestUid]
  ])

  useEffect(()=>{
    if (((currentPage == null && reportState != null && reportState >= 0))){  //|| (reportState == 3))){
      dispatch(createReportActions.setPage(reportState));
    }
  }, [currentPage, reportState])

  /*const clickBack = () => {
    currentPage > 0 && dispatch(createReportActions.setPage(currentPage - 1));
  }

  const clickNext = () => {
    currentPage < 3 && dispatch(createReportActions.setPage(currentPage + 1));
  }*/

  const afterOpenModal = () => {
    /* all refs are set*/
    dispatch(createReportActions.setPage(null));
  }

  const closeModal = () => {
    onClose();
  }

  return(
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={generalStyles}
      shouldCloseOnOverlayClick={false}
      contentLabel="Create Report"
    >
      <div style={{position: 'relative', height:'100%'}}>
        <ModalCloseIcon onClick={closeModal} ><CloseSvg/ ></ModalCloseIcon>
        {/*<ModalPrevIcon className={cn({'disabled': currentPage < 1})} disabled={currentPage < 1} onClick={clickBack}>
          <BsChevronCompactLeft />
        </ModalPrevIcon>
        <ModalNextIcon className={cn({'disabled': currentPage > 3})} disabled={currentPage > 3} onClick={clickNext}>
          <BsChevronCompactRight />
        </ModalNextIcon>*/}
        <ReportWrapper />
      </div>
    </Modal>
  )
}
