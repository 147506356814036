import { constants } from './constants';

const initState = {
  loading: {},
  error: {},
  records: {},
  uploadsLoading: {},
  uploadsError: {},
  uploads: {},
  detectionsLoading: {},
  detectionsError: {},
  detections: {},
  partialScanCharts: {},
};

export function patientRecords(state = initState, action) {
  switch (action.type) {
    case constants.FETCHRECORD_REQUEST:
      return {
        ...state,
        loading: Object.assign({}, state.loading, { [action.data.id]: true }),
        error: Object.assign({}, state.error, { [action.data.id]: null }),
      };
    case constants.FETCHRECORD_SUCCESS:
      return {
        ...state,
        loading: Object.assign({}, state.loading, { [action.data.id]: false }),
        error: Object.assign({}, state.error, { [action.data.id]: null }),
        records: Object.assign({}, state.records, { [action.data.id]: action.data.record }),
        uploads: Object.assign({}, state.uploads, action.data.uploads),
        partialScanCharts: Object.assign({}, state.partialScanCharts, {
          [action.data.id]: action.data.partialScanChart,
        }),
      };
    case constants.FETCHRECORD_FAILURE:
      return {
        ...state,
        loading: Object.assign({}, state.loading, { [action.data.id]: false }),
        error: Object.assign({}, state.error, { [action.data.id]: action.data.error }),
      };
    case constants.FETCHRECORDUPLOAD_REQUEST:
      return {
        ...state,
        uploadsLoading: Object.assign({}, state.uploadsLoading, { [action.data.id]: true }),
        uploadsError: Object.assign({}, state.uploadsError, { [action.data.id]: null }),
      };
    case constants.FETCHRECORDUPLOAD_SUCCESS:
      return {
        ...state,
        uploadsLoading: Object.assign({}, state.uploadsLoading, { [action.data.id]: false }),
        uploadsError: Object.assign({}, state.uploadsError, { [action.data.id]: null }),
        uploads: Object.assign({}, state.uploads, { [action.data.id]: action.data.upload }),
      };
    case constants.FETCHRECORDUPLOAD_FAILURE:
      return {
        ...state,
        uploadsLoading: Object.assign({}, state.uploadsLoading, { [action.data.id]: false }),
        uploadsError: Object.assign({}, state.uploadsError, { [action.data.id]: true }),
      };

    case constants.FETCHDETECTIONS_REQUEST:
      return {
        ...state,
        detectionsLoading: Object.assign({}, state.detectionsLoading, { [action.data.id]: true }),
        detectionsError: Object.assign({}, state.detectionsError, { [action.data.id]: null }),
      };
    case constants.FETCHDETECTIONS_SUCCESS:
      return {
        ...state,
        detectionsLoading: Object.assign({}, state.detectionsLoading, { [action.data.id]: false }),
        detectionsError: Object.assign({}, state.detectionsError, { [action.data.id]: null }),
        detections: Object.assign({}, state.detections, {
          [action.data.id]: action.data.detection,
        }),
      };
    case constants.FETCHDETECTIONS_FAILURE:
      return {
        ...state,
        detectionsLoading: Object.assign({}, state.detectionsLoading, { [action.data.id]: false }),
        detectionsError: Object.assign({}, state.detectionsError, { [action.data.id]: true }),
      };
    case constants.UPDATE_RECORD:
      return {
        ...state,
        records: {
          ...state.records,
          [action.data.id]: Object.assign({}, state.records[action.data.id], action.data.value),
        },
      };
    case constants.UPDATE:
      return {
        ...state,
        ...action.data,
      };
    case constants.UPDATE_PARTIALSCANCHART:
      return {
        ...state,
        partialScanCharts: {
          ...state.partialScanCharts,
          [action.data.id]: Object.assign(
            {},
            state.partialScanCharts[action.data.id],
            action.data.value
          ),
        },
      };
    default:
      return state;
  }
}
