import { useMutation } from 'react-query';
import { QUERY_KEY_NAMES } from './constants';
import { queryClient } from 'src/_libs/react-query';

import { createReportForRequest, updateReport } from './services';


const updateReportApi = async ({ reportUid, data }) => {
    return updateReport(reportUid, data);
}

const createReportApi = async ({ requestUid, data }) => {
    return createReportForRequest(requestUid, data);
}

const handleSuccess = async (reportUid, data) => {
    queryClient.setQueryData([QUERY_KEY_NAMES.GET_REPORT, {reportId: reportUid}], (oldData) => {
        return {
            report: {
                ...(oldData?.report || {}),
                ...data
            }
        }
    });
}

const handleError =  (reportUid, error, context) => {
    console.log(error);
    queryClient.setQueryData([QUERY_KEY_NAMES.GET_REPORT, {reportId: reportUid}], context.previousData);
}

export const useSaveReport = ({ reportUid, requestUid }) => {
    return useMutation({
        mutationKey: QUERY_KEY_NAMES.SAVE_REPORT,
        onMutate: (data) => handleSuccess(reportUid, data),
        onError: (error, context) => {handleError(reportUid, error, context)},
        mutationFn: (data) => {
            if (reportUid) {
                return updateReportApi({ reportUid, data });
            } else {
                return createReportApi({ requestUid, data });
            }
        },
    });
}