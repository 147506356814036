
import { storage } from 'src/_helpers/storage';

export function authHeader() {
  // return authorization header with jwt token
  let user = storage.get('user');
  if (user && user.token) {
    return { Authorization: 'Token ' + user.token };
  } else {
    return {};
  }
}
