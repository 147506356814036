import { constants } from './constants';
import { services } from './services';
import { ReportChart } from '../../_helpers/models';
//import { constants as requestConstants } from '../../PatientRequests/_redux/constants';
import { actions as requestActions } from '../../PatientRequests/_redux/actions';

export const actions = {
  createReport,
  fetchReport,
  updateSendReport,
  addReportChart,
  removeReportChart,
  updateReportChart,
};

function createReport(id) {
  return (dispatch) => {
    dispatch(request(id));
    services.createReport(id, {}).then(
      (data) => {
        // id is reuqest id, update request id's report...
        dispatch(
          requestActions.updateRequest(id, {
            report: { id: data.unique_id, unique_id: data.unique_id },
          })
        );
        dispatch(success(id, data));
      },
      (error) => dispatch(failure(id, error))
    );
  };
  function request(id) {
    return { type: constants.CREATEREPORT_REQUEST, data: { id } };
  }
  function success(id, data) {
    // breakdown reports...
    // TODO mkae this a model? move to utils to normalize json data
    return { type: constants.CREATEREPORT_SUCCESS, data: { id, report: data } };
  }
  function failure(id, error) {
    return { type: constants.CREATEREPORT_FAILURE, data: { id, error } };
  }
}

function fetchReport(id) {
  return (dispatch) => {
    dispatch(request(id));
    services.fetchReport(id).then(
      (data) => dispatch(success(data)),
      (error) => dispatch(failure(error))
    );
  };
  function request(id) {
    return { type: constants.FETCHREPORT_REQUEST, data: { id } };
  }
  function success(data) {
    // breakdown reports...
    //
    const { report } = data;
    //const report = requestData.report // test data
    // TODO mkae this a model? move to utils to normalize json data
    data = {
      id: report.unique_id,
      reportCharts: report.report_charts.map((chrt) => new ReportChart(chrt)),
      report,
    };
    return { type: constants.FETCHREPORT_SUCCESS, data };
  }
  function failure(id, error) {
    return { type: constants.FETCHREPORT_FAILURE, data: { id, error } };
  }
}

function updateSendReport(report) {
  return (dispatch) => {
    dispatch(update(report));
  };
  function update(report) {
    return { type: constants.UPDATE_REPORT, data: { id: report.unique_id, report } };
  }
}

function addReportChart(reportId, chart) {
  return (dispatch) => {
    dispatch(update(new ReportChart(chart)));
  };
  function update(data) {
    return { type: constants.ADD_REPORTCHART, data: { reportId: reportId, reportChart: data } };
  }
}

function updateReportChart(reportId, chart) {
  return (dispatch) => {
    dispatch(update(new ReportChart(chart)));
  };
  function update(data) {
    return { type: constants.UPDATE_REPORTCHART, data: { reportId: reportId, reportChart: data } };
  }
}

function removeReportChart(reportId, reportChartId) {
  return (dispatch) => {
    dispatch(remove(reportId, reportChartId));
  };
  function remove(reportId, reportChartId) {
    return { type: constants.REMOVE_REPORTCHART, data: { reportId, reportChartId } };
  }
}
