import { API_URLS } from '../../_config';

import { authHeader, handleResponse } from '../../_helpers';
import { consoleLog } from '../../_helpers/consoleLog';

export const services = {
  loginGoogle,
  login,
  logout,
  getProfile,
  getChatInitial,
  changePassword,
  resetPassword,
  requestCodeResetPassword,
};

function loginGoogle(data) {
  const requestUrl = API_URLS.loginGoogle;
  const requestOptions = {
    method: requestUrl.method,
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  };

  return fetch(requestUrl.url, requestOptions)
    .then((response) => {
      if (!response.ok) {
        return response.json().then(Promise.reject.bind(Promise));
      }
      return response.json();
    })
    .then((user) => {
      // login successful if there's a token in the response
      consoleLog('login servie', user);
      if (user && user.key) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('user', JSON.stringify(user));
      }
      return user;
    });
}

function login(data) {
  const requestUrl = API_URLS.login;
  const requestOptions = {
    method: requestUrl.method,
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  };

  return fetch(requestUrl.url, requestOptions)
    .then((response) => {
      if (!response.ok) {
        return response.json().then(Promise.reject.bind(Promise));
      }
      return response.json();
    })
    .then((resp) => {
      // login successful if there's a token in the response
      return resp;
    });
}

function getProfile() {
  const requestUrl = API_URLS.getProfile;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };
  let url = requestUrl.url;
  return fetch(url, requestOptions).then(handleResponse);
}

function resetPassword(data) {
  const requestUrl = API_URLS.resetPassword;
  const requestOptions = {
    method: requestUrl.method,
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  };
  const url = requestUrl.url;
  return fetch(url, requestOptions).then(handleResponse);
}

function requestCodeResetPassword(data) {
  const requestUrl = API_URLS.requestCodeResetPassword;
  const requestOptions = {
    method: requestUrl.method,
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  };
  const url = requestUrl.url;
  return fetch(url, requestOptions).then(handleResponse);
}

function changePassword(data) {
  const requestUrl = API_URLS.changePassword;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  };

  let url = requestUrl.url;
  return fetch(url, requestOptions).then(handleResponse);
}

function getChatInitial() {
  const requestUrl = API_URLS.getChatInitial;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };
  let url = requestUrl.url;
  return fetch(url, requestOptions).then(handleResponse);
}

function logout() {
  const requestUrl = API_URLS.logout;
  const requestOptions = {
    method: requestUrl.method,
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    body: JSON.stringify({}),
  };

  return fetch(requestUrl.url, requestOptions).then(() => {
    localStorage.removeItem('user');
    return;
  });
}
