import React from 'react';
import cn from 'classnames';
import styled from 'styled-components';
import { TOOTH_NUMBERING_SYSTEM } from '../../_config';

const StyledToothSetOutsideNum = styled.div`
.number{
  font-family:'ProximaNova-Regular';
  font-size:9px;

}
.region {
  fill:#FFFFFF;
  &:hover{
    cursor:pointer;
  }
}

.tongue{
  opacity:0.4;
  fillRule:evenodd;
  clipRule:evenodd;
  fill:#FFFFFF;
}

.ALL {fill:#000000;}
.GENERAL {fill:#A9A8A8;}
.OTHERS {fill:#A9A8A8;}
.CARIES {fill:#DC4E41;}
.ORTHO{fill:#00574F;}
.GUMS{fill:#FFA500;}
.HABITS{fill:#DAFF00;}
.CALCULUS{fill:#FFA500;}
.COLOR { fill:#367FFF;}
`;

const ToothSetOutsideNum = ({charting={}, toothCharting={}, width="194", height="294", numberingSystem="palmer", onClick}) => (
  <StyledToothSetOutsideNum>
    <svg version="1.1" 
      id="toothSetOutsideNum" xmlns="http://www.w3.org/2000/svg" 
      x="0px" y="0px"
      viewBox="0 0 194 294" width={width} height={height}>
    <style type="text/css">
    </style>
    <text transform="matrix(1 0 0 1 1.7903 129.0001)" className={cn("number", "num_18", toothCharting["t18"])}>{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t18"]}</text>
    <text transform="matrix(1 0 0 1 3.6442 106.996)" className={cn("number", "num_17", toothCharting["t17"])}>{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t17"]}</text>
    <text transform="matrix(1 0 0 1 10.3231 85.0059)" className={cn("number", "num_16", toothCharting["t16"])}>{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t16"]}</text>
    <text transform="matrix(1 0 0 1 16.8052 66.0001)" className={cn("number", "num_15", toothCharting["t15"])}>{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t15"]}</text>
    <text transform="matrix(1 0 0 1 26.6825 46.0035)" className={cn("number", "num_14", toothCharting["t14"])}>{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t14"]}</text>
    <text transform="matrix(1 0 0 1 39.6551 27.0262)" className={cn("number", "num_13", toothCharting["t13"])}>{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t13"]}</text>
    <text transform="matrix(1 0 0 1 55.984 14.9998)" className={cn("number", "num_12", toothCharting["t12"])}>{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t12"]}</text>
    <text transform="matrix(1 0 0 1 82.0737 9.0076)" className={cn("number", "num_11", toothCharting["t11"])}>{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t11"]}</text>
    <text transform="matrix(1 0 0 1 104.8763 9.0074)" className={cn("number", "num_21", toothCharting["t21"])}>{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t21"]}</text>
    <text transform="matrix(1 0 0 1 130.0155 14.9997)" className={cn("number", "num_22", toothCharting["t22"])}>{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t22"]}</text>
    <text transform="matrix(1 0 0 1 147.7629 27)" className={cn("number", "num_23", toothCharting["t23"])}>{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t23"]}</text>
    <text transform="matrix(1 0 0 1 157.6225 46.0033)" className={cn("number", "num_24", toothCharting["t24"])}>{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t24"]}</text>
    <text transform="matrix(1 0 0 1 169.7971 66.0002)" className={cn("number", "num_25", toothCharting["t25"])}>{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t25"]}</text>
    <text transform="matrix(1 0 0 1 176.8247 85.0001)" className={cn("number", "num_26", toothCharting["t26"])}>{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t26"]}</text>
    <text transform="matrix(1 0 0 1 180.6258 106.9957)" className={cn("number", "num_27", toothCharting["t27"])}>{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t27"]}</text>
    <text transform="matrix(1 0 0 1 184.6269 129.0001)" className={cn("number", "num_28", toothCharting["t28"])}>{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t28"]}</text>
    <text transform="matrix(1 0 0 1 179.1478 196.9995)" className={cn("number", "num_37", toothCharting["t37"])}>{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t37"]}</text>
    <text transform="matrix(1 0 0 1 182.4635 173.9998)" className={cn("number", "num_38", toothCharting["t38"])}>{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t38"]}</text>
    <text transform="matrix(1 0 0 1 172.6277 217.0018)" className={cn("number", "num_36", toothCharting["t36"])}>{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t36"]}</text>
    <text transform="matrix(1 0 0 1 165.3958 237.0019)" className={cn("number", "num_35", toothCharting["t35"])}>{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t35"]}</text>
    <text transform="matrix(1 0 0 1 155.6284 256.9872)" className={cn("number", "num_34", toothCharting["t34"])}>{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t34"]}</text>
    <text transform="matrix(1 0 0 1 144.5862 272.9999)" className={cn("number", "num_33", toothCharting["t33"])}>{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t33"]}</text>
    <text transform="matrix(1 0 0 1 126.2147 285.9973)" className={cn("number", "num_32", toothCharting["t32"])}>{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t32"]}</text>
    <text transform="matrix(1 0 0 1 104.3803 292.0004)" className={cn("number", "num_31", toothCharting["t31"])}>{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t31"]}</text>
    <text transform="matrix(1 0 0 1 53.994 286.0003)" className={cn("number", "num_42", toothCharting["t42"])}>{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t42"]}</text>
    <text transform="matrix(1 0 0 1 78.2264 292.0001)" className={cn("number", "num_41", toothCharting["t41"])}>{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t41"]} </text>
    <text transform="matrix(1 0 0 1 27.0414 256.987)" className={cn("number", "num_44", toothCharting["t44"])}>{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t44"]}</text>
    <text transform="matrix(1 0 0 1 37.2989 273.0001)" className={cn("number", "num_43", toothCharting["t43"])}>{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t43"]}</text>
    <text transform="matrix(1 0 0 1 17.5002 236.997)" className={cn("number", "num_45", toothCharting["t45"])}>{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t45"]}</text>
    <text transform="matrix(1 0 0 1 9.9913 217.0016)" className={cn("number", "num_46", toothCharting["t46"])}>{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t46"]}</text>
    <text transform="matrix(1 0 0 1 6.2217 197.0016)" className={cn("number", "num_47", toothCharting["t47"])}>{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t47"]}</text>
    <text transform="matrix(1 0 0 1 1.9155 174)" className={cn("number", "num_48", toothCharting["t48"])}>{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t48"]}</text>
    <path className={cn("region", "t43", charting["t43"])} onClick={onClick && (() => onClick('t43'))} d="M69,251.5c-0.5-2.2-1.6-4.1-3.2-5.7c-1.4-1.4-3.1-1.6-4.8-0.7c-1.8,1-3.5,2.1-4.9,3.5c-1.3,1.2-2.5,2.7-3.4,4.3
    	c-0.2,0.4-0.4,0.8-0.6,1.2c-1.4,3.6-0.1,5.7,3.8,5.8c0.4,0,0.9,0,1.3,0c2.2-0.2,4.3-0.3,6.4-0.9c0.9-0.3,1.7-0.6,2.5-1
    	C68.7,256.5,69.7,254.2,69,251.5"/>
    <path className={cn("region", "t48", charting["t48"])} onClick={onClick && (() => onClick('t48'))} d="M39.7,150.8c-2.2-0.6-4.4-1.1-6.6-1c-0.2,0-0.5,0-0.7,0c-2.6-0.1-4.9,0.1-7,1c-2.6,1-3.8,2.9-3.9,5.6
    	c0,1.2,0,2.3,0,3.5c0,1.4,0,2.8-0.2,4.2c-0.4,4,2.2,6.6,6.9,6.7c1.2,0,2.5,0.1,3.7,0c1.9,0,3.9-0.1,5.8-0.3c3.5-0.3,5.8-3.2,5.2-6.8
    	c-0.1-0.9-0.1-1.8,0.1-2.6c0.1-0.6,0.3-1.2,0.6-1.8C45.7,154.9,44.5,152.2,39.7,150.8"/>
    <path className={cn("region", "t44", charting["t44"])} onClick={onClick && (() => onClick('t44'))} d="M59.1,235.5c-0.2-0.4-0.4-0.9-0.7-1.3c-1.8-2.9-5-4.3-8.3-2.7c-0.7,0.4-1.5,0.7-2.2,1.1
    	c-1.4,0.8-2.7,1.6-3.9,2.8c-2,2-2,6.2-0.2,8.9c0.7,1,1.6,1.8,2.7,2.2c1.6,0.6,3.2,1,4.7,0.9c1.5,0,2.9-0.2,4.3-0.5
    	c0.7-0.2,1.3-0.4,1.9-0.6c1.9-0.6,3.3-2.6,3.2-4.7C60.4,239.6,60,237.5,59.1,235.5"/>
    <path className={cn("region", "t45", charting["t45"])} onClick={onClick && (() => onClick('t45'))} d="M54.1,224.9c-0.1-1.7-0.4-3.3-0.9-4.9c-0.6-1.9-1.6-3.6-3.2-5.1c-1.5-1.4-3.4-2.1-5.5-1.5
    	c-1.3,0.4-2.5,0.8-3.7,1.4c-1.7,0.9-3.3,2-4.8,3.3c-2.4,2.2-2.5,6.2-1.1,9.1c0.8,1.6,2,2.9,3.6,3.5c1.7,0.6,3.5,1,5.4,1
    	c1.2-0.1,2.4-0.2,3.7-0.4c0.8-0.1,1.5-0.3,2.3-0.5C53,230,54.4,228,54.1,224.9"/>
    <path className={cn("region", "t47", charting["t47"])} onClick={onClick && (() => onClick('t47'))} d="M44.8,185.9c-0.4-1.2-0.6-2.4-0.7-3.6c0-1.1,0-2.2,0.2-3.4c0.5-3.1-1.1-5.7-4.2-6.5c-2.7-0.7-5.3-0.9-7.9-0.7
    	c-2.3,0.2-4.6,0.8-6.9,1.8c-2.6,1.2-3.7,3.4-3.3,6.3c0.1,1,0.3,1.9,0.7,2.9c0.2,0.7,0.4,1.4,0.6,2.1c0.2,0.8,0.3,1.7,0.4,2.5
    	c0.2,4.5,3.1,7.1,8.4,7c1-0.2,2.1-0.4,3.3-0.6c1.8-0.3,3.8-0.7,5.8-1.2C44.2,191.9,45.8,188.8,44.8,185.9"/>
    <path className={cn("region", "t46", charting["t46"])} onClick={onClick && (() => onClick('t46'))} d="M48.1,206.7c-0.5-1.4-1-2.8-1.4-4.2c-0.3-1-0.5-2.1-0.6-3.2c-0.5-4-3.7-6.3-7.7-5.3c-0.9,0.2-1.7,0.5-2.6,0.7
    	c-1.5,0.5-3.1,1-4.6,1.6c-2.3,0.9-3.7,3.7-3.2,6.3c0.2,1.3,0.6,2.6,1,3.9c0.3,0.9,0.6,1.8,0.9,2.7c0.7,1.9,2.3,2.9,4.1,3.4
    	c1.8,0.6,3.7,0.9,5.4,0.7c0.4,0,0.7,0,1.1,0c1.7,0,3.2-0.3,4.7-0.7C48.3,211.6,49.3,209.7,48.1,206.7"/>
    <path className={cn("region", "t41", charting["t41"])} onClick={onClick && (() => onClick('t41'))} d="M96.3,272.4c-0.1-0.5-0.2-1.1-0.3-1.7c-0.5-2.7-1.2-5.6-3.5-8c-1.2-1.2-2.5-1.5-4.1-1.2c-2.7,0.5-4.9,2.1-7,3.8
    	c-1,0.7-1.8,1.6-2.6,2.5c-0.4,0.4-0.7,0.9-1.1,1.3c-1.7,2.2-1.6,3.4,0.6,5c1.1,0.8,2.3,1.2,3.6,1.5c2.5,0.6,5.1,1.3,7.6,1.3
    	c1.9,0,3.9-0.1,5.7-1.1C96.2,275.3,96.4,274.8,96.3,272.4"/>
    <path className={cn("region", "t42", charting["t42"])} onClick={onClick && (() => onClick('t42'))} d="M77.8,260c-0.5-2.5-1.8-3.9-5.2-3.6c-3.1,0.3-5.6,1.6-7.9,3.4c-1.1,0.9-2,1.9-3,2.9c-0.9,1-0.7,2.2,0.4,3.4
    	c1.8,1.9,4,3,6.4,3.9c1.9,0.7,3.8,1.3,5.9,1c0.8-0.1,1.3-0.5,1.7-1.2c0.4-0.9,0.8-1.9,1.1-2.9c0.5-1.7,0.7-3.4,0.9-5.3
    	C78,261.3,78,260.7,77.8,260"/>


    <path className={cn("region", "t33", charting["t33"])} onClick={onClick && (() => onClick('t33'))} d="M125.3,251.5c0.5-2.2,1.6-4.1,3.2-5.7c1.4-1.4,3.1-1.6,4.8-0.7c1.8,1,3.5,2.1,4.9,3.5c1.3,1.2,2.5,2.7,3.4,4.3
    	c0.2,0.4,0.4,0.8,0.6,1.2c1.4,3.6,0.1,5.7-3.8,5.8c-0.4,0-0.9,0-1.3,0c-2.2-0.2-4.3-0.3-6.4-0.9c-0.9-0.3-1.7-0.6-2.5-1
    	C125.7,256.5,124.7,254.2,125.3,251.5"/>
    <path className={cn("region", "t38", charting["t38"])} onClick={onClick && (() => onClick('t38'))} d="M154.7,150.8c2.2-0.6,4.4-1.1,6.6-1c0.2,0,0.5,0,0.7,0c2.6-0.1,4.9,0.1,7,1c2.6,1,3.8,2.9,3.9,5.6
    	c0,1.2,0,2.3,0,3.5c0,1.4,0,2.8,0.2,4.2c0.4,4-2.2,6.6-6.9,6.7c-1.2,0-2.5,0.1-3.7,0c-1.9,0-3.9-0.1-5.8-0.3
    	c-3.5-0.3-5.8-3.2-5.2-6.8c0.1-0.9,0.1-1.8-0.1-2.6c-0.1-0.6-0.3-1.2-0.6-1.8C148.6,154.9,149.8,152.2,154.7,150.8"/>
    <path className={cn("region", "t34", charting["t34"])} onClick={onClick && (() => onClick('t34'))} d="M135.2,235.5c0.2-0.4,0.4-0.9,0.7-1.3c1.8-2.9,5-4.3,8.3-2.7c0.7,0.4,1.5,0.7,2.2,1.1c1.4,0.8,2.7,1.6,3.9,2.8
    	c2,2,2,6.2,0.2,8.9c-0.7,1-1.6,1.8-2.7,2.2c-1.6,0.6-3.2,1-4.7,0.9c-1.5,0-2.9-0.2-4.3-0.5c-0.7-0.2-1.3-0.4-1.9-0.6
    	c-1.9-0.6-3.3-2.6-3.2-4.7C133.9,239.6,134.3,237.5,135.2,235.5"/>
    <path className={cn("region", "t35", charting["t35"])} onClick={onClick && (() => onClick('t35'))} d="M140.2,224.9c0.1-1.7,0.4-3.3,0.9-4.9c0.6-1.9,1.6-3.6,3.2-5.1c1.5-1.4,3.4-2.1,5.5-1.5
    	c1.3,0.4,2.5,0.8,3.7,1.4c1.7,0.9,3.3,2,4.8,3.3c2.4,2.2,2.5,6.2,1.1,9.1c-0.8,1.6-2,2.9-3.6,3.5c-1.7,0.6-3.5,1-5.4,1
    	c-1.2-0.1-2.4-0.2-3.7-0.4c-0.8-0.1-1.5-0.3-2.3-0.5C141.4,230,139.9,228,140.2,224.9"/>
    <path className={cn("region", "t36", charting["t36"])} onClick={onClick && (() => onClick('t36'))} d="M146.2,206.7c0.5-1.4,1-2.8,1.4-4.2c0.3-1,0.5-2.1,0.6-3.2c0.5-4,3.7-6.3,7.7-5.3c0.9,0.2,1.7,0.5,2.6,0.7
    	c1.5,0.5,3.1,1,4.6,1.6c2.3,0.9,3.7,3.7,3.2,6.3c-0.2,1.3-0.6,2.6-1,3.9c-0.3,0.9-0.6,1.8-0.9,2.7c-0.7,1.9-2.3,2.9-4.1,3.4
    	c-1.8,0.6-3.7,0.9-5.4,0.7c-0.4,0-0.7,0-1.1,0c-1.7,0-3.2-0.3-4.7-0.7C146,211.6,145.1,209.7,146.2,206.7"/>
    <path className={cn("region", "t37", charting["t37"])} onClick={onClick && (() => onClick('t37'))} d="M149.6,185.9c0.4-1.2,0.6-2.4,0.7-3.6c0-1.1,0-2.2-0.2-3.4c-0.5-3.1,1.1-5.7,4.2-6.5c2.7-0.7,5.3-0.9,7.9-0.7
    	c2.3,0.2,4.6,0.8,6.9,1.8c2.6,1.2,3.7,3.4,3.3,6.3c-0.1,1-0.3,1.9-0.7,2.9c-0.2,0.7-0.4,1.4-0.6,2.1c-0.2,0.8-0.3,1.7-0.4,2.5
    	c-0.2,4.5-3.1,7.1-8.4,7c-1-0.2-2.1-0.4-3.3-0.6c-1.8-0.3-3.8-0.7-5.8-1.2C150.2,191.9,148.5,188.8,149.6,185.9"/>
    <path className={cn("region", "t31", charting["t31"])} onClick={onClick && (() => onClick('t31'))} d="M98,272.4c0.1-0.5,0.2-1.1,0.3-1.7c0.5-2.7,1.2-5.6,3.5-8c1.2-1.2,2.5-1.5,4.1-1.2c2.7,0.5,4.9,2.1,7,3.8
    	c1,0.7,1.8,1.6,2.6,2.5c0.4,0.4,0.7,0.9,1.1,1.3c1.7,2.2,1.6,3.4-0.6,5c-1.1,0.8-2.3,1.2-3.6,1.5c-2.5,0.6-5.1,1.3-7.6,1.3
    	c-1.9,0-3.9-0.1-5.7-1.1C98.2,275.3,98,274.8,98,272.4"/>
    <path className={cn("region", "t32", charting["t32"])} onClick={onClick && (() => onClick('t32'))} d="M116.5,260c0.5-2.5,1.8-3.9,5.2-3.6c3.1,0.3,5.6,1.6,7.9,3.4c1.1,0.9,2,1.9,3,2.9c0.9,1,0.7,2.2-0.4,3.4
    	c-1.8,1.9-4,3-6.4,3.9c-1.9,0.7-3.8,1.3-5.9,1c-0.8-0.1-1.3-0.5-1.6-1.2c-0.4-0.9-0.8-1.9-1.1-2.9c-0.5-1.7-0.7-3.4-0.9-5.3
    	C116.3,261.3,116.3,260.7,116.5,260"/>


    <path className={cn("region", "t13", charting["t13"])} onClick={onClick && (() => onClick('t13'))} d="M69,41.5c-0.5,2.2-1.6,4.1-3.2,5.7c-1.4,1.4-3.1,1.6-4.8,0.7c-1.8-1-3.5-2.1-4.9-3.5c-1.3-1.2-2.5-2.7-3.4-4.3
    	c-0.2-0.4-0.4-0.8-0.6-1.2c-1.4-3.6-0.1-5.7,3.8-5.8c0.4,0,0.9,0,1.3,0c2.2,0.2,4.3,0.3,6.4,0.9c0.9,0.3,1.7,0.6,2.5,1
    	C68.7,36.5,69.7,38.7,69,41.5"/>
    <path className={cn("region", "t18", charting["t18"])} onClick={onClick && (() => onClick('t18'))} d="M39.7,142.2c-2.2,0.6-4.4,1.1-6.6,1c-0.2,0-0.5,0-0.7,0c-2.6,0.1-4.9-0.1-7-1c-2.6-1-3.8-2.9-3.9-5.6
    	c0-1.2,0-2.3,0-3.5c0-1.4,0-2.8-0.2-4.2c-0.4-4,2.2-6.6,6.9-6.7c1.2-0.1,2.5-0.1,3.7,0c1.9,0,3.9,0.1,5.8,0.3
    	c3.5,0.3,5.8,3.2,5.2,6.8c-0.1,0.9-0.1,1.8,0.1,2.6c0.1,0.6,0.3,1.2,0.6,1.8C45.7,138.1,44.5,140.8,39.7,142.2"/>
    <path className={cn("region", "t14", charting["t14"])} onClick={onClick && (() => onClick('t14'))} d="M59.1,57.4c-0.2,0.4-0.4,0.9-0.7,1.3c-1.8,2.9-5,4.3-8.3,2.7c-0.7-0.4-1.5-0.7-2.2-1.1
    	c-1.4-0.8-2.7-1.6-3.9-2.8c-2-2-2-6.2-0.2-8.9c0.7-1,1.6-1.8,2.7-2.2c1.6-0.6,3.2-1,4.7-0.9c1.5,0,2.9,0.2,4.3,0.5
    	c0.7,0.2,1.3,0.4,1.9,0.6c1.9,0.6,3.3,2.6,3.2,4.7C60.4,53.4,60,55.5,59.1,57.4"/>
    <path className={cn("region", "t15", charting["t15"])} onClick={onClick && (() => onClick('t15'))} d="M54.1,68.1c-0.1,1.7-0.4,3.3-0.9,4.9c-0.6,1.9-1.6,3.6-3.2,5.1c-1.5,1.4-3.4,2.1-5.5,1.5
    	c-1.3-0.4-2.5-0.8-3.7-1.4c-1.7-0.9-3.3-2-4.8-3.3c-2.4-2.2-2.5-6.2-1.1-9.1c0.8-1.6,2-2.9,3.6-3.5c1.7-0.6,3.5-1,5.4-1
    	c1.2,0.1,2.4,0.2,3.7,0.4c0.8,0.1,1.5,0.3,2.3,0.5C53,63,54.4,64.9,54.1,68.1"/>
    <path className={cn("region", "t16", charting["t16"])} onClick={onClick && (() => onClick('t16'))} d="M48.1,86.2c-0.5,1.4-1,2.8-1.4,4.2c-0.3,1-0.5,2.1-0.6,3.2c-0.5,4-3.7,6.3-7.7,5.3c-0.9-0.2-1.7-0.5-2.6-0.7
    	c-1.5-0.5-3.1-1-4.6-1.6c-2.3-0.9-3.7-3.7-3.2-6.3c0.2-1.3,0.6-2.6,1-3.9c0.3-0.9,0.6-1.8,0.9-2.7c0.7-1.9,2.3-2.9,4.1-3.4
    	c1.8-0.6,3.7-0.9,5.4-0.7c0.4,0,0.7,0,1.1,0c1.7,0,3.2,0.3,4.7,0.7C48.3,81.3,49.3,83.3,48.1,86.2"/>
    <path className={cn("region", "t17", charting["t17"])} onClick={onClick && (() => onClick('t17'))} d="M44.8,107c-0.4,1.2-0.6,2.4-0.7,3.6c0,1.1,0,2.2,0.2,3.4c0.5,3.1-1.1,5.7-4.2,6.5c-2.7,0.7-5.3,0.9-7.9,0.7
    	c-2.3-0.2-4.6-0.8-6.9-1.8c-2.6-1.2-3.7-3.4-3.3-6.3c0.1-1,0.3-1.9,0.7-2.9c0.2-0.7,0.4-1.4,0.6-2.1c0.2-0.8,0.3-1.7,0.4-2.5
    	c0.2-4.5,3.1-7.1,8.4-7c1,0.2,2.1,0.4,3.3,0.6c1.8,0.3,3.8,0.7,5.8,1.2C44.2,101.1,45.8,104.1,44.8,107"/>
    <path className={cn("region", "t11", charting["t11"])} onClick={onClick && (() => onClick('t11'))} d="M96.3,20.5c-0.1,0.5-0.2,1.1-0.3,1.7c-0.5,2.7-1.2,5.6-3.5,8c-1.2,1.2-2.5,1.5-4.1,1.2c-2.7-0.5-4.9-2.1-7-3.8
    	c-1-0.7-1.8-1.6-2.6-2.5c-0.4-0.4-0.7-0.9-1.1-1.3c-1.7-2.2-1.6-3.4,0.6-5c1.1-0.8,2.3-1.2,3.6-1.5c2.5-0.6,5.1-1.3,7.6-1.3
    	c1.9,0,3.9,0.1,5.7,1.1C96.2,17.6,96.4,18.2,96.3,20.5"/>
    <path className={cn("region", "t12", charting["t12"])} onClick={onClick && (() => onClick('t12'))} d="M77.8,32.9c-0.5,2.5-1.8,3.9-5.2,3.6c-3.1-0.3-5.6-1.6-7.9-3.4c-1.1-0.9-2-1.9-3-2.9c-0.9-1-0.7-2.2,0.4-3.4
    	c1.8-1.9,4-3,6.4-3.9c1.9-0.7,3.8-1.3,5.9-1c0.8,0.1,1.3,0.5,1.7,1.2c0.4,0.9,0.8,1.9,1.1,2.9c0.5,1.7,0.7,3.4,0.9,5.3
    	C78,31.7,78,32.3,77.8,32.9"/>


    <path className={cn("region", "t23", charting["t23"])} onClick={onClick && (() => onClick('t23'))} d="M125.3,41.5c0.5,2.2,1.6,4.1,3.2,5.7c1.4,1.4,3.1,1.6,4.8,0.7c1.8-1,3.5-2.1,4.9-3.5c1.3-1.2,2.5-2.7,3.4-4.3
    	c0.2-0.4,0.4-0.8,0.6-1.2c1.4-3.6,0.1-5.7-3.8-5.8c-0.4,0-0.9,0-1.3,0c-2.2,0.2-4.3,0.3-6.4,0.9c-0.9,0.3-1.7,0.6-2.5,1
    	C125.7,36.5,124.7,38.7,125.3,41.5"/>
    <path className={cn("region", "t28", charting["t28"])} onClick={onClick && (() => onClick('t28'))} d="M154.7,142.2c2.2,0.6,4.4,1.1,6.6,1c0.2,0,0.5,0,0.7,0c2.6,0.1,4.9-0.1,7-1c2.6-1,3.8-2.9,3.9-5.6
    	c0-1.2,0-2.3,0-3.5c0-1.4,0-2.8,0.2-4.2c0.4-4-2.2-6.6-6.9-6.7c-1.2-0.1-2.5-0.1-3.7,0c-1.9,0-3.9,0.1-5.8,0.3
    	c-3.5,0.3-5.8,3.2-5.2,6.8c0.1,0.9,0.1,1.8-0.1,2.6c-0.1,0.6-0.3,1.2-0.6,1.8C148.6,138.1,149.8,140.8,154.7,142.2"/>
    <path className={cn("region", "t24", charting["t24"])} onClick={onClick && (() => onClick('t24'))} d="M135.2,57.4c0.2,0.4,0.4,0.9,0.7,1.3c1.8,2.9,5,4.3,8.3,2.7c0.7-0.4,1.5-0.7,2.2-1.1c1.4-0.8,2.7-1.6,3.9-2.8
    	c2-2,2-6.2,0.2-8.9c-0.7-1-1.6-1.8-2.7-2.2c-1.6-0.6-3.2-1-4.7-0.9c-1.5,0-2.9,0.2-4.3,0.5c-0.7,0.2-1.3,0.4-1.9,0.6
    	c-1.9,0.6-3.3,2.6-3.2,4.7C133.9,53.4,134.3,55.5,135.2,57.4"/>
    <path className={cn("region", "t25", charting["t25"])} onClick={onClick && (() => onClick('t25'))} d="M140.2,68.1c0.1,1.7,0.4,3.3,0.9,4.9c0.6,1.9,1.6,3.6,3.2,5.1c1.5,1.4,3.4,2.1,5.5,1.5c1.3-0.4,2.5-0.8,3.7-1.4
    	c1.7-0.9,3.3-2,4.8-3.3c2.4-2.2,2.5-6.2,1.1-9.1c-0.8-1.6-2-2.9-3.6-3.5c-1.7-0.6-3.5-1-5.4-1c-1.2,0.1-2.4,0.2-3.7,0.4
    	c-0.8,0.1-1.5,0.3-2.3,0.5C141.4,63,139.9,64.9,140.2,68.1"/>
    <path className={cn("region", "t26", charting["t26"])} onClick={onClick && (() => onClick('t26'))} d="M146.2,86.2c0.5,1.4,1,2.8,1.4,4.2c0.3,1,0.5,2.1,0.6,3.2c0.5,4,3.7,6.3,7.7,5.3c0.9-0.2,1.7-0.5,2.6-0.7
    	c1.5-0.5,3.1-1,4.6-1.6c2.3-0.9,3.7-3.7,3.2-6.3c-0.2-1.3-0.6-2.6-1-3.9c-0.3-0.9-0.6-1.8-0.9-2.7c-0.7-1.9-2.3-2.9-4.1-3.4
    	c-1.8-0.6-3.7-0.9-5.4-0.7c-0.4,0-0.7,0-1.1,0c-1.7,0-3.2,0.3-4.7,0.7C146,81.3,145.1,83.3,146.2,86.2"/>
    <path className={cn("region", "t27", charting["t27"])} onClick={onClick && (() => onClick('t27'))} d="M149.6,107c0.4,1.2,0.6,2.4,0.7,3.6c0,1.1,0,2.2-0.2,3.4c-0.5,3.1,1.1,5.7,4.2,6.5c2.7,0.7,5.3,0.9,7.9,0.7
    	c2.3-0.2,4.6-0.8,6.9-1.8c2.6-1.2,3.7-3.4,3.3-6.3c-0.1-1-0.3-1.9-0.7-2.9c-0.2-0.7-0.4-1.4-0.6-2.1c-0.2-0.8-0.3-1.7-0.4-2.5
    	c-0.2-4.5-3.1-7.1-8.4-7c-1,0.2-2.1,0.4-3.3,0.6c-1.8,0.3-3.8,0.7-5.8,1.2C150.2,101.1,148.5,104.1,149.6,107"/>
    <path className={cn("region", "t21", charting["t21"])} onClick={onClick && (() => onClick('t21'))} d="M98,20.5c0.1,0.5,0.2,1.1,0.3,1.7c0.5,2.7,1.2,5.6,3.5,8c1.2,1.2,2.5,1.5,4.1,1.2c2.7-0.5,4.9-2.1,7-3.8
    	c1-0.7,1.8-1.6,2.6-2.5c0.4-0.4,0.7-0.9,1.1-1.3c1.7-2.2,1.6-3.4-0.6-5c-1.1-0.8-2.3-1.2-3.6-1.5c-2.5-0.6-5.1-1.3-7.6-1.3
    	c-1.9,0-3.9,0.1-5.7,1.1C98.2,17.6,98,18.2,98,20.5"/>
    <path className={cn("region", "t22", charting["t22"])} onClick={onClick && (() => onClick('t22'))} d="M116.5,32.9c0.5,2.5,1.8,3.9,5.2,3.6c3.1-0.3,5.6-1.6,7.9-3.4c1.1-0.9,2-1.9,3-2.9c0.9-1,0.7-2.2-0.4-3.4
    	c-1.8-1.9-4-3-6.4-3.9c-1.9-0.7-3.8-1.3-5.9-1c-0.8,0.1-1.3,0.5-1.6,1.2c-0.4,0.9-0.8,1.9-1.1,2.9c-0.5,1.7-0.7,3.4-0.9,5.3
    	C116.3,31.7,116.3,32.3,116.5,32.9"/>
    <path className="tongue" d="M97.5,212.2c0,0.2-0.2,0.4-0.4,0.4s-0.4-0.2-0.4-0.4v-49.9c0-0.2,0.2-0.4,0.4-0.4s0.4,0.2,0.4,0.4V212.2z
    		 M136.8,169.8c0.8-10.6-4-19.2-12.4-21.8c-13.1-4.1-22.3,1.6-25.5,4c-0.6,0.4-1.3,0.7-2,0.7c-0.7,0-1.4-0.3-2-0.7
    		c-4.8-3.6-10.4-5.5-16.3-5.4c-3.1,0-6.2,0.5-9.1,1.4c-8.4,2.6-13.2,11.1-12.4,21.7c0,0.1,0,0.2,0,0.2c0,3.1-0.3,64.4,23.6,78.2
    		c0.1,0,0.1,0.1,0.2,0.1c0.8,0.4,7.1,3.8,16.8,3.8c10.3,0,15-3.7,15.1-3.7l0.1-0.1c24.7-14,23.8-77.7,23.8-78.3V169.8z"/>
  </svg>

  </StyledToothSetOutsideNum>

)

export {ToothSetOutsideNum};
