import React, { useEffect, useState, useRef, useCallback } from 'react';
import {  useSelector, useDispatch } from 'react-redux';
import { fetchMessageHistory } from "pubnub-redux";
import cn from 'classnames';
import { startCase, upperFirst } from 'lodash';
import {
  usePagination,
} from "../_helpers/usePagination";

//components
import { MessageListItem } from '../ChatPage/MessageTemplates/MessageTemplates';

//actions
import { actions as chatActions } from '../ChatPage/_redux/actions';

// selectors
import { getUUID, getIntlMessages } from '../App/_redux/selectors';
import {
  getConversationMessageDraft,
  getCurrentConversationId,
  getCurrentConversationMessages
} from '../ChatPage/_redux/selectors';

// styles
import {
  StyledReportChat
} from './_common/styles';
import {
  ScrollView,
  FlexColumn,
  StyledTextArea
} from '../_css/styles';
import { consoleLog } from '../_helpers/consoleLog';

/*const convertTimestampToTime = (ts) => {
  const date = new Date(parseInt(ts) / 10000);
  const minutes = date.getMinutes();
  return `${date.getHours()}:${minutes > 9 ? minutes : "0" + minutes}`;
};*/


const MessageList = () => {
  const dispatch = useDispatch();
  const [height, setHeight] = useState(0);
  const conversationId = useSelector(getCurrentConversationId);
  const intlMessages = useSelector(getIntlMessages);

  const handleScroll = (e) => {
    // eslint-disable-next-line no-unused-vars
    const scrollPosition = e.target.scrollTop;
    //if (scrollPosition !== 0) {
    //  console.log("")
    //}
  };

  const getNextPage = useCallback(
    async (tt, total, channel) => {
      const pageSize = 100;
      const action = await dispatch(
        fetchMessageHistory({
          count: pageSize,
          channel,
          start: tt || undefined,
          stringifiedTimeToken: true
        })
      );
      const response = action.payload.response;
      return {
        results: response.messages,
        pagination: `${response.startTimeToken}`,
        pagesRemain: response.messages.length === pageSize
      };
    },
    [dispatch]
  );

  const { containerRef, endRef } = usePagination(
    getNextPage,
    conversationId,
    null,
    null
  );

  const messages = useSelector(
    getCurrentConversationMessages
  );

  const el = containerRef.current;

  // when history is pulled, scroll down to compensate
  const newHeight = el && el.scrollHeight;
  useEffect(() => {
    if (height === 0 && newHeight) {
      setHeight(newHeight);
    } else if (newHeight && newHeight !== height) {
      if (el) {
        el.scrollTop += newHeight - height;
      }
      setHeight(newHeight);
    }
  }, [newHeight, height, el]);

  const scrollToBottom = useCallback(() => {
    return el && (el.scrollTop = el.scrollHeight - el.clientHeight);
  }, [el]);

  const hasReachedBottom = el
    ? el.scrollHeight - el.clientHeight === el.scrollTop
    : false;

  useEffect(() => {
    if (hasReachedBottom) {
      scrollToBottom();
    }
  }, [messages.length, hasReachedBottom, scrollToBottom]);

  if (messages.length > 0){
    return (
      <ScrollView ref={containerRef} onScroll={handleScroll}>
        <FlexColumn>
           {/* This moves the list of messages to the bottom, since there's a bug with flex-end scroll */}
           <FlexColumn></FlexColumn>
           <div ref={endRef} />
           { messages.map( (message ) => (
             <MessageListItem
               messageFragment={message}
               conversationId={conversationId}
               key={message.timetoken}
             />
           ))}
         </FlexColumn>
      </ScrollView>
    )
  } else {
    return (
      <ScrollView ref={containerRef} onScroll={handleScroll}>
        <FlexColumn>
           {/* This moves the list of messages to the bottom, since there's a bug with flex-end scroll */}
           <FlexColumn></FlexColumn>
           <div ref={endRef} />
           <div className={'noMessages'}>{upperFirst(intlMessages['requestDetailPage.details.chats.noMessages.description'])}</div>
         </FlexColumn>
      </ScrollView>
    )
  }



}

const newTextDraft = (draft, newText) => {
  if (draft.text=== newText) {
    return draft;
  }
  return {
    type: "text",
    senderId: draft.senderId,
    text: newText
  }
}

const isDraftModified = (message) => {
  switch (message.type) {
    case "text":
      return message.text !== "";
    default:
      consoleLog(`C3annot determine if message of type "${message.type}" has been modified.`)
  }
};

const autoExpand = (el) => {
  setTimeout(function () {
    el.style.cssText = "height:auto; padding:0";
    el.style.cssText = "height:" + (el.scrollHeight + 20) + "px";
  }, 0);
}


const TextMessageEditor = ({message, sendDraft, updateDraft}) => {
  const text = message.text;
  const textareaRef = useRef(document.createElement("textarea"));

  const textChanged = (e) => {
    updateDraft(newTextDraft(message, e.target.value))
  }

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !(e.shiftKey)) {
      const draft = newTextDraft(message, text);
      if (isDraftModified(draft)) {
        sendDraft(draft);
      }
      e.preventDefault();
    }
    autoExpand(e.target);
  }

  /*const emojiInserted = (messageWithEmoji) => {
    updateDraft(newTextDraft(message, messageWithEmoji));
    textareaRef.current.focus();
  };*/

  useEffect(() => {
    autoExpand(textareaRef.current);
  }, [textareaRef]);

  return (
    <div className={'textEditor'}>
      <div className={'textAreaWrapper'}>
        <StyledTextArea
          ref={textareaRef}
          rows={3}
          value={text}
          onChange={textChanged}
          onKeyPress={handleKeyPress}
          placeholder="Press Enter to send message..."
        ></StyledTextArea>
      </div>
    </div>
  )
}

const MessageEditor = ({message, sendDraft, updateDraft}) => {
  switch (message.type) {
    case "text":
      return (
        <div className={'messageEditor'}>
          <TextMessageEditor
            message={message}
            sendDraft={sendDraft}
            updateDraft={updateDraft}
            >
          </TextMessageEditor>
        </div>

      )
    default:
      consoleLog(`no editor available for draft message of type ${message.type}`);
  }
}

const MessageInput = () => {
  const conversationId = useSelector(getCurrentConversationId);
  const userId = useSelector(getUUID);
  const dispatch = useDispatch();
  const storedDraft = useSelector(getConversationMessageDraft);

  const defaultDraft = {
    type: "text",
    senderId: userId,
    text: ""
  }

  const message = storedDraft ? storedDraft : defaultDraft;
  const send = (appMessage) => {
    dispatch(chatActions.sendMessage(appMessage));
    dispatch(chatActions.discardMessageDraft(conversationId));
  }

  const update = (draft) => {
    dispatch(chatActions.updateMessageDraft(conversationId, draft));
  }

  return (
    <div className={'messageInput'}>
      <MessageEditor
        message={message}
        sendDraft={send}
        updateDraft={update}
        ></MessageEditor>
    </div>
  )
}

export const ReportChat = () => {
  const conversationId = useSelector(getCurrentConversationId);
  const intlMessages = useSelector(getIntlMessages);
  return (
    <StyledReportChat
      addCss={{
        height:'100%',
        width:"100%",
        position:["fixed", "static"],
        bg:"backgrounds.content",
        borderRight:"light"
      }}
      >
      <div className={cn('sectionTitle', 'chatTitle')}>
        {startCase(intlMessages['requestDetailPage.details.chats.title'])}
      </div>
      <MessageList key={'mlist'+ conversationId}/>
      <div className={'divider'} />
      <MessageInput />
    </StyledReportChat>
  )
}
