import { services } from './services';
import { constants } from './constants';
import { history } from '../../_helpers';

export const actions = {
  login,
  logout,
  getProfile,
  getChatInitial,
  updateObject,
};

function login(email, password, redirectURL) {
  return (dispatch) => {
    dispatch(request({ email }));

    services.login(email, password).then(
      (user) => {
        dispatch(success(user));
        history.push(redirectURL);
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request(user) {
    return { type: constants.LOGIN_REQUEST, user };
  }
  function success(user) {
    return { type: constants.LOGIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: constants.LOGIN_FAILURE, error };
  }
}

function getProfile() {
  return (dispatch) => {
    dispatch(request());
    services.getProfile().then(
      (resp) => {
        dispatch(success(resp));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: constants.USER_PROFILE_REQUEST };
  }
  function success(data) {
    return { type: constants.USER_PROFILE_SUCCESS, data };
  }
  function failure(error) {
    return { type: constants.USER_PROFILE_FAILURE, error };
  }
}

function getChatInitial() {
  return (dispatch) => {
    dispatch(request());
    services.getChatInitial().then(
      (resp) => {
        dispatch(success(resp));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: constants.USER_CHAT_INITIALIZATION_REQUEST };
  }
  function success(data) {
    return { type: constants.USER_CHAT_INITIALIZATION_SUCCESS, data };
  }
  function failure(error) {
    return { type: constants.USER_CHAT_INITIALIZATION_FAILURE, error };
  }
}

function updateObject(obj) {
  return (dispatch) => {
    dispatch(update(obj));
  };
  function update(obj) {
    return { type: constants.UPDATE_OBJECT, data: { key: obj.key, value: obj.value } };
  }
}

function logout() {
  services.logout();
  return { type: constants.LOGOUT };
}
