import { createSelector } from 'reselect';

const getPatientRequestsSlice = (state) => state.patientRequests;

export const getPatientRequestsLoading = createSelector([getPatientRequestsSlice], (state) => {
  return state.loading;
});

export const getPatientRequestsError = createSelector([getPatientRequestsSlice], (state) => {
  return state.error;
});

export const getPatientRequestsById = createSelector([getPatientRequestsSlice], (state) => {
  return state.requests;
});
