import { API_URLS } from '../../_config';
import axios from 'axios';
import { authHeader } from '../../_helpers';

const upload = (file, record, onUploadProgress, extraData = {}) => {
  let formData = new FormData();
  const requestUrl = API_URLS.uploadToRecord.url;
  const requestHeaders = {
    'Content-Type': 'multipart/form-data',
    ...authHeader(),
  };
  formData.append('file', file);
  formData.append('record', record);
  formData.append('extra_data', JSON.stringify(extraData));

  return axios.post(requestUrl, formData, {
    headers: requestHeaders,
    onUploadProgress,
  });
};

const uploadAvatar = (file, onUploadProgress) => {
  let formData = new FormData();
  const requestUrl = API_URLS.uploadAvatar.url;
  const requestHeaders = {
    'Content-Type': 'multipart/form-data',
    ...authHeader(),
  };
  formData.append('file', file);

  return axios.post(requestUrl, formData, {
    headers: requestHeaders,
    onUploadProgress,
  });
};

export const services = {
  upload,
  uploadAvatar,
};
