import React, { useEffect } from 'react';
import { useSelector, useDispatch} from 'react-redux';
import cn from 'classnames';
import { startCase, toUpper } from 'lodash';
import { Tag, TagLabel, Circle } from '@chakra-ui/react';

import { localeFormatDate } from '../_helpers';

// components
import { ReportToothChart } from '../ReportToothChart';
import { LoadingEllipsis } from '../_components/Loaders';
import { NoReportsIcon } from './_components/NoReportIcon';

// redux state
import { actions } from './_redux/actions';
import {
  getRequestDetails,
} from './_redux/selectors';

//actions
import { actions as toothChartActions } from '../ReportToothChart/_actions';

// selectors
import { getFilteredReportRegions, getChartCategory, getFilteredReportCharts  } from '../ReportToothChart/_selectors';
import { getIntlMessages, getIntlLocale } from '../App/_redux/selectors';
import { getReportModalState } from '../CreateReport/_redux/selectors'


// styles
import {
  StyledPatientReportPreview,
  StyledReportComments,
  StyledReportCommentItem,
} from './_common/styles';
import { StyledButton } from '../_css/styles';
import { StyledChartCategories, StyledCategoryItem } from '../CreateReport/styles';
import { useReportCategories } from 'src/Reports/api/getReportCategories';

const ReportGrade = ({grade}) => {
  const intlMessages = useSelector(getIntlMessages);
  let bgColor, tagColor;
  if (grade === 3){
    bgColor = '#E9F9F1';
    tagColor = 'positive.500';
  } else if (grade === 2){
    bgColor = '#FDF8E2';
    tagColor = 'neutral.500';
  } else if (grade === 1){
    bgColor = '#FFEDED';
    tagColor = 'negative.500';
  } else {
    return <></>
  }
  return (
    <Tag bg={bgColor}>
      <Circle size='8px' mr={2} bg={tagColor}></Circle>
      <TagLabel color={tagColor} lineHeight={0.8}>
        {toUpper(intlMessages[`config.report.grade.${grade}`])}
      </TagLabel>
    </Tag>
  )
}

const ReportCommentItem = ({chart}) => {
  const intlMessages = useSelector(getIntlMessages);

  return (
    <StyledReportCommentItem className={'reportCommentItem'}>
      <div className={'itemHeader'}>
        <div className={cn('category',chart.getReportType())}>{intlMessages[`requestDetailPage.reportChart.category.${chart.getReportType()}.label`]}</div>
        <div className={'regions'}>
          {(chart.getAllRegions().length > 0)
            ? chart.getAllRegions().map((elem) => (<span key={elem}>{elem.replace("t", "")}</span>))
            : <span>{toUpper(intlMessages[`requestDetailPage.reportChart.preview.noTeethSelected`])}</span>
          }
        </div>
      </div>
      <ReportGrade grade={chart.getGrade()} />
      <div className={cn('comment')}><span>{chart.getComment()}</span></div>
    </StyledReportCommentItem>
  )
}

export const ReportComments = () => {
  const regionCharts = useSelector(getFilteredReportCharts);
  return(
    <StyledReportComments className={'reportComments'}>
      {regionCharts.map((chart) => (
        <ReportCommentItem chart={chart} key={chart.id}/>
      ))}
    </StyledReportComments>
  )
}

const CategoryItem = ({value, selected}) => {
  const dispatch = useDispatch();
  const intlMessages = useSelector(getIntlMessages);

  const handleClick = () => {
    if (value == "ALL"){
      dispatch(toothChartActions.selectReportCategory(null))
    } else {
      dispatch(toothChartActions.selectReportCategory(value))
    }
  }

  return(
    <StyledCategoryItem className={cn(value, {'selected': selected})} onClick={handleClick} >
      {intlMessages[`requestDetailPage.reportChart.category.${value}.label`]}
    </StyledCategoryItem>
  )
}

export const ChartCategories = ({report_type}) => {
  const { data } = useReportCategories({report_type});
  const chartCategory = useSelector(getChartCategory);

  return (
    <StyledChartCategories>
      <CategoryItem
        value={'ALL'}
        label={'ALL'}
        selected={chartCategory == null}
        />
      { (data?.categories || []).map((elem) => (
        <CategoryItem
          key={elem.value}
          label={elem.label}
          value={elem.value}
          selected={chartCategory==elem.value}
        />
      ))}
    </StyledChartCategories>
  )
}

export const ChartSection = () => {
  const charting = useSelector(getFilteredReportRegions);

  return(
      <ReportToothChart
        charting={charting}
        width={"290"}
        height={"430"}
      />
  )
}

export const PatientReportPreview = (currentRequestId) => {
  const dispatch = useDispatch();
  const intlMessages = useSelector(getIntlMessages);
  const intlLocale = useSelector(getIntlLocale);
  const state = useSelector(getReportModalState);
  const requestDetails = useSelector(getRequestDetails);


  useEffect(()=>{
    return () => {}
  }, [])

  const handleOpenReport = (e) => {
    e.preventDefault();
    dispatch(actions.openReportModal());
  }

  const display = (currentState) => {
    if (currentState == null){
      return(
        <React.Fragment>
          <div className={'loadingSection'}>
            <LoadingEllipsis />
          </div>
        </React.Fragment>
      )
    }
    else if (currentState == 3){
      // show the charts
      return (
        <React.Fragment>
          <div className={'chartSection'}>
            <ChartSection />
            <ChartCategories />
          </div>
          <div className={'divider'}></div>
          <div className={'informationSection'}>
            <ReportComments />
          </div>
        </React.Fragment>
      )
    } else {
      return(
        <React.Fragment>
          <div className={'blankSection'}>
            <p>{intlMessages['requestDetailPage.details.report.noReport.createdOn'] + " "+ localeFormatDate(requestDetails, intlLocale, "l")}</p>
            <NoReportsIcon />
            <StyledButton onClick={handleOpenReport} >
              {intlMessages['requestDetailPage.details.report.noReport.description']}
            </StyledButton>
          </div>
        </React.Fragment>
      )
    }
  }

  return(
    <StyledPatientReportPreview key={currentRequestId}>
      <div className={cn('sectionTitle', 'reportTitle')}>
        {startCase(intlMessages['requestDetailPage.details.report.title'])}
      </div>
      {display(state)}
    </StyledPatientReportPreview>

  )

}
