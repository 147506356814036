import React from 'react';
import cn from 'classnames';
import { useSelector, useDispatch} from 'react-redux';

import { TimelineEventIcon } from './TimelineEventIcon';

// actions
import { actions } from '../_redux/actions';

// selectors
import { getIntlMessages } from '../../App/_redux/selectors';
import { getCurrentPage, getReportModalState } from '../_redux/selectors';

// styles
import {
  StyledTimelineEvent,
  StyledReportProgressTimeline
} from './styles';


const TimelineEvent = ({className, label, onClick}) => {
  return(
    <StyledTimelineEvent className={className} onClick={onClick}>
      <TimelineEventIcon
        />
      <div className={'timelineEventLabel'}>{label}</div>
    </StyledTimelineEvent>
  )
}

export const ReportProgressTimeline = () => {
  const dispatch = useDispatch();
  const currentPage = useSelector(getCurrentPage);
  const state = useSelector(getReportModalState);
  const intlMessages = useSelector(getIntlMessages);

  

  const onClick = (page) => {
    if (page != currentPage && page <= state){
      dispatch(actions.setPage(page));
    }
  }

  return(
    <StyledReportProgressTimeline>
      {/*<TimelineEvent
        color={(currentPage == 0) ? '#367FFF': '#273238'}
        className={cn(
            {'eventFinished': (state >= 0)},
            {'eventActive': currentPage == 0})}
        label={intlMessages['requestDetailPage.timeline.reviewscans.title']}
        onClick={() => onClick(0)}
        />*/}
      <TimelineEvent
        color={(currentPage == 1) ? '#367FFF' : (state >= 1) ? '273238' : '#D8D8D8'}
        className={cn(
            {'eventFinished': (state >= 1)},
            {'eventActive': currentPage == 1})}
        label={intlMessages['requestDetailPage.timeline.createreport.title']}
        onClick={() => onClick(1)}
        />
      <TimelineEvent
        color={(currentPage == 2) ? '#367FFF' : (state >= 2) ? '273238' : '#D8D8D8'}
        className={cn(
            {'eventFinished': (state >= 2)},
            {'eventActive': currentPage == 2})}
        label={intlMessages['requestDetailPage.timeline.review.title']}
        onClick={() => onClick(2)}
        />
      <TimelineEvent
        color={(currentPage == 3) ? '#367FFF' : (state >= 3) ? '273238' : '#D8D8D8'}
        className={cn(
            {'eventFinished': (state >= 3)},
            {'eventActive': currentPage == 3})}
        label={intlMessages['requestDetailPage.timeline.sent.title']}
        onClick={() => onClick(3)}
        />
    </StyledReportProgressTimeline>
  )
}
