import { create } from 'zustand';

export const useMapStore = create((set) => ({
    currentMapCenter: null,
    isDragging: false,
    clear: () => {
        set((state) => ({
            ...state,
            currentMapCenter: null,
            isDragging: false,
        }))
    },
    dragStarted: () => {
        set((state) => ({
            ...state,
            isDragging: true
        }))
    },
    updateMapCenter: ({lat, lng}) => {
        set((state) => ({
            ...state,
            currentMapCenter: {lat, lng}
        }))
    },
    setEditorInstance: (editorInstanceVal) => 
        set((state) => ({
            ...state,
            editorInstance: editorInstanceVal
        })),
}));