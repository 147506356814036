import { constants } from './constants';

const initState = {
  loading: false,
  error: '',
  requestList: [],
  byId: {},
  loadMoreOffset: 0,
  loadMore: false,
  loadingMore: false,
  errorMore: null,
  requestCount: 0,
  filterBy: null,
  queryParams: {},
};

export function requestList(state = initState, action) {
  switch (action.type) {
    case constants.GETREQUESTS_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case constants.GETREQUESTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        requestCount: action.data?.request_count,
        loadMore: action.data?.load_more,
        byId: action.data.byId,
        queryParams: action.data?.query_params,
      };
    case constants.GETREQUESTS_FAILURE:
      return {
        ...state,
        loading: false,
        loadMore: false,
        error: '',
      };
    case constants.GETREQUESTSMORE_REQUEST:
      return {
        ...state,
        loadingMore: true,
        errorMore: false,
      };
    case constants.GETREQUESTSMORE_SUCCESS:
      return {
        ...state,
        loadingMore: false,
        errorMore: false,
        requestCount: action.data?.request_count,
        loadMore: action.data?.load_more,
        byId: Object.assign({}, state.byId, action.data.byId),
        queryParams: action.data?.query_params,
      };
    case constants.GETREQUESTSMORE_FAILURE:
      return {
        ...state,
        loadingMore: false,
        errorMore: true,
      };
    case constants.UPDATE:
      return {
        ...state,
        ...action.data,
      };
    default:
      return state;
  }
}
