import { create } from 'zustand';

export const useSelectChartingStore = create((set) => ({
    charting: {},
    initializing: true,
    isSaving: false,
    isDirty: false,
    setCharting: (chartingVal) => 
        set((state) => ({
            ...state,
            charting: chartingVal
        })),
    setInitializing: (boolVal) => 
        set((state) => ({
            ...state,
            initializing: boolVal
        })),
    setIsDirty: (boolVal) => 
        set((state) => ({
            ...state,
            isSaving: boolVal
        })),
    setIsSaving: (boolVal) => 
        set((state) => ({
            ...state,
            isSaving: boolVal
        })),
    updateState: (val) => 
        set((state) => ({   
            ...state,
            ...val
        }))
}));