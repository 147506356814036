import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch} from 'react-redux';
import { 
    StyledPage, 
    StyledPageTitle, 
    StyledPatientHeader, 
    StyledPatientListItem, 
    StyledPatientListContainer, 
    StyledLoadingDiv, 
    StyledPatientList, 
    //StyledPatientSearchSection, 
    UnstyledItemLink } from './styles';
import { lowerCase, upperFirst } from 'lodash';
import styled from 'styled-components';
import { Box, Flex } from '@chakra-ui/react'
import { FormattedMessage, useIntl } from 'react-intl';
// redux state
import { actions } from './_redux/actions';
import { getPatientsList, getPatientsListLoading } from './_redux/selectors';
import { StyledButton, OverflowTextContainer, StyledInput } from 'src/_css/styles';
import { NewPatientFormModal } from './PatientsSearch';
import { localeFormatDate } from 'src/_helpers';
import { getIntlLocale } from 'src/App/_redux/selectors';
import { useAppConfig } from 'src/App/state/appConfig';


const PatientsListHeader = () => {
    return (
        <StyledPatientHeader>
            <div>
                <FormattedMessage
                    id="patientsListPage.patientlist.header.name"
                    defaultMessage="Patient Name"
                />
            </div>
            <div>
                <FormattedMessage 
                    id="patientsListPage.patientlist.header.parent"
                    defaultMessage="Related To"
                />
            </div>
            <div>
                <FormattedMessage 
                    id="patientsListPage.patientlist.header.birth_date"
                    defaultMessage="Birthdate"
                />
            </div>
            <div>
                <FormattedMessage 
                    id="patientsListPage.patientlist.header.gender"
                    defaultMessage="Gender"
                />
            </div>
            <div>
                <FormattedMessage 
                    id="patientsListPage.patientlist.header.email"
                    defaultMessage="Email Address"
                />
            </div>
            <div>
                <FormattedMessage 
                    id="patientsListPage.patientlist.header.phone"
                    defaultMessage="Contact Number"
                />
            </div>
        </StyledPatientHeader>
    )
}


const StyledListItemRelationship = styled.div`
    display: flex;

    a {
        margin-top: 0;
    }
`;

const ListItemRelationship = ({ relationship, parent }) => {
    // TODO format first and last name
    const { formatMessage } = useIntl();
    
    const related_to = parent 
        ? ' ' + formatMessage({
                id: 'format.fullName', 
                defaultMessage: '{givenName} {familyName}'
            },
            {
                givenName: parent.first_name, familyName: parent.last_name
            })
        : ''
        

    if (parent == null){
        return <div></div>
    }
    return (
        <StyledListItemRelationship>
            <div>{
                related_to 
                ? (<FormattedMessage
                    id='format.relationshipTo'
                    defaultMessage='{relationship} of {relatedTo}'
                    values={{
                        relationship: upperFirst(lowerCase(relationship)), 
                        relatedTo: related_to
                    }}
                /> )
                : '' }
            </div>
        </StyledListItemRelationship>
    )
}

const GenderText = ({gender=''}) => {
    const genderUpper = (gender || "").toUpperCase();
    if (genderUpper == 'MALE'){
        return <FormattedMessage id="settings.gender.MALE" defaultMessage='male' />
    } else if (genderUpper == 'FEMALE'){

        return <FormattedMessage id="settings.gender.FEMALE" defaultMessage='female' />
    } else {
        return <FormattedMessage id="settings.gender.OTHER" defaultMessage='other' />
    }
}

const PatientListItem = ({ patient }) => {
    const intlLocale = useSelector(getIntlLocale)
    return (
        <UnstyledItemLink to={`/patients/${patient.unique_id}`}>
            <StyledPatientListItem>
                <div>
                    <FormattedMessage
                        id="format.fullName"
                        defaultMessage="{givenName} {familyName}"
                        values={{
                            givenName: patient.first_name, familyName: patient.last_name
                        }}
                    />
                </div>
                <ListItemRelationship relationship={patient.relationship} parent={patient.parent} />
                <div><span>{localeFormatDate(patient.date_of_birth, intlLocale)}</span></div>
                <div><GenderText gender={patient?.gender} /></div>
                <OverflowTextContainer> 
                    {patient.email}
                </OverflowTextContainer>
                <div>{patient.phonenumber ? patient.phonenumber : ''}</div>
            </StyledPatientListItem>
        </UnstyledItemLink>
    )
}

const PatientsList = () => {
    const isLoading = useSelector(getPatientsListLoading);
    const patients = useSelector(getPatientsList);
    // TODO add pagination

    if (isLoading){
        return (
            <StyledPatientListContainer>
                <PatientsListHeader />
                <StyledLoadingDiv>
                    <FormattedMessage 
                        id={"popUp.title.loading"}
                        defaultMessage={"Loading"}
                    />
                </StyledLoadingDiv>
            </StyledPatientListContainer>
        )
    } else {
        return (
            <StyledPatientListContainer>
                <PatientsListHeader />
                <StyledPatientList>
                    {
                        (patients || []).map((patient) => {
                            return (
                                <PatientListItem key={patient.id} patient={patient} />
                            )
                        })
                    }
                </StyledPatientList>
            </StyledPatientListContainer>
        )
    }
}

export const PatientSearchSection = ({searchInput, setSearchInput, handleSearch}) => {
    const { formatMessage } = useIntl();

    const handleKeyPress = (e) => {
        if (e.key === 'Enter'){
            handleSearch();
        }
    }

    return (
        <Box>     
            <Flex mt={'30px'}>
                <StyledInput type="text" 
                    placeholder={formatMessage({id: "patientsListPage.patientSearch.button.search", defaultMessage: "Search"})}
                    value={searchInput} 
                    onKeyUp={handleKeyPress} 
                    onChange={(e) => setSearchInput(e.target.value)} 
                />
                <StyledButton 
                    style={{margin: 0, marginLeft: 14}} 
                    onClick={handleSearch} 
                >
                    <FormattedMessage 
                        id="patientsListPage.patientSearch.button.search"
                        defaultMessage="Search"
                    />
                </StyledButton>
            </Flex>
        </Box>
    )
} 

const PageHeader = () => {
    const dispatch = useDispatch();

    const openModal = () => {
        dispatch(actions.openNewPatientFormModal())
    }

    return (
        <>
            <NewPatientFormModal  />
            <Flex justify={'space-between'} align={'end'}>
                <StyledPageTitle>
                    <FormattedMessage
                        id="patientsListPage.title"
                        defaultMessage="Patients"
                    />    
                </StyledPageTitle>
                <Flex w={'full'} justify={'flex-end'}>
                    <StyledButton className={'outlined'} style={{margin: 0}} onClick={openModal} >
                        <FormattedMessage
                            id="patientsListPage.patientSearch.button.newPatient"
                            defaultMessage="+ Add New Patient"
                        />
                    </StyledButton>
                </Flex>
            </Flex>
        </>
    )
}

export const PatientListPage = () => {
    const dispatch = useDispatch();
    const clinic = useAppConfig(state => state.clinic?.id); // current selected clinic from app config
    let [offset, ] = useState(0)
    let [ searchInput, setSearchInput ] = useState('')

    useEffect(() => {
        dispatch(actions.fetchPatients({offset, clinic, q:searchInput}));
    }, [clinic])

    const handleSearch = () => {
        dispatch(actions.fetchPatients({offset, clinic, q:searchInput}));
    }

    return (
        <StyledPage>
            <PageHeader />
            <PatientSearchSection 
                searchInput={searchInput} 
                setSearchInput={setSearchInput} 
                handleSearch={handleSearch} 
            />
            <PatientsList />
        </StyledPage>
    )
}