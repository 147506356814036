import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import lodashValues from 'lodash/values';

import './Overlay.css';

const baseCssClassName = 'overlay';

const THEMES = {
  DARK: 'dark',
  LIGHT: 'light',
  TRANSPARENT: 'transparent',
};

const DEFAULT_THEME = THEMES.DARK;

const DEFAULT_SCROLL_PROPS = {
  horizontalEnabled: true,
  verticalEnabled: true,
};

/**
 * Creates base css class name.
 *
 * @param {Object} [props]
 * @param {string} [props.theme]
 *
 * @return {string}
 */
function buildBaseClassName(props = {}) {
  return `${baseCssClassName} ${baseCssClassName}__m-theme-${props.theme || DEFAULT_THEME}`;
}

/**
 * @param {OverlayScrollProps} [props]
 */
function buildScrollStyle(props = {}) {
  const style = {};

  if (props.horizontalEnabled && props.verticalEnabled) {
    style.overflow = 'auto';
  } else {
    if (props.horizontalEnabled) {
      style.overflowX = 'auto';
    } else if (props.verticalEnabled) {
      style.overflowY = 'auto';
    }
  }

  return style;
}

/**
 * @typedef {Object} OverlayProps
 *
 * @property {ReactElement} [children]
 * @property {string} [theme=dark]
 * @property {function} [onClick]
 * @property {boolean} [scrollable=true]
 * @property {OverlayScrollProps} [scrollableProps]
 */

/**
 * @typedef {Object} OverlayScrollProps
 *
 * @property {boolean} [horizontalEnabled=true]
 * @property {boolean} [verticalEnabled=true]
 */

/**
 * Component can be used to prevent to interact with any interface elements on the page
 * focusing attention of a user on the children component.
 */
class Overlay extends PureComponent {
  static propTypes = {
    children: PropTypes.node,
    theme: PropTypes.oneOf(lodashValues(THEMES)),
    onClick: PropTypes.func,
    scrollable: PropTypes.bool,

    scrollProps: PropTypes.object,
  };

  static defaultProps = {
    theme: DEFAULT_THEME,
    scrollable: true,

    scrollProps: DEFAULT_SCROLL_PROPS,
  };

  /**
   * @param {OverlayProps} props
   * @param {Object} context
   */
  constructor(props, context) {
    super(props, context);

    this._wasMouseDownInsideOverlay = false;
  }

  _handleMouseDown = (event) => {
    const target = event.currentTarget;
    const verticalScrollWidth = target.offsetWidth - target.clientWidth;
    const horizontalScrollHeight = target.offsetHeight - target.clientHeight;

    if (
      (verticalScrollWidth > 0 && event.clientX >= target.offsetWidth - verticalScrollWidth) ||
      (horizontalScrollHeight > 0 && event.clientY >= target.offsetHeight - horizontalScrollHeight)
    ) {
      return;
    }

    this._wasMouseDownInsideOverlay = true;
  };

  _handleMouseUp = () => {
    const { onClick } = this.props;

    if (this._wasMouseDownInsideOverlay) {
      if (onClick) {
        onClick();
      }
      this._wasMouseDownInsideOverlay = false;
    }
  };

  _handleMouseOver = () => {
    this._wasMouseDownInsideOverlay = false;
  };

  render() {
    return (
      <div
        className={buildBaseClassName(this.props)}
        style={this.props.scrollable ? buildScrollStyle(this.props.scrollProps) : {}}
        onMouseDown={this._handleMouseDown}
        onMouseUp={this._handleMouseUp}
        onMouseOver={this._handleMouseOver}
      >
        {this.props.children}
      </div>
    );
  }
}

Overlay.themes = THEMES;

export default Overlay;
