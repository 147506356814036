const PREFIX = 'PATIENTDETAILSPAGE_';
export const constants = {
  GETREQUEST_REQUEST: `${PREFIX}GETREQUEST_REQUEST`,
  GETREQUEST_SUCCESS: `${PREFIX}GETREQUEST_SUCCESS`,
  GETREQUEST_FAILURE: `${PREFIX}GETREQUEST_FAILURE`,

  UPDATE: `${PREFIX}UPDATE`,
  UPDATE_STATE: `${PREFIX}UPDATE_STATE`,
  UPDATE_STATE_OBJECT: `${PREFIX}UPDATE_STATE_OBJECT`,
  CLEAR_CURRENTREQUEST: `${PREFIX}CLEAR_CURRENTREQUEST`,
  UPDATE_SELECTED_MEDIA: `${PREFIX}UPDATE_SELECTED_MEDIA`,
};
