import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch} from 'react-redux';
import Modal from 'react-modal';
import { Scrollbars } from 'react-custom-scrollbars';

import { localeFormatDate } from '../_helpers';
import { RecordUpload } from '../_helpers/models';

// components
import { CloseIcon as CloseSvg } from '../_images/icons/CloseIcon';
import { RecordUploadMedia } from '../_components/RecordUploadMedia';
import { LoadingEllipsis } from '../_components/Loaders';

// redux state
import { actions } from './_redux/actions';

// actions
import { actions as requestPageActions } from '../PatientDetailsPage/_redux/actions';

// selectors
import { getIntlLocale } from '../App/_redux/selectors';
import {
  getCurrentMediaId,
  getDetailsRecordId,
  getCurrentRecordLoading,
  getCurrentRecordDetails,
  getCurrentRecordCreatedDate,
  getCurrentRecordUploads,
  getCurrentSelectedMediaItem,
  getRecordDetailModalOpen
} from '../PatientDetailsPage/_redux/selectors';
import { ModalCloseIcon } from '../_css/styles';

// styles
import {
  Wrapper,
  StyledRecordDetail,
  StyledMediaPreview,
  StyledMediaList,
  //StyledMediaItem,
  //StyledPreviewImage,
  BlankMediaItem,
  //BlankDiv
} from './styles';

const generalStyles = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
      zIndex: 10,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    content: {
      overflow: 'auto',
      WebkitOverflowScrolling: 'touch',
      width: 'fit-content',
      height:  'fit-content',
      outline: 'none',
      padding: '0',
      position: 'initial',
      zIndex: 10
    }
}

const PreviewImage = () => {
  const dispatch = useDispatch();
  const item = useSelector(getCurrentSelectedMediaItem);
  const [ upload, setUpload ] = useState(null)

  useEffect(()=>{
    return () => {
      dispatch(requestPageActions.setCurrentMedia(null))
    }
  }, [])

  useEffect(() => {
      setUpload(item)
  }, [item && item.id])

  if (item && upload){
    return (
      <RecordUploadMedia
        key={'RecordUploadMediaL' + item.id}
        upload={new RecordUpload(item)}
        imgWidth={640}
        imgHeight={480}
        zoom={false}
        imgBorderRadius={5}
        play={true}
      />
    )
  } else {
  return (
    <BlankMediaItem
      imgWidth={640}
      imgHeight={480}
      imgBorderRadius={5}
      >
    </BlankMediaItem>
  )
  }
}

const MediaItem = ({item, selected}) => {
  const dispatch = useDispatch();
  const handleClick = () => {
    if (item){
      dispatch(requestPageActions.setCurrentMedia(item.id));
    }
  }

  return(
    <RecordUploadMedia
      className={selected ? 'selectedMediaItem' : ''}
      key={'RecordUploadMedia130'+item.id}
      onClick={handleClick}
      selected={selected}
      zoom={false}
      upload={new RecordUpload(item)}
      imgWidth={130}
      imgHeight={130}
      imgBorderRadius={5}
      marginLeft={2}
      marginTop={2}
    />
  )
}

export const MediaList = () => {
  const recordUploads = useSelector(getCurrentRecordUploads)
  const currentSelectedId = useSelector(getCurrentMediaId);
  return (
      <StyledMediaList>
        <Scrollbars autoHide style={{height: '100%'}} >
        <div>
        {
          recordUploads.map((item) =>
            <MediaItem
              key={item.id}
              item={item}
              selected={item.id == currentSelectedId}
            />
          )
        }
        </div>
      </Scrollbars>
      </StyledMediaList>
  )
}

export const MediaPreview = () => {
  return (
    <StyledMediaPreview>
      <PreviewImage />
    </StyledMediaPreview>
  )
}

export const PatientRecordDetail = () => {
  const dispatch = useDispatch();
  const intlLocale = useSelector(getIntlLocale);

  const currentRecordLoading = useSelector(getCurrentRecordLoading);
  const recordUploads = useSelector(getCurrentRecordUploads);
  const currentRecordId = useSelector(getDetailsRecordId);
  const currentSelectedId = useSelector(getCurrentMediaId);
  const currentRecordDetails = useSelector(getCurrentRecordDetails);
  const currentRecordCreatedDate = useSelector(getCurrentRecordCreatedDate);

  useEffect(()=>{
    if (!currentRecordLoading && !currentRecordDetails){
      dispatch(requestPageActions.setCurrentMedia(null));
      dispatch(actions.fetchRecord(currentRecordId));
    }
  }, [currentRecordId])

  useEffect(()=>{
    // initialization. set first upload if no upload is selected
    //
    if (!currentRecordLoading && currentSelectedId == null && recordUploads.length > 0){
      dispatch(requestPageActions.setCurrentMedia(recordUploads[0].id));
    }
  }, [currentRecordLoading, currentSelectedId, recordUploads.length])

  return (
    <Wrapper>
      <h2>{'Report Uploads sent on'} {currentRecordCreatedDate && <span>{' ' + localeFormatDate(currentRecordCreatedDate, intlLocale, "lll")}</span>}</h2>
      <StyledRecordDetail>
        {currentRecordLoading && <div className={'loading'}><LoadingEllipsis /></div>}
        <MediaPreview />
        <MediaList />
      </StyledRecordDetail>
    </Wrapper>

  )
}

export const PatientRecordDetailModal = () => {
  const dispatch = useDispatch();
  const modalIsOpen = useSelector(getRecordDetailModalOpen);

  useEffect(() => {
    return () => {};
  }, [])

  const afterOpenModal = () => {
    /* all refs are set*/
  }

  const closeModal = () => {
    dispatch(requestPageActions.closeRecordDetailModal())
  }

  return(
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={generalStyles}
      shouldCloseOnOverlayClick={false}
      contentLabel="Record Details"
    >
        <div style={{position: 'relative', height: '100%'}}>
          <ModalCloseIcon onClick={closeModal} ><CloseSvg/ ></ModalCloseIcon>
          <PatientRecordDetail />
        </div>
      </Modal>
  )
}
