import { useQuery, useQueryClient } from 'react-query';
import { queryConfig } from 'src/_libs/react-query';

import { fetchRequest } from './services';


export const QUERY_KEY_NAME = 'RequestDetails';

const newQueryConfig = {
    ...queryConfig,
    queries: {
        ...queryConfig.queries,
        refetchOnMount: false
    }
}

export const getQueryData = (requestId) => {
    const queryClient = useQueryClient();
    return queryClient.getQueryData([QUERY_KEY_NAME, {requestId}]);
}

export const queryRequest = async ({ requestId }) => {
    return fetchRequest(requestId);
}

export const useRequestDetails = ({ requestId, config={} }) => {
    return useQuery({
        ...newQueryConfig,
        ...config,
        enabled: !!requestId,
        queryKey: [QUERY_KEY_NAME, {requestId}],
        queryFn: () => queryRequest({requestId}),
      });
}