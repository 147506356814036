
import React, { useEffect, useState } from 'react';

import { authHeader } from 'src/_helpers/auth-header';
import { 
  FormControl, 
  FormLabel, 
  Box, 
  FormErrorMessage ,
  Flex,
} from '@chakra-ui/react';
import { SearchFieldComponent } from 'src/_components/Forms/SearchFieldComponent';
import { FormattedMessage } from 'react-intl';
import { API_URLS } from 'src/_config';

import { useIntl } from 'react-intl';
import { createUserProfile } from '../api/services';

const formatOptionLabel = ({value, label, data, ...props}) => {
  if (props.__isNew__ == true){ // entering a value not from the original list
    if (value == -1){ // this is ValueContainer label. value after the option has been selected
      return(
        <Flex align="center">
          <Box fontFamily="Proxima Nova" mr={5}>{`OTHER | ${label}`}</Box>
        </Flex>
      )
    } else { // is not from the api call so this is the MenuOption label .
      return(
        <Flex align="center">
          <Box fontFamily="Proxima Nova" mr={5}>
            <FormattedMessage 
              id={'userForm.user_profile.no_options_message'}
              defaultMessage={'Create new profile: {value} (default OTHER)'}
              values={{value}}
            />
          </Box>
        </Flex>
      )
    }
  } else if (value?.length === 0 || value === '0' || value === 0){ 
    return(
      <Flex align="center">
        <Box fontFamily="Proxima Nova" mr={5}>{label}</Box>
      </Flex>
    )
  } else {
    return(
      <Flex align="center">
        <Flex direction="column">
          <Box fontFamily="Proxima Nova" mr={5}>
            {`${data?.relationship} | `} 
            <FormattedMessage 
                id={'format.fullName'}
                defaultMessage={'{givenName} {familyName}}'}
                values={{givenName: data?.first_name, familyName: data?.last_name}}
            />
            {data?.is_default && (<FormattedMessage id={'format.default'} defaultMessage={' [DEFAULT]'} />)}
            </Box>
        </Flex>
      </Flex>
    )
  }
}

export const UserProfileSearch = ({userId, label, input, onSelect, isInvalid, error, disabled=false, placeholder=null}) => {
  const {formatMessage} = useIntl();
  const [ currentValue, setCurrentValue ] = useState();

  useEffect(() => {
    setCurrentValue(input.value);
  } , [input.value])


  const onChange = async (val) => {
    if (val?.__isNew__ == true){ 
      try {
        const resp = await createUserProfile(userId, {first_name: val.label, relationship: "OTHER"}    )
        setCurrentValue({value: resp.id, label: val.label , data: resp});
        onSelect({ value: resp.id, label: val.label, data: resp});

      } catch (error) {
        setCurrentValue(null);
        onSelect(null);
      }
    } else {
      setCurrentValue(val);
      onSelect(val);
    }
  }

  //const onInputChange = (val) => {
  //}

  const handleOptionsLoading = (input) => {
    if (!input){
      return Promise.resolve({ options: []})
    }
    const requestOptions = {
      method: 'GET',
      headers: authHeader()
    };
    let url = API_URLS.searchAdminUserProfiles.url+`?q=${input}&user=${userId}`;
    return fetch(url, requestOptions)
      .then((response) => response.json())
      .then((json) => {
        const options = json.search_list.map((obj) => {
          return {value: obj.value,
                  label: obj.label,
                  data: obj,
          }
        })
        return [ ...options ];
      });
  }


  return (
    <>
      <FormControl id={input.name} isInvalid={isInvalid}>
        <FormLabel align="center" pb={1}>
          {label}
        </FormLabel>
        <SearchFieldComponent
          name={input.name}
          value = {currentValue}
          label={label}
          disabled={disabled || userId === null}
          isError={isInvalid}
          intl={formatMessage({id: 'userForm.user_profile.search', defaultMessage: 'Search User Profiles'})}
          placeholder={placeholder}
          onBlur={input.onBlur}
          formatOptionLabel={formatOptionLabel}
          createNewValue={null}
          loadOptions={handleOptionsLoading}
          onChange={onChange}
          canCreateNewValue={true}
        />
        <FormErrorMessage>{error ? error : ""}</FormErrorMessage>
      </FormControl>
    </>
  )

}