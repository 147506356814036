import styled, { keyframes } from 'styled-components';
import { motion } from 'framer-motion';
import { ToothMovementChart } from '../../RequestDetailPage/_components/ToothChart';

const fadeIn = keyframes`
  0%   { opacity: 0.3; }
  100% { opacity: 1; }
`;

export const FlexColumn = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 1 auto;
  padding-bottom: 30px;
`;

export const StyledChartSection = styled.div`
  text-align: center;
  display: flex;
`;

export const StyledToothMovementChart = styled(ToothMovementChart)`
  flex: 1;
`;

export const StyledSelectTeethLabel = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  align-items: center;
  margin: 15px 0;

  .selectMoreButton {
    display: flex;
    align-items: center;

    &:hover {
      cursor: pointer;
    }
    font-size: 14px;
    color: #7a8f99;
    svg {
      height: 10px;
      width: 10px;
      margin-left: 4px;
    }
  }
`;

export const StyledReportChartItem = styled.div`
  display: grid;
  display: -ms-grid;
  -ms-grid-gap: 4px 4px;
  grid-gap: 4px 4px;
  -ms-grid-columns: 20% 55% 18%;
  grid-template-columns: 20% 55% 18%;
  text-align: left;
  margin-bottom: 6px;
  line-height: 12px;
  padding: 5px 10px;

  background-color: ${(props) => (props.selected ? '#EDF3F6' : 'transparent')};

  > :hover {
    cursor: pointer;
  }
  .createdAt {
    text-align: right;
  }
`;

export const StyledReportChartFormHeader = styled.span`
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  margin: 0;
`;

export const StyledReportChart = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  margin-bottom: 8px;
`;

export const StyledReportChartHeader = styled.div`
  font-size: 16px;
  text-align: left;
  background-color: #405159;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
  justify-content: space-between;
  &:hover {
    cursor: pointer;
  }

  .titleHeader {
    span {
      &:not(:first-child) {
        &:before {
          content: ', ';
        }
      }
    }
  }
`;

export const StyledPatientRecordReportChartForm = styled.form`
  display: ${({ isSelected }) => (isSelected ? 'flex' : 'none')};
  padding: 10px 20px;
  margin-bottom: 20px;
  background: #f7f9fa;
  border-top: 1px solid #ededed;
  border-radius: 10px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  text-align: center;
  flex-direction: column;

  button[type='submit'] {
    box-shadow: 0px 2px 14px rgb(39 50 56 / 30%);
    margin-top: 30px;
    border-radius: 28px;
    padding: 10px 15px;
    font-size: 12px;
    margin: 0 auto;
    margin-top: 10px;
    width: fit-content;
    min-width: 150px;

    &:disabled {
      box-shadow: none;
    }
  }

  .delete {
    min-width: 150px;
  }
`;

export const StyledNoteSection = styled(motion.div)`
  visibility: ${(isSelected) => (isSelected ? 'visible' : 'hidden')};
  position: absolute;
  box-shadow: 0px -5px 10px 1px rgba(239, 239, 239, 0.5);
  border: 1px solid #ededed;
  top: 444px;
  background-color: #f7f9fa;
  width: calc(100% - 10px);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  .openFormButton {
    position: absolute;
    width: 100%;
    text-align: center;
    left: 0px;
    top: -20px;
    background: transparent;
    > div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &:hover {
      cursor: pointer;
    }
    font-size: 14px;
    color: #7a8f99;
    svg {
      height: 10px;
      width: 10px;
      margin-left: 3px;
    }
  }
`;

export const StyledReportForm = styled.div`
  position: relative;
  width: 400px;
  overflow-y: hidden;
  height: 545px;
  display: flex;
  justify-content: center;

  .ToothSet {
    padding-top: 5px;
    svg {
      height: 200px;
      width: 400px;
    }
  }
`;

export const StyledReportCategoryRegion = styled(motion.div)`
  background: #7a8f99;
  border-radius: 16px;
  font-family: Proxima Nova Semibold;
  font-size: 16px;
  color: #ffffff;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-item: center;
  text-align: center;
  white-space: nowrap;
  line-height: 31px;
  animation: ${fadeIn} 0.7s linear;
  .reportCategoryRegion {
  }
  &:hover {
    cursor: pointer;
  }
  &.positive {
    color: #d8d8d8;
    box-shadow: 0px 0px 0px 3px #43ac72 inset;
  }
  &.neutral {
    color: #d8d8d8;
    box-shadow: 0px 0px 0px 3px #e9b54d inset;
  }
  &.negative {
    color: #d8d8d8;
    box-shadow: 0px 0px 0px 3px #e8686d inset;
  }
  &.selected {
    &:hover {
      cursor: default;
      opacity: 0.7;
    }
    background: #367fff;
    color: #white;

    &.positive {
      box-shadow: 0px 0px 0px 2px #43ac72 inset;
    }
    &.neutral {
      box-shadow: 0px 0px 0px 2px #e9b54d inset;
    }
    &.negative {
      box-shadow: 0px 0px 0px 2px #e8686d inset;
    }
  }
  &:not(:last-child) {
    margin-right: 5px;
  }
`;

export const StyledBlankReportCategoryRegion = styled(motion.div)`
  display: flex;
  justify-content: flex-start;
  margin-right: 10px;
  align-items: center;
  padding: 10px 0;

  .icon {
    z-index: 1;
    position: absolute;
    background: #dadada;
    height: 30px;
    width: 30px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.noRegions {
      position: relative;
    }

    &.selected {
      background: #367fff;

      path {
        fill: #ffffff;
      }
    }

    svg {
      height: 15px;
      width: 15px;
    }
  }

  .label {
    background: #367fff;
    border-radius: 30px;
    font-size: 14px;
    font-family: Proxima Nova Semibold;
    font-size: 16px;
    color: #ffffff;
    height: 30px;
    padding-left: 37px;
    padding-right: 7px;
    display: flex;
    justify-content: center;
    align-item: center;
    text-align: center;
    white-space: nowrap;
    line-height: 31px;
    max-width: 150px;
    div {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
`;

export const StyledReportCategoryRegions = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 10px 15px;
  overflow: hidden;
`;

export const StyledReportCategory = styled(motion.div)`
    border-radius: ${({ selected }) => (selected ? '20px' : '0')};
    background: ${({ selected }) => (selected ? '#405159' : 'none')};
    padding:${({ selected }) => (selected ? '5px 12px 5px 3px' : '0')};
    color: white;
    display:flex;
    margin: 0 3px;
    font-size: 16px;
    &:hover{
      cursor:pointer;
    }
    .reportLabel{
      overflow:hidden;
      width: 20px;
      align-items: center;
      display: flex;
      justify-content: flex-start;
      span{
        white-space: nowrap;
      }
    }
    .reportLabel::before {
      flex-shrink:0;
      content:'';
      display:inline-block;
      width:13px;height:12px;
      border-radius:20px;
      vertical-align:middle;
      margin-left:7px;
      margin-right:12px;
    }
    .reportLabel.COLOR::before {background-color:#367FFF}
    .reportLabel.ORTHO::before {background-color:#00574F}
    .reportLabel.GUMS::before {background-color:#daff00}
    .reportLabel.HABITS::before {background-color:#daff00}
    .reportLabel.CARIES::before {background-color:#DC4E41}
    .reportLabel.CALCULUS::before {background-color:#ffa500}
    .reportLabel.ALL::before {background-color:#000000}
    .reportLabel:not(:last-child) {margin-bottom:5px;}
  }
`;

export const StyledReportCategories = styled.div`
  width: 400px;
  margin-top: 5px;

  .navArrow {
    display: flex;
    align-items: center;

    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }

    &.disabled {
      &:hover {
        cursor: default;
        opacity: 1;
        pointer-events: none;
      }
      color: #d8d8d8;
    }

    &:last-child {
      margin-left: 7px;
    }
  }

  .reportCategoriesContainer {
    display: flex;
    justify-content: center;
    ${StyledReportCategory} {
    }
  }

  .currentCategoryRegions {
    display: flex;
    padding: 5px 10px;

    span {
      background: #7a8f99;
      border-radius: 16px;
      font-family: Proxima Nova Semibold;
      font-size: 16px;
      color: #ffffff;
      padding: 0 10px;
      &:hover {
        cursor: pointer;
      }
      &.selected {
        &:hover {
          cursor: default;
        }
        opacity: 0.7;
      }
      &:not(:last-child) {
        margin-right: 5px;
      }
    }
  }
`;

export const StyledCreateReportSection = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  flex-grow: 1;
  height: 100%;

  .titleSection {
    color: #12171a;
    margin-top: 20px;
    text-align: center;

    .statusMessage {
      font-style: italic;
      color: gray;
    }
  }

  .actionsSection {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .contentSection {
    padding-top: 30px;
    display: flex;

    .createReportSection {
    }

    .createReportStatusMessage {
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        margin-top: 10px;
      }
    }

    .reportFormSection {
      margin-left: 15px;
      position: relative;
    }
    .createReportFormAction {
      display: flex;
      justify-content: flex-end;

      button {
        margin-right: 5px;
        margin-top: 20px;
      }
    }
  }
`;
