import React from 'react';
import PropTypes from 'prop-types';
import lodashValues from 'lodash/values';

const baseCssClassName = 'popup-dialog-header';
const titleCssClassName = `${baseCssClassName}__title`;
const buttonsCssClassName = `${baseCssClassName}__buttons`;
const buttonContainerCssClassName = `${baseCssClassName}__button-container`;
const closeButtonCssClassName = `${baseCssClassName}__close-button`;
const iconCssClassName = `${baseCssClassName}__icon`;

function getHeaderButtonContainer(content, props) {
  return (
    <div {...props} className={buttonContainerCssClassName}>
      {content}
    </div>
  );
}

function getHeaderCloseButton({ popup: { close } }) {
  return getHeaderButtonContainer(
    <div key={'close_button'} className={closeButtonCssClassName} onClick={close}>
      X
    </div>,
    { key: 'close_button' }
  );
}

const ICONS = {
  SUCCESS: 'success',
  ERROR: 'error',
  LIST: 'list',
};

/**
 * @typedef {Object} PopupDialogHeaderProps
 *
 * @property {string} title
 * @property {string} icon
 * @property {function(PopupInterface):Array<ReactElement>} [buttons]
 * @property {PopupDialogInterface} [dialogInterface]
 */

const propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.oneOf(lodashValues(ICONS)),

  buttons: PropTypes.oneOfType([PropTypes.func, PropTypes.arrayOf(PropTypes.node)]),

  dialogInterface: PropTypes.object,
};

/**
 * @param {PopupDialogHeaderProps} props
 * @returns {ReactElement}
 */
function PopupDialogHeader(props) {
  const icon = props.icon ? (
    <div className={`${iconCssClassName} ${iconCssClassName}__m-${props.icon}`} />
  ) : null;

  return (
    <div className={baseCssClassName}>
      {icon}
      <div className={titleCssClassName} title={props.title}>
        {props.title}
      </div>
      <div className={buttonsCssClassName}>
        {typeof props.buttons === 'function' ? props.buttons(props.dialogInterface) : props.buttons}
      </div>
    </div>
  );
}

PopupDialogHeader.icons = ICONS;
PopupDialogHeader.propTypes = propTypes;

export default PopupDialogHeader;

export { getHeaderButtonContainer, getHeaderCloseButton };
