import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { 
    Flex, 
    Button, 
    Heading,
    Modal, 
    ModalOverlay, 
    ModalHeader, 
    ModalContent, 
    ModalBody,
    ModalCloseButton
} from '@chakra-ui/react';
// actions
import { updateIntl } from 'react-intl-redux';

// selectors
import { getIntlMessages, getIntlLocale } from '../App/_redux/selectors';
import { getIntlMessagesWithDefault } from 'src/_helpers';
import { LANGUAGE_LIST } from 'src/_config';

export const SwitchLanguageModal = ({isOpen, onClose}) => {
  //const modalName = 'switchLanguage'
  const dispatch = useDispatch();
  const intlMessages = useSelector(getIntlMessages);
  const intlLocale = useSelector(getIntlLocale)
    
  //let currLanguage = localStorage.getItem('language')

  const handleClick = (lang) => {
    // all variables involving language switched
    localStorage.setItem("language", lang);
    if (window.ChannelIO){
      window.ChannelIO('updateUser', {language: lang});
    }
    dispatch(updateIntl({locale: lang, messages: getIntlMessagesWithDefault(lang)}));
    onClose()
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent
            w={['500px']}
            py={['20px']}
        >
            <ModalHeader>
                <ModalCloseButton />
                <Flex w={['full']} justify={['center']} align={['center']}>
                    <Heading
                        fontFamily={'Proxima Nova Semibold'}
                        fontSize={['18px']}
                        color={['#000000']}
                    >
                        {intlMessages['settingsPage.switchLanguage.title']}
                    </Heading>
                </Flex>
            </ModalHeader>
            <ModalBody>
                <Flex
                    direction={['column']}
                    align={['center']}
                    justify={['center']}
                    px={['40px']}
                    pb={['40px']}
                    borderRadius={['5px']}
                >
                    <Flex
                        direction={['column']}
                        w={['full']}
                    >
                        {LANGUAGE_LIST.map(item => (
                            <Button key={item.key} my={['15px']} isDisabled={intlLocale == item.key}  onClick={() => handleClick(item.key)}>
                                {intlMessages[`nontranslate.settings.languages.${item.key}.label`]}
                            </Button>
                        ))}
                    </Flex>
                </Flex>
            </ModalBody>
        </ModalContent>
    </Modal>
  )

}
