import styled from 'styled-components';

export const ScrollView = styled.div`
  height: 100%;
  width: 100%;
  ${(props) => props.addCss}
`;
export const StyledSingleStep = styled.div`
  width: 13px;
  height: 12px;
  background: #d8d8d8;
  margin: 5px;
  border-radius: 20px;
  cursor: pointer;
  ${(props) => props.addCSS}
`;

export const StyledPrevStep = styled.div`
  cursor: pointer;
  margin: 5px;
  ${(props) => props.addCSS}
`;

export const StyledNextStep = styled.div`
  cursor: pointer;
  margin: 5px;
  ${(props) => props.addCSS}
`;

export const StyledStepsSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0 20px;
`;

export const StyledVideoFrame = styled.div`
  margin-left: 5px;
  img {
    width: 80px;
    &:hover {
      cursor: pointer;
    }
  }
`;
export const StyledVideoFrameList = styled.div`
  display: flex;
  margin: 15px 0;
  div:first-child {
    margin-left: 0;
  }
`;

export const StyledRegionTopOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  margin: 8px;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 40px;
  padding: 5px 10px;
  z-index: 3;
`;

export const StyledVideoView = styled.div`
  .videoPlayerContainer {
    position: relative;
    background-color: #f7f9fa;

    canvas {
      top: 0;
      left: 0;
      position: absolute;
      pointer-events: none;
      
      visibility: hidden;

      &.DETECTIONON {
        visibility: visible;
      }
    }
  }
  .react-player__preview {
    min-height: unset;
  }

  video::-internal-media-controls-overlay-cast-button {
    display: none;
  }
`;

export const StyledPhotoView = styled.div`
  background-color: #f7f9fa;
`;

export const StyledMediaView = styled.div``;

export const StyledMediaSectionTitle = styled.div`
  margin-bottom: 20px;
  margin-top: 20px;
  text-align: center;
`;
export const StyledMediaSection = styled.div`
  overflow-x: hidden;
  width: 640px;
  min-height: 650px;
  .carousel-status {
    font-size: 12px;
  }
`;

export const ToothChartStyles = styled.div`
  .ToothSet {
    display: flex;
    flex-direction: column;

    &__UPPER {
      #toothLow {
        pointer-events: none;
        stroke: lightgray;

        line.cls-2 {
          stroke: lightgray;
        }

        .tooth {
          stroke: lightgray;
        }
      }
    }

    &__LOWER {
      #toothUp {
        pointer-events: none;
        stroke: lightgray;

        line.cls-2 {
          stroke: lightgray;
        }

        .tooth {
          stroke: lightgray;
        }
      }
    }
  }

  .ToothMovementChart {
    &__loadingOverlay {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: gray;
      border-radius: 100px;
      opacity: 0.2;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__heading {
      margin-bottom: 20px;
      margin-top: 20px;
      text-align: center;

      span {
        margin-left: 3px;
        color: gray;
      }
    }

    &__charting {
      position: relative;
    }

    &__buttons {
      margin-bottom: 20px;
      margin-top: 20px;

      button {
        margin-top: 10px;
        padding: 10px 40px;
        background-color: black;
        text-align: center;
        border-radius: 100px;
        color: white;
        letter-spacing: 1.5px;
        font-family: 'Proxima Nova', Georgia, sans-serif;
      }
    }

    &__HoverTip {
      &__item {
      }
    }
    &__HoverMenu {
      &__title {
        text-align: center;
        font-size: 15px;
        font-weight: 700;
        background-color: darkslategrey;
        color: white;
      }
      &__item {
        &.selected {
          background-color: bisque;
        }
        &:hover {
          cursor: pointer;
          background-color: lightgray;
        }
      }
    }
  }
  g {
    &.move {
      .tooth {
        stroke: slateblue;
        fill: slateblue;
      }
      .tnum {
        fill: white;
      }
    }

    &:hover {
      cursor: pointer;
      .tooth {
        fill: lightgray !important;
      }
    }

    &.history {
      .tooth {
        stroke: slateblue;
        fill: white;
      }
    }

    &.missing {
      .tooth {
        stroke: lightgray;
        fill: white;
      }

      .tnum {
        fill: lightgray;
      }
    }

    &.implant {
      .tooth {
        stroke: lightslategray;
        fill: lightslategray;
      }

      .tnum {
        fill: white;
      }
    }

    &.crown {
      .tooth {
        stroke: lightslategray;
        fill: lightslategray;
      }

      .tnum {
        fill: white;
      }
    }

    &.bridge {
      .tooth {
        stroke: lightslategray;
        fill: lightslategray;
      }

      .tnum {
        fill: white;
      }
    }

    &.inlay {
      .tooth {
        stroke: lightslategray;
        fill: lightslategray;
      }

      .tnum {
        fill: white;
      }
    }

    &.onlay {
      .tooth {
        stroke: lightslategray;
        fill: lightslategray;
      }

      .tnum {
        fill: white;
      }
    }
  }

  .tooth {
    stroke: black;
    fill: white;
  }

  line {
    stroke: black;
  }
`;
