import React from 'react';
import PropTypes from 'prop-types';

import lodashValues from 'lodash/values';
import classnames from 'classnames';

import Button from '../Button/Button';

const baseCssClassName = 'popup-dialog-footer';

const AVAILABLE_ALIGNMENT = {
  LEFT: 'left',
  RIGHT: 'right',
  CENTER: 'center',
};

function getFooterCloseButton({ popup: { close } }, props = {}) {
  return (
    <Button
      key={'close_button'}
      onClick={close}
      theme={Button.AVAILABLE_THEMES.PRIMARY}
      size={Button.AVAILABLE_SIZES.SMALL}
      title={'Close'}
      {...props}
    >
      {props.text || 'Close'}
    </Button>
  );
}

/**
 * @typedef {Object} PopupDialogFooterProps
 *
 * @property {string} buttonsAlignment
 * @property {function(PopupInterface):Array<ReactElement>} [buttons]
 * @property {PopupDialogInterface} [dialogInterface]
 */

const propTypes = {
  buttonsAlignment: PropTypes.oneOf(lodashValues(AVAILABLE_ALIGNMENT)),

  buttons: PropTypes.oneOfType([PropTypes.func, PropTypes.arrayOf(PropTypes.node)]),

  dialogInterface: PropTypes.object,
};

const defaultProps = {
  buttonsAlignment: AVAILABLE_ALIGNMENT.RIGHT,
};

/**
 * @param {PopupDialogFooterProps} props
 * @returns {ReactElement}
 */
function PopupDialogFooter(props) {
  const buttons =
    typeof props.buttons === 'function' ? props.buttons(props.dialogInterface) : props.buttons;

  if (!buttons) {
    return null;
  }

  return (
    <div
      className={classnames([
        baseCssClassName,
        props.buttonsAlignment && `${baseCssClassName}__m-alignment-${props.buttonsAlignment}`,
      ])}
    >
      {buttons}
    </div>
  );
}

PopupDialogFooter.alignment = AVAILABLE_ALIGNMENT;

PopupDialogFooter.propTypes = propTypes;
PopupDialogFooter.defaultProps = defaultProps;

export default PopupDialogFooter;

export { getFooterCloseButton };
