import { constants } from './constants';
const initState = {
  loading: {},
  error: {},
  createLoading: {},
  createError: {},
  reports: {},
  reportCharts: {},
};

export function patientReports(state = initState, action) {
  switch (action.type) {
    case constants.FETCHREPORT_REQUEST:
      return {
        ...state,
        loading: Object.assign({}, state.loading, { [action.data.id]: true }),
        error: Object.assign({}, state.error, { [action.data.id]: null }),
      };
    case constants.FETCHREPORT_SUCCESS:
      return {
        ...state,
        loading: Object.assign({}, state.loading, { [action.data.id]: false }),
        error: Object.assign({}, state.error, { [action.data.id]: null }),
        reports: Object.assign({}, state.reports, { [action.data.id]: action.data.report }),
        reportCharts: Object.assign({}, state.reportCharts, {
          [action.data.id]: action.data.reportCharts,
        }),
      };
    case constants.FETCHREPORT_FAILURE:
      return {
        ...state,
        loading: Object.assign({}, state.loading, { [action.data.id]: false }),
        error: Object.assign({}, state.error, { [action.data.id]: action.data.error }),
      };
    case constants.UPDATEREPORT_REQUEST:
      return {
        ...state,
        reportLoading: true,
        reportError: '',
      };
    case constants.UPDATEREPORT_SUCCESS:
      return {
        ...state,
        reportLoading: false,
        reportError: '',
        requestDetails: {
          ...state.requestDetails,
          request_reports: [action.data],
        },
      };
    case constants.UPDATEREPORT_FAILURE:
      return {
        ...state,
        reportLoading: false,
        reportError: '',
      };
    case constants.CREATEREPORT_REQUEST:
      return {
        ...state,
        createLoading: Object.assign({}, state.loading, { [action.data.id]: true }),
        createError: Object.assign({}, state.error, { [action.data.id]: null }),
      };
    case constants.CREATEREPORT_SUCCESS:
      return {
        ...state,
        createLoading: Object.assign({}, state.loading, { [action.data.id]: false }),
        createError: Object.assign({}, state.error, { [action.data.id]: null }),
        reports: Object.assign({}, state.reports, {
          [action.data.report.unique_id]: action.data.report,
        }),
      };
    case constants.CREATEREPORT_FAILURE:
      return {
        ...state,
        createLoading: Object.assign({}, state.loading, { [action.data.id]: false }),
        createError: Object.assign({}, state.error, { [action.data.id]: action.data.error }),
      };
    case constants.ADD_REPORTCHART:
      return {
        ...state,
        reportCharts: {
          ...state.reportCharts,
          [action.data.reportId]: [
            ...(state.reportCharts[action.data.reportId] || []),
            action.data.reportChart,
          ],
        },
      };
    case constants.UPDATE_REPORTCHART:
      return {
        ...state,
        reportCharts: {
          ...state.reportCharts,
          [action.data.reportId]: [
            // previous chargs
            ...(state.reportCharts[action.data.reportId] || []).map((item) => {
              if (item.getId() == action.data.reportChart.getId()) {
                return Object.assign({}, item, action.data.reportChart);
              }
              return item;
            }),
          ],
        },
      };
    case constants.REMOVE_REPORTCHART:
      return {
        ...state,
        reportCharts: {
          ...state.reportCharts,
          [action.data.reportId]: (state.reportCharts[action.data.reportId] || []).filter(
            (item) => item.getId() != action.data.reportChartId
          ),
        },
      };
    case constants.UPDATE:
      return {
        ...state,
        ...action.data,
      };
    case constants.UPDATE_REPORT:
      return {
        ...state,
        reports: {
          ...state.reports,
          [action.data.id]: Object.assign({}, state.reports[action.data.id], action.data.report),
        },
      };
    default:
      return state;
  }
}
