import React from 'react';
export const PaperclipIcon = ({ width = 17 }) => {
  return (
    <svg
      width={width}
      height={Math.round(width * (20 / 17))}
      viewBox="0 0 17 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.6093 1.39076C13.7549 -0.463586 10.738 -0.463586 8.88365 1.39076L1.80235 8.47203C1.58381 8.69057 1.58381 9.04461 1.80235 9.26316C2.02089 9.4817 2.37493 9.4817 2.59348 9.26316L9.67475 2.18189C11.0493 0.808365 13.4435 0.808365 14.8181 2.18189C16.2359 3.59967 16.2359 5.90695 14.8181 7.32473L4.93006 17.3241C4.0843 18.1699 2.61078 18.1699 1.76506 17.3241C0.892532 16.4516 0.892532 15.0322 1.76506 14.1596L11.2576 4.55524C11.5744 4.23837 12.1273 4.23837 12.4442 4.55524C12.7715 4.88247 12.7715 5.4152 12.4442 5.74247L3.34784 14.9507C3.15916 15.1394 3.15916 15.5531 3.34784 15.7419C3.56638 15.9604 3.92043 15.9604 4.13897 15.7419L13.2353 6.53356C13.9992 5.76973 13.9992 4.5279 13.2353 3.76411C12.4956 3.02436 11.2051 3.02544 10.4664 3.76411L0.973931 13.3685C-0.324644 14.667 -0.324644 16.8166 0.973931 18.1152C1.60827 18.7495 2.45126 19.0912 3.3478 19.0912C4.24434 19.0912 5.08741 18.7495 5.72119 18.1152L15.6092 8.11582C17.4636 6.26148 17.4636 3.24507 15.6093 1.39076Z"
        fill="#4D5666"
      />
    </svg>
  );
};
