import React from 'react';
import {
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, Center, Button
} from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';


export const RequestCreatedDialog = ({ isOpen, onClose, name, requestType }) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered closeOnOverlayClick={false}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    <Center>
                        <FormattedMessage 
                            id={'requestCreatedDialog.title'}
                            defaultMessage='Success!'
                        />
                    </Center>
                </ModalHeader>
                <ModalBody pt={'30px'} pb={'20px'}>
                        <FormattedMessage 
                            id={'requestCreatedDialog.description'}
                            defaultMessage='User Request was created successfully!'
                        /><br /><br />
                        <FormattedMessage 
                            id={'requestCreatedDialog.request.type'}
                            defaultMessage='Type: {type}'
                            values={{type: requestType }}
                        /><br />
                        <FormattedMessage 
                            id={'requestCreatedDialog.request.name'}
                            defaultMessage='Name: {name}'
                            values={{name: name }}
                        />
                </ModalBody>
                <ModalFooter pb={'20px'}>
                    <Button colorScheme="red" mr={3} onClick={onClose}>
                        <FormattedMessage 
                            id={'requestCreatedDialog.close'}
                            defaultMessage={'Close'}
                        />
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
