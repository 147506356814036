import React from 'react';
import { Icon } from '@chakra-ui/react';

const PasswordHideIcon = (props) => {
  const iconWidth = props?.width || 17;
  const iconHeight = props?.height || 12;
  return (
    <Icon 
      viewBox="0 0 17 12" 
      fill={'none'}
      {...props}
      width={`${iconWidth}px`}
      height={`${iconHeight}px`}
    >
        <path d="M13.9446 0.495868C13.9446 0.347107 13.8938 0.247934 13.792 0.14876C13.6902 0.0495866 13.5884 0 13.4357 0C13.283 0 13.1813 0.0495866 13.0795 0.14876L11.7054 1.4876C10.6875 0.991736 9.51696 0.694215 8.14286 0.545455C5.59821 0.793388 3.5625 1.68595 2.1375 3.27273C0.7125 4.8595 0 5.75207 0 6C0 6.24793 0.763393 7.1405 2.1375 8.72727C2.49375 9.12397 2.85 9.47107 3.30804 9.7686L1.93393 11.157C1.83214 11.2562 1.78125 11.3554 1.78125 11.5041C1.78125 11.6529 1.83214 11.7521 1.93393 11.8512C2.03571 11.9504 2.1375 12 2.29018 12C2.44286 12 2.54464 11.9504 2.64643 11.8512L13.8429 0.842975C13.8938 0.743802 13.9446 0.644628 13.9446 0.495868ZM3.66429 8.67769C2.44286 7.58678 1.62857 6.69421 1.22143 6C1.67946 5.2562 2.49375 4.36364 3.66429 3.32231C4.83482 2.28099 6.36161 1.63636 8.14286 1.53719C9.16072 1.58678 10.1277 1.83471 10.942 2.2314L10.0768 3.07438C9.51696 2.72727 8.90625 2.52893 8.14286 2.52893C7.125 2.52893 6.31071 2.87603 5.59821 3.52066C4.88571 4.16529 4.58036 5.00826 4.58036 6C4.58036 6.7438 4.78393 7.38843 5.14018 7.93388L4.07143 9.02479C3.91875 8.92562 3.76607 8.77686 3.66429 8.67769ZM5.90357 7.19008C5.7 6.84298 5.59821 6.44628 5.59821 6C5.59821 5.30579 5.85268 4.71074 6.36161 4.26446C6.87054 3.81818 7.43036 3.52066 8.14286 3.52066C8.60089 3.52066 9.00804 3.61983 9.31339 3.81818L5.90357 7.19008ZM14.1482 3.27273C13.9446 3.02479 13.6902 2.82645 13.4866 2.6281L12.7232 3.3719C13.8937 4.41322 14.708 5.30579 15.1152 6C14.6571 6.7438 13.8428 7.63636 12.6723 8.67769C11.5018 9.71901 9.92409 10.3636 8.14284 10.4628C7.32856 10.4132 6.56516 10.2645 5.90356 10.0165L5.14016 10.7603C6.05623 11.1074 7.0232 11.3554 8.14284 11.4545C10.6875 11.2066 12.7232 10.314 14.1482 8.72727C15.5732 7.1405 16.2857 6.24793 16.2857 6C16.2857 5.75207 15.5223 4.8595 14.1482 3.27273Z" fill="#4C5666"/>
        <path d="M8.32657 8.37894C8.11665 8.37894 7.90674 8.32646 7.69683 8.27398L6.85718 9.11363C7.32948 9.32355 7.80178 9.4285 8.32657 9.4285C9.37613 9.4285 10.2158 9.06115 10.9505 8.37894C11.6327 7.69672 12 6.80459 12 5.75503C12 5.23025 11.8951 4.70547 11.6852 4.28564L10.8455 5.12529C10.898 5.33521 10.9505 5.54512 10.9505 5.75503C10.9505 6.48973 10.6881 7.11946 10.1633 7.59177C9.63852 8.06407 9.06126 8.37894 8.32657 8.37894Z" fill="#4C5666"/>
    </Icon>
  );
};

export default PasswordHideIcon;