import { constants } from './constants';

const initState = {
  chatList: null,
  chatListLoading: false,
  chatListError: false,
  currentConversationId: null,
  messageDraftById: {},
  messageActionsById: {},
};

export function chatsList(state = initState, action) {
  switch (action.type) {
    case constants.CHAT_LIST_SELECT_CONVERSATION:
      return {
        ...state,
        currentConversationId: action.data,
      };
    case constants.CHAT_LIST_LOAD_REQUEST:
      return {
        ...state,
        chatsListLoading: true,
      };
    case constants.CHAT_LIST_LOAD_SUCCESS:
      return {
        ...state,
        chatsList: action.data,
        chatsListLoading: false,
        chatsListError: false,
      };
    case constants.CHAT_LIST_LOAD_FAILURE:
      return {
        ...state,
        chatsListLoading: false,
        chatsListError: action.error,
      };
    case constants.MESSAGE_DRAFT_UPDATED:
      return {
        ...state,
        messageDraftById: {
          ...state.messageDraftById,
          [action.data.conversationId]: action.data.draft,
        },
      };
    case constants.MESSAGE_DRAFT_DISCARDED:
      // eslint-disable-next-line no-case-declarations
      const {
        // eslint-disable-next-line no-unused-vars
        [action.data.conversationId]: discard,
        ...messageDraftById
      } = state.messageDraftById;
      return {
        ...state,
        messageDraftById,
      };
    case constants.UPDATE_CHANNEL_MESSAGE_ACTIONS:
      return {
        ...state,
        messageActionsById: {
          ...state.messageActionsById,
          [action.data.channel]: [
            ...(state.messageActionsById[action.data.channel] || []),
            ...action.data.messageActions,
          ],
        },
      };
    default:
      return state;
  }
}
