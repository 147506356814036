import { constants } from './_constants';

const initState = {
  loading: false,
  error: false,
  currentCategory: null,
  currentChartId: null,
  commentFormIsOpen: false,
  currentRegions: {},
  chartsByRegion: {},
};

export function reportChartForm(state = initState, action) {
  switch (action.type) {
    case constants.UPDATE:
      return {
        ...state,
        ...action.data,
      };
    case constants.UPDATECURRENTREGIONS:
      return {
        ...state,
        currentRegions: action.data,
      };
    case constants.REMOVE_REPORTCHART:
      return {
        ...state,
        currentChartId: -1,
      };
    default:
      return state;
  }
}
