<svg width="109" height="109" viewBox="0 0 109 109" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M44.3303 49.3029L60.2589 60.6805L99.1955 32.8687" stroke="#C7D3D9" strokeWidth="5"/>
<path d="M107.857 43.3966C110.461 55.91 108.584 68.9406 102.554 80.2105C96.5247 91.4803 86.7258 100.272 74.8707 105.05C63.0157 109.827 49.8586 110.286 37.6996 106.346C25.5405 102.406 15.1531 94.3175 8.35308 83.495C1.55309 72.6725 -1.22684 59.8043 0.499266 47.1399C2.22537 34.4755 8.34769 22.8207 17.796 14.2127C27.2443 5.60479 39.4174 0.591453 52.1874 0.0490887C64.9574 -0.493276 77.5117 3.46984 87.6559 11.2456L84.2386 15.7037C75.14 8.72934 63.8795 5.17469 52.4257 5.66115C40.9719 6.14762 30.0534 10.6443 21.5789 18.365C13.1044 26.0857 7.61313 36.5394 6.06493 47.8985C4.51673 59.2576 7.01014 70.7996 13.1093 80.5066C19.2084 90.2137 28.5253 97.4683 39.4311 101.002C50.337 104.536 62.138 104.125 72.7712 99.8398C83.4044 95.5548 92.1933 87.6689 97.6016 77.5606C103.01 67.4523 104.693 55.7647 102.358 44.541L107.857 43.3966Z" fill="#C7D3D9"/>
</svg>
import React from 'react';
import { Icon } from '@chakra-ui/react';

const ReportSentIcon = (props) => {
  const iconWidth = props?.width || 110;
  const iconHeight = props?.height || 110;
  return (
    <Icon 
      viewBox="0 0 109 109" 
      color={'#C7D3D9'} 
      fill={'none'}
      {...props}
      width={`${iconWidth}px`}
      height={`${iconHeight}px`}
    >
        <path 
            d="M44.3303 49.3029L60.2589 60.6805L99.1955 32.8687" 
            stroke="currentColor" 
            strokeWidth="5"
        />
        <path 
            d="M107.857 43.3966C110.461 55.91 108.584 68.9406 102.554 80.2105C96.5247 91.4803 86.7258 100.272 74.8707 105.05C63.0157 109.827 49.8586 110.286 37.6996 106.346C25.5405 102.406 15.1531 94.3175 8.35308 83.495C1.55309 72.6725 -1.22684 59.8043 0.499266 47.1399C2.22537 34.4755 8.34769 22.8207 17.796 14.2127C27.2443 5.60479 39.4174 0.591453 52.1874 0.0490887C64.9574 -0.493276 77.5117 3.46984 87.6559 11.2456L84.2386 15.7037C75.14 8.72934 63.8795 5.17469 52.4257 5.66115C40.9719 6.14762 30.0534 10.6443 21.5789 18.365C13.1044 26.0857 7.61313 36.5394 6.06493 47.8985C4.51673 59.2576 7.01014 70.7996 13.1093 80.5066C19.2084 90.2137 28.5253 97.4683 39.4311 101.002C50.337 104.536 62.138 104.125 72.7712 99.8398C83.4044 95.5548 92.1933 87.6689 97.6016 77.5606C103.01 67.4523 104.693 55.7647 102.358 44.541L107.857 43.3966Z" 
            fill="currentColor"
        />

    </Icon>
  );
};
export default ReportSentIcon;