import { createSelector } from 'reselect';

const getDentistsSlice = (state) => state.dentists;

export const getSearchDentistsLoading = createSelector([getDentistsSlice], (state) => {
  return state.searchLoading;
});

export const getSearchDentistsError = createSelector([getDentistsSlice], (state) => {
  return state.searchError;
});

export const getDentistsByCountry = createSelector([getDentistsSlice], (state) => {
  return state.byCountry || {};
});

export const getDentistsLoading = createSelector([getDentistsSlice], (state) => {
  return state.loading;
});

export const getDentistsById = createSelector([getDentistsSlice], (state) => {
  return state.dentists;
});
