const PREFIX = 'PATIENTNOTES_';
export const constants = {
  FETCH_PATIENTNOTES_REQUEST: `${PREFIX}FETCH_PATIENTNOTES_REQUEST`,
  FETCH_PATIENTNOTES_SUCCESS: `${PREFIX}FETCH_PATIENTNOTES_SUCCESS`,
  FETCH_PATIENTNOTES_FAILURE: `${PREFIX}FETCH_PATIENTNOTES_FAILURE`,
  CREATEPATIENTNOTE_REQUEST: `${PREFIX}CREATEPATIENTNOTE_REQUEST`,
  CREATEPATIENTNOTE_SUCCESS: `${PREFIX}CREATEPATIENTNOTE_SUCCESS`,
  CREATEPATIENTNOTE_FAILURE: `${PREFIX}CREATEPATIENTNOTE_FAILURE`,
  ADD_PATIENTNOTE: `${PREFIX}ADD_PATIENTNOTE`,
  UPDATE_PATIENTNOTE: `${PREFIX}UPDATE_PATIENTNOTE`,
};
