import React, {useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch} from 'react-redux';
import { motion } from 'framer-motion';
import {
  upperFirst, toUpper,
} from 'lodash';
import BeatLoader from 'react-spinners/BeatLoader';
import { Form, Field } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import { GRADES } from '../_config';

import { formatName, localeFormatDate } from '../_helpers';
import { required} from '../_components/FormElements';

import { Flex, Box, SkeletonText,
  Popover, PopoverContent, PopoverArrow, PopoverTrigger, PopoverBody, PopoverFooter  } from '@chakra-ui/react';
import { RiUserSharedLine } from 'react-icons/ri';

// components
import { SelectAdapterGrade } from '../_components/FormElements';


// redux state
import { actions } from './_redux/actions';
import { getReportDetails } from './_redux/selectors';

// actions
import { actions as reportActions } from '../PatientReports/_redux/actions';
import { actions as dentistActions } from '../Dentists/_redux/actions';

// services
import { services as reportServices } from '../PatientReports/_redux/services';

// selectors
import {
  getIntlMessages,
  getIntlLocale,
  getProfileIsStaff,
} from '../App/_redux/selectors';
import {
  getDetailsReportId,
  getCurrentRequestId,
  getRequestDetails,
  getRequestDentistUid
} from '../PatientDetailsPage/_redux/selectors';
import { 
  getDentistsById, getDentistsLoading
} from '../Dentists/_redux/selectors';


// styles
import {
  StyledInputLayout,
  StyledTextArea,
  StyledLabel,
  StyledButton,
  ErrorLabel,
  errorCss,
} from '../_css/styles';

import {
  StyledFormHeader,
  StyledSendReportSection,
  StyledFormActions,
  StyledSendReportFormContainer,
  StyledTextDescription
} from './styles';
import { consoleLog } from '../_helpers/consoleLog';

const ReportStatusText = ({isStaff, sentAt, reportStatus, dentistName}) => {
  const intlMessages = useSelector(getIntlMessages);
  const intlLocale = useSelector(getIntlLocale);

  if (reportStatus === 3){
    return (
        <StyledTextDescription>
          {upperFirst(intlMessages['requestDetailPage.report.form.button.send.message'].replace("{sent_at}", localeFormatDate(sentAt, intlLocale, 'LLL')))}
        </StyledTextDescription>
    )
  }

  if (isStaff){
    if (reportStatus === 2){
      return (
          <StyledTextDescription>
            {intlMessages[`requestDetailPage.report.form.status.dentistApproval`]}{` - ${dentistName}`}
          </StyledTextDescription>
      )
    } else if (reportStatus === 1){ 
      return (
        <StyledTextDescription>
          {intlMessages[`requestDetailPage.report.form.status.review`]}
        </StyledTextDescription>
      )
    } 
  }
  return (<></>)
}

const SendReportActions = ({
  isStaffSendView, isSendReady, onClose, sendReport, isStaff, reportValues,
  setIsSendReady, submitting, sent_at, requestDone, dentistName
}) => {
  const intlMessages = useSelector(getIntlMessages);
  const [ isPopoverOpen, setIsPopoverOpen ] = useState(false);
  if (isStaffSendView){
    return (
      <StyledFormActions>
        <Flex
          width='295px'
        >
          <StyledButton 
            className={'cancel outlined'} 
            size={'medium'}
            style={{
              fontSize: 12,
              minWidth: isStaffSendView ? 130 : 150
            }}
            type="button" 
            onClick={onClose}
          >
            {toUpper(intlMessages['requestDetailPage.timeline.createreport.close.label'])}
          </StyledButton>
          <StyledButton 
              size={'medium'}
              style={{
                fontSize: 12,
                minWidth: isStaffSendView ? 130 : 150
              }}
              disabled={true} 
              type="submit"
          >
            {toUpper(intlMessages[`requestDetailPage.report.form.button.${
                                      isStaff ? 'admin' : 'dent'}.${
                                        isStaffSendView ? 'save' : 'send'
                                      }`])}
          </StyledButton>
        </Flex>
        <Flex 
          as={motion.div}
          position={'absolute'}
          mb={15} 
          justify={'space-between'}
          bottom={0}
          right={0}
          initial={false}
          bg={'#F7F9FA'}
          borderRadius={'28px'}
          width={'fit-content'}
          whiteSpace='nowrap'
          animate={{width: isSendReady ? '100%' : '48px'}}
        >
          <Popover isOpen={isPopoverOpen} onClose={() => setIsPopoverOpen(false)}>
            {({onClose}) => (
              <>
                <PopoverTrigger>
                  <StyledButton 
                    className={''} 
                    type="button"   
                    size={'small'}

                    style={{margin:0, marginTop:10, minWidth: isSendReady ? 'unset' : 'unset', padding: '10px 15px'}}
                    animate={{width: isSendReady ? 'fit-content' : '48px'}}
                    disabled={submitting} 
                    onClick={isSendReady ? () => setIsPopoverOpen(true) : () => setIsSendReady(true)}
                  >
                    <Flex overflow={'clip'} position={'relative'} minH={'18px'} >
                      <Box position={'absolute'} left={'0px'} top={'0px'}>
                        <RiUserSharedLine size={18} fill="#FFFFFF" />
                      </Box>
                      <Box style={{
                        paddingLeft: 10, 
                        paddingRight: 10, 
                        marginLeft: '20px', 
                        width: '100%',
                        whiteSpace: 'nowrap'
                      }}>
                        {toUpper(intlMessages['requestDetailPage.report.form.button.dent.sendtopatient'])}
                      </Box>
                    </Flex> 
                  </StyledButton>
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverBody>
                    {`Send report to patient as Dr. ${dentistName}?`}
                  </PopoverBody>
                  <PopoverFooter
                    border='0'
                    d='flex'
                    alignItems='center'
                    justifyContent='space-between'
                    pb={4}
                  >
                    <Flex justify={'flex-end'} width={'100%'}>
                      <StyledButton 
                        className={'delete'} 
                        size={'small'}
                        style={{
                          fontSize: 12,
                          minWidth: 80,
                          marginRight: 5,
                        }}
                        type="button" 
                        onClick={onClose}
                      >
                        {toUpper(intlMessages['requestDetailPage.timeline.createreport.cancel.label'])}
                      </StyledButton>
                      <StyledButton 
                        className={''} 
                        size={'small'}
                        style={{
                          fontSize: 12,
                          marginLeft: 0,
                          marginRight: 0,
                        }}
                        disabled={submitting || (isSendReady && (dentistName === null)) }
                        type="button" 
                        onClick={() => sendReport(reportValues)}
                      >
                        { (isSendReady && (dentistName === null))
                          ? <BeatLoader color={'#d8dddf'} loading={(dentistName === null)} size={6} />
                          : toUpper(intlMessages['requestDetailPage.report.form.button.dent.send'])
                        }
                      </StyledButton>
                    </Flex>
                  </PopoverFooter>
                </PopoverContent>
              </>
            )}
          </Popover>
          { isSendReady && 
            <StyledButton 
              className={'delete outlined'} 
              size={'medium'}
              style={{
                fontSize: 12,
                minWidth: 80,
                marginRight: 0,
                marginLeft: 0,
              }}
              initial={true}
              animate={{width: isSendReady ? 'fit-content' : '0'}}
              type="button" 
              onClick={onClose}
            >
              {toUpper(intlMessages['requestDetailPage.timeline.createreport.cancel.label'])}
            </StyledButton>
            }
        </Flex>
      </StyledFormActions>
    )
  } else {
    return (
      <StyledFormActions style={ isStaffSendView ? {width: '280px'} : {} }>
        <StyledButton 
          className={'cancel outlined'} 
          size={'medium'}
          style={{
            fontSize: 12,
            minWidth: isStaffSendView ? 130 : 150
          }}
          type="button" 
          onClick={onClose}
        >
          {toUpper(intlMessages['requestDetailPage.timeline.createreport.close.label'])}
        </StyledButton>
        <StyledButton 
            size={'medium'}
            style={{
              fontSize: 12,
              minWidth: isStaffSendView ? 130 : 150
            }}
            disabled={requestDone || sent_at || submitting} 
            type="submit"
        >
          {toUpper(intlMessages[`requestDetailPage.report.form.button.${
                                    isStaff ? 'admin' : 'dent'}.${
                                      isStaffSendView ? 'save' : 'send'
                                    }`])}
        </StyledButton>
      </StyledFormActions>
    )
  }
}

export const SendReportForm = ({onCancel}) => {
  const dispatch = useDispatch();
  const reportId = useSelector(getDetailsReportId)
  const isStaff = useSelector(getProfileIsStaff);
  const requestDetails = useSelector(getRequestDetails)
  const requestId = useSelector(getCurrentRequestId);
  const intlMessages = useSelector(getIntlMessages);
  const report = useSelector(getReportDetails);
  const dentistsById = useSelector(getDentistsById);
  const requestDentistUid = useSelector(getRequestDentistUid);
  const dentistsLoading = useSelector(getDentistsLoading);
  const reportForm = useRef(null);
  const [ isSendReady, setIsSendReady ] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [ isSaving, setIsSaving ] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [ isDirty, setIsDirty ] = useState(false);

  useEffect(() => {
    // fetch dentist if not found in byId
    if (isStaff && requestDentistUid !== undefined){
      if (dentistsLoading[requestDentistUid] !== true && dentistsById[requestDentistUid] === undefined){
        dispatch(dentistActions.fetchDentistDetails(requestDentistUid));
      }
    }
  }, [requestDentistUid, dentistsById, dentistsLoading, isStaff])

  const onClose = () => {
    if (isSendReady){
      setIsSendReady(false);
    } else {
      onCancel();  
    }
  }

  const sendReport = async (values) => {
      // TODO: remove this.props.dispatch({ type: constants.CREATEREPORT_REQUEST })
      setIsSaving(true);
  
      try{
        consoleLog("values for post", values)
        consoleLog({id: reportId, ...values})
        let report = await reportServices.sendReport(requestId, {id: reportId, ...values});
        setIsDirty(false);
        setIsSaving(false);
        dispatch(reportActions.updateSendReport(report));
        dispatch(actions.nextPage());
        return null;
      } catch(err){
        setIsDirty(false);
        setIsSaving(false);
        return {
          ...err,
          [FORM_ERROR] : upperFirst(intlMessages['form.error.general.error'])
        }
      } 
  }

  const updateReport = async (values) => {
    // 
    //
    setIsSaving(true); 
    values.status = 2 // 2 is local dentist review, 1 is internal dentist review
    try{
      let report = await reportServices.updateReport(reportId, values);
      dispatch(reportActions.updateSendReport(report));
      setIsDirty(false);
      setIsSaving(false);
      return null;
    } catch(err){
      setIsDirty(false);
      setIsSaving(false);
      return {
        ...err,
        [FORM_ERROR] : upperFirst(intlMessages['form.error.general.error'])
      }
    } 
  }

  const initValues = { 
    summary: report?.summary, 
    grade: report?.grade 
  }
  const requestDone = (requestDetails?.status == "COMPLETE") || (requestDetails?.status == "CANCELED");

  const isStaffSendView = (isStaff && report?.status == 2);
  
  const dentist = dentistsById[requestDentistUid];
  const dentistIsLoading = dentistsLoading[requestDentistUid]
  const dentistName = dentistIsLoading ? null : formatName(intlMessages['format.fullName'], dentist?.first_name, dentist?.last_name);

  return (
    <StyledSendReportFormContainer>
      <Form
        onSubmit={isStaff ? updateReport : sendReport}
        initialValues={initValues}
        render = {({
          handleSubmit,
          form,
          submitting,
          values
        }) => {
          reportForm.current = form;
          return (
            <form
              onSubmit={handleSubmit}
              >
                <StyledFormHeader>
                  {intlMessages['requestDetailPage.report.form.sendreport.overallsummary.description']}
                </StyledFormHeader>
  
                <Field
                  name="grade"
                  items={GRADES}
                  validate={required}
                  disabled={(requestDone || report?.sent_at || submitting) ? true : false}
                  label={toUpper(intlMessages['requestDetailPage.report.form.grade'])}
                  component={SelectAdapterGrade}
                  intlMessages={intlMessages}
                  placeholder={upperFirst(intlMessages['requestDetailPage.report.form.grade'])}
                />
                <Field name="summary" parse={v => v}>
                  {({ input, meta}) => (
                    <StyledInputLayout>
                      <StyledLabel className="Input__Label">{toUpper(intlMessages['requestDetailPage.report.form.summary'])}</StyledLabel>
                      <StyledTextArea
                        {...input}
                        columns={20}
                        disabled={requestDone || report?.sent_at || submitting}
                        rows={10}
                        type="text"
                        addCSS={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)) && errorCss}
                        placeholder={upperFirst(intlMessages['requestDetailPage.report.form.summary'])} />
                      <ErrorLabel
                        addCSS={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)) && errorCss}
                        >
                          {((meta.error && meta.touched)  || (meta.submitError && !meta.dirtySinceLastSubmit)) && intlMessages[`requestDetailPage.report.form.summary.error.${(meta.error)}`] || meta.submitError}
                      </ErrorLabel>
                    </StyledInputLayout>
                  )}
                </Field>
                <SkeletonText isLoaded={!dentistIsLoading || !isStaff} noOfLines={1}>
                  <ReportStatusText isStaff={isStaff} sentAt={report?.sent_at} reportStatus={report?.status} dentistName={dentistName} />
                </SkeletonText>
                {/*<StyledButton disabled={report?.sent_at || submitting} type="submit">{toUpper(intlMessages['requestDetailPage.report.form.button.submit'])}</StyledButton>*/}
                <SendReportActions 
                  isStaffSendView={isStaffSendView}
                  isSendReady={isSendReady} 
                  isStaff={isStaff}
                  setIsSendReady={setIsSendReady}
                  submitting={submitting} 
                  sent_at={report?.sent_at}
                  requestDone={requestDone} 
                  onClose={onClose} 
                  sendReport={sendReport} 
                  dentistName={dentistName}
                  reportValues={values}
                />
            </form>
          )
        }}
      />
    </StyledSendReportFormContainer>
  )
}

const ContentSection = () => {
  return (
    <div className={'contentSection'}>
      <div className={'sendReportSection'}>
        <SendReportForm />
      </div>
    </div>
  )
}

const TitleSection = () => {
  const intlMessages = useSelector(getIntlMessages);
  const status = null;
  return (
    <div className={'titleSection'}>
      <div><span>{intlMessages['requestDetailPage.timeline.sendreport.description']}</span></div>
      <div className={'statusMessage'}><span>{intlMessages[`requestDetailsPage.timeline.sendreport.statusMessage.${status}`]}</span></div>
    </div>
  )
}

export const SendReportSection = () => {
  return (
    <StyledSendReportSection>
      <TitleSection />
      <ContentSection />
    </StyledSendReportSection>
  )
}
