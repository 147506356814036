import styled from 'styled-components';

export const StyledRecordUploadMedia = styled.div`
  padding: 0;
  background-color: #d8d8d8;
  width: fit-content;
  ${(props) => (props.selected ? 'box-shadow: 0 0 1pt 1pt #367FFF; ' : '')}
  border-radius: ${(props) => props.imgBorderRadius}px;
  width: ${(props) => props.imgWidth}px;
  height: ${(props) => props.imgHeight}px;

  margin-top: ${(props) => props.marginTop}px;
  margin-left: ${(props) => props.marginLeft}px;

  /* width, height: TODO change to 100% and make container have width */
  img {
    width: ${(props) => props.imgWidth}px;
    height: ${(props) => props.imgHeight}px;
    object-fit: cover;
    vertical-align: middle;
    border-radius: ${(props) => props.imgBorderRadius}px;
  }
  /* width, height: TODO change to 100% and make container have width */
  video {
    width: ${(props) => props.imgWidth}px;
    height: ${(props) => props.imgHeight}px;
    object-fit: cover;
    vertical-align: middle;
    border-radius: ${(props) => props.imgBorderRadius}px;
  }
`;

export const StyledLoadingMedia = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => props.imgWidth}px;
  height: ${(props) => props.imgHeight}px;
  border-radius: ${(props) => props.imgBorderRadius}px;
  vertical-align: middle;
  background-color: #d8d8d8;

  svg {
    width: 70px;
    height: 70px;
  }
`;

export const StyledRecordUploadIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => props.imgWidth}px;
  height: ${(props) => props.imgHeight}px;
  vertical-align: middle;
  border-radius: ${(props) => props.imgBorderRadius}px;
  color: #edf3f6;

  svg {
    stroke: 'white';
  }
`;

export const StyledErrorDiv = styled.div``;

export const BlankDiv = styled.div``;

export const BlankMediaItem = styled.div`
  border-radius: ${(props) => props.imgBorderRadius}px;
  position: relative;
  height: ${(props) => props.imgHeight}px;
  width: ${(props) => props.imgWidth}px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;

  > ${BlankDiv} {
    border-radius: ${(props) => props.imgBorderRadius}px;
    background-color: #d8d8d8;
    height: 72px;
    width: 72px;
  }
`;
