import React from 'react';
import { 
    FormControl, 
    FormLabel, 
    FormErrorMessage,
    Checkbox
  } from '@chakra-ui/react';
  
  export const CheckboxComponent = ({name, value, onChange, label, isInvalid, error, isDisabled=false, colorScheme='brand'}) => {
    const handleChange = () => {
      onChange(!value)
    }
  
    return (
      <FormControl id={name} isInvalid={isInvalid} colorScheme={colorScheme}>
        <Checkbox 
          isChecked={value}
          onChange={handleChange}
          size="lg"
          isDisabled={isDisabled}
          colorScheme={colorScheme}
        >
          <FormLabel m={0} ml={'5px'}>{label}</FormLabel>
        </Checkbox>
        {
            isInvalid && <FormErrorMessage>{error ? error  : ""}</FormErrorMessage>
        }
        
      </FormControl>
    )
  }