import { createSelector } from 'reselect';
import {
  getPatientReportChartsById,
  getPatientReportsLoading,
} from '../PatientReports/_redux/selectors';
import { getDetailsReportId } from '../PatientDetailsPage/_redux/selectors';

export const getReportToothChart = (state) => state.reportToothChart;

export const getReportCharts = createSelector(
  [getPatientReportChartsById, getDetailsReportId],
  (reportCharts, currentReport) => {
    return reportCharts[currentReport] || [];
  }
);

export const getReportLoading = createSelector(
  [getPatientReportsLoading, getDetailsReportId],
  (loading, currentReport) => {
    return loading[currentReport] == true;
  }
);

export const getChartCategory = createSelector([getReportToothChart], (state) => {
  return state.currentChartCategory;
});

export const getFilteredReportCharts = createSelector(
  [getReportCharts, getChartCategory],
  (charts, category) => {
    return charts && category ? charts.filter((item) => item.getReportType() == category) : charts;
  }
);
/*
{
  comment: "Straightening teeth is optional. Impacted teeth doesnt impact bite yet."
  created_at: "2021-03-18T11:52:46.860557Z"
  extraData:
  all_regions: (4) ["t23", "t22", "t21", "t11"]
  getAllRegions: ƒ ()
  getCharting: ƒ ()
  getRegions: ƒ ()
  getType: ƒ ()
  getUploadId: ƒ ()
  isUploadId: ƒ (uploadId)
  regions: []
  setRegions: ƒ (regions, all_regions)
  type: "ORTHO"
  upload_id: null
  __proto__: Object
  grade: 1
  id: 7
}
*/
export const getFilteredReportRegions = createSelector([getFilteredReportCharts], (charts) => {
  const regions = {};
  charts.length &&
    charts.map((elem) => {
      elem.getAllRegions().map((item) => {
        regions[item] = regions[item] ? 'ALL' : elem.getReportType();
      });
    });
  return regions;
});
