import { constants } from './constants';

export function alertDialog(state = {}, action) {
  switch (action.type) {
    case constants.SUCCESS:
      return {
        type: 'alert-success',
        ...action,
      };
    case constants.ERROR:
      return {
        type: 'alert-danger',
        ...action,
      };
    case constants.CLEAR:
      return {};
    case constants.SHOW_GENERAL:
      // data should be type
      return {
        ...action.data,
      };
    default:
      return state;
  }
}
