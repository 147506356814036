import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { startCase, toUpper, upperFirst } from 'lodash';
import { REQUEST_TYPES } from '../_config';
import { motion } from 'framer-motion';
import cn from 'classnames';

import { FormattedMessage } from 'react-intl';

import { localeFormatDate } from 'src/_helpers';
import { storage } from 'src/_helpers/storage';

import { MdPlaylistAddCheck } from 'react-icons/md';
import { Flex, Switch, Text, Button, FormLabel } from '@chakra-ui/react';

// redux
import {
  getCurrentRequestId
} from './_redux/selectors';

// components
import { LoadingEllipsis } from '../_components/Loaders';

// action
import { actions as requestActions } from '../PatientRequests/_redux/actions';

// selectors
import { getIntlMessages, getIntlLocale, getProfileIsStaff } from '../App/_redux/selectors';
import { getPatientRequestsById } from '../PatientRequests/_redux/selectors';

// styles
import { StyledButton, OverflowTextContainer } from '../_css/styles';


const gridTemplateColumns = "1fr 1fr 2fr 1fr 1fr";
const gridGap = "20px";

const StyledRequestTaskHeader = styled.div`
    font-size:14px;
    padding-left: 16px;
    padding-right: 16px;
    background: transparent;
    box-sizing: border-box;
    border-radius: 3px;
    display: grid;
    color: #273238;
    text-transform: uppercase;

    grid-template-columns:  ${gridTemplateColumns};

    -ms-grid-gap: ${gridGap};
    grid-gap: ${gridGap};

    > div {

      display:flex;
    }
`

const StyledRequestTask = styled(motion.div)`
  border-radius: 5px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 30px;
  padding-bottom: 30px;
  background: #FFF;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  display: grid;

  grid-template-columns: ${gridTemplateColumns};
  grid-gap: ${gridGap};


  &:not(:disabled):not(.status.COMPLETED):hover{
    background-color: #EDF3F6;
    cursor: pointer;
  }

  .longText{
    white-space: nowrap;
    text-overflow:ellipsis;
    overflow:hidden;
  }

  .status{    
    &:after {content:'';display:inline-block;width:10px;height:10px;border-radius:10px;vertical-align:middle;margin-left:7px;margin-right:12px;}
    &.PENDING::after{background-color:#FFA05C}
    &.COMPLETE::after{background-color:#35BE9D}
    &.COMPLETED::after{background-color:#35BE9D}
  }


  > div {
    display:flex;
    align-items:center;
  }

`;

const Styles = styled.div`
  display:flex;
  flex-direction: column;

  .sectionTitle.tasksTitle{
    padding: 25px 15px 10px;
    display:flex;
    justify-content: space-between;
    box-shadow: none;
    background: none;
  }

  .sectionContent{
    padding: 15px;
    display: flex;
    flex-direction: column;
    background-color: #F7F9FA80;
    min-height: 150px;

    ${StyledRequestTask}{
      margin-bottom: 10px;
    }

    ${StyledButton}{
      margin: 0;
      min-width: 120px;
      font-size: 12px;
      padding: 12px 5px
    }
  }
`;

const TaskLabel = ({taskType}) => {
  if (taskType == REQUEST_TYPES.VIDEOCHAT){
    return (
      <FormattedMessage 
        id={'openRequest.item.type.VIDEOCHAT'}
        defaultMessage={'Video Chat'}
      />)
  } else {
    return (
      <FormattedMessage 
        id={'openRequest.item.type.REPORT'}
        defaultMessage={'Report'}
      />)
    }
}

const RequestTaskContainer = ({task, isShowCompleted, handleTaskClick}) => {
  const intlLocale = useSelector(getIntlLocale);
  const intlMessages = useSelector(getIntlMessages);
  const isStaff = useSelector(getProfileIsStaff);
  
  
  if (task){
    if (task.status == "COMPLETE" && !isShowCompleted){
      return (<></>)
    }
      // some reports have status 0, but sent_at has a date, so they should be marked as completed
    let requestStatus = task?.report?.status;
    if (task?.report?.sent_at != null){
      requestStatus = 3;
    } else if (task?.report === null){
      requestStatus = 0;
    }
    return(
      <StyledRequestTask 
        completed={task.status == 'COMPLETE'}
        onClick={() => handleTaskClick(task.unique_id, task.type, task.status, task?.report?.unique_id)} 
      >
        <div><TaskLabel taskType={task.type}/></div>
        <div>{localeFormatDate(task.created_at, intlLocale, "l LT")}</div>
        <OverflowTextContainer>{task.remark}</OverflowTextContainer>
        <div className={`${toUpper(task.status)} status`}>{intlMessages[`requestListPage.requestList.status.${
                                isStaff ? 'admin' : 'dent'
                              }.${
                                requestStatus// will be undefined if no report has been started
                              }`]}</div>
        <div>
          { (requestStatus == 3) 
            ? <div />
            : (
              <Button
                size={'sm'}
                isDisabled={task.status == "COMPLETE" && task.type != "REPORT"}
                onClick={() => handleTaskClick(task.unique_id, task.type, task.status)}
              >
                { intlMessages[`requestDetailPage.requestTasks.task.button.${task.type}.label`]}
              </Button>
            )

          }
         
        </div>
      </StyledRequestTask>
    )
  } else {
    return(
      <StyledRequestTask>
        <LoadingEllipsis />
      </StyledRequestTask>
    )
  }
}

export const ShowHistoryButton = ({isChecked, onChange}) => {
  const intlMessages = useSelector(getIntlMessages);
  return (
    <Flex align={'center'} alignSelf={'flex-end'}>
      <FormLabel margin={0} mr={2} color={'none'} htmlFor='showHistory'>{intlMessages['requestDetailPage.requestTasks.heading.button.history'] + '?'}</FormLabel>
      <Switch margin={0} size={'sm'} id={'showHistory'} colorScheme={'brand'} 
        isChecked={isChecked} onChange={() => onChange(!isChecked)} />
    </Flex>
  )
}

const RequestTaskHeader = () => {
  const intlMessages = useSelector(getIntlMessages);
  return (
    <StyledRequestTaskHeader>
      <div>{(intlMessages['requestListPage.requestList.header.type'])}</div>
      <div>{(intlMessages['requestListPage.requestList.header.sent_at'])}</div>
      <div>{(intlMessages['requestListPage.requestList.header.remark'])}</div>
      <div>{(intlMessages['requestListPage.requestList.header.status'])}</div>
      <div />
    </StyledRequestTaskHeader>
  )
}

export const RequestTasksSection = ({requests=[], loading, onReviewClick=null}) => {
  const dispatch = useDispatch()
  const intlMessages = useSelector(getIntlMessages);
  const location = useLocation();
  const history = useHistory();
  const [ init, setInit ] = useState(null);

  const [ isShowCompleted, setIsShowCompleted ] = useState(storage.get('showCompletedTasks') || false);

  //const [ requests, setRequests ] = useState([]);
  const currentRequestUid = useSelector(getCurrentRequestId);
  const patientRequests = useSelector(getPatientRequestsById);


  useEffect(()=>{
    return () => {}
  }, [])

  useEffect(()=>{
    if ((init == null) && loading){
      // first load
      setInit(true)
    } else if (init && !loading){
      // first loading finished
      setInit(false)
    }
  }, [loading, init])


  useEffect(()=>{
    if (currentRequestUid && patientRequests[currentRequestUid] == null){
      dispatch(requestActions.fetchRequest(currentRequestUid))
    }
  }, [currentRequestUid])

  const handleTaskClick = (uid, type, status, reportUid=null) => {
     if (type == REQUEST_TYPES.VIDEOCHAT){
      let newParams = new URLSearchParams();
      newParams.set("mdl", `task.${REQUEST_TYPES.VIDEOCHAT}`);
      newParams.set("rid", uid);
      history.push((`${location.pathname}?${newParams.toString()}`));
    } else {
      // tREQUEST_TYPES.REPORT, REQUEST_TYPES.DMFT
      if (status == "COMPLETE"){
        // show pop up of review
        onReviewClick && onReviewClick(reportUid);
      } else {
        history.push((`/reports/request/${uid}`));
      }

    }
  }

  const handleIsShowCompletedClick = (value) => {
    setIsShowCompleted(value)
    storage.set('showCompletedTasks', value)
  }


  const filteredRequests = Object.values(patientRequests).filter(x => isShowCompleted ? true : x.status !== 'COMPLETE')

  return (
    <Styles>
        <div className={cn('sectionTitle', 'tasksTitle')}>
          {startCase(intlMessages['requestDetailPage.details.tasks.title'])}
          <ShowHistoryButton isChecked={isShowCompleted} onChange={handleIsShowCompletedClick} />
        </div>
        <RequestTaskHeader />
        <div className={'sectionContent taskList'}>
          { (!init && loading) && <div className={'loadingSection'}><LoadingEllipsis /></div>}
          {
            init
            ? <div className={'loadingSection'}><LoadingEllipsis /></div>
            :(!requests)
              ? <div>
                  {intlMessages['requestDetailPage.requestTasks.task.noAppointments.label']}
                </div>
              : (requests.length == 0 || Object.keys(filteredRequests).length == 0)
                  ?  <Flex flexGrow={1} direction={'column'} align={'center'} justify={'center'}>
                        <MdPlaylistAddCheck size={26} fill={'#7A8F99'} style={{margin: 0}} />
                        <Text fontSize={16} mt={3}>{upperFirst(intlMessages['requestDetailPage.requestTasks.task.noAppointments.label'])}</Text>
                        <Text fontSize={14} color={'none.500'}>{upperFirst(intlMessages['requestDetailPage.requestTasks.heading.noTasks.description'])}</Text>
                     </Flex>
                  : requests.map( (elem) => (
                      <RequestTaskContainer
                        key={elem}
                        task={patientRequests[elem]}
                        isShowCompleted={isShowCompleted}
                        handleTaskClick={handleTaskClick}
                        />
                      ))
          }
        </div>
    </Styles>
  )
}
