import { useState, useEffect, useRef } from 'react';

export function useComponentSize() {
  const ref = useRef(null);
  const [size, setSize] = useState({ width: 0, height: 0, offsetTop: 0 });

  useEffect(() => {
    const handleResize = () => {
      if (ref.current) {
        setSize({
          width: ref.current.offsetWidth,
          height: ref.current.offsetHeight,
          offsetTop: ref.current.offsetTop
        });
      }
    };

    const resizeObserver = new ResizeObserver(handleResize);
    if (ref.current) {
      resizeObserver.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        resizeObserver.unobserve(ref.current);
      }
    };
  }, [ref.current]);

  return { ref, size };
}

export function useWindowSize() {
    const ref = useRef(null);
    const [ refSize, setRefSize ] = useState({ width: 0, height: 0, offsetTop: 0 });
    const [ windowSize, setSize] = useState({ width: window.innerWidth, height: window.innerHeight });


    useEffect(() => {
        const handleResize = () => {
            setSize({ width: window.innerWidth, height: window.innerHeight });
            if (ref.current){
                setRefSize({
                    width: ref.current.offsetWidth,
                    height: ref.current.offsetHeight,
                    offsetTop: ref.current.offsetTop
                });
            }
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return {windowSize, ref, refSize};
}
