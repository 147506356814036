import React, {useState, useEffect} from 'react';
import { motion, AnimatePresence } from "framer-motion";
import { useSelector, useDispatch} from 'react-redux';
import styled from 'styled-components';
import Modal from 'react-modal';

import { localeFormatDate } from '../_helpers';
import { RecordUpload } from '../_helpers/models';

// components
import { CloseIcon as CloseSvg } from '../_images/icons/CloseIcon';
import { RecordUploadMedia } from '../_components/RecordUploadMedia';

// redux state
import { actions } from './_redux/actions';
import {
  getRecordViewerModalOpen
} from './_redux/selectors';

// actions

// selectors
import { getIntlLocale } from '../App/_redux/selectors';
import {
  getPatientRecords,
} from '../Patients/_redux/selectors';
import {getPatientRecordsById} from '../PatientRecords/_redux/selectors';
import { ModalCloseIcon } from '../_css/styles';

// styles
import { StyledRecordUploadMedia, StyledLoadingMedia } from '../_components/RecordUploadMedia/styles';
import { consoleLog } from '../_helpers/consoleLog';

const generalStyles = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
      zIndex: 10,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    content: {
      overflow: 'auto',
      WebkitOverflowScrolling: 'touch',
      width: '100%',
      height:  '100%',
      outline: 'none',
      padding: '0',
      position: 'initial',
      background: 'transparent',
      border: 'none',
      zIndex: 10
    }
}

const StyledCloseIcon = styled(ModalCloseIcon)`
  margin: 20px;

  svg {
    width: 20px;
    height: 20px;
  }
`;

const Styles = styled.div`
  .title{
    position:absolute;
    top: 0;
    left: 0;
    margin-top: 20px;
    font-size: 20px;
    color: white;
    width: 100%;

    > div {
      text-align: center;
      margin: 0 auto;
    }
  }
`;

const variants = {
  enter: () => {
    return {
      opacity: 0
    };
  },
  center: {
    zIndex: 1,
    opacity: 1
  }
};

/**
 * Experimenting with distilling swipe offset and velocity into a single variable, so the
 * less distance a user has swiped, the more velocity they need to register as a swipe.
 * Should accomodate longer swipes and short flicks without having binary checks on
 * just distance thresholds and velocity > 0.
 */
//const swipeConfidenceThreshold = 10000;
//const swipePower = (offset, velocity) => {
//  return Math.abs(offset) * velocity;
//};


const PreviewImage = ({upload}) => {
  //const dispatch = useDispatch();
  if (upload){
    return (
      <RecordUploadMedia
        key={'RecordUploadMediaL' + upload.id}
        upload={new RecordUpload(upload)}
        imgWidth={640}
        imgHeight={480}
        zoom={false}
        imgBorderRadius={5}
        play={true}
      />
    )
  } else {
  return (
    <div
      style={{
        height: '100%',
        width: '100%'
      }}
      >
    </div>
  )
  }
}

export const StyledMediaPreview = styled(motion.div)`
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.1));
  img { border-radius: 4px; }
  video { border-radius: 4px; }

  ${StyledRecordUploadMedia}, ${StyledLoadingMedia}{
    background-color: transparent;
  }
`;

export const MediaPreview = ({upload}) => {
  return (
    <StyledMediaPreview>
      <PreviewImage upload={upload}/>
    </StyledMediaPreview>
  )
}

export const PatientUploadDetail = ({uploads, setRecordIndex }) => {
  const [[page, direction], setPage] = useState([0, 0]);
  //const uploadIndex = wrap(0, uploads.length, page);

  consoleLog("page ", page)

  document.addEventListener('keydown', function(event){
      consoleLog(`Key: ${event.key} with keycode ${event.keyCode} has been pressed`);
      handleKeyPress(event.keyCode);
  })

  const paginate = (newDirection) => {
    consoleLog(`new direction ${newDirection}, page ${page}, uploads length ${uploads.length}` )
    if ((page+newDirection) == uploads.length){
      setRecordIndex(1);
      setPage([page + newDirection, newDirection]);
    } else if ((page+newDirection) < 0){
      setRecordIndex(-1);
      setPage([0, newDirection]);
    } else {
      setPage([page + newDirection, newDirection]);
    }
  };

  const handleKeyPress = (code) => {
    if (code == 39){
      // right
      paginate(1)
    } else if (code == 37){
      // left
      paginate(-1)
    }
  }

  useEffect(()=>{
    return () => {}
  }, [])

  return (
    <AnimatePresence>
      <motion.div
        style={{height:'100%', width: '100%'}}
        key={page}
        className={'carouselContent'}
        custom={direction}
        variants={variants}
        initial="enter"
        animate="center"
        exit="exit"
        >
          <MediaPreview
            upload={uploads[page]}
          />
      </motion.div>
    </AnimatePresence>
  )
}

export const PatientRecordDetail = ({records}) => {
  const intlLocale = useSelector(getIntlLocale);
  const [[recordIndex, uploadIndex, direction], setPage] = useState([0, 0, 0]);
  //const recordIndex = wrap(0, records.length, page);

  const recordById = useSelector(getPatientRecordsById);

  const paginate = (newDirection) => {
    setPage( prev => {
      if ((prev[1]+newDirection) >= records[prev[0]].patientrecord_uploads.length){
        // end of record, go to next record
        if (prev[0] < records.length - 1){
          return [prev[0]+1, 0, newDirection]
        } else{
          return [prev[0], prev[1], newDirection]
        }
      } else if ((prev[1]+newDirection) < 0){
        if (prev[0] > 0){
          // go to last index - 1 of previous record
          return [prev[0]-1, records[prev[0] -1].patientrecord_uploads.length - 1, newDirection];
        } else{
          return [prev[0], prev[1], newDirection]
        }
      } else {
        // else just add to inde
        return[prev[0], prev[1] + newDirection, newDirection];
      }
    })
  }



  useEffect(()=>{
    // define function within useEffect
    const handleKeyPress = (event) => {
      consoleLog(`${recordIndex}, ${uploadIndex}, ${direction}` )
      if (event.keyCode == 39){
        // right
        paginate(1);
      } else if (event.keyCode == 37){
        // left
        paginate(-1);
      }
    }
    window.addEventListener('keydown', handleKeyPress)
    return () =>{
      window.removeEventListener('keydown', handleKeyPress);
    }
  }, [])

  return (
    <Styles>
      <AnimatePresence>
        <motion.div
          style={{height:'fit-content'}}
          key={`${recordIndex}_${uploadIndex}`}
          className={'carouselContent'}
          custom={direction}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          >
            <div className={'title'}>
              <div>{`File Name: ${recordById[records[recordIndex]?.unique_id]?.patientrecord_uploads[uploadIndex]?.upload?.filename}`}</div>
              <div>{`Date Created: ${localeFormatDate(
                  recordById[records[recordIndex]?.unique_id]?.patientrecord_uploads[uploadIndex]?.upload?.created_at,
                  intlLocale,
                  "ll LT")}`}</div>
            </div>
            <MediaPreview
              upload={recordById[records[recordIndex]?.unique_id]?.patientrecord_uploads[uploadIndex]}
            />
        </motion.div>
      </AnimatePresence>
    </Styles>
  )
}

export const PatientRecordViewerModal = () => {
  const dispatch = useDispatch();
  const modalIsOpen = useSelector(getRecordViewerModalOpen);

  const patientRecords = useSelector(getPatientRecords);

  useEffect(() => {
    return () => {};
  }, [])

  const afterOpenModal = () => {
    /* all refs are set*/
  }

  const closeModal = () => {
    dispatch(actions.closeRecordViewerModal())
  }

  return(
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={generalStyles}
      shouldCloseOnOverlayClick={false}
      contentLabel="Record Details"
    >
      <StyledCloseIcon onClick={closeModal} ><CloseSvg /></StyledCloseIcon>
        <div style={{
              position: 'relative',
              display: 'flex',
              alignItems:'center',
              justifyContent: 'center',
              height: '100%'
            }}
          >
          <PatientRecordDetail
            records={patientRecords}
          />
        </div>
      </Modal>
  )
}
