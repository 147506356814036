import styled from 'styled-components';
import PerfectScrollBar from 'react-perfect-scrollbar';
const WrappedPage = styled(PerfectScrollBar)`
  min-height: 100vh;
  display: -ms-grid;
  display: grid;
  -ms-grid-gap: 10px;
  grid-gap: 10px;
  padding-top: 102px; /* height of the nav bar 72px + extra for content */
  margin-left: 90px; /* width of the sidebar */
  padding-right: 20px;
  background-color:white;
  padding-left: 60px;
  /*grid-template-columns: repeat(12, 1fr);
  grid-auto-rows: minmax(25px, auto);*/
`;
export {WrappedPage};
