import styled from 'styled-components';
import {
  StyledRecordUploadMedia,
  StyledRecordUploadIcon,
  StyledLoadingMedia,
} from '../_components/RecordUploadMedia/styles';

export const BlankDiv = styled.div``;

export const BlankMediaItem = styled.div`
  position: relative;
  border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : '5')}px;
  height: ${({ imgHeight }) => (imgHeight ? imgHeight : '72')}px;
  width: ${({ imgWidth }) => (imgWidth ? imgWidth : '72')}px;
  box-sizing: border-box;

  > ${BlankDiv} {
    border-radius: 5px;
    background-color: #d8d8d8;
    height: 72px;
    width: 72px;
  }
`;

export const StyledPreviewImage = styled.div`
  border-radius: 5px;
`;

export const StyledMediaItem = styled.div`
  border-radius: 3px;
  position: relative;
  height: 130px;
  width: 130px;
  border-radius: 5px;
  margin-bottom: 8px;
  background-color: ${({ selected }) => (selected ? '#367FFF' : 'transparent')};
  border: 2px solid ${({ selected }) => (selected ? '#367FFF' : 'transparent')};
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;

  &:last-child {
    margin-bottom: 0;
  }

  video {
    border-radius: 5px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  img {
    display: block;
    border-radius: 5px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const StyledMediaList = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 170px;
  padding-left: 20px;

  ${StyledRecordUploadMedia} {
    margin-bottom: 10px;

    &:hover {
      cursor: pointer;
      &.selectedMediaItem {
        cursor: default;
      }
    }
  }
  ${StyledRecordUploadIcon} {
    margin-bottom: 10px;
  }
  ${StyledLoadingMedia} {
    margin-bottom: 10px;
  }
`;

export const StyledMediaPreview = styled.div`\
   filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.1));
   border-radius: 4px;

   img { border-radius: 4px; }
   video { border-radius: 4px; }
 `;

export const StyledRecordDetail = styled.div`
  display: flex;
  position: relative;
  .loading {
    padding-right: 10px;
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: calc(100% - 15px);
    border-radius: 10px;
  }
`;

export const Wrapper = styled.div`
  width: fit-content;
  height: 570px;
  background-color: #ffffff;
  padding: 20px;
  padding-right: 0;

  > h2 {
    font-size: 20px;
    padding-bottom: 20px;
    > span {
      font-family: 'Proxima Nova Semibold';
      color: black;
    }
  }
`;

export const StyledPatientRecords = styled.div`
    overflow:hidden;
    background: #F7F9FA;
    border: 1px solid #E6EDF0;
    box-sizing: border-box;
    border-radius: 4px;

    .sectionTitle.recordTitle{
      padding: 25px 15px 10px;
    }

    .loadingBoxes{
      padding: 25px;

    }

    .uploadBoxes{
        padding:0 15px;
      display:flex;
      overflow-x:scroll;
      padding-top: 15px;
      padding-bottom: 25px;
    }

    ${StyledRecordUploadMedia} {
      float:left;
      margin-left:0;
      flex-shrink: 0;
      &:hover{
        cursor:pointer;
      }
    }
    ${StyledRecordUploadIcon}{
      float:left;
      margin-left:0;
      flex-shrink: 0;
      &:hover{
        cursor:pointer;
      }
    }
    ${StyledLoadingMedia}{
      float:left;
      margin-left:0;
      flex-shrink: 0;
    }
    ${StyledRecordUploadMedia}:not(:last-child) {margin-right:20px;}
    ${StyledLoadingMedia}:not(:last-child) {margin-right:20px;}
    ${StyledRecordUploadIcon}:not(:last-child) {margin-right:20px;}
      }
`;
