const PREFIX = 'PATIENTREQUESTS_';
export const constants = {
  GETREQUEST_REQUEST: `${PREFIX}GETREQUEST_REQUEST`,
  GETREQUEST_SUCCESS: `${PREFIX}GETREQUEST_SUCCESS`,
  GETREQUEST_FAILURE: `${PREFIX}GETREQUEST_FAILURE`,
  UPDATE_REQUESTS_MANY: `${PREFIX}UPDATE_REQUESTS_MANY`,
  UPDATE_REQUESTSTATUS: `${PREFIX}UPDATE_REQUESTSTATUS`,
  UPDATE_REQUEST: `${PREFIX}UPDATE_REQUEST`,
  UPDATE_REQUEST_EXTRADATA: `${PREFIX}UPDATE_REQUEST_EXTRADATA`,
};
