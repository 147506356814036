import React, { useEffect, useRef, useState } from "react";

const getCenter = (country) => {
    if (country == "TH"){
        return { lat: 13.736717, lng: 100.523186 };
    } else {
        return { lat: 35.667007175289214, lng: 139.75308217095716 };
    }
}

export const LocationMap = ({ country, center, onMapClick }) => {
  // eslint-disable-next-line no-unused-vars
  const [map, setMap] = useState(null);
  const mapRef = useRef(null);
  const markers = useRef([]);
  useEffect(() => {
    const mapOptions = {
      center: center ? center : getCenter(country),
      zoom: 15,
      panControl: false,
      zoomControl: false,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      overviewMapControl: false,
      rotateControl: false,
    };
    const mapInstance = new window.google.maps.Map(mapRef.current, mapOptions);
    setMap(mapInstance);

    const marker = new window.google.maps.Marker({
      position: center,
      map: mapInstance,
    });
    markers.current.push(marker)
    mapInstance.addListener("click", (event) => {
      const lat = event.latLng.lat();
      const lng = event.latLng.lng();
      onMapClick({ lat, lng });
    });
  }, []);

  useEffect(() => {
    if (map){
      const marker = new window.google.maps.Marker({
        position: center,
        map: map,
      });
      markers.current.forEach(marker => marker.setMap(null));
      markers.current.push(marker);
    }
  }, [map, center]);
  
  return <div ref={mapRef} style={{ width: "100%", height: "400px" }} />;
};
