import { constants } from './constants';
import { services } from './services';
import { actions as patientRequestsActions } from '../../PatientRequests/_redux/actions';
import { actions as patientAppointmentActions } from '../../PatientAppointments/_redux/actions';
import { objectToQueryParamString } from 'src/_helpers/urls';

export const actions = {
  fetchPatients,
  addPatient,
  loadPatientDetailsByUUid,
  updateCurrentPatient,
  getCurrentPatientAppointmentsByUUid,
  getCurrentPatientRequestsByUUid,
  getCurrentPatientRecordsByUUid,
  openNewPatientFormModal,
  closeNewPatientFormModal,
};

function fetchPatients(args) {
  return (dispatch) => {
    dispatch(request());
    let queryParams = objectToQueryParamString(args);

    services.fetchPatients(queryParams).then(
      (data) => dispatch(success(data)),
      (error) => {
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return { type: constants.FETCH_PATIENTLIST_REQUEST };
  }
  function success(data) {
    const tempData = {};
    const { patient_count, patient_list } = data;
    (patient_list || []).map((pat) => {
      // convert to ById
      //tempData[pat.chat_uuid] = pat
      tempData[pat.unique_id] = pat;
    });
    data = { patientsList: patient_list, patients: tempData, patientCount: patient_count };
    return { type: constants.FETCH_PATIENTLIST_SUCCESS, data };
  }
  function failure(error) {
    return { type: constants.FETCH_PATIENTLIST_FAILURE, error };
  }
}

function addPatient(data) {
  return (dispatch) => {
    dispatch(add(data));
  };
  function add(data) {
    return { type: constants.ADD_PATIENT, data };
  }
}

function loadPatientDetailsByUUid(patientUuid) {
  return (dispatch) => {
    if (!patientUuid) {
      return;
    }
    dispatch(request());
    services.fetchPatientDetailsByUUid(patientUuid).then(
      (data) => dispatch(success(patientUuid, data)),
      (error) => dispatch(failure(error))
    );
  };
  function request() {
    return { type: constants.FETCH_PATIENTDETAILS };
  }
  function success(patientUuid, data) {
    return {
      type: constants.FETCH_PATIENTDETAILS_SUCCESS,
      data: { id: patientUuid, patient: data.patient },
    };
  }
  function failure(error) {
    return { type: constants.FETCH_PATIENTDETAILS_FAILURE, error };
  }
}

function getCurrentPatientAppointmentsByUUid(patientUuid) {
  return (dispatch) => {
    dispatch(request());
    services.fetchPatientAppointmentsByUuid(patientUuid).then(
      (data) => {
        let byId = {};
        let uidList = [];
        (data.patient_appointments || []).map((req) => {
          byId[req.unique_id] = req;
          uidList.push(req.unique_id);
        });
        dispatch(patientAppointmentActions.updateAppointmentsMany({ appointments: byId }));
        dispatch(success({ patient_appointments: uidList }));
      },
      (error) => dispatch(failure(error))
    );
  };
  function request() {
    return { type: constants.FETCH_PATIENTAPPOINTMENTS };
  }
  function success(data) {
    return { type: constants.FETCH_PATIENTAPPOINTMENTS_SUCCESS, data };
  }
  function failure(error) {
    return { type: constants.FETCH_PATIENTAPPOINTMENTS_FAILURE, error };
  }
}

function getCurrentPatientRequestsByUUid(patientUuid) {
  return (dispatch) => {
    dispatch(request());
    services.fetchPatientRequestsByUuid(patientUuid).then(
      (data) => {
        let byId = {};
        let uidList = [];
        (data.patient_requests || []).map((req) => {
          byId[req.unique_id] = req;
          uidList.push(req.unique_id);
        });
        dispatch(patientRequestsActions.updateRequestsMany({ requests: byId }));
        dispatch(success({ patient_requests: uidList }));
      },
      (error) => dispatch(failure(error))
    );
  };
  function request() {
    return { type: constants.FETCH_PATIENTREQUESTS };
  }
  function success(data) {
    return { type: constants.FETCH_PATIENTREQUESTS_SUCCESS, data };
  }
  function failure(error) {
    return { type: constants.FETCH_PATIENTREQUESTS_FAILURE, error };
  }
}

function getCurrentPatientRecordsByUUid(patientUuid) {
  return (dispatch) => {
    dispatch(request());
    services.fetchPatientRecordsByUuid(patientUuid).then(
      (data) => dispatch(success(data)),
      (error) => dispatch(failure(error))
    );
  };
  function request() {
    return { type: constants.FETCH_PATIENTRECORDS };
  }
  function success(data) {
    return { type: constants.FETCH_PATIENTRECORDS_SUCCESS, data };
  }
  function failure(error) {
    return { type: constants.FETCH_PATIENTRECORDS_FAILURE, error };
  }
}

function updateCurrentPatient(patientUuid) {
  return (dispatch) => {
    dispatch(update(patientUuid));
  };
  function update(data) {
    return { type: constants.UPDATE_CURRENTPATIENT, data };
  }
}

function openNewPatientFormModal() {
  return (dispatch) => {
    dispatch({ type: constants.UPDATE_STATE, data: { newPatientFormModal: true } });
  };
}

function closeNewPatientFormModal() {
  return (dispatch) => {
    dispatch({ type: constants.UPDATE_STATE, data: { newPatientFormModal: false } });
  };
}
