import React from 'react';
import { Icon } from '@chakra-ui/react';

const ArrowForwardIcon = (props) => {
  const iconWidth = props?.width || 20;
  const iconHeight = props?.height || 20;
  return (
    <Icon 
      viewBox="0 0 20 20" 
      color={'#405159'} 
      fill={'none'}
      {...props}
      width={`${iconWidth}px`}
      height={`${iconHeight}px`}
    >
        <path d="M9.92308 5L15 10L9.92308 15M14.2949 10L4 10" 
            stroke={'currentColor'}
            strokeWidth="1.5" 
            strokeLinecap="round" 
            strokeLinejoin="round"/>
    </Icon>
  );
};
export default ArrowForwardIcon;
