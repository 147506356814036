import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { WrappedPage } from '../PageComponents/WrappedPage';

//import { StyledUpcomingItem } from '../RequestDetailPage/_common/styles';

const gridTemplateColumns = '1fr 1fr 1fr 1fr 1fr 1fr';
const gridGap = '20px';

export const StyledPage = styled(WrappedPage)`
  grid-auto-rows: max-content;
  padding-right: 5rem;
  padding-bottom: 40px;
`;

export const StyledPageTitle = styled.div`
  font-family: Proxima Nova;
  font-weight: 600;
  font-size: 28px;
  /* identical to box height, or 143% */
  color: #12171a;
  text-align: left;
  display: flex;
  min-width: 100px;
  &:not(first-child) {
    margin-top: 15px;
  }
`;

export const StyledLoadingDiv = styled.div`
  font-size: 48px;
  margin-top: 400px;
  padding: 40px;
  width: 100%;
`;

export const StyledErrorDiv = styled.div`
  font-size: 48px;
  padding: 40px;
`;

export const StyledPatientListContainer = styled.div`
  padding: 20px 0px;
  display: grid;
  row-gap: 10px;
`;

export const StyledPatientHeader = styled.div`
  font-size: 14px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 10px;
  background: transparent;
  box-sizing: border-box;
  border-radius: 3px;
  display: grid;
  text-transform: uppercase;

  grid-template-columns: ${gridTemplateColumns};

  -ms-grid-gap: ${gridGap};
  grid-gap: ${gridGap};

  > div {
    display: flex;
  }
`;

export const UnstyledItemLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  display: block;

  &:hover {
    text-decoration: none;
    color: inherit;
  }
`;

export const StyledPatientListItem = styled.div`
  display: grid;
  border-radius: 5px;
  padding-left: 16px;
  padding-right: 16px;
  grid-template-columns: ${gridTemplateColumns};
  grid-gap: ${gridGap};
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);

  &:not(:disabled):hover {
    background-color: #edf3f6;
    cursor: pointer;
  }

  .longText {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  > div {
    display: flex;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
  }
`;

export const StyledPatientList = styled.div`
  > ${UnstyledItemLink}:not(:first-child) {
    margin-top: 12px;
  }
`;

export const StyledPatientItemLink = styled(Link)`
  font-size: 14px;
  padding: 30px;
  background: #f7f9fa;
  border: 1px solid #e6edf0;
  box-sizing: border-box;
  border-radius: 3px;
  display: grid;
  -ms-grid-gap: ${gridGap};
  grid-gap: ${gridGap};
  grid-template-columns: repeat(5, 1fr);

  .longText {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  color: ${({ completed }) => (completed ? 'lightgray' : '#212529')};

  & {
    text-decoration: none;
    color: ${({ completed }) => (completed ? 'lightgray' : '#212529')};
  }

  &:hover {
    cursor: pointer;
    transform: ${({ completed }) => (completed ? 'none' : 'translate(0px, -2px)')};
    box-shadow: ${({ completed }) => (completed ? 'none' : '0px 2px 5px 2px rgba(0, 0, 0, 0.05)')};
  }

  &:hover,
  &:visited,
  &:active,
  &:link {
    text-decoration: none;
    color: ${({ completed }) => (completed ? 'lightgray' : '#212529')};
  }
`;

export const StyledRequestItemRow = styled.a`
  font-size: 14px;
  padding: 30px;
  background: #f7f9fa;
  border: 1px solid #e6edf0;
  box-sizing: border-box;
  border-radius: 3px;
  display: grid;
  & {
    text-decoration: none;
    color: inherit;
  }

  grid-template-columns: repeat(5, 1fr);

  grid-gap: 40px;
  &:hover {
    cursor: pointer;
    transform: translate(0px, -2px);
    box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.05);
  }

  &:hover,
  &:visited,
  &:active,
  &:link {
    text-decoration: none;
    color: inherit;
  }
`;

export const StyledNoRequests = styled.div`
  font-size: 36px;
  text-align: center;
  padding: 50px 0;
`;

export const StyledFilter = styled.div`
  margin-left: 10px;
  color: ${({ selected }) => (selected ? 'white' : '212529')};
  background-color: ${({ selected }) => (selected ? '#212529' : '#FFFFFF')};

  font-family: 'Proxima Nova Semibold';
  padding: 30px 30px;
  font-size: 24px;

  box-shadow: 0px 5px 15px 5px rgba(0, 0, 0, 0.05);
  border-radius: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 120px;
  height: 50px;
  &:hover {
    cursor: ${({ selected }) => (selected ? 'default' : 'pointer')};
    transform: ${({ selected }) => (selected ? 'none' : 'translate(0px, -2px)')};
  }
`;
export const StyledRequestFilters = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
  width: 100%;
`;
