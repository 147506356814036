import React, {useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch  } from 'react-redux';
import { toUpper  } from 'lodash';
import { URLS } from '../_config';
import Select from 'react-select';
import { defaultSelectStyles  } from 'src/_components/Forms/common';


// eslint-disable-next-line no-unused-vars
import moment from 'moment';

import { Flex, Box, Text, HStack, Button, Spinner, IconButton, Select as ChakraSelect,
      TableContainer, Table, Thead, Tbody, Tr, Th, Td, Center, Heading, 
      Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, useDisclosure  } from '@chakra-ui/react';
      import { useReactTable, getCoreRowModel, flexRender } from '@tanstack/react-table'
import { MdPlaylistAddCheck, MdOutlineExpandMore } from 'react-icons/md';


import { localeFormatDate } from '../_helpers';

// redux state
import { actions } from './_redux/actions';
// selectors
import {
  getFilterBy,
  getLoading,
  getisLoadMore,
  getIsLoadingMore,
  getQueryParamsOffset
} from './_redux/selectors';
import { getIntlLocale, getIntlMessages, getProfileIsStaff} from '../App/_redux/selectors';

// styles
import {
  StyledPage,
  StyledRequestListContainer,
  StyledRequestHeader,
  UnstyledItemLink,
  StyledRequestItem
} from './styles';
import { OverflowTextContainer } from '../_css/styles';
import { useSearchParamsObject } from 'src/_hooks/useSearchParams';
import { useRequestList } from './api/getRequests';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelectOptions } from 'src/App/api/getSettings';
import { useHistory } from 'react-router';
import { useAppConfig } from 'src/App/state/appConfig';
 import styled from 'styled-components';
import PaginationNext from 'src/_images/icons/PaginationNext';
import PaginationPrevious from 'src/_images/icons/PaginationPrevious';
import { RequestForm } from './RequestForm';
import { useAuth } from 'src/_libs/auth';

import { AdminSelectClinicModal } from '../AdminPage/components/AdminClinicRequiredModal';

const filterSelectStyles = {
  ...defaultSelectStyles,
  control: (provided, state) => ({
    ...defaultSelectStyles.control(provided, state),
    height: 20,
    minHeight: 'unset',
    borderRadius: 5,
    padding: '0 3px ',
    paddingRight: 0,
    fontSize: 8
  }),
  container: (provided) => ({
    ...provided,
    height: 20,
    width: 80,
    padding: '0 0',
    paddingRight: 0,
    fontSize: 8 
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0 0',
    height: 20,
    fontSize: 8 
  }),
  singleValue: (provided) => ({
    ...provided,
    fontSize: 10
  }),
  indicatorsContainer: (provided, state) => {
    return { 
      ...defaultSelectStyles.indicatorsContainer(provided, state),
      padding: '0 0' }
  },
  option: (provided) => {
    return { 
      ...provided,
      fontSize: 9,
    }
  },
  dropdownIndicator: (provided) => {
    return { 
      ...provided,
      padding: '0 2px',
      "svg": {
        height: 12,
        width: 15
      }
    }
  }
}

class SelectInput extends React.Component{
	render () {
		return (
				<Select
					{...this.props}
                    ref={this.props.inputRef }
                    autoload={true}
					backspaceRemovesValue={true}
				/>
		);
	}
};

const StatusFilterComponent = ({searchParams, pagination, setPagination}) => {
  const history = useHistory();
  const { data, isLoading, isFetching } = useSelectOptions({option: 'request_statuses'});
  const { formatMessage } = useIntl();

  const handleChange = (v) => {
    let newParams = searchParams;
    newParams.status = v.value;
    const params = new URLSearchParams(newParams);
    setPagination({...pagination, pageIndex: 0})
    history.push({search: params.toString()})
  }

  return (
      <Flex ml={'8px'}>
        <SelectInput
          onChange={(v) => handleChange(v)}
          value={data?.options?.find( opt => opt.value == searchParams.status)}
          cache={false}
          isLoading={isLoading || isFetching}
          options={data?.options}
          placeholder={formatMessage({id: 'requestListPage.requestList.header.status', defaultMessage: 'Status'})}
          isDisabled={false}
          isClearable={false}
          isMulti={false}
          isSearchable={false}
          menuPortalTarget={document.body}
          styles={ filterSelectStyles }
          onBlurResetsInput={false}
          onSelectResetsInput={false}
        />
      </Flex>
  )
}
/************
 *
 * Components
 *
 /************/

const RequestListHeader = ({messages}) => {

  useEffect(()=>{
    return () => {};
  }, [])

  return (
    <StyledRequestHeader>
    <div>{toUpper(messages['requestListPage.requestList.header.type'])}</div>
      <div>{toUpper(messages['requestListPage.requestList.header.patient'])}</div>
      <div>{toUpper(messages['requestListPage.requestList.header.sent_at'])}</div>
      <div>{toUpper(messages['requestListPage.requestList.header.remark'])}</div>
      <Flex>
        <Flex as={'span'} mr={'8px'}>{toUpper(messages['requestListPage.requestList.header.status'])}</Flex>
        <StatusFilterComponent 

        />
      </Flex>
    </StyledRequestHeader>
  )
}

const RequestItem = ({request, formattedName, locale}) => {
  const intlMessages = useSelector(getIntlMessages);
  const isStaff = useSelector(getProfileIsStaff);
  const givenName = request.patient && request.patient?.first_name;
  //const familyName = request.patient && request.patient?.last_name;
  //const patientUid = request.patient && request.patient?.unique_id;
  const name = formattedName.replace("{givenName}", givenName).replace("{familyName}", "");
  let age = null;

  //if (request.patient?.date_of_birth){
  //  age = moment().diff(request.patient?.date_of_birth , 'years');
  //}

  // some reports have status 0, but sent_at has a date, so they should be marked as completed
  let requestStatus = request?.report?.status;
  if (request?.report?.sent_at != null || request?.status == "COMPLETE"){
    requestStatus = 3;
  } else if (request?.report === null){
    requestStatus = 0;
  }
  const toUrl = URLS.reportRequestPage.url.replace('{requestId}',request.unique_id)
  //let toPatientUrl = URLS.patientPage.url.replace('{patientId}',patientUid)
  //let toSearch = '';

  if (request.status == 'PENDING' || request.status == 'INIT'){
    //toSearch = `?rid=${request.unique_id}&mdl=task.${request.type}`;
  }

  let typeString = intlMessages[`openRequest.item.type.${request.type}`]
  if ((request?.type || "").indexOf("DMFT") !== -1){
    typeString = "DMFT"
  }
  
  if (request.status == 'CANCELED'){
    return (
     <UnstyledItemLink to={null}>
       <StyledRequestItem>
         <div>{intlMessages[`openRequest.item.type.${request.type}`]}{isStaff && ` (${request.country})`}</div>
         <div>{`${request.patient && name} ${age ? "("+age+")" : ""}` }</div>
         <div>{localeFormatDate(request.created_at, locale)}</div>
         <OverflowTextContainer>{request.remark}</OverflowTextContainer>
         <div className={`${toUpper(request.status)} status`}>{intlMessages[`requestListPage.requestList.status.${
                                 isStaff ? 'admin' : 'dent'
                               }.${
                                   -1 // will be undefined if no report has been started
                               }`]}
         </div>
       </StyledRequestItem>
     </UnstyledItemLink>
    ) 
   } else {
    return (
      <UnstyledItemLink to={toUrl}>
        <StyledRequestItem>
          <div>{typeString}{isStaff && ` (${request.country})`}</div>
          <div>{`${request.patient && name} ${age ? "("+age+")" : ""}` }</div>
          <div>{localeFormatDate(request.created_at, locale)}</div>
          <OverflowTextContainer>{request.remark}</OverflowTextContainer>
          <div className={`${toUpper(request.status)} status`}>{intlMessages[`requestListPage.requestList.status.${
                                  isStaff ? 'admin' : 'dent'
                                }.${
                                  requestStatus// will be undefined if no report has been started
                                }`]}
          </div>
        </StyledRequestItem>
      </UnstyledItemLink>
    )

   }
 
}


const RequestListEmpty = () => {
  const intlMessages = useSelector(getIntlMessages);


  return (
      <Flex direction={'column'} align={'center'} mt={10}>
        <MdPlaylistAddCheck size={42} fill={'#7A8F99'} />
        <Text mt={3}>{intlMessages['requestDetailPage.requestTasks.heading.noTasks.label']}</Text>
      </Flex>
  )
}

export const LoadMore = () => {
  const dispatch = useDispatch();
  const intlMessages = useSelector(getIntlMessages);
  const isLoading = useSelector(getLoading);
  const isLoadingMore = useSelector(getIsLoadingMore);
  const queryParamsOffset = useSelector(getQueryParamsOffset);
  const filterBy = useSelector(getFilterBy);
  const isLoadMore = useSelector(getisLoadMore);

  useEffect(()=>{
    return () => {};
  }, [])

  const fetchMore = () => {
    dispatch(actions.getRequestsMore(filterBy, queryParamsOffset+1))
  }

  if (isLoadingMore){
    return (
      <Flex
        justify={'center'} 
        align={'center'} 
        width={'100%'}
      >
        <Spinner 
          size='xl'
          thickness='4px'
          speed='0.65s' 
          color='#44C5A6' 
        />
      </Flex>
    )
  } else if (isLoadMore && !isLoading){
    return (
        <Flex 
          justify={'center'} 
          align={'center'} 
          width={'100%'}
        >
          <Button 
            leftIcon={<MdOutlineExpandMore size={24} />} 
            mt={4} 
            fontSize={14} 
            variant="solid" 
            colorScheme={'bdBlue'}
            onClick={() => fetchMore()}
          >
            {toUpper(intlMessages['requestListPage.requestList.loadmore'])}
          </Button>
        </Flex>
    )
  } else {
    return (
      <></>
    )  
  }

}

/************
 *
 * Page
 *
 /************/


 const Pagination = ({canPreviousPage, canNextPage, previousPage, nextPage, setPageIndex, pageIndex, total, onScrollToTop}) => {
  const handlePreviousPageClick = () => {
      previousPage();
      onScrollToTop();
  }

  const handleNextPageClick = () => {
      nextPage();
      onScrollToTop();
  }

  const handlePageChange = (e) => {
      const page = e.target.value ? Number(e.target.value) : 0
      setPageIndex(page)
      onScrollToTop();
  }
  
  return (
      <HStack spacing={3} >
          <IconButton 
              icon={<PaginationPrevious  />} 
              isDisabled={!canPreviousPage}
              onClick={handlePreviousPageClick}
              borderRadius={'5px'}
          />
          <ChakraSelect 
              onChange={handlePageChange}
              isDisabled={total < 2}
              
          >
              {Array.from({length: total}, (_, i) => (
                  <option key={i} value={i} selected={pageIndex == i}>
                      {i + 1}
                  </option>
              ))}
          </ChakraSelect>
          <IconButton 
              icon={<PaginationNext />} 
              isDisabled={!canNextPage}
              onClick={handleNextPageClick}
              borderRadius={'5px'}
          />
      </HStack>
  )
}


const StyledStatus = styled.div`
  .status{    
      &:after {content:'';display:inline-block;width:8px;height:8px;border-radius:10px;vertical-align:middle;margin-left:7px;margin-right:12px;}
      &.PENDING::after{background-color:#FFA05C}
      &.COMPLETE::after{background-color:#35BE9D}
      &.COMPLETED::after{background-color:#35BE9D}
  }
`;

const statusAccessorFn = ({row}) => {
  const request = row.original;
  const { formatMessage } = useIntl();
  const isStaff = useSelector(getProfileIsStaff);  
  let requestStatus = request?.report?.status;
  if (request?.report?.sent_at != null || request?.status == "COMPLETE"){
    requestStatus = 3;
  } else if (request?.report === null){
    requestStatus = 0;
  }
  return (
    <StyledStatus>
      <div className={`${toUpper(request?.status)} status`}>{formatMessage({id: `requestListPage.requestList.status.${
          isStaff ? 'admin' : 'dent'
        }.${
          requestStatus
        }`})}
      </div>
    </StyledStatus>
  )
}


const PageTable = ({data, searchParams, isLoading, pagination, setPagination, onScrollToTop}) => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const intlLocale = useSelector(getIntlLocale);

  const StatusHeader = () => {
    return (
      <Flex align={'center'}>
        <FormattedMessage 
            id={'requestListPage.requestList.header.status'}
            defaultMessage='Status'
          />
        <StatusFilterComponent 
          searchParams={searchParams}
          pagination={pagination}
          setPagination={setPagination}
        />
      </Flex>
    )
  }

  const columns = React.useMemo(
      () => [
          {
              header: formatMessage({id: 'requestListPage.requestList.header.type', defaultMessage: 'Type'}),
              id: 'type',
              accessorKey: 'type',
          },
          {
              header: formatMessage({id: 'requestListPage.requestList.header.patient', defaultMessage: 'Patient Name'}),
              id: 'name',
              accessorFn: row => formatMessage(
                {id: "format.fullName", defaultMessage: "{givenName} {familyName}" },
                {givenName: row.patient?.first_name, familyName: row.patient?.last_name}
              ),
          },
          {
            header: formatMessage({id: 'requestListPage.requestList.header.sent_at', defaultMessage: 'Received Date'}),
              id: 'created_at',
              accessorFn: row => localeFormatDate(row.created_at, intlLocale)
          },
          {
            header: formatMessage({id: 'requestListPage.requestList.header.remark', defaultMessage: 'Comment'}),
              id: 'comment',
              accessorKey: 'remark',
          },
          {
              header: <StatusHeader />,
              //header: formatMessage({id: 'requestListPage.requestList.header.status', defaultMessage: 'Status'}),
              id: 'status',
              cell: statusAccessorFn
          }
      ],
      []
  )

  const defaultData = React.useMemo(() => [], [])

  const table = useReactTable({
      data: data?.request_list || defaultData,
      columns,
      state: {
          pagination
      },
      onPaginationChange: setPagination,
      rowCount: data?.request_count,
      getCoreRowModel: getCoreRowModel(),
      manualPagination: true,
      debugTable: true
  })

  const handleRowClick = (row) => {
      history.push(URLS.reportRequestPage.url.replace('{requestId}',row?.unique_id), {'previousLocation': {search: searchParams}})
  }

  return (
      <>
        <TableContainer>
          <Table>
              <Thead>
                  {(table.getHeaderGroups() || []).map(headerGroup => (
                      <Tr key={headerGroup.id}>
                          {headerGroup.headers.map(header => (
                              <Th key={header.id} colSpan={header.colSpan} fontSize={'14px'}>
                                  {flexRender(
                                      header.column.columnDef.header,
                                      header.getContext()
                                  )}
                              </Th>
                          ))}
                      </Tr>
                  ))}
              </Thead>
              <Tbody>
                   {isLoading && (
                   <Flex pos={'absolute'} w={'full'} h={'full'} py={'50px'} align={'flex-start'} justify={'center'} bg={'rgba(255, 255, 255, 0.6)'}>
                      <Spinner 
                        size='xl'
                        thickness='4px'
                        speed='0.65s' 
                        color='#44C5A6' 
                      />
                    </Flex>)}
                  {table.getRowModel().rows.map(row => {
                    return (
                      <Tr 
                        key={row.id} 
                        onClick={() => handleRowClick(row.original)} 
                        _hover={{cursor: 'pointer', bg: '#F7F7F7'}}
                      >
                          {row.getVisibleCells().map(cell => {
                          return (
                              <Td 
                                key={cell.id}
                                py={'30px'}
                              >
                                  {flexRender(
                                      cell.column.columnDef.cell,
                                      cell.getContext()
                                  )}
                              </Td>
                          )
                          })}
                      </Tr>
                      )
                  })}
              </Tbody>
          </Table>
        </TableContainer>
        <Flex w="full" mt={'20px'} justify={'flex-end'}>
            <Pagination 
                onScrollToTop={onScrollToTop}
                isLoading={isLoading}
                canPreviousPage={table.getCanPreviousPage()}
                canNextPage={table.getCanNextPage()}
                pageIndex={table.getState().pagination.pageIndex}
                total={table.getPageCount()}
                nextPage={table.nextPage}
                previousPage={table.previousPage}
                setPageIndex={table.setPageIndex}
            />
        </Flex>
      </>
  )
}

const NewRequestModal = ({
  isOpen,
  onClose,
  onSuccess
}) => {

  const handleOnSuccess = () => {
      onSuccess();
      onClose();
  }
  return (
      <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} isCentered>
        <ModalOverlay bg="blackAlpha.300" />
        <ModalContent minW="700px" bg="#f7f9fa">
          <ModalHeader>
            <Center>
                  <FormattedMessage 
                      id={'adminPage.createPatientRequestFormModal.title'}
                      defaultMessage={'Add New Patient Request'}
                  />
            </Center>
          </ModalHeader>
          <ModalCloseButton/>
          <ModalBody>
              <Heading as={'h2'}>
                  <FormattedMessage 
                      id={'adminPage.createPatientRequestForm.title'}
                      defaultMessage={'Patient Request'}
                  />
              </Heading>
            <Box>
              <RequestForm
                onSuccess={handleOnSuccess}
                onClose={onClose}
              />
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
  )
}

 export const RequestListPage = () => {
  const { user } = useAuth();
  const location = useLocation();
  const searchParams = useSearchParamsObject();
  const { isOpen: isNewRequestOpen, onOpen: onNewRequestOpen, onClose: onNewRequestClose } = useDisclosure();
  const { isOpen: isNeedClinicOpen, onOpen: onNeedClinicOpen, onClose: onNeedClinicClose } = useDisclosure();
  const clinic = useAppConfig(state => state.clinic?.id); // current selected clinic from app config
  const intlLocale = useSelector(getIntlLocale)
  const intlMessages = useSelector(getIntlMessages)
  const PAGE_SIZE = 20;
  const [pagination, setPagination] = React.useState({
    pageIndex: 0,
    pageSize: PAGE_SIZE,
  })
  const scrollRef = React.useRef(null);
  const { data, isLoading, isFetching, refetch } = useRequestList({...searchParams, clinic, offset: pagination.pageIndex, pg_sz: pagination.pageSize });

  useEffect(()=>{
    return () => {};
  }, [])

  const defaultData = React.useMemo(() => [], [])

  // eslint-disable-next-line no-unused-vars
  const renderList = () => {
    if (data?.request_count > 0){
      return (
        <StyledRequestListContainer>
          <RequestListHeader messages={intlMessages} />
          {(isLoading || isFetching) && 
                <Spinner 
                    size='xl'
                    thickness='4px'
                    speed='0.65s' 
                    color='#44C5A6'
                />
          }
          { (data?.request_list || defaultData).map( (request) =>
            (<RequestItem
              key={request.id}
              request={request}
              formattedName={intlMessages['format.fullName']}
              locale={intlLocale}
              location={location}
            />)
          )}
          <LoadMore />
          </StyledRequestListContainer>
        )
    } else {
      return (
        <StyledRequestListContainer>
          <RequestListHeader messages={intlMessages} />
          <RequestListEmpty />
        </StyledRequestListContainer>
      )
    }
  }

  // TODO add a OpenRequests Page for Admins. so that they can assign
  // eslint-disable-next-line no-unused-vars
  const handleUpdateChildSize = () => {
    if (scrollRef.current){
      scrollRef.current.updateScroll();
    }
  }

  const handleScrollToTop = () => {
    if (scrollRef.current){
      scrollRef.current.scrollTop = 0;
    }
  }

  const handlePageChange = (val) => {
    setPagination(val)
    handleScrollToTop();
  }

  const handleNewRequestClick = () => {
    if (user?.is_staff){
      if (clinic){
        onNewRequestOpen();
      } else {
        onNeedClinicOpen()
      }
    } else {
      onNewRequestOpen();
    }
  }

  return (
    <StyledPage ref={scrollRef}>
      {/*<StyledPageTitle>{startCase(intlMessages['requestListPage.upcoming.title'])}</StyledPageTitle>
      <UpcomingSection />*/}
      <Flex pb={['20px']} justify={'space-between'}>
        <Text 
          fontSize={['30px']}
          color={'#405159'}
        >
          <FormattedMessage 
            id={'requestListPage.header.title.request'}
            defaultMessage='Requests'
          />
        </Text>
        <Flex align={'center'}>
          <NewRequestModal 
            isOpen={isNewRequestOpen}
            onClose={onNewRequestClose}
            onSuccess={refetch}
          />
          <AdminSelectClinicModal
            isOpen={isNeedClinicOpen}
            onClose={onNeedClinicClose}
          />
          <Button 
            variant="outline" 
            onClick={handleNewRequestClick}
          >
            <FormattedMessage 
              id={'requestListPage.header.button.newRequest'}
              defaultMessage='+ New Request'
            />
          </Button>
        </Flex>
      </Flex>
      {/*renderList()*/}
      <PageTable data={data} searchParams={searchParams} isLoading={isLoading || isFetching} pagination={pagination} setPagination={handlePageChange} onScrollToTop={handleScrollToTop} />
    </StyledPage>
  )
}
