import { createStore, applyMiddleware } from 'redux';
//import thunkMiddleware from 'redux-thunk';
import ReduxThunk from 'redux-thunk';
//import { reduxPollingMiddleware } from 'redux-polling';
import { createLogger } from 'redux-logger';
import rootReducer from '../_redux/reducers';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'connected-react-router';
import Pubnub from 'pubnub';
//import {history} from './';
import { createBrowserHistory } from 'history';

// mount it on the Store

// initialize intl state
// internationalization

import localeData from '../_locales/data.json';

export const pubnub = new Pubnub({
  publishKey: process.env.REACT_APP_PUBNUB_PUBLISH_KEY,
  subscribeKey: process.env.REACT_APP_PUBNUB_SUBSCRIBE_KEY,
  // TODO: only use cipherkey to encrypt the messages
  //cipherKey: process.env.REACT_APP_PUBNUB_CIPHER_KEY
});
const thunkArgument = {
  pubnub: {
    api: pubnub,
  },
};
const thunkMiddleware = ReduxThunk.withExtraArgument(thunkArgument);
const loggerMiddleware = createLogger();
// create the saga middleware
const sagaMiddleware = createSagaMiddleware();

// Split locales with a region code
//const languageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0];

export const history = createBrowserHistory();

var browserLanguage = 'en-us';
const availableLanguages = ['ja', 'ja-jp', 'th', 'th-th', 'en', 'en-us'];
var navigatorLanguages = navigator.languages.map((elem) => {
  return elem.toLowerCase();
});
if (availableLanguages.indexOf(navigator.language.toLowerCase()) != -1) {
  browserLanguage = navigator.language;
} else {
  for (var lang in navigatorLanguages) {
    if (availableLanguages.indexOf(navigatorLanguages[lang]) != -1) {
      browserLanguage = navigatorLanguages[lang];
      break;
    }
  }
}
var localStorageLanguage = localStorage.getItem('language');
if (availableLanguages.indexOf(localStorageLanguage) != -1) {
  browserLanguage = localStorageLanguage;
}

// convert browser language into language
if (browserLanguage.includes('-')) {
  browserLanguage = browserLanguage.split('-', 1)[0];
}

export const getIntlMessagesWithDefault = (lang) => {
  if (lang == 'ja' || lang == 'th') {
    return localeData[lang];
  } else {
    return localeData['en'];
  }
  /*return (lang == 'en')
    ? localeData[browserLanguage]
    : Object.keys(localeData['en']).reduce((curr, key) => {
      return Object.assign(curr, { [key] : localeData[browserLanguage][key] || localeData['en'][key]})
    })*/
};

const initialIntlState = {
  intl: {
    locale: browserLanguage,
    messages: localeData[browserLanguage],
  },
};

export const store = createStore(
  rootReducer(history),
  initialIntlState,
  applyMiddleware(
    routerMiddleware(history), // for dispatching history actions
    thunkMiddleware,
    loggerMiddleware,
    sagaMiddleware
  )
);

//run the saga
//sagaMiddleware.run(rootSaga)
