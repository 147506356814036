const PREFIX = 'PATIENTREPORTS_';
export const constants = {
  FETCHREPORT_REQUEST: `${PREFIX}FETCHREPORT_REQUEST`,
  FETCHREPORT_SUCCESS: `${PREFIX}FETCHREPORT_SUCCESS`,
  FETCHREPORT_FAILURE: `${PREFIX}FETCHREPORT_FAILURE`,
  UPDATEREPORT_REQUEST: `${PREFIX}UPDATEREPORT_REQUEST`,
  UPDATEREPORT_SUCCESS: `${PREFIX}UPDATEREPORT_SUCCESS`,
  UPDATEREPORT_FAILURE: `${PREFIX}UPDATEREPORT_FAILURE`,
  CREATEREPORT_REQUEST: `${PREFIX}CREATEREPORT_REQUEST`,
  CREATEREPORT_SUCCESS: `${PREFIX}CREATEREPORT_SUCCESS`,
  CREATEREPORT_FAILURE: `${PREFIX}CREATEREPORT_FAILURE`,
  ADD_REPORTCHART: `${PREFIX}ADD_REPORTCHART`,
  UPDATE_REPORTCHART: `${PREFIX}UPDATE_REPORTCHART`,
  REMOVE_REPORTCHART: `${PREFIX}REMOVE_REPORTCHART`,
  UPDATE_REPORT: `${PREFIX}UPDATE_REPORT`,
  UPDATE: `${PREFIX}UPDATE`,
};
