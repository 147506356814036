import React from 'react';
import {
    Flex, Menu, MenuButton, Heading, MenuList, MenuItem,
} from '@chakra-ui/react';
import { FormattedMessage, useIntl } from 'react-intl';
import { SelectChevronDown } from 'src/_images/icons/SelectChevronDown';
import { useHistory } from 'react-router';

export const HeaderMenu = ({page}) => {
    const history = useHistory();
    const { formatMessage } = useIntl(); 
    const adminPageStrings =  {
        dentists: formatMessage({id: "admin.header.menuitem.dentists", defaultMessage: "Dentists"}),
        clinics: formatMessage({id: "admin.header.menuitem.clinics", defaultMessage: "Clinics"}),
        userRequests: formatMessage({id: "admin.header.menuitem.userRequests", defaultMessage: "User Requests"}),
        patientUsers: formatMessage({id: "admin.header.menuitem.patientUsers", defaultMessage: "Patient Users"}),
    }
    return (
        <Menu>
            <MenuButton>
                <Flex align={'center'}>
                    <Heading as={'h1'} mr={'5px'} grow={1} whiteSpace={'nowrap'}>
                        {adminPageStrings[page]}
                    </Heading>
                    <SelectChevronDown />
                </Flex>
            </MenuButton>
            <MenuList>
                <MenuItem  isDisabled={page == "clinics"}  onClick={() => history.push("/adm/collections/clinics")}>
                    <FormattedMessage
                        id="admin.header.menuitem.clinics"
                        defaultMessage="Clinics"
                    />
                </MenuItem>
                <MenuItem isDisabled={page == "dentists"}  onClick={() => history.push("/adm/collections/dentists")}>
                    <FormattedMessage
                        id="admin.header.menuitem.dentists"
                        defaultMessage="Dentists"
                    />
                </MenuItem>
                <MenuItem isDisabled={page == "patientUsers"}  onClick={() => history.push("/adm/collections/patientUsers")}>
                    <FormattedMessage
                        id="admin.header.menuitem.patientUsers"
                        defaultMessage="Patient Users"
                    />
                </MenuItem>
                <MenuItem  isDisabled={page == "userRequests"}  onClick={() => history.push("/adm/collections/userRequests")}>
                    <FormattedMessage
                        id="admin.header.menuitem.userRequests"
                        defaultMessage="User Requests"
                    />
                </MenuItem>
            </MenuList>
      </Menu>
    )
}