import { constants } from './constants';

export const actions = {
  updateAppointmentsMany,
  updateAppointment,
  updateAppointmentExtraData,
};

function updateAppointmentsMany(data) {
  return (dispatch) => {
    dispatch(update(data));
  };
  function update(data) {
    return { type: constants.UPDATE_APPOINTMENTS_MANY, data };
  }
}

function updateAppointment(id, data) {
  return (dispatch) => {
    dispatch(update(id, data));
  };
  function update(id) {
    return { type: constants.UPDATE_APPOINTMENT, data: { id, data } };
  }
}

function updateAppointmentExtraData(id, data) {
  return (dispatch) => {
    dispatch(update(id, data));
  };
  function update(id, data) {
    return { type: constants.UPDATE_APPOINTMENT_EXTRADATA, data: { id, extraData: data } };
  }
}
