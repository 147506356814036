import { consoleLog } from '../../_helpers/consoleLog';
import { toUpper } from 'lodash';

import { constants } from './constants';
import { services } from './services';

//import { data as testData } from './_testData';

export const actions = {
  fetchRequests,
  fetchRequestsMore,
  //sortRequests,
  //filterRequests,
  updateOpenRequest,
  updateOpenRequestStatus,
};

function fetchRequests(type, init = 0) {
  return (dispatch) => {
    dispatch(request());
    const currentOffset = init;
    let requestType = toUpper(type) === 'APPOINTMENTS' ? 'APPOINTMENT' : 'REPORT';
    services.fetchRequests('', requestType, currentOffset).then(
      (data) => dispatch(success(data)),
      (error) => dispatch(failure(error))
    );
  };
  function request() {
    return { type: constants.FETCH_REQUESTS };
  }
  function success(data) {
    const byId = {};
    (data?.request_list || []).map((entry) => {
      //testData.request_list.map((entry, index) => {
      byId[entry.id] = entry;
    });
    delete data['request_list'];
    return { type: constants.FETCH_REQUESTS_SUCCESS, data: { ...data, byId } };
  }
  function failure(error) {
    return { type: constants.FETCH_REQUESTS_ERROR, error };
  }
}

function fetchRequestsMore(type, offset) {
  return (dispatch) => {
    dispatch(request());
    let requestType = toUpper(type) === 'APPOINTMENTS' ? 'APPOINTMENT' : 'REPORT';
    services.fetchRequests('', requestType, offset).then(
      (data) => dispatch(success(data)),
      (error) => dispatch(failure(error))
    );
  };
  function request() {
    return { type: constants.FETCH_REQUESTSMORE };
  }
  function success(data) {
    const byId = {};
    (data?.request_list || []).map((entry) => {
      //testData.request_list.map((entry, index) => {
      byId[entry.id] = entry;
    });
    delete data['request_list'];
    return { type: constants.FETCH_REQUESTSMORE_SUCCESS, data: { ...data, byId } };
  }
  function failure(error) {
    return { type: constants.FETCH_REQUESTSMORE_ERROR, error };
  }
}

function updateOpenRequest(req) {
  return (dispatch) => {
    dispatch(update(req));
  };
  function update(data) {
    return { type: constants.UPDATE_REQUEST, data };
  }
}

function updateOpenRequestStatus(id, status) {
  return (dispatch) => {
    consoleLog('updat open request status', id, status);
    dispatch(update({ id, status }));
  };
  function update(data) {
    return { type: constants.UPDATE_REQUESTSTATUS, data };
  }
}
