import styled from 'styled-components';

export const StyledBox = styled.div`
  ${(props) => props.addCss}
`;

export const FlexRow = styled.div`
  display: flex;
  align-items: center;
  ${(props) => props.addCss}
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 1 auto;
  padding-bottom: 30px;
  ${(props) => props.addCss}
`;

export const ScrollView = styled.div`
  background-color: rgb(247, 249, 250);
  height: 100%;
  overflow-y: auto;
  padding-left: 50px;
  ${(props) => props.addCss}
`;

export const StyledTextArea = styled.textarea`
  overflow: auto;
  padding: 10px;
  color: #12171a;
  resize: none;
  font-family: Proxima Nova;
  font-size: 15px;
  width: 100%;
  border-radius: 10px;
  /*border:none;*/
  &:focus {
    outline: none;
  }
`;

export const Divider = styled.div`
  /*border-bottom: 1px solid #DADADA;
  margin: 10px;*/
`;

export const StyledMessage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 1 auto;
  padding-bottom: 30px;
  align-items: flex-end;
  text-align: left;
  margin-right: ${(props) => (props.senderMe ? '20px' : '0px')};

  > .messageContent {
    display: flex;
    justify-content: ${(props) => (props.senderMe ? 'flex-end' : 'flex-start')};
    align-items: flex-end;
    width: 100%;

    > .messageTime {
      order: ${(props) => (props.senderMe ? '1' : '2')};
      margin: 0px 10px;
    }

    > .messageText {
      max-width: 60%;
      align-items: right;
      border-radius: 30px;
      background-color: ${(props) => (props.senderMe ? '#273238' : '#F0F3F5')};
      color: ${(props) => (props.senderMe ? '#FFFFFF' : '#12171A')};
      order: ${(props) => (props.senderMe ? '2' : '1')};
      padding: 15px 25px;
    }
  }
`;

export const StyedChatHeader = styled(StyledBox)`
  font-size: 24px;
  font-weight: 700;
  padding: 15px;
  background-color: rgb(247, 249, 250);
`;
