import { constants } from './constants';
import { services } from './services';

//import { dataRequestList } from './_testData'

export const actions = {
  fetchAppointments,
  //sortRequests,
  //filterRequests,
  createAppointment,
  addAppointmentState,
  updateAppointmentState,
  updateCurrentSession,
  updateCurrentSessionId,
  updateCurrentSessionRequestId,
};

function createAppointment(data) {
  return (dispatch) => {
    dispatch(request());
    services.createAppointment(data).then(
      (data) => dispatch(success(data)),
      (error) => dispatch(failure(error))
    );
  };
  function request() {
    return { type: constants.CREATE_APPOINTMENT_REQUEST };
  }
  function success(data) {
    return { type: constants.CREATE_APPOINTMENT_SUCCESS, data: { id: data.unique_id, data } };
  }
  function failure(error) {
    // CREATE_APPOINTMENT_ERROR
    return { type: constants.CREATE_APPOINTMENT_ERROR, data: { error } };
  }
}

function fetchAppointments(data) {
  return (dispatch) => {
    dispatch(request());
    services.fetchAppointments(data).then(
      (data) => dispatch(success(data)),
      (error) => dispatch(failure(error))
    );
  };
  function request() {
    return { type: constants.FETCH_APPOINTMENTS };
  }
  function success(data) {
    const byId = {};
    //dataRequestList.request_list.map((entry, index) => {
    data.appointment_list &&
      data.appointment_list.map((entry) => {
        byId[entry.unique_id] = entry;
      });
    return { type: constants.FETCH_APPOINTMENTS_SUCCESS, data: { isMore: data.is_more, byId } };
  }
  function failure(error) {
    return { type: constants.FETCH_APPOINTMENTS_ERROR, error };
  }
}

function addAppointmentState(data) {
  return (dispatch) => {
    dispatch(update(data));
  };
  function update(data) {
    return { type: constants.ADDAPPOINTMENT_STATE, data };
  }
}

function updateAppointmentState(data) {
  return (dispatch) => {
    dispatch(update(data));
  };
  function update(data) {
    return { type: constants.UPDATE_APPOINTMENTSTATE, data };
  }
}

function updateCurrentSessionId(sessionId) {
  return (dispatch) => {
    dispatch(update(sessionId));
  };
  function update(data) {
    return { type: constants.UPDATE_CURRENT_SESSION_ID, data };
  }
}

function updateCurrentSessionRequestId(requestId) {
  return (dispatch) => {
    dispatch(update(requestId));
  };
  function update(data) {
    return { type: constants.UPDATE_CURRENT_SESSION_REQUEST_ID, data };
  }
}

function updateCurrentSession(sessionId, requestId) {
  return (dispatch) => {
    dispatch(update({ sessionId, requestId }));
  };
  function update(data) {
    return { type: constants.UPDATE_CURRENT_SESSION, data };
  }
}
