import { constants } from './constants';
import { services } from './services';

export const actions = {
  getRequest,
  updateCurrentPatientUuid,
  update,
  updateState,
  updateStateObject,
  openReportModal,
  closeReportModal,
  clearCurrentRequest,
  setCurrentMedia,
  openRecordDetailModal,
  closeRecordDetailModal,
  openAppointmentCompleteModal,
  closeAppointmentCompleteModal,
  openPatientNotesModal,
  closePatientNotesModal,
};

function setCurrentMedia(id) {
  return (dispatch) => {
    dispatch(update(id));
  };
  function update(id) {
    return { type: constants.UPDATE_SELECTED_MEDIA, data: { id } };
    //return { type: constants.UPDATE, data: {currentSelectedMedia: id}}
  }
}

function openAppointmentCompleteModal() {
  return (dispatch) => {
    dispatch(open());
  };
  function open() {
    return { type: constants.UPDATE, data: { appointmentCompleteModal: true } };
  }
}

function closeAppointmentCompleteModal() {
  return (dispatch) => {
    dispatch(close());
  };
  function close() {
    return { type: constants.UPDATE, data: { appointmentCompleteModal: false } };
  }
}

function openPatientNotesModal() {
  return (dispatch) => {
    dispatch(open());
  };
  function open() {
    return { type: constants.UPDATE, data: { patientNotesModal: true } };
  }
}

function closePatientNotesModal() {
  return (dispatch) => {
    dispatch(close());
  };
  function close() {
    return { type: constants.UPDATE, data: { patientNotesModal: false } };
  }
}

function clearCurrentRequest() {
  return (dispatch) => {
    dispatch(clear());
  };
  function clear() {
    return { type: constants.CLEAR_CURRENTREQUEST };
  }
}

function updateCurrentPatientUuid(uuid) {
  return (dispatch) => {
    dispatch(update(uuid));
  };
  function update(uuid) {
    return { type: constants.UPDATE, data: { currentPatientUuid: uuid } };
  }
}

function openReportModal() {
  return (dispatch) => {
    dispatch(open());
  };
  function open() {
    return { type: constants.UPDATE, data: { reportModal: true } };
  }
}

function closeReportModal() {
  return (dispatch) => {
    dispatch(close());
  };
  function close() {
    return { type: constants.UPDATE, data: { reportModal: false } };
  }
}

function openRecordDetailModal() {
  return (dispatch) => {
    dispatch(open());
  };
  function open() {
    return { type: constants.UPDATE, data: { recordDetailModal: true } };
  }
}

function closeRecordDetailModal() {
  return (dispatch) => {
    dispatch(close());
  };
  function close() {
    return { type: constants.UPDATE, data: { recordDetailModal: false } };
  }
}

function getRequest(id) {
  return (dispatch) => {
    dispatch(request());
    services.getRequest(id).then(
      (data) => dispatch(success(data)),
      (error) => dispatch(failure(error))
    );
  };
  function request() {
    return { type: constants.GETREQUEST_REQUEST };
  }
  function success(data) {
    // breakdown reports...
    const { request } = data;
    // TODO mkae this a model? move to utils to normalize json data
    data = { patient: request.patient, requestDetails: request };
    return { type: constants.GETREQUEST_SUCCESS, data };
  }
  function failure(error) {
    return { type: constants.GETREQUEST_FAILURE, error };
  }
}

function update(data) {
  return (dispatch) => {
    dispatch(update(data));
  };
  function update(data) {
    return { type: constants.UPDATE, data };
  }
}

function updateState(key, value) {
  return (dispatch) => {
    dispatch(update({ key, value }));
  };
  function update(data) {
    return { type: constants.UPDATE_STATE, data };
  }
}

function updateStateObject(key, value) {
  return (dispatch) => {
    dispatch(update({ key, value }));
  };
  function update(data) {
    return { type: constants.UPDATE_STATE_OBJECT, data };
  }
}
