import React, {useEffect, useState} from 'react';
import {  useSelector, useDispatch  } from 'react-redux';
import { toUpper,  } from 'lodash';
import styled from 'styled-components';
import Modal from 'react-modal';

import { useIntl } from 'react-intl';

import AsyncSelect from 'react-select/async';

import { localeFormatDate } from '../_helpers';

// redux
import { actions } from './_redux/actions';
import { services } from './_redux/services';
import { getNewPatientFormOpen } from './_redux/selectors';


// components
import { CloseIcon as CloseSvg } from '../_images/icons/CloseIcon';
import { PatientForm } from './PatientForm';

// selectors
import { getIntlLocale, getIntlMessages} from '../App/_redux/selectors';

//styles
import {
  generalModalStyles,
  ModalCloseIcon
} from '../_css/styles';

const ModalStyles = styled.div`
  position: relative;
  height: 100%;
  padding: 0px;
  padding-bottom: 30px;
  border-radius: 10px;

  ${ModalCloseIcon} {
    margin-top: 30px;
    margin-right: 30px; 
  }

`;

const Styles = styled.div`
  display:flex;
  width:100%;

  .inputContainer{
    width:100%;
  }
`;

class FormSearchInput extends React.Component{
	render () {
		return (
				<AsyncSelect
					{...this.props}
          autoload={true}
					multi={false}
					backspaceRemoves={true}
				/>
		);
	}
}

const StyledOption = styled.div`
  display: flex;
  padding: 10px 10px;
  align-items: center;
  &:hover{

  }
  > div{
  }
  .name{
    font-family: 'Proxima Nova Semibold';
    font-size: 18px;
    margin-right: 20px;
  }
  .label{
    margin-right: 5px;
    color: #7A8F99;
  }
  .value{
    margin-right: 10px;
    letter-spacing: 1.1px;
    color: black;
  }
  .birthDate, .gender, .splitter{
    font-family: 'Proxima Nova';
    font-size: 14px;
  }
`;

const newPatientValue = {value: "0", label: ''}

const formatOptionLabel = ({value, label}) => {
  let labels = (label || "").split(",")
  if (value == null){
    return (<div></div>)
  }
  if ((value && value.length == 0) || value == '0' || value == 0){
    return(
      <StyledOption>
        <div className={'name'}>{'Create new patient'}</div>
        <div className={'label name'}>{label}</div>
      </StyledOption>
    )
  } else {
    return (
      <StyledOption>
        <div className={'name'}>{labels[0]}</div>
        <div className={'label birthDate'}>{'Date of Birth'}</div><div className={'value birthDate'}>{labels[1]}</div>
        <div className={'splitter'}>{""}</div>
        <div className={'label gender'}>{'Gender'}</div><div className={'value gender'}>{labels[2]}</div>
      </StyledOption>
    )
  }
}

const PatientSearchAdapter = ({ error, className, disabled, loadOptions, onInputChange, onChange, onValueClick, value, ...rest }) => {

  return (
      <div className={className}>
        <FormSearchInput
          {...rest}
          className={className}
          loadOptions={loadOptions}
          value={value}
          onChange={inputValue => onChange(inputValue || {value: "-1", label: ""})}
          onValueClick={onValueClick}
          autoload={true}
          cache={false}
          disabled={disabled}
          onInputChange={onInputChange}
          isClearable={true}
          formatOptionLabel={formatOptionLabel}
          styles={{
            dropdownIndicator: (provided) => {
              const display = 'none';
              return { ...provided, display };
            },
            indicatorSeparator:  (provided) => {
              const display = 'none';
              return { ...provided, display };
            },
            control: (provided) => {
              const borderColor = error ? 'red' : provided.borderColor;
              return { ...provided, borderColor }
            }
          }}
          onBlurResetsInput={false}
          onSelectResetsInput={false}
        />
      </div>
  )
}

const modalStyles = {
  ...generalModalStyles,
};

export const NewPatientFormModal = ({initValues, onCreated}) => {
  const dispatch = useDispatch();
  const modalIsOpen = useSelector(getNewPatientFormOpen);

  const afterOpenModal = () => {
    /* all refs are set*/
  }

  const closeModal = () => {
    dispatch(actions.closeNewPatientFormModal())
  }

  const handleOnCreated = (data) => {
    dispatch(actions.closeNewPatientFormModal());
    onCreated(data)
  }

  return (
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={modalStyles}
      shouldCloseOnOverlayClick={false}
      contentLabel="Create Patient Form"
      >
      <ModalStyles>
        <ModalCloseIcon onClick={closeModal} ><CloseSvg/ ></ModalCloseIcon>
        <PatientForm initValues={initValues} onCreated={handleOnCreated}/>
      </ModalStyles>
    </Modal>
  )
}

export const PatientsSearch = ({defaultValue, onPatientChange, error}) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const intlLocale = useSelector(getIntlLocale);
  const intlMessages = useSelector(getIntlMessages);
  const [patient, setPatient] = useState(defaultValue);
  const [init, setInit] = useState(true);
  const [newPatientData, setNewPatientData] = useState({});

  useEffect(()=>{
    // on initial value
    if (defaultValue && init){
      setPatient(defaultValue)
      setInit(false);
    }
    //setPatient(defaultValue)
  }, [defaultValue])

  const onCreated = (data) => {
    // set patient?
    let genderString = formatMessage(
      {id: `settings.gender.${data.gender}`}
    )
    let newPatientOption = {
      value: `${data.unique_id}`,
      label: `${data.first_name} ${data.last_name}, ${localeFormatDate(data.date_of_birth, intlLocale, "l")}, ${genderString}`
    }
    // TODO: add new patient to patient reducer
    setPatient(newPatientOption);
    onPatientChange(newPatientOption);
  }

  const onChange = (val) => {
    setInit(false);
    if (val.value == '-1'){
      setPatient(null)
      onPatientChange(null)
    } else if (val == null || val.value == '0'){
      let firstName = "";
      let lastName = "";
      let endRegEx = /\s(.*)/;
      let end = null;
      // open paiten form
      if (intlMessages['format.fullName'].split(" ")[0] == "{familyName}"){
        end = endRegEx.exec(val.label);
        firstName = end && end[0];
        lastName = val.label.split(" ", 1)[0];
      } else {
        end = endRegEx.exec(val.label);
        firstName = val.label.split(" ", 1)[0];
        lastName = end && end[0];
      }
      setNewPatientData({
        first_name: firstName || "",
        last_name: lastName || ""
      })
      dispatch(actions.openNewPatientFormModal({}))
    } else {
      setPatient(val);
      onPatientChange(val);
    }
  }

  const onInputChange = () => {
    setInit(false);

  }

  const handlePatientsLoading = (input) => {
    if (init){
      new Promise((resolve) => {
        setTimeout(() => {
          resolve([{...newPatientValue, label: input}]);
        }, 10);
      });
    }
    if (input.length < 2){
      new Promise((resolve) => {
        setTimeout(() => {
          resolve([{...newPatientValue, label: input}]);
        }, 10);
      });
    }
    return services.searchPatients(input)
      .then(
        data => {
          const options = data.patient_list.map((elem) => {
            return {
              value: `${elem.unique_id}`,
              label: `${elem.first_name} ${elem.last_name}, ${localeFormatDate(elem.date_of_birth, intlLocale, "l")}, ${elem.gender}`
            }
          });
          return [{...newPatientValue, label: input}, ...options];
        },
        () => {
          return [{...newPatientValue, label: input}]
        }
      )
  }

  return (
    <Styles>
      <NewPatientFormModal
        initValues={newPatientData}
        onCreated={onCreated}
      />
      <PatientSearchAdapter
        className={'inputContainer'}
        error={error}
        name="patient"
        intlMessages={intlMessages}
        intlLocale={intlLocale}
        value = { patient }
        defaultOptions={[newPatientValue]}
        label={toUpper(intlMessages['patientForm.patient.label'])}
        intl={intlMessages['patientForm.patient.search']}
        placeholder={intlMessages['patientForm.patient.placeholder']}
        onInputChange={onInputChange}
        loadOptions={handlePatientsLoading}
        onChange={onChange}
        />
    </Styles>
  )
}
