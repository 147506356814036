import { constants } from './constants';

const initState = {
  currentRequestId: null,
  currentPatientUuid: null,
  fetching: false,
  loading: false,
  error: '',
  reportModal: false,
  recordDetailModal: false,
  recordViewerModal: false,
  currentSelectedMedia: null,
  appointmentCompleteModal: false,
  patientNotesModal: false,
  appointmentConfirmationModal: false,
};

export function patientDetailPage(state = initState, action) {
  switch (action.type) {
    case constants.CLEAR_CURRENTREQUEST:
      return Object.assign({}, {}, initState);
    case constants.UPDATE_SELECTED_MEDIA:
      return {
        ...state,
        currentSelectedMedia: action.data.id,
      };
    case constants.UPDATE:
      return {
        ...state,
        ...action.data,
      };
    case constants.UPDATE_STATE:
      return {
        ...state,
        [action.data.key]: action.data.value,
      };
    case constants.UPDATE_STATE_OBJECT:
      return {
        ...state,
        [action.data.key]: Object.assign({}, state[action.data.key], action.data.value),
      };
    default:
      return state;
  }
}
