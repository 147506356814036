import { createSelector } from 'reselect';

import {
  getPatientUploadsById,
  getPatientRecordsById,
} from '../../PatientRecords/_redux/selectors';
import { getDetailsRecordId } from '../../PatientDetailsPage/_redux/selectors';

const getMediaSectionSlice = (state) => state.mediaSection;

export const getCurrentImageIndex = createSelector([getMediaSectionSlice], (state) => {
  return state.imageIndex;
});

export const getRequestUploads = createSelector(
  [getDetailsRecordId, getPatientRecordsById],
  (recordId, records) => {
    return records[recordId] ? records[recordId].patientrecord_uploads : [];
  }
);

export const getTotalUploads = createSelector([getRequestUploads], (uploads) => {
  if (uploads) {
    return uploads.length;
  } else {
    return 0;
  }
});

export const getCurrentUpload = createSelector(
  [getRequestUploads, getCurrentImageIndex, getPatientUploadsById, getTotalUploads],
  (recordUploads, currentImageIndex, uploads, totalUploads) => {
    if (totalUploads > 0) {
      return uploads[
        recordUploads[currentImageIndex] ? recordUploads[currentImageIndex].unique_id : null
      ];
    } else {
      return null;
    }
  }
);

export const getCurrentUploadUid = createSelector([getCurrentUpload], (currentUpload) => {
  return currentUpload ? currentUpload.unique_id : null;
});

export const getCurrentUploadId = createSelector([getCurrentUpload], (currentUpload) => {
  return currentUpload ? currentUpload.id : null;
});

export const getCurrentVideoFrames = createSelector(
  [getMediaSectionSlice, getCurrentUploadId],
  (state, uploadId) => {
    return state.videoFrames[uploadId] ? state.videoFrames[uploadId] : [];
  }
);

export const getVideoFrames = createSelector([getMediaSectionSlice], (details) => {
  return details.videoFrames;
});

export const getVideoPlayer = createSelector([getMediaSectionSlice], (state) => {
  return state.videoPlayer;
});

export const getSeekTime = createSelector([getVideoPlayer], (player) => {
  return player.seekTime;
});
