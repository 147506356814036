import { useQuery } from 'react-query';
import { queryConfig } from 'src/_libs/react-query';
import { PAGE_SIZE } from 'src/_config';

import { fetchRequests } from './services';

export const QUERY_KEY_NAME = 'RequestList';

const newQueryConfig = {
    ...queryConfig,
    queries: {
        ...queryConfig.queries,
        refetchOnMount: false
    },
    refetchOnMount: false
}

export const queryRequest = async (queryParams) => {
    // filter through the input params and remove any empty values
    let paramsObj = {}
    for (const [key, val] of Object.entries(queryParams)){
      if (val){
        paramsObj[key] = val
      }
    }
    // format the params object into a URLSearchParams object
    const searchParams = new URLSearchParams(paramsObj)
    return fetchRequests(searchParams)
}

export const useRequestList = ({ query, status="", offset=0, pg_sz=PAGE_SIZE , clinic=null, config={} }) => {

    return useQuery({
        ...newQueryConfig,
        ...config,
        queryKey: [QUERY_KEY_NAME, {query, status, offset, pg_sz, clinic}],
        queryFn: () => queryRequest({query, status, offset, pg_sz, clinic}),
      });
}