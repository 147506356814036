import React, { useRef } from "react";
import { useReactToPrint } from 'react-to-print';
import { FormattedMessage } from "react-intl";
import {
    Text,
    Box,
    Flex,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalCloseButton,
} from "@chakra-ui/react";
import ReportSentIcon from "src/_images/icons/ReportSentIcon";
import { useReportDetails } from "../api/getReport";

import { ReportPrintPreview } from './ReportPrintPreview';


export const ReportSentModal = ({isOpen, onClose, reportUid, requestType}) => {
    const { data, isLoading, isFetching } = useReportDetails({ reportId: reportUid })
    const chartRef = useRef(null);

    const handlePrint = useReactToPrint({
  
      documentTitle: `${data?.report?.sent_at && data?.report?.sent_at.substring(0, 10)} ${data?.report?.patient_name}` ,
      content: () => chartRef.current,
    });
    return (
        <Modal 
            closeOnOverlayClick={false} 
            isOpen={isOpen}
            isCentered={true}
            size={'lg'}
        >
            <ModalOverlay />
            <ModalContent>
                <ModalCloseButton mt={['15px']} onClick={onClose} />
                <ModalHeader 
                    bg={'#fff'} 
                    py={['30px']} 
                    filter={'drop-shadow(0px -2px 15px rgba(0, 0, 0, 0.08))'}
                >
                    <Text 
                        textTransform={'uppercase'}
                        color={'#1217A'} 
                        fontSize={['15px']} 
                        letterSpacing={['1px']} 
                        textAlign={'center'}
                        fontWeight={[400]}
                    >
                        <FormattedMessage
                            id={'reportDetailsPage.reviewReportModalSent.title'}
                            defaultMessage={'Submit Report'}
                        />
                    </Text>
                </ModalHeader>
                <ModalBody py={['30px']} px={['30px']} h={'650px'} textAlign={['center']}>
                    <ReportPrintPreview ref={chartRef} requestType={requestType} data={data} isLoading={isLoading} isFetching={isFetching} />
                    <Text 
                        color={'#273238'} 
                        fontSize={['30px']} 
                        textAlign={'center'}
                        fontWeight={[400]}
                    >
                        <FormattedMessage
                            id={'reportDetailsPage.reviewReportModalSent.body.title'}
                            defaultMessage={'Report Sent!'}
                        />
                    </Text>
                    <Text 
                        mt={['10px']}
                        color={'#405159'} 
                        fontSize={['13px']} 
                        textAlign={'center'}
                        fontWeight={[400]}
                    >
                        <FormattedMessage
                            id={'reportDetailsPage.reviewReportModalSent.body.description'}
                            defaultMessage={'Your report has been sent to the patient.'}
                        />
                    </Text>
                    <ReportSentIcon mt={['30px']} />
                </ModalBody>
                <ModalFooter px={[0]}>
                    <Box w={'full'}>
                        <Flex justify={['center']} py={['15px']} w={'full'}>
                            <Button 
                                variant={'outline'} 
                                mr={['5px']} 
                                size={['lg']} 
                                isLoading={isLoading || isFetching}
                                onClick={handlePrint}
                            >
                                <FormattedMessage
                                    id={'reportDetailsPage.reviewReportModalSent.footer.button.downloadpdf'}
                                    defaultMessage={'Download PDF'}
                                />
                            </Button>
                            <Button 
                                variant={'solid'} 
                                ml={['5px']} 
                                size={['lg']} 
                                onClick={onClose}
                            >
                                <FormattedMessage
                                    id={'reportDetailsPage.reviewReportModalSent.footer.button.close'}
                                    defaultMessage={'Close'}
                                />
                            </Button>
                        </Flex>
                    </Box>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}