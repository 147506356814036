import { useQuery, useQueryClient } from 'react-query';
import { queryConfig, queryClient } from 'src/_libs/react-query';

import { fetchUserRequestDetails } from './services';


export const QUERY_KEY_NAME = 'Admin_UserRequestDetails';

const newQueryConfig = {
    ...queryConfig,
    queries: {
        ...queryConfig.queries,
        refetchOnMount: false
    }
}

export const getQueryData = (requestId) => {
    const queryClient = useQueryClient();
    return queryClient.getQueryData([QUERY_KEY_NAME, {requestId}]);
}

export const queryRequest = async ({ requestId }) => {
    return fetchUserRequestDetails(requestId);
}

export const updateUserRequestDetails = (requestId, newData) => {
    queryClient.setQueryData([QUERY_KEY_NAME, {requestId}], (oldData) => {
        return {
            ...oldData,
            ...newData
        }
    })
}

export const useUserRequestDetails = ({ requestId, config={} }) => {
    return useQuery({
        ...newQueryConfig,
        ...config,
        enabled: !!requestId,
        queryKey: [QUERY_KEY_NAME, {requestId}],
        queryFn: () => queryRequest({requestId}),
      });
}