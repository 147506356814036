import { authHeader, handleResponse } from '../../_helpers';
import { API_URLS } from '../../_config';

export const services = {
  fetchReport,
  fetchReportChart,
  fetchReportUploadChart,
  createReportChart,
  updateReportChart,
  deleteReportChart,
  createRequestPartialScan,
  createReport,
  sendReport,
  updateReport,
  requirePartialScan,
};

function fetchReport(reportId, data) {
  const requestUrl = API_URLS.getDentistRequestReportDetail;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  };
  let url = requestUrl.url.replace('{reportId}', reportId);
  return fetch(url, requestOptions).then(handleResponse);
}

function fetchReportChart(reportId, reportChartId, data) {
  const requestUrl = API_URLS.getDentistRequestReportChartDetail;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  };
  let url = requestUrl.url
    .replace('{reportId}', reportId)
    .replace('{reportChartId}', reportChartId);
  return fetch(url, requestOptions).then(handleResponse);
}

function fetchReportUploadChart(reportId, uploadId, data) {
  const requestUrl = API_URLS.getDentistRequestReportUploadChartDetail;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  };
  let url = requestUrl.url.replace('{reportId}', reportId).replace('{uploadId}', uploadId);
  return fetch(url, requestOptions).then(handleResponse);
}

function createRequestPartialScan(requestId, data) {
  const requestUrl = API_URLS.createRequestPartialScan;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  };
  let url = requestUrl.url.replace('{requestId}', requestId);
  return fetch(url, requestOptions).then(handleResponse);
}

function createReport(requestId, data) {
  const requestUrl = API_URLS.createRequestReport;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  };
  let url = requestUrl.url.replace('{requestId}', requestId);
  return fetch(url, requestOptions).then(handleResponse);
}

function createReportChart(reportId, data) {
  const requestUrl = API_URLS.createRequestReportChart;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  };
  let url = requestUrl.url.replace('{reportId}', reportId);
  return fetch(url, requestOptions).then(handleResponse);
}

function deleteReportChart(reportId, reportChartId) {
  const requestUrl = API_URLS.deleteDentistRequestReportChartDetail;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify({}),
  };
  let url = requestUrl.url
    .replace('{reportId}', reportId)
    .replace('{reportChartId}', reportChartId);
  return fetch(url, requestOptions).then(handleResponse);
}

function sendReport(requestId, data) {
  const requestUrl = API_URLS.sendRequestReport;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  };
  let url = requestUrl.url.replace('{requestId}', requestId);
  return fetch(url, requestOptions).then(handleResponse);
}

function requirePartialScan(requestId, data) {
  const requestUrl = API_URLS.requirePartialScan;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  };
  let url = requestUrl.url.replace('{requestId}', requestId);
  return fetch(url, requestOptions).then(handleResponse);
}

function updateReport(reportId, data) {
  const requestUrl = API_URLS.updateRequestReport;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  };
  let url = requestUrl.url.replace('{reportId}', reportId);
  return fetch(url, requestOptions).then(handleResponse);
}

function updateReportChart(reportId, reportChartId, data) {
  const requestUrl = API_URLS.updateRequestReportChart;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  };
  let url = requestUrl.url
    .replace('{reportId}', reportId)
    .replace('{reportChartId}', reportChartId);
  return fetch(url, requestOptions).then(handleResponse);
}
